import Spinner from "@legacy/core/components/Spinner";
import formatDuration from "@utils/formatDuration";
import moment from "moment-timezone";
import { Component } from "react";
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import { getJobStatusOrSubStatusLabel } from "../../jobs/utils/utils";
import CalendarEvent from "../components/CalendarEvent";
import CalendarResourceHeader from "../components/CalendarResourceHeader";
import CalendarTimeSlotWrapper from "../components/CalendarTimeSlotWrapper";


class Calendar extends Component {

    render = () => {
        if (this.props.calendarData === null) {
            return <Spinner centered={true} />
        }
        else {
            let startOfDate = new Date(this.props.selectedDay)
            startOfDate.setHours(...(this.props.startOfDay.split(":").map(part => parseInt(part))), 0)

            let endOfDate = new Date(this.props.selectedDay)
            endOfDate.setHours(...(this.props.endOfDay.split(":").map(part => parseInt(part))), 0)

            let scrollTime = new Date()
            const today = new Date().setHours(0, 0, 0, 0)

            if (this.props.selectedDay !== today) {
                scrollTime = startOfDate
            }

            return (
                <BigCalendar
                    localizer={momentLocalizer(moment)}
                    defaultDate={this.props.selectedDay}
                    events={this.props.events}
                    defaultView="day"
                    views={["day"]}
                    toolbar={false}
                    selectable={false}
                    components={{
                        event: (wrapperProps) => {
                            return <CalendarEvent
                                {...wrapperProps}
                                preferredTimezone={this.props.preferredTimezone}
                            ></CalendarEvent>
                        },
                        resourceHeader: (wrapperProps) => {
                            return <CalendarResourceHeader
                                {...wrapperProps}
                                calendarExpanded={this.props.calendarExpanded}
                            ></CalendarResourceHeader>
                        },
                        timeSlotWrapper: (wrapperProps) => {
                            return <CalendarTimeSlotWrapper
                                {...wrapperProps}
                                resource={this.props.resources.find(resource => resource.id == wrapperProps.resource)}
                                date={wrapperProps.value}
                                startOfDate={startOfDate}
                                endOfDate={endOfDate}
                            ></CalendarTimeSlotWrapper>
                        },
                    }}
                    formats={{
                        timeGutterFormat: "h A"
                    }}
                    showMultiDayTimes={true}
                    scrollToTime={scrollTime}
                    onSelectEvent={(event) => window.open(DjangoUrls["jobs:jobs-detail"](window.MARKETPLACE_ENTITY_SLUG, event.id), "_blank")}
                    titleAccessor={(event) => `${event.service_name} (${getJobStatusOrSubStatusLabel(event)})`}
                    allDayAccessor="all_day"
                    startAccessor="start_time"
                    endAccessor="end_time"
                    resourceAccessor="technician_id"
                    tooltipAccessor={(event) => {
                        const thisTechnician = event.assigned_technicians.find(technician => technician.id == event.technician_id)
                        return (
                            <p>
                                <strong>{event.__str__}</strong><br />
                                {event.location}<br /><br />
                                Status: {getJobStatusOrSubStatusLabel(event)}<br />
                                Estimated Duration: {formatDuration(event.estimated_duration)}<br />
                                Techs: {`${thisTechnician.short_name}${event.assigned_technicians.length > 1 ? ` and ${event.assigned_technicians.length - 1} other${event.assigned_technicians.length - 1 !== 1 ? "s" : ""}` : ""}`}
                            </p>
                        )
                    }}

                    resources={this.props.resources}
                    resourceIdAccessor="id"
                    resourceTitleAccessor="full_name"
                ></BigCalendar>
            )
        }
    }
}

export default Calendar;
