import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"

import useUser from "@hooks/useUser"

import { CustomIcon, Icon, Tooltip } from "@atoms/index"

import { useNotificationMethodsQuery } from "@pages/Settings/SettingsNotifications/components/NotificationMethodsSection/NotificationMethodsSection.store"

import { EmailNotificationTooltipProps } from "./EmailNotificationTooltip.types"

export default function EmailNotificationTooltip(props: EmailNotificationTooltipProps) {
    const { children } = props

    const { user } = useUser()

    const { data: defaultMethodsPreference } = useNotificationMethodsQuery()

    const hasVerifiedEmail = user?.email_verified

    const tooltipIcon = hasVerifiedEmail ? (
        <Icon
            icon={icon({
                name: "ban",
                style: "solid",
                family: "sharp",
            })}
        />
    ) : (
        <CustomIcon icon="fa-sharp-solid-at-slash" />
    )

    const tooltipTitle = hasVerifiedEmail ? "Notification method is off" : "No verified email address"
    const tooltipMessage = hasVerifiedEmail
        ? "This can't be edited because the related notification method is off. Turn the method on to manage these topics."
        : "Verify your email address in your Profile settings to turn on email notifications"

    return (
        <Tooltip
            type="message"
            icon={tooltipIcon}
            title={tooltipTitle}
            message={tooltipMessage}
            isEnabled={!hasVerifiedEmail || !defaultMethodsPreference?.contact_email}
            side="right"
            sideOffset={4}
        >
            <div>{children}</div>
        </Tooltip>
    )
}
