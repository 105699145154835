import clsx from "clsx"

import styles from "./PropertyStack.module.scss"
import { PropertyStackProps } from "./PropertyStack.types"

export default function PropertyStack(props: PropertyStackProps) {
    const { children, className } = props

    return <div className={clsx(styles.base, className)}>{children}</div>
}
