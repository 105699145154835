import React, { Component, Fragment } from 'react';
import ServiceLocationDisplay from './ServiceLocationDisplay';


class ServiceLocationListDisplay extends Component {

    render() {
        const { fieldName, fieldLabel, serviceLocations, header } = this.props

        return (
            <div className="data-panel__form__field" id={`div_id_${fieldName}`} aria-label={fieldLabel}>
                <span className="data-panel__form__field__label" aria-hidden="true">{fieldLabel}</span>
                <div className="data-panel__form__field__display-list" aria-label={`${fieldLabel} List`}>
                    {
                        serviceLocations.length === 0
                        ?
                        "(No service locations)"
                        :
                        <Fragment>
                            {header}
                            {serviceLocations.map((serviceLocation, index) => <ServiceLocationDisplay key={`${fieldName}_${index}`} serviceLocation={serviceLocation} />)}
                        </Fragment>
                    }
                </div>
            </div>
        )
    }
}

export default ServiceLocationListDisplay;
