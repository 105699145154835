import { NavigationItem } from "@molecules"

export default function HelpCenter() {
    return (
        <NavigationItem
            onClick={() => window.Helpkit.show("sidebar")}
            variant="secondary"
            label="Help Center"
            module="help"
        />
    )
}
