import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"

import { Popover } from "@molecules"
import usePopover from "@molecules/Popover/Popover.store"

import TableViewMenuColumnsOrder from "@organisms/Table/TableViewMenu/TableViewMenuColumnsOrder/TableViewMenuColumnsOrder"

import styles from "./PopoverOrderColumns.module.scss"
import { PopoverOrderColumnsProps } from "./PopoverOrderColumns.types"

export default function PopoverOrderColumns(props: PopoverOrderColumnsProps) {
    const { isVisible } = props

    const { setContentActive } = usePopover()

    return (
        <div className={styles.base}>
            <Popover.SubHeader title="Columns" />
            <TableViewMenuColumnsOrder isVisible={isVisible} />
            <div className={styles.addColumnButton}>
                <Popover.Button
                    icon={icon({
                        name: "plus",
                        style: "light",
                        family: "sharp",
                    })}
                    onClick={() => setContentActive("add-column")}
                    showChevron={true}
                    isTabbable={isVisible}
                >
                    Add column
                </Popover.Button>
            </div>
        </div>
    )
}
