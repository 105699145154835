// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Property-base__teSte{display:flex;flex-wrap:nowrap;gap:var(--size-3);align-items:center;justify-content:space-between;width:var(--size-full);padding:var(--size-3) var(--size-0)}.Property-base__teSte.Property-wrapOnMobile__pV7YB{flex-wrap:wrap}@media only screen and (min-width: 64rem){.Property-base__teSte.Property-wrapOnMobile__pV7YB{flex-wrap:nowrap}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "Property-base__teSte",
	"wrapOnMobile": "Property-wrapOnMobile__pV7YB"
};
export default ___CSS_LOADER_EXPORT___;
