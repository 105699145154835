import clsx from "clsx"

import { Icon } from "@atoms"

import styles from "./InputPrefixSuffix.module.scss"
import { InputPrefixSuffixProps } from "./InputPrefixSuffix.types"

export default function InputPrefixSuffix(props: InputPrefixSuffixProps) {
    const { size, icon = undefined, value = undefined, htmlFor, type, isDisabled } = props
    return (
        <label
            className={clsx(styles.base, styles[type], {
                [styles.baseSm]: size === "sm",
                [styles.baseMd]: size === "md",
                [styles.baseLg]: size === "lg",
                [styles.disabled]: isDisabled,
            })}
            htmlFor={htmlFor}
        >
            <span className={clsx(styles.icon)}>
                {icon ? (
                    <Icon icon={icon} size={size === "lg" ? 16 : 14} />
                ) : (
                    <p
                        className={clsx(styles.text, {
                            [styles.textSm]: size === "sm" || size === "md",
                            [styles.textMd]: size === "lg",
                        })}
                    >
                        {value}
                    </p>
                )}
            </span>
        </label>
    )
}
