import clsx from "clsx"

import styles from "./PageSection.module.scss"
import { PageSectionProps } from "./PageSection.types"

export default function PageSection(props: PageSectionProps) {
    const { children, gap = 16 } = props

    return <div className={clsx(styles.base, styles[`gap${gap}`])}>{children}</div>
}
