// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@assets/images/chess-pattern.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AvatarCropDialog-modal__KidS9{max-width:var(--size-80)}.AvatarCropDialog-alertBanner__Ldyrf{padding-right:var(--size-3_5);padding-left:var(--size-3_5)}.AvatarCropDialog-cropperArea__uarwW{position:relative;width:var(--size-full);height:calc(var(--size-80) + var(--size-5));background-color:var(--gray-2);background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:repeat;background-size:var(--size-16)}@media only screen and (min-width: 64rem){.AvatarCropDialog-cropperArea__uarwW{height:calc(var(--size-64) + var(--size-4))}}.AvatarCropDialog-cropperArea__uarwW .AvatarCropDialog-reactEasyCrop_CropAreaRound__AxFKa{border:var(--size-0_5) dashed var(--white-a6)}.AvatarCropDialog-zoomWrap__t_Z13{display:flex;flex-direction:column;gap:var(--size-3);align-items:center;align-self:stretch;padding:var(--size-4)}.AvatarCropDialog-zoomTitle__mIMES{color:var(--gray-12);text-align:center;font:var(--font-global-heading-02);font-feature-settings:var(--feature-settings-global-heading-02);letter-spacing:var(--letter-spacing-global-heading-02)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "AvatarCropDialog-modal__KidS9",
	"alertBanner": "AvatarCropDialog-alertBanner__Ldyrf",
	"cropperArea": "AvatarCropDialog-cropperArea__uarwW",
	"reactEasyCrop_CropAreaRound": "AvatarCropDialog-reactEasyCrop_CropAreaRound__AxFKa",
	"reactEasyCropCropAreaRound": "AvatarCropDialog-reactEasyCrop_CropAreaRound__AxFKa",
	"zoomWrap": "AvatarCropDialog-zoomWrap__t_Z13",
	"zoomTitle": "AvatarCropDialog-zoomTitle__mIMES"
};
export default ___CSS_LOADER_EXPORT___;
