import React, { Component } from "react";
import deepcopy from "rfdc";
import { valueIsDefined } from "../../core/utils/utils";
import InlineEquipmentWarrantyContainer from "../InlineEquipmentWarrantyContainer";


class EquipmentWarrantyListEditSelect extends Component {

    constructor(props) {
        super(props)

        this.state = {
            deletedWarrantyIDs: {},
        }
    }

    deleteEquipmentWarranty = (index, warranty) => {
        const { warranties, updateEquipmentWarranties } = this.props

        // Save the ID in case this description is going to be reused
        this.setState((state, props) => {
            let updatedState = state
            if (valueIsDefined(warranty.id)) {
                updatedState.deletedWarrantyIDs[warranty.name] = warranty.id
            }
            return updatedState
        })

        let newWarranties = deepcopy()(warranties)
        newWarranties.splice(index, 1)
        updateEquipmentWarranties(newWarranties)
    }

    updateEquipmentWarranty = (index, warranty) => {
        const { warranties, updateEquipmentWarranties } = this.props

        // Re-use an existing ID for a new warranty to avoid uniqueness constraint issues
        if (!valueIsDefined(warranty.id)) {
            if (warranty.name in this.state.deletedWarrantyIDs) {
                warranty.id = this.state.deletedWarrantyIDs[warranty.name]
            }
            else {
                warranty.id = null
            }
        }

        let newWarranties = deepcopy()(warranties)
        newWarranties[index] = warranty

        updateEquipmentWarranties(newWarranties)
    }

    renderEquipmentWarranty = (warranty, index) => {
        return <InlineEquipmentWarrantyContainer key={`warranty_${warranty.name}_${warranty.errors}`} allWarranties={this.props.warranties} warranty={warranty} index={index} updateEquipmentWarranty={newEquipmentWarranty => this.updateEquipmentWarranty(index, newEquipmentWarranty)} deleteEquipmentWarranty={newWarranty => this.deleteEquipmentWarranty(index, newWarranty)} />
    }

    render = () => {
        const { fieldName, warranties, updateEquipmentWarranties } = this.props

        return (
            <div id={`id_${fieldName}`} className="data-panel__list-select">
                {warranties.map(this.renderEquipmentWarranty)}
            </div>
        )
    }
}

export default EquipmentWarrantyListEditSelect;
