export const ESTIMATES_LIST_CONTROLS_VIEW = "roopairs_estimates_list_controls_view"
export const ESTIMATES_LIST_CONTROLS_PHASE = "roopairs_estimates_list_controls_phase"
export const ESTIMATES_LIST_CONTROLS_ACTIVE_TAB = "roopairs_estimates_list_controls_active_tab"
export const ESTIMATES_LIST_CONTROLS_KEYWORD_SEARCH = "roopairs_estimates_list_controls_keyword_search"
export const ESTIMATES_LIST_CONTROLS_ORDERING = "roopairs_estimates_list_controls_ordering"

export const JOBS_LIST_CONTROLS_VIEW = "roopairs_jobs_list_controls_view"
export const JOBS_LIST_CONTROLS_PHASE = "roopairs_jobs_list_controls_phase"
export const JOBS_LIST_CONTROLS_ACTIVE_TAB = "roopairs_jobs_list_controls_active_tab"
export const JOBS_LIST_CONTROLS_KEYWORD_SEARCH = "roopairs_jobs_list_controls_keyword_search"
export const JOBS_LIST_CONTROLS_ORDERING = "roopairs_jobs_list_controls_ordering"
export const JOBS_LIST_TIMELINE_VIEW_CURRENT_DATE_KEY = "roopairs_jobs_list_timeline_view_current_date"
export const JOBS_LIST_TIMELINE_VIEW_TECHNICIANS_COLUMN_STATUS_KEY =
    "roopairs_jobs_list_timeline_view_technicians_column_status"
export const JOBS_LIST_TIMELINE_VIEW_UNSCHEDULED_JOBS_COLUMN_STATUS_KEY =
    "roopairs_jobs_list_timeline_view_unscheduled_column_status"
export const JOBS_LIST_TIMELINE_VIEW_UNSCHEDULED_JOBS_COLUMN_MESSAGE_STATUS_KEY =
    "roopairs_jobs_list_timeline_view_unscheduled_column_message_status"
export const JOBS_LIST_TIMELINE_VIEW_CARD_STYLE = "roopairs_jobs_list_timeline_view_card_style"
export const JOBS_LIST_TIMELINE_VIEW_SHOULD_SHOW_CANCELLED_JOBS =
    "roopairs_jobs_list_timeline_view_should_show_cancelled_jobs"
export const JOBS_LIST_TIMELINE_VIEW_SHOULD_SHOW_FINALIZED_JOBS =
    "roopairs_jobs_list_timeline_view_should_show_finalized_jobs"
export const JOBS_LIST_TIMELINE_VIEW_ORIENTATION = "roopairs_jobs_list_timeline_view_orientation"

export const INVOICES_LIST_CONTROLS_VIEW = "roopairs_invoices_list_controls_view"
export const INVOICES_LIST_CONTROLS_PHASE = "roopairs_invoices_list_controls_phase"
export const INVOICES_LIST_CONTROLS_ACTIVE_TAB = "roopairs_invoices_list_controls_active_tab"
export const INVOICES_LIST_CONTROLS_KEYWORD_SEARCH = "roopairs_invoices_list_controls_keyword_search"
export const INVOICES_LIST_CONTROLS_ORDERING = "roopairs_invoices_list_controls_ordering"

export const PURCHASE_ORDERS_LIST_CONTROLS_VIEW = "roopairs_purchase_orders_list_controls_view"
export const PURCHASE_ORDERS_LIST_CONTROLS_PHASE = "roopairs_purchase_orders_list_controls_phase"
export const PURCHASE_ORDERS_LIST_CONTROLS_ACTIVE_TAB = "roopairs_purchase_orders_list_controls_active_tab"
export const PURCHASE_ORDERS_LIST_CONTROLS_KEYWORD_SEARCH = "roopairs_purchase_orders_list_controls_keyword_search"
export const PURCHASE_ORDERS_LIST_CONTROLS_ORDERING = "roopairs_purchase_orders_list_controls_ordering"

export const CLIENTS_LIST_CONTROLS_VIEW = "roopairs_clients_list_controls_view"
export const CLIENTS_LIST_CONTROLS_PHASE = "roopairs_clients_list_controls_phase"
export const CLIENTS_LIST_CONTROLS_ACTIVE_TAB = "roopairs_clients_list_controls_active_tab"
export const CLIENTS_LIST_CONTROLS_KEYWORD_SEARCH = "roopairs_clients_list_controls_keyword_search"
export const CLIENTS_LIST_CONTROLS_ORDERING = "roopairs_clients_list_controls_ordering"

export const PRICEBOOK_LIST_CONTROLS_VIEW = "roopairs_pricebook_list_{type}_controls_view"
export const PRICEBOOK_LIST_CONTROLS_PHASE = "roopairs_pricebook_list_{type}_controls_phase"
export const PRICEBOOK_LIST_CONTROLS_ACTIVE_TAB = "roopairs_pricebook_list_{type}_controls_active_tab"
export const PRICEBOOK_LIST_CONTROLS_KEYWORD_SEARCH = "roopairs_pricebook_list_{type}_controls_keyword_search"
export const PRICEBOOK_LIST_CONTROLS_ORDERING = "roopairs_pricebook_list_{type}_controls_ordering"

export const VENDORS_LIST_CONTROLS_VIEW = "roopairs_vendors_list_controls_view"
export const VENDORS_LIST_CONTROLS_PHASE = "roopairs_vendors_list_controls_phase"
export const VENDORS_LIST_CONTROLS_ACTIVE_TAB = "roopairs_vendors_list_controls_active_tab"
export const VENDORS_LIST_CONTROLS_KEYWORD_SEARCH = "roopairs_vendors_list_controls_keyword_search"
export const VENDORS_LIST_CONTROLS_ORDERING = "roopairs_vendors_list_controls_ordering"

export const GLOBAL_TOAST_QUEUE = "roopairs_toast_queue"

export const SETTINGS_PREVIOUS_PAGE = "roopairs_settings_previous_page"

export const ACCOUNT_VERIFICATION_TEMPORARY_CODE_ID_HOLD = "roopairs_account_verification_temporary_code_id_hold"

export const AUTHENTICATION_METHOD_PREFERENCE = "roopairs_authentication_method_preference"
