import { forwardRef } from "react"

import Input from "@molecules/Form/inputs/Input/Input"
import { InputProps } from "@molecules/Form/inputs/Input/Input.types"

function EmailInput<T>(props: InputProps<T>, ref: React.Ref<HTMLInputElement>) {
    const { ...rest } = props

    return <Input ref={ref} type="email" {...rest} />
}

export default forwardRef(EmailInput) as typeof EmailInput
