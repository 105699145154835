// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SecurityCheckDialog-subtitle__ozCGk{display:flex;flex-direction:column;align-items:flex-start;align-self:stretch;padding:var(--size-4);color:var(--gray-a11);font:var(--font-mobile-body-375);font-feature-settings:var(--feature-settings-mobile-body-375);letter-spacing:var(--letter-spacing-mobile-body-375)}@media only screen and (min-width: 64rem){.SecurityCheckDialog-subtitle__ozCGk{font:var(--font-global-body-375);font-feature-settings:var(--feature-settings-global-body-375);letter-spacing:var(--letter-spacing-global-body-375)}}.SecurityCheckDialog-subtitleHighlight__PMvH6{color:var(--gray-a12);font:var(--font-mobile-body-475);font-feature-settings:var(--feature-settings-mobile-body-475);letter-spacing:var(--letter-spacing-mobile-body-475)}@media only screen and (min-width: 64rem){.SecurityCheckDialog-subtitleHighlight__PMvH6{font:var(--font-global-body-475);font-feature-settings:var(--feature-settings-global-body-475);letter-spacing:var(--letter-spacing-global-body-475)}}.SecurityCheckDialog-otpInput__ptx0K{display:flex;gap:var(--size-2);align-items:flex-start;align-self:stretch;padding:var(--size-1) var(--size-4)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subtitle": "SecurityCheckDialog-subtitle__ozCGk",
	"subtitleHighlight": "SecurityCheckDialog-subtitleHighlight__PMvH6",
	"otpInput": "SecurityCheckDialog-otpInput__ptx0K"
};
export default ___CSS_LOADER_EXPORT___;
