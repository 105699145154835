// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OTPInputSlot-base__t597U{display:flex;align-items:center;justify-content:center;padding:var(--size-1_5);overflow:hidden;color:var(--gray-a9);text-align:center;border:var(--size-0_25) solid var(--gray-a5);border-radius:var(--border-radius-2);font:var(--font-global-placeholder-small);font-feature-settings:var(--feature-settings-global-placeholder-small);letter-spacing:var(--letter-spacing-global-placeholder-small)}.OTPInputSlot-filled__CI4ix{color:var(--gray-12)}.OTPInputSlot-active__EICqE{background:var(--gray-a3);border-color:var(--gray-12)}.OTPInputSlot-invalid__owHN3{color:var(--tomato-a11);background-color:var(--tomato-a2);border-color:var(--tomato-9);animation:OTPInputSlot-shake__IywQ7 var(--duration-slowest) cubic-bezier(0.36, 0.07, 0.19, 0.97) both}.OTPInputSlot-sizeSm__JICvF,.OTPInputSlot-sizeMd__nD35o{flex:1;width:var(--size-12)}.OTPInputSlot-sizeSm__JICvF{height:calc(var(--size-8_5) + var(--size-1))}.OTPInputSlot-sizeMd__nD35o{height:var(--size-10)}.OTPInputSlot-placeholder__uxhOt{color:var(--gray-a9)}.OTPInputSlot-disabled__b0q2Z{color:var(--gray-a7);background:var(--gray-a3);border:none;cursor:not-allowed}.OTPInputSlot-disabled__b0q2Z .OTPInputSlot-placeholder__uxhOt{display:none}@keyframes OTPInputSlot-shake__IywQ7{10%,90%{transform:translate3d(-1px, 0, 0)}20%,80%{transform:translate3d(2px, 0, 0)}30%,50%,70%{transform:translate3d(-4px, 0, 0)}40%,60%{transform:translate3d(4px, 0, 0)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "OTPInputSlot-base__t597U",
	"filled": "OTPInputSlot-filled__CI4ix",
	"active": "OTPInputSlot-active__EICqE",
	"invalid": "OTPInputSlot-invalid__owHN3",
	"shake": "OTPInputSlot-shake__IywQ7",
	"sizeSm": "OTPInputSlot-sizeSm__JICvF",
	"sizeMd": "OTPInputSlot-sizeMd__nD35o",
	"placeholder": "OTPInputSlot-placeholder__uxhOt",
	"disabled": "OTPInputSlot-disabled__b0q2Z"
};
export default ___CSS_LOADER_EXPORT___;
