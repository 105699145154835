// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PopoverContent-primary__ako98{transform:translateX(calc(var(--size-48) * -1));opacity:0;transition:var(--duration-slow);transition-property:opacity,transform;pointer-events:none}.PopoverContent-primary__ako98 *{pointer-events:none}.PopoverContent-primary__ako98.PopoverContent-visible__eDjQT{transform:translateX(0px);opacity:1;pointer-events:all}.PopoverContent-primary__ako98.PopoverContent-visible__eDjQT *{pointer-events:all}.PopoverContent-secondary__yLBy3{position:absolute;top:var(--size-0);right:var(--size-0);left:var(--size-24);opacity:0;transition:left var(--duration-slow);pointer-events:none}.PopoverContent-secondary__yLBy3 *{pointer-events:none}.PopoverContent-secondary__yLBy3.PopoverContent-visible__eDjQT{left:var(--size-0);opacity:1;pointer-events:all}.PopoverContent-secondary__yLBy3.PopoverContent-visible__eDjQT .PopoverContent-items__PHBib{opacity:1}.PopoverContent-secondary__yLBy3.PopoverContent-visible__eDjQT *{pointer-events:all}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary": "PopoverContent-primary__ako98",
	"visible": "PopoverContent-visible__eDjQT",
	"secondary": "PopoverContent-secondary__yLBy3",
	"items": "PopoverContent-items__PHBib"
};
export default ___CSS_LOADER_EXPORT___;
