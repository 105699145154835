import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import copy from "copy-to-clipboard"

import debounce from "@utils/debounce"
import { ifSpaceOrEnter } from "@utils/keyboard"

import { IconButton, NavigationLink } from "@atoms/index"

import toast from "@molecules/Toast/Toast"

import { Table } from "@organisms"

import styles from "./LinkCell.module.scss"
import { LinkCellProps } from "./LinkCell.types"

export default function LinkCell(props: LinkCellProps) {
    const { destination } = props

    if (!destination) {
        return <Table.Cells.NoValueCell />
    }

    const handleCopy = debounce(
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent<HTMLButtonElement>) => {
            event.stopPropagation()

            const result = copy(destination)

            if (result) {
                toast({
                    type: "success",
                    size: "md",
                    title: "Link copied to clipboard",
                })
            } else {
                toast({
                    type: "error",
                    size: "md",
                    title: "Could not copy link",
                })
            }
        },
        100,
    )

    return (
        <div
            onClick={(event) => event.stopPropagation()}
            onKeyDown={(event) => ifSpaceOrEnter(event, () => event.stopPropagation())}
            className={styles.base}
        >
            <NavigationLink
                path={`${destination.includes("//") ? "" : "//"}${destination}`}
                title={destination}
                openIn="newTab"
                className={styles.content}
            >
                {() => <span>{destination}</span>}
            </NavigationLink>

            <IconButton
                icon={icon({
                    name: "copy",
                    style: "regular",
                    family: "sharp",
                })}
                onClick={handleCopy}
                onKeyDown={handleCopy}
                colorScheme="gray"
                size="xs"
                variant="ghost"
                className={styles.iconButton}
                iconClassName={styles.icon}
            />
        </div>
    )
}
