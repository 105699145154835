import QuickBooksClientRecordConfirmedList from "./Accounting/QuickBooksClientRecordConfirmedList/QuickBooksClientRecordConfirmedList"
import QuickBooksClientRecordProcessedList from "./Accounting/QuickBooksClientRecordProcessedList/QuickBooksClientRecordProcessedList"
import Main from "./Main/Main"
import SettingsAccounting from "./SettingsAccounting/SettingsAccounting"
import SettingsAvailabilitySchedules from "./SettingsAvailabilitySchedules/SettingsAvailabilitySchedules"
import SettingsCompanyCam from "./SettingsCompanyCam/SettingsCompanyCam"
import SettingsNotifications from "./SettingsNotifications/SettingsNotifications"
import SettingsNotificationsEstimates from "./SettingsNotifications/SettingsNotificationsEstimates/SettingsNotificationsEstimates"
import SettingsNotificationsInventory from "./SettingsNotifications/SettingsNotificationsInventory/SettingsNotificationsInventory"
import SettingsNotificationsJobs from "./SettingsNotifications/SettingsNotificationsJobs/SettingsNotificationsJobs"
import SettingsPayments from "./SettingsPayments/SettingsPayments"
import SettingsPreferences from "./SettingsPreferences/SettingsPreferences"
import SettingsProfile from "./SettingsProfile/SettingsProfile"
import SettingsSecurityAndAccess from "./SettingsSecurityAndAccess/SettingsSecurityAndAccess"

export default {
    Profile: SettingsProfile,
    Accounting: SettingsAccounting,
    AvailabilitySchedules: SettingsAvailabilitySchedules,
    CompanyCam: SettingsCompanyCam,
    Notifications: SettingsNotifications,
    Payments: SettingsPayments,
    Preferences: SettingsPreferences,
    SecurityAndAccess: SettingsSecurityAndAccess,
    SettingsNotificationsJobs,
    SettingsNotificationsEstimates,
    SettingsNotificationsInventory,
    Main,
    QuickBooksClientRecordProcessedList,
    QuickBooksClientRecordConfirmedList,
}
