// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TopBarHeader-base__n8MeY{border-bottom:var(--size-0_25) solid var(--gray-a3)}.TopBarHeader-base__n8MeY .TopBarHeader-container__wh0vk{display:flex;align-items:center;justify-content:space-between;height:var(--size-13);padding:var(--size-0) var(--size-3) var(--size-0) var(--size-1)}@media only screen and (min-width: 64rem){.TopBarHeader-base__n8MeY .TopBarHeader-container__wh0vk{height:var(--size-14);padding:var(--size-0) var(--size-4) var(--size-0) var(--size-1_5)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "TopBarHeader-base__n8MeY",
	"container": "TopBarHeader-container__wh0vk"
};
export default ___CSS_LOADER_EXPORT___;
