// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AuthContainer-base__ehAY_{height:var(--size-full)}@media only screen and (min-width: 64rem){.AuthContainer-base__ehAY_{display:flex;align-items:stretch;justify-content:center}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "AuthContainer-base__ehAY_"
};
export default ___CSS_LOADER_EXPORT___;
