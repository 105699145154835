// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SelectListItemStack-base__MkPx6 [cmdk-list-sizer]{display:flex;flex-direction:column;gap:var(--size-0);padding:var(--size-1) var(--size-0)}.SelectListItemStack-empty__VcuNV{display:flex;gap:var(--size-1);align-items:center;width:var(--size-full);padding:var(--size-1);background-color:var(--white);border:0;border-radius:var(--border-radius-1_5);outline:0;outline-offset:calc(var(--size-0_25)*-1);cursor:pointer;transition:var(--duration-fast);transition-property:background-color,color}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "SelectListItemStack-base__MkPx6",
	"empty": "SelectListItemStack-empty__VcuNV"
};
export default ___CSS_LOADER_EXPORT___;
