import Spinner from "@legacy/core/components/Spinner";
import formatDuration from "@utils/formatDuration";
import { Component, Fragment } from "react";
import ButtonGroup from "../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../core/buttons/UniversalButton";
import ClientInformationDisplay from "../../core/components/ClientInformationDisplay";
import LineItemListSelectFieldGroup from "../../core/components/LineItemListSelectFieldGroup";
import AttachmentUploadField from "../../core/fields/AttachmentUploadField";
import BasicDisplayField from "../../core/fields/BasicDisplayField";
import CharField from "../../core/fields/CharField";
import TextField from "../../core/fields/TextField";
import { CustomIDGenerationModes, JobOriginTypes } from "../../core/utils/enums";
import { currencyFormatter, formatCurrencyForServiceCompany } from "../../core/utils/utils";
import EquipmentDetailsAccordion from "../../equipment/components/EquipmentDetailsAccordion";
import { calculateEstimateAmounts } from "../../estimates/utils/utils";
import { calculateInvoiceAmounts } from "../../invoices/utils/utils";
import JobFeedAccordion from "../components/JobFeedAccordion";


class JobSummaryForm extends Component {

    componentDidMount() {
        // Return to the previous scroll
        document.querySelector(".main").scrollTo(0, this.props.returnScroll || 0)
    }

    renderButtons = () => {
        const {
            mode,
            job,
            submitting,
            errors,
            onFormDataChange,
            requestAction,
            switchToSecondaryForm,
            isInCompleteFlow,
            fileStackAPIKey,
            fileStackPolicy,
            fileStackSignature,
            updateAttachments,
            returnScroll,
        } = this.props

        if (errors.unexpectedError) {
            return (
                <div className="data-panel__action-feedback">
                    <span className="text-invalid"><strong>An unexpected error occurred.</strong></span>
                </div>
            )
        }
        else {
            if (submitting) {
                return (
                    <div className="data-panel__action-feedback">
                        <Spinner centered={true} />
                    </div>
                )
            }
            else {
                if (isInCompleteFlow) {
                    return (
                        <ButtonGroup>
                            <ButtonGroupRow>
                                <UniversalButton type="primary" text="Continue" handler={event => requestAction("SUBMIT_JOB_SUMMARY")} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
                else {
                    return (
                        <ButtonGroup>
                            <ButtonGroupRow>
                                <UniversalButton type="primary" text="Save" handler={event => requestAction("SUBMIT_JOB_SUMMARY")} />
                                <UniversalButton type="secondary" text="Cancel" handler={event => requestAction("JOB_SUMMARY_CANCEL_EDITS")} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
            }
        }
    }

    renderCustomIDField = () => {
        const {
            mode,
            job,
            submitting,
            errors,
            onFormDataChange,
            requestAction,
            switchToSecondaryForm,
            isInCompleteFlow,
            returnScroll,
        } = this.props

        if (job.is_job_walk) {
            if (job.service_company.estimate_custom_id_generation_mode === CustomIDGenerationModes.manual) {
                return <CharField
                    fieldName="estimate_custom_id"
                    fieldLabel="Suggested Estimate ID"
                    fieldValue={job.estimate_custom_id || ""}
                    fieldOnChange={estimate_custom_id => onFormDataChange("estimate_custom_id", estimate_custom_id || "")}
                    maxLength="15"
                    optional={true}
                    optionalText="if you have it"
                    errors={errors}
                ></CharField>
            }
        }
        else {
            if (job.service_company.invoice_custom_id_generation_mode === CustomIDGenerationModes.manual) {
                return <CharField
                    fieldName="invoice_custom_id"
                    fieldLabel="Suggested Invoice ID"
                    fieldValue={job.invoice_custom_id || ""}
                    fieldOnChange={invoice_custom_id => onFormDataChange("invoice_custom_id", invoice_custom_id || "")}
                    maxLength="15"
                    optional={true}
                    optionalText="if you have it"
                    errors={errors}
                ></CharField>
            }
        }
    }

    render() {
        const {
            mode,
            job,
            submitting,
            errors,
            onFormDataChange,
            requestAction,
            switchToSecondaryForm,
            isInCompleteFlow,
            fileStackAPIKey,
            fileStackPolicy,
            fileStackSignature,
            updateAttachments,
            returnScroll,
            addToastToQueue,
        } = this.props

        const { subtotal } = job.is_job_walk ? calculateEstimateAmounts(job) : calculateInvoiceAmounts(job)
        const formPrefix = job.is_job_walk ? "Estimated " : ""
        let formCaption = ""

        if (job.is_job_walk) {
            formCaption = "Please provide an estimate of the work that needs to be done, the parts and materials required, and any other charges or discounts that should be applied to the estimate:"
        }
        else {
            formCaption = "Please provide a summary of the work you've done, the parts and materials you used, and any other charges or discounts that should be applied to this service:"
        }

        return (
            <Fragment>
                <div id="job_summary_form_react_wrapper">
                <div className="data-panel-container data-panel-container--with-margin">
                    <div className="data-panel" aria-label="Job Summary Form">
                        <ClientInformationDisplay
                            client={job.service_location.external_client}
                            serviceLocation={job.service_location}
                        ></ClientInformationDisplay>
                        <div className="data-panel__form" aria-label="Job Summary">
                            <p className="data-panel__form__caption">{formCaption}</p>
                            <BasicDisplayField
                                fieldName="id"
                                fieldLabel="Roopairs Job ID"
                                fieldValue={job.custom_id ? job.custom_id : job.id}
                            ></BasicDisplayField>
                            {
                                job.origin_type !== JobOriginTypes.roopairs && (
                                    <BasicDisplayField
                                        fieldName="origin_id"
                                        fieldLabel={`${job.origin_type !== JobOriginTypes.other ? job.origin_type_label : job.origin_type_other_name} ID`}
                                        fieldValue={job.origin_id}
                                    ></BasicDisplayField>
                                )
                            }
                            <BasicDisplayField
                                fieldName="service_name"
                                fieldLabel="Service Type"
                                fieldValue={job.service_name}
                            ></BasicDisplayField>
                            <BasicDisplayField
                                fieldName="is_job_walk"
                                fieldLabel="Job Type"
                                fieldValue={job.is_callback ? "Callback" : job.is_job_walk ? "Job Walk" : "Service"}
                            ></BasicDisplayField>
                            <BasicDisplayField
                                fieldName="total_time_logged"
                                fieldLabel="Total Time Logged"
                                fieldValue={formatDuration(job.total_time_logged)}
                            ></BasicDisplayField>

                            {this.renderCustomIDField()}
                            <LineItemListSelectFieldGroup
                                object={job}
                                labelPrefix={formPrefix}
                                formatCurrencyValue={currencyFormatter(job.service_company.currency_code, job.service_company.language_code)}
                                showSummaryHint={true}
                                switchToSecondaryForm={switchToSecondaryForm}
                                errors={errors}
                                nonFieldErrorMessage={errors.line_items || "There's an issue with one or more of the items below. Try coming back to this page and trying again in a bit."}
                                isJobLineItem={true}
                            ></LineItemListSelectFieldGroup>
                            {
                                !window.CURRENT_USER.hide_financial_context && (
                                    <BasicDisplayField
                                        fieldName="subtotal"
                                        fieldLabel={`${formPrefix}Subtotal`}
                                        fieldValue={formatCurrencyForServiceCompany(subtotal, job.service_company)}
                                    ></BasicDisplayField>
                                )
                            }
                            <TextField
                                fieldName="technician_notes"
                                fieldLabel="Notes to Dispatcher(s)"
                                fieldValue={job.technician_notes || ""}
                                fieldOnChange={technician_notes => onFormDataChange("technician_notes", technician_notes || "")}
                                rows={3}
                                placeholder={`Notes specific to this ${job.is_job_walk ? "job walk" : "service"}. e.g. 'My time logged is actually 3 hours.' or 'Client wants a quote on a new unit.'`}
                                optional={true}
                                errors={errors}
                            ></TextField>
                            {
                                window.FILE_UPLOADS_ENABLED && (
                                    <AttachmentUploadField
                                        fieldName="attachments"
                                        fieldLabel="Attachments"
                                        attachments={job.attachments}
                                        fileStackAPIKey={fileStackAPIKey}
                                        fileStackPolicy={fileStackPolicy}
                                        fileStackSignature={fileStackSignature}
                                        onUploadDone={(response) => updateAttachments(
                                            response.filesUploaded.map(file => ({
                                                "upload_filename": file.filename,
                                                "upload_id": file.uploadId,
                                                "upload_handle": file.handle,
                                                "upload_url": file.url,
                                                "upload_mimetype": file.mimetype,
                                                "upload_size": file.size,
                                                "upload_source": file.source
                                            }))
                                        )}
                                        optional={true}
                                        optionalText={"if any"}
                                        errors={errors}
                                    />
                                )
                            }
                        </div>
                        {this.renderButtons()}
                    </div>
                </div>
                </div>
                {
                    job.equipment.sort((obj, other) => obj.display_name.localeCompare(other.display_name)).map(equipment => {
                        return (
                            <div key={`equipment_${equipment.id}`} className="accordion-wrapper">
                                <EquipmentDetailsAccordion clientID={job.service_location.external_client.id} data={equipment} editable={true} addToastToQueue={addToastToQueue}/>
                            </div>
                        )
                    })
                }
                {
                    <div className="accordion-wrapper">
                        <JobFeedAccordion jobID={job.id} totalTimeLogged={job.total_time_logged} />
                    </div>
                }
            </Fragment>
        )
    }
}

export default JobSummaryForm;
