// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NavigationSection-base__zVzrP{display:flex;flex-direction:column;gap:var(--size-1_5);align-items:flex-start;align-self:stretch;padding-top:var(--size-5)}.NavigationSection-title__Svw85{padding:var(--size-0) var(--size-5);color:var(--gray-a9);font:var(--font-mobile-caption-medium-475);font-feature-settings:var(--feature-settings-mobile-caption-medium-475);letter-spacing:var(--letter-spacing-mobile-caption-medium-475)}@media only screen and (min-width: 64rem){.NavigationSection-title__Svw85{font:var(--font-global-caption-medium-475);font-feature-settings:var(--feature-settings-global-caption-medium-475);letter-spacing:var(--letter-spacing-global-caption-medium-475)}}.NavigationSection-links__ShsmR{display:flex;flex-direction:column;gap:var(--size-0_5);align-items:flex-start;align-self:stretch;padding:var(--size-0) var(--size-1_5)}@media only screen and (min-width: 64rem){.NavigationSection-links__ShsmR{padding:var(--size-0) var(--size-3)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "NavigationSection-base__zVzrP",
	"title": "NavigationSection-title__Svw85",
	"links": "NavigationSection-links__ShsmR"
};
export default ___CSS_LOADER_EXPORT___;
