// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PropertyContent-base__IREp7{display:flex;flex-direction:column;gap:var(--size-0_5);width:var(--size-full)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "PropertyContent-base__IREp7"
};
export default ___CSS_LOADER_EXPORT___;
