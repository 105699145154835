import { useState } from "react"
import * as Yup from "yup"

import useIsDesktop from "@hooks/useIsDesktop"
import useUser from "@hooks/useUser"

import { EmailInput } from "@molecules/Form/Form"
import GenericForm from "@molecules/Form/GenericForm/GenericForm"
import { MutationSuccessFunctionParams } from "@molecules/Form/GenericForm/GenericForm.types"
import toast from "@molecules/Toast/Toast"

import { SecurityCheckDialog } from "@organisms/index"

import { USER_ENDPOINT } from "@endpoints/user"

import { EmailChangeErrorResponse, EmailFormProps, UserEmail } from "./EmailForm.types"

const formSchema: Yup.ObjectSchema<UserEmail> = Yup.object().shape({
    email: Yup.string().required("Please enter an email address").email("Please enter a valid email address"),
})

export default function EmailForm(props: EmailFormProps) {
    const { showLabel = true } = props

    const { user, updateUser } = useUser()
    const isDesktop = useIsDesktop()

    const [isVerifyingEmail, setIsVerifyingEmail] = useState<boolean>(false)

    const startEmailVerification = () => {
        setIsVerifyingEmail(true)
    }

    const endEmailVerification = () => {
        setIsVerifyingEmail(false)

        updateUser({ email_verified: true })

        toast({
            size: "md",
            title: "Email address updated",
            type: "success",
        })
    }

    const onSuccessfulEmailFormSubmission = (args: MutationSuccessFunctionParams<UserEmail, User>) => {
        const emailWasChanged = user?.email !== args.formValues.email

        if (emailWasChanged) {
            startEmailVerification()

            updateUser({
                email: args.formValues.email,
                email_verified: false,
            })
        } else {
            endEmailVerification()
        }
    }

    return (
        <>
            <SecurityCheckDialog
                checkFor="email"
                onSuccess={endEmailVerification}
                onOpenChange={setIsVerifyingEmail}
                isOpen={isVerifyingEmail}
            />
            <GenericForm<UserEmail, User, EmailChangeErrorResponse, UserEmail>
                formConfig={{
                    defaultValues: {
                        email: "",
                    },
                }}
                formValidationSchema={formSchema}
                formValues={{
                    email: user?.email,
                }}
                mutationConfig={{
                    genericErrorMessage: "Could not update email address",
                    method: "PATCH",
                    endpoint: USER_ENDPOINT,
                }}
                onMutationSuccess={onSuccessfulEmailFormSubmission}
            >
                {({ isPending, form }) => {
                    const isTyping = user?.email !== form.getValues()?.email
                    const isEmailVerificationRequired =
                        !user?.email_verified && form.getValues()?.email === user?.email && !isTyping

                    const buttonCopy = !user?.email
                        ? "Set"
                        : isTyping || !isEmailVerificationRequired
                          ? "Update"
                          : "Verify"

                    return (
                        <EmailInput<UserEmail>
                            label={showLabel ? "Email address" : undefined}
                            size={isDesktop ? "md" : "lg"}
                            placeholder="hello@example.com"
                            aria-label="Email address"
                            button={{
                                colorScheme: "gray",
                                variant: "solid",
                                type: isEmailVerificationRequired ? "button" : "submit",
                                children: buttonCopy,
                                isLoading: isPending,
                                loadingText: "Updating",
                                onClick: isEmailVerificationRequired ? startEmailVerification : undefined,
                            }}
                            inputHelpText={
                                isEmailVerificationRequired
                                    ? {
                                          children: "Verification required",
                                          type: "warning",
                                      }
                                    : undefined
                            }
                            name="email"
                        />
                    )
                }}
            </GenericForm>
        </>
    )
}
