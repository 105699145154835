function DownPaymentRenderer(props) {
    const { value, alertCondition } = props

    const iconMap = {
        "Not Required": "fa-sharp fa-light fa-minus",
        "Paid": "fa-sharp fa-light fa-check",
        "Partially Paid": "fa-sharp fa-light fa-xmark",
        "Unpaid": "fa-sharp fa-light fa-xmark",
    }

    const isAlerting = alertCondition && ["Unpaid", "Partially Paid"].includes(value)

    return <span className={isAlerting ? "text-alerting" : ""}><i className={iconMap[value]} aria-hidden="true" />{"\u00A0"}{"\u00A0"}{value}</span>
}

export default DownPaymentRenderer
