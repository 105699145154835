import { convertToKebabCase, formatDateTime, valueIsDefined } from "../../core/utils/utils"
import { getWarrantyStatus } from "../utils/utils"

export default function EquipmentWarrantyDisplay(props) {
    const { warranty } = props

    const formattedStartDate = valueIsDefined(warranty.start_date) ? formatDateTime(warranty.start_date, "UTC", {short: true, addYear: true}).localDateString : "--"
    const formattedEndDate = valueIsDefined(warranty.end_date) ? formatDateTime(warranty.end_date, "UTC", {short: true, addYear: true}).localDateString : "--"

    return (
        <div className="data-panel__form__field__display-list__object equipment-warranty-display" aria-label="Equipment Warranty">
            <div className="equipment-warranty-display__row equipment-warranty-status-display">
                <div className="equipment-warranty-display__field" aria-label={getWarrantyStatus(warranty.start_date, warranty.end_date)}>
                    <div className="equipment-warranty-display__field-value"><span className={`data-tag equipment-warranty--${convertToKebabCase(getWarrantyStatus(warranty.start_date, warranty.end_date))}`} style={{margin: "0", width: "100%"}}>{getWarrantyStatus(warranty.start_date, warranty.end_date)}</span></div>
                </div>
            </div>
            <div className="equipment-warranty-display__row">
                <div className="equipment-warranty-display__field" aria-label="Name">
                    <div className="equipment-warranty-display__field-label" aria-hidden="true">Name</div>
                    <div className="equipment-warranty-display__field-value">{warranty.name}</div>
                </div>
            </div>
            <div className="equipment-warranty-display__row">
                <div className="equipment-warranty-display__field" aria-label="Start Date">
                    <div className={"equipment-warranty-display__field-label"} aria-hidden="true">Start Date</div>
                    <div className="equipment-warranty-display__field-value">{formattedStartDate}</div>
                </div>
                <div className="equipment-warranty-display__field" aria-label="End Date">
                    <div className={"equipment-warranty-display__field-label"} aria-hidden="true">End Date</div>
                    <div className="equipment-warranty-display__field-value">{formattedEndDate}</div>
                </div>
            </div>
            <div className="equipment-warranty-display__row">
                <div className="equipment-warranty-display__field" aria-label="Details">
                    <div className={"equipment-warranty-display__field-label"} aria-hidden="true">Details</div>
                    <div className="equipment-warranty-display__field-value">{warranty.details || "--"}</div>
                </div>
            </div>
        </div>
    )
}
