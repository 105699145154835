import { Slot } from "@radix-ui/react-slot"
import clsx from "clsx"
import {
    ComponentPropsWithoutRef,
    ElementRef,
    HTMLAttributes,
    createContext,
    forwardRef,
    useContext,
    useId,
} from "react"
import {
    Controller,
    ControllerProps,
    FieldPath,
    FieldValues,
    FormProvider,
    UseFormReturn,
    useFormContext,
} from "react-hook-form"

import styles from "./Form.module.scss"
import DateInput from "./inputs/DateInput/DateInput"
import EmailInput from "./inputs/EmailInput/EmailInput"
import PasswordInput from "./inputs/PasswordInput/PasswordInput"
import PhoneNumberInput from "./inputs/PhoneNumberInput/PhoneNumberInput"

type FormFieldContextValue<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
    name: TName
}

const FormFieldContext = createContext<FormFieldContextValue>({} as FormFieldContextValue)

const FormField = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
    ...props
}: ControllerProps<TFieldValues, TName>) => {
    return (
        <FormFieldContext.Provider value={{ name: props.name }}>
            <Controller {...props} />
        </FormFieldContext.Provider>
    )
}

const useFormField = () => {
    const fieldContext = useContext(FormFieldContext)
    const itemContext = useContext(FormItemContext)
    const { getFieldState, formState } = useFormContext()

    const fieldState = getFieldState(fieldContext.name, formState)

    if (!fieldContext || !itemContext) {
        throw new Error("useFormField should be used within <FormField>")
    }

    const { id } = itemContext

    return {
        id,
        name: fieldContext.name,
        formItemId: `${id}-form-item`,
        formDescriptionId: `${id}-form-item-description`,
        formMessageId: `${id}-form-item-message`,
        ...fieldState,
    }
}

type FormItemContextValue = {
    id: string
}

const FormItemContext = createContext<FormItemContextValue>({} as FormItemContextValue)

const FormItem = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }: HTMLAttributes<HTMLDivElement>, ref) => {
        const id = useId()

        return (
            <FormItemContext.Provider value={{ id }}>
                <div ref={ref} className={clsx(styles.formItem, className)} {...props} />
            </FormItemContext.Provider>
        )
    },
)

FormItem.displayName = "FormItem"

const FormControl = forwardRef<ElementRef<typeof Slot>, ComponentPropsWithoutRef<typeof Slot>>(({ ...props }, ref) => {
    const { error, formItemId, formDescriptionId, formMessageId } = useFormField()

    return (
        <Slot
            ref={ref}
            id={formItemId}
            aria-describedby={!error ? `${formDescriptionId}` : `${formDescriptionId} ${formMessageId}`}
            aria-invalid={!!error}
            {...props}
        />
    )
})
FormControl.displayName = "FormControl"

const Form = <
    TFieldValues extends FieldValues,
    TContext = unknown,
    TTransformedValues extends FieldValues | undefined = undefined,
>(
    props: {
        form: UseFormReturn<TFieldValues, TContext, TTransformedValues>
        noFormTag?: boolean
    } & HTMLAttributes<HTMLFormElement>,
) => {
    const { children, onSubmit, className = "", noFormTag = false, form, ...rest } = props

    const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        event.stopPropagation()
    }

    return (
        <FormProvider {...form}>
            {noFormTag ? (
                children
            ) : (
                <form
                    onSubmit={onSubmit || onSubmitHandler}
                    className={clsx(styles.form, className)}
                    // prevents browser validation to be fired
                    noValidate={true}
                    {...rest}
                >
                    {children}
                </form>
            )}
        </FormProvider>
    )
}

export default Form

export { DateInput, EmailInput, Form, FormControl, FormField, FormItem, PasswordInput, PhoneNumberInput, useFormField }
