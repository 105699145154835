// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SelectListHeader-base__nI46r{display:flex;flex:1 0 0;gap:var(--size-0_5);align-items:center;width:var(--size-full);padding:0 var(--size-2_5);color:var(--gray-a12);background-color:var(--gray-a4);border-radius:var(--border-radius-0)}.SelectListHeader-input__rtUs1{width:var(--size-full);height:var(--size-9);padding:0 0 0 var(--size-1);color:var(--gray-12);background-color:rgba(0,0,0,0);border:0;font:var(--font-global-body-475);font-feature-settings:var(--feature-settings-global-body-475);letter-spacing:var(--letter-spacing-global-body-475)}.SelectListHeader-input__rtUs1::placeholder{color:var(--gray-9)}.SelectListHeader-input__rtUs1:focus-visible{border:var(--size-0);outline:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "SelectListHeader-base__nI46r",
	"input": "SelectListHeader-input__rtUs1"
};
export default ___CSS_LOADER_EXPORT___;
