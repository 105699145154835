import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import clsx from "clsx"
import { Command } from "cmdk"
import { useState } from "react"

import { Icon } from "@atoms"

import styles from "@molecules/KeywordSearch/KeywordSearch.module.scss"

export default function BottomSheetSearchInput() {
    const [value, setValue] = useState<string>("")

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value)
    }

    return (
        <div className={clsx(styles.base, styles.alwaysFullWidth)}>
            <div
                className={clsx(styles.innerContainer, {
                    [styles.typed]: value.length > 0,
                    [styles.typing]: value.length === 0,
                })}
            >
                <Icon
                    icon={icon({
                        name: "search",
                        style: "light",
                        family: "sharp",
                    })}
                    size={14}
                />

                <Command.Input
                    onChangeCapture={onInputChange}
                    placeholder="Search..."
                    className={styles.input}
                    autoFocus={true}
                />
            </div>
        </div>
    )
}
