import { useEffect, useRef, useState } from "react"
import WrapperButton from "../buttons/WrapperButton"

export default function ShowMoreTextRenderer(props) {
    const { text, startClamped } = props
    const [clamped, setClamped] = useState(startClamped)
    const [showButton, setShowButton] = useState(true)
    const containerRef = useRef(null)
    const toggleClamped = () => setClamped(!clamped)

    useEffect(() => {
        checkButtonAvailability()
    }, [containerRef])

    const hasClamping = (element) => {
        const { clientHeight, scrollHeight } = element
        return clientHeight !== scrollHeight
    }

    const checkButtonAvailability = () => {
        if (containerRef.current) {
            const hadClampClass = containerRef.current.classList.contains("line-clamp")

            if (!hadClampClass) {
                containerRef.current.classList.add("line-clamp")
            }

            setShowButton(hasClamping(containerRef.current))

            if (!hadClampClass) {
                containerRef.current.classList.remove("line-clamp")
            }
        }
    }

    return (
        <>
            <div ref={containerRef} className={clamped ? "long-text" : "long-text line-clamp"}>{text}</div>
            {
                showButton && (
                    <WrapperButton
                        handler={toggleClamped}
                        title={`Show ${clamped ? "less" : "more"} text`}
                    >
                        <span className="text-show-more">Show {clamped ? "less" : "more"} <i className={`fa-sharp fa-angle-${clamped ? "up" : "down"}`} /></span>
                    </WrapperButton>
                )
            }
        </>
    )
}
