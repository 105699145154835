import pluralize from "pluralize";
import { Fragment } from "react";
import { AsyncPaginate, withAsyncPaginate } from "react-select-async-paginate";
import Creatable from 'react-select/creatable';
import UniversalButton from "../buttons/UniversalButton";
import { valueIsDefined } from "../utils/utils";


async function fetchOptions(...args) {
    const [searchKeywords, _loadedOptions, { endpoint }, props] = args
    const {listEndpoint, listEndpointKwargs, ignoreOptions, getObjectLabel} = props
    let fetchUrl

    if (valueIsDefined(endpoint)) {
        fetchUrl = endpoint
    }
    else {
        const searchParams = new URLSearchParams()
        listEndpointKwargs.forEach(([key, value]) => searchParams.append(key, value))
        searchParams.set("keywords", searchKeywords)
        fetchUrl = listEndpoint + "?" + searchParams.toString()
    }

    const response = await fetch(fetchUrl)
    const objectListResponse = await response.json()

    let objectList
    let shouldFetchMoreObjects
    let nextEndpoint

    // Handle paginated and non-paginated endpoints differently
    if (!objectListResponse.hasOwnProperty("results") && !objectListResponse.hasOwnProperty("next")) {
        objectList = objectListResponse
        shouldFetchMoreObjects = false
        nextEndpoint = null
    }
    else {
        objectList = objectListResponse.results
        shouldFetchMoreObjects = objectListResponse.next !== null
        nextEndpoint = objectListResponse.next
    }

    const objectOptions = objectList.filter(
        object => !valueIsDefined(ignoreOptions) || !ignoreOptions.includes(object.id)
    ).map(
        object => ({"value": object.id, "label": getObjectLabel(object), "object": object})
    )

    return {
        options: objectOptions,
        hasMore: shouldFetchMoreObjects,
        additional: {
            endpoint: nextEndpoint,
        }
    }
}


async function getDetailedSelections(selected, props) {
    const {isMulti, getDetailsEndpoint} = props

    if (!isMulti) {
        selected = [selected]
    }

    // Asynchronously update all the selected objects to be the detailed versions
    const getDetailedObject = async (object) => {
        const endpoint = getDetailsEndpoint(object)
        const response = await fetch(endpoint)
        return await response.json()
    }

    const detailedSelections = await Promise.all(selected.map(
        async (option) => option.hasOwnProperty("object") ? {...option, object: await getDetailedObject(option.object)} : option
    ))

    return isMulti ? detailedSelections : detailedSelections[0]
}


function SearchOrCreateSelectInput(props) {
    const {
        renderKey,
        objectName,
        getObjectLabel,
        listEndpoint,
        listEndpointKwargs,
        getDetailsEndpoint,
        getCreateLabel=(value) => `Create "${value}"`,
        defaultSelected,
        ignoreOptions,
        onSelectionChange,
        onInputChange,
        showCreateButton,
        onCreateClick,
        creatable=false,
        isMulti=false,
        refetchSelection=true,
        disabled=false
    } = props
    const notFoundLabel = props.notFoundLabel || <span>{`No ${pluralize(objectName)} Found.`}{showCreateButton && !disabled ? <Fragment><br></br>Click "+" to add one.</Fragment> : ""}</span>
    const placeholder = props.placeholder || (isMulti ? `Search for ${pluralize(objectName)}...` : `Search for a ${objectName}...`)

    const handleOnChange = (selection) => {
        if (selection === null) {
            onSelectionChange(null)
        }
        else if (refetchSelection) {
            // Refetches the detail version of each selected object.
            // This can cause issues with multi selects with many options selected if all the network requests don't finish before the form is submitted.
            // If the list response is enough to satisfy downstream requirements, it's recommended to disable refetch to avoid performance and edge-case issues.
            getDetailedSelections(selection, {isMulti, getDetailsEndpoint}).then(onSelectionChange)
        }
        else {
            onSelectionChange(selection)
        }
    }

    const PaginationClass = creatable ? withAsyncPaginate(Creatable) : AsyncPaginate

    return <Fragment>
        <PaginationClass
            key={renderKey}
            isMulti={isMulti}
            isClearable={!isMulti}
            isDisabled={disabled}
            isSearchable={!disabled}
            loadOptions={(...args) => fetchOptions(...args, {listEndpoint, listEndpointKwargs, ignoreOptions, getObjectLabel})}
            debounceTimeout={275}
            additional={{endpoint: undefined}}
            defaultValue={defaultSelected}
            defaultOptions={true}
            onChange={handleOnChange}
            onInputChange={onInputChange}
            className={`react-select-container${showCreateButton && !disabled ? " react-select-container--with-button" : ""}`}
            classNamePrefix="react-select"
            noOptionsMessage={() => notFoundLabel}
            formatCreateLabel={getCreateLabel}
            placeholder={placeholder}
        />
        {showCreateButton && !disabled && <UniversalButton type="search_or_create" text={<Fragment><i className="fa-sharp fa-light fa-plus no-margin" aria-label={`Create New ${objectName}`}></i></Fragment>} handler={onCreateClick} title={`Create New ${objectName}`}></UniversalButton>}
    </Fragment>
}

export default SearchOrCreateSelectInput
