import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"

import useRouter from "@hooks/useRouter"
import useUser from "@hooks/useUser"

import { Button, ListInfoGlyph } from "@atoms"

import styles from "@organisms/CardList/CardListEdgeStates/CardListEdgeStates.module.scss"

import { CardListEmptyStateProps } from "./CardListEmptyState.types"

export default function CardListEmptyState(props: CardListEmptyStateProps) {
    const { tabContentName, activeTabConfig, objectCreateRoute } = props
    const { push } = useRouter()
    const { user } = useUser()

    const title = `No ${tabContentName.toLowerCase().replace("all", "")}`

    const explanation = `There are no ${tabContentName
        .toLowerCase()
        .replace("all", "")
        .replace("&", "or")} to display.`

    return (
        <div className={styles.base}>
            <div className={styles.content}>
                <div className={styles.copyWrap}>
                    <ListInfoGlyph
                        variant="black"
                        icon={icon({ name: "file-lines", style: "solid", family: "sharp" })}
                    />
                    <div className={styles.copy}>
                        <h2 className={styles.title}>{title}</h2>
                        <p className={styles.explanation}>{explanation}</p>
                    </div>
                </div>
                {user?.isServiceDispatcher && (
                    <Button
                        colorScheme="gray"
                        size="md"
                        variant="solid"
                        leftIcon={icon({ name: "plus", style: "solid", family: "sharp" })}
                        onClick={() => push(objectCreateRoute)}
                        noDebounce={true}
                    >
                        Create {activeTabConfig?.tabObjectName}
                    </Button>
                )}
            </div>
        </div>
    )
}
