import { useEffect, useState } from "react"

import useUser from "@hooks/useUser"

import { SelectListItem } from "@molecules/Select/SelectListItem/SelectListItem.types"
import { Select } from "@molecules/index"

import styles from "./AppTimezoneSelector.module.scss"

export default function AppTimezoneSelector() {
    const { user } = useUser()

    const [selectedTimezone, setSelectedTimezone] = useState<SelectListItem>()

    useEffect(() => {
        if (user?.service_company.preferred_timezone) {
            setSelectedTimezone({
                value: user?.service_company.preferred_timezone,
                label: user?.service_company.preferred_timezone,
            })
        }
    }, [user])

    return (
        <Select.Root>
            <Select.Input
                text={selectedTimezone?.label}
                placeholder="Timezone"
                isDisabled={true}
                className={styles.button}
                size="md"
            />
        </Select.Root>
    )
}
