import { icon as fontAwesomeIcon } from "@fortawesome/fontawesome-svg-core/import.macro"
import clsx from "clsx"

import { Icon, NavigationLink } from "@atoms"

import useBottomSheet from "@organisms/BottomSheet/BottomSheet.store"

import styles from "./BottomSheetItem.module.scss"
import { BottomSheetItemProps } from "./BottomSheetItem.types"

export default function BottomSheetItem(props: BottomSheetItemProps) {
    const {
        icon,
        label,
        path,
        hasSubItems,
        onClick,
        isExpanded,
        isDisabled,
        isDangerous,
        className,
        isTabbable = true,
        isSelected = false,
        children = null,
    } = props
    const { isOpen } = useBottomSheet()

    return (
        <NavigationLink
            title={label}
            path={path}
            className={clsx(styles.base, className, {
                [styles.disabled]: isDisabled,
                [styles.expanded]: isExpanded,
                [styles.dangerous]: isDangerous,
                [styles.selected]: isSelected,
                [styles.clickable]: !!onClick || !!path,
            })}
            isTabbable={isTabbable && isOpen}
            onClick={onClick}
        >
            {() => (
                <div className={styles.container}>
                    <div className={styles.iconAndText}>
                        {icon && (
                            <div className={styles.icon}>
                                <Icon icon={icon} size={20} noContainer={true} />
                            </div>
                        )}

                        <span className={styles.text}>{label}</span>
                    </div>

                    {children}

                    {hasSubItems && (
                        <div className={styles.chevronWrap}>
                            <Icon
                                icon={fontAwesomeIcon({ name: "angle-right", style: "regular", family: "sharp" })}
                                size={14}
                                noContainer={true}
                                className={styles.chevron}
                            />
                        </div>
                    )}
                    {isSelected && (
                        <div className={styles.checkmarkWrap}>
                            <Icon
                                icon={fontAwesomeIcon({ name: "check-circle", style: "solid", family: "sharp" })}
                                size={16}
                                className={styles.checkmark}
                            />
                        </div>
                    )}
                </div>
            )}
        </NavigationLink>
    )
}
