import SettingsAccountingConfigPanel from "@legacy/core/components/SettingsAccountingConfigPanel"

import useToastQueue from "@hooks/useToastQueue"

import { Settings } from "@templates/index"

export default function SettingsAccounting() {
    const { addToastToQueue } = useToastQueue()

    return (
        <Settings.Wrapper title="Accounting">
            <SettingsAccountingConfigPanel addToastToQueue={addToastToQueue} />
        </Settings.Wrapper>
    )
}
