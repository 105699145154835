function sortContact(a, b) {
    if (a.primary && !b.primary) {
        return -1
    }
    else if (b.primary && !a.primary) {
        return 1
    }
    else {
        return a.name.localeCompare(b.name)
    }
}

export { sortContact }
