import clsx from "clsx"

import useIsDesktop from "@hooks/useIsDesktop"

import { Tooltip } from "@atoms"
import AvatarStatus from "@atoms/Avatar/AvatarStatus/AvatarStatus"

import { UnknownAvatar, UserAvatar } from "@molecules"

import useJobTimelineViewStates from "@organisms/ObjectsView/JobTimelineView/hooks/useJobTimelineViewStates"

import styles from "./TimelineViewTechniciansBarTechCell.module.scss"
import { TimelineViewTechniciansBarTechCellProps } from "./TimelineViewTechniciansBarTechCell.types"

export default function TimelineViewTechniciansBarTechCell(props: TimelineViewTechniciansBarTechCellProps) {
    const { technician, isCollapsed = undefined, isCompact = undefined, isVertical = false } = props

    const isUnassigned = technician === null
    const isAvailable = technician?.available

    const status = isAvailable ? technician?.status || "Working" : technician?.status || "Not Working"

    const isDesktop = useIsDesktop()

    const { techniciansColumnStatus, jobCardStyle } = useJobTimelineViewStates()

    // The component is rendered differently for vertical and horizontal schedulers.
    // For the vertical scheduler, which always re-renders this component, we use the `isCollapsed` prop.
    // For the horizontal scheduler, which doesn't always re-render but retains React capabilities, we use the `techniciansColumnStatus` state.
    const techsBarIsCollapsed = isCollapsed ?? techniciansColumnStatus === "collapsed"

    // The explanation of the following logic is the same as above.
    const jobCardStyleIsCompact = isCompact ?? jobCardStyle === "compact"

    const shouldDisplayAvatarStatus = !isUnassigned && (jobCardStyleIsCompact || (techsBarIsCollapsed && !isDesktop))

    return (
        <div
            className={clsx(styles.base, {
                [styles.unassigned]: isUnassigned,
                [styles.collapsed]: techsBarIsCollapsed && !isDesktop,
                [styles.compact]: jobCardStyleIsCompact,
                [styles.vertical]: isVertical,
            })}
        >
            <Tooltip label={!isUnassigned && jobCardStyleIsCompact && status ? status : ""} side="bottom">
                <div className={styles.avatar}>
                    {isUnassigned ? (
                        <UnknownAvatar shape="circle" />
                    ) : (
                        <UserAvatar
                            user={technician}
                            size={32}
                            shape="circle"
                            status={shouldDisplayAvatarStatus ? (isAvailable ? "working" : "unavailable") : null}
                        />
                    )}
                </div>
            </Tooltip>
            <div className={styles.content}>
                <div className={styles.name}>{isUnassigned ? "Unassigned" : technician?.full_name}</div>
                {!isUnassigned && !jobCardStyleIsCompact && (
                    <div className={styles.statusWrap}>
                        <AvatarStatus status={isAvailable ? "working" : "unavailable"} size={6} />
                        <div className={styles.statusWrapText}>{status}</div>
                    </div>
                )}
            </div>
        </div>
    )
}
