import clsx from "clsx"

import { Table } from "@organisms"

import styles from "./TruncatedTextCell.module.scss"
import { TruncatedTextCellProps } from "./TruncatedTextCell.types"

export default function TruncatedTextCell(props: TruncatedTextCellProps) {
    const { variant = "primary", content } = props

    if (!content) {
        return <Table.Cells.NoValueCell />
    }

    return (
        <div className={clsx(styles.base, styles[variant])}>
            <div className={styles.content}>{content}</div>
        </div>
    )
}
