import { Component, Fragment } from "react";
import { sortContact } from "../../../../core/utils/sorters";
import QuickBooksContactDisplay from "./QuickBooksContactDisplay";


class QuickBooksContactListDisplay extends Component {

    render = () => {
        const { record, label } = this.props
        const contacts = record.contacts.filter(contact => contact.ignored !== true)

        return (
            <div className="data-panel__form__field" aria-label={label}>
                <span className="data-panel__form__field__label" aria-hidden="true">{label}</span>
                <div className="data-panel__form__field__display-list" aria-label="Contacts List">
                    {
                        contacts.length === 0
                        ?
                        "(No contacts)"
                        :
                        <Fragment>
                            {Object.keys(record.errors).length === 0 && (record.child_has_errors !== undefined && record.child_has_errors === false) && (record.recommended_action_label === "Match" || record.recommended_action_label === "Merge") && <span className="data-panel__form__field__success" style={{margin: "0 0 1em"}}><i className="fa-sharp fa-light fa-square-plus" aria-hidden="true"></i>&nbsp;&nbsp;These will be merged into the destination client.</span>}
                            {contacts.sort(sortContact).map((contact, index) => <QuickBooksContactDisplay key={`contact_${index}`} contact={contact} />)}
                        </Fragment>
                    }
                </div>
            </div>
        )
    }
}

export default QuickBooksContactListDisplay;
