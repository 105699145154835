// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Toast-toaster__JfL4U{right:4rem}.Toast-toastBase__iZ1Y0{width:var(--size-full);padding:var(--size-2_5);overflow:hidden;background:var(--surface-secondary);border:1px solid var(--border-primary);border-radius:var(--border-radius-2)}.Toast-headerWrapper__Dh1FF{display:flex;align-items:center;justify-content:space-between;width:var(--size-full)}.Toast-iconAndTitleWrapper___v9on{display:flex;gap:var(--size-2);align-items:center;min-height:var(--size-8);margin-left:var(--size-1)}.Toast-title___RyY1[data-title]{width:-webkit-fill-available;color:var(--gray-12);font:var(--font-global-heading-03);font-feature-settings:var(--feature-settings-global-heading-03);letter-spacing:var(--letter-spacing-global-heading-03)}.Toast-closeButton__yecpR svg[data-icon]{width:var(--size-3_5) !important;height:var(--size-3_5) !important;margin:var(--size-0) !important}.Toast-iconAndTitleWrapper___v9on svg[data-icon]{width:var(--size-4) !important;height:var(--size-4) !important;margin:var(--size-0) !important}.Toast-buttonContainer__xV8IN{display:flex;gap:var(--size-2);align-items:center}.Toast-bodyContainer__d9nif{display:flex;flex-direction:column;gap:var(--size-3);margin-right:var(--size-9);margin-left:var(--size-9)}.Toast-subtitle__aPmLG[data-description]{color:var(--gray-a11);font:var(--font-global-body-375);font-feature-settings:var(--feature-settings-global-body-375);letter-spacing:var(--letter-spacing-global-body-375)}.Toast-ctaContainer__cIecK{display:flex;gap:var(--size-2);align-items:center}.Toast-successIcon__GLnVj{color:var(--jade-9)}.Toast-infoIcon__IuN8N{color:var(--blue-9)}.Toast-warningIcon__OrZoi{color:var(--amber-9)}.Toast-errorIcon__A0g9N{color:var(--tomato-9)}.Toast-normalIcon__RbqrP,.Toast-loadingIcon__xDnvQ{color:var(--gray-12)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toaster": "Toast-toaster__JfL4U",
	"toastBase": "Toast-toastBase__iZ1Y0",
	"headerWrapper": "Toast-headerWrapper__Dh1FF",
	"iconAndTitleWrapper": "Toast-iconAndTitleWrapper___v9on",
	"title": "Toast-title___RyY1",
	"closeButton": "Toast-closeButton__yecpR",
	"buttonContainer": "Toast-buttonContainer__xV8IN",
	"bodyContainer": "Toast-bodyContainer__d9nif",
	"subtitle": "Toast-subtitle__aPmLG",
	"ctaContainer": "Toast-ctaContainer__cIecK",
	"successIcon": "Toast-successIcon__GLnVj",
	"infoIcon": "Toast-infoIcon__IuN8N",
	"warningIcon": "Toast-warningIcon__OrZoi",
	"errorIcon": "Toast-errorIcon__A0g9N",
	"normalIcon": "Toast-normalIcon__RbqrP",
	"loadingIcon": "Toast-loadingIcon__xDnvQ"
};
export default ___CSS_LOADER_EXPORT___;
