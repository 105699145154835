import moment from "moment-timezone"
import { valueIsDefined } from "../../core/utils/utils"

export function validateEquipment(equipment) {
    let isValid = true
    let errors = {}

    if (equipment.display_name === "") {
        isValid = false
        errors.display_name = "Please provide a display name."
    }
    if (equipment.equipment_category === "") {
        isValid = false
        errors.equipment_category = "Please provide an equipment category."
    }
    if (equipment.ownership_type === "") {
        isValid = false
        errors.ownership_type = "Please provide an ownership type."
    }

    if (equipment.equipment_category === "") {
        isValid = false
        errors.equipment_category = "Please provide an equipment category."
    }

    if (equipment.ownership_type === "") {
        isValid = false
        errors.ownership_type = "Please provide an ownership type."
    }

    return { isValid, errors }
}


export function validateEquipmentWarranty(allWarranties, warranty, warrantyIndex) {
    let isValid = true
    let errors = {}

    if (!valueIsDefined(warranty.name)) {
        isValid = false
        errors.name = "Please provide a name."
    }

    if (!valueIsDefined(warranty.start_date)) {
        isValid = false
        errors.start_date = "Please provide a start date."
    }

    if (valueIsDefined(warranty.start_date) && valueIsDefined(warranty.end_date) && warranty.end_date < warranty.start_date) {
        isValid = false
        errors.end_date = "The end date must be on or after the start date."
    }

    const matchingWarranty = allWarranties.find(comparison => warranty.name === comparison.name)
    const matchingIndex = allWarranties.findIndex(comparison => warranty.name === comparison.name)

    if (matchingWarranty && warrantyIndex !== matchingIndex) {
        isValid = false
        errors.name = "Another warranty with this same name already exists on this equipment."
    }

    return { isValid, errors }
}


export function getWarrantyStatus(startDate, endDate) {
    if (startDate !== null && moment(startDate).isAfter(moment(), "day")) {
        return "Upcoming"
    }
    else if (endDate !== null && moment(endDate).isBefore(moment(), "day")) {
        return "Expired"
    }
    else {
        return "Active"
    }
}
