import ClientCreate from "./ClientCreate/ClientCreate"
import ClientDetails from "./ClientDetails/ClientDetails"
import ClientEdit from "./ClientEdit/ClientEdit"
import ClientEquipment from "./ClientEquipment/ClientEquipment"
import ClientList from "./ClientList/ClientList"
import ClientMerge from "./ClientMerge/ClientMerge"
import ClientServiceLocationsMerge from "./ClientServiceLocationsMerge/ClientServiceLocationsMerge"

const List = ClientList
const Create = ClientCreate
const Edit = ClientEdit
const Details = ClientDetails
const Equipment = ClientEquipment
const Merge = ClientMerge
const ServiceLocationsMerge = ClientServiceLocationsMerge

export default {
    List,
    Create,
    Edit,
    Details,
    Equipment,
    Merge,
    ServiceLocationsMerge,
}
