import FirstNameForm from "./FirstNameForm/FirstNameForm"
import LastNameForm from "./LastNameForm/LastNameForm"
import styles from "./UserNamesForms.module.scss"

export default function UserNamesForms() {
    return (
        <div className={styles.base}>
            <FirstNameForm />
            <LastNameForm />
        </div>
    )
}
