import useUser from "@hooks/useUser"

import { CompanyAvatar } from "@molecules"

import styles from "./WorkspaceButton.module.scss"

export default function WorkspaceButton() {
    const { user } = useUser()

    const serviceCompany = user?.service_company

    return (
        <div className={styles.base}>
            <div className={styles.logo}>
                <CompanyAvatar serviceCompany={serviceCompany} />
            </div>
            <div className={styles.name}>{serviceCompany?.name}</div>
        </div>
    )
}
