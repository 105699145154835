import { useContext } from "react"

import { ModalContext } from "./Modal"
import { ModalContextValues } from "./Modal.types"

export default function useModal(): ModalContextValues {
    const context = useContext(ModalContext)
    if (!context) {
        throw new Error("useModal must be used within a Modal component")
    }

    return context
}
