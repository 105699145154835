import { useContext } from "react"

import { AuthContext } from "./Auth"
import { AuthContextValues } from "./Auth.types"

export default function useAuth(): AuthContextValues {
    const context = useContext(AuthContext)
    if (!context) {
        throw new Error("useAuth must be used within a Auth component")
    }

    return context
}
