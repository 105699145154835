import useUser from "@hooks/useUser"
import { formatCurrency } from "../utils/utils"

function PriceBookItemPriceRenderer(props) {
    const { instance } = props
    const { user:currentUser } = useUser()

    if (instance.pricebook_item_type_label === "Tax") {
        return <>{`${parseFloat(instance.default_price)}%`}</>
    }
    else {
        const formattedPrice = formatCurrency(instance.default_price, currentUser.service_company.currency_code, currentUser.service_company.language_code)
        const suffix = instance.default_unit_type_label !== null ? (instance.default_unit_type_label === "Hourly" ? "/hr" : "") : ""
        return <>{`${formattedPrice}${suffix}`}</>
    }
}

export default PriceBookItemPriceRenderer
