// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SideBarSearchButton-base__MtST2{display:flex;gap:var(--size-2);align-items:center;justify-content:space-between;width:var(--size-full);padding:var(--size-1_5) var(--size-2);color:var(--gray-a10);background-color:var(--gray-a3);border:none;border-radius:var(--size-1_5);outline:none;cursor:pointer;transition-duration:var(--duration-fast);transition-property:color,background-color}.SideBarSearchButton-base__MtST2:focus-visible:not(.SideBarSearchButton-isDisabled__NM09S){background-color:var(--gray-a4);outline:var(--size-0_5) solid var(--gray-12);outline-offset:calc(var(--size-0_5)*-1)}.SideBarSearchButton-base__MtST2:hover:not(.SideBarSearchButton-isDisabled__NM09S){background-color:var(--gray-a4)}.SideBarSearchButton-base__MtST2:active:not(.SideBarSearchButton-isDisabled__NM09S){background-color:var(--gray-a5)}.SideBarSearchButton-base__MtST2:focus-visible:not(.SideBarSearchButton-isDisabled__NM09S) .SideBarSearchButton-icon__DlMjs,.SideBarSearchButton-base__MtST2:hover:not(.SideBarSearchButton-isDisabled__NM09S) .SideBarSearchButton-icon__DlMjs,.SideBarSearchButton-base__MtST2:active:not(.SideBarSearchButton-isDisabled__NM09S) .SideBarSearchButton-icon__DlMjs{color:var(--gray-12)}.SideBarSearchButton-base__MtST2.SideBarSearchButton-isDisabled__NM09S{color:var(--gray-a7);cursor:not-allowed}.SideBarSearchButton-iconAndText__YynPU{display:flex;gap:var(--size-2);align-items:center}.SideBarSearchButton-label__Ki3Dx{user-select:none;font:var(--font-global-body-475);font-feature-settings:var(--feature-settings-global-body-475);letter-spacing:var(--letter-spacing-global-body-475)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "SideBarSearchButton-base__MtST2",
	"isDisabled": "SideBarSearchButton-isDisabled__NM09S",
	"icon": "SideBarSearchButton-icon__DlMjs",
	"iconAndText": "SideBarSearchButton-iconAndText__YynPU",
	"label": "SideBarSearchButton-label__Ki3Dx"
};
export default ___CSS_LOADER_EXPORT___;
