import { Component, Fragment } from 'react';
import UniversalButton from '../buttons/UniversalButton';


class ListSelectField extends Component {

    render() {
        const {fieldName, fieldLabel, inputComponent, showButton, buttonLabel, buttonAction, optional=false, optionalText="optional", errors} = this.props

        return (
            <div className="data-panel__form__field" id={`div_id_${fieldName}`} aria-label={fieldLabel}>
                <label htmlFor={`id_${fieldName}`} className="data-panel__form__field__label in-form">{fieldLabel}{optional && <span className="text-optional"> ({optionalText})</span>}</label>
                {errors[fieldName] && <div className="data-panel__form__field__errors" aria-label="Field Errors">{errors[fieldName]}</div>}
                <div className="data-panel__form__field__input data-panel__form__field__input--with-list-select">
                    {inputComponent}
                    {
                        showButton === true && (
                            <UniversalButton type="list_select_action" text={<Fragment><i className="fa-sharp fa-light fa-plus" aria-hidden="true"></i>{buttonLabel}</Fragment>} handler={buttonAction} />
                        )
                    }
                </div>
            </div>
        )
    }
}

export default ListSelectField;
