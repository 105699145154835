import { useAtom } from "jotai"
import { atomWithStorage } from "jotai/utils"
import { useCallback, useEffect } from "react"

import toast from "@molecules/Toast/Toast"
import { ToastProps } from "@molecules/Toast/Toast.types"

import { GLOBAL_TOAST_QUEUE } from "@constants/storage"

import useRouter from "./useRouter"

type QueuedToast = ToastProps & {
    cta?: ToastProps["cta"] & { destination?: string }
    path?: string
    barePathOnly?: boolean // Neccessary to show toasts after a details action submission
    delayRender?: boolean // Neccessary to show toasts after a page reload
}

const toastQueueAtom = atomWithStorage<QueuedToast[]>(GLOBAL_TOAST_QUEUE, [])

export default function useToastQueue() {
    const [toastQueue, setToastQueue] = useAtom(toastQueueAtom)
    const { pathname: currentPath } = useRouter()

    const addToastToQueue = useCallback(
        (queuedToast: QueuedToast, callback?: () => void) => {
            setToastQueue((prevQueue) => {
                queuedToast.barePathOnly = queuedToast.barePathOnly ?? false
                queuedToast.delayRender = queuedToast.delayRender ?? false

                if (!queuedToast.path) {
                    queuedToast.path = currentPath
                }

                return [...prevQueue, queuedToast]
            })
            callback?.()
        },
        [currentPath, setToastQueue],
    )

    function ToastQueueManager() {
        useEffect(() => {
            const nextQueue: QueuedToast[] = []
            let dirty = false

            // Show toasts that are applicable to the current path
            toastQueue.forEach((queuedToast) => {
                const pathmatch =
                    queuedToast.path === currentPath && (!queuedToast.barePathOnly || window.location.search === "")

                if (pathmatch && !queuedToast.delayRender) {
                    if (queuedToast.cta?.destination) {
                        queuedToast.cta.onClick = () => location.assign(queuedToast.cta.destination)
                    }
                    toast(queuedToast)
                    dirty = true
                } else {
                    if (pathmatch && queuedToast.delayRender) {
                        queuedToast.delayRender = false
                        dirty = true
                    }
                    nextQueue.push(queuedToast)
                }
            })

            if (dirty) {
                setToastQueue(nextQueue)
            }
        }, [currentPath, toastQueue, setToastQueue])

        return null
    }

    return { addToastToQueue, ToastQueueManager }
}
