// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SelectListItem-base__qZ4Xz{display:flex;gap:var(--size-2);align-items:center;width:var(--size-full);padding:var(--size-2) var(--size-2_5);background-color:var(--white);border:0;outline:0;outline-offset:calc(var(--size-0_25)*-1);cursor:pointer;transition:var(--duration-fast);transition-property:background-color,color}.SelectListItem-base__qZ4Xz:hover:not(:disabled){background:var(--gray-a3)}.SelectListItem-base__qZ4Xz:active:not(:disabled){background-color:var(--gray-a4)}.SelectListItem-base__qZ4Xz:focus-visible:not(:disabled):not([data-state=selected]){background-color:var(--gray-a3);outline-width:var(--size-0_25);outline-color:var(--gray-12)}.SelectListItem-base__qZ4Xz:focus-visible:not(:disabled):not([data-state=selected]):hover{background-color:var(--white)}.SelectListItem-base__qZ4Xz.SelectListItem-selected__HyBHM,.SelectListItem-base__qZ4Xz[data-state=selected]:not(:disabled){background:var(--gray-a4)}.SelectListItem-base__qZ4Xz:disabled{opacity:.3;cursor:not-allowed}.SelectListItem-iconAndText__nDD0h{display:flex;flex:1 0 0;gap:var(--size-0_5);align-items:center;cursor:pointer}.SelectListItem-checkmark__CaGiz,.SelectListItem-icon__bXXS6{color:var(--gray-12)}.SelectListItem-text__TCOWv{padding-left:var(--size-1);color:var(--gray-12);font:var(--font-global-body-475);font-feature-settings:var(--feature-settings-global-body-475);letter-spacing:var(--letter-spacing-global-body-475)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "SelectListItem-base__qZ4Xz",
	"selected": "SelectListItem-selected__HyBHM",
	"iconAndText": "SelectListItem-iconAndText__nDD0h",
	"checkmark": "SelectListItem-checkmark__CaGiz",
	"icon": "SelectListItem-icon__bXXS6",
	"text": "SelectListItem-text__TCOWv"
};
export default ___CSS_LOADER_EXPORT___;
