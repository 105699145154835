import React, { Component, Fragment } from "react";
import ButtonGroup from "../../../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../../../core/buttons/UniversalButton";
import QuickBooksServiceLocationRecordCard from "../QuickBooksServiceLocationRecordCard";
import { serviceLocationAddressASC } from "../utils/sorters";

class RecordServiceLocationListEditSelect extends Component {

    constructor(props) {
        super(props)

        this.state = {
            showAllServiceLocations: false,
        }
    }

    toggleShowAllServiceLocations = () => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState.showAllServiceLocations = !state.showAllServiceLocations
            return updatedState
        })
    }

    renderServiceLocation = (serviceLocationRecord, index) => {
        return <QuickBooksServiceLocationRecordCard key={`service_location_${serviceLocationRecord.id}`} serviceLocationRecord={serviceLocationRecord} index={index} />
    }

    renderServiceLocationsWithoutErrors(serviceLocations) {
        const serviceLocationsCount = serviceLocations.length

        if (this.state.showAllServiceLocations || serviceLocationsCount <= 10) {
            return serviceLocations.sort(serviceLocationAddressASC).map(this.renderServiceLocation)
        }
        else {
            return (
                <ButtonGroup>
                    <ButtonGroupRow>
                        <UniversalButton type="secondary" text={`Show ${serviceLocationsCount} Service Locations`} handler={event => this.toggleShowAllServiceLocations()} />
                    </ButtonGroupRow>
                </ButtonGroup>
            )
        }
    }

    renderServiceLocations = (records, errorsExist) => {
        if (errorsExist === false) {
            return this.renderServiceLocationsWithoutErrors(records)
        }
        else {
            let recordsWithErrors = []
            let recordsWithoutErrors = []

            // Partition records by whether or not they have errors
            let existingIDs = new Set()
            records.forEach(serviceLocationRecord => {
                if (!existingIDs.has(serviceLocationRecord.id)) {
                    if (Object.keys(serviceLocationRecord.errors).length !== 0) {
                        recordsWithErrors.push(serviceLocationRecord)
                    }
                    else {
                        recordsWithoutErrors.push(serviceLocationRecord)
                    }

                    existingIDs.add(serviceLocationRecord.id)
                }
            })

            return (
                <Fragment>
                    {recordsWithErrors.sort(serviceLocationAddressASC).map(this.renderServiceLocation)}
                    {this.renderServiceLocationsWithoutErrors(recordsWithoutErrors)}
                </Fragment>
            )
        }
    }

    render = () => {
        const {fieldName, records, errorsExist} = this.props

        return (
            <div id={`id_${fieldName}`} className="data-panel__list-select">
                {this.renderServiceLocations(records, errorsExist)}
            </div>
        )
    }
}

export default RecordServiceLocationListEditSelect;
