import { JobRoutes } from "./routes.types"

export const JOBS_ROUTES: JobRoutes = {
    CREATE: "/{service_company_slug}/jobs/create/",
    LIST: "/{service_company_slug}/jobs/",
    COUNT: "/{service_company_slug}/api/jobs/count/",
    LIGHTWEIGHT_LIST: "/{service_company_slug}/api/jobs/lightweight/",
    UPDATE: "/{service_company_slug}/jobs/{id}/update/",
    DETAILS: "/{service_company_slug}/jobs/{id}/",
    COMPLETE: "/{service_company_slug}/jobs/{id}/complete/",
}
