import useUser from "@hooks/useUser";
import Spinner from "@legacy/core/components/Spinner";
import DashboardCard from "./components/DashboardCard";
import DashboardCardColumn from "./components/DashboardCardColumn";
import LatestActivityCard from "./components/LatestActivityCard";
import RecommendedActionsCard from "./components/RecommendedActionsCard";
import RevenuePotentialCard from "./components/RevenuePotentialCard";
import WorkStatusCard from "./components/WorkStatusCard";


export default function DashboardContainer() {
    const { user:currentUser, isLoading } = useUser()

    if (isLoading) {
        return (
            <div className="card-column-container dashboard" aria-label="Dashboard">
                <Spinner />
            </div>
        )
    } else {
        return (
            <div className="card-column-container dashboard" aria-label="Dashboard">
                <DashboardCardColumn title="Actions" predicate={currentUser.user_type_label === "Service Dispatcher"}>
                    <DashboardCard title="Urgent Actions" predicate={currentUser.user_type_label === "Service Dispatcher"} pageSize={4}>
                        <RecommendedActionsCard />
                    </DashboardCard>
                    <DashboardCard title="Latest Activity" predicate={currentUser.user_type_label === "Service Dispatcher"} pageSize={5}>
                        <LatestActivityCard />
                    </DashboardCard>
                </DashboardCardColumn>
                <DashboardCardColumn title="Work Status">
                    <DashboardCard title="Estimates" predicate={currentUser.user_type_label === "Service Dispatcher"}>
                        <WorkStatusCard />
                    </DashboardCard>
                    <DashboardCard title="Jobs" predicate={true}>
                        <WorkStatusCard />
                    </DashboardCard>
                    <DashboardCard title="Invoices" predicate={currentUser.user_type_label === "Service Dispatcher"}>
                        <WorkStatusCard />
                    </DashboardCard>
                    <DashboardCard title="Purchase Orders" predicate={currentUser.user_type_label === "Service Dispatcher"}>
                        <WorkStatusCard />
                    </DashboardCard>
                </DashboardCardColumn>
                <DashboardCardColumn title="Performance Metrics" predicate={currentUser.user_type_label === "Service Dispatcher" && !currentUser.hide_financial_context}>
                    <DashboardCard title="Revenue Potential" predicate={currentUser.user_type_label === "Service Dispatcher"} pageSize={5}>
                        <RevenuePotentialCard />
                    </DashboardCard>
                </DashboardCardColumn>
            </div>
        )
    }
}
