import EventAccordion from "../../core/components/EventAccordion"
import { valueIsDefined } from "../../core/utils/utils"


class PriceBookItemFeedAccordion extends EventAccordion {

    fetchData = async () => {
        const { priceBookItemID } = this.props

        if (valueIsDefined(priceBookItemID)) {
            const endpoint = DjangoUrls["pricebook:api-pricebookitem-feed-list"](window.MARKETPLACE_ENTITY_SLUG, priceBookItemID)
            const response = await fetch(endpoint)
            return await response.json()
        }
        else {
            return null
        }
    }

    getTitle = () => {
        return "Inventory Timeline"
    }

    getEmphasis = () => {
        return "time"
    }
}

export default PriceBookItemFeedAccordion;
