// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TableEdgeStates-base__Cnxj6{position:relative;margin-bottom:var(--bottom-bar-height);overflow:auto}.TableEdgeStates-content__uut3i{position:relative;display:flex;flex-direction:column;gap:var(--size-6);align-items:center}.TableEdgeStates-copyWrap__xosT0{display:flex;flex-direction:column;gap:var(--size-4);align-items:center}.TableEdgeStates-copy__FXxCd{display:flex;flex-direction:column;gap:var(--size-1);align-items:center}.TableEdgeStates-title__jqRw8{margin-bottom:0;color:var(--gray-12);font:var(--font-global-heading-01);font-feature-settings:var(--feature-settings-global-heading-01);letter-spacing:var(--letter-spacing-global-heading-01)}.TableEdgeStates-explanation__zj6so{margin-bottom:0;color:var(--gray-11);font:var(--font-global-body-400);font-feature-settings:var(--feature-settings-global-body-400);letter-spacing:var(--letter-spacing-global-body-400)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "TableEdgeStates-base__Cnxj6",
	"content": "TableEdgeStates-content__uut3i",
	"copyWrap": "TableEdgeStates-copyWrap__xosT0",
	"copy": "TableEdgeStates-copy__FXxCd",
	"title": "TableEdgeStates-title__jqRw8",
	"explanation": "TableEdgeStates-explanation__zj6so"
};
export default ___CSS_LOADER_EXPORT___;
