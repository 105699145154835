// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BottomSheet-backdrop__FS1MW{z-index:var(--z-index-backdrop);background-color:var(--pure-black-a9)}.BottomSheet-dragIndicatorWrap__RfGQb{display:none}.BottomSheet-window__rHCvJ{z-index:var(--z-index-bottom-sheet);padding-bottom:env(safe-area-inset-bottom);overflow:hidden;background-color:var(--white);border:var(--size-0_25) solid var(--gray-3);border-bottom:0;border-radius:var(--border-radius-3) var(--border-radius-3) var(--border-radius-0) var(--border-radius-0)}.BottomSheet-content__kdjrv{overflow:visible;transition:var(--duration-slow);transition-property:height}.BottomSheet-fullHeight__e0K2I{height:var(--size-full)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backdrop": "BottomSheet-backdrop__FS1MW",
	"dragIndicatorWrap": "BottomSheet-dragIndicatorWrap__RfGQb",
	"window": "BottomSheet-window__rHCvJ",
	"content": "BottomSheet-content__kdjrv",
	"fullHeight": "BottomSheet-fullHeight__e0K2I"
};
export default ___CSS_LOADER_EXPORT___;
