import Spinner from "@legacy/core/components/Spinner";
import { Component, Fragment } from "react";
import ButtonGroup from "../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../core/buttons/UniversalButton";
import ContactListDisplay from "../../core/components/ContactListDisplay";
import BasicDisplayField from "../../core/fields/BasicDisplayField";
import { renderClientString, renderServiceLocationString } from "../utils/utils";


class ServiceLocationMergeConfirmDisplay extends Component {

    componentDidMount() {
        // Return to the previous scroll
        document.querySelector(".main").scrollTo(0, this.props.returnScroll || 0)
    }

    renderButtons = () => {
        const { mode, submitting, errors, client, sourceServiceLocation, destinationServiceLocation, requestAction, switchToPrimaryForm, returnScroll} = this.props

        if (errors.unexpectedError) {
            return (
                <div className="data-panel__action-feedback">
                    <span className="text-invalid"><strong>An unexpected error occurred.</strong></span>
                </div>
            )
        }
        else {
            if (submitting) {
                return (
                    <div className="data-panel__action-feedback">
                        <Spinner centered={true} />
                    </div>
                )
            }
            else {
                return (
                    <ButtonGroup>
                        <ButtonGroupRow>
                            <UniversalButton type="primary" text="Confirm Merge" handler={event => requestAction("MERGE")} />
                        </ButtonGroupRow>
                        <ButtonGroupRow>
                            <UniversalButton type="secondary" text="Change Locations" handler={event => switchToPrimaryForm()} />
                        </ButtonGroupRow>
                    </ButtonGroup>
                )
            }
        }
    }

    renderMergeDetails = (destinationServiceLocation) => {
        let detailUrl = DjangoUrls["clients:clients-detail"](window.MARKETPLACE_ENTITY_SLUG, this.props.client.id)

        return <Fragment>{"Merging with "}<a className="text-link text-link--inline" href={detailUrl} target="_blank">{renderServiceLocationString(destinationServiceLocation)}&nbsp;&nbsp;<i className="fa-sharp fa-light fa-arrow-up-right-from-square" aria-hidden="true"></i></a></Fragment>
    }

    renderMatchFieldText = (source, destination, fieldName) => {
        let sourceValue = source[fieldName]
        let destinationValue = destination[fieldName]

        if (sourceValue !== "" && destinationValue === "") {
            return <span className="data-panel__form__field__success"><i className="fa-sharp fa-light fa-square-plus" aria-hidden="true"></i>&nbsp;&nbsp;This will be added to the destination service location.</span>
        }
        else if (sourceValue !== destinationValue) {
            return <span className="data-panel__form__field__warning"><i className="fa-sharp fa-light fa-triangle-exclamation" aria-hidden="true"></i>&nbsp;&nbsp;This does not match the destination service location and will be ignored.</span>
        }
    }

    render() {
        const { mode, submitting, errors, client, sourceServiceLocation, destinationServiceLocation, requestAction, switchToPrimaryForm, returnScroll} = this.props

        return (
            <div className="data-panel-container data-panel-container--with-margin" style={{width: "100%"}}>
                <div className="data-panel" aria-label="Service Location">
                    <div className="data-panel__heading" aria-label="Client/Service Location Information">
                        <span className="data-panel__heading__title" aria-label="Name">{renderClientString(client)}</span>
                        <span className="data-panel__heading__subtitle" aria-label="Display">{renderServiceLocationString(sourceServiceLocation)}</span>
                        <hr aria-hidden="true" />
                    </div>
                    <div className="data-panel__form" aria-label="Service Location Merge Display">
                        <BasicDisplayField
                            fieldName="merge_details"
                            fieldLabel="Merge Details"
                            fieldValue={this.renderMergeDetails(destinationServiceLocation)}
                        ></BasicDisplayField>
                        <BasicDisplayField
                            fieldName="name"
                            fieldLabel="Location Name"
                            fieldValue={sourceServiceLocation.name || "--"}
                            footer={this.renderMatchFieldText(sourceServiceLocation, destinationServiceLocation, "name")}
                        ></BasicDisplayField>
                        <BasicDisplayField
                            fieldName="physical_address"
                            fieldLabel="Location Address"
                            fieldValue={sourceServiceLocation.physical_address_formatted_multiline || "--"}
                            footer={this.renderMatchFieldText(sourceServiceLocation, destinationServiceLocation, "physical_address_formatted")}
                        ></BasicDisplayField>
                        <BasicDisplayField
                            fieldName="billing_address"
                            fieldLabel="Location Billing Address"
                            fieldValue={sourceServiceLocation.billing_address_formatted_multiline || "--"}
                            footer={this.renderMatchFieldText(sourceServiceLocation, destinationServiceLocation, "billing_address_formatted")}
                        ></BasicDisplayField>
                        <BasicDisplayField
                            fieldName="notes"
                            fieldLabel="Gate Codes, Hazards, etc."
                            fieldValue={sourceServiceLocation.notes || "--"}
                            footer={this.renderMatchFieldText(sourceServiceLocation, destinationServiceLocation, "notes")}
                        ></BasicDisplayField>
                        <ContactListDisplay
                            fieldName="contacts"
                            fieldLabel="Location Contacts"
                            contacts={sourceServiceLocation.contacts}
                            primaryContactId={sourceServiceLocation.primary_contact}
                            header={<span className="data-panel__form__field__success" style={{margin: "0 0 1em"}}><i className="fa-sharp fa-light fa-square-plus" aria-hidden="true"></i>&nbsp;&nbsp;These will be merged into the destination client.</span>}
                        ></ContactListDisplay>
                    </div>
                    {this.renderButtons()}
                </div>
            </div>
        )
    }
}

export default ServiceLocationMergeConfirmDisplay;
