// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AvatarManagement-base__gyQtb{position:relative;display:flex;flex-direction:column;gap:var(--size-3);align-items:center;justify-content:center;width:var(--size-full);margin-top:var(--size-7_5);padding:var(--size-20) var(--size-6) var(--size-6) var(--size-6);background:var(--gray-a3);border-radius:var(--border-radius-2_5)}.AvatarManagement-buttons__eneMl{display:flex;gap:var(--size-3);align-items:center;justify-content:center}.AvatarManagement-text__KwbIS{color:var(--gray-a11);text-align:center;font:var(--font-mobile-caption-md-375);font-feature-settings:var(--feature-settings-mobile-caption-md-375);letter-spacing:var(--letter-spacing-mobile-caption-md-375)}.AvatarManagement-textHighlight__LdgII{color:var(--gray-12);font:var(--font-mobile-caption-md-475);font-feature-settings:var(--feature-settings-mobile-caption-md-475);letter-spacing:var(--letter-spacing-mobile-caption-md-475)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "AvatarManagement-base__gyQtb",
	"buttons": "AvatarManagement-buttons__eneMl",
	"text": "AvatarManagement-text__KwbIS",
	"textHighlight": "AvatarManagement-textHighlight__LdgII"
};
export default ___CSS_LOADER_EXPORT___;
