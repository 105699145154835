import JobDetailsUpdateContainer from "@legacy/jobs/JobDetailsUpdateContainer"

import useIsDesktop from "@hooks/useIsDesktop"
import useSlugExtractor from "@hooks/useSlugExtractor"
import useToastQueue from "@hooks/useToastQueue"

import { TopBar } from "@organisms"

import { AppFrame } from "@templates"

import { JOBS_ROUTES } from "@routes/jobs"

export default function JobDetailsEdit() {
    const isDesktop = useIsDesktop()

    const jobId = window.JOB_ID
    const { addToastToQueue } = useToastQueue()

    const [listRoute, detailsRoute] = useSlugExtractor([JOBS_ROUTES.LIST, JOBS_ROUTES.DETAILS], jobId)

    return (
        <AppFrame.Root pageId="JobDetailsEdit">
            <TopBar.Root>
                <TopBar.Header>
                    <TopBar.TopBarHeaderStart>
                        <TopBar.PageTitle iconOnly={!isDesktop} iconName="jobs" label="Jobs" destination={listRoute} />
                        <TopBar.VerticalDivider />
                        <TopBar.PageSubtitle label="Job Details" backDestination={detailsRoute} />
                    </TopBar.TopBarHeaderStart>
                </TopBar.Header>
            </TopBar.Root>
            <AppFrame.PageContent>
                <JobDetailsUpdateContainer addToastToQueue={addToastToQueue} />
            </AppFrame.PageContent>
        </AppFrame.Root>
    )
}
