import * as Yup from "yup"

import useIsDesktop from "@hooks/useIsDesktop"

import { AlertBanner, Button, NavigationLink } from "@atoms/index"

import { EmailInput } from "@molecules/Form/Form"
import GenericForm from "@molecules/Form/GenericForm/GenericForm"
import { MutationSuccessFunctionParams } from "@molecules/Form/GenericForm/GenericForm.types"

import {
    EmailCodeRequestPayload,
    EmailCodeRequestResponse,
} from "@organisms/SecurityCheckDialog/SecurityCheckDialog.types"

import styles from "@pages/Auth/Auth.module.scss"
import useAuth from "@pages/Auth/Auth.store"

import { AUTH_ENDPOINTS } from "@endpoints/auth"

export type EmailSchema = {
    email_address: User["email"]
}

export const emailSchema: Yup.ObjectSchema<EmailSchema> = Yup.object().shape({
    email_address: Yup.string().required("Please enter an email address").email("Please enter a valid email address"),
})

export default function AuthEmailCodeForm() {
    const {
        authMethod,
        isOnOTPSlide,
        setIsOnOTPSlide,
        setAuthMethod,
        setEmailData,
        emailData,
        isWaitingForCodeInput,
        setIsWaitingForCodeInput,
    } = useAuth()

    const isDesktop = useIsDesktop()

    const isActive = authMethod === "email_and_code"

    const onMutationSuccess = (args: MutationSuccessFunctionParams<EmailSchema, EmailCodeRequestResponse>) => {
        setEmailData({
            email: args.formValues.email_address,
            emailId: args.data.email_id,
        })

        setIsWaitingForCodeInput(true)
        setIsOnOTPSlide(true)
    }

    const onGetLoginCodeClick = () => {
        if (isWaitingForCodeInput) {
            setIsOnOTPSlide(true)
        }
    }

    const onRequestPasswordInsteadClick = () => {
        void setAuthMethod("email_and_password")
    }

    const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmailData({
            email: event.target.value,
            emailId: null,
        })
    }

    return (
        <GenericForm<EmailSchema, EmailCodeRequestResponse, Error, EmailCodeRequestPayload>
            formConfig={{
                defaultValues: {
                    email_address: emailData?.email ? emailData?.email : "",
                },
            }}
            formValidationSchema={emailSchema}
            mutationConfig={{
                method: "POST",
                endpoint: AUTH_ENDPOINTS.OTP.EMAIL_REQUEST,
                genericErrorMessage: "Could not request code.",
                disabled: isWaitingForCodeInput,
            }}
            triggerFormReset={!isActive}
            onMutationSuccess={onMutationSuccess}
            className={styles.form}
        >
            {({ isPending, nonFieldError }) => (
                <>
                    {nonFieldError && (
                        <AlertBanner size={isDesktop ? "sm" : "md"} title={nonFieldError} type="error" />
                    )}
                    <EmailInput<EmailSchema>
                        name="email_address"
                        label="Email address"
                        placeholder="email@mail.com"
                        isTabbable={isActive && !isOnOTPSlide}
                        size={isDesktop ? "md" : "lg"}
                        onChangeCapture={onEmailChange}
                    />
                    <NavigationLink
                        onClick={onRequestPasswordInsteadClick}
                        isUnderLine={true}
                        className={styles.link}
                        isTabbable={isActive && !isOnOTPSlide}
                        title="Email and password form"
                        data-prevent-input-validation={true}
                    >
                        {() => "Use password instead"}
                    </NavigationLink>
                    <Button
                        type="submit"
                        colorScheme="gray"
                        size={isDesktop ? "md" : "lg"}
                        variant="solid"
                        isFullWidth={true}
                        isTabbable={isActive && !isOnOTPSlide}
                        isLoading={isPending}
                        onClick={onGetLoginCodeClick}
                    >
                        Get login code
                    </Button>
                </>
            )}
        </GenericForm>
    )
}
