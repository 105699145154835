import * as DialogPrimitive from "@radix-ui/react-dialog"

import useModal from "@molecules/Modal/Modal.store"

import { ModalTriggerProps } from "./ModalTrigger.types"

export default function ModalTrigger(props: ModalTriggerProps) {
    const { children, ...rest } = props

    const { openModal } = useModal()

    return (
        <DialogPrimitive.Trigger {...rest} onClick={openModal}>
            {children}
        </DialogPrimitive.Trigger>
    )
}
