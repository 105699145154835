import NotificationConfigPage from "./NotificationConfigPage/NotificationConfigPage"
import PageBreadcrumbs from "./PageBreadcrumbs/PageBreadcrumbs"
import PageContainer from "./PageContainer/PageContainer"
import PageDivider from "./PageDivider/PageDivider"
import PageHeader from "./PageHeader/PageHeader"
import PageSection from "./PageSection/PageSection"
import SettingsWrapper from "./Wrapper/SettingsWrapper"

export default {
    PageContainer,
    PageHeader,
    Wrapper: SettingsWrapper,
    Section: PageSection,
    NotificationConfigPage,
    Divider: PageDivider,
    Breadcrumbs: PageBreadcrumbs,
}
