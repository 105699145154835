import formatDuration from "@utils/formatDuration";
import { Component } from "react";
import { formatLocalTime, tintColor } from "../../core/utils/utils";
import { getJobStatusOrSubStatusLabel } from "../../jobs/utils/utils";


class CalendarEvent extends Component {

    render = () => {
        const event = this.props.event
        const resource = event.assigned_technicians.find(technician => technician.id == event.technician_id)

        const title = [
            `${event.__str__}`,
            `${event.client}`,
            `${event.location}\n`,
            `ID: ${event.custom_id ? event.custom_id : event.id}`,
            `Status: ${getJobStatusOrSubStatusLabel(event)}`,
            `Estimated Arrival Time: ${formatLocalTime(event.estimated_arrival_time, this.props.preferredTimezone, false, false, false, false).localDateTimeString}`,
            `Estimated Duration: ${formatDuration(event.estimated_duration)}`,
            `Tech(s): ${resource.short_name}${event.assigned_technicians.length > 1 ? ` and ${event.assigned_technicians.length - 1} other${event.assigned_technicians.length - 1 !== 1 ? "s" : ""}` : ""}`,
        ]

        return (
            <div title={title.join("\n")} className={`calendar-event${event.all_day ? " calendar-event--all-day" : ""}`} style={{"--calendar-event-color": resource.color, "--calendar-event-color-background": tintColor(resource.color, 0.8), "--calendar-event-duration-multiplier": Math.min(event.estimated_duration / 3600, 1),  "--calendar-event-duration-height": `${Math.min(event.estimated_duration / 3600, 1) * 100}%`}}>
                <div className="calendar-event-body-container">
                    <div className="calendar-event-body">
                        <span className="calendar-event-body-title">{event.service_name} ({getJobStatusOrSubStatusLabel(event)})</span>
                        <span className="calendar-event-body-description">{event.client}<br />{event.location}</span>
                    </div>
                </div>
            </div>
        )
    }
}

export default CalendarEvent;
