import { ColumnId } from "@organisms/Table/Table.types"

import { sortingEnabledByColumnId } from "@constants/mappings/table/sortingEnabledByColumnId.mapping"

import getColumnNameByColumnId from "./getColumnNameByColumnId"

export default function getSortingEnabledColumnsByIds(columnIds: ColumnId[], objectType: ObjectType) {
    if (columnIds?.length === 0 || !columnIds) {
        return []
    }

    return columnIds
        .map((columnId) => {
            const columnName = getColumnNameByColumnId(columnId, objectType)
            if (sortingEnabledByColumnId[columnId]) {
                return {
                    value: columnId,
                    label: columnName,
                }
            }
        })
        .filter(Boolean)
}
