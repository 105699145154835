import React, { Component } from "react";
import deepcopy from "rfdc";
import { format_phone_number_with_extension } from "../../../../core/utils/utils";
import QuickBooksContactRecordCard from "../QuickBooksContactRecordCard";

class RecordContactListEditSelect extends Component {

    renderContact = (contact, index) => {
        let errors = deepcopy()(contact.errors || {})

        const nameValue = errors.name ? contact.name : (contact.cleaned_name ? contact.cleaned_name : "--")
        const phoneValue = errors.phone ? (contact.cleaned_phone ? `${contact.cleaned_phone}${contact.cleaned_phone_extension ? " x" : ""}${contact.cleaned_phone_extension}` : contact.phone) : (contact.cleaned_phone ? format_phone_number_with_extension(contact.cleaned_phone, contact.cleaned_phone_extension) : "--")
        const emailValue = errors.email ? (contact.cleaned_email ? contact.cleaned_email : contact.email) : (contact.cleaned_email ? contact.cleaned_email : "--")

        const contactString = `${nameValue !== "--" ? nameValue : "(No Name)"}: ${phoneValue}, ${emailValue}`

        return <QuickBooksContactRecordCard key={`contact_${contactString}_${contact.primary || false}_${contact.ignored || false}`} contact={contact} updateContact={this.updateContact(index)} />
    }

    updateContact = (index) => {
        const { contacts, updateContacts } = this.props

        return (contact) => {
            let newContacts = deepcopy()(contacts)

            if (contact.primary) {
                for (let newContact of newContacts) {
                    newContact.primary = false
                }
            }

            newContacts[index] = contact
            updateContacts(newContacts)
        }
    }

    render = () => {
        const { fieldName, contacts, updateContacts } = this.props

        return (
            <div id={`id_${fieldName}`} className="data-panel__list-select">
                {contacts.map(this.renderContact)}
            </div>
        )
    }
}

export default RecordContactListEditSelect;
