import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';

function Spinner(props) {
    const { centered = false } = props

    return <span className={clsx("spinner", "fa-2x", {"spinner-centered": centered})} aria-label="Loading..."><FontAwesomeIcon icon={icon({name: "spinner-third", style: "light", family: "sharp"})} spin={true} className="spin-fast" /></span>
}

export default Spinner
