// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MobileHeader-base__fdwGl{position:sticky;top:var(--size-0);z-index:var(--z-index-bottom-sheet);display:flex;flex-direction:column;width:var(--size-full);padding:var(--size-0) var(--size-1)}.MobileHeader-base__fdwGl.MobileHeader-withDivider__nZ7xt::after{position:absolute;right:var(--size-0);bottom:var(--size-0);left:var(--size-0);width:var(--size-full);height:var(--size-0_25);background-color:var(--gray-4);content:\"\"}.MobileHeader-innerContainer__SZtf8{display:flex;align-items:center;justify-content:space-between;width:var(--size-full);height:var(--size-14)}.MobileHeader-saveButtonSpace__HtfVO{width:var(--size-9);height:var(--size-9)}.MobileHeader-headingAndCounter__YYoHJ{display:flex;flex:1;gap:var(--size-1_5);align-items:center;justify-content:center;color:var(--gray-12);text-align:center}.MobileHeader-iconAndTitle__KCT26{display:flex;gap:var(--size-0_25);align-items:center;font:var(--font-mobile-heading-03);font-feature-settings:var(--feature-settings-mobile-heading-03);letter-spacing:var(--letter-spacing-mobile-heading-03)}.MobileHeader-counter__w8BZT{font-weight:575}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "MobileHeader-base__fdwGl",
	"withDivider": "MobileHeader-withDivider__nZ7xt",
	"innerContainer": "MobileHeader-innerContainer__SZtf8",
	"saveButtonSpace": "MobileHeader-saveButtonSpace__HtfVO",
	"headingAndCounter": "MobileHeader-headingAndCounter__YYoHJ",
	"iconAndTitle": "MobileHeader-iconAndTitle__KCT26",
	"counter": "MobileHeader-counter__w8BZT"
};
export default ___CSS_LOADER_EXPORT___;
