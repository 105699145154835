// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PopoverAddColumns-noColumns__js17R{display:flex;align-items:center;justify-content:center;height:var(--size-16)}.PopoverAddColumns-noColumnsText__xUP75{color:var(--gray-11);font:var(--font-global-caption-medium-375);font-feature-settings:var(--feature-settings-global-caption-medium-375);letter-spacing:var(--letter-spacing-global-caption-medium-375)}.PopoverAddColumns-searchInput__a8ESN{padding:var(--size-1_5)}.PopoverAddColumns-items__IDAK0{padding:var(--size-0) var(--size-1_5) var(--size-1_5) var(--size-1_5)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noColumns": "PopoverAddColumns-noColumns__js17R",
	"noColumnsText": "PopoverAddColumns-noColumnsText__xUP75",
	"searchInput": "PopoverAddColumns-searchInput__a8ESN",
	"items": "PopoverAddColumns-items__IDAK0"
};
export default ___CSS_LOADER_EXPORT___;
