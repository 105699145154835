import Spinner from '@legacy/core/components/Spinner';
import { Component } from 'react';
import EventFeedItem from "../../core/components/EventFeedItem";
import { valueIsDefined } from '../../core/utils/utils';


class EquipmentJobHistory extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: null,
        }
    }

    fetchData = async () => {
        const { equipmentID } = this.props

        if (valueIsDefined(equipmentID)) {
            const endpoint = DjangoUrls["equipment:api-equipment-job-history-list"](window.MARKETPLACE_ENTITY_SLUG, equipmentID)
            const response = await fetch(endpoint)
            return await response.json()
        }
        else {
            return null
        }
    }

    componentDidMount = async () => {
        if (this.state.data === null) {
            const data = await this.fetchData()
            this.setState({data})
        }
    }

    renderJobHistory = () => {
        if (this.state.data === null) {
            return (
                <div className="job-history-display__text">
                    <Spinner />
                </div>
            )
        }
        else {
            if (this.state.data.length !== 0) {
                return (
                    <ul aria-label="Job History List">
                        {this.state.data.map((event, index) => <EventFeedItem key={index} event={event} emphasis="date" />)}
                    </ul>
                )
            }
            else {
                return (
                    <div className="job-history-display__text">
                        <span>No job history found.</span>
                    </div>
                )
            }
        }
    }

    render() {
        return (
            <div className="job-history-display">
                <div className="section-separator">Job History</div>
                <div>{this.renderJobHistory()}</div>
            </div>
        )
    }
}

export default EquipmentJobHistory;
