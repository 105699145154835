import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"

import { Button, ListInfoGlyph } from "@atoms"

import styles from "@organisms/Table/TableEdgeStates/TableEdgeStates.module.scss"

import { CardListNoResultsStateProps } from "./CardListNoResultsState.types"

export default function CardListNoResultsState(props: CardListNoResultsStateProps) {
    const { tabContentName } = props

    const explanation = `There are no ${tabContentName
        .toLowerCase()
        .replace("all", "")
        .replace("&", "or")} to display.`

    return (
        <div className={styles.base}>
            <div className={styles.content}>
                <div className={styles.copyWrap}>
                    <ListInfoGlyph variant="black" icon={icon({ name: "eye", style: "solid", family: "sharp" })} />
                    <div className={styles.copy}>
                        <h2 className={styles.title}>No results found</h2>
                        <p className={styles.explanation}>{explanation}</p>
                    </div>
                </div>

                <Button
                    colorScheme="gray"
                    size="md"
                    variant="outline"
                    onClick={() => {
                        const openChangelog = new CustomEvent("clear-list-search", {})
                        window.dispatchEvent(openChangelog)
                    }}
                    noDebounce={true}
                >
                    Clear search
                </Button>
            </div>
        </div>
    )
}
