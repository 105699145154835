import useIsDesktop from "@hooks/useIsDesktop"
import useRouter from "@hooks/useRouter"
import useSlugExtractor from "@hooks/useSlugExtractor"

import { MobileHeader } from "@molecules/index"

import { TopBar } from "@organisms"

import { AppFrame } from "@templates"

import { MENU_ROUTE } from "@routes/menu"
import { SETTINGS_ROUTES } from "@routes/settings"

import { PROFILE_BOTTOM_SHEET_OPEN } from "@constants/searchParams"

import { SettingsWrapperProps } from "./SettingsWrapper.types"

export default function SettingsWrapper(props: SettingsWrapperProps) {
    const { children, title, backDestination } = props

    const isDesktop = useIsDesktop()

    const router = useRouter()

    const [menuRoute] = useSlugExtractor([MENU_ROUTE])

    const onMobileHeaderBackButtonClick = () => {
        if (router.pathname === SETTINGS_ROUTES.main) {
            router.push(`${menuRoute}?${PROFILE_BOTTOM_SHEET_OPEN}=open`)
        } else {
            router.push(backDestination || SETTINGS_ROUTES.main)
        }
    }

    return (
        <AppFrame.Root pageId="Main" navigationType="settings">
            {!isDesktop && (
                <TopBar.Root>
                    <TopBar.Header>
                        <MobileHeader
                            title={title}
                            showBackButton={true}
                            onBackClick={onMobileHeaderBackButtonClick}
                            showDivider={false}
                        />
                    </TopBar.Header>
                </TopBar.Root>
            )}
            <AppFrame.PageContent>{children}</AppFrame.PageContent>
        </AppFrame.Root>
    )
}
