import dayjs from "dayjs"
import "dayjs/locale/en"
import { useEffect, useState } from "react"

import useUser from "@hooks/useUser"

import { SelectListItem } from "@molecules/Select/SelectListItem/SelectListItem.types"
import { Select } from "@molecules/index"

import styles from "./AppStartOfWeekSelector.module.scss"

export default function AppStartOfWeekSelector() {
    const { user } = useUser()

    const [selectedStartOfWeek, setSelectedStartOfWeek] = useState<SelectListItem>()

    useEffect(() => {
        if (user?.service_company.start_of_week) {
            const dayOfWeek = dayjs().day(user?.service_company.start_of_week).format("dddd")

            setSelectedStartOfWeek({
                value: dayOfWeek,
                label: dayOfWeek,
            })
        }
    }, [user])

    return (
        <Select.Root>
            <Select.Input
                text={selectedStartOfWeek?.label}
                placeholder="StartOfWeek"
                isDisabled={true}
                className={styles.button}
                size="md"
            />
        </Select.Root>
    )
}
