import { BryntumGrid } from "@bryntum/schedulerpro-react"
import clsx from "clsx"
import { useEffect } from "react"

import styles from "./JobTimelineView.module.scss"
import { gridConfig } from "./JobTimelineViewConfig/JobTimelineViewConfig"
import UnscheduledJobsColumnMessage from "./UnscheduledJobsColumnMessage/UnscheduledJobsColumnMessage"
import useJobTimelineViewBryntumInstances from "./hooks/useJobTimelineViewBryntumInstances"
import useJobTimelineViewData from "./hooks/useJobTimelineViewData"
import useJobTimelineViewGridProps from "./hooks/useJobTimelineViewGridProps"
import useJobTimelineViewStates from "./hooks/useJobTimelineViewStates"
import useTimelineViewUnscheduledJobsUtils from "./hooks/useTimelineViewUnscheduledJobsUtils"

export default function JobTimelineViewUnscheduledJobs() {
    const { unscheduledJobsColumnStatus, unscheduledJobsColumnMessageStatus, hideUnscheduledJobsColumnMessage } =
        useJobTimelineViewStates()

    const { manageUnscheduledJobsProcess, initializeUnscheduledJobsGrid } = useTimelineViewUnscheduledJobsUtils()

    const { desktopProps } = useJobTimelineViewGridProps()

    const { unscheduledJobsGrid, schedulerPro } = useJobTimelineViewBryntumInstances()

    const { unscheduledJobsData } = useJobTimelineViewData()

    useEffect(() => {
        const hasUnscheduledJobsData = unscheduledJobsData?.pages?.length > 0

        if (hasUnscheduledJobsData) {
            manageUnscheduledJobsProcess()
        }
    }, [unscheduledJobsData?.pages])

    useEffect(() => {
        // Initializes the drag and drop functionality for the Grid -> Scheduler.
        const scheduler = schedulerPro.current?.instance

        if (scheduler) {
            initializeUnscheduledJobsGrid()
        }
    }, [schedulerPro.current?.instance])

    return (
        <div
            className={clsx(styles.unscheduledJobs, "unscheduled-jobs-grid", {
                [styles.unscheduledJobsCollapsed]: unscheduledJobsColumnStatus === "collapsed",
            })}
        >
            {unscheduledJobsColumnMessageStatus === "initial" && (
                <div className={styles.unscheduledJobsMessage}>
                    <UnscheduledJobsColumnMessage onButtonClick={hideUnscheduledJobsColumnMessage} showButton={true} />
                </div>
            )}
            <BryntumGrid {...gridConfig} ref={unscheduledJobsGrid} {...desktopProps} />
        </div>
    )
}
