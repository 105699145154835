// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CardListLoaderCard-base__HO0C9{display:flex;flex-direction:row;align-items:center;justify-content:center;padding:var(--size-4);background:var(--gray-2)}.CardListLoaderCard-body__eYxLS{display:flex;flex-direction:column}.CardListLoaderCard-icon__jIVWh{margin-right:1.125em}.CardListLoaderCard-contentContainer__poM_B{display:flex;flex-direction:row;align-items:center;margin-right:.25em}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "CardListLoaderCard-base__HO0C9",
	"body": "CardListLoaderCard-body__eYxLS",
	"icon": "CardListLoaderCard-icon__jIVWh",
	"contentContainer": "CardListLoaderCard-contentContainer__poM_B"
};
export default ___CSS_LOADER_EXPORT___;
