import { ColumnId, ColumnVisibility, PinningState } from "@organisms/Table/Table.types"

interface GetSortingEnabledColumnsByIdsArgs {
    columnIds: ColumnId[]
    columnVisibility: ColumnVisibility
    columnPinning?: PinningState
}

type VisibleColumnsMap = {
    pinnedColumns: ColumnId[]
    unpinnedColumns: ColumnId[]
    allColumns: ColumnId[]
}

export default function mapTableVisibleColumns(args: GetSortingEnabledColumnsByIdsArgs): VisibleColumnsMap {
    const { columnIds, columnVisibility, columnPinning } = args

    if (columnIds?.length === 0 || !columnIds) {
        return {
            pinnedColumns: [],
            unpinnedColumns: [],
            allColumns: [],
        }
    }

    const pinnedColumns: ColumnId[] = columnPinning?.left
    const unpinnedColumns: ColumnId[] = []

    columnIds.forEach((columnId) => {
        if (columnVisibility[columnId] === false) {
            return null
        }

        if (!columnPinning?.left?.includes(columnId)) {
            unpinnedColumns.push(columnId)
        }
    })

    return {
        pinnedColumns,
        unpinnedColumns,
        allColumns: pinnedColumns.concat(unpinnedColumns),
    }
}
