import { useRef } from "react"

import useAuth from "@pages/Auth/Auth.store"

import styles from "./AuthSlides.module.scss"
import { AuthSlidesProps } from "./AuthSlides.types"

export default function AuthSlides(props: AuthSlidesProps) {
    const { children } = props

    const contentRef = useRef<HTMLDivElement>(null)

    const { contentHeight } = useAuth()

    return (
        <div className={styles.base} style={{ height: contentHeight }} ref={contentRef}>
            {children}
        </div>
    )
}
