import { TimeZoneHelper } from "@bryntum/schedulerpro"
import { atom, useAtom } from "jotai"
import { atomWithStorage } from "jotai/utils"

import useUser from "@hooks/useUser"

import extractURLSearchParam from "@utils/extractURLSearchParam"
import isValidDate from "@utils/isValidDate"

import {
    CollapsibleSchedulerColumnStates,
    JobTimelineViewOrientation,
    JobTimelineViewStyle,
} from "@organisms/ObjectsView/JobTimelineView/JobTimelineView.types"

import { TIMELINE_VIEW_PARAM_CURRENT_DATE } from "@constants/searchParams"
import {
    JOBS_LIST_TIMELINE_VIEW_CARD_STYLE,
    JOBS_LIST_TIMELINE_VIEW_CURRENT_DATE_KEY,
    JOBS_LIST_TIMELINE_VIEW_ORIENTATION,
    JOBS_LIST_TIMELINE_VIEW_SHOULD_SHOW_CANCELLED_JOBS,
    JOBS_LIST_TIMELINE_VIEW_SHOULD_SHOW_FINALIZED_JOBS,
    JOBS_LIST_TIMELINE_VIEW_TECHNICIANS_COLUMN_STATUS_KEY,
    JOBS_LIST_TIMELINE_VIEW_UNSCHEDULED_JOBS_COLUMN_MESSAGE_STATUS_KEY,
    JOBS_LIST_TIMELINE_VIEW_UNSCHEDULED_JOBS_COLUMN_STATUS_KEY,
} from "@constants/storage"

const jobCardStyleAtom = atomWithStorage<JobTimelineViewStyle>(
    JOBS_LIST_TIMELINE_VIEW_CARD_STYLE,
    "regular",
    undefined,
    {
        getOnInit: true,
    },
)
const shouldShowCancelledJobsAtom = atomWithStorage<boolean>(
    JOBS_LIST_TIMELINE_VIEW_SHOULD_SHOW_CANCELLED_JOBS,
    false,
    undefined,
    {
        getOnInit: true,
    },
)
const shouldShowFinalizedJobsAtom = atomWithStorage<boolean>(
    JOBS_LIST_TIMELINE_VIEW_SHOULD_SHOW_FINALIZED_JOBS,
    false,
    undefined,
    {
        getOnInit: true,
    },
)
const timelineViewOrientationAtom = atomWithStorage<JobTimelineViewOrientation>(
    JOBS_LIST_TIMELINE_VIEW_ORIENTATION,
    "horizontal",
    undefined,
    {
        getOnInit: true,
    },
)
const technicianColumnStatusAtom = atomWithStorage<CollapsibleSchedulerColumnStates>(
    JOBS_LIST_TIMELINE_VIEW_TECHNICIANS_COLUMN_STATUS_KEY,
    "collapsed",
    undefined,
    {
        getOnInit: true,
    },
)
const unscheduledJobsColumnStatusAtom = atomWithStorage<CollapsibleSchedulerColumnStates>(
    JOBS_LIST_TIMELINE_VIEW_UNSCHEDULED_JOBS_COLUMN_STATUS_KEY,
    "collapsed",
    undefined,
    {
        getOnInit: true,
    },
)
const unscheduledJobsColumnMessageStatusAtom = atomWithStorage<"initial" | "shown" | "hidden">(
    JOBS_LIST_TIMELINE_VIEW_UNSCHEDULED_JOBS_COLUMN_MESSAGE_STATUS_KEY,
    "initial",
    undefined,
    {
        getOnInit: true,
    },
)

const currentDateOverride = extractURLSearchParam(TIMELINE_VIEW_PARAM_CURRENT_DATE)

const currentDateAtom = atomWithStorage<Date>(
    JOBS_LIST_TIMELINE_VIEW_CURRENT_DATE_KEY,
    isValidDate(currentDateOverride) ? new Date(currentDateOverride) : new Date(),
    undefined,
    {
        getOnInit: true,
    },
)

const totalOfUnscheduledJobsAtom = atom<number>(0)
const limitOfItemsPerGridFetchAtom = atom<number>(0)

const isSchedulerConfiguredAtom = atom<boolean>(false)

const allowShowMouseMoveFeedbackAtom = atom<boolean>(true)

export default function useJobTimelineViewStates() {
    const { user } = useUser()

    const [jobCardStyle, setJobCardStyle] = useAtom(jobCardStyleAtom)

    const [shouldShowCancelledJobs, setShouldShowCancelledJobs] = useAtom(shouldShowCancelledJobsAtom)
    const [shouldShowFinalizedJobs, setShouldShowFinalizedJobs] = useAtom(shouldShowFinalizedJobsAtom)
    const [timelineViewOrientation, setTimelineViewOrientation] = useAtom(timelineViewOrientationAtom)
    const [techniciansColumnStatus, setTechniciansColumnStatus] = useAtom(technicianColumnStatusAtom)
    const [unscheduledJobsColumnStatus, setUnscheduledColumnStatus] = useAtom(unscheduledJobsColumnStatusAtom)
    const [unscheduledJobsColumnMessageStatus, setUnscheduledColumnMessageStatus] = useAtom(
        unscheduledJobsColumnMessageStatusAtom,
    )
    const [totalOfUnscheduledJobs, setTotalOfUnscheduledJobs] = useAtom(totalOfUnscheduledJobsAtom)
    const [limitOfItemsPerGridFetch, setLimitOfItemsPerGridFetch] = useAtom(limitOfItemsPerGridFetchAtom)

    const [currentDate, setCurrentDate] = useAtom(currentDateAtom)
    const [isSchedulerConfigured, setIsSchedulerConfigured] = useAtom(isSchedulerConfiguredAtom)
    const [allowShowMouseMoveFeedback, setAllowShowMouseMoveFeedback] = useAtom(allowShowMouseMoveFeedbackAtom)

    const setJobCardStyleToRegular = () => {
        void setJobCardStyle("regular")
    }
    const setJobCardStyleToCompact = () => {
        void setJobCardStyle("compact")
    }

    const toggleCancelledJobs = () => {
        void setShouldShowCancelledJobs((prev) => !prev)
    }

    const toggleFinalizedJobs = () => {
        void setShouldShowFinalizedJobs((prev) => !prev)
    }

    const setTimelineViewOrientationToHorizontal = () => {
        void setTimelineViewOrientation("horizontal")
    }
    const setTimelineViewOrientationToVertical = () => {
        void setTimelineViewOrientation("vertical")
    }

    const showUnscheduledJobsColumnMessage = () => {
        void setUnscheduledColumnMessageStatus("shown")
    }

    const hideUnscheduledJobsColumnMessage = () => {
        void setUnscheduledColumnMessageStatus("hidden")
    }

    const collapseUnscheduledJobsColumn = () => {
        void setUnscheduledColumnStatus("collapsed")
    }

    const expandUnscheduledJobsColumn = () => {
        void setUnscheduledColumnStatus("expanded")
    }

    const todayInUserTimezone = TimeZoneHelper.toTimeZone(new Date(), user?.service_company?.preferred_timezone)

    return {
        currentDate: new Date(isValidDate(currentDate) ? currentDate : todayInUserTimezone),
        todayInUserTimezone,
        jobCardStyle,
        shouldShowCancelledJobs,
        shouldShowFinalizedJobs,
        timelineViewOrientation,
        techniciansColumnStatus,
        unscheduledJobsColumnStatus,
        unscheduledJobsColumnMessageStatus,
        totalOfUnscheduledJobs,
        limitOfItemsPerGridFetch,
        isSchedulerConfigured,
        setCurrentDate,
        setLimitOfItemsPerGridFetch,
        setTotalOfUnscheduledJobs,
        toggleCancelledJobs,
        toggleFinalizedJobs,
        setJobCardStyleToCompact,
        setJobCardStyleToRegular,
        setTechniciansColumnStatus,
        expandUnscheduledJobsColumn,
        collapseUnscheduledJobsColumn,
        showUnscheduledJobsColumnMessage,
        hideUnscheduledJobsColumnMessage,
        setTimelineViewOrientationToHorizontal,
        setTimelineViewOrientationToVertical,
        setIsSchedulerConfigured,
        allowShowMouseMoveFeedback,
        setAllowShowMouseMoveFeedback,
    }
}
