import Spinner from '@legacy/core/components/Spinner';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Component } from "react";
import deepcopy from "rfdc";
import ContactForm from "../clients/forms/ContactForm";
import { PriceBookItemTypes } from '../core/utils/enums';
import { historyHasState, sendDataToServer, validateSendContact, valueIsDefined } from "../core/utils/utils";
import EstimateDetailsCard from "./components/EstimateDetailsCard";
import EstimateApproveForm from "./forms/EstimateApproveForm";
import EstimateCancelForm from "./forms/EstimateCancelForm";
import EstimateDeclineForm from "./forms/EstimateDeclineForm";
import EstimateOnlinePaymentForm from "./forms/EstimateOnlinePaymentForm";
import EstimatePaymentForm from "./forms/EstimatePaymentForm";
import EstimateSendForm from "./forms/EstimateSendForm";
import { calculateEstimateAmounts } from "./utils/utils";

const PAGE_MODES = {
    VIEW_ESTIMATE: "VIEW_ESTIMATE",
    SEND_ESTIMATE: "SEND_ESTIMATE",
    APPROVE_ESTIMATE: "APPROVE_ESTIMATE",
    DECLINE_ESTIMATE: "DECLINE_ESTIMATE",
    CANCEL_ESTIMATE: "CANCEL_ESTIMATE",
    ADD_PAYMENT: "ADD_PAYMENT",
    EDIT_PAYMENT: "EDIT_PAYMENT",
    PAY_ONLINE: "PAY_ONLINE",
    ADD_CONTACT: "ADD_CONTACT",
    EDIT_CONTACT: "EDIT_CONTACT",
}

const PAGE_MODE_SUBTITLES = {
    VIEW_ESTIMATE: "Estimate Details",
    SEND_ESTIMATE: "Send Estimate",
    APPROVE_ESTIMATE: "Mark Estimate as Approved",
    DECLINE_ESTIMATE: "Mark Estimate as Declined",
    CANCEL_ESTIMATE: "Cancel Estimate",
    ADD_PAYMENT: "Add Payment",
    EDIT_PAYMENT: "Edit Payment",
    PAY_ONLINE: "Pay Estimate Online",
    ADD_CONTACT: "Add Contact",
    EDIT_CONTACT: "Edit Contact",
}

const PAGE_MODE_BACK_BUTTON_DISPLAY = {
    VIEW_ESTIMATE: "flex",
    SEND_ESTIMATE: "none",
    APPROVE_ESTIMATE: "none",
    DECLINE_ESTIMATE: "none",
    CANCEL_ESTIMATE: "none",
    ADD_PAYMENT: "none",
    EDIT_PAYMENT: "none",
    PAY_ONLINE: "none",
    ADD_CONTACT: "none",
    EDIT_CONTACT: "none",
}

const PRIMARY_PAGE_MODES = [PAGE_MODES.VIEW_ESTIMATE]
const SECONDARY_PAGE_MODES = [
    PAGE_MODES.SEND_ESTIMATE,
    PAGE_MODES.APPROVE_ESTIMATE,
    PAGE_MODES.DECLINE_ESTIMATE,
    PAGE_MODES.CANCEL_ESTIMATE,
    PAGE_MODES.ADD_PAYMENT,
    PAGE_MODES.EDIT_PAYMENT,
    PAGE_MODES.PAY_ONLINE,
    PAGE_MODES.ADD_CONTACT,
    PAGE_MODES.EDIT_CONTACT,
]

const PAYMENT_PAGE_MODES = [
    PAGE_MODES.ADD_PAYMENT,
    PAGE_MODES.EDIT_PAYMENT,
]

const CONTACT_PAGE_MODES = [
    PAGE_MODES.ADD_CONTACT,
    PAGE_MODES.EDIT_CONTACT,
]

const FORM_DATA_NAMES_BY_MODE = {
    VIEW_ESTIMATE: "estimateData",
    SEND_ESTIMATE: "sendData",
    APPROVE_ESTIMATE: "approveData",
    DECLINE_ESTIMATE: "declineData",
    CANCEL_ESTIMATE: "cancelData",
    ADD_PAYMENT: "paymentData",
    EDIT_PAYMENT: "paymentData",
    PAY_ONLINE: "paymentData",
    ADD_CONTACT: "contactData",
    EDIT_CONTACT: "contactData",
}

const SUBMITTING_NAMES_BY_MODE = {
    VIEW_ESTIMATE: "submittingEstimate",
    SEND_ESTIMATE: "submittingSend",
    APPROVE_ESTIMATE: "submittingApprove",
    DECLINE_ESTIMATE: "submittingDecline",
    CANCEL_ESTIMATE: "submittingCancel",
    ADD_PAYMENT: "submittingPayment",
    EDIT_PAYMENT: "submittingPayment",
    PAY_ONLINE: "submittingPayment",
    ADD_CONTACT: "submittingContact",
    EDIT_CONTACT: "submittingContact",
}

const ERROR_NAMES_BY_MODE = {
    VIEW_ESTIMATE: "estimate",
    SEND_ESTIMATE: "send",
    APPROVE_ESTIMATE: "approve",
    DECLINE_ESTIMATE: "decline",
    CANCEL_ESTIMATE: "cancel",
    ADD_PAYMENT: "payment",
    EDIT_PAYMENT: "payment",
    PAY_ONLINE: "payment",
    ADD_CONTACT: "contact",
    EDIT_CONTACT: "contact",
}


class EstimateDetailsContainer extends Component {

    // Initialize

    constructor(props) {
        super(props)

        const defaultMode = this.props.formMode || PAGE_MODES.VIEW_ESTIMATE
        this.addToastToQueue = this.props.addToastToQueue
        this.paymentMethodOptions = window.PAYMENT_METHODS

        this.stripePromise = loadStripe(window.STRIPE_PUBLISHABLE_KEY)
        this.defaultClientMessage = window.CURRENT_USER.service_company?.estimate_default_client_message

        this.state = {
            estimateData: null,
            estimateChildJobsData: null,
            estimateChildInvoicesData: null,

            sendData: {},
            paymentData: {},
            approveData: {},
            declineData: {},
            cancelData: {},
            contactData: {},

            selectedSendPhoneContacts: [],
            selectedSendEmailContacts: [],
            selectedOnlinePaymentRecipient: null,

            errors: {
                estimate: {},
                send: {},
                payment: {},
                approve: {},
                decline: {},
                cancel: {},
                contact: {},
            },

            defaultMode: defaultMode,
            mode: defaultMode,

            sendViaSMS: false,
            sendViaEmail: false,
            sendManually: false,

            returnScroll: 0,
        }

        window.onpopstate = (event) => {
            if (event.state !== null && Object.keys(event.state).length) {
                this.setState(event.state)
            }
        }
    }

    componentDidMount = async () => {
        if (this.state.estimateData === null) {
            let estimateEndpoint

            if (window.USING_PUBLIC_URL === true) {
                estimateEndpoint = window.PUBLIC_REST_URL
            }
            else {
                estimateEndpoint = DjangoUrls["estimates:api-estimates-detail"](window.MARKETPLACE_ENTITY_SLUG, window.ESTIMATE_ID)
            }

            const estimateResponse = await fetch(estimateEndpoint)
            const estimate = await estimateResponse.json()

            this.setState((state, props) => {
                let updatedState = state
                updatedState.estimateData = estimate

                // Split line items into their respective lists
                updatedState.estimateData.service_charges = estimate.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.service)
                updatedState.estimateData.parts = estimate.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.part)
                updatedState.estimateData.other_charges = estimate.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.other)
                updatedState.estimateData.discounts = estimate.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.discount)

                return updatedState
            })

            if (window.USER_TYPE === "SERVICE_DISPATCHER" && estimate !== null && this.state.estimateChildJobsData === null && !(window.USING_PUBLIC_URL === true)) {
                let estimateChildJobs = []

                let estimateChildJobEndpoint
                let estimateChildJobResponse
                let estimateChildJobResponseJSON = {
                    next: `${DjangoUrls["jobs:api-jobs-list"](window.MARKETPLACE_ENTITY_SLUG)}?estimate=${estimate.id}`,
                    previous: null,
                    results: [],
                }

                while (estimateChildJobResponseJSON.next !== null) {
                    estimateChildJobEndpoint = estimateChildJobResponseJSON.next
                    estimateChildJobResponse = await fetch(estimateChildJobEndpoint)
                    estimateChildJobResponseJSON = await estimateChildJobResponse.json()
                    estimateChildJobs.push(...estimateChildJobResponseJSON.results)
                }

                this.setState((state, props) => {
                    let updatedState = state
                    updatedState.estimateChildJobsData = estimateChildJobs
                    return updatedState
                })
            }

            if (window.USER_TYPE === "SERVICE_DISPATCHER" && estimate !== null && this.state.estimateChildInvoicesData === null && !(window.USING_PUBLIC_URL === true)) {
                let estimateChildInvoices = []

                let estimateChildInvoicesEndpoint
                let estimateChildInvoicesResponse
                let estimateChildInvoicesResponseJSON = {
                    next: `${DjangoUrls["invoices:api-invoices-list"](window.MARKETPLACE_ENTITY_SLUG)}?estimate=${estimate.id}`,
                    previous: null,
                    results: [],
                }

                while (estimateChildInvoicesResponseJSON.next !== null) {
                    estimateChildInvoicesEndpoint = estimateChildInvoicesResponseJSON.next
                    estimateChildInvoicesResponse = await fetch(estimateChildInvoicesEndpoint)
                    estimateChildInvoicesResponseJSON = await estimateChildInvoicesResponse.json()
                    estimateChildInvoices.push(...estimateChildInvoicesResponseJSON.results)
                }

                this.setState((state, props) => {
                    let updatedState = state
                    updatedState.estimateChildInvoicesData = estimateChildInvoices
                    return updatedState
                })
            }
        }


        if (historyHasState(history)) {
            if (document.querySelector(".page-subtitle")) {
                document.querySelector(".page-subtitle").innerHTML = PAGE_MODE_SUBTITLES[history.state.mode]
            }
            if (document.querySelector(".back-button")) {
                document.querySelector(".back-button").style.display = PAGE_MODE_BACK_BUTTON_DISPLAY[history.state.mode]
            }
            this.setState(history.state)
        }
        else {
            // If the client asks for the send estimate page directly, oblidge
            const desiredMode = new URLSearchParams(document.location.search).get("mode") || ""
            if (desiredMode === "send-estimate") {
                this.handleActionRequest("ESTIMATE_SEND")
            }
            else if (desiredMode === "pay-online") {
                this.handleActionRequest("ESTIMATE_PAY_ONLINE")
            }
        }
    }

    // Form helpers

    updateFormData = (formName, fieldName, fieldValue, then=null) => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState[formName][fieldName] = fieldValue
            return updatedState
        }, then)
    }

    switchFormMode = (mode) => {
        if (document.querySelector(".page-subtitle")) {
            document.querySelector(".page-subtitle").innerHTML = PAGE_MODE_SUBTITLES[mode]
        }
        if (document.querySelector(".back-button")) {
            document.querySelector(".back-button").style.display = PAGE_MODE_BACK_BUTTON_DISPLAY[mode]
        }

        if (SECONDARY_PAGE_MODES.includes(mode)) {
            history.replaceState(this.state, "", "")
        }

        this.setState((state, props) => {
            let updatedState = state
            updatedState.mode = mode
            history.pushState(updatedState, "", "?mode=" + mode.toLowerCase().replace(/_/g, "-"));
            return updatedState
        })
    }

    switchToPrimaryForm = () => {
        this.setState((state, props) => {
            let updatedState = state

            // Clear the secondary form data
            updatedState[FORM_DATA_NAMES_BY_MODE[state.mode]] = {}
            updatedState[SUBMITTING_NAMES_BY_MODE[state.mode]] = false
            updatedState.errors[ERROR_NAMES_BY_MODE[state.mode]] = {}

            return updatedState
        })
        this.switchFormMode(this.state.defaultMode)
    }

    switchToSecondaryForm = (newFormMode, data, initialData) => {
        this.setState((state, props) => {
            let updatedState = state
            // Set the scroll state
            updatedState.returnScroll = document.querySelector(".main").scrollTop

            updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]] = {}

            if (data !== null) {
                updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]] = deepcopy()(data)
            }
            else {
                if (newFormMode === PAGE_MODES.ADD_CONTACT) {
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].attached_to = "external_client"
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].is_ephemeral = false
                }
                else if (newFormMode === PAGE_MODES.SEND_ESTIMATE && this.defaultClientMessage) {
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].client_message = this.defaultClientMessage
                }
            }

            if (initialData !== null) {
                Object.assign(updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]], initialData)
            }

            return updatedState
        })

        this.switchFormMode(newFormMode)
    }

    updateSendPhoneSelection = (selectedContacts) => {
        this.setState((state, props) => {
            let updatedState = state

            updatedState.selectedSendPhoneContacts = selectedContacts
            updatedState.sendData.phone_contacts = selectedContacts

            return updatedState
        })
    }

    updateSendEmailSelection = (selectedContacts) => {
        this.setState((state, props) => {
            let updatedState = state

            updatedState.selectedSendEmailContacts = selectedContacts
            updatedState.sendData.email_contacts = selectedContacts

            return updatedState
        })
    }

    updateOnlinePaymentRecipientSelection = (selectedContact) => {
        this.setState((state, props) => {
            let updatedState = state

            updatedState.selectedOnlinePaymentRecipient = selectedContact

            return updatedState
        })
    }

    // Create Estimate

    createEstimate = async () => {
        const dataName = "estimateData"
        const submittingName = "submittingEstimate"
        const errorDictName = "estimate"
        const endpoint = DjangoUrls["estimates:api-estimates-list"](window.MARKETPLACE_ENTITY_SLUG)
        const endpointMethod = "POST"

        const dataManipulator = (data, state) => {
            let preparedData = deepcopy()(data)
            preparedData.is_draft = false

            // Convert job walk to ID
            if (data.job_walk !== null) {
                preparedData.job_walk = data.job_walk.id
            }

            // Convert service company to slug
            preparedData.service_company = data.service_company.slug

            // Convert service location to ID
            preparedData.service_location = data.service_location.id

            return preparedData
        }

        const onSuccess = (estimate) => {
            const successUrl = DjangoUrls["estimates:estimates-detail"](window.MARKETPLACE_ENTITY_SLUG, estimate.id) + "?mode=send-estimate"
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Estimate "${estimate.custom_id || estimate.id}" created`,
                path: successUrl.split("?")[0],
                barePathOnly: true,
                delayRender: true,
            })

            history.replaceState({}, "", "")
            location.assign(successUrl)
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Estimate could not be created",
            })
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, dataManipulator, undefined)
    }

    // Send data

    submitSendData = async () => {
        const endpoint = DjangoUrls["estimates:api-estimates-send"](window.MARKETPLACE_ENTITY_SLUG, this.state.estimateData.id)
        const endpointMethod = "POST"
        const successUrl = DjangoUrls["estimates:estimates-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.estimateData.id)

        const dataName = "sendData"
        const submittingName = "submittingSend"
        const errorDictName = "send"

        const onSuccess = () => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Estimate "${this.state.estimateData.custom_id || this.state.estimateData.id}" sent`,
                path: successUrl.split("?")[0],
                barePathOnly: true,
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }

        const dataManipulator = (data, state) => {
            data.send_via_sms = state.sendViaSMS
            data.send_via_email = state.sendViaEmail
            data.send_manually = state.sendManually
            data.phone_contacts = data.phone_contacts || []
            data.email_contacts = data.email_contacts || []

            return data
        }

        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Estimate could not be sent",
            })
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, dataManipulator, undefined)
    }

    // Approve data

    submitApproveData = async () => {
        const endpoint = DjangoUrls["estimates:api-estimates-approve"](window.MARKETPLACE_ENTITY_SLUG, this.state.estimateData.id)
        const endpointMethod = "POST"
        const successUrl = DjangoUrls["estimates:estimates-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.estimateData.id)

        this.sendApproveData(endpoint, endpointMethod, successUrl)
    }

    submitApproveDataPublic = async () => {
        const { downPaymentAmountDue } = calculateEstimateAmounts(this.state.estimateData)

        const sendToPayment = (
            this.state.estimateData.service_company.accept_online_payments &&
            this.state.estimateData.service_company.online_payments_configured &&
            this.state.estimateData.accept_online_payments &&
            downPaymentAmountDue.toFixed(2) > 0.50
        )

        const endpoint = window.PUBLIC_APPROVE_URL
        const successUrl = window.PUBLIC_URL + (sendToPayment ? "?mode=pay-online" : "")
        const endpointMethod = "POST"

        this.sendApproveData(endpoint, endpointMethod, successUrl)
    }

    sendApproveData = async (endpoint, endpointMethod, successUrl) => {
        const dataName = "approveData"
        const submittingName = "submittingApproveData"
        const errorDictName = "approve"

        const onSuccess = () => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Estimate "${this.state.estimateData.custom_id || this.state.estimateData.id}" approved`,
                path: successUrl.split("?")[0],
                barePathOnly: true,
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Estimate could not be approved",
            })
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, undefined, undefined)
    }

    // Decline data

    submitDeclineData = async () => {
        const endpoint = DjangoUrls["estimates:api-estimates-decline"](window.MARKETPLACE_ENTITY_SLUG, this.state.estimateData.id)
        const endpointMethod = "POST"
        const successUrl = DjangoUrls["estimates:estimates-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.estimateData.id)

        this.sendDeclineData(endpoint, endpointMethod, successUrl)
    }

    submitDeclineDataPublic = async () => {
        const endpoint = window.PUBLIC_DECLINE_URL
        const successUrl = window.PUBLIC_URL
        const endpointMethod = "POST"

        this.sendDeclineData(endpoint, endpointMethod, successUrl)
    }

    sendDeclineData = async (endpoint, endpointMethod, successUrl) => {
        const dataName = "declineData"
        const submittingName = "submittingDeclineData"
        const errorDictName = "decline"

        const onSuccess = () => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Estimate "${this.state.estimateData.custom_id || this.state.estimateData.id}" declined`,
                path: successUrl.split("?")[0],
                barePathOnly: true,
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Estimate could not be declined",
            })
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, undefined, undefined)
    }

    // Cancel data

    submitCancelData = async () => {
        const endpoint = DjangoUrls["estimates:api-estimates-cancel"](window.MARKETPLACE_ENTITY_SLUG, this.state.estimateData.id)
        const endpointMethod = "POST"
        const successUrl = DjangoUrls["estimates:estimates-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.estimateData.id)

        const dataName = "cancelData"
        const submittingName = "submittingCancelData"
        const errorDictName = "cancel"

        const onSuccess = (json) => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Estimate "${this.state.estimateData.custom_id || this.state.estimateData.id}" cancelled`,
                path: successUrl.split("?")[0],
                barePathOnly: true,
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Estimate could not be cancelled",
            })
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, undefined, undefined)
    }

    // Crud payment

    createPayment = async () => {
        const endpoint = DjangoUrls["estimates:api-estimates-payments-list"](window.MARKETPLACE_ENTITY_SLUG, this.state.estimateData.id)
        const successUrl = DjangoUrls["estimates:estimates-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.estimateData.id)
        this.CUDPayment(endpoint, "POST", successUrl, "created")
    }

    updatePayment = async (paymentID) => {
        const endpoint = DjangoUrls["estimates:api-estimates-payments-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.estimateData.id, paymentID)
        const successUrl = DjangoUrls["estimates:estimates-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.estimateData.id)
        this.CUDPayment(endpoint, "PUT", successUrl, "updated")
    }

    deletePayment = async (paymentID) => {
        const endpoint = DjangoUrls["estimates:api-estimates-payments-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.estimateData.id, paymentID)
        const successUrl = DjangoUrls["estimates:estimates-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.estimateData.id)
        this.CUDPayment(endpoint, "DELETE", successUrl, "deleted")
    }

    createOnlinePayment = async () => {
        let endpoint
        let successUrl

        if (window.USING_PUBLIC_URL) {
            endpoint = window.PUBLIC_PAYMENTS_URL
            successUrl = window.PUBLIC_URL
        }
        else {
            endpoint = window.PUBLIC_PAYMENTS_URL
            successUrl = DjangoUrls["estimates:estimates-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.estimateData.id)
        }
        // Online payments are paid in full
        this.CUDPayment(endpoint, "POST", successUrl, "paid")
    }

    CUDPayment = async (endpoint, endpointMethod, successUrl, toastTitleSuffix) => {
        const dataName = "paymentData"
        const submittingName = "submittingPayment"
        const errorDictName = "payment"

        const onSuccess = (json) => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Estimate down payment ${toastTitleSuffix}`,
                path: successUrl.split("?")[0],
                barePathOnly: true,
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: `Estimate down payment could not be ${toastTitleSuffix}`,
            })
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, undefined, undefined)
    }

    // Crud Contact

    createContact = async () => {
        const attachedTo = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]].attached_to
        const populationRef = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]].populationRef

        const contactData = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]]

        const { isValid, errors } = validateSendContact(
            contactData,
            [populationRef === "updateSendPhoneSelection" ? "phone" : "email"]
        )

        if (isValid) {
            let endpoint

            if (attachedTo === "external_client") {
                endpoint = DjangoUrls["clients:api-clients-contacts-list"](window.MARKETPLACE_ENTITY_SLUG, this.state.estimateData.service_location.external_client.id)
            }
            else {
                endpoint = DjangoUrls["clients:api-clients-service-locations-contacts-list"](window.MARKETPLACE_ENTITY_SLUG, this.state.estimateData.service_location.external_client.id, this.state.estimateData.service_location.id)
            }

            const endpointMethod = "POST"

            const onSuccess = (contact) => {
                let destinationMode = null
                let destinationData = null

                // Set the new contact in state and then grab that state to use in a new form
                if (populationRef === "updateSendPhoneSelection") {
                    destinationMode = PAGE_MODES.SEND_ESTIMATE
                    destinationData = deepcopy()(this.state.sendData)

                    if (valueIsDefined(contact.phone)) {
                        const newContacts = [...this.state.selectedSendPhoneContacts, contact]
                        destinationData.phone_contacts = newContacts
                        this[populationRef](newContacts)
                    }
                }
                else if (populationRef === "updateSendEmailSelection") {
                    destinationMode = PAGE_MODES.SEND_ESTIMATE
                    destinationData = deepcopy()(this.state.sendData)

                    if (valueIsDefined(contact.email)) {
                        const newContacts = [...this.state.selectedSendEmailContacts, contact]
                        destinationData.email_contacts = newContacts
                        this[populationRef](newContacts)
                    }
                }
                else if (populationRef === "updateOnlinePaymentRecipientSelection") {
                    destinationMode = PAGE_MODES.ADD_PAYMENT
                    destinationData = deepcopy()(this.state.paymentData)

                    if (valueIsDefined(contact.email)) {
                        this[populationRef](contact)
                    }
                }

                this.switchToPrimaryForm()
                this.switchToSecondaryForm(destinationMode, destinationData, null)
                this.addToastToQueue({
                    type: "success",
                    size: contact.is_ephemeral ? "lg" : "md",
                    title: `Contact ${contact.is_ephemeral ? "added" : "created"}`,
                    subtitle: `This contact will not be saved to the contact list`
                })
            }
            const onError = () => {
                this.addToastToQueue({
                    type: "error",
                    size: "md",
                    title: `Contact could not be ${contactData.is_ephemeral ? "added" : "created"}`,
                })
            }

            this.createUpdateContact(endpoint, endpointMethod, onSuccess, onError)
        }
        else {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: `Contact could not be ${contactData.is_ephemeral ? "added" : "created"}`,
            })
            this.setState((state, props) => {
                let updatedState = state
                updatedState.errors[ERROR_NAMES_BY_MODE[state.mode]] = errors
                return updatedState
            })
        }
    }

    updateContact = async () => {
        const attachedTo = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]].attached_to
        const populationRef = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]].populationRef
        const dataRef = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]].dataRef

        const { isValid, errors } = validateSendContact(
            this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]],
            [populationRef === "updateSendPhoneSelection" ? "phone" : "email"]
        )

        if (isValid) {
            let endpoint

            if (attachedTo === "external_client") {
                endpoint = DjangoUrls["clients:api-clients-contacts-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.estimateData.service_location.external_client.id, this.state[dataRef].id)
            }
            else {
                endpoint = DjangoUrls["clients:api-clients-service-locations-contacts-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.estimateData.service_location.external_client.id, this.state.estimateData.service_location.id, this.state[dataRef].id)
            }

            const endpointMethod = "PUT"

            const onSuccess = (contact) => {
                let destinationMode = null
                let destinationData = null

                if (populationRef === "updateSendPhoneSelection") {
                    destinationMode = PAGE_MODES.SEND_ESTIMATE
                    destinationData = deepcopy()(this.state.sendData)

                    if (valueIsDefined(contact.phone)) {
                        const newContacts = [...this.state.selectedSendPhoneContacts, contact]
                        destinationData.phone_contacts = newContacts
                        this[populationRef](newContacts)
                    }
                }
                else if (populationRef === "updateSendEmailSelection") {
                    destinationMode = PAGE_MODES.SEND_ESTIMATE
                    destinationData = deepcopy()(this.state.sendData)

                    if (valueIsDefined(contact.email)) {
                        const newContacts = [...this.state.selectedSendEmailContacts, contact]
                        destinationData.email_contacts = newContacts
                        this[populationRef](newContacts)
                    }
                }
                else if (populationRef === "updateOnlinePaymentRecipientSelection") {
                    destinationMode = PAGE_MODES.ADD_PAYMENT
                    destinationData = deepcopy()(this.state.paymentData)

                    if (valueIsDefined(contact.email)) {
                        this[populationRef](contact)
                    }
                }

                this.switchToPrimaryForm()
                this.switchToSecondaryForm(destinationMode, destinationData, null)
                this.addToastToQueue({
                    type: "success",
                    size: "md",
                    title: "Contact updated",
                })
            }
            const onError = () => {
                this.addToastToQueue({
                    type: "error",
                    size: "md",
                    title: "Contact could not be updated",
                })
            }

            this.createUpdateContact(endpoint, endpointMethod, onSuccess, onError)
        }
        else {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Contact could not be updated",
            })
            this.setState((state, props) => {
                let updatedState = state
                updatedState.errors[ERROR_NAMES_BY_MODE[state.mode]] = errors
                return updatedState
            })
        }
    }

    createUpdateContact = async (endpoint, endpointMethod, onSuccess, onError) => {
        const dataName = "contactData"
        const submittingName = "submittingContact"
        const errorDictName = "contact"

        const setErrors = (fieldName, message, errorDict) => {
            if (fieldName === "non_field_errors" && message.endsWith("name, phone, phone_extension, email must make a unique set.")) {
                errorDict["non_field_error"] = "A contact with these details already exists."
            }
            else {
                errorDict[fieldName] = message
            }
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, undefined, setErrors)
    }

    // Mark Estimate as Sent

    markEstimateAsSent = async () => {
        const endpoint = DjangoUrls["estimates:api-estimates-mark-as-sent"](window.MARKETPLACE_ENTITY_SLUG, this.state.estimateData.id)
        const endpointMethod = "POST"
        const successUrl = DjangoUrls["estimates:estimates-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.estimateData.id)

        const dataName = "sendData"
        const submittingName = "submittingSend"
        const errorDictName = "send"

        const onSuccess = () => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Estimate "${this.state.estimateData.custom_id || this.state.estimateData.id}" marked as sent`,
                path: successUrl.split("?")[0],
                barePathOnly: true,
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }

        const dataManipulator = (data, state) => {
            data.phone_contacts = data.phone_contacts || []
            data.email_contacts = data.email_contacts || []
            return data
        }

        document.querySelectorAll("#message_modal_mark_as_sent .modal__close .button").forEach(button => button.style.display = "none")
        document.querySelector("#message_modal_mark_as_sent .modal__close .spinner-centered").style.display = "block"

        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Estimate could not be marked as sent",
            })
            const markAsInvoicedModal = document.querySelector("#message_modal_mark_as_sent .modal__close")
            if (markAsInvoicedModal) {
                markAsInvoicedModal.innerHTML = '<span class="text-invalid"><strong>An unexpected error occurred.</strong></span>'
            }
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, dataManipulator, undefined)
    }

    // Handle Actions

    handleActionRequest = (action) => {
        switch (action) {
            case "ESTIMATE_CREATE":
                this.createEstimate()
                break
            case "ESTIMATE_EDIT":
                location.assign(DjangoUrls["estimates:estimates-update"](window.MARKETPLACE_ENTITY_SLUG, this.state.estimateData.id))
                break
            case "ESTIMATE_SEND":
                this.switchToSecondaryForm(PAGE_MODES.SEND_ESTIMATE, null, null)
                break
            case "TOGGLE_SEND_VIA_SMS":
                this.setState((state, props) => {
                    let updatedState = state
                    updatedState.sendViaSMS = !state.sendViaSMS
                    return updatedState
                })
                break
            case "TOGGLE_SEND_VIA_EMAIL":
                this.setState((state, props) => {
                    let updatedState = state
                    updatedState.sendViaEmail = !state.sendViaEmail
                    return updatedState
                })
                break
            case "TOGGLE_SEND_MANUALLY":
                this.setState((state, props) => {
                    let updatedState = state
                    updatedState.sendManually = !state.sendManually
                    return updatedState
                })
                break
            case "ESTIMATE_SEND_SUBMIT":
                if (!(this.state.sendViaSMS || this.state.sendViaEmail || this.state.sendManually)) {
                    // Check that at least one method is selected
                    this.setState((state, props) => {
                        let updatedState = state
                        updatedState.errors.send.deliveryMethod = "Please select at least one delivery method."
                        return updatedState
                    })
                }
                else {
                    if (this.state.estimateData.state_label === "Sent" || this.state.sendViaSMS || this.state.sendViaEmail) {
                        this.submitSendData()
                    }
                    else {
                        // Mark as sent if it's not yet sent or if they choose not to send it via SMS or Email
                        window.markEstimateAsSent = this.markEstimateAsSent
                        document.querySelector("#message_modal_mark_as_sent").style.display = ""
                        window.MicroModal.show("message_modal_mark_as_sent")
                    }
                }
                break
            case "ESTIMATE_APPROVE":
                this.switchToSecondaryForm(PAGE_MODES.APPROVE_ESTIMATE, null, null)
                break
            case "ESTIMATE_APPROVE_SUBMIT":
                if (window.USING_PUBLIC_URL) {
                    this.submitApproveDataPublic()
                }
                else {
                    this.submitApproveData()
                }
                break
            case "ESTIMATE_DECLINE":
                this.switchToSecondaryForm(PAGE_MODES.DECLINE_ESTIMATE, null, null)
                break
            case "ESTIMATE_DECLINE_SUBMIT":
                if (window.USING_PUBLIC_URL) {
                    this.submitDeclineDataPublic()
                }
                else {
                    this.submitDeclineData()
                }
                break
            case "ESTIMATE_CANCEL":
                this.switchToSecondaryForm(PAGE_MODES.CANCEL_ESTIMATE, null, null)
                break
            case "ESTIMATE_CANCEL_SUBMIT":
                this.submitCancelData()
                break
            case "ESTIMATE_CONVERT_JOB":
                location.assign(DjangoUrls["jobs:jobs-create"](window.MARKETPLACE_ENTITY_SLUG) + `?from_estimate=${this.state.estimateData.id}`)
                break
            case "ESTIMATE_CONVERT_INVOICE":
                location.assign(DjangoUrls["invoices:invoices-create"](window.MARKETPLACE_ENTITY_SLUG) + `?from_estimate=${this.state.estimateData.id}`)
                break
            case "ESTIMATE_DOWNLOAD_PDF":
                location.assign(window.PUBLIC_PDF_URL)
                break
            case "ESTIMATE_ADD_PAYMENT":
                this.switchToSecondaryForm(PAGE_MODES.ADD_PAYMENT, null, null)
                break
            case "ESTIMATE_PAY_ONLINE":
                this.switchToSecondaryForm(PAGE_MODES.PAY_ONLINE, null, null)
                break
            case "ADD_PAYMENT":
                this.createPayment()
                break
            case "EDIT_PAYMENT":
                this.updatePayment(this.state.paymentData.id)
                break
            case "DELETE_PAYMENT":
                this.deletePayment(this.state.paymentData.id)
                break
            case "PAY_ONLINE":
                this.createOnlinePayment()
                break
            case "CONTACT_CREATE":
                this.createContact()
                break
            case "CONTACT_UPDATE":
                const dataRef = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]].dataRef

                if (this.state[dataRef].is_ephemeral) {
                    this.createContact()
                }
                else {
                    this.updateContact()
                }
                break
            case "CONTACT_CANCEL_CREATE":
            case "CONTACT_CANCEL_EDITS":
                const populationRef = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]].populationRef

                let destinationMode = null
                let destinationData = null

                if (populationRef === "updateSendPhoneSelection") {
                    destinationMode = PAGE_MODES.SEND_ESTIMATE
                    destinationData = deepcopy()(this.state.sendData)
                }
                else if (populationRef === "updateSendEmailSelection") {
                    destinationMode = PAGE_MODES.SEND_ESTIMATE
                    destinationData = deepcopy()(this.state.sendData)
                }
                else if (populationRef === "updateOnlinePaymentRecipientSelection") {
                    destinationMode = PAGE_MODES.ADD_PAYMENT
                    destinationData = deepcopy()(this.state.paymentData)
                }

                this.switchToPrimaryForm()
                this.switchToSecondaryForm(destinationMode, destinationData, null)
                break
            default:
                console.error(`No action handler exists for action "${action}".`)
        }
    }

    // Render

    render() {
        if (this.state.estimateData === null) {
            return <Spinner centered={true} />
        }
        else {
            if (PRIMARY_PAGE_MODES.includes(this.state.mode)) {
                return <EstimateDetailsCard
                    estimate={this.state.estimateData}
                    childJobs={this.state.estimateChildJobsData || []}
                    childInvoices={this.state.estimateChildInvoicesData || []}
                    requestAction={this.handleActionRequest}
                    switchToSecondaryForm={this.switchToSecondaryForm}
                    returnScroll={this.state.returnScroll}
                    submitting={this.state.submittingEstimate}
                    errors={this.state.errors.estimate}
                ></EstimateDetailsCard>
            }
            else if (this.state.mode === PAGE_MODES.SEND_ESTIMATE) {
                return <EstimateSendForm
                    estimate={this.state.estimateData}
                    sendData={this.state.sendData}
                    requestAction={this.handleActionRequest}
                    switchToPrimaryForm={this.switchToPrimaryForm}
                    switchToSecondaryForm={this.switchToSecondaryForm}
                    submitting={this.state.submittingSend}
                    errors={this.state.errors.send}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("sendData", fieldName, fieldValue)}
                    updateSendPhoneSelection={this.updateSendPhoneSelection}
                    updateSendEmailSelection={this.updateSendEmailSelection}
                    selectedSendPhoneContacts={this.state.selectedSendPhoneContacts}
                    selectedSendEmailContacts={this.state.selectedSendEmailContacts}
                    sendViaSMS={this.state.sendViaSMS}
                    sendViaEmail={this.state.sendViaEmail}
                    sendManually={this.state.sendManually}
                    returnScroll={0}
                ></EstimateSendForm>
            }
            else if (this.state.mode === PAGE_MODES.APPROVE_ESTIMATE) {
                return <EstimateApproveForm
                    estimate={this.state.estimateData}
                    approveData={this.state.approveData}
                    requestAction={this.handleActionRequest}
                    switchToPrimaryForm={this.switchToPrimaryForm}
                    submitting={this.state.submittingApprove}
                    errors={this.state.errors.approve}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("approveData", fieldName, fieldValue)}
                    returnScroll={0}
                ></EstimateApproveForm>
            }
            else if (this.state.mode === PAGE_MODES.DECLINE_ESTIMATE) {
                return <EstimateDeclineForm
                    estimate={this.state.estimateData}
                    declineData={this.state.declineData}
                    requestAction={this.handleActionRequest}
                    switchToPrimaryForm={this.switchToPrimaryForm}
                    submitting={this.state.submittingDecline}
                    errors={this.state.errors.decline}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("declineData", fieldName, fieldValue)}
                    returnScroll={0}
                ></EstimateDeclineForm>
            }
            else if (this.state.mode === PAGE_MODES.CANCEL_ESTIMATE) {
                return <EstimateCancelForm
                    estimate={this.state.estimateData}
                    cancelData={this.state.cancelData}
                    requestAction={this.handleActionRequest}
                    switchToPrimaryForm={this.switchToPrimaryForm}
                    submitting={this.state.submittingCancel}
                    errors={this.state.errors.cancel}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("cancelData", fieldName, fieldValue)}
                    returnScroll={0}
                ></EstimateCancelForm>
            }
            else if (PAYMENT_PAGE_MODES.includes(this.state.mode)) {
                return (
                    <Elements stripe={this.stripePromise}>
                        <EstimatePaymentForm
                            mode={this.state.mode}
                            submitting={this.state.submittingPayment}
                            estimate={this.state.estimateData}
                            payment={this.state.paymentData}
                            errors={this.state.errors.payment}
                            onFormDataChange={(fieldName, fieldValue, then=null) => this.updateFormData("paymentData", fieldName, fieldValue, then)}
                            requestAction={this.handleActionRequest}
                            switchToPrimaryForm={this.switchToPrimaryForm}
                            switchToSecondaryForm={this.switchToSecondaryForm}
                            paymentMethodOptions={this.paymentMethodOptions}
                            updateOnlinePaymentRecipientSelection={this.updateOnlinePaymentRecipientSelection}
                            selectedOnlinePaymentRecipient={this.state.selectedOnlinePaymentRecipient}
                            returnScroll={0}
                        ></EstimatePaymentForm>
                    </Elements>
                )
            }
            else if (this.state.mode === PAGE_MODES.PAY_ONLINE) {
                return (
                    <Elements stripe={this.stripePromise}>
                        <EstimateOnlinePaymentForm
                            mode={this.state.mode}
                            submitting={this.state.submittingPayment}
                            estimate={this.state.estimateData}
                            errors={this.state.errors.payment}
                            onFormDataChange={(fieldName, fieldValue, then=null) => this.updateFormData("paymentData", fieldName, fieldValue, then)}
                            requestAction={this.handleActionRequest}
                            switchToPrimaryForm={this.switchToPrimaryForm}
                            returnScroll={0}
                        ></EstimateOnlinePaymentForm>
                    </Elements>
                )
            }
            else if (CONTACT_PAGE_MODES.includes(this.state.mode)) {
                return <ContactForm
                    mode={this.state.mode}
                    submitting={this.state.submittingContact}
                    contact={this.state.contactData}
                    errors={this.state.errors.contact}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("contactData", fieldName, fieldValue)}
                    requestAction={this.handleActionRequest}
                    switchToPrimaryForm={this.switchToPrimaryForm}
                    returnScroll={0}
                ></ContactForm>
            }
            else {
                return (
                    <div className="data-panel-container data-panel-container--with-margin">
                        <div className="data-panel" aria-label="Unknown Form Mode">
                            <div className="data-panel__form">
                                <p className="data-panel__form__caption">An unhandled form mode was supplied.</p>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    }
}

export default EstimateDetailsContainer;
