import clsx from "clsx"
import { Children, cloneElement, isValidElement, useEffect, useState } from "react"

import useIsDesktop from "@hooks/useIsDesktop"

import { Modal } from "@molecules/index"

import { BottomSheet } from "@organisms/index"

import styles from "./ResponsiveDialog.module.scss"
import { ResponsiveDialogProps } from "./ResponsiveDialog.types"
import ResponsiveDialogBody from "./ResponsiveDialogBody/ResponsiveDialogBody"
import ResponsiveDialogFooter from "./ResponsiveDialogFooter/ResponsiveDialogFooter"

function ResponsiveDialog(props: ResponsiveDialogProps) {
    const {
        trigger = undefined,
        children,
        headerTitle,
        modalClassName,
        onOpenChange = () => {},
        isDefaultOpen = undefined,
        isOpen = undefined,
    } = props

    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(isDefaultOpen)

    const isDesktop = useIsDesktop()

    useEffect(() => {
        if (typeof isOpen === "boolean") {
            setIsDialogOpen(isOpen)
        }
    }, [isOpen])

    useEffect(() => {
        onOpenChange?.(isDialogOpen)
    }, [isDialogOpen])

    const closeDialog = () => setIsDialogOpen(false)
    const openDialog = () => setIsDialogOpen(true)

    if (!isDesktop) {
        const childrenWithProps = Children.map(trigger, (child) => {
            if (isValidElement<React.HTMLAttributes<HTMLButtonElement>>(child)) {
                return cloneElement(child, { onClick: openDialog })
            }
            return child
        })

        return (
            <>
                {childrenWithProps && childrenWithProps}
                <BottomSheet.Root isOpen={isDialogOpen} onClose={closeDialog}>
                    <BottomSheet.Content
                        header={{
                            title: headerTitle,
                            showDivider: true,
                            showDragIndicator: true,
                        }}
                        isVisible={isDialogOpen}
                        priority="exclusive"
                        itemsClassName={styles.bottomSheetContent}
                    >
                        {() => <>{children}</>}
                    </BottomSheet.Content>
                </BottomSheet.Root>
            </>
        )
    } else {
        return (
            <Modal.Root onOpenChange={setIsDialogOpen} open={isDialogOpen}>
                {trigger && <Modal.Trigger asChild={true}>{trigger}</Modal.Trigger>}
                <Modal.Content className={clsx(modalClassName, styles.modal)}>
                    <Modal.Header showDivider={true}>{headerTitle}</Modal.Header>
                    <>{children}</>
                </Modal.Content>
            </Modal.Root>
        )
    }
}

export default {
    Root: ResponsiveDialog,
    Body: ResponsiveDialogBody,
    Footer: ResponsiveDialogFooter,
}
