// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BottomSheetOrderColumns-base__Tor1T{display:flex;flex-direction:column;max-height:inherit}.BottomSheetOrderColumns-divider__GdvGA{padding:var(--size-2_5) var(--size-3)}.BottomSheetOrderColumns-addColumnButton__Yks8v{position:relative;padding:var(--size-1_5) var(--size-2_5)}.BottomSheetOrderColumns-addColumnButton__Yks8v::before{position:absolute;top:var(--size-0);right:var(--size-0);left:var(--size-0);display:block;width:var(--size-full);height:var(--size-0_25);background-color:var(--gray-3);content:\"\"}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "BottomSheetOrderColumns-base__Tor1T",
	"divider": "BottomSheetOrderColumns-divider__GdvGA",
	"addColumnButton": "BottomSheetOrderColumns-addColumnButton__Yks8v"
};
export default ___CSS_LOADER_EXPORT___;
