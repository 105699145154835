// TODO: replace with next router
export default function useRouter() {
    return {
        push: (path: string) => {
            window.location.assign(path)
        },
        replace: (path: string) => {
            window.location.replace(path)
        },
        pathname: window.location.pathname,
    }
}
