import useIsDesktop from "@hooks/useIsDesktop"

import JobTimelineViewDesktopUnscheduledJobs from "./JobTimelineViewDesktopUnscheduledJobs"
import JobTimelineViewMobileUnscheduledJobs from "./JobTimelineViewMobileUnscheduledJobs"
import JobTimelineViewUnscheduledJobsWrapper from "./JobTimelineViewUnscheduledJobsWrapper"

export default function JobTimelineViewUnscheduledJobs() {
    const isDesktop = useIsDesktop()

    return (
        <JobTimelineViewUnscheduledJobsWrapper>
            {isDesktop ? <JobTimelineViewDesktopUnscheduledJobs /> : <JobTimelineViewMobileUnscheduledJobs />}
        </JobTimelineViewUnscheduledJobsWrapper>
    )
}
