// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SkeletonCell-base__ZI4pq{position:relative;display:flex;flex-grow:1;gap:var(--size-1_5);align-items:center;justify-content:flex-start;width:100%;line-height:0}.SkeletonCell-base__ZI4pq .react-loading-skeleton{--base-color: var(--gray-4);--highlight-color: var(--gray-2)}.SkeletonCell-base__ZI4pq>span{display:flex;width:100%}.SkeletonCell-base__ZI4pq::before{position:absolute;right:0;bottom:0;left:0;width:var(--size-full);height:var(--size-0_25);background-color:var(--gray-4);content:\"\"}.SkeletonCell-header__vRFVx{padding-top:calc(var(--size-4) - var(--size-0_25));padding-right:var(--size-12);padding-bottom:calc(var(--size-4) - var(--size-0_25));padding-left:var(--size-4)}.SkeletonCell-header__vRFVx.SkeletonCell-rightAligned__MCfZn{justify-content:flex-end;padding-right:var(--size-4);padding-left:var(--size-12)}.SkeletonCell-text__e19rc{padding-top:var(--size-4_5);padding-right:var(--size-6);padding-bottom:var(--size-4_5);padding-left:var(--size-4)}.SkeletonCell-text__e19rc.SkeletonCell-rightAligned__MCfZn{justify-content:flex-end;padding-right:var(--size-4);padding-left:var(--size-6)}.SkeletonCell-badge__xn1Cn{padding-top:var(--size-3);padding-right:var(--size-12);padding-bottom:var(--size-3);padding-left:var(--size-4)}.SkeletonCell-icon-text__D8tPH{padding-top:var(--size-4_5);padding-right:var(--size-6);padding-bottom:var(--size-4_5);padding-left:var(--size-4)}.SkeletonCell-icon-text__D8tPH>span:first-child{width:var(--size-3)}.SkeletonCell-avatar__ySAUn{padding-top:var(--size-2_5);padding-right:var(--size-4);padding-bottom:var(--size-2_5);padding-left:var(--size-4)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "SkeletonCell-base__ZI4pq",
	"header": "SkeletonCell-header__vRFVx",
	"rightAligned": "SkeletonCell-rightAligned__MCfZn",
	"text": "SkeletonCell-text__e19rc",
	"badge": "SkeletonCell-badge__xn1Cn",
	"icon-text": "SkeletonCell-icon-text__D8tPH",
	"iconText": "SkeletonCell-icon-text__D8tPH",
	"avatar": "SkeletonCell-avatar__ySAUn"
};
export default ___CSS_LOADER_EXPORT___;
