import React, { Component } from "react";
import ButtonGroup from "../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../core/buttons/UniversalButton";
import HorizontalLayout from "../../core/components/HorizontalLayout";
import BasicDisplayField from "../../core/fields/BasicDisplayField";
import CharField from "../../core/fields/CharField";
import QuantityField from "../../core/fields/QuantityField";
import { getInventoryStatus } from "../utils/utils";

class InlineInventoryLevelForm extends Component {

    renderButtons = () => {
        const { inventoryLevel, onFormDataChange, requestAction } = this.props

        return (
            <ButtonGroup>
                <ButtonGroupRow>
                    <UniversalButton type="primary" text="Save" handler={event => requestAction("INVENTORY_LEVEL_SAVE")} />
                </ButtonGroupRow>
                <ButtonGroupRow>
                    <UniversalButton type="secondary" text="Cancel Edits" handler={event => requestAction("INVENTORY_LEVEL_CANCEL_EDITS")} />
                </ButtonGroupRow>
            </ButtonGroup>
        )
    }

    render() {
        const { inventoryLevel, onFormDataChange, requestAction } = this.props
        const errors = inventoryLevel.errors || {}

        return (
            <div className="data-panel__list-select__form">
                <div className="data-panel__form" aria-label="Inventory Level Edit Form">
                    {errors.non_field_error && <div className="data-panel__form__field__errors" aria-label="Non Field Error">{errors.non_field_error}</div>}
                    <BasicDisplayField
                        fieldName="location"
                        fieldLabel="Inventory Location"
                        fieldValue={inventoryLevel.location_label}
                        disabled={true}
                    ></BasicDisplayField>
                    <HorizontalLayout>
                        <QuantityField
                            fieldName="quantity_on_hand"
                            fieldLabel="Quantity on Hand"
                            fieldValue={inventoryLevel.quantity_on_hand || ""}
                            fieldOnChange={quantity_on_hand => {
                                onFormDataChange("quantity_on_hand", quantity_on_hand || "")
                                onFormDataChange("inventory_status", getInventoryStatus(quantity_on_hand, inventoryLevel.quantity_on_order, inventoryLevel.quantity_desired, inventoryLevel.quantity_low_alert_threshold))
                            }}
                            errors={errors}
                        ></QuantityField>
                        <QuantityField
                            fieldName="quantity_on_order"
                            fieldLabel="Quantity on Order"
                            fieldValue={inventoryLevel.quantity_on_order || ""}
                            fieldOnChange={quantity_on_order => {
                                onFormDataChange("quantity_on_order", quantity_on_order || "")
                                onFormDataChange("inventory_status", getInventoryStatus(inventoryLevel.quantity_on_hand, quantity_on_order, inventoryLevel.quantity_desired, inventoryLevel.quantity_low_alert_threshold))
                            }}
                            errors={errors}
                        ></QuantityField>
                    </HorizontalLayout>
                    <HorizontalLayout>
                        <QuantityField
                            fieldName="quantity_desired"
                            fieldLabel="Quantity Desired"
                            fieldValue={inventoryLevel.quantity_desired || ""}
                            fieldOnChange={quantity_desired => {
                                onFormDataChange("quantity_desired", quantity_desired || "")
                                onFormDataChange("inventory_status", getInventoryStatus(inventoryLevel.quantity_on_hand, inventoryLevel.quantity_on_order, quantity_desired, inventoryLevel.quantity_low_alert_threshold))
                            }}
                            errors={errors}
                        ></QuantityField>
                        <QuantityField
                            fieldName="quantity_low_alert_threshold"
                            fieldLabel="Quantity Low Alert Threshold"
                            fieldValue={inventoryLevel.quantity_low_alert_threshold || ""}
                            fieldOnChange={quantity_low_alert_threshold => {
                                onFormDataChange("quantity_low_alert_threshold", quantity_low_alert_threshold || "")
                                onFormDataChange("inventory_status", getInventoryStatus(inventoryLevel.quantity_on_hand, inventoryLevel.quantity_on_order, inventoryLevel.quantity_desired, quantity_low_alert_threshold))
                            }}
                            errors={errors}
                        ></QuantityField>
                    </HorizontalLayout>
                    <CharField
                        fieldName="bin"
                        fieldLabel="Bin"
                        fieldValue={inventoryLevel.bin || ""}
                        fieldOnChange={bin => onFormDataChange("bin", bin || "")}
                        maxLength="50"
                        optional={true}
                        errors={errors}
                    ></CharField>
                </div>
                {this.renderButtons()}
            </div>
        )
    }
}

export default InlineInventoryLevelForm;
