import { PurchaseOrderStatuses } from "@legacy/core/utils/enums"
import dayjs from "dayjs"
import timezone from "dayjs/plugin/timezone"

import useObjectDetailsRoutes from "@hooks/useObjectDetailsRoute"
import useSlugExtractor from "@hooks/useSlugExtractor"
import useUser from "@hooks/useUser"

import { Table } from "@organisms"
import { Cells, Columns } from "@organisms/Table/Table.types"
import { GetObjectDetailsRouteFunction, Tab } from "@organisms/Table/TableViewControls/TableViewTab/TableViewTab.types"

import { PURCHASE_ORDER_ROUTES } from "@routes/purchase-orders"

dayjs.extend(timezone)

export default function usePurchaseOrderTabs(): [Tab<PurchaseOrder>[], Tab<PurchaseOrder>[]] {
    const { user } = useUser()

    const [tabCountEndpoint, tabListEndpoint] = useSlugExtractor([
        PURCHASE_ORDER_ROUTES.COUNT,
        PURCHASE_ORDER_ROUTES.LIGHTWEIGHT_LIST,
        PURCHASE_ORDER_ROUTES.UPDATE,
        PURCHASE_ORDER_ROUTES.DETAILS,
    ])

    const getTabObjectDetailsRoute = useObjectDetailsRoutes("PurchaseOrder") as GetObjectDetailsRouteFunction

    const headerByColumn = {
        id: "ID",
        vendor_name: "Vendor",
        state_label: "State",
        status_label: "Status",
        item_count: "Items",
        subtotal: "Amount",
        shipping: "Shipping",
        preferred_shipping: "Shipping Preference",
        tracking_number: "Tracking Number",
        associated_jobs: "Associated Jobs",
        last_status_transition_time: "Status Changed",
        last_modified_at: "Last Modified",
        date_issued: "Issue Date",
        estimated_arrival_time: "Est. Arrival",
        payment_terms: "Payment Terms",
        last_sent_at: "Sent",
        last_acknowledged_at: "Acknowledged",
        last_in_transit_at: "In Transit",
        last_delivered_at: "Delivered",
        last_stocked_at: "Stocked",
        last_paid_at: "Paid",
        last_cancelled_at: "Cancelled",
        last_viewed: "Seen",
    }

    const sortingEnabledByColumn = {
        id: true,
        vendor_name: true,
        state_label: false,
        status_label: true,
        item_count: true,
        subtotal: true,
        shipping: false,
        preferred_shipping: false,
        tracking_number: false,
        associated_jobs: false,
        last_status_transition_time: false,
        last_modified_at: true,
        date_issued: true,
        estimated_arrival_time: true,
        payment_terms: false,
        last_sent_at: true,
        last_acknowledged_at: true,
        last_in_transit_at: true,
        last_delivered_at: true,
        last_stocked_at: true,
        last_paid_at: true,
        last_cancelled_at: true,
        last_viewed: true,
    }

    const widthByColumn = {
        id: 96,
        vendor_name: 224,
        state_label: 128,
        status_label: 160,
        item_count: 96,
        subtotal: 128,
        shipping: 224,
        preferred_shipping: 224,
        tracking_number: 176,
        associated_jobs: 176,
        last_status_transition_time: 160,
        last_modified_at: 144,
        date_issued: 144,
        estimated_arrival_time: 144,
        payment_terms: 160,
        last_sent_at: 144,
        last_acknowledged_at: 144,
        last_in_transit_at: 144,
        last_delivered_at: 144,
        last_stocked_at: 144,
        last_paid_at: 144,
        last_cancelled_at: 144,
        last_viewed: 96,
    }

    const pinningByColumn = {
        id: true,
        vendor_name: true,
        state_label: false,
        status_label: false,
        item_count: false,
        subtotal: false,
        shipping: false,
        preferred_shipping: false,
        tracking_number: false,
        associated_jobs: false,
        last_status_transition_time: false,
        last_modified_at: false,
        date_issued: false,
        estimated_arrival_time: false,
        payment_terms: false,
        last_sent_at: false,
        last_acknowledged_at: false,
        last_in_transit_at: false,
        last_delivered_at: false,
        last_stocked_at: false,
        last_paid_at: false,
        last_cancelled_at: false,
        last_viewed: false,
    }

    const cellByColumn: Cells<PurchaseOrder> = {
        id: ({ row }) => <Table.Cells.IDCell id={row.original.custom_id || row.original.id} />,
        vendor_name: ({ getValue }) => <Table.Cells.TruncatedTextCell content={getValue()} />,
        state_label: ({ getValue }) => <Table.Cells.BadgesCell badges={[getValue()]} />,
        status_label: ({ getValue }) => <Table.Cells.StatusCell status={getValue()} />,
        item_count: ({ getValue }) => <Table.Cells.NumericalDataCell value={getValue()} type="number" />,
        subtotal: ({ getValue }) => <Table.Cells.NumericalDataCell value={getValue()} type="currency" />,
        shipping: ({ getValue }) => <Table.Cells.TruncatedTextCell content={getValue()} />,
        preferred_shipping: ({ getValue }) => <Table.Cells.TruncatedTextCell content={getValue()} />,
        tracking_number: ({ getValue }) => <Table.Cells.TruncatedTextCell content={getValue()} />,
        associated_jobs: ({ getValue }) => <Table.Cells.BadgesCell badges={getValue()} />,
        last_status_transition_time: ({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />,
        last_modified_at: ({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />,
        date_issued: ({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />,
        estimated_arrival_time: ({ getValue, row }) => {
            const parsedDate = dayjs(getValue()).tz(user?.service_company.preferred_timezone)
            const arrivalIsLate = parsedDate.isBefore(dayjs().tz(user?.service_company.preferred_timezone))

            return (
                <Table.Cells.DateTimeCell
                    value={getValue()}
                    isDangerous={arrivalIsLate && row.original.state_label === "In Transit"}
                />
            )
        },
        payment_terms: ({ getValue }) => <Table.Cells.PaymentTermsCell terms={getValue()} />,
        last_sent_at: ({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />,
        last_acknowledged_at: ({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />,
        last_in_transit_at: ({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />,
        last_delivered_at: ({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />,
        last_stocked_at: ({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />,
        last_paid_at: ({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />,
        last_cancelled_at: ({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />,
        last_viewed: ({ getValue }) => <Table.Cells.SeenCell value={getValue()} />,
    }

    const columnByName: Columns<PurchaseOrder> = {
        id: {
            header: headerByColumn["id"],
            accessorKey: "id",
            id: "id",
            size: widthByColumn["id"],
            cell: cellByColumn["id"],
            enableSorting: sortingEnabledByColumn["id"],
            enablePinning: pinningByColumn["id"],
        },
        vendor_name: {
            header: headerByColumn["vendor_name"],
            accessorKey: "vendor_name",
            id: "vendor_name",
            size: widthByColumn["vendor_name"],
            cell: cellByColumn["vendor_name"],
            enableSorting: sortingEnabledByColumn["vendor_name"],
            enablePinning: pinningByColumn["vendor_name"],
        },
        state_label: {
            header: headerByColumn["state_label"],
            accessorKey: "state_label",
            id: "state_label",
            size: widthByColumn["state_label"],
            cell: cellByColumn["state_label"],
            enableSorting: sortingEnabledByColumn["state_label"],
            enablePinning: pinningByColumn["state_label"],
        },
        status_label: {
            header: headerByColumn["status_label"],
            accessorKey: "status_label",
            id: "status_label",
            size: widthByColumn["status_label"],
            cell: cellByColumn["status_label"],
            enableSorting: sortingEnabledByColumn["status_label"],
            enablePinning: pinningByColumn["status_label"],
        },
        item_count: {
            header: headerByColumn["item_count"],
            accessorKey: "item_count",
            id: "item_count",
            size: widthByColumn["item_count"],
            cell: cellByColumn["item_count"],
            enableSorting: sortingEnabledByColumn["item_count"],
            enablePinning: pinningByColumn["item_count"],
        },
        subtotal: {
            header: headerByColumn["subtotal"],
            accessorKey: "subtotal",
            id: "subtotal",
            size: widthByColumn["subtotal"],
            cell: cellByColumn["subtotal"],
            enableSorting: sortingEnabledByColumn["subtotal"],
            enablePinning: pinningByColumn["subtotal"],
        },
        shipping: {
            header: headerByColumn["shipping"],
            accessorKey: "shipping",
            id: "shipping",
            size: widthByColumn["shipping"],
            cell: cellByColumn["shipping"],
            enableSorting: sortingEnabledByColumn["shipping"],
            enablePinning: pinningByColumn["shipping"],
        },
        preferred_shipping: {
            header: headerByColumn["preferred_shipping"],
            accessorKey: "preferred_shipping",
            id: "preferred_shipping",
            size: widthByColumn["preferred_shipping"],
            cell: cellByColumn["preferred_shipping"],
            enableSorting: sortingEnabledByColumn["preferred_shipping"],
            enablePinning: pinningByColumn["preferred_shipping"],
        },
        tracking_number: {
            header: headerByColumn["tracking_number"],
            accessorKey: "tracking_number",
            id: "tracking_number",
            size: widthByColumn["tracking_number"],
            cell: cellByColumn["tracking_number"],
            enableSorting: sortingEnabledByColumn["tracking_number"],
            enablePinning: pinningByColumn["tracking_number"],
        },
        associated_jobs: {
            header: headerByColumn["associated_jobs"],
            accessorKey: "associated_jobs",
            id: "associated_jobs",
            size: widthByColumn["associated_jobs"],
            cell: cellByColumn["associated_jobs"],
            enableSorting: sortingEnabledByColumn["associated_jobs"],
            enablePinning: pinningByColumn["associated_jobs"],
        },
        last_status_transition_time: {
            header: headerByColumn["last_status_transition_time"],
            accessorKey: "last_status_transition_time",
            id: "last_status_transition_time",
            size: widthByColumn["last_status_transition_time"],
            cell: cellByColumn["last_status_transition_time"],
            enableSorting: sortingEnabledByColumn["last_status_transition_time"],
            enablePinning: pinningByColumn["last_status_transition_time"],
        },
        last_modified_at: {
            header: headerByColumn["last_modified_at"],
            accessorKey: "last_modified_at",
            id: "last_modified_at",
            size: widthByColumn["last_modified_at"],
            cell: cellByColumn["last_modified_at"],
            enableSorting: sortingEnabledByColumn["last_modified_at"],
            enablePinning: pinningByColumn["last_modified_at"],
        },
        date_issued: {
            header: headerByColumn["date_issued"],
            accessorKey: "date_issued",
            id: "date_issued",
            size: widthByColumn["date_issued"],
            cell: cellByColumn["date_issued"],
            enableSorting: sortingEnabledByColumn["date_issued"],
            enablePinning: pinningByColumn["date_issued"],
        },
        estimated_arrival_time: {
            header: headerByColumn["estimated_arrival_time"],
            accessorKey: "estimated_arrival_time",
            id: "estimated_arrival_time",
            size: widthByColumn["estimated_arrival_time"],
            cell: cellByColumn["estimated_arrival_time"],
            enableSorting: sortingEnabledByColumn["estimated_arrival_time"],
            enablePinning: pinningByColumn["estimated_arrival_time"],
        },
        payment_terms: {
            header: headerByColumn["payment_terms"],
            accessorKey: "payment_terms",
            id: "payment_terms",
            size: widthByColumn["payment_terms"],
            cell: cellByColumn["payment_terms"],
            enableSorting: sortingEnabledByColumn["payment_terms"],
            enablePinning: pinningByColumn["payment_terms"],
        },
        last_sent_at: {
            header: headerByColumn["last_sent_at"],
            accessorKey: "last_sent_at",
            id: "last_sent_at",
            size: widthByColumn["last_sent_at"],
            cell: cellByColumn["last_sent_at"],
            enableSorting: sortingEnabledByColumn["last_sent_at"],
            enablePinning: pinningByColumn["last_sent_at"],
        },
        last_acknowledged_at: {
            header: headerByColumn["last_acknowledged_at"],
            accessorKey: "last_acknowledged_at",
            id: "last_acknowledged_at",
            size: widthByColumn["last_acknowledged_at"],
            cell: cellByColumn["last_acknowledged_at"],
            enableSorting: sortingEnabledByColumn["last_acknowledged_at"],
            enablePinning: pinningByColumn["last_acknowledged_at"],
        },
        last_in_transit_at: {
            header: headerByColumn["last_in_transit_at"],
            accessorKey: "last_in_transit_at",
            id: "last_in_transit_at",
            size: widthByColumn["last_in_transit_at"],
            cell: cellByColumn["last_in_transit_at"],
            enableSorting: sortingEnabledByColumn["last_in_transit_at"],
            enablePinning: pinningByColumn["last_in_transit_at"],
        },
        last_delivered_at: {
            header: headerByColumn["last_delivered_at"],
            accessorKey: "last_delivered_at",
            id: "last_delivered_at",
            size: widthByColumn["last_delivered_at"],
            cell: cellByColumn["last_delivered_at"],
            enableSorting: sortingEnabledByColumn["last_delivered_at"],
            enablePinning: pinningByColumn["last_delivered_at"],
        },
        last_stocked_at: {
            header: headerByColumn["last_stocked_at"],
            accessorKey: "last_stocked_at",
            id: "last_stocked_at",
            size: widthByColumn["last_stocked_at"],
            cell: cellByColumn["last_stocked_at"],
            enableSorting: sortingEnabledByColumn["last_stocked_at"],
            enablePinning: pinningByColumn["last_stocked_at"],
        },
        last_paid_at: {
            header: headerByColumn["last_paid_at"],
            accessorKey: "last_paid_at",
            id: "last_paid_at",
            size: widthByColumn["last_paid_at"],
            cell: cellByColumn["last_paid_at"],
            enableSorting: sortingEnabledByColumn["last_paid_at"],
            enablePinning: pinningByColumn["last_paid_at"],
        },
        last_cancelled_at: {
            header: headerByColumn["last_cancelled_at"],
            accessorKey: "last_cancelled_at",
            id: "last_cancelled_at",
            size: widthByColumn["last_cancelled_at"],
            cell: cellByColumn["last_cancelled_at"],
            enableSorting: sortingEnabledByColumn["last_cancelled_at"],
            enablePinning: pinningByColumn["last_cancelled_at"],
        },
        last_viewed: {
            header: headerByColumn["last_viewed"],
            accessorKey: "last_viewed",
            id: "last_viewed",
            size: widthByColumn["last_viewed"],
            cell: cellByColumn["last_viewed"],
            enableSorting: sortingEnabledByColumn["last_viewed"],
            enablePinning: pinningByColumn["last_viewed"],
        },
    }

    const openPurchaseOrdersTabs: Tab<PurchaseOrder>[] = [
        {
            title: "All Open",
            tabObjectName: "Order",
            showTab: true,
            objectsPhase: "open",
            countEndpoint: tabCountEndpoint,
            listEndpoint: tabListEndpoint,
            getDetailsRoute: getTabObjectDetailsRoute,
            endpointKwargs: [
                ["status", PurchaseOrderStatuses.pending],
                ["status", PurchaseOrderStatuses.sent],
                ["status", PurchaseOrderStatuses.acknowledged],
                ["status", PurchaseOrderStatuses.in_transit],
                ["status", PurchaseOrderStatuses.delivered],
                ["status", PurchaseOrderStatuses.stocked],
                ["ordering", "date_issued"],
            ],
            columnDefinition: [
                columnByName["id"],
                columnByName["vendor_name"],
                columnByName["state_label"],
                columnByName["status_label"],
                columnByName["item_count"],
                columnByName["subtotal"],
                columnByName["shipping"],
                columnByName["tracking_number"],
                columnByName["associated_jobs"],
                columnByName["last_status_transition_time"],
                columnByName["last_modified_at"],
            ],
        },
        {
            title: "Pending",
            tabObjectName: "Order",
            showTab: true,
            objectsPhase: "open",
            countEndpoint: tabCountEndpoint,
            listEndpoint: tabListEndpoint,
            getDetailsRoute: getTabObjectDetailsRoute,
            endpointKwargs: [
                ["status", PurchaseOrderStatuses.pending],
                ["ordering", "date_issued"],
            ],
            columnDefinition: [
                columnByName["id"],
                columnByName["vendor_name"],
                columnByName["status_label"],
                columnByName["item_count"],
                columnByName["subtotal"],
                columnByName["preferred_shipping"],
                columnByName["associated_jobs"],
                columnByName["last_modified_at"],
            ],
        },
        {
            title: "Open",
            tabObjectName: "Order",
            showTab: true,
            objectsPhase: "open",
            countEndpoint: tabCountEndpoint,
            listEndpoint: tabListEndpoint,
            getDetailsRoute: getTabObjectDetailsRoute,
            endpointKwargs: [
                ["status", PurchaseOrderStatuses.sent],
                ["status", PurchaseOrderStatuses.acknowledged],
                ["ordering", "date_issued"],
            ],
            columnDefinition: [
                columnByName["id"],
                columnByName["vendor_name"],
                columnByName["status_label"],
                columnByName["item_count"],
                columnByName["subtotal"],
                columnByName["preferred_shipping"],
                columnByName["date_issued"],
                columnByName["associated_jobs"],
                columnByName["last_status_transition_time"],
                columnByName["last_modified_at"],
                columnByName["last_viewed"],
            ],
        },
        {
            title: "In Transit",
            tabObjectName: "Order",
            showTab: true,
            objectsPhase: "open",
            countEndpoint: tabCountEndpoint,
            listEndpoint: tabListEndpoint,
            getDetailsRoute: getTabObjectDetailsRoute,
            endpointKwargs: [
                ["status", PurchaseOrderStatuses.in_transit],
                ["ordering", "date_issued"],
            ],
            columnDefinition: [
                columnByName["id"],
                columnByName["vendor_name"],
                columnByName["status_label"],
                columnByName["item_count"],
                columnByName["subtotal"],
                columnByName["estimated_arrival_time"],
                columnByName["shipping"],
                columnByName["tracking_number"],
                columnByName["associated_jobs"],
                columnByName["last_in_transit_at"],
                columnByName["last_modified_at"],
            ],
        },
        {
            title: "Delivered",
            tabObjectName: "Order",
            showTab: true,
            objectsPhase: "open",
            countEndpoint: tabCountEndpoint,
            listEndpoint: tabListEndpoint,
            getDetailsRoute: getTabObjectDetailsRoute,
            endpointKwargs: [
                ["status", PurchaseOrderStatuses.delivered],
                ["ordering", "date_issued"],
            ],
            columnDefinition: [
                columnByName["id"],
                columnByName["vendor_name"],
                columnByName["status_label"],
                columnByName["item_count"],
                columnByName["subtotal"],
                columnByName["shipping"],
                columnByName["tracking_number"],
                columnByName["associated_jobs"],
                columnByName["last_delivered_at"],
                columnByName["last_modified_at"],
            ],
        },
        {
            title: "Stocked",
            tabObjectName: "Order",
            showTab: true,
            objectsPhase: "open",
            countEndpoint: tabCountEndpoint,
            listEndpoint: tabListEndpoint,
            getDetailsRoute: getTabObjectDetailsRoute,
            endpointKwargs: [
                ["status", PurchaseOrderStatuses.stocked],
                ["ordering", "date_issued"],
            ],
            columnDefinition: [
                columnByName["id"],
                columnByName["vendor_name"],
                columnByName["status_label"],
                columnByName["item_count"],
                columnByName["subtotal"],
                columnByName["payment_terms"],
                columnByName["associated_jobs"],
                columnByName["last_stocked_at"],
                columnByName["last_modified_at"],
            ],
        },
    ]

    const closedPurchaseOrdersTabs: Tab<PurchaseOrder>[] = [
        {
            title: "All Closed",
            tabObjectName: "Order",
            showTab: true,
            objectsPhase: "closed",
            countEndpoint: tabCountEndpoint,
            listEndpoint: tabListEndpoint,
            getDetailsRoute: getTabObjectDetailsRoute,
            endpointKwargs: [
                ["status", PurchaseOrderStatuses.paid],
                ["status", PurchaseOrderStatuses.cancelled],
                ["ordering", "date_issued"],
            ],
            columnDefinition: [
                columnByName["id"],
                columnByName["vendor_name"],
                columnByName["state_label"],
                columnByName["item_count"],
                columnByName["subtotal"],
                columnByName["associated_jobs"],
                columnByName["last_status_transition_time"],
                columnByName["last_modified_at"],
            ],
        },
        {
            title: "Paid",
            tabObjectName: "Order",
            showTab: true,
            objectsPhase: "closed",
            countEndpoint: tabCountEndpoint,
            listEndpoint: tabListEndpoint,
            getDetailsRoute: getTabObjectDetailsRoute,
            endpointKwargs: [
                ["status", PurchaseOrderStatuses.paid],
                ["ordering", "date_issued"],
            ],
            columnDefinition: [
                columnByName["id"],
                columnByName["vendor_name"],
                columnByName["item_count"],
                columnByName["subtotal"],
                columnByName["associated_jobs"],
                columnByName["last_paid_at"],
                columnByName["last_modified_at"],
            ],
        },
        {
            title: "Cancelled",
            tabObjectName: "Order",
            showTab: true,
            objectsPhase: "closed",
            countEndpoint: tabCountEndpoint,
            listEndpoint: tabListEndpoint,
            getDetailsRoute: getTabObjectDetailsRoute,
            endpointKwargs: [
                ["status", PurchaseOrderStatuses.cancelled],
                ["ordering", "date_issued"],
            ],
            columnDefinition: [
                columnByName["id"],
                columnByName["vendor_name"],
                columnByName["item_count"],
                columnByName["subtotal"],
                columnByName["associated_jobs"],
                columnByName["last_cancelled_at"],
                columnByName["last_modified_at"],
            ],
        },
    ]

    if (!tabListEndpoint) {
        return [null, null]
    } else {
        return [openPurchaseOrdersTabs, closedPurchaseOrdersTabs]
    }
}
