import clsx from "clsx"

import { Icon } from "@atoms/index"

import styles from "./PopoverSubHeader.module.scss"
import { PopoverSubHeaderProps } from "./PopoverSubHeader.types"

export default function PopoverSubHeader(props: PopoverSubHeaderProps) {
    const { title, icon, showBorderBottom } = props

    return (
        <div
            className={clsx(styles.base, {
                [styles.withBorderBottom]: showBorderBottom,
            })}
        >
            <div className={styles.iconAndHeading}>
                {icon && <Icon icon={icon} size={12} />}
                <div className={styles.title}>{title}</div>
            </div>
        </div>
    )
}
