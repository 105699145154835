import { Component } from "react";


class DashboardCardColumn extends Component {

    render = () => {
        const { title, predicate=true, children } = this.props

        if (predicate) {
            return (
                <div className="card-column" aria-label={`Card Column: ${title}`}>
                    <div className="card-column__header" aria-label="Card Column Header">
                        <span className="card-column__header__title" aria-label={title}>{title}</span>
                    </div>
                    <div className="card-column__cards" aria-label="Card Column Content">
                        {children}
                    </div>
                </div>
            )
        }
        else {
            return null
        }
    }
}


export default DashboardCardColumn
