import DashboardContainer from "@legacy/dashboard/DashboardContainer"

import { TopBar } from "@organisms"

import { AppFrame } from "@templates"

export default function Dashboard() {
    return (
        <AppFrame.Root pageId="Dashboard">
            <TopBar.Root>
                <TopBar.Header>
                    <TopBar.TopBarHeaderStart>
                        <TopBar.PageTitle iconName="home" label="Home" />
                    </TopBar.TopBarHeaderStart>
                </TopBar.Header>
            </TopBar.Root>
            <AppFrame.PageContent>
                <DashboardContainer />
            </AppFrame.PageContent>
        </AppFrame.Root>
    )
}
