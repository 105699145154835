import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import * as DropdownMenu from "@radix-ui/react-dropdown-menu"
import clsx from "clsx"
import { Fragment, useEffect, useMemo, useRef } from "react"

import generateRandomKey from "@utils/generateRandomKey"

import { Icon } from "@atoms"

import styles from "./OverflowMenu.module.scss"
import OverflowMenuProps, { Item, SubItem } from "./OverflowMenu.types"

export default function OverflowMenu(props: OverflowMenuProps) {
    const {
        items,
        isOpen,
        onClose,
        onSelect = () => null,
        side = "bottom",
        startingPoint = "right",
        triggerClassName,
    } = props

    const triggerRef = useRef<HTMLDivElement>(null)

    const onOpenChange = (isOpen: boolean) => {
        if (!isOpen) {
            onClose()
        }
    }

    const onItemClick = (item: Item | SubItem) => {
        onSelect(item)
        onClose()

        item.onClick?.()
    }

    const hasDividers = useMemo(() => items.some((item) => item.dividerBottom || item.dividerTop), [items])

    useEffect(() => {
        if (isOpen) {
            triggerRef.current?.focus()
        }
    }, [isOpen])

    return (
        <DropdownMenu.Root open={isOpen} onOpenChange={onOpenChange}>
            <DropdownMenu.Trigger asChild={true} className={triggerClassName}>
                <span
                    className={clsx(styles.trigger, {
                        [styles.toRight]: startingPoint === "right",
                        [styles.toLeft]: startingPoint === "left",
                    })}
                    ref={triggerRef}
                />
            </DropdownMenu.Trigger>
            <DropdownMenu.Portal>
                <DropdownMenu.Content
                    onEscapeKeyDown={onClose}
                    onPointerDownOutside={onClose}
                    className={clsx(styles.menuContent, {
                        [styles.menuContentWithDividers]: hasDividers,
                    })}
                    sideOffset={8}
                    collisionPadding={8}
                    avoidCollisions={true}
                    side={side}
                    align={startingPoint === "right" ? "start" : "end"}
                    loop={true}
                >
                    {items.map((item) => {
                        if (item.subItems) {
                            return (
                                <DropdownMenu.Sub key={generateRandomKey()}>
                                    <DropdownMenu.SubTrigger
                                        className={clsx(styles.menuItem, {
                                            [styles.menuItemDisabled]: item.isDisabled,
                                        })}
                                    >
                                        <div className={styles.menuItemInnerContainer}>
                                            {item.icon && (
                                                <div className={styles.iconWrap}>
                                                    <Icon icon={item.icon} size={16} />
                                                </div>
                                            )}
                                            <DropdownMenu.Label className={styles.itemLabel}>
                                                {item.label}
                                            </DropdownMenu.Label>
                                            <div className={styles.menuItemChevron}>
                                                <Icon
                                                    icon={icon({
                                                        name: "angle-right",
                                                        style: "regular",
                                                        family: "sharp",
                                                    })}
                                                    size={10}
                                                    noContainer={true}
                                                />
                                            </div>
                                        </div>
                                    </DropdownMenu.SubTrigger>
                                    <DropdownMenu.Portal>
                                        <DropdownMenu.SubContent
                                            className={styles.menuSubContent}
                                            loop={true}
                                            sideOffset={0}
                                            collisionPadding={16}
                                            onEscapeKeyDown={onClose}
                                        >
                                            {item.subItems.map((subItem) => (
                                                <DropdownMenu.Item
                                                    key={generateRandomKey()}
                                                    className={styles.subItem}
                                                    onClick={() => onItemClick(subItem)}
                                                >
                                                    <div className={styles.menuItemInnerContainer}>
                                                        {subItem.label}
                                                    </div>
                                                </DropdownMenu.Item>
                                            ))}
                                        </DropdownMenu.SubContent>
                                    </DropdownMenu.Portal>
                                </DropdownMenu.Sub>
                            )
                        } else {
                            return (
                                <Fragment key={generateRandomKey()}>
                                    {item.dividerTop && <DropdownMenu.Separator className={styles.divider} />}
                                    <DropdownMenu.Item
                                        onClick={() => onItemClick(item)}
                                        className={clsx(styles.menuItem, {
                                            [styles.menuItemDisabled]: item.isDisabled,
                                            [styles.menuItemDangerous]: item.isDangerous,
                                        })}
                                    >
                                        <div className={styles.menuItemInnerContainer}>
                                            {item.icon && (
                                                <div className={styles.iconWrap}>
                                                    <Icon icon={item.icon} size={16} />
                                                </div>
                                            )}
                                            <DropdownMenu.Label className={styles.itemLabel}>
                                                {item.label}
                                            </DropdownMenu.Label>
                                        </div>
                                    </DropdownMenu.Item>
                                    {item.dividerBottom && <DropdownMenu.Separator className={styles.divider} />}
                                </Fragment>
                            )
                        }
                    })}
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
        </DropdownMenu.Root>
    )
}
