import Spinner from "@legacy/core/components/Spinner";
import { Component, Fragment } from "react";
import WrapperButton from "../buttons/WrapperButton";
import { PaymentMethods } from "../utils/enums";
import { formatCurrencyForServiceCompany, formatLocalTime } from "../utils/utils";


class PaymentListSelect extends Component {

    constructor() {
        super()

        this.state = {
            payments: null,
        }
    }

    fetchPayments = async () => {
        const response = await fetch(this.props.endpoint)
        return await response.json()
    }

    componentDidMount = async () => {
        if (this.props.object.id !== undefined) {
            const paymentsList = await this.fetchPayments()

            this.setState((state, props) => {
                let updatedState = state
                updatedState.payments = []
                paymentsList.filter(payment => (payment.from_down_payment === undefined || payment.from_down_payment === null)).forEach(payment => {updatedState.payments.push(payment)})

                return updatedState
            })
        }
        else {
            this.setState((state, props) => {
                let updatedState = state
                updatedState.payments = []
                return updatedState
            })
        }
    }

    renderItem = (item, object) => {
        return (
            <Fragment>
                <div className="data-panel__list-select__option__label payment-label">
                    {
                        item.is_uncollectible === true && (
                            <div className="payment-label__row">
                                <div className="uncollectible-designator"><span>Uncollectible / Bad Debt</span></div>
                            </div>
                        )
                    }
                    {
                        item.is_online_payment === true && (
                            <div className="payment-label__row">
                                <div className="stripe-designator"><i className="fa-brands fa-stripe inline-stripe" aria-label="Stripe"></i><span>Online Card Payment</span></div>
                            </div>
                        )
                    }
                    <div className="payment-label__row">
                        <div className="payment-label__field">
                            <div className="payment-label__field-label">Payment Method</div>
                            <div className="payment-label__field-value">{item.payment_method === PaymentMethods.other && item.payment_method_other_name ? item.payment_method_other_name : item.payment_method_label}</div>
                        </div>
                        <div className="payment-label__field">
                            <div className="payment-label__field-label">Amount</div>
                            <div className="payment-label__field-value">{formatCurrencyForServiceCompany(item.amount * -1, object.service_company)}</div>
                        </div>
                    </div>
                    <div className="payment-label__row">
                        <div className="payment-label__field">
                            <div className="payment-label__field-label">Payment Date</div>
                            <div className="payment-label__field-value">{formatLocalTime(item.payment_date, "UTC", true, false, false, true).localDateString}</div>
                        </div>
                    </div>
                    {
                        item.is_online_payment
                        ?
                        <div className="payment-label__row">
                            <div className="payment-label__field">
                                <div className="payment-label__field-label">Reference ID</div>
                                <div className="payment-label__field-value">{item.stripe_charge}</div>
                            </div>
                        </div>
                        :
                        <Fragment>
                            {
                                item.reference_id !== "" && (
                                    <div className="payment-label__row">
                                        <div className="payment-label__field">
                                            <div className="payment-label__field-label">Reference ID</div>
                                            <div className="payment-label__field-value">{item.reference_id}</div>
                                        </div>
                                    </div>
                                )
                            }
                        </Fragment>
                    }
                    {
                        item.payment_memo !== "" && (
                            <div className="payment-label__row">
                                <div className="payment-label__field">
                                    <div className="payment-label__field-label">Memo</div>
                                    <div className="payment-label__field-value">{item.payment_memo}</div>
                                </div>
                            </div>
                        )
                    }
                </div>
                {item.is_online_payment !== true && item.is_uncollectible !== true && <div className="data-panel__list-select__option__icon"><i className="fa-sharp fa-angle-right" aria-hidden="true"></i></div>}
            </Fragment>
        )
    }

    render = () => {
        const {fieldId, fieldName, onSelect, object} = this.props

        if (this.state.payments === null) {
            return (
                <div id="id_payments" className="data-panel__list-select">
                    <Spinner centered={true} />
                </div>
            )
        }
        else {
            return (
                <div id={fieldId} className="data-panel__list-select">
                    {this.state.payments.map(
                        (item, index) => {
                            if (item.is_online_payment) {
                                return (
                                    <div key={index} className="data-panel__list-select__option online-payment-card">
                                        {this.renderItem(item, object)}
                                    </div>
                                )
                            }
                            else if (item.is_uncollectible) {
                                return (
                                    <div key={index} className="data-panel__list-select__option uncollectible-payment-card">
                                        {this.renderItem(item, object)}
                                    </div>
                                )
                            }
                            else {
                                return (
                                    <WrapperButton key={index} type="object_link" handler={event => onSelect(item)} title={item.__str__ || "New Payment"} ariaLabel="Update Payment">
                                        {this.renderItem(item, object)}
                                    </WrapperButton>
                                )
                            }
                        }
                    )}
                </div>
            )
        }
    }
}

export default PaymentListSelect;
