import * as DialogPrimitive from "@radix-ui/react-dialog"

import useModal from "@molecules/Modal/Modal.store"

import { ModalCloseProps } from "./ModalClose.types"

export default function ModalClose(props: ModalCloseProps) {
    const { children, ...rest } = props

    const { closeModal } = useModal()

    return (
        <DialogPrimitive.Close {...rest} onClick={closeModal}>
            {children}
        </DialogPrimitive.Close>
    )
}
