// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PropertySubtitle-base__eC6HL{color:var(--gray-a11);font:var(--font-mobile-body-375);font-feature-settings:var(--feature-settings-mobile-body-375);letter-spacing:var(--letter-spacing-mobile-body-375)}@media only screen and (min-width: 64rem){.PropertySubtitle-base__eC6HL{font:var(--font-global-body-375);font-feature-settings:var(--feature-settings-global-body-375);letter-spacing:var(--letter-spacing-global-body-375)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "PropertySubtitle-base__eC6HL"
};
export default ___CSS_LOADER_EXPORT___;
