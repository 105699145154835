import useUser from "@hooks/useUser"
import formatDuration from "@utils/formatDuration"
import { formatDateTime } from "../utils/utils"

function DateTimeRenderer(props) {
    const { value, type="datetime", options } = props
    const { duration=null, onlyDuration = false } = options
    const { user:currentUser } = useUser()

    const {
        localDateString,
        localTimeString,
        localDateTimeString
    } = formatDateTime(value, currentUser?.service_company.preferred_timezone, options)

    const typeMap = {
        "date": localDateString,
        "time": localTimeString,
        "datetime": localDateTimeString
    }


    if (onlyDuration) {
        return <>{formatDuration(duration)}</>
    }

    return (
        <>
            {`${typeMap[type] || localDateTimeString}${duration ? ` \u2022 ${formatDuration(duration)}` : ""}`}
        </>
    )
}

export default DateTimeRenderer
