import Spinner from "@legacy/core/components/Spinner";
import { Component } from "react";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContactSearchOrCreateSelect, { contactToContactOption } from "../../clients/inputs/ContactSearchOrCreateSelect";
import ButtonGroup from "../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../core/buttons/UniversalButton";
import Banner from "../../core/components/Banner";
import FieldGroup from "../../core/components/FieldGroup";
import SearchCreateSelectField from "../../core/fields/SearchCreateSelectField";
import TextField from "../../core/fields/TextField";


class PurchaseOrderSendForm extends Component {

    constructor(props) {
        super(props)

        this.state = {
            shareLink: window.BASE_URL + window.PUBLIC_URL,
            info: {},

            phoneSearchInput: "",
            emailSearchInput: "",
        }
    }

    componentDidMount() {
        // Return to the previous scroll
        document.querySelector(".main").scrollTo(0, this.props.returnScroll || 0)
    }

    renderButtons = () => {
        const {
            purchaseOrder,
            sendData,
            requestAction,
            switchToPrimaryForm,
            switchToSecondaryForm,
            submitting,
            errors,
            onFormDataChange,
            updateSendPhoneSelection,
            updateSendEmailSelection,
            selectedSendPhoneContacts,
            selectedSendEmailContacts,
            sendViaSMS,
            sendViaEmail,
            sendManually,
            returnScroll
        } = this.props

        if (errors.unexpectedError) {
            return (
                <div className="data-panel__action-feedback">
                    <span className="text-invalid"><strong>An unexpected error occurred.</strong></span>
                </div>
            )
        }
        else {
            if (submitting) {
                return <Spinner centered={true} />
            }
            else {
                return (
                    <ButtonGroup>
                        <ButtonGroupRow>
                            <UniversalButton type="primary" text="Send" handler={event => requestAction("PURCHASE_ORDER_SEND_SUBMIT")} />
                            {purchaseOrder.state_label !== "Pending" && <UniversalButton type="secondary" text="Cancel" handler={event => switchToPrimaryForm()} />}
                        </ButtonGroupRow>
                    </ButtonGroup>
                )
            }
        }
    }

    onShareLinkCopy = () => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState.info.share_link = "Share link copied!"
            return updatedState
        })

        setTimeout(() => this.setState({info: {}}), 5000);
    }

    render() {
        const {
            purchaseOrder,
            sendData,
            requestAction,
            switchToPrimaryForm,
            switchToSecondaryForm,
            submitting,
            errors,
            onFormDataChange,
            updateSendPhoneSelection,
            updateSendEmailSelection,
            selectedSendPhoneContacts,
            selectedSendEmailContacts,
            sendViaSMS,
            sendViaEmail,
            sendManually,
            returnScroll
        } = this.props

        return (
            <div className="data-panel-container data-panel-container--with-margin">
                <div className="data-panel" aria-label="Purchase Order Send">
                    <div className="data-panel__form" aria-label="Purchase Order Send Form">
                        <p className="data-panel__form__caption">Please provide the following information to send this purchase order to your vendor.</p>
                        <div className="data-panel__form__field" id="div_id_share_link" aria-label="Share Link">
                            <label htmlFor="id_share_link" className="data-panel__form__field__label">Share Link</label>
                            <div className="data-panel__form__field__input">
                                <div className="data-panel__form__field__input-group">
                                    <input type="text" name="share_link" className="is-disabled" id="id_share_link" defaultValue={this.state.shareLink} readOnly />
                                    <div className="input-group-append">
                                        <CopyToClipboard text={this.state.shareLink} onCopy={this.onShareLinkCopy}>
                                            {/* CopyToClipboard can't handle react component children */}
                                            <button className="wrapper-button button button--clipboard-copy" title="Copy share link to clipboard"><div className="input-group-text">COPY</div></button>
                                        </CopyToClipboard>
                                    </div>
                                </div>
                            </div>
                            {this.state.info.share_link && <Banner type="info" text={this.state.info.share_link} />}
                        </div>
                        <div className="data-panel__form__field" id="div_id_delivery" aria-label="Delivery Methods">
                            <label htmlFor="id_delivery_methods" className="data-panel__form__field__label in-form">Delivery Methods</label>
                            <div className="data-panel__form__field__input data-panel__form__field__input--with-checkbox">
                                <input type="checkbox" className="checkbox-input" name="send_via_sms" id="id_send_via_sms" checked={sendViaSMS} onChange={event => requestAction("TOGGLE_SEND_VIA_SMS")} />
                                <label className="checkbox-label" htmlFor="id_send_via_sms">Send via Text Message</label>
                            </div>
                            {
                                sendViaSMS && (
                                    <FieldGroup>
                                        <SearchCreateSelectField
                                            fieldName="phone_contacts"
                                            fieldLabel=""
                                            fieldValue={sendData.phone_contacts}
                                            inputComponent={
                                                <ContactSearchOrCreateSelect
                                                    fieldName="phone_contacts"
                                                    isMulti={true}
                                                    restrictOptionsTo="phone"
                                                    onSelectionChange={selectedOptions => updateSendPhoneSelection((selectedOptions || []).map(option => option.object))}
                                                    onInputChange={(input, action) => {action.action === "input-change" && this.setState({phoneSearchInput: input})}}
                                                    defaultSelected={selectedSendPhoneContacts.map(contact => contactToContactOption(contact, "phone"))}
                                                    searchVendorContacts={true}
                                                    vendor={purchaseOrder.vendor}
                                                    showCreateButton={true}
                                                    onCreateClick={event => switchToSecondaryForm("ADD_CONTACT", null, {name: this.state.phoneSearchInput, populationRef: "updateSendPhoneSelection"})}
                                                ></ContactSearchOrCreateSelect>
                                            }
                                            showButton={false}
                                            errors={errors}
                                        ></SearchCreateSelectField>
                                    </FieldGroup>
                                )
                            }
                            <div className="data-panel__form__field__input data-panel__form__field__input--with-checkbox">
                                <input type="checkbox" className="checkbox-input" name="send_via_email" id="id_send_via_email" checked={sendViaEmail} onChange={event => requestAction("TOGGLE_SEND_VIA_EMAIL")} />
                                <label className="checkbox-label" htmlFor="id_send_via_email">Send via Email</label>
                            </div>
                            {
                                sendViaEmail && (
                                    <FieldGroup>
                                        <SearchCreateSelectField
                                            fieldName="email_contacts"
                                            fieldLabel=""
                                            fieldValue={sendData.email_contacts}
                                            inputComponent={
                                                <ContactSearchOrCreateSelect
                                                    fieldName="email_contacts"
                                                    isMulti={true}
                                                    restrictOptionsTo="email"
                                                    onSelectionChange={selectedOptions => updateSendEmailSelection((selectedOptions || []).map(option => option.object))}
                                                    onInputChange={(input, action) => {action.action === "input-change" && this.setState({emailSearchInput: input})}}
                                                    defaultSelected={selectedSendEmailContacts.map(contact => contactToContactOption(contact, "email"))}
                                                    searchVendorContacts={true}
                                                    vendor={purchaseOrder.vendor}
                                                    showCreateButton={true}
                                                    onCreateClick={event => switchToSecondaryForm("ADD_CONTACT", null, {name: this.state.emailSearchInput, populationRef: "updateSendEmailSelection"})}
                                                ></ContactSearchOrCreateSelect>
                                            }
                                            showButton={false}
                                            errors={errors}
                                        ></SearchCreateSelectField>
                                    </FieldGroup>
                                )
                            }
                            {purchaseOrder.state_label === "Pending" && (
                                <div className="data-panel__form__field__input data-panel__form__field__input--with-checkbox" id="div_id_send_manually">
                                    <input type="checkbox" className="checkbox-input" name="send_manually" id="id_send_manually" checked={sendManually} onChange={event => requestAction("TOGGLE_SEND_MANUALLY")} />
                                    <label className="checkbox-label" htmlFor="id_send_manually">Send Manually</label>
                                </div>
                            )}
                            {errors.deliveryMethod && <div className="data-panel__form__field__errors" aria-label="Field Errors">{errors.deliveryMethod}</div>}
                        </div>
                        {
                            (sendViaSMS || sendViaEmail) && (
                                <TextField
                                    fieldName="vendor_message"
                                    fieldLabel="Message to Vendor"
                                    fieldValue={sendData.vendor_message || ""}
                                    fieldOnChange={vendor_message => onFormDataChange("vendor_message", vendor_message || "")}
                                    rows={3}
                                    placeholder={`This will appear on the ${sendViaSMS ? "text message" : ""}${sendViaSMS && sendViaEmail ? " and " : ""}${sendViaEmail ? "email" : ""} sent to your vendor.`}
                                    optional={true}
                                    errors={errors}
                                />
                            )
                        }
                    </div>
                    {this.renderButtons()}
                </div>
            </div>
        )
    }
}

export default PurchaseOrderSendForm;
