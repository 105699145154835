import React, { Component } from "react";
import deepcopy from "rfdc";
import InlineEquipmentWarrantyDisplay from "./components/InlineEquipmentWarrantyDisplay";
import InlineEquipmentWarrantyForm from "./forms/InlineEquipmentWarrantyForm";
import { validateEquipmentWarranty } from "./utils/utils";

const PAGE_MODES = {
    VIEW_EQUIPMENT_WARRANTY: "VIEW_EQUIPMENT_WARRANTY",
    EDIT_EQUIPMENT_WARRANTY: "EDIT_EQUIPMENT_WARRANTY",
}

const FORM_DATA_NAMES_BY_MODE = {
    EDIT_EQUIPMENT_WARRANTY: "warrantyFormData",
}


class InlineEquipmentWarrantyContainer extends Component {

    // Initialize

    constructor(props) {
        super(props)

        let defaultMode = this.props.formMode || PAGE_MODES.VIEW_EQUIPMENT_WARRANTY

        const warranty = deepcopy()(this.props.warranty)
        const isNewAddition = warranty.__new__ === true

        if (isNewAddition) {
            defaultMode = PAGE_MODES.EDIT_EQUIPMENT_WARRANTY
            delete warranty.__new__
        }

        this.state = {
            removed: false,
            isNewAddition: isNewAddition,
            warrantyData: warranty,
            warrantyFormData: deepcopy()(warranty),
            mode: defaultMode,
        }
    }

    // Form helpers

    updateFormData = (formName, fieldName, fieldValue) => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState[formName][fieldName] = fieldValue
            return updatedState
        })
    }

    switchFormMode = (mode) => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState.mode = mode
            return updatedState
        })
    }

    switchToDisplay = () => {
        this.setState((state, props) => {
            let updatedState = state

            // Reset primary form data
            updatedState[FORM_DATA_NAMES_BY_MODE[state.mode]] = deepcopy()(this.props.warranty)
            return updatedState
        })
        this.switchFormMode(PAGE_MODES.VIEW_EQUIPMENT_WARRANTY)
    }

    switchToPrimaryForm = () => {
        this.switchFormMode(PAGE_MODES.EDIT_EQUIPMENT_WARRANTY)
    }

    softDeleteEquipmentWarranty = (json) => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState.removed = true
            return updatedState
        })
    }

    // Handle Actions

    handleActionRequest = (action) => {
        switch (action) {
            case "EQUIPMENT_WARRANTY_EDIT":
                this.switchToPrimaryForm()
                break
            case "EQUIPMENT_WARRANTY_SAVE":
                const { isValid, errors } = validateEquipmentWarranty(this.props.allWarranties, this.state.warrantyFormData, this.props.index)

                if (isValid) {
                    this.setState((state, props) => {
                        let updatedState = state

                        delete state.warrantyFormData.errors
                        updatedState.warrantyData = state.warrantyFormData

                        this.props.updateEquipmentWarranty(updatedState.warrantyData)

                        return updatedState
                    })
                    this.switchFormMode(PAGE_MODES.VIEW_EQUIPMENT_WARRANTY)
                }
                else {
                    this.setState((state, props) => {
                        let updatedState = state
                        updatedState.warrantyFormData.errors = errors
                        return updatedState
                    })
                }
                break
            case "EQUIPMENT_WARRANTY_DELETE":
                this.props.deleteEquipmentWarranty(this.state.warrantyData)
                this.softDeleteEquipmentWarranty()
                break
            case "EQUIPMENT_WARRANTY_CANCEL_EDITS":
                this.switchToDisplay()
                break
            default:
                console.error(`No action handler exists for action "${action}".`)
        }
    }

    // Render

    render = () => {
        if (!this.state.removed) {
            if (this.state.mode === PAGE_MODES.VIEW_EQUIPMENT_WARRANTY) {
                return <InlineEquipmentWarrantyDisplay
                    warranty={this.state.warrantyData}
                    requestAction={this.handleActionRequest}
                />
            }
            else if (this.state.mode === PAGE_MODES.EDIT_EQUIPMENT_WARRANTY) {
                return <InlineEquipmentWarrantyForm
                    warranty={this.state.warrantyFormData}
                    isNewAddition={this.state.isNewAddition}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData(FORM_DATA_NAMES_BY_MODE[this.state.mode], fieldName, fieldValue)}
                    requestAction={this.handleActionRequest}
                />
            }
            else {
                return (
                    <div className="data-panel-container data-panel-container--with-margin">
                        <div className="data-panel" aria-label="Unknown Form Mode">
                            <div className="data-panel__form">
                                <p className="data-panel__form__caption">An unhandled form mode was supplied.</p>
                            </div>
                        </div>
                    </div>
                )
            }
        }
        else {
            return null
        }
    }
}

export default InlineEquipmentWarrantyContainer;
