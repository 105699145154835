import { ColumnId, SortingState } from "@organisms/Table/Table.types"

export default function getSortingStateFromOrdering(ordering: string): SortingState {
    if (ordering) {
        return ordering.split(",").map((order) => ({
            id: (order.startsWith("-") ? order.replace("-", "") : order) as ColumnId,
            desc: order.startsWith("-"),
        }))
    } else {
        return []
    }
}
