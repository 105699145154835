import { flexRender } from "@tanstack/react-table"
import clsx from "clsx"
import { forwardRef } from "react"

import styles from "./TableBodyCell.module.scss"
import { TableBodyCellProps } from "./TableBodyCell.types"

function TableBodyCell(props: TableBodyCellProps, ref: React.Ref<HTMLTableCellElement>) {
    const { cell, isSticky, leftStickyOffset, virtualRow, isLastSticky, isScrolling, width, isTabbable } = props

    return (
        <td
            tabIndex={isTabbable ? null : -1}
            ref={ref}
            className={clsx(styles.base, {
                [styles.sticky]: isSticky,
                [styles.lastSticky]: isLastSticky,
                [styles.scrolling]: isScrolling,
            })}
            style={{
                minWidth: width,
                flexBasis: width,
                height: `${virtualRow.size}px`,
                left: isSticky ? `${leftStickyOffset}px` : "auto",
            }}
        >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
    )
}

export default forwardRef(TableBodyCell)
