// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SrOnly-base__dlwaJ{position:absolute;width:var(--size-0_25);height:var(--size-0_25);margin:calc(var(--size-0_25)*-1);padding:0;overflow:hidden;white-space:nowrap;border-width:0;clip:rect(0, 0, 0, 0)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "SrOnly-base__dlwaJ"
};
export default ___CSS_LOADER_EXPORT___;
