import clsx from "clsx"
import { useMemo, useState } from "react"

import debounce from "@utils/debounce"

import { Badge, Icon, Tooltip } from "@atoms"
import { BadgeProps } from "@atoms/Badge/Badge.types"
import { IconSize } from "@atoms/Icon/Icon.types"

import useTabStack from "@molecules/TabStack/TabStack.store"
import { TabStackProps } from "@molecules/TabStack/TabStack.types"

import styles from "./Tab.module.scss"
import { TabProps } from "./Tab.types"

export default function Tab(props: TabProps) {
    const {
        icon,
        iconInReactNode,
        children,
        count,
        isPressed = false,
        isDisabled = false,
        isTabbable = true,
        onClick,
        noDebounce,
        title,
    } = props

    const { attached, size } = useTabStack()

    const [isFocused, setIsFocused] = useState(false)
    const [isHovering, setIsHovering] = useState(false)
    const [isActive, setIsActive] = useState(false)

    const iconSize = useMemo(() => {
        const sizeMap: { [key in TabStackProps["size"]]: IconSize } = {
            xs: 12,
            sm: 14,
            md: 14,
            lg: 16,
            xl: 16,
        }
        return sizeMap[size]
    }, [size])

    const badgeSize = useMemo(() => {
        const sizeMap: { [key in TabStackProps["size"]]: BadgeProps["size"] } = {
            xs: "sm",
            sm: "md",
            md: "md",
            lg: "lg",
            xl: "lg",
        }
        return sizeMap[size]
    }, [size])

    const desiredIcon = useMemo(() => {
        if (iconInReactNode) {
            return iconInReactNode
        } else if (icon) {
            return (
                <div className={styles.icon}>
                    <Icon icon={icon} size={iconSize} />
                </div>
            )
        } else {
            return null
        }
    }, [icon, iconInReactNode])

    const showActiveBadge = isPressed || isFocused || isHovering || isActive

    return (
        <Tooltip label={title}>
            <button
                onClick={
                    noDebounce
                        ? onClick
                        : debounce((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onClick(event))
                }
                type="button"
                disabled={isDisabled}
                role="tab"
                className={clsx(styles.base, styles[`${size}Size`], {
                    [styles.isPressed]: isPressed,
                    [styles.attached]: attached,
                    [styles.iconOnly]: !children && count === undefined,
                })}
                tabIndex={isTabbable ? 0 : -1}
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                onMouseDown={() => setIsActive(true)}
                onMouseUp={() => setIsActive(false)}
            >
                {(icon || iconInReactNode) && desiredIcon}
                {children && <div className={styles.content}>{children}</div>}
                {count !== undefined && (
                    <div className={styles.badgeContainer}>
                        <Badge
                            colorScheme={showActiveBadge ? "white" : "gray"}
                            size={badgeSize}
                            variant={showActiveBadge ? "solid" : "subtle"}
                            type="counter"
                        >
                            {count}
                        </Badge>
                    </div>
                )}
            </button>
        </Tooltip>
    )
}
