import { ColumnId, TableDataType } from "@organisms/Table/Table.types"

export const tableDataTypeByColumnId: {
    [key in ColumnId]?: TableDataType
} = {
    id: "alphabetical",
    client_name: "alphabetical",
    state_label: "other",
    status_label: "other",
    service_name: "alphabetical",
    service_location_address: "alphabetical",
    total: "numerical",
    down_payment_status: "other",
    last_status_transition_time: "timing",
    last_sent_at: "timing",
    last_approved_at: "timing",
    last_won_at: "timing",
    last_lost_at: "timing",
    last_cancelled_at: "timing",
    last_modified_at: "timing",
    last_viewed: "other",
    job_type: "other",
    assigned_technicians: "other",
    date_received: "timing",
    estimated_arrival_time: "timing",
    estimated_duration: "numerical",
    last_scheduled_at: "timing",
    last_active_at: "timing",
    last_paused_at: "timing",
    last_on_hold_at: "timing",
    last_completed_at: "timing",
    last_finalized_at: "timing",
    amount_due: "numerical",
    date_due: "timing",
    last_paid_at: "timing",
    last_voided_at: "timing",
    last_forgiven_at: "timing",
    vendor_name: "alphabetical",
    item_count: "numerical",
    subtotal: "numerical",
    shipping: "alphabetical",
    preferred_shipping: "alphabetical",
    tracking_number: "alphabetical",
    associated_jobs: "other",
    date_issued: "timing",
    payment_terms: "alphabetical",
    last_acknowledged_at: "timing",
    last_in_transit_at: "timing",
    last_delivered_at: "timing",
    last_stocked_at: "timing",
    name: "alphabetical",
    client_type_label: "other",
    service_locations_display: "alphabetical",
    default_invoice_net: "numerical",
    credit_limit: "numerical",
    outstanding_balance: "numerical",
    current_estimates_count: "numerical",
    current_jobs_count: "numerical",
    current_invoices_count: "numerical",
    description: "alphabetical",
    summary_template: "alphabetical",
    rate: "numerical",
    cost: "numerical",
    default_price: "numerical",
    discount: "numerical",
    tax_rate: "numerical",
    manufacturer: "alphabetical",
    part_number: "alphabetical",
    default_job_duration: "numerical",
    labels: "other",
    inventory_status: "other",
    account_id: "alphabetical",
    billing_address_formatted: "alphabetical",
    website: "alphabetical",
    current_purchase_order_count: "numerical",
}
