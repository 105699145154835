import { Component, Fragment } from "react";
import ButtonGroup from "../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../core/buttons/UniversalButton";
import ClientInformationDisplay from "../../core/components/ClientInformationDisplay";
import ContactListDisplay from "../../core/components/ContactListDisplay";
import BasicDisplayField from "../../core/fields/BasicDisplayField";
import { formatLocalTime, valueIsDefined } from "../../core/utils/utils";
import { renderJobTime } from "../../jobs/utils/utils";
import { getPricebookTaxById } from "../../pricebook/utils/utils";
import { getClientDefaultTaxDisplay, getClientDefaultTermsDisplay } from "../utils/utils";
import ClientFeedAccordion from "./ClientFeedAccordion";
import ServiceLocationListDisplay from "./ServiceLocationListDisplay";

class ClientDetailsCard extends Component {

    constructor(props) {
        super(props);

        this.priceBookTaxes = window.PRICEBOOK_TAXES || []
        this.pricebook_tax = getPricebookTaxById(props.client.default_pricebook_tax, this.priceBookTaxes)
    }

    componentDidMount() {
        // Return to the previous scroll
        document.querySelector(".main").scrollTo(0, this.props.returnScroll || 0)
    }

    renderButtons = () => {
        const { client, requestAction, switchToSecondaryForm, formatCurrencyValue, returnScroll } = this.props

        return (
            <ButtonGroup>
                {client.is_active && (
                    <ButtonGroupRow>
                        <UniversalButton type="primary" text="Edit Client" handler={event => requestAction("CLIENT_EDIT")} />
                    </ButtonGroupRow>
                )}
                {client.is_active ? (
                    <ButtonGroupRow>
                        <UniversalButton type="secondary" text="Deactivate Client" handler={event => requestAction("CLIENT_DEACTIVATE")} />
                    </ButtonGroupRow>
                ) :
                    <ButtonGroupRow>
                        <UniversalButton type="secondary" text="Reactivate Client" handler={event => requestAction("CLIENT_REACTIVATE")} />
                    </ButtonGroupRow>
                }
                <ButtonGroupRow>
                    <UniversalButton type="secondary" text="View Logged Equipment" handler={event => requestAction("CLIENT_EQUIPMENT_VIEW")} />
                </ButtonGroupRow>
                {client.is_active && window.ACCOUNTING_INTEGRATION !== 1 && (
                    <ButtonGroupRow>
                        <UniversalButton type="secondary" text="Merge Client" handler={event => requestAction("CLIENT_MERGE")} />
                        <UniversalButton type="secondary" text="Merge Locations" handler={event => requestAction("CLIENT_LOCATION_MERGE")} />
                    </ButtonGroupRow>
                )}
            </ButtonGroup>
        )

    }

    renderSyncTime = (syncTime, preferredTimezone) => {
        if (syncTime) {
            const { localDateTimeString } = formatLocalTime(syncTime, preferredTimezone, false, true, false, true)
            return localDateTimeString
        }
        else {
            return "\u2014"
        }
    }

    render() {
        const { client, requestAction, switchToSecondaryForm, formatCurrencyValue, returnScroll } = this.props

        return (
            <Fragment>
                <div className="header-wall" aria-hidden="true"></div>
                <div className="data-panel-container">
                    <div className="data-panel" aria-label="Client Details">
                        {
                            client.logo && (
                                <div className="data-panel__floating-logo" aria-hidden="true">
                                    <div className="data-panel__floating-logo__frame">
                                        <img src={client.logo} alt="Logo" />
                                    </div>
                                </div>
                            )
                        }
                        <ClientInformationDisplay
                            client={client}
                        ></ClientInformationDisplay>
                        <div className="data-panel__form" aria-label="Detailed Information">
                            <BasicDisplayField
                                fieldName="client_type"
                                fieldLabel="Client Type"
                                fieldValue={client.client_type_label}
                            ></BasicDisplayField>
                            <BasicDisplayField
                                fieldName="industry_type"
                                fieldLabel="Industry Type"
                                fieldValue={client.industry_type_label || "--"}
                            ></BasicDisplayField>
                            <BasicDisplayField
                                fieldName="billing_address"
                                fieldLabel="Billing Address"
                                fieldValue={client.billing_address_formatted_multiline || "--"}
                            ></BasicDisplayField>
                            <BasicDisplayField
                                fieldName="notes"
                                fieldLabel="Client Notes"
                                fieldValue={client.notes || "--"}
                            ></BasicDisplayField>
                            <BasicDisplayField
                                fieldName="credit_limit"
                                fieldLabel="Credit Limit"
                                fieldValue={valueIsDefined(client.credit_limit) ? formatCurrencyValue(client.credit_limit) : "--"}
                            ></BasicDisplayField>
                            <BasicDisplayField
                                fieldName="default_payment_terms"
                                fieldLabel="Default Client Payment Terms"
                                fieldValue={getClientDefaultTermsDisplay(client.default_invoice_net, client.service_company_default_invoice_net)}
                            ></BasicDisplayField>
                            <BasicDisplayField
                                fieldName="default_pricebook_tax"
                                fieldLabel="Default Client Tax Rate"
                                fieldValue={getClientDefaultTaxDisplay(this.pricebook_tax, client.service_company_default_pricebook_tax, this.priceBookTaxes)}
                            ></BasicDisplayField>
                            {
                                client.is_active && client.last_reactivated_message !== "" && (
                                    <BasicDisplayField
                                        fieldName="last_reactivated_message"
                                        fieldLabel="Reactivation Reason"
                                        fieldValue={client.last_reactivated_message}
                                    ></BasicDisplayField>
                                )
                            }
                            {
                                !client.is_active && client.last_deactivated_message !== "" && (
                                    <BasicDisplayField
                                        fieldName="last_deactivated_message"
                                        fieldLabel="Deactivation Reason"
                                        fieldValue={client.last_deactivated_message}
                                    ></BasicDisplayField>
                                )
                            }
                            <BasicDisplayField
                                fieldName="last_modified_at"
                                fieldLabel="Last Modified"
                                fieldValue={renderJobTime(client.last_modified_at, window.PREFERRED_TIMEZONE)}
                            ></BasicDisplayField>
                            <ContactListDisplay
                                fieldName="contacts"
                                fieldLabel="Client Contacts"
                                contacts={client.contacts}
                                primaryContactId={client.primary_contact}
                            ></ContactListDisplay>
                            <ServiceLocationListDisplay
                                fieldName="service_locations"
                                fieldLabel="Service Locations"
                                serviceLocations={client.service_locations}
                            ></ServiceLocationListDisplay>
                            {
                                client.accounting_sync_status !== null && (
                                    <BasicDisplayField
                                        fieldName="accounting_sync_status"
                                        fieldLabel="Accounting Sync Status"
                                        fieldValue={client.accounting_sync_status.needs_syncing? "Not Synced" : `Synced on ${this.renderSyncTime(client.accounting_sync_status.last_synced_at, window.PREFERRED_TIMEZONE)}`}
                                    ></BasicDisplayField>
                                )
                            }
                        </div>
                        {this.renderButtons()}
                    </div>
                </div>
                {
                    valueIsDefined(client.id) && (
                        <div className="accordion-wrapper">
                            <ClientFeedAccordion clientID={client.id} />
                        </div>
                    )
                }
            </Fragment>
        )
    }

}

export default ClientDetailsCard;
