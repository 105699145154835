import React, { Component } from "react";


class FieldGroup extends Component {

    render() {
        return (
            <div className="data-panel__form__field__group">
                <div className="data-panel__form__field__group__inputs">
                    {this.props.children}
                </div>
            </div>
        )
    }
}

export default FieldGroup;
