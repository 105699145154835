// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UserNamesForms-base__2dEON{display:flex;flex-direction:column;gap:var(--size-6);align-items:stretch;align-self:stretch}@media only screen and (min-width: 64rem){.UserNamesForms-base__2dEON{flex-direction:row;gap:var(--size-4)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "UserNamesForms-base__2dEON"
};
export default ___CSS_LOADER_EXPORT___;
