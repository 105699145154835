import { Component } from "react";
import { renderRecordServiceLocationString } from "../utils/utils";


class QuickBooksServiceLocationDisplay extends Component {

    renderServiceLocationMatchFieldText = (serviceLocationRecord, serviceLocation, fieldName) => {
        if (serviceLocation === null || serviceLocation === undefined) {
            if (serviceLocation === undefined) {
                Sentry.captureException(new Error(`Anomaly ${serviceLocationRecord.id}`))
            }
            return
        }

        let recordValue = serviceLocationRecord[`cleaned_service_location_${fieldName}`]
        let serviceLocationValue = serviceLocation[fieldName]

        if (fieldName === "physical_address_formatted") {
            recordValue = renderRecordServiceLocationString(serviceLocationRecord)
            serviceLocationValue = (serviceLocation.name ? `${serviceLocation.name} / ` : "") + serviceLocation.physical_address_formatted
        }

        if (serviceLocationRecord.recommended_action_label === "Match" || serviceLocationRecord.recommended_action_label === "Merge") {
            if (recordValue !== "" && serviceLocationValue === "") {
                return <span className="data-panel__form__field__success"><i className="fa-sharp fa-light fa-square-plus" aria-hidden="true"></i>&nbsp;&nbsp;This will be added to the existing Roopairs service location.</span>
            }
            else if (recordValue !== serviceLocationValue) {
                return <span className="data-panel__form__field__warning"><i className="fa-sharp fa-light fa-triangle-exclamation" aria-hidden="true"></i>&nbsp;&nbsp;This does not match the existing Roopairs service location and will be ignored.</span>
            }
        }
    }

    render = () => {
        const { record, serviceLocationRecord } = this.props
        const matchedServiceLocation = serviceLocationRecord.action_target ? serviceLocationRecord.action_target.service_location : null

        return (
            <div className="data-panel__form__field__display-list__object record-display" aria-label="Service Location Record">
                {Object.keys(serviceLocationRecord.errors).length === 0 && Object.keys(record.errors).length === 0 && record.child_has_errors === false && (record.recommended_action_label === "Match" || record.recommended_action_label === "Merge") && serviceLocationRecord.recommended_action_label === "Create" && <span className="data-panel__form__field__success" style={{margin: "0 0 1em"}}><i className="fa-sharp fa-light fa-square-plus" aria-hidden="true"></i>&nbsp;&nbsp;This service location will be added to the existing Roopairs client.</span>}
                <div className="record-display__row">
                    <div className="record-display__field" aria-label="Location Name">
                        <div className="record-display__field-label" aria-hidden="true">Location Name</div>
                        <div className="record-display__field-value">{serviceLocationRecord.cleaned_service_location_name || "--"}</div>
                        {this.renderServiceLocationMatchFieldText(serviceLocationRecord, matchedServiceLocation, "name")}
                    </div>
                </div>
                <div className="record-display__row">
                    <div className="record-display__field" aria-label="Location Address">
                        <div className={"record-display__field-label" + (serviceLocationRecord.errors.cleaned_service_location_physical_address_street ? " invalid" : "")} aria-hidden="true">Location Address</div>
                        <div className="record-display__field-value">{serviceLocationRecord.cleaned_service_location_physical_address_formatted_multiline || "--"}</div>
                        {serviceLocationRecord.errors.cleaned_service_location_physical_address_street && <div className="data-panel__form__field__errors" aria-label="Field Errors">{serviceLocationRecord.errors.cleaned_service_location_physical_address_street}</div>}
                        {this.renderServiceLocationMatchFieldText(serviceLocationRecord, matchedServiceLocation, "physical_address_formatted")}
                    </div>
                </div>
                <div className="record-display__row">
                    <div className="record-display__field" aria-label="Location Billing Address">
                        <div className="record-display__field-label" aria-hidden="true">Location Billing Address</div>
                        <div className="record-display__field-value">{serviceLocationRecord.cleaned_service_location_billing_address_formatted_multiline || "Same as client"}</div>
                        {this.renderServiceLocationMatchFieldText(serviceLocationRecord, matchedServiceLocation, "billing_address_formatted")}
                    </div>
                </div>
                <div className="record-display__row">
                    <div className="record-display__field" aria-label="Gate Codes, Hazards, etc.">
                        <div className="record-display__field-label" aria-hidden="true">Gate Codes, Hazards, etc.</div>
                        <div className="record-display__field-value">{serviceLocationRecord.cleaned_service_location_notes || "--"}</div>
                        {this.renderServiceLocationMatchFieldText(serviceLocationRecord, matchedServiceLocation, "notes")}
                    </div>
                </div>
                <div className="record-display__row">
                    <div className="record-display__field" aria-label="Location Contacts">
                        <div className="record-display__field-label" aria-hidden="true">Location Contacts</div>
                        {serviceLocationRecord.errors.contacts && <div className="data-panel__form__field__errors" aria-label="Field Errors">Some of this location's contacts have errors</div>}
                        <div className="record-display__field-value">{serviceLocationRecord.contacts.length !== 0 ? `${serviceLocationRecord.contacts.length} contact${serviceLocationRecord.contacts.length !== 1 ? "s" : ""}` : "(No contacts)"}</div>
                    </div>
                </div>
            </div>
        )
    }
}

export default QuickBooksServiceLocationDisplay;
