// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MainSideBar-base__ts31T{display:flex;flex-direction:column;flex-shrink:0;justify-content:space-between;width:var(--size-64);height:var(--size-full);background-color:rgba(0,0,0,0);border-right:var(--size-0_25) solid var(--gray-a3)}.MainSideBar-header__XbqqH{display:flex;align-items:center;justify-content:space-between;padding:var(--size-2) var(--size-3)}.MainSideBar-navigation__Cj1GU{display:flex;flex:1;flex-direction:column;justify-content:space-between;overflow-y:auto;border-bottom:var(--size-0_25) solid var(--gray-a3)}.MainSideBar-navigation__Cj1GU.MainSideBar-scrolling___n7O3{border-top:var(--size-0_25) solid var(--gray-a3)}.MainSideBar-primaryNavigation__byS2r{display:flex;flex-direction:column;gap:var(--size-0_5);padding:var(--size-0) var(--size-3)}.MainSideBar-secondaryNavigation__Oqv8u{display:flex;flex-direction:column;gap:var(--size-0_5);padding:var(--size-3)}.MainSideBar-searchInput__OwncY{margin-bottom:var(--size-2);padding:var(--size-0) var(--size-3)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "MainSideBar-base__ts31T",
	"header": "MainSideBar-header__XbqqH",
	"navigation": "MainSideBar-navigation__Cj1GU",
	"scrolling": "MainSideBar-scrolling___n7O3",
	"primaryNavigation": "MainSideBar-primaryNavigation__byS2r",
	"secondaryNavigation": "MainSideBar-secondaryNavigation__Oqv8u",
	"searchInput": "MainSideBar-searchInput__OwncY"
};
export default ___CSS_LOADER_EXPORT___;
