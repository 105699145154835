import { Fragment } from "react";
import SearchOrCreateSelectInput from "../../core/inputs/SearchOrCreateSelectInput";
import { formatLocalTime } from "../../core/utils/utils";
import { getJobStatusOrSubStatusLabel } from "../utils/utils";


function getJobObjectLabel(job, preferredTimezone) {
    return <Fragment>
        <span><strong>{`[${job.job_type}] ${job.service_name} (${getJobStatusOrSubStatusLabel(job)})`}</strong><br></br></span>
        <span className="select-label-secondary-level"><em>{job.custom_id || job.id}</em><br></br></span>
        {job.estimated_arrival_time && <span className="select-label-secondary-level">{formatLocalTime(job.estimated_arrival_time, preferredTimezone, false, true, false, true).localDateTimeString}</span>}
    </Fragment>
}

function jobToJobOption(job, preferredTimezone) {
    return {value: job.id, label: getJobObjectLabel(job, preferredTimezone), object: job}
}

function JobSearchOrCreateSelect(props) {
    const {preferredTimezone} = props

    return <SearchOrCreateSelectInput
        objectName="Job"
        getObjectLabel={(job) => getJobObjectLabel(job, preferredTimezone)}
        listEndpoint={DjangoUrls["jobs:api-jobs-lightweight-list"](window.MARKETPLACE_ENTITY_SLUG)}
        listEndpointKwargs={[
            ["ordering", "status,-estimated_arrival_time"],
        ]}
        getDetailsEndpoint={(job) => DjangoUrls["jobs:api-jobs-detail"](window.MARKETPLACE_ENTITY_SLUG, job.id)}
        {...props}
    />
}

export { jobToJobOption };
export default JobSearchOrCreateSelect
