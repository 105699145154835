import dayjs from "dayjs"
import timezone from "dayjs/plugin/timezone"

import useObjectDetailsRoutes from "@hooks/useObjectDetailsRoute"
import useSlugExtractor from "@hooks/useSlugExtractor"

//import useUser from "@hooks/useUser"
import { Table } from "@organisms"
import { Cells, Columns } from "@organisms/Table/Table.types"
import { GetObjectDetailsRouteFunction, Tab } from "@organisms/Table/TableViewControls/TableViewTab/TableViewTab.types"

import { VENDOR_ROUTES } from "@routes/vendor"

dayjs.extend(timezone)

export default function useVendorTabs(): [Tab<Vendor>[], Tab<Vendor>[]] {
    const [tabCountEndpoint, tabListEndpoint] = useSlugExtractor([VENDOR_ROUTES.COUNT, VENDOR_ROUTES.LIGHTWEIGHT_LIST])

    const getTabObjectDetailsRoute = useObjectDetailsRoutes("Vendor") as GetObjectDetailsRouteFunction

    const headerByColumn = {
        name: "Vendor",
        account_id: "Account ID",
        billing_address_formatted: "Billing address",
        payment_terms: "Terms",
        current_purchase_order_count: "Current POs",
        outstanding_balance: "Current Balance",
        website: "Website",
        last_modified_at: "Last modified",
    }

    const sortingEnabledByColumn = {
        name: true,
        account_id: true,
        billing_address_formatted: true,
        payment_terms: true,
        outstanding_balance: true,
        current_purchase_order_count: true,
        website: false,
        last_modified_at: true,
    }

    const widthByColumn = {
        name: 224,
        account_id: 128,
        billing_address_formatted: 288,
        payment_terms: 144,
        outstanding_balance: 160,
        current_purchase_order_count: 144,
        website: 224,
        last_modified_at: 176,
    }

    const pinningByColumn = {
        name: false,
        account_id: false,
        billing_address_formatted: false,
        payment_terms: true,
        current_purchase_order_count: true,
        outstanding_balance: true,
        website: false,
        last_modified_at: false,
    }

    const cellByColumn: Cells<Vendor> = {
        name: ({ getValue }) => <Table.Cells.TruncatedTextCell content={getValue()} />,
        account_id: ({ getValue }) => <Table.Cells.TruncatedTextCell content={getValue()} />,
        billing_address_formatted: ({ getValue }) => (
            <Table.Cells.TruncatedTextCell content={getValue()} variant="secondary" />
        ),
        payment_terms: ({ getValue }) => <Table.Cells.PaymentTermsCell terms={getValue()} />,
        current_purchase_order_count: ({ getValue }) => (
            <Table.Cells.NumericalDataCell value={getValue()} type="number" />
        ),
        outstanding_balance: ({ getValue }) => (
            <Table.Cells.NumericalDataCell value={getValue()} ignoreZero={true} type="currency" />
        ),
        website: ({ getValue }) => <Table.Cells.LinkCell destination={getValue()} />,
        last_modified_at: ({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />,
    }

    const columnByName: Columns<Vendor> = {
        name: {
            header: headerByColumn["name"],
            accessorKey: "name",
            id: "name",
            size: widthByColumn["name"],
            cell: cellByColumn["name"],
            enableSorting: sortingEnabledByColumn["name"],
            enablePinning: pinningByColumn["name"],
        },
        account_id: {
            header: headerByColumn["account_id"],
            accessorKey: "account_id",
            id: "account_id",
            size: widthByColumn["account_id"],
            cell: cellByColumn["account_id"],
            enableSorting: sortingEnabledByColumn["account_id"],
            enablePinning: pinningByColumn["account_id"],
        },
        billing_address_formatted: {
            header: headerByColumn["billing_address_formatted"],
            accessorKey: "billing_address_formatted",
            id: "billing_address_formatted",
            size: widthByColumn["billing_address_formatted"],
            cell: cellByColumn["billing_address_formatted"],
            enableSorting: sortingEnabledByColumn["billing_address_formatted"],
            enablePinning: pinningByColumn["billing_address_formatted"],
        },
        payment_terms: {
            header: headerByColumn["payment_terms"],
            accessorKey: "payment_terms",
            id: "payment_terms",
            size: widthByColumn["payment_terms"],
            cell: cellByColumn["payment_terms"],
            enableSorting: sortingEnabledByColumn["payment_terms"],
            enablePinning: pinningByColumn["payment_terms"],
        },
        current_purchase_order_count: {
            header: headerByColumn["current_purchase_order_count"],
            accessorKey: "current_purchase_order_count",
            id: "current_purchase_order_count",
            size: widthByColumn["current_purchase_order_count"],
            cell: cellByColumn["current_purchase_order_count"],
            enableSorting: sortingEnabledByColumn["current_purchase_order_count"],
            enablePinning: pinningByColumn["current_purchase_order_count"],
        },
        outstanding_balance: {
            header: headerByColumn["outstanding_balance"],
            accessorKey: "outstanding_balance",
            id: "outstanding_balance",
            size: widthByColumn["outstanding_balance"],
            cell: cellByColumn["outstanding_balance"],
            enableSorting: sortingEnabledByColumn["outstanding_balance"],
            enablePinning: pinningByColumn["outstanding_balance"],
        },
        website: {
            header: headerByColumn["website"],
            accessorKey: "website",
            id: "website",
            size: widthByColumn["website"],
            cell: cellByColumn["website"],
            enableSorting: sortingEnabledByColumn["website"],
            enablePinning: pinningByColumn["website"],
        },
        last_modified_at: {
            header: headerByColumn["last_modified_at"],
            accessorKey: "last_modified_at",
            id: "last_modified_at",
            size: widthByColumn["last_modified_at"],
            cell: cellByColumn["last_modified_at"],
            enableSorting: sortingEnabledByColumn["last_modified_at"],
            enablePinning: pinningByColumn["last_modified_at"],
        },
    }

    const activeVendorsTabs: Tab<Vendor>[] = [
        {
            title: "All Active",
            tabObjectName: "Vendor",
            showTab: true,
            objectsPhase: "active",
            countEndpoint: tabCountEndpoint,
            listEndpoint: tabListEndpoint,
            getDetailsRoute: getTabObjectDetailsRoute,
            endpointKwargs: [
                ["ordering", "name"],
                ["is_active", "true"],
            ],
            columnDefinition: [
                columnByName["name"],
                columnByName["account_id"],
                columnByName["billing_address_formatted"],
                columnByName["payment_terms"],
                columnByName["current_purchase_order_count"],
                columnByName["outstanding_balance"],
                columnByName["website"],
                columnByName["last_modified_at"],
            ],
        },
    ]

    const inactiveVendorsTabs: Tab<Vendor>[] = [
        {
            title: "All Inactive",
            tabObjectName: "Vendor",
            showTab: true,
            objectsPhase: "inactive",
            countEndpoint: tabCountEndpoint,
            listEndpoint: tabListEndpoint,
            getDetailsRoute: getTabObjectDetailsRoute,
            endpointKwargs: [
                ["ordering", "name"],
                ["is_active", "false"],
            ],
            columnDefinition: [
                columnByName["name"],
                columnByName["account_id"],
                columnByName["billing_address_formatted"],
                columnByName["payment_terms"],
                columnByName["current_purchase_order_count"],
                columnByName["outstanding_balance"],
                columnByName["website"],
                columnByName["last_modified_at"],
            ],
        },
    ]

    if (!tabListEndpoint) {
        return [null, null]
    } else {
        return [activeVendorsTabs, inactiveVendorsTabs]
    }
}
