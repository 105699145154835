// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UnscheduledJobsHeader-base__Dl2hJ{display:flex;flex-shrink:0;gap:var(--size-1);align-items:center;width:var(--size-72);height:var(--size-13);padding:var(--size-3) var(--size-4);background:var(--white);border-bottom:var(--size-0_25) solid var(--gray-3);border-left:var(--size-0_25) solid var(--gray-3)}@media only screen and (min-width: 64rem){.UnscheduledJobsHeader-base__Dl2hJ{height:var(--size-14)}}.UnscheduledJobsHeader-questionMarkAndTitle__wCqDN{display:flex;flex:1 0 0;gap:var(--size-1_5);align-items:center}.UnscheduledJobsHeader-title__qjgty{color:var(--gray-12);font:var(--font-global-heading-03);font-feature-settings:var(--feature-settings-global-heading-03);letter-spacing:var(--letter-spacing-global-heading-03)}.UnscheduledJobsHeader-questionMarkIcon__AF4gL{color:var(--violet-9)}.UnscheduledJobsHeader-questionMark__w5o79{cursor:pointer}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "UnscheduledJobsHeader-base__Dl2hJ",
	"questionMarkAndTitle": "UnscheduledJobsHeader-questionMarkAndTitle__wCqDN",
	"title": "UnscheduledJobsHeader-title__qjgty",
	"questionMarkIcon": "UnscheduledJobsHeader-questionMarkIcon__AF4gL",
	"questionMark": "UnscheduledJobsHeader-questionMark__w5o79"
};
export default ___CSS_LOADER_EXPORT___;
