import { NavigationLink } from "@atoms"

import { Breadcrumb } from "@organisms/index"

import { Settings } from "@templates"

import { SETTINGS_ROUTES } from "@routes/settings"

import NotificationTopicsCards from "./NotificationTopicsCards/NotificationTopicsCards"
import NotificationMethodsSection from "./components/NotificationMethodsSection/NotificationMethodsSection"

export default function SettingsNotifications() {
    return (
        <Settings.Wrapper title="Notifications">
            <Settings.PageContainer>
                <Settings.Breadcrumbs>
                    <Breadcrumb.Item label="My account" showSlash={true} />
                    <Breadcrumb.Item label={"Notifications"} path={SETTINGS_ROUTES.notifications} />
                </Settings.Breadcrumbs>
                <Settings.PageHeader
                    variant="page-heading"
                    title="Notifications"
                    subtitle="Select how and when you'll be notified."
                />

                <Settings.Divider />

                <Settings.Section>
                    <Settings.PageHeader
                        variant="section-heading"
                        title="Notification methods"
                        subtitle="Choose the methods via which you want to stay up to date with new activity."
                        infoMessage={
                            <>
                                Notifications will be sent to the contact information in your{" "}
                                <NavigationLink title="Profile" path={SETTINGS_ROUTES.profile} isUnderLine={true}>
                                    {() => "Profile"}
                                </NavigationLink>
                                .
                            </>
                        }
                    />
                    <NotificationMethodsSection />
                </Settings.Section>

                <Settings.Divider />

                <Settings.Section>
                    <Settings.PageHeader
                        variant="section-heading"
                        title="Notification topics"
                        subtitle="Select which notification topics you want to receive via each method."
                    />
                    <NotificationTopicsCards />
                </Settings.Section>
            </Settings.PageContainer>
        </Settings.Wrapper>
    )
}
