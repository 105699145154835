// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NotificationConfigPage-wrapper__XO6VH{display:flex;flex-direction:column;gap:var(--size-1);width:var(--size-full)}.NotificationConfigPage-checkboxes__bM17O{display:flex;gap:var(--size-11);align-items:center;justify-content:flex-end}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "NotificationConfigPage-wrapper__XO6VH",
	"checkboxes": "NotificationConfigPage-checkboxes__bM17O"
};
export default ___CSS_LOADER_EXPORT___;
