// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TopBarPageTitle-base__xN_tO{position:relative;display:flex;flex-shrink:0;align-items:center}.TopBarPageTitle-dropdownTrigger__UhSP9{top:calc(var(--size-full) - var(--size-1_5));left:var(--size-0)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "TopBarPageTitle-base__xN_tO",
	"dropdownTrigger": "TopBarPageTitle-dropdownTrigger__UhSP9"
};
export default ___CSS_LOADER_EXPORT___;
