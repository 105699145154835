import clsx from "clsx"

import styles from "./CounterBadge.module.scss"
import { CounterBadgeProps } from "./CounterBadge.types"

export default function CounterBadge(props: CounterBadgeProps) {
    const { size, current, total } = props

    return (
        <div
            className={clsx(styles.base, {
                [styles.sizeLg]: size === "lg",
                [styles.sizeMd]: size === "md",
            })}
        >
            <span className={styles.current}>{current}</span>/<div className={styles.total}>{total}</div>
        </div>
    )
}
