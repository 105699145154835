// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProfileOverflowMenu-overflowMenuTrigger__FogUH{position:absolute;bottom:var(--size-2);left:calc(var(--size-1)*-1)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overflowMenuTrigger": "ProfileOverflowMenu-overflowMenuTrigger__FogUH"
};
export default ___CSS_LOADER_EXPORT___;
