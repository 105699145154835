import Spinner from "@legacy/core/components/Spinner";
import { Component } from "react";
import ButtonGroup from "../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../core/buttons/UniversalButton";
import BasicDisplayField from "../../core/fields/BasicDisplayField";
import CharField from "../../core/fields/CharField";
import GeneratedDatetimeField from "../../core/fields/GeneratedDatetimeField";
import { formatCurrencyForServiceCompany } from "../../core/utils/utils";
import ShippingPreferenceFormFields from "../fields/ShippingPreferenceFormFields";
import { calculatePurchaseOrderAmounts } from "../utils/utils";


class PurchaseOrderUpdateShippingForm extends Component {

    componentDidMount() {
        // Return to the previous scroll
        document.querySelector(".main").scrollTo(0, this.props.returnScroll || 0)
    }

    renderButtons = () => {
        const { purchaseOrder, shippingData, requestAction, switchToPrimaryForm, submitting, errors, onFormDataChange, shippingCarrierChoices, shippingMethodChoices, returnScroll } = this.props

        if (errors.unexpectedError) {
            return (
                <div className="data-panel__action-feedback">
                    <span className="text-invalid"><strong>An unexpected error occurred.</strong></span>
                </div>
            )
        }
        else {
            if (submitting) {
                return <Spinner centered={true} />
            }
            else {
                return (
                    <ButtonGroup>
                        <ButtonGroupRow>
                            <UniversalButton type="primary" text="Save" handler={event => requestAction("PURCHASE_ORDER_UPDATE_SHIPPING_SUBMIT")} />
                            <UniversalButton type="secondary" text="Cancel" handler={event => switchToPrimaryForm()} />
                        </ButtonGroupRow>
                    </ButtonGroup>
                )
            }
        }
    }

    render() {
        const { purchaseOrder, shippingData, requestAction, switchToPrimaryForm, submitting, errors, onFormDataChange, shippingCarrierChoices, shippingMethodChoices, returnScroll } = this.props
        const { subtotal } = calculatePurchaseOrderAmounts(purchaseOrder)

        return (
            <div className="data-panel-container data-panel-container--with-margin">
                <div className="data-panel" aria-label="Purchase Order Update Shipping">
                    <div className="data-panel__form" aria-label="Purchase Order Update Shipping Form">
                        <p className="data-panel__form__caption">Please provide updated shipment details below.</p>
                        <BasicDisplayField
                            fieldName="id"
                            fieldLabel="Roopairs Purchase Order ID"
                            fieldValue={purchaseOrder.custom_id ? purchaseOrder.custom_id : purchaseOrder.id}
                        ></BasicDisplayField>
                        <BasicDisplayField
                            fieldName="vendor"
                            fieldLabel="Vendor"
                            fieldValue={purchaseOrder.vendor.name}
                        ></BasicDisplayField>
                        <BasicDisplayField
                            fieldName="item_count"
                            fieldLabel="Item Count"
                            fieldValue={`${purchaseOrder.line_items.length} items`}
                        ></BasicDisplayField>
                        <BasicDisplayField
                            fieldName="subtotal"
                            fieldLabel="Expected Subtotal"
                            fieldValue={formatCurrencyForServiceCompany(subtotal, purchaseOrder.service_company)}
                        ></BasicDisplayField>
                        <BasicDisplayField
                            fieldName="shipping"
                            fieldLabel="Shipping Preference"
                            fieldValue={purchaseOrder.preferred_shipping || "No Preference"}
                        ></BasicDisplayField>
                        <CharField
                            fieldName="vendor_internal_id"
                            fieldLabel="Vendor Purchase Order ID"
                            fieldValue={shippingData.vendor_internal_id || ""}
                            fieldOnChange={vendor_internal_id => onFormDataChange("vendor_internal_id", vendor_internal_id || "")}
                            maxLength="30"
                            optional={true}
                            optionalText="if different"
                            errors={errors}
                        ></CharField>
                        <GeneratedDatetimeField
                            fieldName="estimated_arrival_time"
                            fieldLabel="Estimated Arrival Time"
                            fieldValue={shippingData.estimated_arrival_time}
                            fieldOnChange={estimated_arrival_time => onFormDataChange("estimated_arrival_time", estimated_arrival_time || null)}
                            errors={errors}
                            preferredTimezone={purchaseOrder.service_company.preferred_timezone}
                        ></GeneratedDatetimeField>
                        <ShippingPreferenceFormFields
                            purchaseOrder={purchaseOrder}
                            shippingData={shippingData}
                            errors={errors}
                            onFormDataChange={onFormDataChange}
                            shippingCarrierChoices={shippingCarrierChoices}
                            shippingMethodChoices={shippingMethodChoices}
                        ></ShippingPreferenceFormFields>
                    </div>
                    {this.renderButtons()}
                </div>
            </div>
        )
    }
}

export default PurchaseOrderUpdateShippingForm;
