import pluralize from "pluralize"
import { convertToKebabCase } from "../utils/utils"

function ObjectListCard(props) {
    const {
        objectName,
        objectID,
        showTagsInHeaderPrimary=false,
        primaryTags,
        showState=false,
        stateLabel,
        headerContext,
        statusChip,
        bodyTitle,
        bodySubtextPrimary,
        bodySubtextSecondary,
        bodyTags,
        footerContextLabel,
        footerContextValue,
        footerPrimary,
    } = props

    return (
        <div className={`object-list-card ${convertToKebabCase(objectName)}-list-card ${convertToKebabCase(pluralize(objectName))}-${convertToKebabCase(stateLabel)}`} aria-label={`${objectName} Card: ${objectID}`}>
            <div className="object-list-card__inner-wrapper">
                <div className="object-list-card__grow">
                    <div className="object-list-card__header" aria-label="Card Header">
                        {
                            showTagsInHeaderPrimary
                            ?
                            <div className="object-list-card__header__primary object-list-card__header__primary--tags" aria-label="Card Header Primary Content">
                                {primaryTags}
                            </div>
                            :
                            <div className="object-list-card__header__primary" aria-label="Card Header Primary Content">
                                {
                                    showState && (
                                        <div className="object-list-card__header__state" aria-label="Object State">
                                            {stateLabel}
                                        </div>
                                    )
                                }
                                {
                                    <div className="object-list-card__header__id" aria-label="Object ID">
                                        {objectID}
                                    </div>
                                }
                            </div>
                        }
                        <div className="object-list-card__header__secondary" aria-label="Card Header Secondary Content">
                            <div className="object-list-card__header__context" aria-label="Object Extra Context Header">
                                {headerContext}
                            </div>
                            <div className="object-list-card__header__status" aria-label="Object Status">
                                {statusChip}
                            </div>
                        </div>
                    </div>
                    <div className="object-list-card__body" aria-label="Card Body">
                        <div className="object-list-card__body__title" aria-label="Object Title">
                            {bodyTitle}
                        </div>
                        {
                            (bodySubtextPrimary || bodySubtextSecondary) && (
                                <div className="object-list-card__body__subtext" aria-label="Object Subtext">
                                    <div className={`object-list-card__body__subtext__primary${bodySubtextSecondary ? " object-list-card__body__subtext__primary--with-secondary" : ""}`} aria-label="Object Subtext Primary">
                                        {bodySubtextPrimary}
                                    </div>
                                    {
                                        bodySubtextSecondary && (
                                            <div className="object-list-card__body__subtext__secondary" aria-label="Object Subtext Secondary">
                                                {bodySubtextSecondary}
                                            </div>
                                        )
                                    }
                                </div>

                            )
                        }
                        {
                            bodyTags && (
                                <div className="object-list-card__body__tags" aria-label="Object Tag List">
                                    {bodyTags}
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className="object-list-card__footer" aria-label="Card Footer">
                    <div className="object-list-card__footer__context" aria-label="Card Footer Extra Context">
                        <div className="object-list-card__footer__context__label" aria-label="Object Extra Context Footer Label">
                            {footerContextLabel}
                        </div>
                        <div className="object-list-card__footer__context__value" aria-label="Object Extra Context Footer Value">
                            {footerContextValue}
                        </div>
                    </div>
                    <div className="object-list-card__footer__primary" aria-label="Card Footer Primary Content">
                        {footerPrimary}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ObjectListCard
