// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TableViewControls-base__bJDNl{border-bottom:var(--size-0_25) solid var(--gray-a3)}.TableViewControls-base__bJDNl .TableViewControls-container__zjcyE{position:relative;display:flex;flex-direction:column}@media only screen and (min-width: 64rem){.TableViewControls-base__bJDNl .TableViewControls-container__zjcyE{flex-direction:row;gap:var(--size-2);align-items:center;justify-content:space-between}}@media only screen and (min-width: 64rem){.TableViewControls-base__bJDNl{padding:var(--size-0) var(--size-4)}}.TableViewControls-navControls___N5mx{display:flex;align-items:center;min-width:var(--size-0);height:var(--size-13);padding:var(--size-0) var(--size-3);padding-right:var(--size-0);border-bottom:var(--size-0_25) solid var(--gray-a3)}@media only screen and (min-width: 64rem){.TableViewControls-navControls___N5mx{height:var(--size-14);padding:var(--size-0);border:none}}.TableViewControls-divider__mx35A{padding:var(--size-0) var(--size-2)}.TableViewControls-viewControls__DjS4n{position:relative;display:flex;gap:var(--size-2);align-items:center;align-self:stretch;height:var(--size-13);padding:var(--size-0) var(--size-3)}@media only screen and (min-width: 64rem){.TableViewControls-viewControls__DjS4n{height:var(--size-14);padding:var(--size-0)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "TableViewControls-base__bJDNl",
	"container": "TableViewControls-container__zjcyE",
	"navControls": "TableViewControls-navControls___N5mx",
	"divider": "TableViewControls-divider__mx35A",
	"viewControls": "TableViewControls-viewControls__DjS4n"
};
export default ___CSS_LOADER_EXPORT___;
