import clsx from "clsx"
import { Fragment } from "react"

import generateRandomKey from "@utils/generateRandomKey"

import { Avatar } from "@atoms"

import UserAvatar from "@molecules/UserAvatar/UserAvatar"

import styles from "./UserAvatarStack.module.scss"
import { UserAvatarStackProps } from "./UserAvatarStack.types"

export default function UserAvatarStack(props: UserAvatarStackProps) {
    const { users, avatarSize, limit, showTooltip = true, variation = "inline" } = props

    const count = users.length

    const remainingUsers = users.slice(limit - 1)

    return (
        <div
            className={clsx(styles.base, styles[`avatarSize${avatarSize}`], {
                [styles.inline]: variation === "inline",
                [styles.stack]: variation === "stack",
            })}
        >
            {users.map((user, index) => {
                const oneIndexedIndex = index + 1 // To match 1-indexed limit prop

                if (limit < oneIndexedIndex) {
                    return null
                } else if (limit === oneIndexedIndex && limit < count) {
                    return (
                        <Fragment key={generateRandomKey()}>
                            <Avatar
                                size={avatarSize}
                                shape="circle"
                                count={count - limit + 1} // Add one to account for the user replaced by this counter
                                countShowPlus={limit > 1}
                                isOverlapping={variation === "inline"}
                            />

                            {variation === "stack" && (
                                <div className={styles.avatarName}>
                                    <span>{remainingUsers.map((user) => user.full_name).join(", ")}</span>
                                </div>
                            )}
                        </Fragment>
                    )
                } else {
                    return (
                        <Fragment key={generateRandomKey()}>
                            <UserAvatar
                                size={avatarSize}
                                shape="circle"
                                user={user}
                                showTooltip={showTooltip}
                                isOverlapping={variation === "inline"}
                                isUnknown={user.isUnknown}
                            />

                            {variation === "stack" && (
                                <div
                                    className={clsx(styles.avatarName, {
                                        [styles.unknown]: user.isUnknown,
                                    })}
                                >
                                    <span>{user.full_name}</span>
                                </div>
                            )}
                        </Fragment>
                    )
                }
            })}
        </div>
    )
}
