import { useEffect, useState } from "react"

import useIsAnyDialogOpen from "@hooks/useIsAnyDialogOpen"
import useIsDesktop from "@hooks/useIsDesktop"
import useUser from "@hooks/useUser"

import extractURLSearchParam from "@utils/extractURLSearchParam"
import { ifSpaceOrEnter } from "@utils/keyboard"

import { UserAvatar } from "@molecules"

import { PROFILE_BOTTOM_SHEET_OPEN } from "@constants/searchParams"

import ProfileBottomSheet from "./ProfileBottomSheet/ProfileBottomSheet"
import ProfileOverflowMenu from "./ProfileOverflowMenu/ProfileOverflowMenu"
import styles from "./UserProfileButton.module.scss"

const statusFromUrl = extractURLSearchParam(PROFILE_BOTTOM_SHEET_OPEN)

export default function UserProfileButton() {
    const { user } = useUser()
    const [isOpen, setIsOpen] = useState<boolean>(statusFromUrl === "open")

    const isDesktop = useIsDesktop()

    const onClose = () => setIsOpen(false)

    const { setIsAnyDialogOpen } = useIsAnyDialogOpen()

    useEffect(() => {
        setIsAnyDialogOpen(isOpen)
    }, [isOpen])

    return (
        <div className={styles.base}>
            <div
                className={styles.button}
                tabIndex={0}
                onClick={() => setIsOpen(!isOpen)}
                onKeyDown={(e) => ifSpaceOrEnter(e, () => setIsOpen(!isOpen))}
            >
                <UserAvatar user={user} shape="circle" size={isDesktop ? 28 : 36} />
            </div>
            {isDesktop ? (
                <ProfileOverflowMenu isOpen={isOpen} onClose={onClose} />
            ) : (
                <ProfileBottomSheet isOpen={isOpen} onClose={onClose} />
            )}
        </div>
    )
}
