// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CardListContent-base__ezV3F{display:flex;flex-direction:column;flex-grow:1;margin-bottom:var(--bottom-bar-height);overflow:auto}.CardListContent-noScroll__OUkV3{overflow:hidden}.CardListContent-scrollContainer__GFRXC{display:flex;flex-direction:column;align-items:center;width:var(--size-full);margin-bottom:var(--bottom-bar-height);overflow:auto}.CardListContent-virtualizer__oEFLK{position:relative;width:var(--size-full)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "CardListContent-base__ezV3F",
	"noScroll": "CardListContent-noScroll__OUkV3",
	"scrollContainer": "CardListContent-scrollContainer__GFRXC",
	"virtualizer": "CardListContent-virtualizer__oEFLK"
};
export default ___CSS_LOADER_EXPORT___;
