import { isValidPhoneNumber } from "react-phone-number-input"
import * as Yup from "yup"

import useIsDesktop from "@hooks/useIsDesktop"

import { AlertBanner, Button, NavigationLink } from "@atoms/index"

import { PhoneNumberInput } from "@molecules/Form/Form"
import GenericForm from "@molecules/Form/GenericForm/GenericForm"
import { MutationSuccessFunctionParams } from "@molecules/Form/GenericForm/GenericForm.types"

import {
    PhoneCodeRequestPayload,
    PhoneCodeRequestResponse,
} from "@organisms/SecurityCheckDialog/SecurityCheckDialog.types"

import styles from "@pages/Auth/Auth.module.scss"
import useAuth from "@pages/Auth/Auth.store"

import { AUTH_ENDPOINTS } from "@endpoints/auth"

export type PhoneSchema = {
    phone_number: User["phone"]
}

export const phoneNumberSchema: Yup.ObjectSchema<PhoneSchema> = Yup.object().shape({
    phone_number: Yup.string()
        .required("Please enter a phone number")
        .test("is-valid-phone", "Please enter a valid phone number", (value) => isValidPhoneNumber(value)),
})

export default function AuthPhoneCodeForm() {
    const {
        authMethod,
        isOnOTPSlide,
        setIsOnOTPSlide,
        setAuthMethod,
        setPhoneData,
        setIsWaitingForCodeInput,
        isWaitingForCodeInput,
    } = useAuth()

    const isDesktop = useIsDesktop()

    const isActive = authMethod === "phone_and_code"

    const onMutationSuccess = (args: MutationSuccessFunctionParams<PhoneSchema, PhoneCodeRequestResponse>) => {
        setPhoneData({
            phone: args.formValues.phone_number,
            phoneId: args.data.phone_id,
        })

        setIsWaitingForCodeInput(true)
        setIsOnOTPSlide(true)
    }

    const onRequestPasswordInsteadClick = () => {
        setIsOnOTPSlide(false)
        void setAuthMethod("phone_and_password")
    }

    const onGetLoginCodeClick = () => {
        if (isWaitingForCodeInput) {
            setIsOnOTPSlide(true)
        }
    }

    const onPhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPhoneData({
            phone: event.target.value,
            phoneId: null,
        })
    }

    return (
        <GenericForm<PhoneSchema, PhoneCodeRequestResponse, Error, PhoneCodeRequestPayload>
            formConfig={{
                defaultValues: {
                    phone_number: "",
                },
            }}
            formValidationSchema={phoneNumberSchema}
            mutationConfig={{
                method: "POST",
                endpoint: AUTH_ENDPOINTS.OTP.SMS_REQUEST,
                genericErrorMessage: "Could not request code.",
                disabled: isWaitingForCodeInput,
            }}
            triggerFormReset={!isActive}
            onMutationSuccess={onMutationSuccess}
            className={styles.form}
        >
            {({ isPending, nonFieldError }) => (
                <>
                    {nonFieldError && (
                        <AlertBanner size={isDesktop ? "sm" : "md"} title={nonFieldError} type="error" />
                    )}
                    <PhoneNumberInput<PhoneSchema>
                        name="phone_number"
                        label="Phone number"
                        defaultCountryCode="US"
                        placeholder="(000) 000-0000"
                        isTabbable={isActive && !isOnOTPSlide}
                        size={isDesktop ? "md" : "lg"}
                        onChangeCapture={onPhoneChange}
                    />
                    <NavigationLink
                        onClick={onRequestPasswordInsteadClick}
                        isUnderLine={true}
                        className={styles.link}
                        isTabbable={isActive && !isOnOTPSlide}
                        title="Phone and password form"
                        data-prevent-input-validation={true}
                    >
                        {() => "Use password instead"}
                    </NavigationLink>
                    <Button
                        type="submit"
                        colorScheme="gray"
                        size={isDesktop ? "md" : "lg"}
                        variant="solid"
                        isFullWidth={true}
                        isTabbable={isActive && !isOnOTPSlide}
                        isLoading={isPending}
                        onClick={onGetLoginCodeClick}
                    >
                        Get login code
                    </Button>
                </>
            )}
        </GenericForm>
    )
}
