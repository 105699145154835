import Spinner from "@legacy/core/components/Spinner";
import { Component, Fragment } from "react";
import ButtonGroup from "../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../core/buttons/UniversalButton";
import Banner from "../../core/components/Banner";
import LineItemListSelect from "../../core/components/LineItemListSelect";
import AttachmentUploadField from "../../core/fields/AttachmentUploadField";
import BasicDisplayField from "../../core/fields/BasicDisplayField";
import BasicSelectField from "../../core/fields/BasicSelectField";
import CharField from "../../core/fields/CharField";
import DateField from "../../core/fields/DateField";
import ListSelectField from "../../core/fields/ListSelectField";
import SearchCreateSelectField from "../../core/fields/SearchCreateSelectField";
import TextField from "../../core/fields/TextField";
import { ShippingCarriers, ShippingMethods } from "../../core/utils/enums";
import { valueIsDefined } from "../../core/utils/utils";
import InventoryLocationSearchOrCreateSelect, { inventoryLocationToInventoryLocationOption } from "../../inventory/inputs/InventoryLocationSearchOrCreateSelect";
import VendorSearchOrCreateSelect, { vendorToVendorOption } from "../../pricebook/inputs/VendorSearchOrCreateSelect";
import PurchaseOrderFeedAccordion from "../components/PurchaseOrderFeedAccordion";
import { calculatePurchaseOrderAmounts } from "../utils/utils";


class PurchaseOrderForm extends Component {

    constructor(props) {
        super(props)

        this.state = {
            vendorSearchInput: "",
            inventoryLocationSearchInput: "",
        }
    }

    componentDidMount() {
        // Return to the previous scroll
        document.querySelector(".main").scrollTo(0, this.props.returnScroll || 0)
    }

    purchaseOrderIsSeen = (purchaseOrder) => {
        return valueIsDefined(purchaseOrder.id) && valueIsDefined(purchaseOrder.is_draft) && purchaseOrder.is_draft === false && purchaseOrder.last_viewed !== null
    }

    renderButtons = () => {
        const {
            mode,
            submitting,
            purchaseOrder,
            errors,
            onFormDataChange,
            requestAction,
            switchToSecondaryForm,
            updateVendorSelection,
            updateInventoryLocationSelection,
            selectedVendor,
            selectedInventoryLocation,
            formatCurrencyValue,
            currencySymbol,
            showCustomPurchaseOrderIDField,
            foldDataComplete,
            shippingCarrierChoices,
            shippingMethodChoices,
            fileStackAPIKey,
            fileStackPolicy,
            fileStackSignature,
            updateAttachments,
            returnScroll
        } = this.props

        if (errors.unexpectedError) {
            return (
                <div className="data-panel__action-feedback">
                    <span className="text-invalid"><strong>An unexpected error occurred.</strong></span>
                </div>
            )
        }
        else {
            if (submitting) {
                return (
                    <div className="data-panel__action-feedback">
                        <Spinner centered={true} />
                    </div>
                )
            }
            else {
                if (mode === "ADD_PURCHASE_ORDER") {
                    return (
                        <ButtonGroup>
                            <ButtonGroupRow>
                                <UniversalButton type="primary" text="Create and Send" handler={event => requestAction("PURCHASE_ORDER_CREATE")} />
                            </ButtonGroupRow>
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Preview Order" handler={event => requestAction("PURCHASE_ORDER_PREVIEW")} />
                                <UniversalButton type="secondary" text="Save Draft" handler={event => requestAction("PURCHASE_ORDER_CREATE_DRAFT")} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
                else if (mode === "EDIT_PURCHASE_ORDER") {
                    if (purchaseOrder.is_draft) {
                        return (
                            <ButtonGroup>
                                <ButtonGroupRow>
                                    <UniversalButton type="primary" text="Create and Send" handler={event => requestAction("PURCHASE_ORDER_CREATE")} />
                                </ButtonGroupRow>
                                <ButtonGroupRow>
                                    <UniversalButton type="secondary" text="Preview Order" handler={event => requestAction("PURCHASE_ORDER_PREVIEW")} />
                                </ButtonGroupRow>
                                <ButtonGroupRow>
                                    <UniversalButton type="secondary" text="Save Draft" handler={event => requestAction("PURCHASE_ORDER_CREATE_DRAFT")} />
                                    <UniversalButton type="danger" text="Delete Draft" handler={event => requestAction("PURCHASE_ORDER_DELETE_DRAFT")} />
                                </ButtonGroupRow>
                            </ButtonGroup>
                        )
                    }
                    else {
                        return (
                            <ButtonGroup>
                                {
                                    this.purchaseOrderIsSeen(purchaseOrder) && (
                                        <ButtonGroupRow>
                                            <UniversalButton type="primary" text="Save and Resend" handler={event => requestAction("PURCHASE_ORDER_UPDATE_AND_RESEND")} />
                                        </ButtonGroupRow>
                                    )
                                }
                                <ButtonGroupRow>
                                    <UniversalButton type={this.purchaseOrderIsSeen(purchaseOrder) ? "secondary" : "primary"} text="Save" handler={event => requestAction("PURCHASE_ORDER_UPDATE")} />
                                    <UniversalButton type="secondary" text="Cancel Edits" handler={event => requestAction("PURCHASE_ORDER_CANCEL_EDITS")} />
                                </ButtonGroupRow>
                            </ButtonGroup>
                        )
                    }
                }
            }
        }
    }

    render() {
        const {
            mode,
            submitting,
            purchaseOrder,
            errors,
            onFormDataChange,
            requestAction,
            switchToSecondaryForm,
            updateVendorSelection,
            updateInventoryLocationSelection,
            selectedVendor,
            selectedInventoryLocation,
            formatCurrencyValue,
            currencySymbol,
            showCustomPurchaseOrderIDField,
            foldDataComplete,
            shippingCarrierChoices,
            shippingMethodChoices,
            fileStackAPIKey,
            fileStackPolicy,
            fileStackSignature,
            updateAttachments,
            returnScroll
        } = this.props
        const { subtotal } = calculatePurchaseOrderAmounts(purchaseOrder)
        const allowVendorDetailsEdit = !valueIsDefined(purchaseOrder.status) || ["Draft", "Unsent", "Sent"].includes(purchaseOrder.status_label)
        const allowShippingPreferencesEdit = !valueIsDefined(purchaseOrder.state_label) || ["Pending", "Open"].includes(purchaseOrder.state_label)
        const selectedCarrierChoice = shippingCarrierChoices.find(choice => choice.value === parseInt(purchaseOrder.preferred_shipping_carrier))

        return (
            <Fragment>
                <div id="purchase_order_form_react_wrapper">
                    <div className="data-panel-container data-panel-container--with-margin">
                        <div className="data-panel" aria-label="Purchase Order Create/Update">
                            <div className="data-panel__form" aria-label="Purchase Order Create/Update Form">
                                <p className="data-panel__form__caption">Please provide the following information to create your purchase order:</p>
                                <fieldset>
                                    <legend>Basic Details</legend>
                                    <SearchCreateSelectField
                                        fieldName="vendor"
                                        fieldLabel="Vendor"
                                        fieldValue={selectedVendor !== null ? selectedVendor.name : null}
                                        inputComponent={
                                            <VendorSearchOrCreateSelect
                                                disabled={!allowVendorDetailsEdit}
                                                onSelectionChange={selectedOption => updateVendorSelection(selectedOption != null ? selectedOption.object : null)}
                                                onInputChange={(input, action) => {action.action === "input-change" && this.setState({vendorSearchInput: input})}}
                                                defaultSelected={selectedVendor !== null ? vendorToVendorOption(selectedVendor) : null}
                                                showCreateButton={true}
                                                onCreateClick={event => switchToSecondaryForm("ADD_VENDOR", null, {name: this.state.vendorSearchInput})}
                                            ></VendorSearchOrCreateSelect>
                                        }
                                        showButton={allowVendorDetailsEdit && selectedVendor !== null}
                                        buttonLabel={<Fragment><i className="fa-sharp fa-light fa-pen-to-square" aria-hidden="true"></i>Edit Vendor</Fragment>}
                                        buttonAction={event => switchToSecondaryForm("EDIT_VENDOR", selectedVendor, null)}
                                        disabled={!allowVendorDetailsEdit}
                                        errors={errors}
                                    ></SearchCreateSelectField>
                                    {
                                        selectedVendor !== null && selectedVendor.notes !== "" && (
                                            <BasicDisplayField
                                                fieldName="vendor_notes"
                                                fieldLabel="Vendor Notes"
                                                fieldValue={selectedVendor.notes}
                                                indented={true}
                                            ></BasicDisplayField>
                                        )
                                    }
                                    <SearchCreateSelectField
                                        fieldName="inventory_location"
                                        fieldLabel="Inventory Location"
                                        fieldValue={selectedInventoryLocation !== null ? selectedInventoryLocation.name : null}
                                        inputComponent={
                                            <InventoryLocationSearchOrCreateSelect
                                                refetchSelection={false}
                                                disabled={!allowVendorDetailsEdit}
                                                onSelectionChange={selectedOption => updateInventoryLocationSelection(selectedOption != null ? selectedOption.object : null)}
                                                onInputChange={(input, action) => {action.action === "input-change" && this.setState({inventoryLocationSearchInput: input})}}
                                                defaultSelected={selectedInventoryLocation !== null ? inventoryLocationToInventoryLocationOption(selectedInventoryLocation) : null}
                                                showCreateButton={false}
                                            ></InventoryLocationSearchOrCreateSelect>
                                        }
                                        showButton={false}
                                        errors={errors}
                                    ></SearchCreateSelectField>
                                    {
                                        selectedInventoryLocation !== null && selectedInventoryLocation.notes !== "" && (
                                            <BasicDisplayField
                                                fieldName="inventory_location_notes"
                                                fieldLabel="Inventory Location Notes"
                                                fieldValue={selectedInventoryLocation.notes}
                                                indented={true}
                                            ></BasicDisplayField>
                                        )
                                    }
                                    {
                                        showCustomPurchaseOrderIDField
                                        ?
                                        <CharField
                                            fieldName="custom_id"
                                            fieldLabel="Purchase Order ID"
                                            fieldValue={purchaseOrder.custom_id || ""}
                                            fieldOnChange={custom_id => onFormDataChange("custom_id", custom_id || "")}
                                            maxLength="15"
                                            errors={errors}
                                        ></CharField>
                                        :
                                        <div id="div_id_custom_id">{errors.custom_id && <Banner type="warning" text={errors.custom_id} extraMargin={true} />}</div>
                                    }
                                    {
                                        mode === "EDIT_PURCHASE_ORDER" && !["Draft", "Unsent", "Sent"].includes(purchaseOrder.status_label) && (
                                            <CharField
                                                fieldName="vendor_internal_id"
                                                fieldLabel="Vendor Purchase Order ID"
                                                fieldValue={purchaseOrder.vendor_internal_id || ""}
                                                fieldOnChange={vendor_internal_id => onFormDataChange("vendor_internal_id", vendor_internal_id || "")}
                                                maxLength="30"
                                                optional={true}
                                                optionalText="if different"
                                                errors={errors}
                                            ></CharField>
                                        )
                                    }
                                    <DateField
                                        fieldName="date_issued"
                                        fieldLabel="Issue Date"
                                        fieldValue={purchaseOrder.date_issued || ""}
                                        fieldOnChange={date_issued => onFormDataChange("date_issued", date_issued || "")}
                                        errors={errors}
                                    ></DateField>

                                    <BasicSelectField
                                        fieldName="preferred_shipping_carrier"
                                        fieldLabel="Preferred Shipping Carrier"
                                        fieldValue={purchaseOrder.preferred_shipping_carrier}
                                        fieldOnChange={preferred_shipping_carrier => onFormDataChange("preferred_shipping_carrier", preferred_shipping_carrier)}
                                        choices={shippingCarrierChoices}
                                        optional={true}
                                        disabled={!allowShippingPreferencesEdit}
                                        errors={errors}
                                    ></BasicSelectField>
                                    {
                                        valueIsDefined(purchaseOrder.preferred_shipping_carrier) && parseInt(purchaseOrder.preferred_shipping_carrier) === ShippingCarriers.other && (
                                            <CharField
                                                fieldName="preferred_shipping_carrier_other_name"
                                                fieldLabel="Preferred Shipping Carrier Name"
                                                fieldValue={purchaseOrder.preferred_shipping_carrier_other_name}
                                                fieldOnChange={preferred_shipping_carrier_other_name => onFormDataChange("preferred_shipping_carrier_other_name", preferred_shipping_carrier_other_name)}
                                                disabled={!allowShippingPreferencesEdit}
                                                errors={errors}
                                            ></CharField>
                                        )
                                    }
                                    {
                                        selectedCarrierChoice && valueIsDefined(selectedCarrierChoice.account_id) && (
                                            <BasicDisplayField
                                                fieldName="carrier_account_id"
                                                fieldLabel="Carrier Account ID"
                                                fieldValue={selectedCarrierChoice.account_id}
                                                indented={true}
                                            ></BasicDisplayField>
                                        )
                                    }
                                    <BasicSelectField
                                        fieldName="preferred_shipping_method"
                                        fieldLabel="Preferred Shipping Method"
                                        fieldValue={purchaseOrder.preferred_shipping_method}
                                        fieldOnChange={preferred_shipping_method => onFormDataChange("preferred_shipping_method", preferred_shipping_method)}
                                        choices={shippingMethodChoices}
                                        optional={true}
                                        disabled={!allowShippingPreferencesEdit}
                                        errors={errors}
                                    ></BasicSelectField>
                                    {
                                        valueIsDefined(purchaseOrder.preferred_shipping_method) && parseInt(purchaseOrder.preferred_shipping_method) === ShippingMethods.other && (
                                            <CharField
                                                fieldName="preferred_shipping_method_other_name"
                                                fieldLabel="Preferred Shipping Method Name"
                                                fieldValue={purchaseOrder.preferred_shipping_method_other_name}
                                                fieldOnChange={preferred_shipping_method_other_name => onFormDataChange("preferred_shipping_method_other_name", preferred_shipping_method_other_name)}
                                                disabled={!allowShippingPreferencesEdit}
                                                errors={errors}
                                            ></CharField>
                                        )
                                    }
                                </fieldset>
                                {
                                    selectedVendor !== null && selectedInventoryLocation !== null && foldDataComplete === true && (
                                        <fieldset>
                                            <legend>Order Details</legend>
                                            <br />
                                            <div className="data-panel__form__field" id="div_id_line_items" aria-label="Line Item Errors" style={errors.line_items ?  {"display": "initial"} : {"display": "none"}}>
                                                {errors.line_items && <div className="data-panel__form__field__errors text-centered" aria-label="Field Errors">There's an issue with one or more of the items below. Try coming back to this page and trying again in a bit.</div>}
                                            </div>
                                            <ListSelectField
                                                fieldName="parts"
                                                fieldLabel="Parts and Materials"
                                                inputComponent={
                                                    <LineItemListSelect
                                                        fieldName="parts"
                                                        onSelect={data => switchToSecondaryForm("EDIT_PART", data, null)}
                                                        parent={purchaseOrder}
                                                        items={purchaseOrder.parts || []}
                                                        formatCurrencyValue={formatCurrencyValue}
                                                        isPurchaseOrderLineItem={true}
                                                    ></LineItemListSelect>
                                                }
                                                showButton={true}
                                                buttonLabel="Add Part or Material"
                                                buttonAction={event => switchToSecondaryForm("ADD_PART", null, null)}
                                                errors={errors}
                                            ></ListSelectField>
                                            <TextField
                                                fieldName="comments"
                                                fieldLabel="Comments"
                                                fieldValue={purchaseOrder.comments || ""}
                                                fieldOnChange={comments => onFormDataChange("comments", comments || "")}
                                                rows={3}
                                                placeholder="Any comments to add to the purchase order."
                                                optional={true}
                                                errors={errors}
                                            ></TextField>
                                            {
                                                window.FILE_UPLOADS_ENABLED && (
                                                    <AttachmentUploadField
                                                        fieldName="attachments"
                                                        fieldLabel="Attachments"
                                                        attachments={purchaseOrder.attachments}
                                                        fileStackAPIKey={fileStackAPIKey}
                                                        fileStackPolicy={fileStackPolicy}
                                                        fileStackSignature={fileStackSignature}
                                                        onUploadDone={(response) => updateAttachments(
                                                            response.filesUploaded.map(file => ({
                                                                "upload_filename": file.filename,
                                                                "upload_id": file.uploadId,
                                                                "upload_handle": file.handle,
                                                                "upload_url": file.url,
                                                                "upload_mimetype": file.mimetype,
                                                                "upload_size": file.size,
                                                                "upload_source": file.source
                                                            }))
                                                        )}
                                                        optional={true}
                                                        optionalText={"if any"}
                                                        errors={errors}
                                                    />
                                                )
                                            }
                                        </fieldset>
                                    )
                                }
                            </div>
                            {
                                selectedVendor !== null && selectedInventoryLocation !== null && foldDataComplete === true && (
                                    <div className="data-panel__form data-panel__data-summary amount-summary" aria-label="Amount Summary">
                                        <hr aria-hidden="true" />
                                        <div className="data-panel__data-summary__row amount-summary__item amount-summary__item--subtotal" aria-label="Expected Subtotal">
                                            <div className="data-panel__data-summary__data__label"><span>Expected Subtotal</span></div>
                                            <div className="data-panel__data-summary__data__value">{formatCurrencyValue(subtotal)}</div>
                                        </div>
                                        {
                                            this.purchaseOrderIsSeen(purchaseOrder) && (
                                                <Banner type="warning" text="The latest version of this purchase has been seen. We recommend resending it to your vendor with a message summarizing the revisions you've made." />
                                            )
                                        }
                                    </div>
                                )
                            }
                            {
                                selectedVendor !== null && selectedInventoryLocation !== null && foldDataComplete === true && this.renderButtons()
                            }
                        </div>
                    </div>
                </div>
                {
                    valueIsDefined(purchaseOrder.id) && (
                        <div className="accordion-wrapper">
                            <PurchaseOrderFeedAccordion purchaseOrderID={purchaseOrder.id} />
                        </div>
                    )
                }
            </Fragment>
        )
    }

}

export default PurchaseOrderForm;
