// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CardListEdgeStates-base__Hk7ak{position:relative;margin-top:2em;margin-bottom:var(--bottom-bar-height);overflow:auto}.CardListEdgeStates-content__QVxUk{position:relative;display:flex;flex-direction:column;gap:var(--size-6);align-items:center}.CardListEdgeStates-copyWrap__guVYX{display:flex;flex-direction:column;gap:var(--size-4);align-items:center}.CardListEdgeStates-copy__tVekT{display:flex;flex-direction:column;gap:var(--size-1);align-items:center}.CardListEdgeStates-title__sb1ck{margin-bottom:0;color:var(--gray-12);font:var(--font-global-heading-01);font-feature-settings:var(--feature-settings-global-heading-01);letter-spacing:var(--letter-spacing-global-heading-01)}.CardListEdgeStates-explanation__RJQxA{margin-bottom:0;color:var(--gray-11);font:var(--font-global-body-400);font-feature-settings:var(--feature-settings-global-body-400);letter-spacing:var(--letter-spacing-global-body-400)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "CardListEdgeStates-base__Hk7ak",
	"content": "CardListEdgeStates-content__QVxUk",
	"copyWrap": "CardListEdgeStates-copyWrap__guVYX",
	"copy": "CardListEdgeStates-copy__tVekT",
	"title": "CardListEdgeStates-title__sb1ck",
	"explanation": "CardListEdgeStates-explanation__RJQxA"
};
export default ___CSS_LOADER_EXPORT___;
