// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BottomSheetAddColumns-noColumns___ndJ4{display:flex;align-items:center;justify-content:center;height:calc(var(--size-64) + var(--size-1))}.BottomSheetAddColumns-noColumnsText__T9cIa{font:var(--font-global-body-400);font-feature-settings:var(--feature-settings-global-body-400);letter-spacing:var(--letter-spacing-global-body-400);color:var(--gray-11)}.BottomSheetAddColumns-searchInput__tMzH8{position:relative;padding:var(--size-0) var(--size-2_5) var(--size-2) var(--size-2_5)}.BottomSheetAddColumns-searchInput__tMzH8 ::after{position:absolute;right:var(--size-0);bottom:var(--size-0);left:var(--size-0);width:var(--size-full);height:var(--size-0_25);background-color:var(--gray-4);content:\"\"}.BottomSheetAddColumns-items__LzMsF{padding:var(--size-2_5)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noColumns": "BottomSheetAddColumns-noColumns___ndJ4",
	"noColumnsText": "BottomSheetAddColumns-noColumnsText__T9cIa",
	"searchInput": "BottomSheetAddColumns-searchInput__tMzH8",
	"items": "BottomSheetAddColumns-items__LzMsF"
};
export default ___CSS_LOADER_EXPORT___;
