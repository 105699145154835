import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"

import getTableDataType from "@utils/getTableDataType"

import { ColumnId, TableDataType } from "@organisms/Table/Table.types"

interface getTableSortingIconArgs {
    columnId: ColumnId
    ascending: boolean
}

export default function getTableSortingIcon(args: getTableSortingIconArgs): IconDefinition | null {
    const { columnId, ascending } = args

    const dataType: TableDataType = getTableDataType(columnId)

    if (dataType === "alphabetical" && ascending) {
        return icon({
            name: "arrow-up-a-z",
            style: "light",
            family: "sharp",
        })
    } else if (dataType === "alphabetical" && !ascending) {
        return icon({
            name: "arrow-down-z-a",
            style: "light",
            family: "sharp",
        })
    } else if (dataType === "numerical" && ascending) {
        return icon({
            name: "arrow-up-1-9",
            style: "light",
            family: "sharp",
        })
    } else if (dataType === "numerical" && !ascending) {
        return icon({
            name: "arrow-down-9-1",
            style: "light",
            family: "sharp",
        })
    } else if (dataType === "timing" && ascending) {
        return icon({
            name: "arrow-up-short-wide",
            style: "light",
            family: "sharp",
        })
    } else if (dataType === "timing" && !ascending) {
        return icon({
            name: "arrow-down-short-wide",
            style: "light",
            family: "sharp",
        })
    } else if (dataType === "other" && ascending) {
        return icon({
            name: "arrow-up-short-wide",
            style: "light",
            family: "sharp",
        })
    } else if (dataType === "other" && !ascending) {
        return icon({
            name: "arrow-down-short-wide",
            style: "light",
            family: "sharp",
        })
    } else {
        return null
    }
}
