// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Checkbox-wrapper__fctrY{position:relative;display:flex;gap:var(--size-2);align-items:center}.Checkbox-base__cQgXu{position:relative;display:flex;align-items:center;justify-content:center;min-height:0;background-color:rgba(0,0,0,0);border:1px solid var(--gray-a7);outline:none;transition:all var(--duration-normal)}.Checkbox-base__cQgXu:disabled{cursor:not-allowed}.Checkbox-base__cQgXu:hover,.Checkbox-base__cQgXu:active{text-decoration:none;outline:none}.Checkbox-base__cQgXu:hover{background-color:var(--gray-a4);border-color:var(--gray-a8)}.Checkbox-base__cQgXu:focus{background-color:var(--gray-a4);border-color:var(--gray-a8);box-shadow:0 0 0 1px var(--gray-1),0 0 0 3px var(--gray-12)}.Checkbox-base__cQgXu:disabled{cursor:not-allowed}.Checkbox-smBaseSize__Ni63b{width:var(--size-4);height:var(--size-4);border-radius:var(--border-radius-1)}.Checkbox-mdBaseSize__HPw0d{width:var(--size-5);height:var(--size-5);border-radius:var(--border-radius-1_5)}.Checkbox-grayBaseColorScheme__nN1xQ[data-state=checked],.Checkbox-grayBaseColorScheme__nN1xQ[data-state=indeterminate]{background-color:var(--gray-12);border-color:rgba(0,0,0,0)}.Checkbox-grayBaseColorScheme__nN1xQ[data-disabled]{background-color:var(--gray-a3);border-color:rgba(0,0,0,0)}.Checkbox-indicator__HJpYb{display:flex;align-items:center;justify-content:center}.Checkbox-smIndicatorSize__H9s1K{width:var(--size-3_5);height:var(--size-3_5)}.Checkbox-mdIndicatorSize__k76wJ{width:var(--size-4);height:var(--size-4)}.Checkbox-grayIndicatorColorScheme__hBLa9{color:var(--gray-1)}.Checkbox-grayIndicatorColorScheme__hBLa9[data-disabled]{color:var(--gray-a6)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Checkbox-wrapper__fctrY",
	"base": "Checkbox-base__cQgXu",
	"smBaseSize": "Checkbox-smBaseSize__Ni63b",
	"mdBaseSize": "Checkbox-mdBaseSize__HPw0d",
	"grayBaseColorScheme": "Checkbox-grayBaseColorScheme__nN1xQ",
	"indicator": "Checkbox-indicator__HJpYb",
	"smIndicatorSize": "Checkbox-smIndicatorSize__H9s1K",
	"mdIndicatorSize": "Checkbox-mdIndicatorSize__k76wJ",
	"grayIndicatorColorScheme": "Checkbox-grayIndicatorColorScheme__hBLa9"
};
export default ___CSS_LOADER_EXPORT___;
