import Spinner from '@legacy/core/components/Spinner';
import { CardElement, ElementsConsumer } from '@stripe/react-stripe-js';
import { Component, Fragment } from "react";
import ContactSearchOrCreateSelect, { contactToContactOption } from "../../clients/inputs/ContactSearchOrCreateSelect";
import ButtonGroup from "../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../core/buttons/UniversalButton";
import Banner from '../../core/components/Banner';
import BasicDisplayField from "../../core/fields/BasicDisplayField";
import BasicSelectField from "../../core/fields/BasicSelectField";
import CharField from "../../core/fields/CharField";
import DateField from "../../core/fields/DateField";
import PriceField from "../../core/fields/PriceField";
import SearchCreateSelectField from "../../core/fields/SearchCreateSelectField";
import SwitchField from "../../core/fields/SwitchField";
import TextField from "../../core/fields/TextField";
import { PaymentMethods } from '../../core/utils/enums';
import { formatCurrencyForServiceCompany, getCurrencySymbolForServiceCompany, valueIsDefined } from "../../core/utils/utils";
import { calculateEstimateAmounts } from "../utils/utils";

const FORM_MODE_CAPTIONS = {
    ADD_PAYMENT: "Please provide the following information to add a payment to this estimate",
    EDIT_PAYMENT: "Use the fields below to edit this payment",
}

class EstimatePaymentForm extends Component {

    constructor(props) {
        super(props)

        this.state = {
            cardholder_name: props.estimate.service_location.external_client.name || "",
            recipientSearchInput: "",

            errors: {}
        }
    }

    componentDidMount() {
        // Return to the previous scroll
        document.querySelector(".main").scrollTo(0, this.props.returnScroll || 0)
    }

    submitCardData = async () => {
        const { mode, submitting, estimate, errors, onFormDataChange, requestAction, switchToPrimaryForm, stripe, elements } = this.props

        if (this.state.cardholder_name === "") {
            this.setState((state, props) => {
                let updatedState = state
                updatedState.errors.cardholder_name = "Please supply a cardholder name."
                return updatedState
            })
        }
        else {
            this.setState((state, props) => {
                let updatedState = state
                updatedState.errors = {}
                return updatedState
            })

            const card = elements.getElement(CardElement);
            const result = await stripe.createToken(card, {name: this.state.cardholder_name, currency: estimate.service_company.currency_code})

            if (result.error) {
                this.setState({"errors": {"card_data": result.error.message}})
            } else {
                onFormDataChange(
                    "stripe_token",
                    result.token,
                    () => onFormDataChange(
                        "receipt_email",
                        this.props.selectedOnlinePaymentRecipient ? this.props.selectedOnlinePaymentRecipient.email : null,
                        () => requestAction("PAY_ONLINE")
                    )
                )
            }
        }
    }

    renderButtons = () => {
        const {
            mode,
            submitting,
            estimate,
            payment,
            errors,
            onFormDataChange,
            requestAction,
            switchToPrimaryForm,
            switchToSecondaryForm,
            paymentMethodOptions,
            updateOnlinePaymentRecipientSelection,
            selectedOnlinePaymentRecipient,
        } = this.props
        const { downPaymentAmountDue } = calculateEstimateAmounts(estimate)

        if (errors.unexpectedError) {
            return (
                <div className="data-panel__action-feedback">
                    <span className="text-invalid"><strong>An unexpected error occurred.</strong></span>
                </div>
            )
        }
        else {
            if (submitting) {
                return <Spinner centered={true} />
            }
            else {
                if ((payment.is_online_payment || false) && parseInt(payment.payment_method) === PaymentMethods.card) {
                    return (
                        <ButtonGroup>
                            <ButtonGroupRow>
                                <UniversalButton type="primary" text={`Charge ${formatCurrencyForServiceCompany(downPaymentAmountDue, estimate.service_company)} Now`} handler={event => this.submitCardData()} />
                            </ButtonGroupRow>
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Cancel" handler={event => switchToPrimaryForm()} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
                else {
                    if (mode.startsWith("ADD_")) {
                        return (
                            <ButtonGroup>
                                <ButtonGroupRow>
                                    <UniversalButton type="primary" text="Add" handler={event => requestAction(mode)} />
                                    <UniversalButton type="secondary" text="Cancel" handler={event => switchToPrimaryForm()} />
                                </ButtonGroupRow>
                            </ButtonGroup>
                        )
                    }
                    else {
                        return (
                            <ButtonGroup>
                                <ButtonGroupRow>
                                    <UniversalButton type="primary" text="Save" handler={event => requestAction(mode)} />
                                </ButtonGroupRow>
                                <ButtonGroupRow>
                                    <UniversalButton type="danger" text="Delete" handler={event => requestAction("DELETE_" + mode.replace("ADD_", "").replace("EDIT_", ""))} />
                                    <UniversalButton type="secondary" text="Cancel" handler={event => switchToPrimaryForm()} />
                                </ButtonGroupRow>
                            </ButtonGroup>
                        )
                    }
                }
            }
        }
    }

    render() {
        const {
            mode,
            submitting,
            estimate,
            payment,
            errors,
            onFormDataChange,
            requestAction,
            switchToPrimaryForm,
            switchToSecondaryForm,
            paymentMethodOptions,
            updateOnlinePaymentRecipientSelection,
            selectedOnlinePaymentRecipient,
            stripe,
            elements,
        } = this.props
        const { downPaymentAmountDue } = calculateEstimateAmounts(estimate)

        return stripe && elements && (
            <div className="data-panel-container data-panel-container--with-margin">
                <div className="data-panel" aria-label="Estimate Payment Create/Update">
                    <div className="data-panel__form" aria-label="Estimate Payment Create/Update Form">
                        <p className="data-panel__form__caption">{FORM_MODE_CAPTIONS[mode]}</p>
                        {
                            mode !== "EDIT_PAYMENT" && (
                                <BasicDisplayField
                                    fieldName="down_payment_amount_due"
                                    fieldLabel="Down Payment Amount Due"
                                    fieldValue={formatCurrencyForServiceCompany(downPaymentAmountDue, estimate.service_company)}
                                ></BasicDisplayField>
                            )
                        }
                        <BasicSelectField
                            fieldName="payment_method"
                            fieldLabel="Payment Method"
                            fieldValue={payment.payment_method}
                            fieldOnChange={payment_method => onFormDataChange("payment_method", payment_method)}
                            choices={paymentMethodOptions}
                            errors={errors}
                        ></BasicSelectField>
                        {
                            valueIsDefined(payment.payment_method) && parseInt(payment.payment_method) === PaymentMethods.other && (
                                <CharField
                                    fieldName="payment_method_other_name"
                                    fieldLabel="Payment Method Name"
                                    fieldValue={payment.payment_method_other_name}
                                    fieldOnChange={payment_method_other_name => onFormDataChange("payment_method_other_name", payment_method_other_name)}
                                    optional={true}
                                    errors={errors}
                                ></CharField>
                            )
                        }
                        {
                            valueIsDefined(payment.payment_method) && parseInt(payment.payment_method) === PaymentMethods.card && (
                                <SwitchField
                                    disabled={mode === "EDIT_PAYMENT" || !(estimate.service_company.accept_online_payments && estimate.service_company.online_payments_configured && estimate.accept_online_payments && downPaymentAmountDue.toFixed(2) > 0.50)}
                                    fieldName="is_online_payment"
                                    fieldLabel="Payment Type"
                                    fieldValue={payment.is_online_payment || false}
                                    fieldOnChange={is_online_payment => onFormDataChange("is_online_payment", is_online_payment)}
                                    checkedText="Online"
                                    uncheckedText="Offline"
                                    errors={errors}
                                ></SwitchField>
                            )
                        }
                        {
                            (payment.is_online_payment || false) && parseInt(payment.payment_method) === PaymentMethods.card
                            ?
                            <Fragment>
                                <div className="data-panel__form__field" id="div_id_card_data" aria-label="Card Information">
                                    <label htmlFor="id_card_data" className="data-panel__form__field__label in-form">Card Information</label>
                                    <div className="data-panel__form__field__input data-panel__form__field__input--stripe-elements">
                                        <CardElement />
                                    </div>
                                    {errors.card_data && <div className="data-panel__form__field__errors" aria-label="Field Errors">{errors.card_data}</div>}
                                </div>
                                <CharField
                                    fieldName="cardholder_name"
                                    fieldLabel="Name on Card"
                                    fieldValue={this.state.cardholder_name || ""}
                                    fieldOnChange={cardholder_name => this.setState({"cardholder_name": cardholder_name || ""})}
                                    errors={this.state.errors}  /* Name is validated inside of this component */
                                ></CharField>
                                <SearchCreateSelectField
                                    fieldName="receipt_email"
                                    fieldLabel="To whom should the receipt be emailed?"
                                    fieldValue={selectedOnlinePaymentRecipient ? selectedOnlinePaymentRecipient.email : null}
                                    inputComponent={
                                        <ContactSearchOrCreateSelect
                                            fieldName="reporter"
                                            restrictOptionsTo="email"
                                            onSelectionChange={selectedOption => updateOnlinePaymentRecipientSelection(selectedOption != null ? selectedOption.object : null)}
                                            onInputChange={(input, action) => {action.action === "input-change" && this.setState({recipientSearchInput: input})}}
                                            defaultSelected={selectedOnlinePaymentRecipient ? contactToContactOption(selectedOnlinePaymentRecipient, "email") : null}
                                            client={estimate.service_location.external_client}
                                            serviceLocation={estimate.service_location}
                                            showCreateButton={true}
                                            onCreateClick={event => switchToSecondaryForm("ADD_CONTACT", null, {name: this.state.recipientSearchInput, populationRef: "updateOnlinePaymentRecipientSelection"})}
                                        ></ContactSearchOrCreateSelect>
                                    }
                                    showButton={selectedOnlinePaymentRecipient !== null && valueIsDefined(selectedOnlinePaymentRecipient.id) && !valueIsDefined(selectedOnlinePaymentRecipient.__isNonExistant__)}
                                    buttonLabel={<Fragment><i className="fa-sharp fa-light fa-pen-to-square" aria-hidden="true"></i>Edit Contact</Fragment>}
                                    buttonAction={event => switchToSecondaryForm("EDIT_CONTACT", selectedOnlinePaymentRecipient, {populationRef: "updateOnlinePaymentRecipientSelection", dataRef: "selectedOnlinePaymentRecipient"})}
                                    errors={errors}
                                ></SearchCreateSelectField>
                                <TextField
                                    fieldName="payment_memo"
                                    fieldLabel="Payment Memo"
                                    fieldValue={payment.payment_memo || ""}
                                    fieldOnChange={payment_memo => onFormDataChange("payment_memo", payment_memo || "")}
                                    rows={3}
                                    placeholder="Notes on this transaction. e.g. 'Payment 1 of 2' or 'Transfer from account #00000'."
                                    optional={true}
                                    errors={errors}
                                ></TextField>
                                <Banner type="info" text={<span>{window.STRIPE_PUBLISHABLE_KEY && window.STRIPE_PUBLISHABLE_KEY.includes("test") && <span className="stripe-test-signifier">Test Mode</span>} You're charging <strong>{formatCurrencyForServiceCompany(downPaymentAmountDue, estimate.service_company)} {estimate.service_company.currency_code}</strong> as a down payment on Estimate <strong>{estimate.custom_id ? estimate.custom_id : estimate.id}</strong></span>} />
                            </Fragment>
                            :
                            <Fragment>
                                <PriceField
                                    fieldName="amount"
                                    fieldLabel="Payment Amount"
                                    fieldValue={payment.amount || ""}
                                    fieldOnChange={amount => onFormDataChange("amount", amount || 0)}
                                    currencySymbol={getCurrencySymbolForServiceCompany(estimate.service_company)}
                                    errors={errors}
                                ></PriceField>
                                <DateField
                                    fieldName="payment_date"
                                    fieldLabel="Payment Date"
                                    fieldValue={payment.payment_date || ""}
                                    fieldOnChange={payment_date => onFormDataChange("payment_date", payment_date || "")}
                                    errors={errors}
                                ></DateField>
                                <CharField
                                    fieldName="reference_id"
                                    fieldLabel="Reference ID"
                                    fieldValue={payment.reference_id}
                                    fieldOnChange={reference_id => onFormDataChange("reference_id", reference_id)}
                                    optional={true}
                                    errors={errors}
                                ></CharField>
                                <TextField
                                    fieldName="payment_memo"
                                    fieldLabel="Payment Memo"
                                    fieldValue={payment.payment_memo || ""}
                                    fieldOnChange={payment_memo => onFormDataChange("payment_memo", payment_memo || "")}
                                    rows={3}
                                    placeholder="Notes on this transaction. e.g. 'Payment 1 of 2' or 'Transfer from account #00000'."
                                    optional={true}
                                    errors={errors}
                                ></TextField>
                            </Fragment>
                        }
                    </div>
                    {this.renderButtons()}
                </div>
            </div>
        )
    }
}

export default (props) => <ElementsConsumer>{({stripe, elements}) => <EstimatePaymentForm {...props} stripe={stripe} elements={elements} />}</ElementsConsumer>
