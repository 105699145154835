import QuickBooksDesktopClientRecordListContainer from "@legacy/integrations/accounting/quickbooks_desktop/QuickBooksDesktopClientRecordListContainer"

import { TopBar } from "@organisms"

import { AppFrame } from "@templates"

import { SETTINGS_ROUTES } from "@routes/settings"

const QUICKBOOKS_CLIENT_RECORD_FILTER_MAP = new Map(
    Object.entries({
        ERRORS: "?status=1&has_errors=true",
        NEW: "?status=1&has_errors=false&recommended_action=1",
        MATCHES: "?status=1&has_errors=false&recommended_action=2&recommended_action=3", // Matches tab holds both matches and merges
        MERGES: "?status=1&has_errors=false&recommended_action=3",
        CONFIRMED: "?status=2",
    }),
)

export default function QuickBooksClientRecordConfirmedList() {
    const settingsAccountingRoute = SETTINGS_ROUTES.accounting

    return (
        <AppFrame.Root pageId="QuickBooksClientRecordConfirmedList" navigationType="settings">
            <TopBar.Root>
                <TopBar.Header>
                    <TopBar.TopBarHeaderStart>
                        <TopBar.PageTitle iconName="settings" label="Settings" />
                        <TopBar.VerticalDivider />
                        <TopBar.PageSubtitle
                            backDestination={settingsAccountingRoute}
                            label="Confirmed Importable Client Records"
                        />
                    </TopBar.TopBarHeaderStart>
                </TopBar.Header>
            </TopBar.Root>
            <AppFrame.PageContent>
                <QuickBooksDesktopClientRecordListContainer
                    tabs={["CONFIRMED"]}
                    tabFilterMap={QUICKBOOKS_CLIENT_RECORD_FILTER_MAP}
                    showFilterInput={true}
                    usesPagedResults={true}
                />
            </AppFrame.PageContent>
        </AppFrame.Root>
    )
}
