import { faEye } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import useUser from "@hooks/useUser"
import { Fragment } from "react"
import { formatDateTime, valueIsDefined } from "../utils/utils"

function LastSeenRenderer(props) {
    const { value, } = props
    const { user:currentUser } = useUser()

    if (valueIsDefined(value)) {
        const { localDateTimeString } = formatDateTime(
            value,
            currentUser.service_company.preferred_timezone,
            {addDay: true, addYear: true}
        )

        return (
            <Fragment>
                <FontAwesomeIcon icon={faEye} />
                <span title={`Last seen ${localDateTimeString}`}>{"\u00A0"}Seen</span>
            </Fragment>
        )
    }
    else {
        return null
    }
}

export default LastSeenRenderer
