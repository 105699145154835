import { ReactNode } from "react"
import { createPortal } from "react-dom"

import styles from "./TopBar.module.scss"
import { TopBarProps } from "./TopBar.types"
import TopBarCTAButton from "./TopBarCTAButton/TopBarCTAButton"
import TopBarHeader from "./TopBarHeader/TopBarHeader"
import TopBarHeaderEnd from "./TopBarHeader/TopBarHeaderEnd/TopBarHeaderEnd"
import TopBarHeaderStart from "./TopBarHeader/TopBarHeaderStart/TopBarHeaderStart"
import TopBarHorizontalDivider from "./TopBarHorizontalDivider/TopBarHorizontalDivider"
import TopBarPagePresence from "./TopBarPagePresence/TopBarPagePresence"
import TopBarPageSubtitle from "./TopBarPageSubtitle/TopBarPageSubtitle"
import TopBarPageTitle from "./TopBarPageTitle/TopBarPageTitle"
import TopBarSearchButton from "./TopBarSearchButton/TopBarSearchButton"
import TopBarVerticalDivider from "./TopBarVerticalDivider/TopBarVerticalDivider"
import TopBarViewSelector from "./TopBarViewSelector/TopBarViewSelector"

function TopBar(props: TopBarProps) {
    const { children } = props

    return (
        <div className={styles.base} data-topbar-portal={true}>
            {children}
        </div>
    )
}

function TopBarPortal(props: { children: ReactNode }) {
    const { children } = props

    const topBar = document.querySelector("[data-topbar-portal]")

    if (!topBar) {
        return null
    } else {
        return createPortal(children, topBar)
    }
}

const Root = TopBar
const PageTitle = TopBarPageTitle
const PageSubtitle = TopBarPageSubtitle
const SearchButton = TopBarSearchButton
const ViewSelector = TopBarViewSelector
const CTAButton = TopBarCTAButton
const Header = TopBarHeader
const HorizontalDivider = TopBarHorizontalDivider
const VerticalDivider = TopBarVerticalDivider
const Portal = TopBarPortal
const PagePresence = TopBarPagePresence

export default {
    Root,
    TopBarHeaderStart,
    TopBarHeaderEnd,
    Header,
    PageTitle,
    PageSubtitle,
    SearchButton,
    ViewSelector,
    CTAButton,
    HorizontalDivider,
    VerticalDivider,
    Portal,
    PagePresence,
}
