import clsx from "clsx"
import { useMemo } from "react"

import { getContainerSizeFromSize } from "@atoms/Icon/Icon"
import styles from "@atoms/Icon/Icon.module.scss"

import { DEFAULT_ROOT_FONT_SIZE } from "@constants/fonts"

import { CustomIconProps } from "./CustomIcon.types"

export default function CustomIcon(props: CustomIconProps) {
    const { icon, size = null, noContainer = false, className, containerClassName } = props

    const fontSize = useMemo(() => (size ? `${size / DEFAULT_ROOT_FONT_SIZE}rem` : null), [size]) // Convert the pixel-based size to rem
    const containerSize = useMemo(() => getContainerSizeFromSize(size, noContainer), [size, noContainer])

    return (
        <div
            className={clsx(styles.base, containerClassName)}
            style={{
                // Repeating the fontSize here is necessary for the mask to work properly
                fontSize,
                ["--container-size" as string]: containerSize,
            }}
        >
            <i
                style={{
                    fontSize,
                }}
                className={clsx("fa-kit", icon, className)}
            />
        </div>
    )
}
