// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DateTimeCell-base__fL2UN{display:flex;align-items:center;width:var(--size-full);padding:var(--size-3_5) var(--size-4);color:var(--gray-12);font:var(--font-global-body-400);font-feature-settings:var(--feature-settings-global-body-400);letter-spacing:var(--letter-spacing-global-body-400)}.DateTimeCell-success__BuoJ2{color:var(--jade-10)}.DateTimeCell-warning__qw0Hg{color:var(--amber-10)}.DateTimeCell-dangerous__Lp_ax{color:var(--tomato-9)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "DateTimeCell-base__fL2UN",
	"success": "DateTimeCell-success__BuoJ2",
	"warning": "DateTimeCell-warning__qw0Hg",
	"dangerous": "DateTimeCell-dangerous__Lp_ax"
};
export default ___CSS_LOADER_EXPORT___;
