import * as RadixTooltip from "@radix-ui/react-tooltip"

import styles from "./Tooltip.module.scss"
import { TooltipProps } from "./Tooltip.types"

export default function Tooltip(props: TooltipProps) {
    const {
        side = "bottom",
        triggerClassName,
        children,
        label,
        type = "caption",
        icon,
        title,
        message,
        isEnabled = true,
        sideOffset = 2,
    } = props

    if (!isEnabled) {
        return <>{children}</>
    } else if (type === "caption" && !label && isEnabled) {
        return <>{children}</>
    } else {
        return (
            <RadixTooltip.Provider>
                <RadixTooltip.Root>
                    <RadixTooltip.Trigger asChild={true} className={triggerClassName}>
                        {children}
                    </RadixTooltip.Trigger>
                    <RadixTooltip.Portal>
                        {type === "caption" ? (
                            <RadixTooltip.Content
                                side={side}
                                className={styles.tooltipCaption}
                                sideOffset={sideOffset}
                            >
                                {label}
                            </RadixTooltip.Content>
                        ) : (
                            <RadixTooltip.Content
                                side={side}
                                className={styles.tooltipMessage}
                                sideOffset={sideOffset}
                            >
                                <div className={styles.tooltipIcon}>{icon}</div>
                                <div className={styles.tooltipMessageContent}>
                                    <span className={styles.tooltipMessageTitle}>{title}</span>
                                    <span className={styles.tooltipMessageText}>{message}</span>
                                </div>
                            </RadixTooltip.Content>
                        )}
                    </RadixTooltip.Portal>
                </RadixTooltip.Root>
            </RadixTooltip.Provider>
        )
    }
}
