import Spinner from "@legacy/core/components/Spinner";
import { Component, Fragment } from "react";
import ButtonGroup from "../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../core/buttons/UniversalButton";
import ContactListDisplay from "../../core/components/ContactListDisplay";
import BasicDisplayField from "../../core/fields/BasicDisplayField";
import { ClientTypes } from "../../core/utils/enums";
import { valueIsDefined } from "../../core/utils/utils";
import { renderClientString } from "../utils/utils";
import ServiceLocationListDisplay from "./ServiceLocationListDisplay";


class ClientMergeConfirmDisplay extends Component {

    componentDidMount() {
        // Return to the previous scroll
        document.querySelector(".main").scrollTo(0, this.props.returnScroll || 0)
    }

    renderButtons = () => {
        const { mode, submitting, errors, sourceClient, destinationClient, formatCurrencyValue, requestAction, switchToPrimaryForm, returnScroll } = this.props

        if (errors.unexpectedError) {
            return (
                <div className="data-panel__action-feedback">
                    <span className="text-invalid"><strong>An unexpected error occurred.</strong></span>
                </div>
            )
        }
        else {
            if (submitting) {
                return (
                    <div className="data-panel__action-feedback">
                        <Spinner centered={true} />
                    </div>
                )
            }
            else {
                return (
                    <ButtonGroup>
                        <ButtonGroupRow>
                            <UniversalButton type="primary" text="Confirm Merge" handler={event => requestAction("MERGE")} />
                        </ButtonGroupRow>
                        <ButtonGroupRow>
                            <UniversalButton type="secondary" text="Change Destination" handler={event => switchToPrimaryForm()} />
                        </ButtonGroupRow>
                    </ButtonGroup>
                )
            }
        }
    }

    renderMergeDetails = (destinationClient) => {
        let detailUrl = DjangoUrls["clients:clients-detail"](window.MARKETPLACE_ENTITY_SLUG, destinationClient.id)

        return <Fragment>{"Merging with "}<a className="text-link text-link--inline" href={detailUrl} target="_blank">{renderClientString(destinationClient)}&nbsp;&nbsp;<i className="fa-sharp fa-light fa-arrow-up-right-from-square" aria-hidden="true"></i></a></Fragment>
    }

    renderMatchFieldText = (source, destination, fieldName) => {
        let sourceValue = source[fieldName]
        let destinationValue = destination[fieldName]

        if (sourceValue !== "" && destinationValue === "") {
            return <span className="data-panel__form__field__success"><i className="fa-sharp fa-light fa-square-plus" aria-hidden="true"></i>&nbsp;&nbsp;This will be added to the destination client.</span>
        }
        else if (sourceValue !== destinationValue) {
            return <span className="data-panel__form__field__warning"><i className="fa-sharp fa-light fa-triangle-exclamation" aria-hidden="true"></i>&nbsp;&nbsp;This does not match the destination client and will be ignored.</span>
        }
    }

    render() {
        const { mode, submitting, errors, sourceClient, destinationClient, formatCurrencyValue, requestAction, switchToPrimaryForm, returnScroll } = this.props

        return (
            <div className="data-panel-container data-panel-container--with-margin" style={{width: "100%"}}>
                <div className="data-panel" aria-label="Client">
                    <div className="data-panel__heading" aria-label="Client Information">
                        <span className="data-panel__heading__title" aria-label="Name">{renderClientString(sourceClient)}</span>
                        <hr aria-hidden="true" />
                    </div>
                    <div className="data-panel__form" aria-label="Client Merge Display">
                        <BasicDisplayField
                            fieldName="merge_details"
                            fieldLabel="Merge Details"
                            fieldValue={this.renderMergeDetails(destinationClient)}
                        ></BasicDisplayField>
                        <BasicDisplayField
                            fieldName="client_type"
                            fieldLabel="Client Type"
                            fieldValue={sourceClient.client_type_label}
                            footer={this.renderMatchFieldText(sourceClient, destinationClient, "client_type")}
                        ></BasicDisplayField>
                        <BasicDisplayField
                            fieldName="industry_type"
                            fieldLabel="Industry Type"
                            fieldValue={sourceClient.industry_type_label || "--"}
                            footer={this.renderMatchFieldText(sourceClient, destinationClient, "industry_type")}
                        ></BasicDisplayField>
                        <BasicDisplayField
                            fieldName="name"
                            fieldLabel={sourceClient.client_type === ClientTypes.business ? "Business Name" : "Individual Name"}
                            fieldValue={sourceClient.name}
                            footer={this.renderMatchFieldText(sourceClient, destinationClient, "name")}
                        ></BasicDisplayField>
                        <BasicDisplayField
                            fieldName="billing_address"
                            fieldLabel="Billing Address"
                            fieldValue={sourceClient.billing_address_formatted_multiline || "--"}
                            footer={this.renderMatchFieldText(sourceClient, destinationClient, "billing_address_formatted")}
                        ></BasicDisplayField>
                        <BasicDisplayField
                            fieldName="notes"
                            fieldLabel="Client Notes"
                            fieldValue={sourceClient.notes || "--"}
                            footer={this.renderMatchFieldText(sourceClient, destinationClient, "notes")}
                        ></BasicDisplayField>
                        <BasicDisplayField
                            fieldName="credit_limit"
                            fieldLabel="Credit Limit"
                            fieldValue={valueIsDefined(sourceClient.credit_limit) ? formatCurrencyValue(sourceClient.credit_limit) : "--"}
                        ></BasicDisplayField>
                        <BasicDisplayField
                            fieldName="default_payment_terms"
                            fieldLabel="Default Client Payment Terms"
                            fieldValue={sourceClient.default_invoice_net !== null ? (sourceClient.default_invoice_net === 0 ? "Same Day" : `Net ${sourceClient.default_invoice_net}`) : "Same as company default" }
                        ></BasicDisplayField>
                        <ContactListDisplay
                            fieldName="contacts"
                            fieldLabel="Client Contacts"
                            contacts={sourceClient.contacts}
                            primaryContactId={sourceClient.primary_contact}
                            header={<span className="data-panel__form__field__success" style={{margin: "0 0 1em"}}><i className="fa-sharp fa-light fa-square-plus" aria-hidden="true"></i>&nbsp;&nbsp;These will be merged into the destination client.</span>}
                        ></ContactListDisplay>
                        <ServiceLocationListDisplay
                            fieldName="service_locations"
                            fieldLabel="Service Locations"
                            serviceLocations={sourceClient.service_locations}
                            header={<span className="data-panel__form__field__success" style={{margin: "0 0 1em"}}><i className="fa-sharp fa-light fa-square-plus" aria-hidden="true"></i>&nbsp;&nbsp;These will be merged into the destination client.</span>}
                        ></ServiceLocationListDisplay>
                    </div>
                    {this.renderButtons()}
                </div>
            </div>
        )
    }
}

export default ClientMergeConfirmDisplay;
