import { Component } from "react";
import ButtonGroup from "../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../core/buttons/UniversalButton";
import BasicDisplayField from "../../core/fields/BasicDisplayField";
import SearchCreateSelectField from "../../core/fields/SearchCreateSelectField";
import ClientSearchOrCreateSelect, { clientToClientOption } from "../inputs/ClientSearchOrCreateSelect";
import { renderClientString } from "../utils/utils";


class ClientMergeForm extends Component {

    componentDidMount() {
        // Return to the previous scroll
        document.querySelector(".main").scrollTo(0, this.props.returnScroll || 0)
    }

    renderButtons = () => {
        const {
            mode,
            mergeData,
            sourceClient,
            destinationClient,
            onFormDataChange,
            requestAction,
            switchToSecondaryForm,
            updateClientSelection,
            selectedClient,
            returnScroll
        } = this.props

        return (
            <ButtonGroup>
                <ButtonGroupRow>
                    <UniversalButton type="primary" text="Continue" handler={event => requestAction("CONFIRM_MERGE")} />
                    <UniversalButton type="secondary" text="Cancel" handler={event => requestAction("CANCEL_MERGE")} />
                </ButtonGroupRow>
            </ButtonGroup>
        )
    }

    render() {
        const {
            mode,
            mergeData,
            errors,
            sourceClient,
            destinationClient,
            onFormDataChange,
            requestAction,
            switchToSecondaryForm,
            updateClientSelection,
            returnScroll
        } = this.props

        return (
            <div className="data-panel-container data-panel-container--with-margin">
                <div className="data-panel" aria-label="Client Merge">
                    <div className="data-panel__form" aria-label="Client Merge Form">
                        <p className="data-panel__form__caption">Select the client you wish to merge this client into below.</p>
                        <p className="data-panel__form__caption text-medium">
                            This should only be used to merge duplicated clients. All equipment, jobs, and invoices from the source client will be retroactively updated with the destination client's details, and the source client and events associated with it will be deleted.<br /><br />
                            This action cannot be undone.
                        </p>
                        {
                            window.ACCOUNTING_INTEGRATION === 2 && (
                                <p className="data-panel__form__caption text-medium">
                                    Merging in QuickBooks Desktop can be quite tricky due to a few bugs and restrictions in the QuickBooks Desktop API. We highly suggest reaching out to an account manager to help with any merges you plan on doing. By continuing, you’re asserting that you understand the quirks of merging in QuickBooks and are willing to get your hands dirty troubleshooting.
                                </p>
                            )
                        }
                        <BasicDisplayField
                            fieldName="source_client"
                            fieldLabel="Source Client"
                            fieldValue={renderClientString(sourceClient)}
                            indented={false}
                        ></BasicDisplayField>
                        <SearchCreateSelectField
                            fieldName="destination_client"
                            fieldLabel="Destination Client"
                            fieldValue={destinationClient !== null ? renderClientString(destinationClient) : null}
                            inputComponent={
                                <ClientSearchOrCreateSelect
                                    ignoreOptions={[sourceClient.id]}
                                    onSelectionChange={selectedOption => updateClientSelection(selectedOption != null ? selectedOption.object : null)}
                                    defaultSelected={destinationClient !== null ? clientToClientOption(destinationClient) : null}
                                    showCreateButton={false}
                                ></ClientSearchOrCreateSelect>
                            }
                            showButton={false}
                            errors={errors}
                        ></SearchCreateSelectField>
                    </div>
                    {this.renderButtons()}
                </div>
            </div>
        )
    }

}

export default ClientMergeForm;
