import EmailForm from "./EmailForm/EmailForm"
import PhoneForm from "./PhoneForm/PhoneForm"
import styles from "./UserContactsForms.module.scss"

export default function UserContactsForms() {
    return (
        <div className={styles.base}>
            <div className={styles.forms}>
                <EmailForm />
                <PhoneForm />
            </div>
            <span className={styles.disclaimer}>
                For your security, we will send you a code to verify any change to your email address or phone number.
            </span>
        </div>
    )
}
