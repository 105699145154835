export const SETTINGS_ROUTES = {
    main: "/settings/",
    profile: "/settings/profile/",
    accounting: "/settings/accounting/",
    availabilitySchedules: "/settings/availability-schedules/",
    companyCam: "/settings/company-cam/",
    notifications: "/settings/notifications/",
    payments: "/settings/payments/",
    preferences: "/settings/preferences/",
    securityAndAccess: "/settings/security-and-access/",
    notificationsJobs: "/settings/notifications/jobs/",
    notificationsEstimates: "/settings/notifications/estimates/",
    notificationsInventory: "/settings/notifications/inventory/",
}
