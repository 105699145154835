import React, { Component } from "react";
import { renderServiceLocationString } from "../utils/utils";


class ServiceLocationDisplay extends Component {

    render = () => {
        const { serviceLocation } = this.props

        return (
            <div className="data-panel__form__field__display-list__object" aria-label="Service Location"><span>{renderServiceLocationString(serviceLocation)}</span></div>
        )
    }
}

export default ServiceLocationDisplay;
