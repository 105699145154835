import { Breadcrumb } from "@organisms/index"

import { Settings } from "@templates/index"

import { SETTINGS_ROUTES } from "@routes/settings"

import AvatarManagement from "./components/AvatarManagement/AvatarManagement"
import UserContactsForms from "./components/UserContactsForms/UserContactsForms"
import UserNamesForms from "./components/UserNamesForms/UserNamesForms"

export default function SettingsProfile() {
    return (
        <Settings.Wrapper title="Profile">
            <Settings.PageContainer>
                <Settings.Breadcrumbs>
                    <Breadcrumb.Item label="My account" showSlash={true} />
                    <Breadcrumb.Item label="Profile" path={SETTINGS_ROUTES.profile} />
                </Settings.Breadcrumbs>
                <Settings.PageHeader
                    variant="page-heading"
                    title="Profile"
                    subtitle="Manage your Roopairs profile and contact information."
                />

                <Settings.Divider />

                <Settings.Section gap={24}>
                    <Settings.PageHeader
                        title="Your profile"
                        subtitle="Customize how you appear to your teammates and clients."
                        variant="section-heading"
                    />
                    <AvatarManagement />
                    <UserNamesForms />
                </Settings.Section>

                <Settings.Divider />

                <Settings.Section gap={24}>
                    <Settings.PageHeader
                        title="Email and phone"
                        subtitle="Manage the email address and phone number you use to sign into Roopairs and receive notifications."
                        variant="section-heading"
                    />
                    <UserContactsForms />
                </Settings.Section>
            </Settings.PageContainer>
        </Settings.Wrapper>
    )
}
