import Spinner from '@legacy/core/components/Spinner';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import debounce from "debounce-promise";
import moment from "moment-timezone";
import { Component } from "react";
import CharField from "../core/fields/CharField";
import { formatLocalTime } from "../core/utils/utils";
import AssignedTechniciansField from "../jobs/fields/AssignedTechniciansField";
import { getDefaultAssignedTechnicians } from "../jobs/utils/utils";
import Calendar from "./components/Calendar";
import CalendarDayPicker from "./components/CalendarDayPicker";


class CalendarContainer extends Component {

    // Initialize

    constructor(props) {
        super(props)

        const desiredDate = new Date(new URLSearchParams(document.location.search).get("date") || "")

        this.workingTechnicianOptions = window.WORKING_TECHNICIANS || []

        this.state = {
            selectedDay: !isNaN(desiredDate) ? desiredDate : new Date(),
            calendarData: null,
            assigned_technicians: null,
            search_keywords: "",
        }
    }

    fetchCalendarDataForDate = async (date) => {
        const dateString = moment.tz(date, window.PREFERRED_TIMEZONE).format("YYYY-MM-DD")

        const calendarDataEndpoint = DjangoUrls["calendar:api-calendar-data-list"](window.MARKETPLACE_ENTITY_SLUG, dateString)
        const calendarDataResponse = await fetch(calendarDataEndpoint + `?keywords=${this.state.search_keywords}&` + `${(this.state.assigned_technicians !== null && this.state.assigned_technicians.length !== 0) ? `${this.state.assigned_technicians.map(technicianID => `assigned_technicians=${technicianID}`).join("&")}` : ""}`)
        const calendarData = await calendarDataResponse.json()

        const availabilitySchedulesEndpoint = DjangoUrls["calendar:api-availability-schedules-list"](window.MARKETPLACE_ENTITY_SLUG, dateString)
        const availabilitySchedulesResponse = await fetch(availabilitySchedulesEndpoint)
        const availabilitySchedulesData = await availabilitySchedulesResponse.json()

        // Populate resources
        let resourceIDs = new Set()
        let resources = availabilitySchedulesData.filter(
            schedule => {
                resourceIDs.add(schedule.technician.id)
                return this.state.assigned_technicians === null || this.state.assigned_technicians.length === 0 || this.state.assigned_technicians.includes(schedule.technician.id)
            }
        ).map(
            schedule => {
                return {
                    ...schedule.technician,
                    available: schedule.available,
                    status: schedule.status,
                }
            }
        )

        // Populate Events
        let events = []

        const dayStart = new Date(date)
        dayStart.setHours(0, 0, 0, 0)

        const dayEnd = new Date(date)
        dayEnd.setDate(dayEnd.getDate() + 1)
        dayEnd.setHours(0, 0, 0, 0)

        for (let event of calendarData) {
            // If the technicians are filtered, don't ignore the events
            if ((this.state.assigned_technicians !== null && this.state.assigned_technicians.length !== 0) && !(this.state.assigned_technicians.includes(event.technician_id))) {
                continue
            }

            // If the technician isn't in the resource list, mark it as inactive
            if (!resourceIDs.has(event.technician_id)) {
                resourceIDs.add(event.technician_id)
                let technician = event.assigned_technicians.find(technician => technician.id === event.technician_id)
                resources.push(
                    {
                        ...technician,
                        available: false,
                        status: "Inactive",
                    }
                )
            }

            // Create the events
            const startTime = new Date(event.start_time)
            const endTime = new Date(event.end_time)

            events.push({
                ...event,
                estimated_arrival_time: startTime,
                start_time: new Date(Math.max(dayStart, startTime)),
                end_time: new Date(
                    Math.min(dayEnd,
                        Math.max(
                            endTime,
                            new Date((new Date(Math.max(dayStart, startTime))).getTime() + (60 * 60000))  // Slots must be at least 1 hour long
                        )
                    )
                ),
            })
        }

        return { resources, events }
    }

    componentDidMount = async () => {
        if (this.state.calendarData === null) {
            const calendarData = await this.fetchCalendarDataForDate(this.state.selectedDay)

            this.setState((state, props) => {
                let updatedState = state
                updatedState.calendarData = calendarData
                return updatedState
            })
        }
    }

    // Handle Actions

    handleActionRequest = (action) => {
        switch (action) {
            default:
                console.error(`No action handler exists for action "${action}".`)
        }
    }

    handleDayChange = async (day) => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState.selectedDay = day
            updatedState.calendarData = null
            return updatedState
        })

        const calendarData = await this.fetchCalendarDataForDate(day)

        this.setState((state, props) => {
            let updatedState = state
            updatedState.calendarData = calendarData
            return updatedState
        })
    }

    handleSearchKeywordsChange = debounce(
        (search_keywords) => {
            this.setState(
                (state, props) => {
                    let updatedState = state
                    updatedState.search_keywords = search_keywords
                    return updatedState
                },
                () => this.handleDayChange(this.state.selectedDay)
            )
        },
        375
    )

    handleAssignedTechniciansChange = (assigned_technicians) => {
        this.setState(
            (state, props) => {
                let updatedState = state
                updatedState.assigned_technicians = assigned_technicians
                return updatedState
            },
            () => this.handleDayChange(this.state.selectedDay)
        )
    }

    // Render

    renderCalendar = () => {
        if (this.state.calendarData === null) {
            return <Spinner centered={true} />
        }
        else {
            return (
                <Calendar
                    selectedDay={this.state.selectedDay}
                    resources={this.state.calendarData.resources}
                    events={this.state.calendarData.events}
                    startOfDay={window.START_OF_DAY}
                    endOfDay={window.END_OF_DAY}
                    preferredTimezone={window.PREFERRED_TIMEZONE}
                    calendarExpanded={true}
                ></Calendar>
            )
        }
    }

    renderCalendarContainer = () => {
        return (
            <div className="data-panel calendar-calendar-container" aria-label="Calendar Calendar">
                <span className="calendar-date">{formatLocalTime(this.state.selectedDay, window.PREFERRED_TIMEZONE, false, true, true, false).localDateString}</span>
                {this.renderCalendar()}
            </div>
        )
    }

    render() {
        const queryClient = new QueryClient()

        return (
            <div className="data-panel-container data-panel-container--calendar data-panel-container--with-margin">
                <div className="day-picker-search">
                    <QueryClientProvider client={queryClient}>
                    <CalendarDayPicker
                        initialDate={this.state.selectedDay}
                        onDateChange={this.handleDayChange}
                        showAvailability={true}
                    ></CalendarDayPicker>
                    </QueryClientProvider>
                    <div className="data-panel day-picker" aria-label="Calendar Filters">
                        <div className="data-panel__form data-panel__form--filters" aria-label="Calendar Filters">
                            <CharField
                                fieldName="search_keywords"
                                fieldLabel=""
                                fieldValue={this.state.search_keywords}
                                fieldOnChange={search_keywords => this.handleSearchKeywordsChange(search_keywords)}
                                placeholder="Search jobs..."
                                errors={{}}
                            ></CharField>
                            <AssignedTechniciansField
                                fieldName="assigned_technicians"
                                fieldLabel=""
                                fieldValue={getDefaultAssignedTechnicians(this.state, this.workingTechnicianOptions)}
                                fieldOnChange={assigned_technicians => this.handleAssignedTechniciansChange(assigned_technicians)}
                                choices={this.workingTechnicianOptions}
                                placeholder="Filter technicians..."
                                noOptionsMessage="No Technicians Found"
                                errors={{}}
                            ></AssignedTechniciansField>
                        </div>
                    </div>
                </div>
                {this.renderCalendarContainer()}
            </div>
        )
    }
}

export default CalendarContainer;
