// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SeenCell-base__FPRig{display:flex;gap:var(--size-1_5);align-items:center;width:var(--size-full);padding:var(--size-4);color:var(--gray-12);font:var(--font-global-caption-medium-400);font-feature-settings:var(--feature-settings-global-caption-medium-400);letter-spacing:var(--letter-spacing-global-caption-medium-400)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "SeenCell-base__FPRig"
};
export default ___CSS_LOADER_EXPORT___;
