import { Component } from "react";
import deepcopy from "rfdc";
import WrapperButton from "../../core/buttons/WrapperButton";
import { snakeCaseToTitleCase, valueIsDefined } from "../../core/utils/utils";


class InlineInventoryLevelDisplay extends Component {

    render() {
        const { inventoryLevel, requestAction } = this.props
        let errors = deepcopy()(inventoryLevel.errors || {})

        return (
            <WrapperButton key={`${inventoryLevel.id}_${inventoryLevel.location_name}`} type="object_link" handler={event => requestAction("INVENTORY_LEVEL_EDIT")} title={inventoryLevel.location_label} ariaLabel="Update Inventory Level">
                <div className="data-panel__list-select__option__label inventory-level-label">
                    {
                        valueIsDefined(inventoryLevel.inventory_status) && (
                            <div className="inventory-level-label__row inventory-level-status-display">
                                <div className="inventory-level-label__field" aria-label={`${inventoryLevel.inventory_status}`}>
                                    <div className="inventory-level-label__field-value"><span className={`data-tag inventory-level--${inventoryLevel.inventory_status}`} style={{margin: "0", width: "100%"}}>{snakeCaseToTitleCase(inventoryLevel.inventory_status)}</span></div>
                                </div>
                            </div>
                        )
                    }

                    {errors.non_field_error && <div className="data-panel__form__field__errors" aria-label="Non Field Error">{errors.non_field_error}</div>}
                    <div className="inventory-level-label__row">
                        <div className="inventory-level-label__field" aria-label="Location">
                            <div className="inventory-level-label__field-label" aria-hidden="true">Inventory Location</div>
                            <div className="inventory-level-label__field-value">{inventoryLevel.location_label}</div>
                        </div>
                    </div>
                    <div className="inventory-level-label__row">
                        <div className="inventory-level-label__field" aria-label="Quantity on Hand">
                            <div className={"inventory-level-label__field-label" + (errors.quantity_on_hand ? " invalid" : "")} aria-hidden="true">Quantity on Hand</div>
                            <div className="inventory-level-label__field-value">{valueIsDefined(inventoryLevel.quantity_on_hand) ? `${parseFloat(inventoryLevel.quantity_on_hand)}` : "--"}</div>
                            {errors.quantity_on_hand && <div className="data-panel__form__field__errors" aria-label="Field Errors">{errors.quantity_on_hand}</div>}
                        </div>
                        <div className="inventory-level-label__field" aria-label="Quantity on Order">
                            <div className={"inventory-level-label__field-label" + (errors.quantity_on_order ? " invalid" : "")} aria-hidden="true">Quantity on Order</div>
                            <div className="inventory-level-label__field-value">{valueIsDefined(inventoryLevel.quantity_on_order) ? `${parseFloat(inventoryLevel.quantity_on_order)}` : "--"}</div>
                            {errors.quantity_on_order && <div className="data-panel__form__field__errors" aria-label="Field Errors">{errors.quantity_on_order}</div>}
                        </div>
                    </div>
                    <div className="inventory-level-label__row">
                        <div className="inventory-level-label__field" aria-label="Quantity Desired">
                            <div className={"inventory-level-label__field-label" + (errors.quantity_desired ? " invalid" : "")} aria-hidden="true">Quantity Desired</div>
                            <div className="inventory-level-label__field-value">{valueIsDefined(inventoryLevel.quantity_desired) ? `${parseFloat(inventoryLevel.quantity_desired)}` : "--"}</div>
                            {errors.quantity_desired && <div className="data-panel__form__field__errors" aria-label="Field Errors">{errors.quantity_desired}</div>}
                        </div>
                        <div className="inventory-level-label__field" aria-label="Quantity Low Alert Threshold">
                            <div className={"inventory-level-label__field-label" + (errors.quantity_low_alert_threshold ? " invalid" : "")} aria-hidden="true">Quantity Low Alert Threshold</div>
                            <div className="inventory-level-label__field-value">{valueIsDefined(inventoryLevel.quantity_low_alert_threshold) ? `${parseFloat(inventoryLevel.quantity_low_alert_threshold)}` : "--"}</div>
                            {errors.quantity_low_alert_threshold && <div className="data-panel__form__field__errors" aria-label="Field Errors">{errors.quantity_low_alert_threshold}</div>}
                        </div>
                    </div>
                    <div className="inventory-level-label__row">
                        <div className="inventory-level-label__field" aria-label="Bin">
                            <div className={"inventory-level-label__field-label" + (errors.bin ? " invalid" : "")} aria-hidden="true">Bin</div>
                            <div className="inventory-level-label__field-value">{inventoryLevel.bin || "--"}</div>
                            {errors.bin && <div className="data-panel__form__field__errors" aria-label="Field Errors">{errors.bin}</div>}
                        </div>
                    </div>
                </div>
                <div className="data-panel__list-select__option__icon"><i className="fa-sharp fa-angle-right" aria-hidden="true"></i></div>
            </WrapperButton>
        )
    }
}

export default InlineInventoryLevelDisplay;
