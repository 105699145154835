import { Fragment } from "react";
import BasicDisplayField from "../../core/fields/BasicDisplayField";
import BasicSelectField from "../../core/fields/BasicSelectField";
import CharField from "../../core/fields/CharField";
import { ShippingCarriers, ShippingMethods } from "../../core/utils/enums";
import { valueIsDefined } from "../../core/utils/utils";


function ShippingPreferenceFormFields(props) {
    const { purchaseOrder, shippingData, errors, onFormDataChange, shippingCarrierChoices, shippingMethodChoices } = props

    const selectedCarrierChoice = shippingCarrierChoices.find(choice => choice.value === parseInt(shippingData.shipping_carrier))

    return (
        <Fragment>
            <BasicSelectField
                fieldName="shipping_carrier"
                fieldLabel="Shipping Carrier"
                fieldValue={shippingData.shipping_carrier}
                fieldOnChange={shipping_carrier => onFormDataChange("shipping_carrier", shipping_carrier)}
                choices={shippingCarrierChoices}
                errors={errors}
            ></BasicSelectField>
            {
                valueIsDefined(shippingData.shipping_carrier) && parseInt(shippingData.shipping_carrier) === ShippingCarriers.other && (
                    <CharField
                        fieldName="shipping_carrier_other_name"
                        fieldLabel="Shipping Carrier Name"
                        fieldValue={shippingData.shipping_carrier_other_name}
                        fieldOnChange={shipping_carrier_other_name => onFormDataChange("shipping_carrier_other_name", shipping_carrier_other_name)}
                        errors={errors}
                    ></CharField>
                )
            }
            {
                selectedCarrierChoice && valueIsDefined(selectedCarrierChoice.account_id) && (
                    <BasicDisplayField
                        fieldName="carrier_account_id"
                        fieldLabel="Carrier Account ID"
                        fieldValue={selectedCarrierChoice.account_id}
                        indented={true}
                    ></BasicDisplayField>
                )
            }
            <BasicSelectField
                fieldName="shipping_method"
                fieldLabel="Shipping Method"
                fieldValue={shippingData.shipping_method}
                fieldOnChange={shipping_method => onFormDataChange("shipping_method", shipping_method)}
                choices={shippingMethodChoices}
                errors={errors}
            ></BasicSelectField>
            {
                valueIsDefined(shippingData.shipping_method) && parseInt(shippingData.shipping_method) === ShippingMethods.other && (
                    <CharField
                        fieldName="shipping_method_other_name"
                        fieldLabel="Shipping Method Name"
                        fieldValue={shippingData.shipping_method_other_name}
                        fieldOnChange={shipping_method_other_name => onFormDataChange("shipping_method_other_name", shipping_method_other_name)}
                        errors={errors}
                    ></CharField>
                )
            }
            <CharField
                fieldName="tracking_number"
                fieldLabel="Tracking Number"
                fieldValue={shippingData.tracking_number || ""}
                fieldOnChange={tracking_number => onFormDataChange("tracking_number", tracking_number)}
                maxLength="100"
                optional={true}
                optionalText={"if available"}
                errors={errors}
            ></CharField>
        </Fragment>
    )
}

export default ShippingPreferenceFormFields;
