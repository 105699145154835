import { Component, Fragment } from 'react';
import WrapperButton from '../../core/buttons/WrapperButton';
import Spinner from './Spinner';


class Accordion extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isOpen: props.startOpen || false,
            data: null,
        }
    }

    fetchData = async () => {
        console.error("You must implement Accordion.getListEndpoint()")
    }

    componentDidMount = async () => {
        if (this.state.data === null) {
            const data = await this.fetchData()
            this.setState({data})
        }
    }

    toggle = () => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState.isOpen = !state.isOpen
            return updatedState
        })
    }

    getID = () => {
        return undefined
    }

    getTitle = () => {
        console.error("You must implement Accordion.getTitle()")
    }

    getHelpText = () => {
        return `Click or tap to view ${this.getTitle().toLowerCase()}.`
    }

    getExtraBodyClasses = () => {
        return null
    }

    renderBody = (data) => {
        console.error("You must implement Accordion.renderBody()")
    }

    getExtraFooterClasses = () => {
        return null
    }

    renderFooter = (data) => {
        return null
    }

    renderBodyContainer = (data) => {
        if (data === null) {
            return (
                <div className="accordion__body" aria-label="Accordion Body">
                    <Spinner centered={true} />
                </div>
            )
        }
        else {
            const extraBodyClasses = this.getExtraBodyClasses()
            const extraFooterClasses = this.getExtraFooterClasses()
            const footer = this.renderFooter(data)

            return <Fragment>
                {<div className={`accordion__body${extraBodyClasses ? ` ${extraBodyClasses}` : ""}`} aria-label="Accordion Body">{this.renderBody(data)}</div>}
                {footer !== null && <div className={`accordion__footer${extraFooterClasses ? ` ${extraFooterClasses}` : ""}`} aria-label="Accordion Footer">{footer}</div>}
            </Fragment>
        }
    }

    render() {
        const { startOpen=false, startInactive=false, showHelpText=True } = this.props

        return (
            <div className="accordion-container" id={this.getID()}>
                <div className={"accordion" + (this.state.isOpen ? " is-open" : "") + (startInactive ? "" : " active")} aria-label={this.getTitle()}>
                    <WrapperButton type="open_trigger" handler={this.toggle} ariaLabel="Accordion Details Expand Trigger">
                        <div className="accordion__header" aria-label="Accordion Header">
                            <div className="accordion__header__text"><h3>{this.getTitle()}</h3></div>
                            <div className="accordion__header__icon" aria-hidden="true"><i className={"fa-sharp fa-regular " + (this.state.isOpen ? "fa-angle-up" : "fa-angle-down")}></i></div>
                        </div>
                    </WrapperButton>
                    {this.state.isOpen && this.renderBodyContainer(this.state.data)}
                </div>
                {
                    !this.state.isOpen && showHelpText && (
                        <div className="accordion-help-text" aria-hidden="true" onClick={this.toggle}>
                            <span>{this.getHelpText()}</span>
                        </div>
                    )
                }
            </div>
        )
    }
}

export default Accordion;
