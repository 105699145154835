import { useState } from "react"

import { SelectListItem } from "@molecules/Select/SelectListItem/SelectListItem.types"
import { Select } from "@molecules/index"

import styles from "./AppLanguageSelector.module.scss"

const languages = [
    {
        value: "english",
        label: "English",
    },
]

export default function AppLanguageSelector() {
    const [selectedLanguage, setSelectedLanguage] = useState<SelectListItem>(languages[0])

    return (
        <Select.Root>
            <Select.Input
                text={selectedLanguage.label}
                placeholder="Language"
                isDisabled={true}
                className={styles.button}
                size="md"
            />
            <Select.List>
                <Select.ListItemStack>
                    {languages.map((item) => {
                        return (
                            <Select.ListItem
                                key={item.value}
                                item={item}
                                onSelect={() => setSelectedLanguage(item)}
                                isSelected={selectedLanguage.value === item.value}
                            />
                        )
                    })}
                </Select.ListItemStack>
            </Select.List>
        </Select.Root>
    )
}
