// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TableViewMenuBottomSheet-sortingContainer__IjrAd{padding:var(--size-0)}.TableViewMenuBottomSheet-sortingColumns__LEOmq{display:flex;flex-direction:column;gap:var(--size-0_5);padding:var(--size-2_5)}.TableViewMenuBottomSheet-sortingOrderSelector__FOhPj{padding:var(--size-2_5)}.TableViewMenuBottomSheet-bottomSheetContent__l68do{max-height:inherit;padding:var(--size-0)}.TableViewMenuBottomSheet-addColumnButton__bgpVa{padding:var(--size-1_5) var(--size-2_5)}.TableViewMenuBottomSheet-primaryContent__TMRqG{padding:var(--size-0)}.TableViewMenuBottomSheet-primaryContentItems__TgyWX{display:flex;flex-direction:column;gap:var(--size-0_5);padding:var(--size-2_5)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sortingContainer": "TableViewMenuBottomSheet-sortingContainer__IjrAd",
	"sortingColumns": "TableViewMenuBottomSheet-sortingColumns__LEOmq",
	"sortingOrderSelector": "TableViewMenuBottomSheet-sortingOrderSelector__FOhPj",
	"bottomSheetContent": "TableViewMenuBottomSheet-bottomSheetContent__l68do",
	"addColumnButton": "TableViewMenuBottomSheet-addColumnButton__bgpVa",
	"primaryContent": "TableViewMenuBottomSheet-primaryContent__TMRqG",
	"primaryContentItems": "TableViewMenuBottomSheet-primaryContentItems__TgyWX"
};
export default ___CSS_LOADER_EXPORT___;
