import { useQuery } from "@tanstack/react-query";
import { Component, Fragment } from "react";
import WrapperButton from "../buttons/WrapperButton";
import { PriceBookItemTypes } from "../utils/enums";
import { formatServiceDates, getLineItemUnits, valueIsDefined } from "../utils/utils";
import { fetchPriceBookData } from "./LineItemListDisplay";

function PriceBookPartDataDisplay(props) {
    const { lineItem } = props
    const currentUser = window.CURRENT_USER

    const {
        data: pricebookItem,
        error,
        isLoading,
        isError,
    } = useQuery({
        queryKey: ["pricebook-retrieve-by-description", currentUser.id, lineItem.description],
        queryFn: () => fetchPriceBookData(currentUser, lineItem.description),
        staleTime: 60000,
    })

    if (isError) {
        console.error(error)
    } else if (pricebookItem) {
        return (
            <div className="line-item-label__row">
                <div className="line-item-label__field" aria-label="Manufacturer">
                    <div className="line-item-label__field-label" aria-hidden="true">
                        Manufacturer
                    </div>
                    <div className="line-item-label__field-value" aria-label="Manufacturer">
                        {pricebookItem.manufacturer || "--"}
                    </div>
                </div>
                <div className="line-item-label__field" aria-label="Part Number">
                    <div className="line-item-label__field-label" aria-hidden="true">
                        Part Number
                    </div>
                    <div className="line-item-label__field-value" aria-label="Part Number">
                        {pricebookItem.part_number || "--"}
                    </div>
                </div>
            </div>
        )
    }
}

class LineItemListSelect extends Component {

    renderSummary = (item) => {
        const { showSummaryHint=false } = this.props

        if (item.summary) {
            return (
                <div className="line-item-label__row">
                    <div className="line-item-label__field">
                        <div className="line-item-label__field-label">{item.line_item_type === PriceBookItemTypes.service ? "Service" : "Detailed"} Summary</div>
                        <div className="line-item-label__field-value preserve-whitespace">{item.summary}</div>
                        {item.errors && item.errors.summary && <div className="data-panel__form__field__errors" aria-label="Field Errors">{item.errors.summary}</div>}
                    </div>
                </div>
            )
        }
        else if (showSummaryHint) {
            return (
                <div className="line-item-label__row">
                    <div className="line-item-label__field">
                        <div className="line-item-label__field-label">{item.line_item_type === PriceBookItemTypes.service ? "Service" : "Detailed"} Summary</div>
                        <div className="line-item-label__field-value preserve-whitespace">(Click to provide a summary)</div>
                        {item.errors && item.errors.summary && <div className="data-panel__form__field__errors" aria-label="Field Errors">{item.errors.summary}</div>}
                    </div>
                </div>
            )
        }
    }

    renderQuickBooksItem = (item) => {
        if (item.quickbooks_desktop_item_id) {
            const quickbooks_desktop_item = window.QUICKBOOKS_DESKTOP_ITEMS.find(quickbooks_item => item.quickbooks_desktop_item_id === quickbooks_item.value)

            return (
                <div className="line-item-label__row">
                    <div className="line-item-label__field">
                        <div className="line-item-label__field-label">QuickBooks PriceBook Item</div>
                        <div className="line-item-label__field-value preserve-whitespace">{quickbooks_desktop_item !== undefined ? quickbooks_desktop_item.label : "(Click to select an item)"}</div>
                        {item.errors && item.errors.quickbooks_desktop_item_id && <div className="data-panel__form__field__errors" aria-label="Field Errors">{item.errors.quickbooks_desktop_item_id}</div>}
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className="line-item-label__row">
                    <div className="line-item-label__field">
                        <div className="line-item-label__field-label">QuickBooks PriceBook Item</div>
                        <div className="line-item-label__field-value preserve-whitespace">(Click to select an item)</div>
                        {item.errors && item.errors.quickbooks_desktop_item_id && <div className="data-panel__form__field__errors" aria-label="Field Errors">{item.errors.quickbooks_desktop_item_id}</div>}
                    </div>
                </div>
            )
        }
    }

    render = () => {
        const {
            fieldName,
            onSelect,
            parent,
            items,
            formatCurrencyValue,
            showQuickBooksItemSelect=false,
            useTaxes=false,
            showSummaryHint=false,
            isEstimateLineItem=false,
            isJobLineItem=false,
            isInvoiceLineItem=false,
            isPurchaseOrderLineItem=false,
        } = this.props

        return (
            <div id={`id_${fieldName}`} className="data-panel__list-select">
                {items.map((item, index) => {
                    const { formattedDates, formattedLabel } = formatServiceDates(item.service_start_date, item.service_end_date, "UTC")
                    const { quantityUnit, priceUnit, priceLabel } = getLineItemUnits(item)

                    return (
                        <WrapperButton key={index} type="object_link" handler={event => onSelect(item)} title={item.__str__ || "New Line Item"} ariaLabel="Update Line Item">
                            <div className="data-panel__list-select__option__label line-item-label">
                                <div className="line-item-label__row">
                                    <div className="line-item-label__field">
                                        <div className="line-item-label__field-label">Short Description</div>
                                        <div className="line-item-label__field-value">{item.description}</div>
                                        {item.errors && item.errors.description && <div className="data-panel__form__field__errors" aria-label="Field Errors">{item.errors.description}</div>}
                                    </div>
                                </div>
                                {
                                    !isEstimateLineItem && item.line_item_type === PriceBookItemTypes.service && (isJobLineItem ? !parent.is_job_walk : true) && (
                                        <div className="line-item-label__row">
                                            <div className="line-item-label__field">
                                                <div className="line-item-label__field-label">{formattedLabel}</div>
                                                <div className="line-item-label__field-value">{formattedDates}</div>
                                                {item.errors && item.errors.service_start_date && <div className="data-panel__form__field__errors" aria-label="Field Errors">{item.errors.service_start_date}</div>}
                                                {item.errors && item.errors.service_end_date && <div className="data-panel__form__field__errors" aria-label="Field Errors">{item.errors.service_end_date}</div>}
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    item.line_item_type === PriceBookItemTypes.part && (isJobLineItem || isInvoiceLineItem) && (item.inventory_location || (item.errors && item.errors.inventory_location)) && (
                                        <div className="line-item-label__row">
                                            <div className="line-item-label__field">
                                                <div className="line-item-label__field-label">Inventory Location</div>
                                                <div className="line-item-label__field-value">{item.inventory_location ? item.inventory_location_label : "(Click to select a location)"}</div>
                                                {item.errors && item.errors.inventory_location && <div className="data-panel__form__field__errors" aria-label="Field Errors">{item.errors.inventory_location}</div>}
                                            </div>
                                        </div>
                                    )
                                }
                                { !isPurchaseOrderLineItem && window.MARKETPLACE_ENTITY_SLUG === parent.service_company.slug && item.line_item_type === PriceBookItemTypes.part && <PriceBookPartDataDisplay lineItem={item} /> }
                                <div className="line-item-label__row">
                                    {
                                        !window.CURRENT_USER.hide_financial_context && item.line_item_type === PriceBookItemTypes.part && (
                                            <div className="line-item-label__field">
                                                <div className="line-item-label__field-label">Unit Cost</div>
                                                <div className="line-item-label__field-value">{valueIsDefined(item.cost) ? `${formatCurrencyValue(item.cost)}` : "--"}</div>
                                                {item.errors && item.errors.cost && <div className="data-panel__form__field__errors" aria-label="Field Errors">{item.errors.cost}</div>}
                                            </div>
                                        )
                                    }
                                    <div className="line-item-label__field">
                                        <div className="line-item-label__field-label">Qty.</div>
                                        <div className="line-item-label__field-value">{valueIsDefined(item.quantity) ? `${parseFloat(item.quantity)}${quantityUnit}` : "--"}</div>
                                        {item.errors && item.errors.quantity && <div className="data-panel__form__field__errors" aria-label="Field Errors">{item.errors.quantity}</div>}
                                    </div>
                                    {
                                        isPurchaseOrderLineItem
                                        ?
                                            !window.CURRENT_USER.hide_financial_context && (
                                                <Fragment>
                                                    <div className="line-item-label__field">
                                                        <div className="line-item-label__field-label">Expected Vendor Cost</div>
                                                        <div className="line-item-label__field-value">{valueIsDefined(item.expected_cost) ? formatCurrencyValue(item.expected_cost) : "--"}</div>
                                                        {item.errors && item.errors.expected_cost && <div className="data-panel__form__field__errors" aria-label="Field Errors">{item.errors.expected_cost}</div>}
                                                    </div>
                                                    <div className="line-item-label__field">
                                                        <div className="line-item-label__field-label">Amount</div>
                                                        <div className="line-item-label__field-value">{valueIsDefined(item.quantity) && valueIsDefined(item.expected_cost) ? formatCurrencyValue(parseFloat(item.quantity) * parseFloat(item.expected_cost)) : "--"}</div>
                                                    </div>
                                                </Fragment>
                                            )
                                        :
                                            !window.CURRENT_USER.hide_financial_context && (
                                                <Fragment>
                                                    <div className="line-item-label__field">
                                                        <div className="line-item-label__field-label">{priceLabel}</div>
                                                        <div className="line-item-label__field-value">{valueIsDefined(item.price) ? `${formatCurrencyValue(item.price)}${priceUnit}` : "--"}</div>
                                                        {item.errors && item.errors.price && <div className="data-panel__form__field__errors" aria-label="Field Errors">{item.errors.price}</div>}
                                                    </div>
                                                    <div className="line-item-label__field">
                                                        <div className="line-item-label__field-label">Amount</div>
                                                        <div className="line-item-label__field-value">{valueIsDefined(item.quantity) && valueIsDefined(item.price) ? formatCurrencyValue(parseFloat(item.quantity) * parseFloat(item.price)) : "--"}</div>
                                                    </div>
                                                </Fragment>
                                            )
                                    }
                                </div>
                                {
                                    isPurchaseOrderLineItem && (
                                        <div className="line-item-label__row">
                                            <div className="line-item-label__field">
                                                <div className="line-item-label__field-label">Vendor Part Number</div>
                                                <div className="line-item-label__field-value">{item.part_number}</div>
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    useTaxes && item.line_item_type !== PriceBookItemTypes.discount && (
                                        <div className="line-item-label__row">
                                            <div className="line-item-label__field">
                                                <div className="line-item-label__field-label">Tax</div>
                                                <div className="line-item-label__field-value">{<span className="data-tag" style={{margin: "0", width: "auto"}}>{item.is_taxable ? "Taxable" : "Non-Taxable"}</span>}</div>
                                            </div>
                                        </div>
                                    )
                                }
                                {this.renderSummary(item)}
                                {showQuickBooksItemSelect && this.renderQuickBooksItem(item)}
                                {
                                    isPurchaseOrderLineItem && valueIsDefined(item.jobs) && item.jobs.length !== 0 && (
                                        <div className="line-item-label__row">
                                            <div className="line-item-label__field">
                                                <div className="line-item-label__field-label">Associated Jobs</div>
                                                <div className="line-item-label__field-value">{item.jobs_label}</div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                            <div className="data-panel__list-select__option__icon"><i className="fa-sharp fa-angle-right" aria-hidden="true"></i></div>
                        </WrapperButton>
                    )
                })}
            </div>
        )
    }
}

export default LineItemListSelect;
