import clsx from "clsx"

import { Icon } from ".."
import styles from "./ListInfoGlyph.module.scss"
import { ListInfoGlyphProps } from "./ListInfoGlyph.types"

export default function TableInfoGlyph(props: ListInfoGlyphProps) {
    const { variant, icon } = props

    return (
        <div className={clsx(styles.base, styles[variant])}>
            <Icon icon={icon} size={24} />
        </div>
    )
}
