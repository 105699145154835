// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HoverCard-hoverCardContent__aReIA{width:var(--size-80);min-height:var(--size-24);max-height:calc(var(--radix-hover-card-content-available-height) - var(--size-4));padding:var(--size-4);overflow:auto;background-color:var(--white);border-radius:var(--border-radius-1_5);outline:var(--size-0_25) solid var(--gray-6);outline-offset:calc(var(--size-0_25)*-1);box-shadow:var(--box-shadow-xl-down);animation-duration:400ms;animation-timing-function:cubic-bezier(0.16, 1, 0.3, 1);will-change:transform,opacity}.HoverCard-hoverCardContent__aReIA[data-side=top]{animation-name:HoverCard-slideDownAndFade__pLofX}.HoverCard-hoverCardContent__aReIA[data-side=right]{animation-name:HoverCard-slideLeftAndFade__DWFam}.HoverCard-hoverCardContent__aReIA[data-side=bottom]{animation-name:HoverCard-slideUpAndFade__Fhp8c}.HoverCard-hoverCardContent__aReIA[data-side=left]{animation-name:HoverCard-slideRightAndFade__rjk9j}@keyframes HoverCard-slideUpAndFade__Fhp8c{0%{transform:translateY(2px);opacity:0}100%{transform:translateY(0);opacity:1}}@keyframes HoverCard-slideRightAndFade__rjk9j{0%{transform:translateX(-2px);opacity:0}100%{transform:translateX(0);opacity:1}}@keyframes HoverCard-slideDownAndFade__pLofX{0%{transform:translateY(-2px);opacity:0}100%{transform:translateY(0);opacity:1}}@keyframes HoverCard-slideLeftAndFade__DWFam{0%{transform:translateX(2px);opacity:0}100%{transform:translateX(0);opacity:1}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hoverCardContent": "HoverCard-hoverCardContent__aReIA",
	"slideDownAndFade": "HoverCard-slideDownAndFade__pLofX",
	"slideLeftAndFade": "HoverCard-slideLeftAndFade__DWFam",
	"slideUpAndFade": "HoverCard-slideUpAndFade__Fhp8c",
	"slideRightAndFade": "HoverCard-slideRightAndFade__rjk9j"
};
export default ___CSS_LOADER_EXPORT___;
