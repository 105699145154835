// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TableLoaderRow-base__CtUxR{display:flex;flex-direction:row;align-items:center;justify-content:center;padding:var(--size-4);background:var(--gray-2)}.TableLoaderRow-body__oDow5{display:flex;flex-direction:column}.TableLoaderRow-icon__HQeyU{margin-right:1.125em}.TableLoaderRow-contentContainer__xSTaN{display:flex;flex-direction:row;align-items:center;margin-right:.25em}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "TableLoaderRow-base__CtUxR",
	"body": "TableLoaderRow-body__oDow5",
	"icon": "TableLoaderRow-icon__HQeyU",
	"contentContainer": "TableLoaderRow-contentContainer__xSTaN"
};
export default ___CSS_LOADER_EXPORT___;
