// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TruncatedTextCell-base__QFuzo{display:flex;align-items:center;width:var(--size-full);padding:var(--size-3_5) var(--size-4);font:var(--font-global-body-400);font-feature-settings:var(--feature-settings-global-body-400);letter-spacing:var(--letter-spacing-global-body-400)}.TruncatedTextCell-primary__eQP8o{color:var(--gray-12)}.TruncatedTextCell-secondary__Q_FUp{color:var(--gray-11)}.TruncatedTextCell-success__LOqVj{color:var(--jade-10)}.TruncatedTextCell-warning__iiTo7{color:var(--orange-10)}.TruncatedTextCell-dangerous__C1Snw{color:var(--tomato-10)}.TruncatedTextCell-caution__cuKGW{color:var(--amber-10)}.TruncatedTextCell-content__Y3nYJ{overflow:hidden;white-space:nowrap;text-overflow:ellipsis}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "TruncatedTextCell-base__QFuzo",
	"primary": "TruncatedTextCell-primary__eQP8o",
	"secondary": "TruncatedTextCell-secondary__Q_FUp",
	"success": "TruncatedTextCell-success__LOqVj",
	"warning": "TruncatedTextCell-warning__iiTo7",
	"dangerous": "TruncatedTextCell-dangerous__C1Snw",
	"caution": "TruncatedTextCell-caution__cuKGW",
	"content": "TruncatedTextCell-content__Y3nYJ"
};
export default ___CSS_LOADER_EXPORT___;
