import { icon as fontAwesomeIcon } from "@fortawesome/fontawesome-svg-core/import.macro"
import clsx from "clsx"

import useIsDesktop from "@hooks/useIsDesktop"

import cardStyles from "@atoms/Card/Card.module.scss"
import { Icon, NavigationLink } from "@atoms/index"

import { CounterBadge } from ".."
import styles from "./NotificationTopicCard.module.scss"
import { NotificationTopicCardProps } from "./NotificationTopicCard.types"

export default function NotificationTopicCard(props: NotificationTopicCardProps) {
    const { icon, caption, path, title, notificationsInUse, totalOfNotifications } = props

    const isDesktop = useIsDesktop()

    return (
        <NavigationLink path={path} isTabbable={true} className={clsx(styles.base, cardStyles.base)} title={title}>
            {() => (
                <>
                    <div className={styles.iconAndText}>
                        <Icon size={14} icon={icon} />
                        <div className={styles.text}>
                            <span className={styles.title}>{title}</span>
                            {caption && <span className={styles.caption}>{caption}</span>}
                        </div>
                    </div>
                    <CounterBadge
                        current={notificationsInUse}
                        total={totalOfNotifications}
                        size={isDesktop ? "md" : "md"}
                    />
                    <Icon
                        size={12}
                        icon={fontAwesomeIcon({
                            name: "angle-right",
                            family: "sharp",
                            style: "regular",
                        })}
                        className={styles.chevron}
                    />
                </>
            )}
        </NavigationLink>
    )
}
