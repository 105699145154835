
export default function ContactSaveCheckBox(props) {
    const { isChecked, handleOnChange } = props

  return (
    <div className="data-panel__form__field" id="div_id_ephemeral_contact" aria-label="Ephemeral Contact">
        <label htmlFor="id_client" className="data-panel__form__field__label in-form">Save Contact</label>
        <div className="data-panel__form__field__input data-panel__form__field__input--with-checkbox">
            <input type="checkbox" className="checkbox-input" name="ephemeral_contact" id="id_ephemeral_contact" checked={isChecked} onChange={handleOnChange} />
            <label className="checkbox-label" htmlFor="id_ephemeral_contact">Save to Contact List</label>
        </div>
    </div>
  )
}
