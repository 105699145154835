import { DateHelper } from "@bryntum/schedulerpro"
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import { JobStatuses } from "@legacy/core/utils/enums"
import clsx from "clsx"
import { useMemo } from "react"

import { Icon } from "@atoms"

import useJobTimelineViewStates from "@organisms/ObjectsView/JobTimelineView/hooks/useJobTimelineViewStates"

import styles from "./TimelineViewEventCardIcon.module.scss"
import { TimelineViewEventCardIconProps } from "./TimelineViewEventCardIcon.types"

export default function TimelineViewEventCardIcon(props: TimelineViewEventCardIconProps) {
    const { startTime, status, statusColorOverride } = props

    const { todayInUserTimezone } = useJobTimelineViewStates()

    const isPastDue = DateHelper.isBefore(startTime, new Date(todayInUserTimezone))

    const renderIcon = useMemo(() => {
        if (JobStatuses.on_hold === status) {
            return icon({
                name: "circle-stop",
                style: "solid",
                family: "sharp",
            })
        } else if (JobStatuses.completed === status) {
            return icon({
                name: "check-circle",
                style: "solid",
                family: "sharp",
            })
        } else if (JobStatuses.finalized === status) {
            return icon({
                name: "check-circle",
                style: "solid",
                family: "sharp",
            })
        } else if (JobStatuses.cancelled === status) {
            return icon({
                name: "circle-xmark",
                style: "solid",
                family: "sharp",
            })
        } else if (JobStatuses.scheduled === status) {
            return icon({
                name: "circle-calendar",
                style: "solid",
                family: "sharp",
            })
        } else if (JobStatuses.active === status) {
            return icon({
                name: "circle-play",
                style: "solid",
                family: "sharp",
            })
        } else if (JobStatuses.paused === status) {
            return icon({
                name: "circle-pause",
                style: "solid",
                family: "sharp",
            })
        } else if (JobStatuses.pending === status && !startTime) {
            return icon({
                name: "circle-dashed",
                style: "solid",
                family: "sharp",
            })
        } else {
            return null
        }
    }, [status])

    return (
        <div
            className={clsx(styles.base, {
                [styles.pastDue]: status === JobStatuses.scheduled && isPastDue,
                [styles.scheduled]: status === JobStatuses.scheduled && !isPastDue,
                [styles.active]: status === JobStatuses.active,
                [styles.paused]: status === JobStatuses.paused,
                [styles.onHold]: status === JobStatuses.on_hold,
                [styles.onHoldInternal]: statusColorOverride === "jobs-on-hold-internal",
                [styles.onHoldExternal]: statusColorOverride === "jobs-on-hold-external",
                [styles.completed]: status === JobStatuses.completed,
                [styles.finalized]: status === JobStatuses.finalized,
                [styles.cancelled]: status === JobStatuses.cancelled,
                [styles.pending]: status === JobStatuses.pending && !startTime,
            })}
        >
            <Icon icon={renderIcon} size={16} />
        </div>
    )
}
