import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"

import useUser from "@hooks/useUser"

import { Icon, Tooltip } from "@atoms/index"

import { useNotificationMethodsQuery } from "@pages/Settings/SettingsNotifications/components/NotificationMethodsSection/NotificationMethodsSection.store"

import { SMSNotificationTooltipProps } from "./SMSNotificationTooltip.types"

export default function SMSNotificationTooltip(props: SMSNotificationTooltipProps) {
    const { children } = props

    const { user } = useUser()

    const { data: defaultMethodsPreference } = useNotificationMethodsQuery()

    const hasVerifiedPhone = user?.phone && user?.phone_verified

    const tooltipIcon = hasVerifiedPhone
        ? icon({
              name: "ban",
              style: "solid",
              family: "sharp",
          })
        : icon({
              name: "message-slash",
              style: "solid",
              family: "sharp",
          })

    const tooltipTitle = hasVerifiedPhone ? "Notification method is off" : "No verified phone number"
    const tooltipMessage = hasVerifiedPhone
        ? "This can't be edited because the related notification method is off. Turn the method on to manage these topics."
        : "Verify your phone number in your Profile settings to turn on text message notifications."

    return (
        <Tooltip
            type="message"
            icon={<Icon icon={tooltipIcon} />}
            title={tooltipTitle}
            message={tooltipMessage}
            isEnabled={!hasVerifiedPhone || !defaultMethodsPreference?.contact_sms}
            side="right"
            sideOffset={4}
        >
            <div>{children}</div>
        </Tooltip>
    )
}
