import { Property } from "@molecules/index"

import { Breadcrumb, PropertyStack } from "@organisms/index"

import { Settings } from "@templates/index"

import { SETTINGS_ROUTES } from "@routes/settings"

import AppAppearanceSelector from "./components/AppAppearanceSelector/AppAppearanceSelector"
import AppLanguageSelector from "./components/AppLanguageSelector/AppLanguageSelector"
import AppStartOfWeekSelector from "./components/AppStartOfWeekSelector/AppStartOfWeekSelector"
import AppTimezoneSelector from "./components/AppTimezoneSelector/AppTimezoneSelector"
import UseCompanyTimezoneToggler from "./components/UseCompanyTimezoneToggler/UseCompanyTimezoneToggler"

export default function SettingsPreferences() {
    return (
        <Settings.Wrapper title="Preferences">
            <Settings.PageContainer>
                <Settings.Breadcrumbs>
                    <Breadcrumb.Item label="My account" showSlash={true} />
                    <Breadcrumb.Item label={"Preferences"} path={SETTINGS_ROUTES.preferences} />
                </Settings.Breadcrumbs>
                <Settings.PageHeader
                    variant="page-heading"
                    title="Preferences"
                    subtitle="Manage your application preferences."
                    infoMessage="These preferences only apply to you."
                />

                <Settings.Divider />

                <Settings.Section gap={24}>
                    <Settings.PageHeader
                        variant="section-heading"
                        title="Appearance"
                        subtitle="Choose your desired Roopairs interface."
                    />
                    <AppAppearanceSelector />
                </Settings.Section>

                <Settings.Divider />

                <Settings.Section>
                    <Settings.PageHeader
                        variant="section-heading"
                        title="Localization"
                        subtitle="Manage your date, time, and language settings."
                    />
                    <PropertyStack>
                        <Property.Root>
                            <Property.Content>
                                <Property.Label>Language</Property.Label>
                                <Property.Subtitle>
                                    Select the language used in the app and in notifications.
                                </Property.Subtitle>
                            </Property.Content>
                            <Property.Value>
                                <AppLanguageSelector />
                            </Property.Value>
                        </Property.Root>

                        <Property.Root>
                            <Property.Content>
                                <Property.Label>Use company time zone</Property.Label>
                                <Property.Subtitle>
                                    All dates and times displayed in the app and in notifications are based on your
                                    company&apos;s time zone.
                                </Property.Subtitle>
                            </Property.Content>
                            <Property.Value>
                                <UseCompanyTimezoneToggler />
                            </Property.Value>
                        </Property.Root>

                        <Property.Root>
                            <Property.Content>
                                <Property.Label>Time zone</Property.Label>
                                <Property.Subtitle>Current time zone setting.</Property.Subtitle>
                            </Property.Content>
                            <Property.Value>
                                <AppTimezoneSelector />
                            </Property.Value>
                        </Property.Root>

                        <Property.Root>
                            <Property.Content>
                                <Property.Label>Start of week</Property.Label>
                                <Property.Subtitle>
                                    Choose on which day the calendar week should start.
                                </Property.Subtitle>
                            </Property.Content>
                            <Property.Value>
                                <AppStartOfWeekSelector />
                            </Property.Value>
                        </Property.Root>
                    </PropertyStack>
                </Settings.Section>
            </Settings.PageContainer>
        </Settings.Wrapper>
    )
}
