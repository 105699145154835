import React from 'react'

function EmailDisplay(props) {
    const {email, hyperlink=true} = props
    let formattedEmail = email

    if (hyperlink) {
        formattedEmail = <span><a href={`mailto:${email}`}>{formattedEmail}</a></span>
    }

  return formattedEmail
}

export default EmailDisplay
