import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import clsx from "clsx"

import { CustomIcon, Icon, IconButton } from "@atoms"
import { CustomIconName } from "@atoms/CustomIcon/CustomIcon.types"

import { BottomSheet } from "@organisms"

import styles from "./MobileHeader.module.scss"
import { MobileHeaderProps } from "./MobileHeader.types"

export default function MobileHeader(props: MobileHeaderProps) {
    const {
        title,
        titleIcon,
        showBackButton,
        onBackClick,
        className,
        backButtonClassName = "",
        titleClassName,
        showDivider = true,
        showRightButton = false,
        rightButtonIcon = null,
        rightButtonIconWhenPressed = null,
        onRightButtonClick = null,
        rightButtonAriaLabel = "",
        isRightButtonPressed = false,
        counter = null,
        isTabbable = false,
        showDragIndicator = false,
    } = props

    const isUsingCustomTitleIcon = typeof titleIcon === "string"

    return (
        <>
            <div
                className={clsx(styles.base, className, {
                    [styles.withDivider]: showDivider,
                })}
            >
                {showDragIndicator && <BottomSheet.DragIndicator />}
                <div className={styles.innerContainer}>
                    {showBackButton ? (
                        <IconButton
                            colorScheme="gray"
                            size="sm"
                            variant="ghost"
                            className={clsx(styles.iconButton, backButtonClassName)}
                            isTabbable={isTabbable}
                            icon={icon({ name: "angle-left", style: "regular", family: "sharp" })}
                            onClick={onBackClick}
                            aria-label="Back to previous items"
                            noDebounce={true}
                        />
                    ) : (
                        <span className={styles.saveButtonSpace} />
                    )}
                    <div className={clsx(styles.headingAndCounter, titleClassName)}>
                        <div className={styles.iconAndTitle}>
                            {titleIcon &&
                                (isUsingCustomTitleIcon ? (
                                    <CustomIcon icon={titleIcon as CustomIconName} size={14} />
                                ) : (
                                    <Icon icon={titleIcon as IconProp} size={14} />
                                ))}
                            {title}
                        </div>

                        {counter && <div className={styles.counter}>{counter}</div>}
                    </div>
                    {showRightButton ? (
                        <IconButton
                            colorScheme="gray"
                            size="sm"
                            variant="ghost"
                            className={clsx(styles.iconButton)}
                            isTabbable={isTabbable}
                            isPressed={isRightButtonPressed}
                            icon={rightButtonIcon}
                            iconOnActive={rightButtonIconWhenPressed}
                            iconIsActive={isRightButtonPressed}
                            onClick={onRightButtonClick}
                            aria-label={rightButtonAriaLabel}
                            noDebounce={true}
                        />
                    ) : (
                        <span className={styles.saveButtonSpace} />
                    )}
                </div>
            </div>
        </>
    )
}
