import clsx from "clsx"
import { useEffect, useRef } from "react"

import usePopover from "@molecules/Popover/Popover.store"

import styles from "./PopoverContent.module.scss"
import { PopoverContentProps } from "./PopoverContent.types"

export default function PopoverContent(props: PopoverContentProps) {
    const { priority, children, isVisible } = props

    const ref = useRef<HTMLDivElement>(null)

    const { setContentHeight } = usePopover()

    useEffect(() => {
        const onResize = () => {
            setContentHeight(ref?.current?.scrollHeight)
        }

        const resizeObserver = new ResizeObserver(onResize)

        if (ref.current && isVisible) {
            resizeObserver.observe(ref.current)
        } else {
            resizeObserver.unobserve(ref.current)
        }

        return () => {
            if (ref.current) {
                resizeObserver.unobserve(ref.current)
            }
        }
    }, [ref.current, isVisible])

    return (
        <div
            ref={ref}
            className={clsx(styles[priority], {
                [styles.visible]: isVisible,
            })}
        >
            {/* Dynamically renders the children passing down a boolean that indicates if the link should be active. */}
            {children(isVisible)}
        </div>
    )
}
