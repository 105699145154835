import Spinner from "@legacy/core/components/Spinner";
import { Component, Fragment } from "react";
import ButtonGroup from "../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../core/buttons/UniversalButton";
import Banner from "../../core/components/Banner";
import BasicDisplayField from "../../core/fields/BasicDisplayField";
import TextField from "../../core/fields/TextField";
import { formatCurrencyForServiceCompany } from "../../core/utils/utils";
import { calculateInvoiceAmounts } from "../utils/utils";


class InvoiceForgiveForm extends Component {

    componentDidMount() {
        // Return to the previous scroll
        document.querySelector(".main").scrollTo(0, this.props.returnScroll || 0)
    }

    renderButtons = () => {
        const {invoice, forgiveData, requestAction, switchToPrimaryForm, submitting, errors, onFormDataChange, inventoryUsed, returnScroll} = this.props

        if (errors.unexpectedError) {
            return (
                <div className="data-panel__action-feedback">
                    <span className="text-invalid"><strong>An unexpected error occurred.</strong></span>
                </div>
            )
        }
        else {
            if (submitting) {
                return <Spinner centered={true} />
            }
            else {
                return (
                    <ButtonGroup>
                        <ButtonGroupRow>
                            <UniversalButton type="danger" text="Forgive" handler={event => requestAction("INVOICE_FORGIVE_SUBMIT")} />
                            <UniversalButton type="secondary" text="Cancel" handler={event => switchToPrimaryForm()} />
                        </ButtonGroupRow>
                    </ButtonGroup>
                )
            }
        }
    }

    render() {
        const {invoice, forgiveData, requestAction, switchToPrimaryForm, submitting, errors, onFormDataChange, inventoryUsed, returnScroll} = this.props
        const {subtotal, tax, total, amountDue} = calculateInvoiceAmounts(invoice)

        return (
            <div className="data-panel-container data-panel-container--with-margin">
                <div className="data-panel" aria-label="Invoice Forgive">
                    <div className="data-panel__form" aria-label="Invoice Forgive Form">
                        <p className="data-panel__form__caption">Please confirm that you'd like to forgive this invoice.</p>
                        <BasicDisplayField
                            fieldName="id"
                            fieldLabel="Roopairs Invoice ID"
                            fieldValue={invoice.custom_id ? invoice.custom_id : invoice.id}
                        ></BasicDisplayField>
                        <BasicDisplayField
                            fieldName="service_name"
                            fieldLabel="Service Type"
                            fieldValue={invoice.service_name}
                        ></BasicDisplayField>
                        {
                            parseInt(invoice.amount_due) !== 0 && (
                                <Fragment>
                                    <BasicDisplayField
                                        fieldName="amount_due"
                                        fieldLabel="Amount Due"
                                        fieldValue={formatCurrencyForServiceCompany(amountDue, invoice.service_company)}
                                    ></BasicDisplayField>
                                </Fragment>
                            )
                        }
                        <TextField
                            fieldName="forgive_message"
                            fieldLabel="Forgive Reason"
                            fieldValue={forgiveData.forgive_message || ""}
                            fieldOnChange={forgive_message => onFormDataChange("forgive_message", forgive_message || "")}
                            rows={3}
                            placeholder="Why are you forgiving this invoice?"
                            errors={errors}
                        ></TextField>
                        <TextField
                            fieldName="forgive_memo"
                            fieldLabel="Memo"
                            fieldValue={forgiveData.forgive_memo || ""}
                            fieldOnChange={forgive_memo => onFormDataChange("forgive_memo", forgive_memo || "")}
                            rows={3}
                            placeholder="Memo to add to the forgiven balance record (e.g. 'Uncollectible after multple attempts' or 'Forgiving due to harship)'"
                            optional={true}
                            errors={errors}
                        ></TextField>
                        {
                            inventoryUsed && (
                                <Banner type="warning" text="Parts tracked in inventory were used on this invoice. Forgiving this invoice will not increase inventory parts' quantity on hand." />
                            )
                        }
                    </div>
                    {this.renderButtons()}
                </div>
            </div>
        )
    }
}

export default InvoiceForgiveForm;
