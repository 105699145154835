// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StackedNavigationItems-base__p7B_D{display:flex;flex-direction:column;gap:var(--size-0_5)}.StackedNavigationItems-items__ZFrYk{display:flex;flex-direction:column;gap:var(--size-0_5);overflow:hidden;transition:var(--duration-normal);transition-property:max-height}.StackedNavigationItems-items__ZFrYk *{pointer-events:none}.StackedNavigationItems-open__O5zq0 .StackedNavigationItems-items__ZFrYk *{pointer-events:all}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "StackedNavigationItems-base__p7B_D",
	"items": "StackedNavigationItems-items__ZFrYk",
	"open": "StackedNavigationItems-open__O5zq0"
};
export default ___CSS_LOADER_EXPORT___;
