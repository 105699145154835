import React, { Component, Fragment } from 'react';
import { sortContact } from '../utils/sorters';
import ContactDisplay from './ContactDisplay';


class ContactListDisplay extends Component {

    setPrimary = (contact) => {
        contact.primary = contact.id === this.props.primaryContactId
        return contact
    }

    render() {
        const { fieldName, fieldLabel, contacts, primaryContactId, header } = this.props

        return (
            <div className="data-panel__form__field" id={`div_id_${fieldName}`} aria-label={fieldLabel}>
                <span className="data-panel__form__field__label" aria-hidden="true">{fieldLabel}</span>
                <div className="data-panel__form__field__display-list" aria-label={`${fieldLabel} List`}>
                    {
                        contacts.length === 0
                        ?
                        "(No contacts)"
                        :
                        <Fragment>
                            {header}
                            {contacts.map(this.setPrimary).sort(sortContact).map((contact, index) => <ContactDisplay key={`${fieldName}_${index}`} contact={contact} />)}
                        </Fragment>
                    }
                </div>
            </div>
        )
    }
}

export default ContactListDisplay;
