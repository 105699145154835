import useUser from "@hooks/useUser";
import { useQuery } from '@tanstack/react-query';
import React from "react";
import Meter from "../../core/components/Meter";
import { formatCurrencyForServiceCompany } from "../../core/utils/utils";
import { DashboardCardRowStates } from "./DashboardCard";

async function fetchRevenuePotential(currentUser, filterValue) {
    const endpoint = DjangoUrls["dashboard:api-revenue-potential-detail"](currentUser.service_company.slug, (filterValue || "all"))

    const response = await fetch(endpoint)
    if (!response.ok) {
        throw new Error("There was an error while trying to load revenue potential.")
    }
    return await response.json()
}


function RevenuePotentialCard(props) {
    const { filterSelection, setFilterOptions, setCurrentPageData } = props

    const { user:currentUser } = useUser()

    const filterValue = filterSelection?.value || ""

    const { data, error, isLoading, isError } = useQuery({
        queryKey: ["revenue-potential", filterValue],
        queryFn: () => fetchRevenuePotential(currentUser, filterValue),
        staleTime: 60000
    })

    React.useEffect(() => {
        setFilterOptions([
            {"label": "All", "value": "all"},
            {"label": "Today", "value": "day"},
            {"label": "This Week", "value": "week"},
            {"label": "This Month", "value": "month"},
            {"label": "This Quarter", "value": "quarter"},
            {"label": "This Year", "value": "year"},
        ])
    }, [])

    // Send the data to the parent to render pagination
    React.useEffect(() => {
        setCurrentPageData(data)
    }, [data])

    return <DashboardCardRowStates
        isLoading={isLoading}
        isError={isError}
        errorMessage={error?.message}
        isEmpty={data === null}
        emptyIcon="fa-signal-bars-slash"
        emptyMessage="There is no data to display."
    >
        <div className="card__data__row revenue-potential" aria-label="Revenue Potential">
            <div className="card__data__row__content-container">
                <div className="card__data__row__body" aria-label="Revenue Potential Details">
                    <div className="revenue-potential__meter" aria-hidden="true">
                        <Meter percentage={data?.collected_percentage || 0} />
                    </div>
                    <div className="revenue-potential__details">
                        <div className="revenue-potential__detail" aria-label="Revenue Potential Collected">
                            <span className="revenue-potential__detail__value">{formatCurrencyForServiceCompany(data?.collected, currentUser.service_company)}</span>
                            <span className="revenue-potential__detail__percentage">{data?.collected_percentage}% Collected</span>
                        </div>
                        <div className="revenue-potential__detail align-right" aria-label="Revenue Potential Collected">
                            <span className="revenue-potential__detail__value">{formatCurrencyForServiceCompany(data?.uncollected, currentUser.service_company)}</span>
                            <span className="revenue-potential__detail__percentage">{data?.uncollected_percentage}% Uncollected</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </DashboardCardRowStates>
}


export default RevenuePotentialCard
