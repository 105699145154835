import { Label } from "@atoms"
import { LabelProps } from "@atoms/Label/Label.types"

import styles from "./PropertyLabel.module.scss"

export default function PropertyLabel(props: Omit<LabelProps, "size">) {
    const { ...rest } = props

    return <Label className={styles.base} variant="focused" size="lg" {...rest} />
}
