// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LinkCell-base__ucMUw{display:flex;gap:var(--size-2);align-items:center;width:var(--size-full);padding:var(--size-2_5) var(--size-4);color:var(--gray-12);border-bottom:1px solid var(--border-primary);font:var(--font-global-buttons-and-links-medium-375);font-feature-settings:var(--feature-settings-global-buttons-and-links-medium-375);letter-spacing:var(--letter-spacing-global-buttons-and-links-medium-375)}@media only screen and (min-width: 64rem){.LinkCell-base__ucMUw .LinkCell-iconButton__roRjz .LinkCell-icon__FTpx0{display:none}.LinkCell-base__ucMUw .LinkCell-iconButton__roRjz:hover .LinkCell-icon__FTpx0,.LinkCell-base__ucMUw .LinkCell-iconButton__roRjz:focus-visible .LinkCell-icon__FTpx0,.LinkCell-base__ucMUw .LinkCell-iconButton__roRjz:active .LinkCell-icon__FTpx0{display:flex}}.LinkCell-content__OymEO{width:var(--size-full);overflow:hidden;white-space:nowrap;text-overflow:ellipsis;text-decoration-line:underline}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "LinkCell-base__ucMUw",
	"iconButton": "LinkCell-iconButton__roRjz",
	"icon": "LinkCell-icon__FTpx0",
	"content": "LinkCell-content__OymEO"
};
export default ___CSS_LOADER_EXPORT___;
