import Spinner from "@legacy/core/components/Spinner";
import { Component } from "react";
import ButtonGroup from "../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../core/buttons/UniversalButton";
import BasicDisplayField from "../../core/fields/BasicDisplayField";
import CharField from "../../core/fields/CharField";
import TextField from "../../core/fields/TextField";
import { formatCurrencyForServiceCompany } from "../../core/utils/utils";
import { calculatePurchaseOrderAmounts } from "../utils/utils";


class PurchaseOrderAcknowledgeForm extends Component {

    componentDidMount() {
        // Return to the previous scroll
        document.querySelector(".main").scrollTo(0, this.props.returnScroll || 0)
    }

    renderButtons = () => {
        const { purchaseOrder, acknowledgeData, requestAction, switchToPrimaryForm, submitting, errors, onFormDataChange, carrierAccountID, returnScroll } = this.props

        if (errors.unexpectedError) {
            return (
                <div className="data-panel__action-feedback">
                    <span className="text-invalid"><strong>An unexpected error occurred.</strong></span>
                </div>
            )
        }
        else {
            if (submitting) {
                return <Spinner centered={true} />
            }
            else {
                return (
                    <ButtonGroup>
                        <ButtonGroupRow>
                            <UniversalButton type="primary" text="Acknowledge" handler={event => requestAction("PURCHASE_ORDER_ACKNOWLEDGE_SUBMIT")} />
                            <UniversalButton type="secondary" text="Cancel" handler={event => switchToPrimaryForm()} />
                        </ButtonGroupRow>
                    </ButtonGroup>
                )
            }
        }
    }

    render() {
        const { purchaseOrder, acknowledgeData, requestAction, switchToPrimaryForm, submitting, errors, onFormDataChange, carrierAccountID, returnScroll } = this.props
        const { subtotal } = calculatePurchaseOrderAmounts(purchaseOrder)

        return (
            <div className="data-panel-container data-panel-container--with-margin">
                <div className="data-panel" aria-label="Purchase Order Acknowledge">
                    <div className="data-panel__form" aria-label="Purchase Order Acknowledge Form">
                        <p className="data-panel__form__caption">Please confirm your acknowledgment of this purchase order.</p>
                        <BasicDisplayField
                            fieldName="id"
                            fieldLabel="Roopairs Purchase Order ID"
                            fieldValue={purchaseOrder.custom_id ? purchaseOrder.custom_id : purchaseOrder.id}
                        ></BasicDisplayField>
                        <BasicDisplayField
                            fieldName="vendor"
                            fieldLabel="Vendor"
                            fieldValue={purchaseOrder.vendor.name}
                        ></BasicDisplayField>
                        <BasicDisplayField
                            fieldName="item_count"
                            fieldLabel="Item Count"
                            fieldValue={`${purchaseOrder.line_items.length} items`}
                        ></BasicDisplayField>
                        <BasicDisplayField
                            fieldName="subtotal"
                            fieldLabel="Expected Subtotal"
                            fieldValue={formatCurrencyForServiceCompany(subtotal, purchaseOrder.service_company)}
                        ></BasicDisplayField>
                        <BasicDisplayField
                            fieldName="shipping"
                            fieldLabel="Shipping Preference"
                            fieldValue={purchaseOrder.preferred_shipping || "No Preference"}
                        ></BasicDisplayField>
                        {
                            carrierAccountID && (
                                <BasicDisplayField
                                    fieldName="carrier_account_id"
                                    fieldLabel="Carrier Account ID"
                                    fieldValue={carrierAccountID}
                                ></BasicDisplayField>
                            )
                        }
                        <CharField
                            fieldName="vendor_internal_id"
                            fieldLabel="Vendor Purchase Order ID"
                            fieldValue={acknowledgeData.vendor_internal_id || ""}
                            fieldOnChange={vendor_internal_id => onFormDataChange("vendor_internal_id", vendor_internal_id || "")}
                            maxLength="30"
                            optional={true}
                            optionalText="if different"
                            errors={errors}
                        ></CharField>
                        <TextField
                            fieldName="acknowledge_message"
                            fieldLabel="Comments"
                            fieldValue={acknowledgeData.acknowledge_message || ""}
                            fieldOnChange={acknowledge_message => onFormDataChange("acknowledge_message", acknowledge_message || "")}
                            rows={3}
                            placeholder="Any comments you'd like to add. You'll be able to provide an ETA and shipment tracking details when you mark this PO as shipped."
                            optional={true}
                            errors={errors}
                        ></TextField>
                    </div>
                    {this.renderButtons()}
                </div>
            </div>
        )
    }
}

export default PurchaseOrderAcknowledgeForm;
