import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"

import useIsDesktop from "@hooks/useIsDesktop"
import useUser from "@hooks/useUser"

import { Badge, Icon, IconButton, Tooltip } from "@atoms"

import useJobTimelineViewStates from "@organisms/ObjectsView/JobTimelineView/hooks/useJobTimelineViewStates"

import styles from "./UnscheduledJobsHeader.module.scss"

export default function UnscheduledJobsHeader() {
    const isDesktop = useIsDesktop()
    const { user } = useUser()
    const {
        unscheduledJobsColumnStatus,
        totalOfUnscheduledJobs,
        unscheduledJobsColumnMessageStatus,
        collapseUnscheduledJobsColumn,
    } = useJobTimelineViewStates()

    if (unscheduledJobsColumnStatus === "collapsed" || !isDesktop || !user.isServiceDispatcher) {
        return null
    } else {
        return (
            <div className={styles.base}>
                <div className={styles.questionMarkAndTitle}>
                    {unscheduledJobsColumnMessageStatus !== "initial" && (
                        <Tooltip
                            type="message"
                            icon={
                                <Icon
                                    className={styles.questionMarkIcon}
                                    icon={icon({
                                        name: "circle-dashed",
                                        style: "solid",
                                        family: "sharp",
                                    })}
                                />
                            }
                            title="Unscheduled Jobs"
                            message="We currently only support scheduling Unscheduled jobs from the side panel. To manage Draft, Past Due, and On Hold jobs, switch to Table view."
                        >
                            <div className={styles.questionMark}>
                                <Icon
                                    icon={icon({
                                        name: "question-circle",
                                        style: "light",
                                        family: "sharp",
                                    })}
                                    iconOnMouseOver={icon({
                                        name: "question-circle",
                                        style: "solid",
                                        family: "sharp",
                                    })}
                                />
                            </div>
                        </Tooltip>
                    )}
                    <div className={styles.title}>Unscheduled Jobs</div>
                    <Badge
                        colorScheme={totalOfUnscheduledJobs === 0 ? "gray" : "tomato"}
                        size="md"
                        variant="subtle"
                        type="counter"
                    >
                        {totalOfUnscheduledJobs}
                    </Badge>
                </div>

                <div className={styles.button}>
                    <IconButton
                        colorScheme="gray"
                        size="sm"
                        variant="ghost"
                        icon={icon({
                            name: "arrow-right-to-line",
                            style: "light",
                            family: "sharp",
                        })}
                        onClick={collapseUnscheduledJobsColumn}
                        aria-label="Collapse Unscheduled Jobs"
                        noDebounce={true}
                    />
                </div>
            </div>
        )
    }
}
