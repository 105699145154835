import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import clsx from "clsx"
import { useState } from "react"

import useUser from "@hooks/useUser"

import replaceSlugs from "@utils/replaceSlugs"

import { Icon } from "@atoms"

import { BottomSheet } from "@organisms"

import { CLIENTS_ROUTES } from "@routes/clients"
import { ESTIMATES_ROUTES } from "@routes/estimates"
import { INVOICES_ROUTES } from "@routes/invoices"
import { JOBS_ROUTES } from "@routes/jobs"
import { PRICEBOOK_ITEMS_ROUTES } from "@routes/pricebook-items"
import { PURCHASE_ORDER_ROUTES } from "@routes/purchase-orders"
import { VENDOR_ROUTES } from "@routes/vendor"

import { APIDeckTaxStrategies, AccountingIntegrations } from "@constants/choices"

import styles from "./BottomBarCreateButton.module.scss"
import { BottomBarCreateButtonProps } from "./BottomBarCreateButton.types"

export default function BottomBarCreateButton(props: BottomBarCreateButtonProps) {
    const { isDisabled } = props

    const { user } = useUser()
    const slug = { service_company_slug: user?.service_company.slug }

    const isUsingTaxRates =
        user?.service_company.use_taxes &&
        !(
            user?.service_company.accounting_integration === AccountingIntegrations.apideck &&
            user?.service_company.apideck_tax_strategy !== APIDeckTaxStrategies.normal
        )

    const [isBottomSheetOpen, setIsBottomSheetOpen] = useState<boolean>(false)
    const [isSubItemsOpen, setIsSubItemsOpen] = useState<boolean>(false)

    const onCloseBottomSheet = () => {
        setIsBottomSheetOpen(false)
        setTimeout(() => {
            closeSubItems()
        }, 300)
    }

    const openBottomSheet = () => {
        setIsBottomSheetOpen(true)
    }

    const openSubItems = () => {
        setIsSubItemsOpen(true)
    }

    const closeSubItems = () => {
        setIsSubItemsOpen(false)
    }

    const routes = {
        estimate: replaceSlugs(ESTIMATES_ROUTES.CREATE, slug),
        jobs: replaceSlugs(JOBS_ROUTES.CREATE, slug),
        invoices: replaceSlugs(INVOICES_ROUTES.CREATE, slug),
        purchase_orders: replaceSlugs(PURCHASE_ORDER_ROUTES.CREATE, slug),
        vendors: replaceSlugs(VENDOR_ROUTES.CREATE, slug),
        clients: replaceSlugs(CLIENTS_ROUTES.CREATE, slug),
        pricebook: {
            service: replaceSlugs(PRICEBOOK_ITEMS_ROUTES.SERVICE.CREATE, slug),
            part_or_material: replaceSlugs(PRICEBOOK_ITEMS_ROUTES.PART_OR_MATERIAL.CREATE, slug),
            miscellaneous: replaceSlugs(PRICEBOOK_ITEMS_ROUTES.MISCELLANEOUS.CREATE, slug),
            discount: replaceSlugs(PRICEBOOK_ITEMS_ROUTES.DISCOUNT.CREATE, slug),
            tax_rates: isUsingTaxRates ? replaceSlugs(PRICEBOOK_ITEMS_ROUTES.TAX_RATES.CREATE, slug) : undefined,
        },
    }

    return (
        <>
            <div className={styles.base} onClickCapture={openBottomSheet}>
                <button
                    className={clsx(styles.trigger, {
                        [styles.isDisabled]: isDisabled,
                    })}
                    aria-disabled={isDisabled}
                    aria-label="Create Object"
                >
                    <Icon
                        icon={icon({
                            name: "plus",
                            style: "solid",
                            family: "sharp",
                        })}
                        size={16}
                    />
                </button>
            </div>
            <BottomSheet.Root isOpen={isBottomSheetOpen} onClose={onCloseBottomSheet}>
                <BottomSheet.Content
                    header={{
                        title: "Create Object",
                    }}
                    priority="primary"
                    isVisible={!isSubItemsOpen}
                    itemsClassName={styles.items}
                >
                    {(isTabbable) => (
                        <>
                            <BottomSheet.Item
                                icon={icon({ name: "file-lines", style: "light", family: "sharp" })}
                                path={routes.estimate}
                                className={styles.bottomSheetItem}
                                label="Estimate"
                                isTabbable={isTabbable}
                            />
                            <BottomSheet.Item
                                icon={icon({ name: "wrench-simple", style: "light", family: "sharp" })}
                                path={routes.jobs}
                                className={styles.bottomSheetItem}
                                label="Job"
                                isTabbable={isTabbable}
                            />
                            <BottomSheet.Item
                                icon={icon({ name: "circle-dollar", style: "light", family: "sharp" })}
                                path={routes.invoices}
                                className={styles.bottomSheetItem}
                                label="Invoice"
                                isTabbable={isTabbable}
                            />
                            <BottomSheet.Item
                                icon={icon({ name: "basket-shopping", style: "light", family: "sharp" })}
                                path={routes.purchase_orders}
                                className={styles.bottomSheetItem}
                                label="Purchase Order"
                                isTabbable={isTabbable}
                            />
                            <BottomSheet.Item
                                icon={icon({ name: "store", style: "light", family: "sharp" })}
                                path={routes.vendors}
                                className={styles.bottomSheetItem}
                                label="Vendor"
                                isTabbable={isTabbable}
                            />
                            <BottomSheet.Item
                                icon={icon({ name: "user-circle", style: "light", family: "sharp" })}
                                path={routes.clients}
                                className={styles.bottomSheetItem}
                                label="Client"
                                isTabbable={isTabbable}
                            />
                            <BottomSheet.Item
                                icon={icon({ name: "tag", style: "light", family: "sharp" })}
                                className={styles.bottomSheetItem}
                                label="Pricebook Item"
                                isTabbable={isTabbable}
                                hasSubItems={true}
                                onClick={openSubItems}
                            />
                        </>
                    )}
                </BottomSheet.Content>
                <BottomSheet.Content
                    header={{
                        onBackClick: closeSubItems,
                        title: "Create Pricebook Item",
                    }}
                    itemsClassName={styles.items}
                    priority="secondary"
                    isVisible={isSubItemsOpen}
                >
                    {(isTabbable) => (
                        <>
                            <BottomSheet.Item
                                path={routes.pricebook.service}
                                className={styles.bottomSheetItem}
                                label="Service"
                                isTabbable={isTabbable}
                            />

                            <BottomSheet.Item
                                path={routes.pricebook.part_or_material}
                                className={styles.bottomSheetItem}
                                label="Part or Material"
                                isTabbable={isTabbable}
                            />

                            <BottomSheet.Item
                                path={routes.pricebook.miscellaneous}
                                className={styles.bottomSheetItem}
                                label="Miscellaneous"
                                isTabbable={isTabbable}
                            />

                            <BottomSheet.Item
                                path={routes.pricebook.discount}
                                className={styles.bottomSheetItem}
                                label="Discount"
                                isTabbable={isTabbable}
                            />

                            {isUsingTaxRates && (
                                <BottomSheet.Item
                                    path={routes.pricebook.tax_rates}
                                    className={styles.bottomSheetItem}
                                    label="Tax Rate"
                                    isTabbable={isTabbable}
                                />
                            )}
                        </>
                    )}
                </BottomSheet.Content>
            </BottomSheet.Root>
        </>
    )
}
