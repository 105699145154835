import React, { Component } from "react";
import deepcopy from "rfdc";
import ButtonGroup from "../../../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../../../core/buttons/UniversalButton";
import BooleanField from "../../../../core/fields/BooleanField";
import CharField from "../../../../core/fields/CharField";
import EmailField from "../../../../core/fields/EmailField";
import PhoneNumberField from "../../../../core/fields/PhoneNumberField";


class QuickBooksContactRecordForm extends Component {

    renderButtons = () => {
        const { contact, errors, onFormDataChange, requestAction } = this.props

        return (
            <ButtonGroup>
                <ButtonGroupRow>
                    <UniversalButton type="primary" text="Save" handler={event => requestAction("RECORD_SAVE_CONTACT")} />
                </ButtonGroupRow>
                <ButtonGroupRow>
                    <UniversalButton type="secondary" text="Cancel Edits" handler={event => requestAction("RECORD_CANCEL_CONTACT_EDITS")} />
                </ButtonGroupRow>
            </ButtonGroup>
        )
    }

    render() {
        const { contact, onFormDataChange, requestAction } = this.props
        let { errors } = this.props
        errors = deepcopy()(errors)

        if (errors.phone) {
            errors.phone += ` The value retrieved from QuickBooks is "${contact.phone}".`
        }

        if (errors.email) {
            errors.email += ` The value retrieved from QuickBooks is "${contact.email}".`
        }

        return (
            <div className="data-panel__list-select__form">
                <div className="data-panel__form" aria-label="Contact Edit Form">
                    {errors.non_field_error && <div className="data-panel__form__field__errors" aria-label="Non Field Error">{errors.non_field_error}</div>}
                    <CharField
                        fieldName="name"
                        fieldLabel="Name"
                        fieldValue={contact.cleaned_name || ""}
                        fieldOnChange={cleaned_name => onFormDataChange("cleaned_name", cleaned_name || "")}
                        maxLength="100"
                        optional={true}
                        errors={errors}
                    ></CharField>
                    <PhoneNumberField
                        fieldName="phone"
                        fieldLabel="Phone"
                        fieldValue={errors.phone ? (contact.cleaned_phone ? contact.cleaned_phone : contact.phone) : (contact.cleaned_phone || "")}
                        fieldOnChange={cleaned_phone => onFormDataChange("cleaned_phone", cleaned_phone || "")}
                        extensionFieldName="cleaned_phone_extension"
                        extensionFieldLabel="Ext."
                        extensionFieldValue={contact.cleaned_phone_extension || ""}
                        extensionOnChange={cleaned_phone_extension => onFormDataChange("cleaned_phone_extension", cleaned_phone_extension || "")}
                        defaultCountryCode={window.PHONE_NUMBER_COUNTRY}
                        optional={true}
                        errors={errors}
                        showExtensionField={true}
                    ></PhoneNumberField>
                    <EmailField
                        fieldName="email"
                        fieldLabel="Email"
                        fieldValue={errors.email ? (contact.cleaned_email ? contact.cleaned_email : contact.email) : (contact.cleaned_email || "")}
                        fieldOnChange={cleaned_email => onFormDataChange("cleaned_email", cleaned_email || "")}
                        optional={true}
                        errors={errors}
                    ></EmailField>
                    <BooleanField
                        fieldName="primary"
                        fieldLabel="Primary"
                        fieldValue={contact.primary || false}
                        fieldOnChange={primary => onFormDataChange("primary", primary)}
                        inputLabel="Primary Contact"
                        errors={errors}
                    ></BooleanField>
                </div>
                {this.renderButtons()}
            </div>
        )
    }
}

export default QuickBooksContactRecordForm;
