import JobListCard from "@legacy/jobs/components/JobListCard"
import { useEffect, useMemo } from "react"

import useIsDesktop from "@hooks/useIsDesktop"
import useLocalStorage from "@hooks/useLocalStorage"
import useSlugExtractor from "@hooks/useSlugExtractor"
import useUser from "@hooks/useUser"

import extractURLSearchParam from "@utils/extractURLSearchParam"

import { ObjectsView, TopBar } from "@organisms"
import { ObjectsViewType } from "@organisms/ObjectsView/ObjectsView.types"

import { AppFrame } from "@templates"

import { JOBS_ROUTES } from "@routes/jobs"

import {
    LIST_SEARCH_PARAM_ACTIVE_TAB,
    LIST_SEARCH_PARAM_KEYWORD_SEARCH,
    LIST_SEARCH_PARAM_PHASE,
} from "@constants/searchParams"
import {
    JOBS_LIST_CONTROLS_ACTIVE_TAB,
    JOBS_LIST_CONTROLS_KEYWORD_SEARCH,
    JOBS_LIST_CONTROLS_ORDERING,
    JOBS_LIST_CONTROLS_PHASE,
    JOBS_LIST_CONTROLS_VIEW,
} from "@constants/storage"

import useJobTabs from "./useJobTabs"

export default function JobList() {
    const { user } = useUser()

    const isDesktop = useIsDesktop()

    const [createRoute] = useSlugExtractor([JOBS_ROUTES.CREATE])
    const [openTabs, closedTabs] = useJobTabs()

    const phaseOverride = useMemo(() => extractURLSearchParam(LIST_SEARCH_PARAM_PHASE) as ObjectPhase, [])
    const activeTabOverride = useMemo(() => extractURLSearchParam(LIST_SEARCH_PARAM_ACTIVE_TAB), [])
    const searchKeywordsOverride = useMemo(() => extractURLSearchParam(LIST_SEARCH_PARAM_KEYWORD_SEARCH), [])

    const [viewType, setViewType] = useLocalStorage<ObjectsViewType>(
        JOBS_LIST_CONTROLS_VIEW,
        isDesktop ? "table" : "card",
    )
    const [phase, setPhase] = useLocalStorage<ObjectPhase>(JOBS_LIST_CONTROLS_PHASE, "open", phaseOverride)
    const [activeTab, setActiveTab] = useLocalStorage<string>(JOBS_LIST_CONTROLS_ACTIVE_TAB, "", activeTabOverride)
    const [searchKeywords, setSearchKeywords] = useLocalStorage<string>(
        JOBS_LIST_CONTROLS_KEYWORD_SEARCH,
        "",
        searchKeywordsOverride,
    )

    const onShowOpen = () => {
        setPhase("open")
        setActiveTab(openTabs[0].title)
    }

    const onShowClosed = () => {
        setPhase("closed")
        setActiveTab(closedTabs[0].title)
    }

    const tabs = phase === "open" ? openTabs : closedTabs

    useEffect(() => {
        const isAnyTabAvailable = tabs && tabs.length > 0

        if (isAnyTabAvailable && !activeTab) {
            setActiveTab(tabs[0].title)
        }
    }, [tabs, activeTab])

    return (
        <AppFrame.Root pageId="JobList">
            <TopBar.Root>
                <TopBar.Header>
                    <TopBar.TopBarHeaderStart>
                        <TopBar.PageTitle iconName="jobs" label="Jobs" />
                    </TopBar.TopBarHeaderStart>
                    <TopBar.TopBarHeaderEnd>
                        <TopBar.PagePresence />
                        <TopBar.SearchButton scope="Jobs" />
                        <TopBar.ViewSelector
                            viewTypes={["table", "card", "timeline"]}
                            selectedViewType={viewType}
                            setViewType={setViewType}
                        />
                        {user?.isServiceDispatcher && (
                            <TopBar.CTAButton destination={createRoute}>
                                {isDesktop ? "New Job" : null}
                            </TopBar.CTAButton>
                        )}
                    </TopBar.TopBarHeaderEnd>
                </TopBar.Header>
            </TopBar.Root>
            <ObjectsView
                viewType={viewType}
                searchKeywords={searchKeywords}
                setActiveTab={setActiveTab}
                setSearchKeywords={setSearchKeywords}
                onShowOpen={onShowOpen}
                onShowClosed={onShowClosed}
                phase={phase}
                orderingKey={JOBS_LIST_CONTROLS_ORDERING}
                tabs={tabs}
                activeTab={activeTab}
                CardComponent={JobListCard}
                listTitle="Jobs"
                objectCreateRoute={createRoute}
                objectType="job"
            />
        </AppFrame.Root>
    )
}
