import { PLATFORM_HAS_COMMAND_KEY } from "@constants/platforms"

export const getSymbolByName = (name: string) => {
    switch (name) {
        case "Command":
            return "\u2318"
        case "Shift":
            return "\u21E7"
        case "Option":
            return "\u2325"
        case "Caret":
            return "\u2303"
        case "Mod":
            return PLATFORM_HAS_COMMAND_KEY ? "\u2318" : "Ctrl"
        default:
            return name
    }
}
