// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AppAppearanceSelector-base__OQU_x{display:flex;gap:var(--size-3);align-items:flex-start;align-self:stretch;padding:var(--size-0)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "AppAppearanceSelector-base__OQU_x"
};
export default ___CSS_LOADER_EXPORT___;
