import clsx from "clsx"

import { NavigationLink } from "@atoms"

import { ActivatableIcon } from "@molecules"

import styles from "./BreadcrumbItem.module.scss"
import { BreadcrumbItemProps } from "./BreadcrumbItem.types"

export default function BreadcrumbItem(props: BreadcrumbItemProps) {
    const { label, path, hasSubItems, onClick, isActive, isDisabled, isOpen, showSlash, iconName, iconOnly } = props

    const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        isDisabled ? event.preventDefault() : onClick?.(event)
    }

    return (
        <div className={styles.base}>
            <NavigationLink
                title={label}
                path={path}
                className={clsx(styles.button, {
                    [styles.disabled]: isDisabled,
                    [styles.open]: isOpen,
                    [styles.hasSubItems]: hasSubItems,
                    [styles.notClickable]: !onClick && !path,
                    [styles.iconOnly]: iconOnly,
                })}
                isActive={isActive}
                activeClassName={styles.active}
                onClick={handleClick}
                isDisabled={isDisabled}
                isTabbable={!!path || !!onClick}
            >
                {(isActive) => (
                    <div className={styles.buttonInnerContainer}>
                        {iconName ? (
                            <div className={styles.icon}>
                                <ActivatableIcon iconName={iconName} isActive={true} size={16} />
                            </div>
                        ) : null}

                        {iconOnly ? null : (
                            <div className={styles.textAndChevron}>
                                <span className={styles.label}>{label}</span>{" "}
                            </div>
                        )}
                        {hasSubItems && (
                            <div className={styles.textAndChevron}>
                                <ActivatableIcon iconName="angle_down" isActive={isActive} size={12} />
                            </div>
                        )}
                    </div>
                )}
            </NavigationLink>
            {showSlash && <span className={styles.slash}>/</span>}
        </div>
    )
}
