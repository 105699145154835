// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FakeCaret-base__uMXEc{top:0;right:0;bottom:0;left:0;display:flex;align-items:center;justify-content:center;pointer-events:none}.FakeCaret-caret__FLvlo{width:var(--size-0_5);height:var(--size-6);background-color:var(--gray-12);animation:FakeCaret-caretBlink__ZHTa3 1.2s ease-out infinite}.FakeCaret-caret__FLvlo.FakeCaret-filled__IqJ5m{height:var(--size-4_5)}@keyframes FakeCaret-caretBlink__ZHTa3{0%{opacity:1}20%,50%{opacity:0}70%,100%{opacity:1}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "FakeCaret-base__uMXEc",
	"caret": "FakeCaret-caret__FLvlo",
	"caretBlink": "FakeCaret-caretBlink__ZHTa3",
	"filled": "FakeCaret-filled__IqJ5m"
};
export default ___CSS_LOADER_EXPORT___;
