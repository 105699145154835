// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AvatarManagementDialogTrigger-base__UPfdB{position:absolute;top:calc(var(--size-7_5)*-1);display:flex;align-items:center;justify-content:center;margin:0 auto;padding:var(--size-1_5);overflow:hidden;background-color:var(--surface-primary);border:0;border-radius:var(--border-radius-full)}.AvatarManagementDialogTrigger-base__UPfdB .AvatarManagementDialogTrigger-hoverIndicator__LoeOo{position:absolute;top:var(--size-1_5);right:var(--size-1_5);bottom:var(--size-1_5);left:var(--size-1_5);z-index:var(--z-index-base);display:flex;align-items:center;justify-content:center;color:var(--pure-white);background-color:var(--pure-black-a6);border-radius:var(--border-radius-full);opacity:0;transition-duration:var(--duration-fast);transition-property:opacity}.AvatarManagementDialogTrigger-base__UPfdB:hover .AvatarManagementDialogTrigger-hoverIndicator__LoeOo{opacity:1}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "AvatarManagementDialogTrigger-base__UPfdB",
	"hoverIndicator": "AvatarManagementDialogTrigger-hoverIndicator__LoeOo"
};
export default ___CSS_LOADER_EXPORT___;
