import styles from "./TopBarHeader.module.scss"
import { TopBarHeaderProps } from "./TopBarHeader.types"

export default function TopBarHeader(props: TopBarHeaderProps) {
    const { children } = props

    return (
        <div className={styles.base}>
            <div className={styles.container}>{children}</div>
        </div>
    )
}
