import useObjectDetailsRoutes from "@hooks/useObjectDetailsRoute"
import useSlugExtractor from "@hooks/useSlugExtractor"

import { Table } from "@organisms"
import { Cells } from "@organisms/Table/Table.types"
import { GetObjectDetailsRouteFunction, Tab } from "@organisms/Table/TableViewControls/TableViewTab/TableViewTab.types"

import { CLIENTS_ROUTES } from "@routes/clients"

export default function useClientTabs(): [Tab<Client>[], Tab<Client>[]] {
    const [tabCountEndpoint, tabListEndpoint] = useSlugExtractor([
        CLIENTS_ROUTES.COUNT,
        CLIENTS_ROUTES.LIGHTWEIGHT_LIST,
    ])

    const getTabObjectDetailsRoute = useObjectDetailsRoutes("Client") as GetObjectDetailsRouteFunction

    const headerByColumn = {
        name: "Client",
        client_type_label: "Type",
        service_locations_display: "Location",
        default_invoice_net: "Terms",
        credit_limit: "Credit Limit",
        outstanding_balance: "Balance Due",
        current_estimates_count: "Current Est.",
        current_jobs_count: "Current Jobs",
        current_invoices_count: "Current Inv.",
        last_modified_at: "Last Modified",
    }

    const sortingEnabledByColumn = {
        name: true,
        client_type_label: true,
        service_locations_display: false,
        default_invoice_net: true,
        credit_limit: true,
        outstanding_balance: true,
        current_estimates_count: true,
        current_jobs_count: true,
        current_invoices_count: true,
        last_modified_at: true,
    }

    const widthByColumn = {
        name: 224,
        client_type_label: 112,
        service_locations_display: 288,
        default_invoice_net: 144,
        credit_limit: 144,
        outstanding_balance: 144,
        current_estimates_count: 144,
        current_jobs_count: 144,
        current_invoices_count: 144,
        last_modified_at: 144,
    }

    const pinningByColumn = {
        name: true,
        client_type_label: true,
        service_locations_display: false,
        default_invoice_net: false,
        credit_limit: false,
        outstanding_balance: false,
        current_estimates_count: false,
        current_jobs_count: false,
        current_invoices_count: false,
        last_modified_at: false,
    }

    const cellByColumn: Cells<Client> = {
        name: ({ getValue }) => <Table.Cells.TruncatedTextCell content={getValue()} />,
        client_type_label: ({ getValue }) => <Table.Cells.TruncatedTextCell content={getValue()} />,
        service_locations_display: ({ getValue }) => (
            <Table.Cells.TruncatedTextCell content={getValue()} variant="secondary" />
        ),
        default_invoice_net: ({ getValue }) => <Table.Cells.PaymentTermsCell terms={getValue()} />,
        credit_limit: ({ getValue }) => <Table.Cells.NumericalDataCell value={getValue()} type="currency" />,
        outstanding_balance: ({ getValue, row }) => (
            <Table.Cells.NumericalDataCell
                value={getValue()}
                ignoreZero={true}
                type="currency"
                isAlerting={parseFloat(row.original.outstanding_balance) > parseFloat(row.original.credit_limit)}
            />
        ),
        current_estimates_count: ({ getValue }) => <Table.Cells.NumericalDataCell value={getValue()} type="number" />,
        current_jobs_count: ({ getValue }) => <Table.Cells.NumericalDataCell value={getValue()} type="number" />,
        current_invoices_count: ({ getValue }) => <Table.Cells.NumericalDataCell value={getValue()} type="number" />,
        last_modified_at: ({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />,
    }

    const columnByName = {
        name: {
            header: headerByColumn["name"],
            accessorKey: "name",
            id: "name",
            size: widthByColumn["name"],
            cell: cellByColumn["name"],
            enableSorting: sortingEnabledByColumn["name"],
            enablePinning: pinningByColumn["name"],
        },
        client_type_label: {
            header: headerByColumn["client_type_label"],
            accessorKey: "client_type_label",
            id: "client_type_label",
            size: widthByColumn["client_type_label"],
            cell: cellByColumn["client_type_label"],
            enableSorting: sortingEnabledByColumn["client_type_label"],
            enablePinning: pinningByColumn["client_type_label"],
        },
        service_locations_display: {
            header: headerByColumn["service_locations_display"],
            accessorKey: "service_locations_display",
            id: "service_locations_display",
            size: widthByColumn["service_locations_display"],
            cell: cellByColumn["service_locations_display"],
            enableSorting: sortingEnabledByColumn["service_locations_display"],
            enablePinning: pinningByColumn["service_locations_display"],
        },
        default_invoice_net: {
            header: headerByColumn["default_invoice_net"],
            accessorKey: "default_invoice_net",
            id: "default_invoice_net",
            size: widthByColumn["default_invoice_net"],
            cell: cellByColumn["default_invoice_net"],
            enableSorting: sortingEnabledByColumn["default_invoice_net"],
            enablePinning: pinningByColumn["default_invoice_net"],
        },
        credit_limit: {
            header: headerByColumn["credit_limit"],
            accessorKey: "credit_limit",
            id: "credit_limit",
            size: widthByColumn["credit_limit"],
            cell: cellByColumn["credit_limit"],
            enableSorting: sortingEnabledByColumn["credit_limit"],
            enablePinning: pinningByColumn["credit_limit"],
        },
        outstanding_balance: {
            header: headerByColumn["outstanding_balance"],
            accessorKey: "outstanding_balance",
            id: "outstanding_balance",
            size: widthByColumn["outstanding_balance"],
            cell: cellByColumn["outstanding_balance"],
            enableSorting: sortingEnabledByColumn["outstanding_balance"],
            enablePinning: pinningByColumn["outstanding_balance"],
        },
        current_estimates_count: {
            header: headerByColumn["current_estimates_count"],
            accessorKey: "current_estimates_count",
            id: "current_estimates_count",
            size: widthByColumn["current_estimates_count"],
            cell: cellByColumn["current_estimates_count"],
            enableSorting: sortingEnabledByColumn["current_estimates_count"],
            enablePinning: pinningByColumn["current_estimates_count"],
        },
        current_jobs_count: {
            header: headerByColumn["current_jobs_count"],
            accessorKey: "current_jobs_count",
            id: "current_jobs_count",
            size: widthByColumn["current_jobs_count"],
            cell: cellByColumn["current_jobs_count"],
            enableSorting: sortingEnabledByColumn["current_jobs_count"],
            enablePinning: pinningByColumn["current_jobs_count"],
        },
        current_invoices_count: {
            header: headerByColumn["current_invoices_count"],
            accessorKey: "current_invoices_count",
            id: "current_invoices_count",
            size: widthByColumn["current_invoices_count"],
            cell: cellByColumn["current_invoices_count"],
            enableSorting: sortingEnabledByColumn["current_invoices_count"],
            enablePinning: pinningByColumn["current_invoices_count"],
        },
        last_modified_at: {
            header: headerByColumn["last_modified_at"],
            accessorKey: "last_modified_at",
            id: "last_modified_at",
            size: widthByColumn["last_modified_at"],
            cell: cellByColumn["last_modified_at"],
            enableSorting: sortingEnabledByColumn["last_modified_at"],
            enablePinning: pinningByColumn["last_modified_at"],
        },
    }

    const activeClientsTabs: Tab<Client>[] = [
        {
            title: "All Active",
            tabObjectName: "Client",
            objectsPhase: "active",
            showTab: true,
            countEndpoint: tabCountEndpoint,
            listEndpoint: tabListEndpoint,
            getDetailsRoute: getTabObjectDetailsRoute,
            endpointKwargs: [
                ["ordering", "name"],
                ["is_active", "true"],
            ],
            columnDefinition: [
                columnByName["name"],
                columnByName["client_type_label"],
                columnByName["service_locations_display"],
                columnByName["default_invoice_net"],
                columnByName["credit_limit"],
                columnByName["outstanding_balance"],
                columnByName["current_estimates_count"],
                columnByName["current_jobs_count"],
                columnByName["current_invoices_count"],
                columnByName["last_modified_at"],
            ],
        },
    ]

    const inactiveClientsTabs: Tab<Client>[] = [
        {
            title: "All Inactive",
            tabObjectName: "Client",
            objectsPhase: "inactive",
            showTab: true,
            countEndpoint: tabCountEndpoint,
            listEndpoint: tabListEndpoint,
            getDetailsRoute: getTabObjectDetailsRoute,
            endpointKwargs: [
                ["ordering", "name"],
                ["is_active", "false"],
            ],
            columnDefinition: [
                columnByName["name"],
                columnByName["client_type_label"],
                columnByName["service_locations_display"],
                columnByName["default_invoice_net"],
                columnByName["credit_limit"],
                columnByName["outstanding_balance"],
                columnByName["current_estimates_count"],
                columnByName["current_jobs_count"],
                columnByName["current_invoices_count"],
                columnByName["last_modified_at"],
            ],
        },
    ]

    if (!tabListEndpoint) {
        return [null, null]
    } else {
        return [activeClientsTabs, inactiveClientsTabs]
    }
}
