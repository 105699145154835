import React, { Component } from "react";

class UniversalButton extends Component {

    constructor(props) {
        super(props)

        this.state = {
            disabled: false,
        }
    }

    getClassName = (type) => {
        const typeClassMap = {
            "primary": "button--primary",
            "secondary": "button--secondary",
            "secondary_transparent": "button--secondary-transparent",
            "danger": "button--danger",
            "card": "button--secondary button--card",
            "card_list_load_more": "button--secondary button--card-list-load-more",
            "list_select_load_more": "button--secondary button--list-select-load-more",
            "list_select_action": "button--list-select-action",
            "search_or_create": "button--primary button--form-field-attached",
            "data_panel_pill": "button--secondary button--data-panel-pill",
            "signature_pad_clear": "button--signature-pad-clear",
        }
        return `button ${typeClassMap[type] || ""}`
    }

    debounceHandler = (handler, event) => {
        if (!this.state.disabled) {
            handler(event)
            this.setState({disabled: true})
            setTimeout(() => this.setState({disabled: false}), 1000)
        }
    }

    render() {
        const { type, text, handler, title, extraClasses, immediate=false } = this.props
        return <button
            className={`${this.getClassName(type)}${extraClasses ? ` ${extraClasses}` : ""}${this.state.disabled ? " button--waiting" : ""}`}
            onClick={event => immediate ? handler(event) : this.debounceHandler(handler, event)}
            title={title}
            disabled={(this.state.disabled || this.props.disabled) ? true : undefined}
        >
            {text}
        </button>
    }
}

export default UniversalButton;
