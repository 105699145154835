import { DateHelper, DomClassList, Model } from "@bryntum/schedulerpro"
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import { JobStatuses } from "@legacy/core/utils/enums"
import { useCallback } from "react"
import ReactDOMServer from "react-dom/server"

import useUser from "@hooks/useUser"

import formatTimelineViewCardLocationName from "@utils/formatTimelineViewCardLocationName"

import { Icon } from "@atoms/index"

import JobPreviewCard from "@organisms/ObjectsView/JobTimelineView/JobPreviewCard/JobPreviewCard"
import styles from "@organisms/ObjectsView/JobTimelineView/JobTimelineView.module.scss"
import {
    EventModel,
    RenderData,
    ResourceTimeRangeModel,
    SchedulerEventModel,
    SchedulerResourceModel,
} from "@organisms/ObjectsView/JobTimelineView/JobTimelineView.types"
import TimelineViewEventCard from "@organisms/ObjectsView/JobTimelineView/TimelineViewEventCard/TimelineViewEventCard"
import TimelineViewTechniciansBarHeader from "@organisms/ObjectsView/JobTimelineView/TimelineViewTechniciansBar/TimelineViewTechniciansBarHeader/TimelineViewTechniciansBarHeader"
import TimelineViewTechniciansBarTechCell from "@organisms/ObjectsView/JobTimelineView/TimelineViewTechniciansBar/TimelineViewTechniciansBarTechCell/TimelineViewTechniciansBarTechCell"

import useJobTimelineViewStates from "./useJobTimelineViewStates"

export default function useJobTimelineViewRenderers() {
    const { jobCardStyle, timelineViewOrientation, todayInUserTimezone } = useJobTimelineViewStates()
    const { user } = useUser()

    const renderUnscheduledJobsCellsTooltip = ({ record }: { record: EventModel<CalendarEvent> }) => {
        return (
            <JobPreviewCard
                jobId={record._data.custom_id}
                jobName={record._data.service_name}
                clientName={record._data.client}
                assignedTechnicians={[]}
                jobAddress={record._data.location}
                jobDate={record._data.start_time}
                jobDuration={record._data.estimated_duration}
                reasonForCall={record._data.details}
                statusColorOverride={record._data.status_color_override}
                statusLabel={record._data.status_label}
                subStatusLabel={record._data.substatus_label}
                createdAt={record._data.created_at}
                createdBy={record._data.created_by}
                status={record._data.status}
            />
        )
    }

    const renderUnscheduledJobsCells = ({ record }: { record: Model }) => {
        const jobEvent = record as unknown as CalendarEvent
        return ReactDOMServer.renderToString(
            <TimelineViewEventCard
                location={formatTimelineViewCardLocationName(jobEvent)}
                client={jobEvent.client}
                duration={jobEvent.estimated_duration}
                customId={jobEvent.custom_id}
                serviceName={jobEvent.service_name}
                isResizeEnabled={false}
                status={jobEvent.status}
                statusColorOverride={jobEvent.status_color_override}
                id={jobEvent.id}
                assignedTechnicians={[]}
            />,
        )
    }

    const renderTechniciansColumnCell = ({ record }: { record: Model }) => {
        const techRecord = record as SchedulerResourceModel<CalendarTechnician>

        const userId = techRecord?.originalData?.id

        if (userId === "unassigned") {
            return <TimelineViewTechniciansBarTechCell technician={null} />
        } else {
            return <TimelineViewTechniciansBarTechCell technician={techRecord?.originalData} />
        }
    }

    const renderTechniciansColumnHeader = () => {
        return ReactDOMServer.renderToStaticMarkup(<TimelineViewTechniciansBarHeader />)
    }

    const renderJobTimelineViewCard = useCallback(
        ({ eventRecord, renderData }: { eventRecord: SchedulerEventModel; renderData: RenderData }) => {
            const isDragAndResizeEnabled =
                eventRecord.originalData.status !== JobStatuses.cancelled &&
                eventRecord.originalData.status !== JobStatuses.finalized

            eventRecord.draggable = isDragAndResizeEnabled
            ;(renderData.wrapperCls as DomClassList).add("jsEventDroppableArea")

            const startDate = (eventRecord.resizingStartTime || eventRecord.startDate) as Date
            const endDate = (eventRecord.resizingEndTime || eventRecord.endDate) as Date

            const eventDuration = Math.abs((new Date(endDate).getTime() - new Date(startDate).getTime()) / 1000)

            return ReactDOMServer.renderToStaticMarkup(
                <TimelineViewEventCard
                    startTime={startDate}
                    endTime={endDate}
                    status={eventRecord._data.status}
                    statusColorOverride={eventRecord._data.status_color_override}
                    customId={eventRecord._data.custom_id}
                    id={eventRecord._data.id}
                    assignedTechnicians={eventRecord._data.assigned_technicians}
                    serviceName={eventRecord._data.service_name}
                    client={eventRecord._data.client}
                    location={formatTimelineViewCardLocationName(eventRecord._data)}
                    isResizeEnabled={user.isServiceDispatcher && isDragAndResizeEnabled}
                    isCompact={jobCardStyle === "compact"}
                    isVertical={timelineViewOrientation === "vertical"}
                    duration={eventDuration}
                    isResizingEventStart={eventRecord.isResizingEventStart}
                    isResizingEventEnd={eventRecord.isResizingEventEnd}
                    isOverlappingOnResize={eventRecord.isOverlappingOnResize}
                />,
            )
        },
        [jobCardStyle, timelineViewOrientation, user?.isServiceDispatcher],
    )

    const renderJobTimelineViewCardTooltip = ({ eventRecord }: { eventRecord: SchedulerEventModel }) => {
        const jobDate = eventRecord.startDate as Date
        const isPastDue = DateHelper.isBefore(new Date(jobDate), new Date(todayInUserTimezone))

        // This is a hack to fix the status label when the event is being dragged.
        // The status label is not updated until the calendar endpoint that feeds the entire
        // timeline is fetched again, so we need to update the `Past Due` and `Upcoming`
        // labels manually.
        const statusLabel = ((statusLabel) => {
            if (statusLabel === "Past Due" && !isPastDue) {
                return "Upcoming"
            } else if (statusLabel === "Upcoming" && isPastDue) {
                return "Past Due"
            } else {
                return statusLabel
            }
        })(eventRecord._data.status_label)

        return (
            <JobPreviewCard
                jobId={eventRecord._data.custom_id || eventRecord._data.id}
                jobName={eventRecord._data.service_name}
                clientName={eventRecord._data.client}
                assignedTechnicians={eventRecord._data.assigned_technicians}
                jobAddress={eventRecord._data.location}
                jobDate={jobDate}
                jobDuration={eventRecord._data.estimated_duration}
                reasonForCall={eventRecord._data.details}
                statusColorOverride={eventRecord._data.status_color_override}
                statusLabel={statusLabel}
                subStatusLabel={eventRecord._data.substatus_label}
                createdAt={eventRecord._data.created_at}
                createdBy={eventRecord._data.created_by}
                status={eventRecord._data.status}
            />
        )
    }

    const renderResourceTimeRange = ({
        resourceTimeRangeRecord,
    }: {
        resourceTimeRangeRecord: ResourceTimeRangeModel
    }) => {
        if (resourceTimeRangeRecord.data.isOverlappingTimeRange) {
            return ReactDOMServer.renderToStaticMarkup(
                <div className={styles.overlappingRegionCounter}>
                    <Icon icon={icon({ name: "diamond-exclamation", style: "solid", family: "sharp" })} />
                    {resourceTimeRangeRecord.data.amountOfItems}
                </div>,
            )
        } else {
            return resourceTimeRangeRecord.name
        }
    }

    const renderUnscheduledJobsGridEmptyMessage = () => {
        return ReactDOMServer.renderToStaticMarkup(
            <div className={styles.unscheduledJobsEmptyState}>
                <Icon
                    className={styles.unscheduledJobsEmptyStateIcon}
                    icon={icon({ name: "circle-dashed", style: "solid", family: "sharp" })}
                    size={32}
                />

                <div className={styles.unscheduledJobsEmptyStateTextWrap}>
                    <div className={styles.unscheduledJobsEmptyStateTitle}>No Unscheduled Jobs</div>
                    <div className={styles.unscheduledJobsEmptyStateMessage}>
                        Once you unschedule a job, it&apos;ll appear here.
                    </div>
                </div>
            </div>,
        )
    }

    return {
        renderResourceTimeRange,
        renderUnscheduledJobsCellsTooltip,
        renderUnscheduledJobsCells,
        renderTechniciansColumnCell,
        renderTechniciansColumnHeader,
        renderJobTimelineViewCard,
        renderJobTimelineViewCardTooltip,
        renderUnscheduledJobsGridEmptyMessage,
    }
}
