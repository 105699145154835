import { Component } from "react";
import PhoneNumberDisplay from "../../core/components/PhoneNumberDisplay";
import EmailDisplay from "./EmailDisplay";

class ContactDisplay extends Component {

    renderInvoiceReminderPreference = (contact) => {
        if (contact.receive_invoice_reminders_via_email && contact.receive_invoice_reminders_via_sms) {
            return "Invoice reminders via Email and SMS"
        } else if (contact.receive_invoice_reminders_via_email) {
            return "Invoice reminders via Email"
        }
        else if (contact.receive_invoice_reminders_via_sms) {
            return "Invoice reminders via SMS"
        }
        else {
            return "\u2014"
        }
    }

    renderEstimateReminderPreference = (contact) => {
        if (contact.receive_estimate_reminders_via_email && contact.receive_estimate_reminders_via_sms) {
            return "Estimate reminders via Email and SMS"
        } else if (contact.receive_estimate_reminders_via_email) {
            return "Estimate reminders via Email"
        }
        else if (contact.receive_estimate_reminders_via_sms) {
            return "Estimate reminders via SMS"
        }
        else {
            return "\u2014"
        }
    }

    render = () => {
        const { contact } = this.props

        return (
            <div className="data-panel__form__field__display-list__object contact-display" aria-label="Contact">
                {
                    contact.primary && (
                        <div className="contact-display__row contact-display-primary">
                            <div className="contact-display__field" aria-label="Primary">
                                <div className="contact-display__field-value"><span className="data-tag" style={{margin: "0", width: "100%"}}>Primary Contact</span></div>
                            </div>
                        </div>
                    )
                }
                <div className="contact-display__row">
                    {
                        contact.name && (
                            <div className="contact-display__field" aria-label="Name">
                                <div className="contact-display__field-label" aria-hidden="true">Name</div>
                                <div className="contact-display__field-value">{contact.name || "\u2014"}</div>
                            </div>
                        )
                    }
                    {
                        contact.phone && (
                            <div className="contact-display__field" aria-label="Phone">
                                <div className="contact-display__field-label" aria-hidden="true">Phone</div>
                                <div className="contact-display__field-value">{contact.phone ? <PhoneNumberDisplay phone={contact.phone} phoneExtension={contact.phone_extension} hyperlink={true} /> : "\u2014"}</div>
                            </div>
                        )
                    }
                    {
                        contact.email && (
                            <div className="contact-display__field" aria-label="Email">
                                <div className="contact-display__field-label" aria-hidden="true">Email</div>
                                <div className="contact-display__field-value">{contact.email ? <EmailDisplay email={contact.email} hyperlink={true} /> : "\u2014"}</div>
                            </div>
                        )
                    }
                </div>
                {
                    (contact.attached_to === "external_client" || contact.attached_to === "service_location") && (
                        <div className="contact-display__row">
                            <div className="contact-display__field" aria-label="Reminders">
                                <div className="contact-display__field-label" aria-hidden="true">Reminders</div>
                                <div className="contact-display__field-value">{this.renderInvoiceReminderPreference(contact)}</div>
                                <div className="contact-display__field-value">{this.renderEstimateReminderPreference(contact)}</div>
                            </div>
                        </div>
                    )
                }
            </div>
        )
    }
}

export default ContactDisplay;
