import Spinner from "@legacy/core/components/Spinner";
import debounce from "debounce-promise";
import { Component } from "react";
import deepcopy from "rfdc";
import ContactForm from "../clients/forms/ContactForm";
import { PriceBookItemTypes } from "../core/utils/enums";
import { historyHasState, sendDataToServer, valueIsDefined } from "../core/utils/utils";
import EquipmentForm from "../equipment/forms/EquipmentForm";
import JobDetailsUpdateForm from "./forms/JobDetailsUpdateForm";


const FORM_MODES = {
    EDIT_JOB_DETAILS: "EDIT_JOB_DETAILS",
    ADD_EQUIPMENT: "ADD_EQUIPMENT",
    ADD_CONTACT: "ADD_CONTACT",
    EDIT_CONTACT: "EDIT_CONTACT",
}

const FORM_MODE_SUBTITLES = {
    EDIT_JOB_DETAILS: "Edit Job Details",
    ADD_EQUIPMENT: "Log New Equipment",
    ADD_CONTACT: "Add Contact",
    EDIT_CONTACT: "Edit Contact",
}

const FORM_MODE_BACK_BUTTON_DISPLAY = {
    EDIT_JOB_DETAILS: "flex",
    ADD_EQUIPMENT: "none",
    ADD_CONTACT: "none",
    EDIT_CONTACT: "none",
}

const PRIMARY_FORM_MODES = [FORM_MODES.EDIT_JOB_DETAILS]
const SECONDARY_FORM_MODES = [
    FORM_MODES.ADD_EQUIPMENT,
    FORM_MODES.ADD_CONTACT,
    FORM_MODES.EDIT_CONTACT,
]

const EQUIPMENT_FORM_MODES = [
    FORM_MODES.ADD_EQUIPMENT,
]

const CONTACT_FORM_MODES = [
    FORM_MODES.ADD_CONTACT,
    FORM_MODES.EDIT_CONTACT,
]

const FORM_DATA_NAMES_BY_MODE = {
    EDIT_JOB_DETAILS: "jobData",
    ADD_EQUIPMENT: "equipmentData",
    ADD_CONTACT: "contactData",
    EDIT_CONTACT: "contactData",
}

const SUBMITTING_NAMES_BY_MODE = {
    EDIT_JOB_DETAILS: "submittingJob",
    ADD_EQUIPMENT: "submittingEquipment",
    ADD_CONTACT: "submittingContact",
    EDIT_CONTACT: "submittingContact",
}

const ERROR_NAMES_BY_MODE = {
    EDIT_JOB: "job",
    ADD_EQUIPMENT: "equipment",
    ADD_CONTACT: "contact",
    EDIT_CONTACT: "contact",
}


class JobDetailsUpdateContainer extends Component {

    // Initialize

    constructor(props) {
        super(props)

        const defaultMode = this.props.formMode || FORM_MODES.EDIT_JOB_DETAILS
        this.addToastToQueue = this.props.addToastToQueue
        this.duplicateEquipmentCache = {}
        this.workingTechnicianOptions = window.WORKING_TECHNICIANS || []

        this.state = {
            jobData: null,
            jobChildEstimatesData: null,
            jobChildCallbacksData: null,
            jobChildInvoicesData: null,
            equipmentData: {},
            contactData: {},

            selectedClient: null,
            selectedServiceLocation: null,
            selectedEquipment: [],
            selectedReporter: null,
            selectedPointOfContact: null,

            duplicateEquipmentData: [],

            attachments: [],

            errors: {
                job: {},
                equipment: {},
                contact: {},
            },

            defaultMode: defaultMode,
            mode: defaultMode,

            inventoryUsed: false,

            equipmentCategoryOptions: window.EQUIPMENT_CATEGORIES || [],
            equipmentTypeOptions: window.EQUIPMENT_TYPES || [],
            ownershipTypeOptions: window.OWNERSHIP_TYPES || [],

            preferredTimezone: window.PREFERRED_TIMEZONE,
            phoneNumberCountry: window.PHONE_NUMBER_COUNTRY,

            fileStackAPIKey: window.FILESTACK_API_KEY,
            fileStackPolicy: window.FILESTACK_POLICY,
            fileStackSignature: window.FILESTACK_SIGNATURE,

            returnScroll: 0,
        }

        window.onpopstate = (event) => {
            if (event.state !== null && Object.keys(event.state).length) {
                this.setState(event.state)
            }
        }
    }

    componentDidMount = async () => {
        if (this.state.jobData === null) {
            const endpoint = DjangoUrls["jobs:api-jobs-detail"](window.MARKETPLACE_ENTITY_SLUG, window.JOB_ID)
            const response = await fetch(endpoint)

            let job

            if (response.ok) {
                job = await response.json()

                const parts = job.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.part)
                let inventoryUsed = false

                if (parts.length && window.USING_PUBLIC_URL === false) {
                    let params = new URLSearchParams()
                    params.append("line_item_type", PriceBookItemTypes.part)
                    params.append("track_inventory", true)
                    params.append("confirmed", true)

                    parts.forEach(part => params.append("description", part.description))

                    const inventoryPartsEndpoint = DjangoUrls["pricebook:api-pricebookitem-lightweight-list"](window.MARKETPLACE_ENTITY_SLUG) + `?${params}`
                    const inventoryPartsResponse = await fetch(inventoryPartsEndpoint)
                    const inventoryPartsResponseJSON = await inventoryPartsResponse.json()

                    inventoryUsed = inventoryPartsResponseJSON.results.length !== 0
                }

                this.setState((state, props) => {
                    let updatedState = state

                    updatedState.selectedClient = job.service_location.external_client
                    updatedState.selectedServiceLocation = job.service_location
                    updatedState.selectedEquipment = job.equipment

                    updatedState.attachments = deepcopy()(job.attachments)

                    // Because contacts are based on the service location, we can't just pull a full list and find a match.
                    if ([job.reporter_name, job.reporter_phone, job.reporter_email].some(detail => detail !== "")) {
                        updatedState.selectedReporter = {
                            "composite_key": `${job.reporter_name}_${job.reporter_phone}_${job.reporter_phone_extension}_${job.reporter_email}`,
                            "name": job.reporter_name,
                            "phone": job.reporter_phone,
                            "phone_extension": job.reporter_phone_extension,
                            "email": job.reporter_email,
                        }
                    }
                    if ([job.point_of_contact_name, job.point_of_contact_phone, job.point_of_contact_email].some(detail => detail !== "")) {
                        updatedState.selectedPointOfContact = {
                            "composite_key": `${job.point_of_contact_name}_${job.point_of_contact_phone}_${job.point_of_contact_phone_extension}_${job.point_of_contact_email}`,
                            "name": job.point_of_contact_name,
                            "phone": job.point_of_contact_phone,
                            "phone_extension": job.point_of_contact_phone_extension,
                            "email": job.point_of_contact_email,
                        }
                    }

                    updatedState.jobData = job

                    // Split line items into their respective lists
                    updatedState.jobData.service_charges = job.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.service)
                    updatedState.jobData.parts = parts
                    updatedState.jobData.other_charges = job.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.other)
                    updatedState.jobData.discounts = job.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.discount)

                    updatedState.inventoryUsed = inventoryUsed

                    // Convert service location to ID
                    updatedState.jobData.service_location = job.service_location.id

                    // Convert assigned technician objects to IDs
                    const assignedTechnicianIDs = job.assigned_technicians.map(technician => technician.id)
                    updatedState.jobData.assigned_technicians = assignedTechnicianIDs

                    // Convert equipment to IDs
                    const equipmentIDs = job.equipment.map(equipment => equipment.id)
                    updatedState.jobData.equipment = equipmentIDs

                    // Convert attachments to ids
                    updatedState.jobData.estimate = job.estimate ? job.estimate.id : null
                    updatedState.jobData.callback_to = job.callback_to ? job.callback_to.id : null
                    updatedState.jobData.series_origin = job.series_origin ? job.series_origin.id : null

                    return updatedState
                })
            }
        }

        if (window.USER_TYPE === "SERVICE_DISPATCHER" && this.state.jobData !== null && this.state.jobData.is_job_walk && this.state.jobChildEstimatesData === null && !(window.USING_PUBLIC_URL === true)) {
            let jobChildEstimates = []

            let jobChildEstimateEndpoint
            let jobChildEstimateResponse
            let jobChildEstimateResponseJSON = {
                next: `${DjangoUrls["estimates:api-estimates-list"](window.MARKETPLACE_ENTITY_SLUG)}?job_walk=${this.state.jobData.id}`,
                previous: null,
                results: [],
            }

            while (jobChildEstimateResponseJSON.next !== null) {
                jobChildEstimateEndpoint = jobChildEstimateResponseJSON.next
                jobChildEstimateResponse = await fetch(jobChildEstimateEndpoint)
                jobChildEstimateResponseJSON = await jobChildEstimateResponse.json()
                jobChildEstimates.push(...jobChildEstimateResponseJSON.results)
            }

            this.setState((state, props) => {
                let updatedState = state
                updatedState.jobChildEstimatesData = jobChildEstimates
                return updatedState
            })
        }

        if (window.USER_TYPE === "SERVICE_DISPATCHER" && this.state.jobData !== null && !this.state.jobData.is_job_walk && this.state.jobChildCallbacksData === null && !(window.USING_PUBLIC_URL === true)) {
            let jobChildCallbacks = []

            let jobChildCallbacksEndpoint
            let jobChildCallbacksResponse
            let jobChildCallbacksResponseJSON = {
                next: `${DjangoUrls["jobs:api-jobs-list"](window.MARKETPLACE_ENTITY_SLUG)}?callback_to=${this.state.jobData.id}`,
                previous: null,
                results: [],
            }

            while (jobChildCallbacksResponseJSON.next !== null) {
                jobChildCallbacksEndpoint = jobChildCallbacksResponseJSON.next
                jobChildCallbacksResponse = await fetch(jobChildCallbacksEndpoint)
                jobChildCallbacksResponseJSON = await jobChildCallbacksResponse.json()
                jobChildCallbacks.push(...jobChildCallbacksResponseJSON.results)
            }

            this.setState((state, props) => {
                let updatedState = state
                updatedState.jobChildCallbacksData = jobChildCallbacks
                return updatedState
            })
        }

        if (window.USER_TYPE === "SERVICE_DISPATCHER" && this.state.jobData !== null && !this.state.jobData.is_job_walk && this.state.jobChildInvoicesData === null && !(window.USING_PUBLIC_URL === true)) {
            let jobChildInvoices = []

            let jobChildInvoicesEndpoint
            let jobChildInvoicesResponse
            let jobChildInvoicesResponseJSON = {
                next: `${DjangoUrls["invoices:api-invoices-list"](window.MARKETPLACE_ENTITY_SLUG)}?job=${this.state.jobData.id}`,
                previous: null,
                results: [],
            }

            while (jobChildInvoicesResponseJSON.next !== null) {
                jobChildInvoicesEndpoint = jobChildInvoicesResponseJSON.next
                jobChildInvoicesResponse = await fetch(jobChildInvoicesEndpoint)
                jobChildInvoicesResponseJSON = await jobChildInvoicesResponse.json()
                jobChildInvoices.push(...jobChildInvoicesResponseJSON.results)
            }

            this.setState((state, props) => {
                let updatedState = state
                updatedState.jobChildInvoicesData = jobChildInvoices
                return updatedState
            })
        }

        if (historyHasState(history)) {
            document.querySelector(".page-subtitle").innerHTML = FORM_MODE_SUBTITLES[history.state.mode]
            document.querySelector(".back-button").style.display = FORM_MODE_BACK_BUTTON_DISPLAY[history.state.mode]
            this.setState(history.state)
        }

        this.fetchDuplicateEquipment()
    }

    // Form helpers

    updateFormData = (formName, fieldName, fieldValue) => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState[formName][fieldName] = fieldValue
            return updatedState
        }, this.fetchDuplicateEquipment)
    }

    switchFormMode = (mode) => {
        document.querySelector(".page-subtitle").innerHTML = FORM_MODE_SUBTITLES[mode]
        document.querySelector(".back-button").style.display = FORM_MODE_BACK_BUTTON_DISPLAY[mode]

        if (SECONDARY_FORM_MODES.includes(mode)) {
            history.replaceState(this.state, "", "")
        }

        this.setState((state, props) => {
            let updatedState = state
            updatedState.mode = mode
            history.pushState(updatedState, "", "?mode=" + mode.toLowerCase().replace(/_/g, "-"));
            return updatedState
        })
    }

    switchToPrimaryForm = () => {
        this.setState((state, props) => {
            let updatedState = state

            // Clear the secondary form data
            updatedState[FORM_DATA_NAMES_BY_MODE[state.mode]] = {}
            updatedState[SUBMITTING_NAMES_BY_MODE[state.mode]] = false
            updatedState.errors[ERROR_NAMES_BY_MODE[state.mode]] = {}

            return updatedState
        }, this.fetchDuplicateEquipment)
        this.switchFormMode(this.state.defaultMode)
    }

    switchToSecondaryForm = (newFormMode, data, initialData) => {
        this.setState((state, props) => {
            let updatedState = state
            // Set the scroll state
            updatedState.returnScroll = document.querySelector(".main").scrollTop

            updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]] = {}

            if (data !== null) {
                updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]] = deepcopy()(data)
            }
            else {
                if (EQUIPMENT_FORM_MODES.includes(newFormMode)) {
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].service_location = state.jobData.service_location  // Set the service location id on the new equipment

                    // Auto-set if there's only one option; the associated field is disabled if there's only one as well
                    const valueOptions = state.equipmentCategoryOptions.filter(option => option.value !== "")
                    if (valueOptions.length == 1) {
                        updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].equipment_category = valueOptions[0].value
                    }
                }
                else if (newFormMode === FORM_MODES.ADD_CONTACT) {
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].attached_to = "service_location"
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].is_ephemeral = false
                }
            }

            if (initialData !== null) {
                Object.assign(updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]], initialData)
            }

            return updatedState
        })

        this.switchFormMode(newFormMode)
    }

    updateEquipmentSelection = (selectedEquipment) => {
        this.setState((state, props) => {
            let updatedState = state

            updatedState.selectedEquipment = selectedEquipment
            updatedState.jobData.equipment = selectedEquipment.map(equipment => equipment.id)

            return updatedState
        })
    }

    updateReporterSelection = (selectedContact) => {
        this.setState((state, props) => {
            let updatedState = state

            if (selectedContact !== null) {
                updatedState.selectedReporter = selectedContact

                updatedState.jobData.reporter_name = selectedContact.name
                updatedState.jobData.reporter_phone = selectedContact.phone
                updatedState.jobData.reporter_phone_extension = selectedContact.phone_extension
                updatedState.jobData.reporter_email = selectedContact.email
            }
            else {
                updatedState.selectedReporter = null

                updatedState.jobData.reporter_name = ""
                updatedState.jobData.reporter_phone = ""
                updatedState.jobData.reporter_phone_extension = ""
                updatedState.jobData.reporter_email = ""
            }

            return updatedState
        })
    }

    updateAttachments = (attachmentUploadData) => {
        this.setState((state, props) => {
            let updatedState = state

            updatedState.attachments.push(...attachmentUploadData)
            updatedState.jobData.attachments.push(...attachmentUploadData)

            return updatedState
        })
    }

    updatePointOfContactSelection = (selectedContact) => {
        this.setState((state, props) => {
            let updatedState = state

            if (selectedContact !== null) {
                updatedState.selectedPointOfContact = selectedContact

                updatedState.jobData.point_of_contact_name = selectedContact.name
                updatedState.jobData.point_of_contact_phone = selectedContact.phone
                updatedState.jobData.point_of_contact_phone_extension = selectedContact.phone_extension
                updatedState.jobData.point_of_contact_email = selectedContact.email
            }
            else {
                updatedState.selectedPointOfContact = null

                updatedState.jobData.point_of_contact_name = ""
                updatedState.jobData.point_of_contact_phone = ""
                updatedState.jobData.point_of_contact_phone_extension = ""
                updatedState.jobData.point_of_contact_email = ""
            }

            return updatedState
        })
    }

    // Update Job Details

    updateJobDetails = async () => {
        const endpoint = DjangoUrls["jobs:api-jobs-detail"](window.MARKETPLACE_ENTITY_SLUG, window.JOB_ID)
        const endpointMethod = "PUT"
        const successUrl = DjangoUrls["jobs:jobs-detail"](window.MARKETPLACE_ENTITY_SLUG, window.JOB_ID)

        const dataName = "jobData"
        const submittingName = "submittingJob"
        const errorDictName = "job"

        const onSuccess = (job) => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Job "${job.custom_id || job.id}" details updated`,
                path: successUrl.split("?")[0],
                barePathOnly: true,
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }

        const dataManipulator = (data, state) => {
            // Convert blank Job ID value to null
            data.custom_id = data.custom_id || null

            // Set a blank tag list if there aren't any selected
            data.tags = data.tags || []

            return data
        }

        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Job details could not be updated",
            })
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, dataManipulator, undefined)
    }

    // Crud equipment

    createEquipment = async () => {
        const endpoint = DjangoUrls["equipment:api-service-location-equipment-list"](window.MARKETPLACE_ENTITY_SLUG, this.state.selectedServiceLocation.id)
        const endpointMethod = "POST"

        const onSuccess = (equipment) => {
            this.updateEquipmentSelection([...this.state.selectedEquipment, equipment])
            this.switchToPrimaryForm()
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Equipment "${equipment.display_name}" created`,
            })
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Equipment could not be created",
            })
        }

        this.createUpdateEquipment(endpoint, endpointMethod, onSuccess, onError)
    }

    createUpdateEquipment = async (endpoint, endpointMethod, onSuccess, onError) => {
        const dataName = "equipmentData"
        const submittingName = "submittingEquipment"
        const errorDictName = "equipment"

        const dataManipulator = (data, state) => {
            data.equipment_category = data.equipment_category || null
            data.equipment_type = data.equipment_type || null
            data.warranties = data.warranties || []

            if (data.ownership_type === "") {
                delete data.ownership_type
            }

            return data
        }

        const setErrors = (fieldName, message, errorDict) => {
            if (fieldName === "non_field_errors" && message === "The fields service_location, display_name must make a unique set.") {
                errorDict["display_name"] = "A piece of equipment with this name at this service location already exists."
            }
            else if (fieldName === "non_field_errors" && message === "There are other pieces of equipment that belong to this Client with the same manufacturer, model number, and serial number.") {
                errorDict["identifying_info"] = "Please ensure this equipment's manufacturer, model number, and serial number are unique for this Client."
            }
            else if (fieldName === "warranties") {
                errorDict["warranties"] = "Please correct the warranty errors below:"

                // Apply the nested errors
                this.setState((state, props) => {
                    let updatedState = state
                    message.map((warrantyError, index) => updatedState[FORM_DATA_NAMES_BY_MODE[state.mode]].warranties[index].errors = warrantyError)
                    return updatedState
                })
            }
            else {
                errorDict[fieldName] = message
            }
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, dataManipulator, setErrors)
    }

    fetchDuplicateEquipment = debounce(
        async () => {
            this.setState((state, props) => {
                let updatedState = state
                updatedState.duplicateEquipmentData = []
                return updatedState
            })

            if (valueIsDefined(this.state.equipmentData.manufacturer) && valueIsDefined(this.state.equipmentData.model_number) && valueIsDefined(this.state.equipmentData.serial_number)) {
                const params = new URLSearchParams({
                    "manufacturer": this.state.equipmentData.manufacturer,
                    "model_number": this.state.equipmentData.model_number,
                    "serial_number": this.state.equipmentData.serial_number,
                })

                const endpoint = DjangoUrls["equipment:api-equipment-duplicates-list"](window.MARKETPLACE_ENTITY_SLUG, this.state.selectedClient.id) + `?${params.toString()}`
                let duplicateEquipment

                if (params.toString() in this.duplicateEquipmentCache) {
                    duplicateEquipment = this.duplicateEquipmentCache[params.toString()]
                }
                else {
                    const duplicateEquipmentResponse = await fetch(endpoint)
                    duplicateEquipment = await duplicateEquipmentResponse.json()
                    this.duplicateEquipmentCache[params.toString()] = duplicateEquipment
                }

                this.setState((state, props) => {
                    let updatedState = state
                    updatedState.duplicateEquipmentData = duplicateEquipment.filter(equipment => equipment.id !== this.state.equipmentData.id)
                    return updatedState
                })
            }
        }, 500
    )

    // Crud Contact

    createContact = async () => {
        const attachedTo = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]].attached_to
        const populationRef = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]].populationRef

        const contactData = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]]

        let endpoint

        if (attachedTo === "external_client") {
            endpoint = DjangoUrls["clients:api-clients-contacts-list"](window.MARKETPLACE_ENTITY_SLUG, this.state.selectedClient.id)
        }
        else {
            endpoint = DjangoUrls["clients:api-clients-service-locations-contacts-list"](window.MARKETPLACE_ENTITY_SLUG, this.state.selectedClient.id, this.state.selectedServiceLocation.id)
        }

        const endpointMethod = "POST"

        const onSuccess = (contact) => {
            this[populationRef](contact)
            this.switchToPrimaryForm()
            this.addToastToQueue({
                type: "success",
                size: contact.is_ephemeral ? "lg" : "md",
                title: `Contact ${contact.is_ephemeral ? "added" : "created"}`,
                subtitle: `This contact will not be saved to the contact list`
            })
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: `Contact could not be ${contactData.is_ephemeral ? "added" : "created"}`,
            })
        }

        this.createUpdateContact(endpoint, endpointMethod, onSuccess, onError)
    }

    updateContact = async () => {
        const attachedTo = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]].attached_to
        const populationRef = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]].populationRef
        const dataRef = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]].dataRef
        let endpoint

        if (attachedTo === "external_client") {
            endpoint = DjangoUrls["clients:api-clients-contacts-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.selectedClient.id, this.state[dataRef].id)
        }
        else {
            endpoint = DjangoUrls["clients:api-clients-service-locations-contacts-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.selectedClient.id, this.state.selectedServiceLocation.id, this.state[dataRef].id)
        }

        const endpointMethod = "PUT"
        const onSuccess = (contact) => {
            this[populationRef](contact)
            this.switchToPrimaryForm()
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: "Contact updated",
            })
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Contact could not be updated",
            })
        }

        this.createUpdateContact(endpoint, endpointMethod, onSuccess, onError)
    }

    createUpdateContact = async (endpoint, endpointMethod, onSuccess, onError) => {
        const dataName = "contactData"
        const submittingName = "submittingContact"
        const errorDictName = "contact"

        const setErrors = (fieldName, message, errorDict) => {
            if (fieldName === "non_field_errors" && message.endsWith("name, phone, phone_extension, email must make a unique set.")) {
                errorDict["non_field_error"] = "A contact with these details already exists."
            }
            else {
                errorDict[fieldName] = message
            }
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, undefined, setErrors)
    }

    // Handle Actions

    handleActionRequest = (action) => {
        switch (action) {
            case "JOB_DETAILS_UPDATE":
                this.updateJobDetails()
                break
            case "JOB_DETAILS_CANCEL_EDITS":
                location.assign(DjangoUrls["jobs:jobs-detail"](window.MARKETPLACE_ENTITY_SLUG, window.JOB_ID))
                break
            case "EQUIPMENT_CREATE":
                this.createEquipment()
                break
            case "CONTACT_CREATE":
                this.createContact()
                break
            case "CONTACT_UPDATE":
                const dataRef = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]].dataRef

                if (this.state[dataRef].is_ephemeral) {
                    this.createContact()
                }
                else {
                    this.updateContact()
                }
                break
            case "CONTACT_CANCEL_CREATE":
            case "CONTACT_CANCEL_EDITS":
                this.switchToPrimaryForm()
                break
            default:
                console.error(`No action handler exists for action "${action}".`)
        }
    }

    // Render

    render() {
        if (this.state.jobData === null) {
            return <Spinner centered={true} />
        }
        else {
            if (PRIMARY_FORM_MODES.includes(this.state.mode)) {
                return <JobDetailsUpdateForm
                    mode={this.state.mode}
                    submitting={this.state.submittingJob}
                    job={this.state.jobData}
                    errors={this.state.errors.job}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("jobData", fieldName, fieldValue)}
                    requestAction={this.handleActionRequest}
                    switchToSecondaryForm={this.switchToSecondaryForm}
                    updateEquipmentSelection={this.updateEquipmentSelection}
                    updateReporterSelection={this.updateReporterSelection}
                    updatePointOfContactSelection={this.updatePointOfContactSelection}
                    selectedClient={this.state.selectedClient}
                    selectedServiceLocation={this.state.selectedServiceLocation}
                    selectedEquipment={this.state.selectedEquipment}
                    selectedReporter={this.state.selectedReporter}
                    selectedPointOfContact={this.state.selectedPointOfContact}
                    preferredTimezone={this.state.preferredTimezone}
                    defaultCountryCode={this.state.phoneNumberCountry}
                    inventoryUsed={this.state.inventoryUsed}
                    childEstimates={this.state.jobChildEstimatesData || []}
                    childCallbacks={this.state.jobChildCallbacksData || []}
                    childInvoices={this.state.jobChildInvoicesData || []}
                    fileStackAPIKey={this.state.fileStackAPIKey}
                    fileStackPolicy={this.state.fileStackPolicy}
                    fileStackSignature={this.state.fileStackSignature}
                    updateAttachments={this.updateAttachments}
                    returnScroll={this.state.returnScroll}
                    workingTechnicianOptions={this.workingTechnicianOptions}
                ></JobDetailsUpdateForm>
            }
            else if (EQUIPMENT_FORM_MODES.includes(this.state.mode)) {
                return <EquipmentForm
                    mode={this.state.mode}
                    submitting={this.state.submittingEquipment}
                    equipment={this.state.equipmentData}
                    errors={this.state.errors.equipment}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("equipmentData", fieldName, fieldValue)}
                    requestAction={this.handleActionRequest}
                    switchToPrimaryForm={this.switchToPrimaryForm}
                    equipmentCategoryOptions={this.state.equipmentCategoryOptions}
                    equipmentTypeOptions={this.state.equipmentTypeOptions}
                    ownershipTypeOptions={this.state.ownershipTypeOptions}
                    duplicateEquipment={this.state.duplicateEquipmentData}
                    returnScroll={0}
                ></EquipmentForm>
            }
            else if (CONTACT_FORM_MODES.includes(this.state.mode)) {
                return <ContactForm
                    mode={this.state.mode}
                    submitting={this.state.submittingContact}
                    contact={this.state.contactData}
                    errors={this.state.errors.contact}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("contactData", fieldName, fieldValue)}
                    requestAction={this.handleActionRequest}
                    switchToPrimaryForm={this.switchToPrimaryForm}
                    returnScroll={0}
                ></ContactForm>
            }
            else {
                return (
                    <div className="data-panel-container data-panel-container--with-margin">
                        <div className="data-panel" aria-label="Unknown Form Mode">
                            <div className="data-panel__form">
                                <p className="data-panel__form__caption">An unhandled form mode was supplied.</p>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    }
}

export default JobDetailsUpdateContainer;
