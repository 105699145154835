import React, { Component } from "react";


class HorizontalLayout extends Component {

    render() {
        return (
            <div className="data-panel__form__row">
                {this.props.children}
            </div>
        )
    }
}

export default HorizontalLayout;
