import useUser from "@hooks/useUser"

import useJobTimelineViewBryntumInstances from "./useJobTimelineViewBryntumInstances"
import useJobTimelineViewStates from "./useJobTimelineViewStates"
import useJobTimelineViewTimeRanges from "./useJobTimelineViewTimeRanges"

export default function useJobTimelineViewSchedulerConfig() {
    const { user } = useUser()
    const preferredTimezone = user?.service_company?.preferred_timezone

    const { schedulerPro } = useJobTimelineViewBryntumInstances()

    const { getTimeRanges } = useJobTimelineViewTimeRanges()

    const { currentDate } = useJobTimelineViewStates()

    const configureSchedulerTimezone = () => {
        return new Promise<void>((resolve) => {
            schedulerPro.current.instance.timeZone = preferredTimezone

            void schedulerPro.current.instance.project.commitAsync().then(() => {
                resolve()
            })
        })
    }

    const configureSchedulerDates = () => {
        const timeRange = getTimeRanges(currentDate)

        schedulerPro.current.instance.setStartDate(timeRange?.midnight)
        schedulerPro.current.instance.setEndDate(timeRange?.nextDayAtMidnight)
    }

    return {
        configureSchedulerTimezone,
        configureSchedulerDates,
    }
}
