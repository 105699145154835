import React, { Component } from 'react';


class BasicSelectField extends Component {

    render() {
        const {fieldName, fieldLabel, fieldValue, fieldOnChange, choices, disabled=false, optional=false, optionalText="optional", errors} = this.props

        return (
            <div className="data-panel__form__field" id={`div_id_${fieldName}`} aria-label={fieldLabel}>
                <label htmlFor={`id_${fieldName}`} className="data-panel__form__field__label in-form">{fieldLabel}{optional && <span className="text-optional"> ({optionalText})</span>}</label>
                <div className="data-panel__form__field__input" title={disabled && fieldValue ? (choices.find(choice => String(choice.value) === fieldValue) || {label: null}).label : null}>
                    <select
                        defaultValue={fieldValue}
                        onChange={event => fieldOnChange(event.target.value)}
                        id={`id_${fieldName}`}
                        name={fieldName}
                        className={disabled === true ? "is-disabled" : ""}
                        disabled={disabled === true}
                        autoComplete="off"
                    >
                        {choices.map((choice, index) => <option key={index} value={choice.value}>{choice.label}</option>)}
                    </select>
                </div>
                {errors[fieldName] && <div className="data-panel__form__field__errors" aria-label="Field Errors">{errors[fieldName]}</div>}
            </div>
        )
    }
}

export default BasicSelectField;
