import React, { Component } from 'react';
import PhoneInput from 'react-phone-number-input/input';


class PhoneNumberField extends Component {

    render() {
        const {fieldName, fieldLabel, fieldValue, fieldOnChange, extensionFieldName, extensionFieldLabel, extensionFieldValue, extensionOnChange, defaultCountryCode, disabled=false, optional=false, optionalText="optional", errors, showExtensionField} = this.props

        return (
            <div className="data-panel__form__field" id={`div_id_${fieldName}`} aria-label={fieldLabel}>
                <label htmlFor={`id_${fieldName}`} className="data-panel__form__field__label in-form">{fieldLabel}{optional && <span className="text-optional"> ({optionalText})</span>}</label>
                <div className="data-panel__form__field__input-group" title={disabled && fieldValue ? `${fieldValue}${extensionFieldValue ? `x${extensionFieldValue}` : ""}` : null}>
                    <div className="data-panel__form__field__input" style={showExtensionField !== true ? {flexBasis: "100%"} : {}}>
                        <PhoneInput
                            value={fieldValue}
                            defaultCountry={defaultCountryCode}
                            onChange={fieldOnChange}
                            autoComplete="off"
                            id={`id_${fieldName}`}
                            name={fieldName}
                            maxLength="128"
                            placeholder="Number"
                            className={disabled ? "is-disabled" : ""}
                            disabled={disabled}
                        ></PhoneInput>
                    </div>
                    {
                        showExtensionField && (
                            <div className="data-panel__form__field__input data-panel__form__field__input--phone-extension">
                                <input
                                    type="text"
                                    name={extensionFieldName}
                                    maxLength="20"
                                    defaultValue={extensionFieldValue}
                                    onChange={event => extensionOnChange(event.target.value)}
                                    id={`id_${extensionFieldName}`}
                                    autoComplete="off"
                                    placeholder={extensionFieldLabel}
                                    className={disabled ? "is-disabled" : ""}
                                    disabled={disabled}
                                />
                            </div>
                        )
                    }
                </div>
                {errors[fieldName] && <div className="data-panel__form__field__errors" aria-label="Field Errors">{errors[fieldName]}</div>}
                {errors[extensionFieldName] && <div className="data-panel__form__field__errors" aria-label="Field Errors">{errors[extensionFieldName]}</div>}
            </div>
        )
    }
}

export default PhoneNumberField;
