// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PageBreadcrumbs-base__vlGDq{display:none}@media only screen and (min-width: 64rem){.PageBreadcrumbs-base__vlGDq{display:block;margin-left:calc(var(--size-2_5)*-1);padding-top:var(--size-8);padding-bottom:var(--size-6)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "PageBreadcrumbs-base__vlGDq"
};
export default ___CSS_LOADER_EXPORT___;
