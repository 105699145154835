// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DownPaymentCell-base__GoYjD{display:flex;gap:var(--size-1_5);align-items:center;justify-content:flex-start;width:var(--size-full);padding:var(--size-3_5) var(--size-4);font:var(--font-global-body-400);font-feature-settings:var(--feature-settings-global-body-400);letter-spacing:var(--letter-spacing-global-body-400)}.DownPaymentCell-not-required__W1wDu{color:var(--gray-9)}.DownPaymentCell-paid__admeu{color:var(--jade-10)}.DownPaymentCell-partially-paid__LIgC0{color:var(--amber-10)}.DownPaymentCell-unpaid__l02NL{color:var(--tomato-9)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "DownPaymentCell-base__GoYjD",
	"not-required": "DownPaymentCell-not-required__W1wDu",
	"notRequired": "DownPaymentCell-not-required__W1wDu",
	"paid": "DownPaymentCell-paid__admeu",
	"partially-paid": "DownPaymentCell-partially-paid__LIgC0",
	"partiallyPaid": "DownPaymentCell-partially-paid__LIgC0",
	"unpaid": "DownPaymentCell-unpaid__l02NL"
};
export default ___CSS_LOADER_EXPORT___;
