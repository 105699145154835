import clsx from "clsx"

import styles from "./HotKeyStack.module.scss"
import { HotKeyStackProps } from "./HotKeyStack.types"

export default function HotKeyStack(props: HotKeyStackProps) {
    const { children, className } = props

    return <div className={clsx(styles.base, className)}>{children}</div>
}
