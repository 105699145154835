import Spinner from "@legacy/core/components/Spinner";
import { Component, Fragment } from "react";
import ButtonGroup from "../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../core/buttons/UniversalButton";
import BasicDisplayField from "../../core/fields/BasicDisplayField";
import TextField from "../../core/fields/TextField";
import { formatCurrencyForServiceCompany } from "../../core/utils/utils";
import { calculateEstimateAmounts } from "../utils/utils";


class EstimateApproveForm extends Component {

    componentDidMount() {
        // Return to the previous scroll
        document.querySelector(".main").scrollTo(0, this.props.returnScroll || 0)
    }

    renderButtons = () => {
        const {estimate, approveData, requestAction, switchToPrimaryForm, submitting, errors, onFormDataChange, returnScroll} = this.props

        if (errors.unexpectedError) {
            return (
                <div className="data-panel__action-feedback">
                    <span className="text-invalid"><strong>An unexpected error occurred.</strong></span>
                </div>
            )
        }
        else {
            if (submitting) {
                return <Spinner centered={true} />
            }
            else {
                return (
                    <ButtonGroup>
                        <ButtonGroupRow>
                            <UniversalButton type="primary" text="Approve" handler={event => requestAction("ESTIMATE_APPROVE_SUBMIT")} />
                            <UniversalButton type="secondary" text="Cancel" handler={event => switchToPrimaryForm()} />
                        </ButtonGroupRow>
                    </ButtonGroup>
                )
            }
        }
    }

    render() {
        const {estimate, approveData, requestAction, switchToPrimaryForm, submitting, errors, onFormDataChange, returnScroll} = this.props
        const {subtotal, total, downPaymentAmountPaid, downPaymentAmountDue} = calculateEstimateAmounts(estimate)

        return (
            <div className="data-panel-container data-panel-container--with-margin">
                <div className="data-panel" aria-label="Estimate Approve">
                    <div className="data-panel__form" aria-label="Estimate Approve Form">
                        <p className="data-panel__form__caption">Please confirm that you'd like to approve this estimate.</p>
                        <BasicDisplayField
                            fieldName="id"
                            fieldLabel="Roopairs Estimate ID"
                            fieldValue={estimate.custom_id ? estimate.custom_id : estimate.id}
                        ></BasicDisplayField>
                        <BasicDisplayField
                            fieldName="service_name"
                            fieldLabel="Service Type"
                            fieldValue={estimate.service_name}
                        ></BasicDisplayField>
                        <BasicDisplayField
                            fieldName="total"
                            fieldLabel="Total"
                            fieldValue={formatCurrencyForServiceCompany(total, estimate.service_company)}
                        ></BasicDisplayField>
                        {
                            parseInt(estimate.down_payment_amount) !== 0 && (
                                <Fragment>
                                    <BasicDisplayField
                                        fieldName="down_payment_amount"
                                        fieldLabel="Down Payment"
                                        fieldValue={formatCurrencyForServiceCompany(estimate.down_payment_amount, estimate.service_company)}
                                    ></BasicDisplayField>
                                    <BasicDisplayField
                                        fieldName="down_payment_amount_due"
                                        fieldLabel="Amount Due Now"
                                        fieldValue={formatCurrencyForServiceCompany(downPaymentAmountDue, estimate.service_company)}
                                    ></BasicDisplayField>
                                </Fragment>
                            )
                        }
                        <TextField
                            fieldName="approve_message"
                            fieldLabel="Comments"
                            fieldValue={approveData.approve_message || ""}
                            fieldOnChange={approve_message => onFormDataChange("approve_message", approve_message || "")}
                            rows={3}
                            placeholder="Any comments you'd like to add"
                            optional={true}
                            errors={errors}
                        ></TextField>
                    </div>
                    {this.renderButtons()}
                </div>
            </div>
        )
    }
}

export default EstimateApproveForm;
