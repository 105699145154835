import useIsDesktop from "@hooks/useIsDesktop"

import { Button } from "@atoms/index"

import useAuth from "@pages/Auth/Auth.store"

export default function AuthMethodToggleButton() {
    const isDesktop = useIsDesktop()

    const { authMethod, setAuthMethod, isOnOTPSlide } = useAuth()

    const isUsingEmail = authMethod === "email_and_code" || authMethod === "email_and_password"

    const onClick = () => {
        if (authMethod === "email_and_code") {
            void setAuthMethod("phone_and_code")
        } else if (authMethod === "phone_and_code") {
            void setAuthMethod("email_and_code")
        } else if (authMethod === "email_and_password") {
            void setAuthMethod("phone_and_password")
        } else {
            void setAuthMethod("email_and_password")
        }
    }

    return isOnOTPSlide ? null : (
        <Button
            colorScheme="gray"
            size={isDesktop ? "md" : "lg"}
            onClick={onClick}
            variant="subtle"
            isFullWidth={true}
            data-prevent-input-validation={true}
        >
            Continue with {isUsingEmail ? "phone" : "email"}
        </Button>
    )
}
