// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BottomBarCreateButton-base__Ue7cq{position:relative;display:flex;flex:1;align-items:center;justify-content:center;width:var(--size-full);padding:var(--size-0) var(--size-4);border-radius:var(--border-radius-2)}.BottomBarCreateButton-trigger__atePJ{display:flex;align-items:center;justify-content:center;width:var(--size-11);height:var(--size-11);color:var(--gray-1);background-color:var(--gray-12);border:none;border-radius:var(--border-radius-full);cursor:pointer;transition:background-color var(--duration-normal)}.BottomBarCreateButton-trigger__atePJ:focus-visible:not(.BottomBarCreateButton-isDisabled__aKhPy){background-color:var(--gray-a11);outline:var(--gray-12) solid var(--size-0_5);outline-offset:calc(var(--size-0_5)*-1)}.BottomBarCreateButton-trigger__atePJ:hover:not(.BottomBarCreateButton-isDisabled__aKhPy){background-color:var(--gray-a11)}.BottomBarCreateButton-trigger__atePJ:active:not(.BottomBarCreateButton-isDisabled__aKhPy){background-color:var(--gray-a10)}.BottomBarCreateButton-trigger__atePJ.BottomBarCreateButton-isDisabled__aKhPy{color:var(--gray-a7);background-color:var(--gray-a3);cursor:not-allowed;pointer-events:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "BottomBarCreateButton-base__Ue7cq",
	"trigger": "BottomBarCreateButton-trigger__atePJ",
	"isDisabled": "BottomBarCreateButton-isDisabled__aKhPy"
};
export default ___CSS_LOADER_EXPORT___;
