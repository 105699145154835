import { useLayoutEffect, useRef, useState } from "react"

interface UseIsScrollingProps {
    inHorizontal?: boolean
}

export default function useIsScrolling<T extends HTMLElement>(args: UseIsScrollingProps = {}) {
    const { inHorizontal = false } = args

    const [isScrolling, setIsScrolling] = useState<boolean>(false)
    const [isLeftContentClipped, setIsLeftContentClipped] = useState<boolean>(false)
    const [isRightContentClipped, setIsRightContentClipped] = useState<boolean>(false)

    const ref = useRef<T>(null)

    useLayoutEffect(() => {
        const handleScroll = () => {
            if (ref.current) {
                if (inHorizontal) {
                    const scrollLeft = ref.current.scrollLeft
                    const scrollWidth = ref.current.scrollWidth
                    const clientWidth = ref.current.clientWidth
                    const scrollRight = scrollWidth - scrollLeft - clientWidth

                    setIsRightContentClipped(scrollRight > 0)
                    setIsLeftContentClipped(ref.current.scrollLeft > 0)
                    setIsScrolling(ref.current.scrollLeft > 0)
                } else {
                    setIsScrolling(ref.current.scrollTop > 0)
                }
            }
        }

        if (ref.current) {
            ref.current.addEventListener("scroll", handleScroll)
        }

        return () => {
            if (ref.current) {
                ref.current.removeEventListener("scroll", handleScroll)
            }
        }
    }, [])

    return { isScrolling, isRightContentClipped, isLeftContentClipped, ref }
}
