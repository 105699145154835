import useUser from "@hooks/useUser"

import replaceSlugs from "@utils/replaceSlugs"

import { ObjectsData } from "@organisms/ObjectsView/ObjectsView.types"
import { GetObjectDetailsRouteFunction } from "@organisms/Table/TableViewControls/TableViewTab/TableViewTab.types"

import { PRICEBOOK_ITEM_TYPE_URL_PARAM_MAP } from "@pages/Pricebook/PricebookList/usePricebookTabs"

import { CLIENTS_ROUTES } from "@routes/clients"
import { ESTIMATES_ROUTES } from "@routes/estimates"
import { INVOICES_ROUTES } from "@routes/invoices"
import { JOBS_ROUTES } from "@routes/jobs"
import { PRICEBOOK_ITEMS_ROUTES } from "@routes/pricebook-items"
import { PURCHASE_ORDER_ROUTES } from "@routes/purchase-orders"
import { VENDOR_ROUTES } from "@routes/vendor"

import useSlugExtractor from "./useSlugExtractor"

export type ObjectsKeysMap = {
    [key in ObjectIndexName]: GetObjectDetailsRouteFunction
}

export default function useObjectDetailsRoutes(
    objectName?: ObjectIndexName,
): ObjectsKeysMap | GetObjectDetailsRouteFunction {
    const [
        estimateUpdateRoute,
        estimateDetailsRoute,
        jobUpdateRoute,
        jobDetailsRoute,
        invoiceUpdateRoute,
        invoiceDetailsRoute,
        purchaseOrderUpdateRoute,
        purchaseOrderDetailsRoute,
        vendorDetailsRoute,
        clientDetailsRoute,
        pricebookItemUpdateRoute,
        pricebookItemReviewRoute,
    ] = useSlugExtractor([
        ESTIMATES_ROUTES.UPDATE,
        ESTIMATES_ROUTES.DETAILS,
        JOBS_ROUTES.UPDATE,
        JOBS_ROUTES.DETAILS,
        INVOICES_ROUTES.UPDATE,
        INVOICES_ROUTES.DETAILS,
        PURCHASE_ORDER_ROUTES.UPDATE,
        PURCHASE_ORDER_ROUTES.DETAILS,
        VENDOR_ROUTES.DETAILS,
        CLIENTS_ROUTES.DETAILS,
        PRICEBOOK_ITEMS_ROUTES.UPDATE,
        PRICEBOOK_ITEMS_ROUTES.REVIEW,
    ])

    const { user } = useUser()

    const getObjectDetailsRoute: {
        [key in ObjectIndexName]: (object: ObjectsData) => string
    } = {
        Estimate: (estimate: Estimate) => {
            const pathname = estimate.is_draft ? estimateUpdateRoute : estimateDetailsRoute
            return replaceSlugs(pathname, { id: estimate.id })
        },
        Job: (job: Job) => {
            const pathname =
                job.is_draft && user?.user_type_label === "Service Dispatcher" ? jobUpdateRoute : jobDetailsRoute

            return replaceSlugs(pathname, { id: job.id })
        },
        Invoice: (invoice: Invoice) => {
            const pathname = invoice.is_draft ? invoiceUpdateRoute : invoiceDetailsRoute
            return replaceSlugs(pathname, { id: invoice.id })
        },
        PurchaseOrder: (purchaseOrder: PurchaseOrder) => {
            const pathname = purchaseOrder.is_draft ? purchaseOrderUpdateRoute : purchaseOrderDetailsRoute
            return replaceSlugs(pathname, { id: purchaseOrder.id })
        },
        Vendor: (vendor: Vendor) => {
            return replaceSlugs(vendorDetailsRoute, { id: vendor.id })
        },
        Client: (client: Client) => {
            return replaceSlugs(clientDetailsRoute, { id: client.id })
        },
        PriceBookItem: (pricebookItem: PricebookItem) => {
            const pathname = pricebookItem.confirmed ? pricebookItemUpdateRoute : pricebookItemReviewRoute
            const query = `?type=${PRICEBOOK_ITEM_TYPE_URL_PARAM_MAP[pricebookItem.pricebook_item_type]}`
            return `${replaceSlugs(pathname, { id: String(pricebookItem.id) })}${query}`
        },
    }

    if (objectName) {
        return getObjectDetailsRoute[objectName]
    } else {
        return getObjectDetailsRoute
    }
}
