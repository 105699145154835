import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import clsx from "clsx"
import { useEffect, useRef, useState } from "react"

import useDebounce from "@hooks/useDebounce"

import { Icon, IconButton } from "@atoms"

import styles from "./KeywordSearch.module.scss"
import { KeywordSearchProps } from "./KeywordSearch.types"

const MIN_SEARCH_LENGTH = 2

export default function KeywordSearch(props: KeywordSearchProps) {
    const { defaultValue, onValueChange } = props

    const [value, setValue] = useState<string>(defaultValue)
    const debouncedValue = useDebounce<string>(value, 500)

    const inputRef = useRef<HTMLInputElement>(null)

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value)
    }

    const eraseInput = () => {
        setValue("")
        inputRef.current?.focus()
    }

    useEffect(() => {
        setValue(defaultValue)
    }, [defaultValue])

    useEffect(() => {
        if (debouncedValue.length >= MIN_SEARCH_LENGTH) {
            onValueChange(debouncedValue)
        } else {
            onValueChange("")
        }
    }, [debouncedValue])

    useEffect(() => {
        window.addEventListener("clear-list-search", eraseInput)

        return () => {
            window.removeEventListener("clear-list-search", eraseInput)
        }
    }, [])

    return (
        <div className={styles.base}>
            <div
                className={clsx(styles.innerContainer, {
                    [styles.typed]: debouncedValue.length > 0,
                    [styles.typing]: debouncedValue.length === 0 && value.length > 0,
                })}
            >
                <div className={styles.iconContainer}>
                    <Icon
                        icon={icon({
                            name: "bolt",
                            style: "light",
                            family: "sharp",
                        })}
                        size={14}
                    />
                </div>

                <input
                    value={value}
                    onChange={onInputChange}
                    placeholder="Keyword search"
                    className={styles.input}
                    ref={inputRef}
                />

                {value && (
                    <IconButton
                        colorScheme="gray"
                        size="sm"
                        variant="ghost"
                        className={styles.eraseButton}
                        icon={icon({
                            name: "close",
                            style: "light",
                            family: "sharp",
                        })}
                        onClick={eraseInput}
                        aria-label="Erase input"
                        noDebounce={true}
                    />
                )}
            </div>
        </div>
    )
}
