import { Component } from "react";


class TabbedContainer extends Component {

    constructor(props) {
        super(props)

        this.state = {
            tabbedObjects: {},
            existingTabbedObjectIDs: {},
            filteredTabbedObjects: {},
            nextObjectsEndpoints: {},
            loadingMoreObjects: false,
        }
    }

    filterTabs = (tabs) => {
        return tabs
    }

    filterObjects = (filterKwargs) => {
        this.setState({filteredTabbedObjects: this.state.tabbedObjects})
    }

    getListEndpoint = (tab) => {
        console.log("You must implement TabbedContainer.getListEndpoint()")
    }

    _getResults = async (tab, tabFilterKwargs, baseEndpoint, forceRefresh) => {
        const response = await fetch(baseEndpoint + tabFilterKwargs)
        const json = await response.json()
        return json
    }

    _getPagedResults = async (tab, tabFilterKwargs, baseEndpoint, nextEndpointKey, forceRefresh) => {
        let objectsEndpoint = this.state[nextEndpointKey][tab]

        if (objectsEndpoint === undefined || forceRefresh === true) {
            objectsEndpoint = baseEndpoint + tabFilterKwargs
        }

        let results = []
        let nextObjectsEndpoint = null

        if (objectsEndpoint !== null) {
            const response = await fetch(objectsEndpoint)
            const json = await response.json()
            results = json.results
            nextObjectsEndpoint = json.next
        }

        this.setState((state, props) => {
            let updatedState = state
            updatedState[nextEndpointKey][tab] = nextObjectsEndpoint
            return updatedState
        })

        return results
    }

    _fetchResults = async (tab, tabFilterKwargs, forceRefresh) => {
        const resultsFunc = this.props.usesPagedResults === true ? this._getPagedResults : this._getResults
        return await resultsFunc(tab, tabFilterKwargs, this.getListEndpoint(tab), "nextObjectsEndpoints", forceRefresh)
    }

    fetchObjects = async (tab, tabFilterKwargs, forceRefresh) => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState.loadingMoreObjects = true
            return updatedState
        })

        const results = await this._fetchResults(tab, tabFilterKwargs, forceRefresh)

        if (this.state.existingTabbedObjectIDs[tab] === undefined) {
            this.state.existingTabbedObjectIDs[tab] = new Set()
        }

        let uniqueResults = []

        results.forEach(result => {
            if (!this.state.existingTabbedObjectIDs[tab].has(result.id)) {
                uniqueResults.push(result)
                this.state.existingTabbedObjectIDs[tab].add(result.id)
            }
        })

        this.setState((state, props) => {
            let updatedState = state

            updatedState.tabbedObjects[tab] = (updatedState.tabbedObjects[tab] || []).concat(uniqueResults)
            updatedState.filteredTabbedObjects[tab] = (updatedState.filteredTabbedObjects[tab] || []).concat(uniqueResults)

            updatedState.loadingMoreObjects = false

            return updatedState
        })
    }

    shouldFetchMoreObjects = (tab) => {
        return this.state.nextObjectsEndpoints[tab] !== null
    }

    fetchMoreObjects = (tab) => {
        const {tabFilterMap} = this.props
        this.fetchObjects(tab, tabFilterMap.get(tab))
    }

    componentDidMount = () => {
        const {tabs, tabFilterMap} = this.props

        document.querySelector(".subheader")?.classList?.add("subheader--no-border")

        for (let tab of this.filterTabs(tabs)) {
            this.fetchObjects(tab, tabFilterMap.get(tab))
        }
    }

    renderIdleTimeout = () => {}

    renderFilterInput = () => {
        console.error("You must implement TabbedContainer.renderFilterInput().")
    }

    renderTabSwitcher = () => {
        console.error("You must implement TabbedContainer.renderTabSwitcher().")
    }

    render() {
        const {tabs, tabFilterMap, showFilterInput, usesPagedResults} = this.props

        return (
            <div className="tabbed-container">
                {showFilterInput && (
                    <div className="full-width-bar">
                        {this.renderFilterInput()}
                    </div>
                )}
                {this.renderIdleTimeout()}
                {this.renderTabSwitcher()}
            </div>
        )
    }
}

export default TabbedContainer
