// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TableBodyCell-base__MHoqU{display:flex;flex-grow:1;padding:var(--size-0);background-color:inherit}.TableBodyCell-base__MHoqU.TableBodyCell-sticky__P7_9Q{position:sticky;z-index:var(--z-index-table-sticky-column)}.TableBodyCell-scrolling__Bfrgk.TableBodyCell-lastSticky__PSHrN ::after{position:absolute;top:0;right:0;bottom:0;width:var(--size-0_25);height:var(--size-full);background-color:var(--gray-4);content:\"\"}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "TableBodyCell-base__MHoqU",
	"sticky": "TableBodyCell-sticky__P7_9Q",
	"scrolling": "TableBodyCell-scrolling__Bfrgk",
	"lastSticky": "TableBodyCell-lastSticky__PSHrN"
};
export default ___CSS_LOADER_EXPORT___;
