import { ColumnStore, DateHelper, EventHelper, Model, TimeZoneHelper } from "@bryntum/schedulerpro"
import { BryntumSchedulerPro } from "@bryntum/schedulerpro-react"

import useRouter from "@hooks/useRouter"
import useUser from "@hooks/useUser"

import formatDate from "@utils/formatDate"
import replaceSlugs from "@utils/replaceSlugs"

import {
    Column,
    DateField,
    EventRecord,
    GridClickEvent,
    JobCardClickEvent,
    JobCardDragEvent,
    JobCardDropEvent,
    JobCardPartialResizeEvent,
    JobCardResizeEvent,
    JobUpdatePayload,
    SchedulerClickEvent,
    SchedulerEventModel,
} from "@organisms/ObjectsView/JobTimelineView/JobTimelineView.types"

import { JOBS_ROUTES } from "@routes/jobs"

import useJobTimelineViewBryntumInstances from "./useJobTimelineViewBryntumInstances"
import useJobTimelineViewData from "./useJobTimelineViewData"
import useJobTimelineViewOverlappingUtils from "./useJobTimelineViewOverlappingUtils"
import useJobTimelineViewSchedulerRefresh from "./useJobTimelineViewSchedulerRefresh"
import useJobTimelineViewStates from "./useJobTimelineViewStates"
import useJobTimelineViewTimeRanges from "./useJobTimelineViewTimeRanges"
import useJobTimelineViewUIUtils from "./useJobTimelineViewUIUtils"

export default function useJobTimelineViewEventHandlers() {
    const { user } = useUser()
    const serviceCompanySlug = user?.service_company?.slug
    const preferredTimezone = user?.service_company?.preferred_timezone

    const { schedulerPro, unscheduledJobsGridDrag } = useJobTimelineViewBryntumInstances()

    const {
        setTechniciansColumnStatus,
        timelineViewOrientation,
        setCurrentDate,
        todayInUserTimezone,
        currentDate,
        shouldShowCancelledJobs,
        toggleCancelledJobs,
        shouldShowFinalizedJobs,
        toggleFinalizedJobs,
        setIsSchedulerConfigured,
        setTimelineViewOrientationToVertical,
        setTimelineViewOrientationToHorizontal,
        allowShowMouseMoveFeedback,
        setAllowShowMouseMoveFeedback,
    } = useJobTimelineViewStates()

    const router = useRouter()

    const { getTimeRanges, configureSchedulerTimeRange } = useJobTimelineViewTimeRanges()

    const {
        hideEventFeedback,
        showEventFeedback,
        hideEventMoveFeedback,
        showEventMoveFeedback,
        hideCancelledJobsFromScheduler,
        showCancelledJobsInScheduler,
        hideFinalizedJobsFromScheduler,
        showFinalizedJobsInScheduler,
    } = useJobTimelineViewUIUtils()

    const { handleOverlappingEvents, removeAllOverlappingRegionFeedback, findOverlappingEvents } =
        useJobTimelineViewOverlappingUtils()

    const { syncJobUpdatesWithServer, updateEventRecord, updateEventStore, updateEventInCache, resetTimeRanges } =
        useJobTimelineViewData()

    const { forceEventToRerender } = useJobTimelineViewSchedulerRefresh()

    // Processes technician updates upon event drop
    const processTechnicianUpdates = (event: JobCardDropEvent) => {
        const assignedTechnicians = event.context.eventRecord._data.assigned_technicians
        const technicianToAdd = event.targetResourceRecord._data as unknown as CalendarTechnician
        const technicianToDrop = event.resourceRecord._data

        const isSameTechnician = String(technicianToAdd.id) === String(technicianToDrop.id)

        if (isSameTechnician) {
            return assignedTechnicians
        }

        let newAssignedTechnicians = assignedTechnicians.filter(
            (technician) => String(technician.id) !== String(technicianToDrop.id) && !!technician.id,
        )

        if (String(technicianToAdd.id) !== "unassigned") {
            if (!assignedTechnicians.some((tech) => String(tech.id) === String(technicianToAdd.id))) {
                newAssignedTechnicians.push(technicianToAdd)
            } else {
                newAssignedTechnicians = newAssignedTechnicians.map((technician) =>
                    String(technician.id) === String(technicianToDrop.id) ? technicianToAdd : technician,
                )
            }
        }

        const isJobMultiAssigned = assignedTechnicians.length > 1

        if (technicianToAdd.id === "unassigned" && isJobMultiAssigned) {
            schedulerPro.current.instance.assignmentStore.unassignEventFromResource(
                event.context.eventRecord,
                "unassigned",
            )
        }

        return newAssignedTechnicians
    }

    const onEventDrop = (event: JobCardDropEvent) => {
        removeAllOverlappingRegionFeedback()

        const jobId = event.context.eventRecord.originalData?.id
        const newAssignedTechnicians = processTechnicianUpdates(event)

        const updatedStartDate = TimeZoneHelper.fromTimeZone(
            event.context.eventRecord._data.startDate,
            preferredTimezone,
        )
        const updatedEndDate = TimeZoneHelper.fromTimeZone(event.context.eventRecord._data.endDate, preferredTimezone)

        const newTechniciansIds = newAssignedTechnicians.map((technician) => technician.id)

        void syncJobUpdatesWithServer(jobId, {
            estimated_arrival_time: updatedStartDate,
            estimated_duration: Math.abs((updatedEndDate.getTime() - updatedStartDate.getTime()) / 1000),
            assigned_technicians: newTechniciansIds.filter(Boolean),
            custom_id: event.context.eventRecord.originalData.custom_id,
        } as JobUpdatePayload)

        updateEventStore(event.context.eventRecord._data.id, {
            start_time: updatedStartDate,
            end_time: updatedEndDate,
            assigned_technicians: newAssignedTechnicians,
        } as CalendarEvent)

        updateEventInCache({
            jobId: event.context.eventRecord._data.id,
            technicianToAdd: event.targetResourceRecord._data,
            technicianToDrop: event.resourceRecord._data,
            newData: {
                start_time: updatedStartDate,
                end_time: updatedEndDate,
                assigned_technicians: newAssignedTechnicians,
            } as CalendarEvent,
        })

        updateEventRecord(event.context.eventRecord, {
            isResizingEventStart: false,
            isResizingEventEnd: false,
            isOverlappingOnResize: false,
            resizingEndTime: null,
            resizingStartTime: null,
        })

        hideEventMoveFeedback()
        handleOverlappingEvents()
    }

    const onEventResizeEnd = (event: JobCardResizeEvent) => {
        removeAllOverlappingRegionFeedback()

        const eventStore = schedulerPro.current.instance.eventStore.getById(event.eventRecord.originalData.id)

        updateEventRecord(event.eventRecord, {
            isResizingEventStart: false,
            isResizingEventEnd: false,
            isOverlappingOnResize: false,
        })

        forceEventToRerender(eventStore)

        if (event.changed) {
            const jobId = event.eventRecord.originalData?.id

            const updatedStartDate = TimeZoneHelper.fromTimeZone(event.eventRecord._data.startDate, preferredTimezone)
            const updatedEndDate = TimeZoneHelper.fromTimeZone(event.eventRecord._data.endDate, preferredTimezone)

            const updatedEstimatedDuration = Math.abs((updatedEndDate.getTime() - updatedStartDate.getTime()) / 1000)

            void syncJobUpdatesWithServer(jobId, {
                estimated_arrival_time: updatedStartDate,
                estimated_duration: updatedEstimatedDuration,
                custom_id: event.eventRecord.originalData.custom_id,
            } as JobUpdatePayload)

            updateEventStore(event.eventRecord.originalData.id, {
                start_time: updatedStartDate,
                end_time: updatedEndDate,
                estimated_duration: updatedEstimatedDuration,
            } as CalendarEvent)

            updateEventInCache({
                jobId: event.eventRecord._data.id,
                newData: {
                    start_time: updatedStartDate,
                    end_time: updatedEndDate,
                    estimated_duration: updatedEstimatedDuration,
                } as CalendarEvent,
            })
        }

        handleOverlappingEvents()
    }

    const onDateRangeChange = (newDate: Date) => {
        resetTimeRanges()

        void setCurrentDate(newDate)

        const timeRange = getTimeRanges(newDate)

        configureSchedulerTimeRange(timeRange)
    }

    const onEventClick = (event: JobCardClickEvent) => {
        const jobDetailsPage = replaceSlugs(JOBS_ROUTES.DETAILS, {
            service_company_slug: serviceCompanySlug,
            id: event.eventRecord.originalData?.id,
        })

        router.push(jobDetailsPage)
    }

    const onGridCellClick = (event: GridClickEvent) => {
        const jobDetailsPage = replaceSlugs(JOBS_ROUTES.DETAILS, {
            service_company_slug: serviceCompanySlug,
            id: event.record.originalData?.id,
        })

        router.push(jobDetailsPage)
    }

    const onScheduleClick = (event: SchedulerClickEvent) => {
        if (user.isServiceDispatcher) {
            const jobCreatePage = replaceSlugs(JOBS_ROUTES.CREATE, {
                service_company_slug: serviceCompanySlug,
            })

            const startDate = TimeZoneHelper.fromTimeZone(event.tickStartDate, preferredTimezone).toISOString()
            const endDate = TimeZoneHelper.fromTimeZone(event.tickEndDate, preferredTimezone).toISOString()
            const technicianId = event.resourceRecord.originalData?.id

            const urlWithParams = `${jobCreatePage}?start_date=${startDate}&end_date=${endDate}&technician_id=${technicianId}`

            router.push(urlWithParams)
        }
    }

    const overlappingEventSorter = (a: EventRecord, b: EventRecord) => {
        // this function doesn't sort only the overlapped events,
        // but all events in the row where the overlap happens
        const eventsOverlap = a.endDate > b.startDate && a.startDate < b.endDate

        // first we try to sort by custom_id for events that overlap, if not, we sort by dates
        if (eventsOverlap && a.custom_id && b.custom_id) {
            return a.custom_id.localeCompare(b.custom_id)
        } else if (a.endDate !== b.endDate) {
            return Number(a.endDate) - Number(b.endDate)
        } else if (a.startDate !== b.startDate) {
            return Number(a.startDate) - Number(b.startDate)
        } else {
            return 0
        }
    }

    const onEventPartialResize = (event: JobCardPartialResizeEvent) => {
        const { eventRecord, startDate, endDate } = event

        const oldStartDate = eventRecord._data.startDate

        const oldEndDate = eventRecord._data.endDate

        const isEndDateChanged = !DateHelper.isEqual(eventRecord.resizingEndTime || oldEndDate, endDate, "minutes")

        const isResizingEventStart = !DateHelper.isEqual(
            eventRecord.resizingStartTime || oldStartDate,
            startDate,
            "minutes",
        )

        const overlappingEvents = findOverlappingEvents(eventRecord, event.context.resourceRecord, startDate, endDate)

        const isOverlapping = overlappingEvents.length > 0

        updateEventRecord(eventRecord, {
            resizingStartTime: startDate,
            resizingEndTime: endDate,
            isResizingEventStart,
            isResizingEventEnd: isEndDateChanged,
            isOverlappingOnResize: isOverlapping,
        })
    }

    const onEventDrag = ({ context, startDate, endDate }: JobCardDragEvent) => {
        setAllowShowMouseMoveFeedback(false)

        const tooltipToLeft = context.context.element.querySelector("[data-bryntum-event-card-tooltip-to-left]")
        const tooltipToRight = context.context.element.querySelector("[data-bryntum-event-card-tooltip-to-right]")

        const { localTimeString: startTime } = formatDate({
            datetime: startDate,
            preferredTimezone: undefined,
        })

        const { localTimeString: endTime } = formatDate({
            datetime: endDate,
            preferredTimezone: undefined,
        })

        const eventCard: HTMLElement = context.context.element.querySelector("[data-bryntum-event-card]")

        const draggedEventRecord = context.eventRecords[0]
        const resourceRecord = context.newResource

        // Find if any events overlap with the dragged event's new position
        const overlappingEvents = schedulerPro.current.instance.eventStore.query((record: SchedulerEventModel) => {
            return (
                record !== draggedEventRecord && // Exclude the dragged event itself
                record.resourceId === resourceRecord.id && // Check events on the same resource
                record.startDate < endDate &&
                record.endDate > startDate
            )
        })

        const isOverlapping = overlappingEvents.length > 0

        if (isOverlapping) {
            eventCard.dataset["overlapping"] = "true"
        } else {
            eventCard.dataset["overlapping"] = "false"
        }

        if (tooltipToLeft) {
            tooltipToLeft.textContent = startTime
        }
        if (tooltipToRight) {
            tooltipToRight.textContent = endTime
        }

        // movement indicator for vertical doesn't work well yet.
        if (timelineViewOrientation === "horizontal") {
            showEventMoveFeedback({
                startDate,
                endDate,
                resourceId: resourceRecord.id,
                isOverlapping,
            })
        }
    }

    function onSchedulerPaint(
        this: { element: Element },
        {
            firstPaint,
            source,
        }: {
            firstPaint: boolean
            source: BryntumSchedulerPro["instance"]
        },
    ) {
        if (firstPaint) {
            EventHelper.on({
                element: this.element,
                delegate: "button.jsJobTechniciansBarColumnHeaderToggle",
                click: () => {
                    const techniciansColumn = document.querySelector(".jsJobTimelineView")
                    techniciansColumn.classList.toggle("job-timeline-view__technicians-bar--collapsed")

                    const column = (source.columns as ColumnStore).getById("techniciansColumn") as Column

                    void setTechniciansColumnStatus(column.data.collapsed ? "expanded" : "collapsed")

                    column.set({
                        collapsed: !column.data.collapsed,
                        width: column.data.collapsed ? 256 : 56,
                    })
                },
                capture: true,
                thisObj: this,
            })
        }
    }

    const onMouseMoveOverScheduler = ({
        source: scheduler,
        tickStartDate,
        tickEndDate,
        resourceRecord,
    }: {
        source: BryntumSchedulerPro["instance"]
        tickStartDate: Date
        tickEndDate: Date
        resourceRecord: Model
    }) => {
        if (user.isServiceDispatcher) {
            const isEventBeingDragged =
                scheduler.features.eventDrag.isDragging || unscheduledJobsGridDrag.current.isDragging
            const isEventBeingResized = scheduler.features.eventResize.isResizing

            if (isEventBeingDragged || isEventBeingResized || !allowShowMouseMoveFeedback) {
                hideEventFeedback()
                return
            }

            const resourceTimeRangeStore = scheduler.resourceTimeRangeStore as {
                getById(id: string | number): {
                    id: string | number
                    resourceId: string | number
                    startDate: Date
                    endDate: Date
                    name: string
                    cls: string
                    setStartEndDate(startDate: Date, endDate: Date): void
                }
                add: (timeRange: {
                    id: string | number
                    resourceId: string | number
                    startDate: Date
                    endDate: Date
                    name: string
                    cls: string
                }) => void
            }

            const targetedTimeRange = resourceTimeRangeStore.getById("hovered-time-range")

            if (targetedTimeRange) {
                const isSameTimeRange =
                    targetedTimeRange.startDate.getTime() === tickStartDate.getTime() &&
                    targetedTimeRange.endDate.getTime() === tickEndDate.getTime()
                const isSameResource = targetedTimeRange.resourceId === resourceRecord?.id

                if (!isSameTimeRange || !isSameResource) {
                    targetedTimeRange.setStartEndDate(tickStartDate, tickEndDate)
                    targetedTimeRange.resourceId = resourceRecord?.id

                    showEventFeedback()
                }
            }
        }
    }

    const onTopBarDateFieldChange = ({ source }: { source: DateField }) => {
        onDateRangeChange(new Date(source.value))
    }

    const onTopBarPreviousButtonClick = () => {
        onDateRangeChange(DateHelper.add(currentDate, -1, "d"))
    }

    const onTopBarNextButtonClick = () => {
        onDateRangeChange(DateHelper.add(currentDate, 1, "d"))
    }

    const onTopBarNowButtonClick = () => {
        onDateRangeChange(todayInUserTimezone)
    }

    const onFinalizedJobsToggle = () => {
        removeAllOverlappingRegionFeedback()
        shouldShowFinalizedJobs ? hideFinalizedJobsFromScheduler() : showFinalizedJobsInScheduler()
        handleOverlappingEvents()

        toggleFinalizedJobs()
    }

    const onCancelledJobsToggle = () => {
        removeAllOverlappingRegionFeedback()
        shouldShowCancelledJobs ? hideCancelledJobsFromScheduler() : showCancelledJobsInScheduler()
        handleOverlappingEvents()

        toggleCancelledJobs()
    }

    const onSetSchedulerToVertical = () => {
        setIsSchedulerConfigured(false)
        setTimelineViewOrientationToVertical()
    }

    const onSetSchedulerToHorizontal = () => {
        setIsSchedulerConfigured(false)
        setTimelineViewOrientationToHorizontal()
    }

    const onEventDragAbort = () => {
        setAllowShowMouseMoveFeedback(true)
        hideEventMoveFeedback()
    }

    return {
        onEventDrop,
        onEventResizeEnd,
        onDateRangeChange,
        onEventClick,
        onGridCellClick,
        onScheduleClick,
        overlappingEventSorter,
        onEventPartialResize,
        onEventDrag,
        onMouseMoveOverScheduler,
        onTopBarDateFieldChange,
        onTopBarNowButtonClick,
        onTopBarPreviousButtonClick,
        onTopBarNextButtonClick,
        onSchedulerPaint,
        onFinalizedJobsToggle,
        onCancelledJobsToggle,
        onSetSchedulerToVertical,
        onSetSchedulerToHorizontal,
        onEventDragAbort,
    }
}
