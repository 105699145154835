import { RadioGroup } from "@radix-ui/react-radio-group"
import { useMutation } from "@tanstack/react-query"
import { useTheme } from "next-themes"
import { useEffect } from "react"

import useUser from "@hooks/useUser"

import toast from "@molecules/Toast/Toast"

import { themeNameBySchemeCode } from "@constants/mappings/themeNameBySchemeCode.mapping"

import styles from "./AppAppearanceSelector.module.scss"
import { patchColorMode } from "./AppAppearanceSelector.utils"
import AppearanceSelectorCard from "./AppearanceSelectorCard/AppearanceSelectorCard"

export default function AppAppearanceSelector() {
    const { theme, setTheme } = useTheme()

    const { user } = useUser()

    const { mutate: mutateColorMode, isError } = useMutation<User["color_mode"], Error, User["color_mode"]>({
        mutationFn: patchColorMode,
    })

    useEffect(() => {
        if (isError) {
            toast({
                type: "error",
                size: "md",
                title: "Could not update appearance settings",
            })

            // Reset to original configuration
            setTheme(themeNameBySchemeCode[user.color_mode])
        }
    }, [isError])

    const handleColorSchemePick = (colorScheme: User["color_mode"]) => {
        setTheme(themeNameBySchemeCode[colorScheme])

        mutateColorMode(colorScheme)
    }

    return (
        <RadioGroup className={styles.base} tabIndex={-1}>
            <AppearanceSelectorCard isSelected={theme === "light"} variant="light" onClick={handleColorSchemePick} />
            <AppearanceSelectorCard isSelected={theme === "dark"} variant="dark" onClick={handleColorSchemePick} />
            <AppearanceSelectorCard
                isSelected={theme === "system"}
                variant="default"
                onClick={handleColorSchemePick}
            />
        </RadioGroup>
    )
}
