import clsx from "clsx"

import styles from "./Property.module.scss"
import { PropertyProps } from "./Property.types"
import PropertyContent from "./PropertyContent/PropertyContent"
import PropertyLabel from "./PropertyLabel/PropertyLabel"
import PropertySubtitle from "./PropertySubtitle/PropertySubtitle"
import PropertyValue from "./PropertyValue/PropertyValue"

function Property(props: PropertyProps) {
    const { children, className, wrapOnMobile = false } = props

    return (
        <div
            className={clsx(styles.base, className, {
                [styles.wrapOnMobile]: wrapOnMobile,
            })}
        >
            {children}
        </div>
    )
}

export default {
    Root: Property,
    Label: PropertyLabel,
    Subtitle: PropertySubtitle,
    Content: PropertyContent,
    Value: PropertyValue,
}
