import React, { Component } from "react";
import deepcopy from "rfdc";
import QuickBooksContactRecordDisplay from "./components/QuickBooksContactRecordDisplay";
import QuickBooksContactRecordForm from "./forms/QuickBooksContactRecordForm";

const PAGE_MODES = {
    VIEW_RECORD: "VIEW_RECORD",
    EDIT_RECORD: "EDIT_RECORD",
}

const FORM_DATA_NAMES_BY_MODE = {
    EDIT_RECORD: "recordFormData",
}


class QuickBooksContactRecordCard extends Component {

    // Initialize

    constructor(props) {
        super(props)

        const defaultMode = this.props.formMode || PAGE_MODES.VIEW_RECORD

        this.state = {
            removed: false,
            recordData: this.props.contact,
            recordFormData: deepcopy()(this.props.contact),

            defaultMode: defaultMode,
            mode: defaultMode,
        }
    }

    // Form helpers

    updateFormData = (formName, fieldName, fieldValue) => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState[formName][fieldName] = fieldValue
            return updatedState
        })
    }

    switchFormMode = (mode) => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState.mode = mode
            return updatedState
        })
    }

    switchToDisplay = () => {
        this.setState((state, props) => {
            let updatedState = state

            // Reset primary form data
            updatedState[FORM_DATA_NAMES_BY_MODE[state.mode]] = deepcopy()(this.props.contact)
            return updatedState
        })
        this.switchFormMode(PAGE_MODES.VIEW_RECORD)
    }

    switchToPrimaryForm = () => {
        this.switchFormMode(PAGE_MODES.EDIT_RECORD)
    }

    // Crud Record

    softDeleteRecord = (json) => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState.removed = true
            return updatedState
        })
    }

    // Handle Actions

    handleActionRequest = (action) => {
        switch (action) {
            case "RECORD_EDIT_CONTACT":
                this.switchToPrimaryForm()
                break
            case "RECORD_SAVE_CONTACT":
                this.setState((state, props) => {
                    let updatedState = state

                    delete state.recordFormData.errors
                    updatedState.recordData = state.recordFormData

                    if (updatedState.recordData.cleaned_name == undefined) {
                        updatedState.recordData.cleaned_name = ""
                    }

                    if (updatedState.recordData.cleaned_phone == undefined) {
                        updatedState.recordData.cleaned_phone = ""
                    }

                    if (updatedState.recordData.cleaned_email == undefined) {
                        updatedState.recordData.cleaned_email = ""
                    }

                    this.props.updateContact(updatedState.recordData)

                    return updatedState
                })
                this.switchFormMode(PAGE_MODES.VIEW_RECORD)
                break
            case "RECORD_CANCEL_CONTACT_EDITS":
                this.switchToDisplay()
                break
            case "RECORD_IGNORE_CONTACT":
                this.setState((state, props) => {
                    let updatedState = state

                    updatedState.recordFormData.ignored = true
                    updatedState.recordData = state.recordFormData

                    this.props.updateContact(updatedState.recordData)

                    return updatedState
                })
                this.softDeleteRecord()
                break
            default:
                console.error(`No action handler exists for action "${action}".`)
        }
    }

    // Render

    render = () => {
        if (!this.state.removed) {
            if (this.state.mode === PAGE_MODES.VIEW_RECORD) {
                return <QuickBooksContactRecordDisplay
                    contact={this.state.recordData}
                    requestAction={this.handleActionRequest}
                />
            }
            else if (this.state.mode === PAGE_MODES.EDIT_RECORD) {
                return <QuickBooksContactRecordForm
                    contact={this.state.recordFormData}
                    errors={this.state.recordFormData.errors || {}}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData(FORM_DATA_NAMES_BY_MODE[this.state.mode], fieldName, fieldValue)}
                    requestAction={this.handleActionRequest}
                />
            }
            else {
                return (
                    <div className="data-panel-container data-panel-container--with-margin">
                        <div className="data-panel" aria-label="Unknown Form Mode">
                            <div className="data-panel__form">
                                <p className="data-panel__form__caption">An unhandled form mode was supplied.</p>
                            </div>
                        </div>
                    </div>
                )
            }
        }
        else {
            return null
        }
    }
}

export default QuickBooksContactRecordCard;
