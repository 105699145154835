import clsx from "clsx"

import convertToKebabCase from "@utils/convertToKebabCase"
import determineOverdueBucket from "@utils/determineOverdueBucket"

import styles from "./StatusDot.module.scss"
import { StatusDotProps } from "./StatusDot.types"

export default function StatusDot(props: StatusDotProps) {
    const { status, dateDue, isOutstandingOrOverdue } = props

    const kebabStatus = isOutstandingOrOverdue
        ? `overdue-${determineOverdueBucket(dateDue)?.toLowerCase().replaceAll("_", "-")}`
        : convertToKebabCase(status)

    return <div className={clsx(styles.base, styles[kebabStatus])}></div>
}
