// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BottomBar-base__hN7AR{position:fixed;right:var(--size-0);bottom:var(--size-0);left:var(--size-0);z-index:var(--z-index-app-frame);width:var(--size-full);padding-bottom:env(safe-area-inset-bottom);background-color:var(--surface-primary);border-top:var(--size-0_25) solid var(--gray-a3)}.BottomBar-container__tVRZq{display:flex;gap:var(--size-0_5);align-items:stretch;justify-content:space-between;margin:0 auto;padding:var(--size-1) var(--size-2)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "BottomBar-base__hN7AR",
	"container": "BottomBar-container__tVRZq"
};
export default ___CSS_LOADER_EXPORT___;
