import Spinner from "@legacy/core/components/Spinner";
import { Component, Fragment } from "react";
import { withAsyncPaginate } from "react-select-async-paginate";
import Creatable from 'react-select/creatable';


const CreatableAsyncPaginate = withAsyncPaginate(Creatable);


class TagSearchInput extends Component {

    constructor(props) {
        super(props)

        this.state = {
            tagOptions: null,
        }
    }

    fetchTags = async () => {
        let endpoint = this.props.choicesEndpoint

        let params = new URLSearchParams()
        for (let param of this.props.extraFilters || []) {
            params.append(param[0], param[1])
        }
        endpoint = endpoint + `?${params}`

        const response = await fetch(endpoint)
        return await response.json()
    }

    setTagOptions = async (tagList) => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState.tagOptions = []
            tagList.forEach(tag => {updatedState.tagOptions.push({"value": tag.name, "label": tag.name})})
            return updatedState
        })
    }

    componentDidMount = async () => {
        const tagList = await this.fetchTags()
        await this.setTagOptions(tagList)
    }

    filterTagOptions = (searchQuery) => {
        let filteredTagOptions = this.state.tagOptions

        if (searchQuery !== "") {
            for (let queryChunk of searchQuery.split(" ")) {
                queryChunk = queryChunk.toLowerCase()
                filteredTagOptions = filteredTagOptions.filter(tagOption => tagOption.label.toLowerCase().includes(queryChunk))
            }
        }

        return filteredTagOptions
    }

    loadOptions = async (searchQuery, loadedOptions, kwargs) => {
        return {
            options: this.filterTagOptions(searchQuery),
            hasMore: false,
            additional: undefined,
        }
    }

    onChange = (selected) => {
        const { onSelectionChange, isMulti=true } = this.props

        if (isMulti) {
            onSelectionChange((selected || []).map(selectedOption => selectedOption.value))
        }
        else {
            onSelectionChange(selected !== null ? selected.value : null)
        }
    }

    render = () => {
        const { defaultSelectedTags, onSelectionChange, choicesEndpoint, placeholder, noOptionsMessage, isMulti=true, disabled=false } = this.props
        let defaultValue

        if (isMulti) {
            defaultValue = defaultSelectedTags.map(tag => {return {"value": tag, "label": tag}})
        }
        else {
            if (defaultSelectedTags.length !== 0) {
                const tag = defaultSelectedTags[0]
                defaultValue = {"value": tag, "label": tag}
            }
            else {
                defaultValue = null
            }
        }

        if (this.state.tagOptions === null) {
            return <Spinner centered={true} />
        }
        else {
            return <Fragment>
                <CreatableAsyncPaginate
                    placeholder={placeholder}
                    isMulti={isMulti}
                    loadOptions={this.loadOptions}
                    defaultValue={defaultValue}
                    defaultOptions={true}
                    onChange={this.onChange}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    isClearable={true}
                    noOptionsMessage={() => noOptionsMessage}
                    isDisabled={disabled}
                />
            </Fragment>
        }
    }
}

export default TagSearchInput;
