// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PageSection-base__wTUKf{display:flex;flex-direction:column;align-items:flex-start;align-self:stretch;padding:var(--size-0)}.PageSection-gap16__ZUgVH{gap:var(--size-4)}.PageSection-gap24__J2iMV{gap:var(--size-6)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "PageSection-base__wTUKf",
	"gap16": "PageSection-gap16__ZUgVH",
	"gap24": "PageSection-gap24__J2iMV"
};
export default ___CSS_LOADER_EXPORT___;
