import { IconDefinition, RotateProp } from "@fortawesome/fontawesome-svg-core"
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import { memo } from "react"

import { Icon } from "@atoms"

import { ActivatableIconProps, ActivatableIcons } from "./ActivatableIcon.types"

const icons: ActivatableIcons = {
    home: {
        active: icon({ name: "house", style: "solid", family: "sharp" }),
        inactive: icon({ name: "house", style: "regular", family: "sharp" }),
    },
    calendar: {
        active: icon({ name: "objects-align-left", style: "solid", family: "sharp" }),
        inactive: icon({ name: "objects-align-left", style: "regular", family: "sharp" }),
    },
    clients: {
        active: icon({ name: "user-circle", style: "solid", family: "sharp" }),
        inactive: icon({ name: "user-circle", style: "regular", family: "sharp" }),
    },
    estimates: {
        active: icon({ name: "file-lines", style: "solid", family: "sharp" }),
        inactive: icon({ name: "file-lines", style: "regular", family: "sharp" }),
    },
    jobs: {
        active: icon({ name: "wrench-simple", style: "solid", family: "sharp" }),
        inactive: icon({ name: "wrench-simple", style: "regular", family: "sharp" }),
    },
    invoices: {
        active: icon({ name: "circle-dollar", style: "solid", family: "sharp" }),
        inactive: icon({ name: "circle-dollar", style: "regular", family: "sharp" }),
    },
    purchase_orders: {
        active: icon({ name: "basket-shopping", style: "solid", family: "sharp" }),
        inactive: icon({ name: "basket-shopping", style: "regular", family: "sharp" }),
    },
    vendors: {
        active: icon({ name: "store", style: "solid", family: "sharp" }),
        inactive: icon({ name: "store", style: "regular", family: "sharp" }),
    },
    pricebook: {
        active: icon({ name: "tag", style: "solid", family: "sharp" }),
        inactive: icon({ name: "tag", style: "regular", family: "sharp" }),
    },
    reports: {
        active: icon({ name: "chart-mixed", style: "solid", family: "sharp" }),
        inactive: icon({ name: "chart-mixed", style: "regular", family: "sharp" }),
    },
    search: {
        active: icon({ name: "magnifying-glass", style: "solid", family: "sharp" }),
        inactive: icon({ name: "magnifying-glass", style: "regular", family: "sharp" }),
    },
    menu: {
        active: icon({ name: "list-ul", style: "solid", family: "sharp" }),
        inactive: icon({ name: "list-ul", style: "regular", family: "sharp" }),
    },
    help: {
        active: icon({ name: "question-circle", style: "solid", family: "sharp" }),
        inactive: icon({ name: "question-circle", style: "regular", family: "sharp" }),
    },
    news: {
        active: icon({ name: "bullhorn", style: "solid", family: "sharp" }),
        inactive: icon({ name: "bullhorn", style: "regular", family: "sharp" }),
    },
    angle_down: {
        active: icon({ name: "angle-down", style: "solid", family: "sharp" }),
        inactive: icon({ name: "angle-down", style: "regular", family: "sharp" }),
    },
    settings: {
        active: icon({ name: "gear", style: "solid", family: "sharp" }),
        inactive: icon({ name: "gear", style: "regular", family: "sharp" }),
    },
    open: {
        active: icon({ name: "circle-dashed", style: "regular", family: "sharp" }),
        inactive: icon({ name: "circle-dashed", style: "light", family: "sharp" }),
    },
    closed: {
        active: icon({ name: "archive", style: "regular", family: "sharp" }),
        inactive: icon({ name: "archive", style: "light", family: "sharp" }),
    },
    active: {
        active: icon({ name: "circle-minus", style: "regular", family: "sharp" }),
        inactive: icon({ name: "circle-minus", style: "light", family: "sharp" }),
    },
    inactive: {
        active: icon({ name: "circle", style: "regular", family: "sharp" }),
        inactive: icon({ name: "circle", style: "light", family: "sharp" }),
    },
    profile: {
        active: icon({ name: "user", style: "regular", family: "sharp" }),
        inactive: icon({ name: "user", style: "light", family: "sharp" }),
    },
    preferences: {
        active: icon({ name: "gear", style: "regular", family: "sharp" }),
        inactive: icon({ name: "gear", style: "light", family: "sharp" }),
    },
    security_and_access: {
        active: icon({ name: "key", style: "regular", family: "sharp" }),
        inactive: icon({ name: "key", style: "light", family: "sharp" }),
    },
    notifications: {
        active: icon({ name: "bell", style: "regular", family: "sharp" }),
        inactive: icon({ name: "bell", style: "light", family: "sharp" }),
    },
    payments: {
        active: icon({ name: "dollar-circle", style: "regular", family: "sharp" }),
        inactive: icon({ name: "dollar-circle", style: "light", family: "sharp" }),
    },
    company_cam: {
        active: icon({ name: "camera-retro", style: "regular", family: "sharp" }),
        inactive: icon({ name: "camera-retro", style: "light", family: "sharp" }),
    },
    accounting: {
        active: icon({ name: "receipt", style: "regular", family: "sharp" }),
        inactive: icon({ name: "receipt", style: "light", family: "sharp" }),
    },
    availability_schedules: {
        active: icon({ name: "calendar", style: "regular", family: "sharp" }),
        inactive: icon({ name: "calendar", style: "light", family: "sharp" }),
    },
}

function getRotation(iconName: string): RotateProp {
    if (iconName === "active") {
        return 90
    }
}

function ActivatableIcon(props: ActivatableIconProps) {
    const { isActive, iconName, className, size, noContainer } = props

    const rotation = getRotation(iconName)

    return (
        <Icon
            className={className}
            icon={icons[iconName][isActive ? "active" : "inactive"] as IconDefinition}
            size={size}
            noContainer={noContainer}
            rotation={rotation}
        />
    )
}

export default memo(ActivatableIcon)
