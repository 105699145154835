import styles from "./AuthContent.module.scss"
import { AuthContentProps } from "./AuthContent.types"

function AuthContent(props: AuthContentProps) {
    const { children } = props

    return <div className={styles.base}>{children}</div>
}

export default AuthContent
