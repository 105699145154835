import clsx from "clsx"

import { Divider } from "@atoms"

import styles from "./BottomSheetDivider.module.scss"
import { BottomSheetDividerProps } from "./BottomSheetDivider.types"

export default function BottomSheetDivider(props: BottomSheetDividerProps) {
    const { fullWidth } = props

    return (
        <div
            className={clsx(styles.base, {
                [styles.fullWidth]: fullWidth,
            })}
        >
            <Divider />
        </div>
    )
}
