import Spinner from "@legacy/core/components/Spinner";
import { Component } from "react";
import deepcopy from "rfdc";
import ButtonGroup from "../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../core/buttons/UniversalButton";
import AddressAutocompleteFieldGroup from "../../core/fields/AddressAutocompleteFieldGroup";
import CharField from "../../core/fields/CharField";
import ListSelectField from "../../core/fields/ListSelectField";
import PaymentTermsField from "../../core/fields/PaymentTermsField";
import SearchCreateSelectField from "../../core/fields/SearchCreateSelectField";
import TextField from "../../core/fields/TextField";
import { valueIsDefined } from "../../core/utils/utils";
import PriceBookTaxSearchOrCreateSelect, { getPriceBookTaxObjectLabel, priceBookTaxToPriceBookTaxOption } from "../../pricebook/inputs/PriceBookTaxSearchOrCreateSelect";
import ContactListEditSelect from "../components/ContactListEditSelect";


const BILLING_ADDRESS_FIELD_NAMES = [
    "billing_address_recipient",
    "billing_address_street",
    "billing_address_unit",
    "billing_address_city",
    "billing_address_state",
    "billing_address_postal",
    "billing_address_country",
]


const FORM_MODE_CAPTIONS = {
    ADD_SERVICE_LOCATION: "Please provide the following information to add a new service location:",
    EDIT_SERVICE_LOCATION: "Use the fields below to edit this service location:",
}

class ServiceLocationForm extends Component {

    constructor(props) {
        super(props)

        this.state = {
            useBillingAddress: BILLING_ADDRESS_FIELD_NAMES.some(fieldName => valueIsDefined(this.props.serviceLocation[fieldName])),
            useTerms: valueIsDefined(this.props.serviceLocation.default_invoice_net),
            usePriceBookTax: valueIsDefined(this.props.serviceLocation.default_pricebook_tax),
        }
    }

    componentDidMount() {
        // Return to the previous scroll
        document.querySelector(".main").scrollTo(0, this.props.returnScroll || 0)
    }

    renderButtons = () => {
        const {
            mode,
            submitting,
            client,
            serviceLocation,
            errors,
            onFormDataChange,
            requestAction,
            switchToPrimaryForm,
            switchToSecondaryForm,
            defaultCountryCode,
            useTaxes,
            showTaxCreateButton,
            selectedPriceBookTax,
            updatePriceBookTaxSelection,
            returnScroll
        } = this.props

        if (errors.unexpectedError) {
            return (
                <div className="data-panel__action-feedback">
                    <span className="text-invalid"><strong>An unexpected error occurred.</strong></span>
                </div>
            )
        }
        else {
            if (submitting) {
                return <Spinner centered={true} />
            }
            else {
                if (mode === "ADD_SERVICE_LOCATION") {
                    return (
                        <ButtonGroup>
                            <ButtonGroupRow>
                                <UniversalButton type="primary" text="Add" handler={event => requestAction("SERVICE_LOCATION_CREATE")} />
                                <UniversalButton type="secondary" text="Cancel" handler={event => switchToPrimaryForm()} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
                else {
                    return (
                        <ButtonGroup>
                            <ButtonGroupRow>
                                <UniversalButton type="primary" text="Save" handler={event => requestAction("SERVICE_LOCATION_UPDATE")} />
                                <UniversalButton type="secondary" text="Cancel" handler={event => switchToPrimaryForm()} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
            }
        }
    }

    toggleUseDefaultPriceBookTax = () => {
        this.props.onFormDataChange("default_pricebook_tax", null)
        this.setState({usePriceBookTax: !this.state.usePriceBookTax})
    }

    toggleUseBillingAddress = () => {
        this.props.onFormDataChange("billing_address_recipient", "")
        this.props.onFormDataChange("billing_address_street", "")
        this.props.onFormDataChange("billing_address_unit", "")
        this.props.onFormDataChange("billing_address_city", "")
        this.props.onFormDataChange("billing_address_state", "")
        this.props.onFormDataChange("billing_address_postal", "")
        this.props.onFormDataChange("billing_address_country", "")
        this.props.onFormDataChange("billing_address_formatted", "")
        this.props.onFormDataChange("billing_address_formatted_multiline", "")
        this.setState({useBillingAddress: !this.state.useBillingAddress})
    }

    toggleUseTerms = () => {
        this.props.onFormDataChange("default_invoice_net", null)
        this.setState({useTerms: !this.state.useTerms})
    }

    render() {
        const {
            mode,
            submitting,
            client,
            serviceLocation,
            errors,
            onFormDataChange,
            requestAction,
            switchToPrimaryForm,
            switchToSecondaryForm,
            defaultCountryCode,
            useTaxes,
            showTaxCreateButton,
            selectedPriceBookTax,
            updatePriceBookTaxSelection,
            returnScroll
        } = this.props
        const newContactExistsInList = (serviceLocation.contacts || []).some(contact => contact.__new__ || false)

        return (
            <div className="data-panel-container data-panel-container--with-margin">
                <div className="data-panel" aria-label="Service Location Create/Update">
                    <div className="data-panel__form" aria-label="Service Location Create/Update Form">
                        <p className="data-panel__form__caption">{FORM_MODE_CAPTIONS[mode]}</p>
                        <CharField
                            fieldName="name"
                            fieldLabel="Location Name"
                            fieldValue={serviceLocation.name || ""}
                            fieldOnChange={name => onFormDataChange("name", name || "")}
                            maxLength="100"
                            placeholder="Nickname, Franchise ID, etc."
                            optional={true}
                            errors={errors}
                        ></CharField>
                        <AddressAutocompleteFieldGroup
                            key="service_location_physical_address"
                            fieldGroupName="physical_address"
                            fieldGroupLabel="Location Address"
                            fieldPrefix="physical_address"
                            fieldValues={{
                                "physical_address_street": serviceLocation.physical_address_street || "",
                                "physical_address_unit": serviceLocation.physical_address_unit || "",
                                "physical_address_city": serviceLocation.physical_address_city || "",
                                "physical_address_state": serviceLocation.physical_address_state || "",
                                "physical_address_postal": serviceLocation.physical_address_postal || "",
                                "physical_address_country": serviceLocation.physical_address_country || "",
                            }}
                            onFormDataChange={onFormDataChange}
                            includeRecipient={false}
                            errors={errors}
                        ></AddressAutocompleteFieldGroup>
                        <div className="data-panel__form__field" id="div_id_billing_address" aria-label="Location Billing Address">
                            <label htmlFor="id_client" className="data-panel__form__field__label in-form">Location Billing Address <span className="text-optional">(optional)</span></label>
                            <div className="data-panel__form__field__input data-panel__form__field__input--with-checkbox">
                                <input type="checkbox" className="checkbox-input" name="use_billing_address" id="id_use_billing_address" checked={!this.state.useBillingAddress} onChange={event => this.toggleUseBillingAddress()} />
                                <label className="checkbox-label" htmlFor="id_use_billing_address">Same as client</label>
                            </div>
                            {
                                this.state.useBillingAddress && (
                                    <AddressAutocompleteFieldGroup
                                        key="service_location_billing_address"
                                        fieldGroupName="billing_address"
                                        fieldGroupLabel=""
                                        fieldPrefix="billing_address"
                                        fieldValues={{
                                            "billing_address_recipient": serviceLocation.billing_address_recipient || "",
                                            "billing_address_street": serviceLocation.billing_address_street || "",
                                            "billing_address_unit": serviceLocation.billing_address_unit || "",
                                            "billing_address_city": serviceLocation.billing_address_city || "",
                                            "billing_address_state": serviceLocation.billing_address_state || "",
                                            "billing_address_postal": serviceLocation.billing_address_postal || "",
                                            "billing_address_country": serviceLocation.billing_address_country || "",
                                        }}
                                        onFormDataChange={onFormDataChange}
                                        includeRecipient={true}
                                        errors={errors}
                                    ></AddressAutocompleteFieldGroup>
                                )
                            }
                        </div>
                        <TextField
                            fieldName="notes"
                            fieldLabel="Gate Codes, Hazards, etc."
                            fieldValue={serviceLocation.notes || ""}
                            fieldOnChange={notes => onFormDataChange("notes", notes || "")}
                            rows={3}
                            placeholder="Any additional details about this location. Details about a particular job should not be entered here."
                            optional={true}
                            errors={errors}
                        ></TextField>
                        <div className="data-panel__form__field" id="div_id_default_invoice_net" aria-label="Default Location Payment Terms">
                            <label htmlFor="id_client" className="data-panel__form__field__label in-form">Default Location Payment Terms <span className="text-optional">(optional)</span></label>
                            <div className="data-panel__form__field__input data-panel__form__field__input--with-checkbox">
                                <input type="checkbox" className="checkbox-input" name="use_terms" id="id_use_terms" checked={!this.state.useTerms} onChange={event => this.toggleUseTerms()} />
                                <label className="checkbox-label" htmlFor="id_use_terms">Same as client default</label>
                            </div>
                            {
                                this.state.useTerms && (
                                    <PaymentTermsField
                                        fieldName="default_invoice_net"
                                        fieldLabel=""
                                        fieldValue={serviceLocation.default_invoice_net !== null ? serviceLocation.default_invoice_net : ""}
                                        fieldOnChange={net => onFormDataChange("default_invoice_net", net || "")}
                                        errors={errors}
                                    ></PaymentTermsField>
                                )
                            }
                        </div>
                        {
                            useTaxes && (
                                <div className="data-panel__form__field" id="div_id_default_pricebook_tax" aria-label="Default Location Tax Rate">
                                    <label htmlFor="id_client" className="data-panel__form__field__label in-form">Default Location Tax Rate <span className="text-optional">(optional)</span></label>
                                    <div className="data-panel__form__field__input data-panel__form__field__input--with-checkbox">
                                        <input type="checkbox" className="checkbox-input" name="default_pricebook_tax" id="id_default_pricebook_tax" checked={!this.state.usePriceBookTax} onChange={event => this.toggleUseDefaultPriceBookTax()} />
                                        <label className="checkbox-label" htmlFor="id_default_pricebook_tax">Same as client default</label>
                                    </div>
                                    {
                                        this.state.usePriceBookTax && (
                                            <SearchCreateSelectField
                                                key={selectedPriceBookTax ? getPriceBookTaxObjectLabel(selectedPriceBookTax) : null}
                                                fieldName="default_pricebook_tax"
                                                fieldLabel=""
                                                fieldValue={selectedPriceBookTax ? getPriceBookTaxObjectLabel(selectedPriceBookTax) : null}
                                                inputComponent={
                                                    <PriceBookTaxSearchOrCreateSelect
                                                        onSelectionChange={selectedOption => {
                                                            onFormDataChange("default_pricebook_tax", selectedOption?.object?.id || null)
                                                            updatePriceBookTaxSelection(selectedOption?.object || null)
                                                        }}
                                                        onInputChange={(input, action) => {action.action === "input-change" && this.setState({taxSearchInput: input})}}
                                                        defaultSelected={selectedPriceBookTax ? priceBookTaxToPriceBookTaxOption(selectedPriceBookTax) : null}
                                                        showCreateButton={showTaxCreateButton}
                                                        limitToQuickBooksSyncedItems={!showTaxCreateButton}
                                                        onCreateClick={event => switchToSecondaryForm("ADD_PRICEBOOKITEM_TAX", null, {description: this.state.taxSearchInput})}
                                                    />
                                                }
                                                showButton={!!selectedPriceBookTax}
                                                buttonLabel={<><i className="fa-sharp fa-light fa-pen-to-square" aria-hidden="true"></i>Edit Tax Rate</>}
                                                buttonAction={event => switchToSecondaryForm("EDIT_PRICEBOOKITEM_TAX", selectedPriceBookTax, null)}
                                                errors={errors}
                                            />
                                        )
                                    }
                                </div>
                            )
                        }
                        <ListSelectField
                            fieldName="contacts"
                            fieldLabel="Location Contacts"
                            inputComponent={<ContactListEditSelect fieldName="contacts" contacts={serviceLocation.contacts || []} primaryContactId={serviceLocation.primary_contact} updateContacts={contacts => onFormDataChange("contacts", contacts)} />}
                            showButton={!newContactExistsInList}
                            buttonLabel="Add Contact"
                            buttonAction={event => {
                                let newContacts = deepcopy()(serviceLocation.contacts || [])
                                newContacts.push({
                                    "id": null,
                                    "name": "",
                                    "phone": "",
                                    "phone_extension": "",
                                    "email": "",
                                    "receive_invoice_reminders_via_email": false,
                                    "receive_invoice_reminders_via_sms": false,
                                    "receive_estimate_reminders_via_email": false,
                                    "receive_estimate_reminders_via_sms": false,
                                    "primary": false,
                                    "attached_to": "service_location",
                                    "__new__": true
                                })
                                onFormDataChange("contacts", newContacts)
                            }}
                            errors={errors}
                        ></ListSelectField>
                    </div>
                    {this.renderButtons()}
                </div>
            </div>
        )
    }
}

export default ServiceLocationForm;
