import JobCompleteSummary from "./JobCompleteSummary/JobCompleteSummary"
import JobCompleteSummaryConfirmation from "./JobCompleteSummaryConfirmation/JobCompleteSummaryConfirmation"
import JobCreate from "./JobCreate/JobCreate"
import JobDetails from "./JobDetails/JobDetails"
import JobDetailsEdit from "./JobDetailsEdit/JobDetailsEdit"
import JobEdit from "./JobEdit/JobEdit"
import JobEquipmentSummaryEdit from "./JobEquipmentSummaryEdit/JobEquipmentSummaryEdit"
import JobList from "./JobList/JobList"
import JobPublicDetails from "./JobPublicDetails/JobPublicDetails"
import JobSummaryEdit from "./JobSummaryEdit/JobSummaryEdit"

const List = JobList
const Create = JobCreate
const Edit = JobEdit
const Details = JobDetails
const PublicDetails = JobPublicDetails
const DetailsEdit = JobDetailsEdit
const SummaryEdit = JobSummaryEdit
const EquipmentSummaryEdit = JobEquipmentSummaryEdit
const CompleteSummary = JobCompleteSummary
const CompleteSummaryConfirmation = JobCompleteSummaryConfirmation

export default {
    List,
    Create,
    Edit,
    Details,
    PublicDetails,
    DetailsEdit,
    SummaryEdit,
    EquipmentSummaryEdit,
    CompleteSummary,
    CompleteSummaryConfirmation,
}
