import styles from "./ModalFooter.module.scss"
import { ModalFooterProps } from "./ModalFooter.types"

export default function ModalFooter(props: ModalFooterProps) {
    const { children, ...rest } = props

    return (
        <div className={styles.base} {...rest}>
            {children}
        </div>
    )
}
