import ClientInformationDisplay from "@legacy/core/components/ClientInformationDisplay";
import ContactListDisplay from "@legacy/core/components/ContactListDisplay";
import BasicDisplayField from "@legacy/core/fields/BasicDisplayField";
import { valueIsDefined } from "@legacy/core/utils/utils";
import { renderJobTime } from "@legacy/jobs/utils/utils";
import { Component, Fragment } from "react";
import ButtonGroup from "../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../core/buttons/UniversalButton";
import VendorFeedAccordion from "./VendorFeedAccordion";

class VendorDetailsCard extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        // Return to the previous scroll
        document.querySelector(".main").scrollTo(0, this.props.returnScroll || 0)
    }

    renderButtons = () => {
        const { vendor, requestAction, switchToSecondaryForm, formatCurrencyValue, returnScroll } = this.props

        return (
            <ButtonGroup>
                {vendor.is_active && (
                    <ButtonGroupRow>
                        <UniversalButton type="primary" text="Edit Vendor" handler={event => requestAction("VENDOR_EDIT")} />
                    </ButtonGroupRow>
                )}
                {vendor.is_active ? (
                    <ButtonGroupRow>
                        <UniversalButton type="secondary" text="Deactivate Vendor" handler={event => requestAction("VENDOR_DEACTIVATE")} />
                    </ButtonGroupRow>
                ) :
                    <ButtonGroupRow>
                        <UniversalButton type="secondary" text="Reactivate Vendor" handler={event => requestAction("VENDOR_REACTIVATE")} />
                    </ButtonGroupRow>
                }
            </ButtonGroup>
        )
    }

    render() {
        const { vendor, requestAction, switchToSecondaryForm, formatCurrencyValue, returnScroll } = this.props
        return (
            <Fragment>
                <div className="header-wall" aria-hidden="true"></div>
                <div className="data-panel-container">
                    <div className="data-panel" aria-label="Vendor Details">
                        <ClientInformationDisplay
                            client={vendor}
                        ></ClientInformationDisplay>
                         <div className="data-panel__form" aria-label="Detailed Information">
                            <BasicDisplayField
                                fieldName="website"
                                fieldLabel="Website"
                                fieldValue={vendor.website ? <a target="_blank" rel="noreferrer noopener" href={vendor.website.includes("//") ? "" : `//${vendor.website}`}>{vendor.website}</a> : "--"}
                            ></BasicDisplayField>
                            <BasicDisplayField
                                fieldName="billing_address"
                                fieldLabel="Billing Address"
                                fieldValue={vendor.billing_address_formatted_multiline || "--"}
                            ></BasicDisplayField>
                            <BasicDisplayField
                                fieldName="account_id"
                                fieldLabel="Account ID"
                                fieldValue={vendor.account_id || "--"}
                            ></BasicDisplayField>
                            <BasicDisplayField
                                fieldName="payment_terms"
                                fieldLabel="Payment Terms"
                                fieldValue={vendor.payment_terms !== 0 ? `Net ${vendor.payment_terms}` : "Same Day"}
                            ></BasicDisplayField>
                            <BasicDisplayField
                                fieldName="notes"
                                fieldLabel="Vendor Notes"
                                fieldValue={vendor.notes || "--"}
                            ></BasicDisplayField>
                            {
                                vendor.is_active && vendor.last_reactivated_message !== "" && (
                                    <BasicDisplayField
                                        fieldName="last_reactivated_message"
                                        fieldLabel="Reactivation Reason"
                                        fieldValue={vendor.last_reactivated_message}
                                    ></BasicDisplayField>
                                )
                            }
                            {
                                !vendor.is_active && vendor.last_deactivated_message !== "" && (
                                    <BasicDisplayField
                                        fieldName="last_deactivated_message"
                                        fieldLabel="Deactivation Reason"
                                        fieldValue={vendor.last_deactivated_message}
                                    ></BasicDisplayField>
                                )
                            }
                            <BasicDisplayField
                                fieldName="last_modified_at"
                                fieldLabel="Last Modified"
                                fieldValue={renderJobTime(vendor.last_modified_at, window.PREFERRED_TIMEZONE)}
                            ></BasicDisplayField>
                            <ContactListDisplay
                                fieldName="contacts"
                                fieldLabel="Vendor Contacts"
                                contacts={vendor.contacts}
                                primaryContactId={vendor.primary_contact}
                            ></ContactListDisplay>
                        </div>
                        {this.renderButtons()}
                    </div>
                </div>
                {
                    valueIsDefined(vendor.id) && (
                        <div className="accordion-wrapper">
                            <VendorFeedAccordion vendorID={vendor.id} />
                        </div>
                    )
                }
            </Fragment>
        )
    }

}

export default VendorDetailsCard;
