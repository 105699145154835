// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NumericalDataCell-base__jxDIy{display:flex;align-items:center;justify-content:flex-end;width:var(--size-full);padding:var(--size-3_5) var(--size-4);color:var(--gray-12);text-align:right;font:var(--font-global-body-400-tabular);font-feature-settings:var(--feature-settings-global-body-400-tabular);letter-spacing:var(--letter-spacing-global-body-400-tabular)}.NumericalDataCell-alerting__bhDUL{color:var(--tomato-9)}.NumericalDataCell-durationType__h0P6v{justify-content:flex-start;color:var(--gray-a11)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "NumericalDataCell-base__jxDIy",
	"alerting": "NumericalDataCell-alerting__bhDUL",
	"durationType": "NumericalDataCell-durationType__h0P6v"
};
export default ___CSS_LOADER_EXPORT___;
