import Spinner from "@legacy/core/components/Spinner";
import { Component } from "react";
import deepcopy from "rfdc";
import ButtonGroup from "../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../core/buttons/UniversalButton";
import BasicSelectField from "../../core/fields/BasicSelectField";
import CharField from "../../core/fields/CharField";
import DateField from "../../core/fields/DateField";
import ListSelectField from "../../core/fields/ListSelectField";
import TextField from "../../core/fields/TextField";
import { EquipmentCategories } from "../../core/utils/enums";
import { valueIsDefined } from "../../core/utils/utils";
import DuplicateEquipmentDisplay from "../components/DuplicateEquipmentDisplay";
import EquipmentWarrantyListEditSelect from "../components/EquipmentWarrantyListEditSelect";

const FORM_MODE_CAPTIONS = {
    ADD_EQUIPMENT: "Please provide the following information to log a new piece of equipment:",
    ADD_EQUIPMENT_STANDALONE: "Please provide the following information to log a new piece of equipment:",
    EDIT_EQUIPMENT: "Use the fields below to edit this piece of equipment:",
}

class EquipmentForm extends Component {

    componentDidMount() {
        // Return to the previous scroll
        document.querySelector(".main").scrollTo(0, this.props.returnScroll || 0)
    }

    renderButtons = () => {
        const {mode, submitting, equipment, errors, onFormDataChange, requestAction, switchToPrimaryForm, equipmentCategoryOptions, equipmentTypeOptions, ownershipTypeOptions, duplicateEquipment, returnScroll} = this.props

        if (errors.unexpectedError) {
            return (
                <div className="data-panel__action-feedback">
                    <span className="text-invalid"><strong>An unexpected error occurred.</strong></span>
                </div>
            )
        }
        else {
            if (submitting) {
                return <Spinner centered={true} />
            }
            else {
                if (mode === "ADD_EQUIPMENT") {
                    return (
                        <ButtonGroup>
                            <ButtonGroupRow>
                                <UniversalButton type="primary" text="Add" handler={event => requestAction("EQUIPMENT_CREATE")} />
                                <UniversalButton type="secondary" text="Cancel" handler={event => switchToPrimaryForm()} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
                if (mode === "ADD_EQUIPMENT_STANDALONE") {
                    return (
                        <ButtonGroup>
                            <ButtonGroupRow>
                                <UniversalButton type="primary" text="Add" handler={event => requestAction("EQUIPMENT_CREATE_STANDALONE")} />
                                <UniversalButton type="secondary" text="Cancel" handler={event => switchToPrimaryForm()} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
                else {
                    return (
                        <ButtonGroup>
                            <ButtonGroupRow>
                                <UniversalButton type="primary" text="Save" handler={event => requestAction("EQUIPMENT_UPDATE")} />
                                <UniversalButton type="secondary" text="Cancel" handler={event => switchToPrimaryForm()} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
            }
        }
    }

    render() {
        const {mode, submitting, equipment, errors, onFormDataChange, requestAction, switchToPrimaryForm, equipmentCategoryOptions, equipmentTypeOptions, ownershipTypeOptions, duplicateEquipment, returnScroll} = this.props
        const newWarrantyExistsInList = (equipment.warranties || []).some(warranty => warranty.__new__ || false)

        return (
            <div className="data-panel-container data-panel-container--with-margin">
                <div className="data-panel" aria-label="Equipment Create/Update">
                    <div className="data-panel__form" aria-label="Equipment Create/Update Form">
                        <div id="div_id_non_field_error"></div>
                        <p className="data-panel__form__caption">{FORM_MODE_CAPTIONS[mode]}</p>
                        {errors.non_field_error && <div className="data-panel__form__field__errors" aria-label="Non Field Error">{errors.non_field_error}</div>}
                        <BasicSelectField
                            fieldName="equipment_category"
                            fieldLabel="Equipment Category"
                            fieldValue={equipment.equipment_category}
                            fieldOnChange={equipment_category => {onFormDataChange("equipment_category", equipment_category); onFormDataChange("equipment_type", null)}}
                            choices={equipmentCategoryOptions}
                            disabled={equipmentCategoryOptions.filter(option => option.value !== "").length <= 1}
                            errors={errors}
                        ></BasicSelectField>
                        {
                            valueIsDefined(equipment.equipment_category) && parseInt(equipment.equipment_category) !== EquipmentCategories.other && (
                                <BasicSelectField
                                    fieldName="equipment_type"
                                    fieldLabel="Equipment Type"
                                    fieldValue={equipment.equipment_type}
                                    fieldOnChange={equipment_type => onFormDataChange("equipment_type", equipment_type !== "" ? equipment_type : null)}
                                    choices={equipmentTypeOptions.filter(equipmentType => equipmentType.value === "" || equipmentType.category === parseInt(equipment.equipment_category))}
                                    errors={errors}
                                ></BasicSelectField>
                            )
                        }
                        <CharField
                            fieldName="display_name"
                            fieldLabel="Display Name"
                            fieldValue={equipment.display_name || ""}
                            fieldOnChange={display_name => onFormDataChange("display_name", display_name || "")}
                            maxLength="100"
                            errors={errors}
                        ></CharField>
                        <CharField
                            fieldName="manufacturer"
                            fieldLabel="Manufacturer"
                            fieldValue={equipment.manufacturer || ""}
                            fieldOnChange={manufacturer => onFormDataChange("manufacturer", manufacturer || "")}
                            maxLength="100"
                            errors={errors}
                        ></CharField>
                        <CharField
                            fieldName="model_number"
                            fieldLabel="Model Number"
                            fieldValue={equipment.model_number || ""}
                            fieldOnChange={model_number => onFormDataChange("model_number", model_number || "")}
                            maxLength="100"
                            errors={errors}
                        ></CharField>
                        <div id="div_id_identifying_info"></div>
                        <CharField
                            fieldName="serial_number"
                            fieldLabel="Serial Number"
                            fieldValue={equipment.serial_number || ""}
                            fieldOnChange={serial_number => onFormDataChange("serial_number", serial_number || "")}
                            maxLength="100"
                            errors={errors}
                        ></CharField>
                        {errors.identifying_info && <div className="data-panel__form__field__errors" aria-label="Non Field Error">{errors.identifying_info}</div>}
                        {duplicateEquipment.length !== 0 && <DuplicateEquipmentDisplay duplicateEquipment={duplicateEquipment} />}
                        <BasicSelectField
                            fieldName="ownership_type"
                            fieldLabel="Ownership Type"
                            fieldValue={equipment.ownership_type}
                            fieldOnChange={ownership_type => {onFormDataChange("ownership_type", ownership_type)}}
                            choices={ownershipTypeOptions}
                            errors={errors}
                        ></BasicSelectField>
                        <CharField
                            fieldName="location"
                            fieldLabel="Location in Building"
                            fieldValue={equipment.location || ""}
                            fieldOnChange={location => onFormDataChange("location", location || "")}
                            maxLength="100"
                            errors={errors}
                        ></CharField>
                        <DateField
                            fieldName="installation_date"
                            fieldLabel="Installation Date"
                            fieldValue={equipment.installation_date || ""}
                            fieldOnChange={installation_date => onFormDataChange("installation_date", installation_date || null)}
                            errors={errors}
                        ></DateField>
                        <TextField
                            fieldName="notes"
                            fieldLabel="Notes"
                            fieldValue={equipment.notes || ""}
                            fieldOnChange={notes => onFormDataChange("notes", notes || "")}
                            rows={3}
                            optional={true}
                            errors={errors}
                        ></TextField>
                        <ListSelectField
                            fieldName="warranties"
                            fieldLabel="Warranties"
                            inputComponent={<EquipmentWarrantyListEditSelect fieldName="warranties" warranties={equipment.warranties || []} updateEquipmentWarranties={warranties => onFormDataChange("warranties", warranties)} />}
                            showButton={!newWarrantyExistsInList}
                            buttonLabel="Add Warranty"
                            buttonAction={event => {
                                let newWarranties = deepcopy()(equipment.warranties || [])
                                newWarranties.push({
                                    "id": null,
                                    "name": "",
                                    "start_date": null,
                                    "end_date": null,
                                    "details": "",
                                    "__new__": true
                                })
                                onFormDataChange("warranties", newWarranties)
                            }}
                            errors={errors}
                        ></ListSelectField>
                    </div>
                    {this.renderButtons()}
                </div>
            </div>
        )
    }
}

export default EquipmentForm;
