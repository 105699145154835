import { Component, Fragment } from 'react';
import UniversalLinkButton from '../buttons/UniversalLinkButton';


class LinkedObjectsDisplayField extends Component {

    render() {
        const {fieldName, fieldLabel, objectComponentList, showButton=false, buttonLabel, buttonDestination, fieldClasses=["data-panel__form__field"] } = this.props

        return (
            <div className={fieldClasses.join(" ")} id={`div_id_${fieldName}`} aria-label={fieldLabel}>
                <span className="data-panel__form__field__label" aria-hidden="true">{fieldLabel}</span>
                <div className="data-panel__form__field__input data-panel__form__field__input--with-list-select">
                    <div id={`id_${fieldName}`} className="data-panel__list-select">
                        {objectComponentList}
                    </div>
                    {
                        showButton === true && (
                            <UniversalLinkButton type="list_select_action" text={<Fragment><i className="fa-sharp fa-light fa-plus" aria-hidden="true"></i>{buttonLabel}</Fragment>} destination={buttonDestination} />
                        )
                    }
                </div>
            </div>
        )
    }
}

export default LinkedObjectsDisplayField;
