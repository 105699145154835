import { InvalidateQueryFilters, useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useEffect } from "react"

import toast from "@molecules/Toast/Toast"

import { NOTIFICATION_ENDPOINTS } from "@endpoints/notification"

import {
    EstimatesNotificationTopics,
    InventoryNotificationTopics,
    JobsAssignmentNotificationTopics,
    JobsManagerNotificationTopics,
    NotificationPreferenceTopics,
    NotificationTopicsMap,
    NotificationTopicsType,
} from "./SettingsNotification.types"
import { patchNotificationPreference } from "./SettingsNotifications.utils"

export const notificationPreferenceQueryKey = [
    "list-notification-methods",
    NOTIFICATION_ENDPOINTS.RETRIEVE_NOTIFICATION_PREFERENCES,
]

const useNotificationPreferencesQuery = <T = undefined>(
    topic?: NotificationTopicsType,
): {
    isLoading: boolean
    topicPreference: T
    allTopics: NotificationTopicsMap
} => {
    const { data, isError, isLoading } = useQuery({
        queryKey: notificationPreferenceQueryKey,
        queryFn: async (): Promise<NotificationPreferenceTopics> => {
            const response = await fetch(NOTIFICATION_ENDPOINTS.RETRIEVE_NOTIFICATION_PREFERENCES)
            return (await response.json()) as NotificationPreferenceTopics
        },
    })

    const notificationTopicsTypes: NotificationTopicsMap = {
        inventory: {
            enable_inventory_notification_low_inventory: data?.enable_inventory_notification_low_inventory,
        },
        jobs: {
            assignment: {
                enable_job_notification_job_assigned: data?.enable_job_notification_job_assigned,
                enable_job_notification_job_unassigned: data?.enable_job_notification_job_unassigned,
                enable_job_notification_job_updated: data?.enable_job_notification_job_updated,
                enable_job_notification_job_rescheduled: data?.enable_job_notification_job_rescheduled,
                enable_job_notification_job_unscheduled: data?.enable_job_notification_job_unscheduled,
                enable_job_notification_job_cancelled: data?.enable_job_notification_job_cancelled,
            },
            manager: {
                enable_job_notification_new_job_request: data?.enable_job_notification_new_job_request,
                enable_job_notification_job_started: data?.enable_job_notification_job_started,
                enable_job_notification_job_paused: data?.enable_job_notification_job_paused,
                enable_job_notification_job_placed_on_hold: data?.enable_job_notification_job_placed_on_hold,
                enable_job_notification_job_resumed: data?.enable_job_notification_job_resumed,
                enable_job_notification_job_completed: data?.enable_job_notification_job_completed,
                enable_job_notification_job_summary_updated: data?.enable_job_notification_job_summary_updated,
            },
        },
        estimates: {
            enable_estimate_notification_estimate_approved: data?.enable_estimate_notification_estimate_approved,
            enable_estimate_notification_estimate_declined: data?.enable_estimate_notification_estimate_declined,
        },
    }

    useEffect(() => {
        if (isError) {
            toast({
                type: "error",
                size: "md",
                title: "Could not retrieve notification preference",
            })
        }
    }, [isError])

    return {
        topicPreference: notificationTopicsTypes?.[topic] as T,
        allTopics: notificationTopicsTypes,
        isLoading,
    }
}

const useNotificationPreferencesMutation = () => {
    const client = useQueryClient()

    const { mutate, isError } = useMutation<
        | InventoryNotificationTopics
        | EstimatesNotificationTopics
        | JobsAssignmentNotificationTopics
        | JobsManagerNotificationTopics,
        Error,
        | InventoryNotificationTopics
        | EstimatesNotificationTopics
        | JobsAssignmentNotificationTopics
        | JobsManagerNotificationTopics
    >({
        mutationFn: patchNotificationPreference,
        onSuccess: () => {
            void client.invalidateQueries([notificationPreferenceQueryKey] as InvalidateQueryFilters)
        },
    })

    useEffect(() => {
        if (isError) {
            toast({
                type: "error",
                size: "md",
                title: "Could not update notification preference",
            })
        }
    }, [isError])

    return { mutate, isError }
}

export { useNotificationPreferencesMutation, useNotificationPreferencesQuery }
