import React, { Component } from 'react';
import { valueIsDefined } from "../utils/utils";


class PriceField extends Component {

    render() {
        const {fieldName, fieldLabel, fieldValue, fieldOnChange, currencySymbol, credit=false, disabled=false, optional=false, optionalText="optional", extraClasses=null, errors} = this.props

        return (
            <div className={`data-panel__form__field ${extraClasses !== null ? extraClasses.join(" ") : ""}`} id={`div_id_${fieldName}`} aria-label={fieldLabel}>
                <label htmlFor={`id_${fieldName}`} className="data-panel__form__field__label in-form">{fieldLabel}{optional && <span className="text-optional"> ({optionalText})</span>}</label>
                <div className="data-panel__form__field__input">
                    <div className="data-panel__form__field__input-group" title={disabled && fieldValue ? `${currencySymbol}${fieldValue}` : null}>
                        <div className="input-group-prepend">
                            <div className="input-group-text">{currencySymbol}</div>
                        </div>
                        <input
                            defaultValue={valueIsDefined(fieldValue) ? parseFloat(fieldValue) * (credit ? -1 : 1) : ""}
                            onChange={event => fieldOnChange(valueIsDefined(event.target.value) ? event.target.value * (credit ? -1 : 1) : null)}
                            type="number"
                            min="0"
                            max="9999999"
                            step="0.5"
                            id={`id_${fieldName}`}
                            name={fieldName}
                            autoComplete="off"
                            className={disabled ? "is-disabled" : ""}
                            disabled={disabled}
                        />
                    </div>
                </div>
                {errors[fieldName] && <div className="data-panel__form__field__errors" aria-label="Field Errors">{errors[fieldName]}</div>}
            </div>
        )
    }
}

export default PriceField;
