import { useEffect, useState } from "react"
import { formatPhoneNumber } from "react-phone-number-input"

import useIsDesktop from "@hooks/useIsDesktop"
import useUser from "@hooks/useUser"

import OTPInput from "@molecules/Form/inputs/OTPInput/OTPInput"
import ResponsiveDialog from "@molecules/ResponsiveDialog/ResponsiveDialog"

import OTPForm from "@organisms/OTPForm/OTPForm"

import { AUTH_ENDPOINTS } from "@endpoints/auth"

import styles from "./SecurityCheckDialog.module.scss"
import { SecurityCheckDialogProps } from "./SecurityCheckDialog.types"

export default function SecurityCheckDialog(props: SecurityCheckDialogProps) {
    const { children = undefined, checkFor, onSuccess, isOpen, onOpenChange } = props

    const { user } = useUser()

    const isDesktop = useIsDesktop()

    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

    useEffect(() => {
        if (typeof isOpen === "boolean") {
            setIsDialogOpen(isOpen)
        }
    }, [isOpen])

    useEffect(() => {
        onOpenChange?.(isDialogOpen)
    }, [isDialogOpen])

    const contactInfo = checkFor === "email" ? user?.[checkFor] : formatPhoneNumber(user?.[checkFor])

    return (
        <OTPForm.Root
            checkFor={checkFor}
            codeRequestEndpoint={
                checkFor === "email" ? AUTH_ENDPOINTS.OTP.EMAIL_REQUEST : AUTH_ENDPOINTS.OTP.SMS_REQUEST
            }
            codeVerificationEndpoint={
                checkFor === "email" ? AUTH_ENDPOINTS.OTP.EMAIL_VERIFY : AUTH_ENDPOINTS.OTP.SMS_VERIFY
            }
            onCodeVerificationSuccessful={onSuccess}
            triggerOTPReset={!isDialogOpen}
            triggerSendCode={user && isDialogOpen}
        >
            <ResponsiveDialog.Root
                headerTitle="Security check"
                trigger={children}
                onOpenChange={setIsDialogOpen}
                isOpen={isDialogOpen}
            >
                <ResponsiveDialog.Body>
                    <OTPForm.AlertBanner />
                    <span className={styles.subtitle}>
                        Enter the code we just sent to <span className={styles.subtitleHighlight}>{contactInfo}</span>
                    </span>
                    <div className={styles.otpInput}>
                        <OTPInput size={isDesktop ? "sm" : "md"} />
                    </div>
                </ResponsiveDialog.Body>
                <ResponsiveDialog.Footer>
                    <OTPForm.PasteButton />
                    <OTPForm.SendCodeButton isFullWidth={false} />
                </ResponsiveDialog.Footer>
            </ResponsiveDialog.Root>
        </OTPForm.Root>
    )
}
