import EventAccordion from "../../core/components/EventAccordion"
import { valueIsDefined } from "../../core/utils/utils"


class VendorFeedAccordion extends EventAccordion {

    fetchData = async () => {
        const { vendorID } = this.props

        if (valueIsDefined(vendorID)) {
            const endpoint = DjangoUrls["pricebook:api-vendors-feed-list"](window.MARKETPLACE_ENTITY_SLUG, vendorID)
            const response = await fetch(endpoint)
            return await response.json()
        }
        else {
            return null
        }
    }

    getTitle = () => {
        return "Vendor Timeline"
    }

    getEmphasis = () => {
        return "time"
    }
}

export default VendorFeedAccordion;
