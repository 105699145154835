// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NotificationMethodsSection-base__IiRVe{width:var(--size-full);padding:var(--size-2) var(--size-0)}.NotificationMethodsSection-wrapper__sL4ac{display:flex;flex-direction:column;gap:var(--size-2);align-items:flex-start;align-self:stretch;width:var(--size-full)}.NotificationMethodsSection-notificationMethod__gfsL0{width:var(--size-full)}@media only screen and (min-width: 64rem){.NotificationMethodsSection-notificationMethod__gfsL0{width:var(--size-72)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "NotificationMethodsSection-base__IiRVe",
	"wrapper": "NotificationMethodsSection-wrapper__sL4ac",
	"notificationMethod": "NotificationMethodsSection-notificationMethod__gfsL0"
};
export default ___CSS_LOADER_EXPORT___;
