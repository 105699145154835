import { useVirtualizer } from "@tanstack/react-virtual"
import { useEffect } from "react"

import useIsScrolling from "@hooks/useIsScrolling"

import CardListCard from "@organisms/CardList/CardListCard/CardListCard"
import CardListEmptyState from "@organisms/CardList/CardListEdgeStates/CardListEmptyState/CardListEmptyState"
import CardListErrorState from "@organisms/CardList/CardListEdgeStates/CardListErrorState/CardListErrorState"
import CardListLoadingState from "@organisms/CardList/CardListEdgeStates/CardListLoadingState/CardListLoadingState"
import CardListNoResultsState from "@organisms/CardList/CardListEdgeStates/CardListNoResultsState/CardListNoResultsState"
import CardListLoaderCard from "@organisms/CardList/CardListLoaderCard/CardListLoaderCard"

import withAlternativeStates from "@HOCs/withAlternativeStates"

import styles from "./CardListContent.module.scss"
import { CardListContentProps } from "./CardListContent.types"

const CARD_HEIGHT = 157.5

function CardListContent(props: CardListContentProps) {
    const { activeTabConfig, currentData, fetchNextPage, hasNextPage, isFetchingNextPage, CardComponent } = props

    const { ref: scrollingElement } = useIsScrolling<HTMLDivElement>()

    const rowVirtualizer = useVirtualizer({
        count: hasNextPage ? currentData.length + 1 : currentData.length,
        getScrollElement: () => scrollingElement.current,
        estimateSize: () => CARD_HEIGHT,
        overscan: 5,
    })

    const virtualRows = rowVirtualizer.getVirtualItems()

    // Fetch more rows when we hit the bottom
    useEffect(() => {
        const [lastItem] = [...virtualRows].reverse()

        if (!lastItem) {
            return
        }

        if (lastItem.index === currentData?.length && hasNextPage && !isFetchingNextPage) {
            void fetchNextPage()
        }
    }, [fetchNextPage, hasNextPage, isFetchingNextPage, currentData?.length, virtualRows])

    return (
        <div ref={scrollingElement} className={styles.scrollContainer}>
            <div className={styles.virtualizer} style={{ height: `${rowVirtualizer.getTotalSize()}px` }}>
                {virtualRows?.map((virtualRow) => {
                    const isLoaderRow = virtualRow.index > currentData?.length - 1

                    if (isLoaderRow && hasNextPage) {
                        return (
                            <CardListLoaderCard
                                key={virtualRow.index}
                                height={virtualRow.size}
                                transform={virtualRow.start}
                            />
                        )
                    } else {
                        return (
                            <CardListCard
                                key={virtualRow.index}
                                height={virtualRow.size}
                                transform={virtualRow.start}
                                index={virtualRow.index}
                                measureElement={rowVirtualizer.measureElement}
                                activeTabConfig={activeTabConfig}
                                currentData={currentData}
                                CardComponent={CardComponent}
                            />
                        )
                    }
                })}
            </div>
        </div>
    )
}

export default withAlternativeStates({
    LoadingState: CardListLoadingState,
    ErrorState: CardListErrorState,
    EmptyState: CardListEmptyState,
    NoResultsState: CardListNoResultsState,
    SuccessState: CardListContent,
})
