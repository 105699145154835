import Spinner from "@legacy/core/components/Spinner";
import { Component } from 'react';
import ButtonGroup from "../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../core/buttons/UniversalButton";
import WrapperButton from '../buttons/WrapperButton';
import { convertToKebabCase } from '../utils/utils';


class TabSwitcher extends Component {
    constructor(props) {
        super(props)

        const desiredTab = new URLSearchParams(document.location.search).get("tab") || ""
        let defaultTab = props.tabs.includes(desiredTab.toUpperCase()) ? desiredTab.toUpperCase() : props.tabs[0]

        this.state = {
            activeTab: defaultTab
        }
    }

    toggleActiveTab = (desiredTab) => {
        const params = new URLSearchParams(document.location.search)
        params.set("tab", desiredTab.toLowerCase())
        history.replaceState({}, "", `${location.pathname}?${params}`);

        this.setState({activeTab: desiredTab})
    }

    renderLoadMoreButton = (buttonText) => {
        if (this.props.loadingMoreObjects) {
            return <Spinner centered={true} />
        }
        else {
            if (this.props.shouldFetchMoreObjects(this.state.activeTab)) {
                return (
                    <ButtonGroup>
                        <ButtonGroupRow>
                            <UniversalButton type="card_list_load_more" text={buttonText} handler={event => this.props.fetchMoreObjects(this.state.activeTab)} />
                        </ButtonGroupRow>
                    </ButtonGroup>
                )
            }
        }
    }


    renderCardsForTab(tab, objects) {
        console.error("You must implement TabSwitcher.renderCardsForTab().")
    }

    render() {
        const {tabs, tabTitleConverter, filteredTabbedObjects, objectName} = this.props

        return (
            <div className="card-container" aria-label="Card List">
                <div className="full-width-bar">
                    <ul className={"card-tab-switcher card-tab-switcher--" + convertToKebabCase(objectName) + "s"} aria-label="Card Tab Switcher">
                        {tabs.map(
                            (tab, index) => (
                                <WrapperButton key={index} type="tab" handler={event => this.toggleActiveTab(tab)}>
                                    <li className={"card-tab-switcher__item " + objectName.toLowerCase().replace(" ", "-") + "s-" + tab.toLowerCase() + (tab == this.state.activeTab ? " active" : "")}>
                                        {tabTitleConverter(tab)}
                                    </li>
                                </WrapperButton>
                            )
                        )}
                    </ul>
                </div>
                <div className="card-list" aria-label={this.state.activeTab + " " + objectName + "s"}>
                    {
                        filteredTabbedObjects[this.state.activeTab] === undefined
                    ?
                        <Spinner centered={true} />
                    :
                        <div className="card-category-container" aria-label="Category Container">
                            {this.renderCardsForTab(this.state.activeTab, filteredTabbedObjects[this.state.activeTab])}
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default TabSwitcher;
