import React, { Component } from "react";
import deepcopy from "rfdc";
import { formatAddress, sendDataToServer } from "../../../core/utils/utils";
import QuickBooksServiceLocationRecordDisplay from "./components/QuickBooksServiceLocationRecordDisplay";
import QuickBooksServiceLocationRecordForm from "./forms/QuickBooksServiceLocationRecordForm";

const PAGE_MODES = {
    VIEW_RECORD: "VIEW_RECORD",
    EDIT_RECORD: "EDIT_RECORD",
}

const FORM_DATA_NAMES_BY_MODE = {
    EDIT_RECORD: "recordFormData",
}


class QuickBooksServiceLocationRecordCard extends Component {

    // Initialize

    constructor(props) {
        super(props)

        const defaultMode = this.props.formMode || PAGE_MODES.VIEW_RECORD

        this.state = {
            removed: false,
            recordData: this.props.serviceLocationRecord,
            recordFormData: deepcopy()(this.props.serviceLocationRecord),

            errors: {
                record: {}
            },

            defaultMode: defaultMode,
            mode: defaultMode,
        }
    }

    // Form helpers

    updateFormData = (formName, fieldName, fieldValue) => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState[formName][fieldName] = fieldValue
            return updatedState
        })
    }

    switchFormMode = (mode) => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState.mode = mode
            return updatedState
        })
    }

    switchToDisplay = () => {
        this.setState((state, props) => {
            let updatedState = state

            // Reset primary form data
            updatedState[FORM_DATA_NAMES_BY_MODE[state.mode]] = deepcopy()(this.props.serviceLocationRecord)
            return updatedState
        })
        this.switchFormMode(PAGE_MODES.VIEW_RECORD)
    }

    switchToPrimaryForm = () => {
        this.switchFormMode(PAGE_MODES.EDIT_RECORD)
    }

    // Crud Record

    softDeleteRecord = (json) => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState.removed = true
            return updatedState
        })
    }

    updateSuccess = () => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState.submittingRecord = false
            delete state.recordFormData.errors.cleaned_service_location_physical_address_street
            updatedState.recordData = state.recordFormData
            updatedState.recordData.cleaned_service_location_physical_address_formatted = formatAddress(state.recordFormData, "cleaned_service_location_physical_address")
            updatedState.recordData.cleaned_service_location_physical_address_formatted_multiline = formatAddress(state.recordFormData, "cleaned_service_location_physical_address", true)
            updatedState.recordData.cleaned_service_location_billing_address_formatted = formatAddress(state.recordFormData, "cleaned_service_location_billing_address")
            updatedState.recordData.cleaned_service_location_billing_address_formatted_multiline = formatAddress(state.recordFormData, "cleaned_service_location_billing_address", true)

            return updatedState
        })
        this.switchFormMode(PAGE_MODES.VIEW_RECORD)
    }

    updateRecord = async (endpoint, endpointMethod, onSuccess) => {
        const dataName = "recordFormData"
        const submittingName = "submittingRecord"
        const errorDictName = "record"

        const dataManipulator = (data, state) => {
            data = {}
            data.cleaned_service_location_name = state[dataName].cleaned_service_location_name
            data.cleaned_service_location_physical_address_street = state[dataName].cleaned_service_location_physical_address_street
            data.cleaned_service_location_physical_address_unit = state[dataName].cleaned_service_location_physical_address_unit
            data.cleaned_service_location_physical_address_city = state[dataName].cleaned_service_location_physical_address_city
            data.cleaned_service_location_physical_address_state = state[dataName].cleaned_service_location_physical_address_state
            data.cleaned_service_location_physical_address_postal = state[dataName].cleaned_service_location_physical_address_postal
            data.cleaned_service_location_physical_address_country = state[dataName].cleaned_service_location_physical_address_country
            data.cleaned_service_location_billing_address_recipient = state[dataName].cleaned_service_location_billing_address_recipient
            data.cleaned_service_location_billing_address_street = state[dataName].cleaned_service_location_billing_address_street
            data.cleaned_service_location_billing_address_unit = state[dataName].cleaned_service_location_billing_address_unit
            data.cleaned_service_location_billing_address_city = state[dataName].cleaned_service_location_billing_address_city
            data.cleaned_service_location_billing_address_state = state[dataName].cleaned_service_location_billing_address_state
            data.cleaned_service_location_billing_address_postal = state[dataName].cleaned_service_location_billing_address_postal
            data.cleaned_service_location_billing_address_country = state[dataName].cleaned_service_location_billing_address_country

            data.cleaned_service_location_notes = state[dataName].cleaned_service_location_notes
            data.contacts = deepcopy()(state[dataName].contacts)

            // Clear record errors. If they still exist, they'll re-appear after processing.
            data.errors = state[dataName].errors
            delete data.errors.cleaned_service_location_physical_address_street

            return data
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, undefined, dataManipulator, undefined)
    }

    // Handle Actions

    handleActionRequest = (action) => {
        let endpoint

        switch (action) {
            case "RECORD_EDIT_SERVICE_LOCATION":
                this.switchToPrimaryForm()
                break
            case "RECORD_UPDATE_SERVICE_LOCATION":
                if (!this.state.recordData.is_created_service_location) {
                    endpoint = DjangoUrls["quickbooks_desktop:api-quickbooks-desktop-records-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.recordData.id)
                }
                else {
                    endpoint = DjangoUrls["quickbooks_desktop:api-quickbooks-desktop-created-service-location-records-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.recordData.id)
                }

                this.updateRecord(endpoint, "PATCH", this.updateSuccess)
                break
            case "RECORD_CANCEL_SERVICE_LOCATION_EDITS":
                this.switchToDisplay()
                break
            case "RECORD_IGNORE_SERVICE_LOCATION":
                if (!this.state.recordData.is_created_service_location) {
                    endpoint = DjangoUrls["quickbooks_desktop:api-quickbooks-desktop-records-ignore"](window.MARKETPLACE_ENTITY_SLUG, this.state.recordData.id)
                }
                else {
                    endpoint = DjangoUrls["quickbooks_desktop:api-quickbooks-desktop-created-service-location-records-ignore"](window.MARKETPLACE_ENTITY_SLUG, this.state.recordData.id)
                }

                this.updateRecord(endpoint, "POST", this.softDeleteRecord)
                break
            default:
                console.error(`No action handler exists for action "${action}".`)
        }
    }

    // Render

    render = () => {
        if (!this.state.removed) {
            if (this.state.mode === PAGE_MODES.VIEW_RECORD) {
                return <QuickBooksServiceLocationRecordDisplay
                    serviceLocationRecord={this.state.recordData}
                    requestAction={this.handleActionRequest}
                />
            }
            else if (this.state.mode === PAGE_MODES.EDIT_RECORD) {
                return <QuickBooksServiceLocationRecordForm
                    submitting={this.state.submittingRecord}
                    serviceLocationRecord={this.state.recordFormData}
                    errors={Object.keys(this.state.recordData.errors).length !== 0 ? this.state.recordData.errors : this.state.errors.record}
                    formErrors={this.state.errors.record}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData(FORM_DATA_NAMES_BY_MODE[this.state.mode], fieldName, fieldValue)}
                    requestAction={this.handleActionRequest}
                />
            }
            else {
                return (
                    <div className="data-panel-container data-panel-container--with-margin">
                        <div className="data-panel" aria-label="Unknown Form Mode">
                            <div className="data-panel__form">
                                <p className="data-panel__form__caption">An unhandled form mode was supplied.</p>
                            </div>
                        </div>
                    </div>
                )
            }
        }
        else {
            return null
        }
    }
}

export default QuickBooksServiceLocationRecordCard;
