import { useContext } from "react"

import { BottomSheetContext } from "./BottomSheet"
import { BottomSheetContextValues } from "./BottomSheet.types"

export default function useBottomSheet(): BottomSheetContextValues {
    const context = useContext(BottomSheetContext)
    if (!context) {
        throw new Error("useBottomSheet must be used within a BottomSheet component")
    }

    return context
}
