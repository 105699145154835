import Spinner from "@legacy/core/components/Spinner";
import formatDuration from "@utils/formatDuration";
import { Component, Fragment } from "react";
import { renderClientString } from "../../clients/utils/utils";
import ButtonGroup from "../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../core/buttons/UniversalButton";
import AttachmentHorizontalDisplay from "../../core/components/AttachmentHorizontalDisplay";
import ClientInformationDisplay from "../../core/components/ClientInformationDisplay";
import LineItemListDisplayGroup from "../../core/components/LineItemListDisplayGroup";
import BasicDisplayField from "../../core/fields/BasicDisplayField";
import CharField from "../../core/fields/CharField";
import SignatureField from "../../core/fields/SignatureField";
import { JobOriginTypes } from "../../core/utils/enums";
import { currencyFormatterForServiceCompany, formatCurrencyForServiceCompany } from "../../core/utils/utils";
import EquipmentDetailsAccordion from "../../equipment/components/EquipmentDetailsAccordion";
import { calculateEstimateAmounts } from "../../estimates/utils/utils";
import { calculateInvoiceAmounts } from "../../invoices/utils/utils";
import JobFeedAccordion from "../components/JobFeedAccordion";


class JobCompleteConfirmForm extends Component {

    componentDidMount() {
        // Return to the previous scroll
        document.querySelector(".main").scrollTo(0, this.props.returnScroll || 0)
    }

    renderButtons = () => {
        const { mode, job, confirmData, forceSkipSignature, requestAction, switchToSecondaryForm, submitting, errors, onFormDataChange, returnScroll } = this.props

        if (errors.unexpectedError) {
            return (
                <div className="data-panel__action-feedback">
                    <span className="text-invalid"><strong>An unexpected error occurred.</strong></span>
                </div>
            )
        }
        else {
            if (submitting) {
                return (
                    <div className="data-panel__action-feedback">
                        <Spinner centered={true} />
                    </div>
                )
            }
            else {
                return (
                    <ButtonGroup>
                        <ButtonGroupRow>
                            <UniversalButton type="primary" text="Confirm and Complete" handler={event => requestAction("JOB_CONFIRM")} />
                        </ButtonGroupRow>
                        {
                            !forceSkipSignature && (
                                <ButtonGroupRow>
                                    <UniversalButton type="secondary" text="Skip Signature" handler={event => requestAction("JOB_SKIP_SIGNATURE")} />
                                </ButtonGroupRow>
                            )
                        }
                        <ButtonGroupRow>
                            <UniversalButton type="secondary" text="Update Job Summary" handler={event => requestAction("JOB_UPDATE_SUMMARY")} />
                        </ButtonGroupRow>
                    </ButtonGroup>
                )
            }
        }
    }

    renderCustomIDDisplay = () => {
        const { job } = this.props

        if (job.is_job_walk) {
            if (job.estimate_custom_id) {
                return <BasicDisplayField
                    fieldName="estimate_custom_id"
                    fieldLabel="Suggested Estimate ID"
                    fieldValue={job.estimate_custom_id}
                ></BasicDisplayField>
            }
        }
        else {
            if (job.invoice_custom_id) {
                return <BasicDisplayField
                    fieldName="invoice_custom_id"
                    fieldLabel="Suggested Invoice ID"
                    fieldValue={job.invoice_custom_id}
                ></BasicDisplayField>
            }
        }
    }

    render() {
        const { mode, job, confirmData, forceSkipSignature, requestAction, switchToSecondaryForm, submitting, errors, onFormDataChange, returnScroll, addToastToQueue } = this.props
        const { subtotal } = job.is_job_walk ? calculateEstimateAmounts(job) : calculateInvoiceAmounts(job)
        const formPrefix = job.is_job_walk ? "Estimated " : ""
        let formCaption = ""

        if (forceSkipSignature) {
            formCaption = `Please review and confirm the ${job.is_job_walk ? "estimate" : "work"} summary before completing this ${job.is_job_walk ? "job walk" : "service"}:`
        }
        else {
            formCaption = `Please have ${renderClientString(job.service_location.external_client)} review the work summary and provide a signature to complete this service:`
        }

        return (
            <Fragment>
                <div className="data-panel-container data-panel-container--with-margin">
                    <div className="data-panel" aria-label="Job Complete Confirm Form">
                        <ClientInformationDisplay
                            client={job.service_location.external_client}
                            serviceLocation={job.service_location}
                        ></ClientInformationDisplay>
                        <div className="data-panel__form" aria-label="Job Complete Confirm">
                            <p className="data-panel__form__caption">{formCaption}</p>
                            <BasicDisplayField
                                fieldName="id"
                                fieldLabel="Roopairs Job ID"
                                fieldValue={job.custom_id ? job.custom_id : job.id}
                            ></BasicDisplayField>
                            {
                                job.origin_type !== JobOriginTypes.roopairs && (
                                    <BasicDisplayField
                                        fieldName="origin_id"
                                        fieldLabel={`${job.origin_type !== JobOriginTypes.other ? job.origin_type_label : job.origin_type_other_name} ID`}
                                        fieldValue={job.origin_id}
                                    ></BasicDisplayField>
                                )
                            }
                            <BasicDisplayField
                                fieldName="service_name"
                                fieldLabel="Service Type"
                                fieldValue={job.service_name}
                            ></BasicDisplayField>
                            <BasicDisplayField
                                fieldName="is_job_walk"
                                fieldLabel="Job Type"
                                fieldValue={job.is_callback ? "Callback" : job.is_job_walk ? "Job Walk" : "Service"}
                            ></BasicDisplayField>
                            <BasicDisplayField
                                fieldName="total_time_logged"
                                fieldLabel="Total Time Logged"
                                fieldValue={formatDuration(job.total_time_logged)}
                            ></BasicDisplayField>
                            {this.renderCustomIDDisplay()}
                            <LineItemListDisplayGroup
                                object={job}
                                labelPrefix={formPrefix}
                                formatCurrencyValue={currencyFormatterForServiceCompany(job.service_company)}
                                useTaxes={false}
                                isJobLineItem={true}
                                isShownToClients={true}
                            ></LineItemListDisplayGroup>
                            {
                                !window.CURRENT_USER.hide_financial_context && (
                                    <BasicDisplayField
                                        fieldName="subtotal"
                                        fieldLabel={`${formPrefix}Subtotal`}
                                        fieldValue={formatCurrencyForServiceCompany(subtotal, job.service_company)}
                                    ></BasicDisplayField>
                                )
                            }
                            {
                                job.attachments.length !== 0 && (
                                    <BasicDisplayField
                                        fieldName="attachments"
                                        fieldLabel="Attachments"
                                        fieldValue={<AttachmentHorizontalDisplay attachments={job.attachments} />}
                                    />
                                )
                            }
                            {
                                !forceSkipSignature && (
                                    <Fragment>
                                        <SignatureField
                                            fieldName="signature"
                                            fieldLabel="Signature"
                                            fieldValue={null}
                                            fieldOnChange={signature => onFormDataChange("signature", signature || "")}
                                            errors={errors}
                                        ></SignatureField>
                                        <CharField
                                            fieldName="signed_by"
                                            fieldLabel="Signed By"
                                            fieldValue={job.signed_by || ""}
                                            fieldOnChange={signed_by => onFormDataChange("signed_by", signed_by || "")}
                                            placeholder="Who's signing?"
                                            errors={errors}
                                        ></CharField>
                                    </Fragment>
                                )
                            }
                        </div>
                        {this.renderButtons()}
                    </div>
                </div>
                {
                    job.equipment.sort((obj, other) => obj.display_name.localeCompare(other.display_name)).map(equipment => {
                        return (
                            <div key={`equipment_${equipment.id}`} className="accordion-wrapper">
                                <EquipmentDetailsAccordion clientID={job.service_location.external_client.id} data={equipment} editable={true} addToastToQueue={addToastToQueue}/>
                            </div>
                        )
                    })
                }
                {
                    <div className="accordion-wrapper">
                        <JobFeedAccordion jobID={job.id} totalTimeLogged={job.total_time_logged} />
                    </div>
                }
            </Fragment>
        )
    }
}

export default JobCompleteConfirmForm;
