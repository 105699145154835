import { useEffect, useMemo } from "react"

import useIsDesktop from "@hooks/useIsDesktop"
import useLocalStorage from "@hooks/useLocalStorage"
import useSlugExtractor from "@hooks/useSlugExtractor"

import extractURLSearchParam from "@utils/extractURLSearchParam"

import { ObjectsView, TopBar } from "@organisms"
import { ObjectsViewType } from "@organisms/ObjectsView/ObjectsView.types"

import { AppFrame } from "@templates"

import { VENDOR_ROUTES } from "@routes/vendor"

import {
    LIST_SEARCH_PARAM_ACTIVE_TAB,
    LIST_SEARCH_PARAM_KEYWORD_SEARCH,
    LIST_SEARCH_PARAM_PHASE,
} from "@constants/searchParams"
import {
    VENDORS_LIST_CONTROLS_ACTIVE_TAB,
    VENDORS_LIST_CONTROLS_KEYWORD_SEARCH,
    VENDORS_LIST_CONTROLS_ORDERING,
    VENDORS_LIST_CONTROLS_PHASE,
    VENDORS_LIST_CONTROLS_VIEW,
} from "@constants/storage"

import useVendorTabs from "./useVendorTabs"

export default function VendorList() {
    const isDesktop = useIsDesktop()

    const [createRoute] = useSlugExtractor([VENDOR_ROUTES.CREATE])
    const [activeTabs, inactiveTabs] = useVendorTabs()

    const phaseOverride = useMemo(() => extractURLSearchParam(LIST_SEARCH_PARAM_PHASE) as ObjectPhase, [])
    const activeTabOverride = useMemo(() => extractURLSearchParam(LIST_SEARCH_PARAM_ACTIVE_TAB), [])
    const searchKeywordsOverride = useMemo(() => extractURLSearchParam(LIST_SEARCH_PARAM_KEYWORD_SEARCH), [])

    const [viewType, setViewType] = useLocalStorage<ObjectsViewType>(VENDORS_LIST_CONTROLS_VIEW, "table")
    const [phase, setPhase] = useLocalStorage<ObjectPhase>(VENDORS_LIST_CONTROLS_PHASE, "active", phaseOverride)
    const [activeTab, setActiveTab] = useLocalStorage<string>(VENDORS_LIST_CONTROLS_ACTIVE_TAB, "", activeTabOverride)
    const [searchKeywords, setSearchKeywords] = useLocalStorage<string>(
        VENDORS_LIST_CONTROLS_KEYWORD_SEARCH,
        "",
        searchKeywordsOverride,
    )

    const onShowActive = () => {
        setPhase("active")
        setActiveTab(activeTabs[0].title)
    }

    const onShowInactive = () => {
        setPhase("inactive")
        setActiveTab(inactiveTabs[0].title)
    }

    const tabs = phase === "active" ? activeTabs : inactiveTabs

    useEffect(() => {
        const isAnyTabAvailable = tabs && tabs.length > 0

        if (isAnyTabAvailable && !activeTab) {
            setActiveTab(tabs[0].title)
        }
    }, [tabs, activeTab])

    return (
        <AppFrame.Root pageId="VendorList">
            <TopBar.Root>
                <TopBar.Header>
                    <TopBar.TopBarHeaderStart>
                        <TopBar.PageTitle iconName="vendors" label="Vendors" />
                    </TopBar.TopBarHeaderStart>
                    <TopBar.TopBarHeaderEnd>
                        <TopBar.PagePresence />
                        <TopBar.SearchButton scope="Vendors" />
                        <TopBar.ViewSelector
                            viewTypes={["table"]}
                            selectedViewType={viewType}
                            setViewType={setViewType}
                        />
                        <TopBar.CTAButton destination={createRoute}>
                            {isDesktop ? "New Vendor" : null}
                        </TopBar.CTAButton>
                    </TopBar.TopBarHeaderEnd>
                </TopBar.Header>
            </TopBar.Root>
            <ObjectsView
                viewType={viewType}
                searchKeywords={searchKeywords}
                setActiveTab={setActiveTab}
                setSearchKeywords={setSearchKeywords}
                onShowOpen={onShowActive}
                onShowClosed={onShowInactive}
                phase={phase}
                orderingKey={VENDORS_LIST_CONTROLS_ORDERING}
                tabs={tabs}
                activeTab={activeTab}
                CardComponent={null}
                listTitle="Vendors"
                objectCreateRoute={createRoute}
                objectType="vendor"
            />
        </AppFrame.Root>
    )
}
