import Spinner from "@legacy/core/components/Spinner";
import formatDuration from "@utils/formatDuration";
import { Component } from "react";
import { renderServiceLocationString } from "../../clients/utils/utils";
import ButtonGroup from "../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../core/buttons/UniversalButton";
import Banner from "../../core/components/Banner";
import BasicDisplayField from "../../core/fields/BasicDisplayField";
import TextField from "../../core/fields/TextField";
import { JobOriginTypes } from "../../core/utils/enums";
import { renderJobTime } from "../utils/utils";


class JobCancelForm extends Component {

    componentDidMount() {
        // Return to the previous scroll
        document.querySelector(".main").scrollTo(0, this.props.returnScroll || 0)
    }

    renderButtons = () => {
        const {job, cancelData, requestAction, switchToPrimaryForm, submitting, errors, onFormDataChange, inventoryUsed, returnScroll} = this.props

        if (errors.unexpectedError) {
            return (
                <div className="data-panel__action-feedback">
                    <span className="text-invalid"><strong>An unexpected error occurred.</strong></span>
                </div>
            )
        }
        else {
            if (submitting) {
                return <Spinner centered={true} />
            }
            else {
                return (
                    <ButtonGroup>
                        <ButtonGroupRow>
                            <UniversalButton type="danger" text="Cancel Job" handler={event => requestAction("JOB_CANCEL_SUBMIT")} />
                            <UniversalButton type="secondary" text="Nevermind" handler={event => switchToPrimaryForm()} />
                        </ButtonGroupRow>
                    </ButtonGroup>
                )
            }
        }
    }

    render() {
        const {job, cancelData, requestAction, switchToPrimaryForm, submitting, errors, onFormDataChange, inventoryUsed, returnScroll} = this.props

        return (
            <div className="data-panel-container data-panel-container--with-margin">
                <div className="data-panel" aria-label="Job Cancel">
                    <div className="data-panel__form" aria-label="Job Cancel Form">
                        <p className="data-panel__form__caption">Please confirm that you'd like to cancel this job.</p>
                        <BasicDisplayField
                            fieldName="id"
                            fieldLabel="Roopairs Job ID"
                            fieldValue={job.custom_id ? job.custom_id : job.id}
                        ></BasicDisplayField>
                        {
                            job.origin_type !== JobOriginTypes.roopairs && (
                                <BasicDisplayField
                                    fieldName="origin_id"
                                    fieldLabel={`${job.origin_type !== JobOriginTypes.other ? job.origin_type_label : job.origin_type_other_name} ID`}
                                    fieldValue={job.origin_id}
                                ></BasicDisplayField>
                            )
                        }
                        <BasicDisplayField
                            fieldName="service_name"
                            fieldLabel="Service Type"
                            fieldValue={job.service_name}
                        ></BasicDisplayField>
                        <BasicDisplayField
                            fieldName="is_job_walk"
                            fieldLabel="Job Type"
                            fieldValue={job.is_callback ? "Callback" : job.is_job_walk ? "Job Walk" : "Service"}
                        ></BasicDisplayField>

                        <BasicDisplayField
                            fieldName="service_location"
                            fieldLabel="Service Location"
                            fieldValue={renderServiceLocationString(job.service_location, true)}
                        ></BasicDisplayField>
                        <BasicDisplayField
                            fieldName="estimated_arrival_time"
                            fieldLabel="Scheduled For"
                            fieldValue={renderJobTime(job.estimated_arrival_time, job.service_company.preferred_timezone, true)}
                        ></BasicDisplayField>
                        <BasicDisplayField
                            fieldName="estimated_duration"
                            fieldLabel="Estimated Job Duration"
                            fieldValue={formatDuration(job.estimated_duration)}
                        ></BasicDisplayField>
                        <BasicDisplayField
                            fieldName="details"
                            fieldLabel={job.is_callback ? "Reason For Callback" : "Reason For Call"}
                            fieldValue={job.details}
                        ></BasicDisplayField>

                        <TextField
                            fieldName="cancel_message"
                            fieldLabel="Cancel Reason"
                            fieldValue={cancelData.cancel_message || ""}
                            fieldOnChange={cancel_message => onFormDataChange("cancel_message", cancel_message || "")}
                            rows={3}
                            placeholder="Why was this job cancelled?"
                            errors={errors}
                        ></TextField>
                        {
                            inventoryUsed && !job.is_job_walk && job.summary_previously_updated && (
                                <Banner type="warning" text="Parts tracked in inventory were used on this job. Cancelling this job will cause those inventory parts' quantity on hand to increase by the quantities on this job." />
                            )
                        }
                    </div>
                    {this.renderButtons()}
                </div>
            </div>
        )
    }
}

export default JobCancelForm;
