import clsx from "clsx"

import { Divider } from "@atoms"

import styles from "./TableViewMenuPopoverDivider.module.scss"
import { TableViewMenuPopoverDividerProps } from "./TableViewMenuPopoverDivider.types"

export default function TableViewMenuPopoverDivider(props: TableViewMenuPopoverDividerProps) {
    const { fullWidth = false } = props

    return (
        <div
            className={clsx(styles.base, {
                [styles.fullWidth]: fullWidth,
            })}
        >
            <Divider contrast="medium" />
        </div>
    )
}
