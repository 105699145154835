import styles from "./NavigationSection.module.scss"
import { NavigationSectionProps } from "./NavigationSection.types"

export default function NavigationSection(props: NavigationSectionProps) {
    const { title, children } = props

    return (
        <nav className={styles.base}>
            <span className={styles.title}>{title}</span>
            <div className={styles.links}>{children}</div>
        </nav>
    )
}
