import Spinner from "@legacy/core/components/Spinner";
import { Component, Fragment } from "react";
import ButtonGroup from "../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../core/buttons/UniversalButton";
import Banner from "../../core/components/Banner";
import ClientInformationDisplay from "../../core/components/ClientInformationDisplay";
import BasicDisplayField from "../../core/fields/BasicDisplayField";
import DurationField from "../../core/fields/DurationField";
import GeneratedDatetimeField from "../../core/fields/GeneratedDatetimeField";
import SwitchField from "../../core/fields/SwitchField";
import { JobOriginTypes } from "../../core/utils/enums";
import { valueIsDefined } from "../../core/utils/utils";
import AssignedTechniciansField from "../fields/AssignedTechniciansField";
import { getDefaultAssignedTechnicians } from "../utils/utils";
import JobEditableDetailsFields from "./fragments/JobEditableDetailsFields";


class JobDetailsUpdateForm extends Component {

    componentDidMount() {
        // Return to the previous scroll
        document.querySelector(".main").scrollTo(0, this.props.returnScroll || 0)
    }

    renderButtons = () => {
        const {
            mode,
            submitting,
            job,
            errors,
            onFormDataChange,
            requestAction,
            switchToSecondaryForm,
            updateEquipmentSelection,
            updateReporterSelection,
            updatePointOfContactSelection,
            selectedClient,
            selectedServiceLocation,
            selectedEquipment,
            selectedReporter,
            selectedPointOfContact,
            preferredTimezone,
            defaultCountryCode,
            inventoryUsed,
            childEstimates,
            childCallbacks,
            childInvoices,
            fileStackAPIKey,
            fileStackPolicy,
            fileStackSignature,
            updateAttachments,
            returnScroll
        } = this.props

        if (errors.unexpectedError) {
            return (
                <div className="data-panel__action-feedback">
                    <span className="text-invalid"><strong>An unexpected error occurred.</strong></span>
                </div>
            )
        }
        else {
            if (submitting) {
                return (
                    <div className="data-panel__action-feedback">
                        <Spinner centered={true} />
                    </div>
                )
            }
            else {
                return (
                    <ButtonGroup>
                        <ButtonGroupRow>
                            <UniversalButton type="primary" text="Save" handler={event => requestAction("JOB_DETAILS_UPDATE")} />
                            <UniversalButton type="secondary" text="Cancel" handler={event => requestAction("JOB_DETAILS_CANCEL_EDITS")} />
                        </ButtonGroupRow>
                    </ButtonGroup>
                )
            }
        }
    }

    render() {
        const {
            mode,
            submitting,
            job,
            errors,
            onFormDataChange,
            requestAction,
            switchToSecondaryForm,
            updateEquipmentSelection,
            updateReporterSelection,
            updatePointOfContactSelection,
            selectedClient,
            selectedServiceLocation,
            selectedEquipment,
            selectedReporter,
            selectedPointOfContact,
            preferredTimezone,
            defaultCountryCode,
            inventoryUsed,
            childEstimates,
            childCallbacks,
            childInvoices,
            fileStackAPIKey,
            fileStackPolicy,
            fileStackSignature,
            updateAttachments,
            returnScroll,
            workingTechnicianOptions
        } = this.props

        const jobDoesNotHaveParent = !valueIsDefined(job.estimate) && !valueIsDefined(job.callback_to)
        const jobDoesNotHaveChildren = childEstimates.length === 0 && childCallbacks.length === 0 && childInvoices.length === 0
        const allowJobTypeEdit = jobDoesNotHaveParent && jobDoesNotHaveChildren

        return (
            <Fragment>
                <div className="data-panel-container data-panel-container--with-margin">
                    <div className="data-panel" aria-label="Job Summary Form">
                        <ClientInformationDisplay
                            client={selectedClient}
                            serviceLocation={selectedServiceLocation}
                        ></ClientInformationDisplay>
                        <div className="data-panel__form" aria-label="Job Summary">
                            <p className="data-panel__form__caption">Use the fields below to edit this job's details:</p>
                            <BasicDisplayField
                                fieldName="id"
                                fieldLabel="Roopairs Job ID"
                                fieldValue={job.custom_id ? job.custom_id : job.id}
                            ></BasicDisplayField>
                            {
                                job.origin_type !== JobOriginTypes.roopairs && (
                                    <BasicDisplayField
                                        fieldName="origin_id"
                                        fieldLabel={`${job.origin_type !== JobOriginTypes.other ? job.origin_type_label : job.origin_type_other_name} ID`}
                                        fieldValue={job.origin_id}
                                    ></BasicDisplayField>
                                )
                            }
                            <BasicDisplayField
                                fieldName="service_name"
                                fieldLabel="Service Type"
                                fieldValue={job.service_name}
                            ></BasicDisplayField>
                            <SwitchField
                                disabled={!allowJobTypeEdit}
                                fieldName="is_job_walk"
                                fieldLabel="Job Type"
                                fieldValue={job.is_job_walk}
                                fieldOnChange={is_job_walk => onFormDataChange("is_job_walk", is_job_walk)}
                                checkedText="Job Walk"
                                uncheckedText="Service"
                                errors={errors}
                            ></SwitchField>
                            {
                                inventoryUsed && job.summary_previously_updated && (
                                    <Banner type="warning" text="Parts tracked in inventory are used on this job. Changing this job's type will cause inventory parts' quantity on hand to adjust by the quantities on this job." />
                                )
                            }

                            <JobEditableDetailsFields {...this.props} />

                            <GeneratedDatetimeField
                                fieldName="estimated_arrival_time"
                                fieldLabel="Estimated Arrival Time"
                                fieldValue={job.estimated_arrival_time}
                                fieldOnChange={estimated_arrival_time => onFormDataChange("estimated_arrival_time", estimated_arrival_time || null)}
                                errors={errors}
                                preferredTimezone={preferredTimezone}
                            ></GeneratedDatetimeField>
                            <DurationField
                                key={`duration_${job.service_name}`}
                                fieldName="estimated_duration"
                                fieldLabel="Estimated Job Duration"
                                fieldValue={job.estimated_duration || ""}
                                fieldOnChange={estimated_duration => onFormDataChange("estimated_duration", estimated_duration || null)}
                                unit="hours"
                                unitMultiplier={3600}
                                errors={errors}
                            ></DurationField>

                            <AssignedTechniciansField
                                fieldName="assigned_technicians"
                                fieldLabel="Assigned Technician(s)"
                                fieldValue={getDefaultAssignedTechnicians(job, workingTechnicianOptions)}
                                fieldOnChange={assigned_technicians => onFormDataChange("assigned_technicians", assigned_technicians || [])}
                                choices={workingTechnicianOptions}
                                placeholder="Select technicians..."
                                noOptionsMessage="No Technicians Found"
                                errors={errors}
                            ></AssignedTechniciansField>
                        </div>
                        {this.renderButtons()}
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default JobDetailsUpdateForm;
