// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UserProfileButton-base__S3ymo{position:relative}.UserProfileButton-button__t2yTV{display:flex;align-items:center;justify-content:center;padding:var(--size-2);border-radius:var(--border-radius-full);opacity:1;cursor:pointer;transition:opacity var(--duration-fast)}.UserProfileButton-button__t2yTV:hover{opacity:.8}.UserProfileButton-button__t2yTV:active{opacity:.5}.UserProfileButton-button__t2yTV:focus-visible{outline:var(--gray-12) solid var(--size-0_5);outline-offset:calc(var(--size-0_5)*-1)}.UserProfileButton-button__t2yTV.UserProfileButton-disabled__YDfN5{opacity:.4;cursor:not-allowed;pointer-events:none}@media only screen and (min-width: 64rem){.UserProfileButton-button__t2yTV{padding:calc(var(--size-1) + var(--size-0_25))}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "UserProfileButton-base__S3ymo",
	"button": "UserProfileButton-button__t2yTV",
	"disabled": "UserProfileButton-disabled__YDfN5"
};
export default ___CSS_LOADER_EXPORT___;
