const EstimateStatuses = {
    pending: 0,
    sent: 1,
    approved: 2,
    won: 3,
    lost: 5,
    cancelled: 6,
}

const InvoiceStatuses = {
    pending: 0,
    sent: 1,
    paid: 2,
    voided: 3,
    forgiven: 4,
}

const JobStatuses = {
    pending: 0,
    scheduled: 1,
    active: 2,
    paused: 3,
    on_hold: 4,
    completed: 5,
    finalized: 6,
    cancelled: 7,
}

const PurchaseOrderStatuses = {
    pending: 0,
    sent: 1,
    acknowledged: 2,
    in_transit: 3,
    delivered: 4,
    stocked: 5,
    paid: 6,
    cancelled: 7,
}

const ClientTypes = {
    business: 1,
    individual: 2,
}

const IndustryTypes = {
    residential: 1,
    commercial: 2,
    industrial: 3,
    government: 4,
}

const PriceBookItemTypes = {
    service: 1,
    part: 2,
    other: 3,
    discount: 4,
    tax: 5,
}

const LineItemUnitTypes = {
    hourly: 1,
    flat_rate: 2,
}

const InventoryLocationTypes = {
    warehouse: 1,
    drop_point: 2,
    truck: 3,
}

const CustomIDGenerationModes = {
    off: 0,
    manual: 1,
    automatic: 2,
}

const PaymentMethods = {
    other: 0,
    cash: 1,
    check: 2,
    card: 3,
    ach: 4,
    stripe_card: 5,
}

const EquipmentCategories = {
    other: 0,
    cooking_equipment: 1,
    fryers: 2,
    ice_machines: 3,
    ovens_and_ranges: 4,
    concession_and_condiment_equipment: 5,
    dishwashing_equipment: 6,
    holding_and_warming_equipment: 7,
    refrigeration: 8,
    beverage_equipment: 9,
    food_preparation_equipment: 10,
    laundry: 11,
    plumbing: 12,
    air_purifiers: 13,
    worktable_shelf_and_transport_cart: 14,
    hvac: 15,
    water_filtration: 16,
    lighting_and_electrical: 17,
}

const ShippingCarriers = {
    other: 0,
    usps: 1,
    stamps_com: 2,
    fedex: 3,
    ups: 4,
    dhl_express: 5,
    dhl_global_mail: 6,
    canada_post: 7,
    australia_post: 8,
    firstmile: 9,
    asendia: 10,
    ontrac: 11,
    apc: 12,
    newgistics: 13,
    globegistics: 14,
    rr_donnelley: 15,
    imex: 16,
    access_worldwide: 17,
    purolator_ca: 18,
    sendle: 19,
}

const ShippingMethods = {
    other: 0,
    ground: 1,
    next_day_early_am: 2,
    next_day_air: 3,
    two_day_air: 4,
}

const JobOriginTypes = {
    other: 100,
    roopairs: 0,
    service_channel: 1,
    corrigo: 2,
    ecotrak: 3,
}


export { ClientTypes, CustomIDGenerationModes, EquipmentCategories, EstimateStatuses, IndustryTypes, InventoryLocationTypes, InvoiceStatuses, JobOriginTypes, JobStatuses, LineItemUnitTypes, PaymentMethods, PriceBookItemTypes, PurchaseOrderStatuses, ShippingCarriers, ShippingMethods }
