
function TagListRenderer(props) {
    const { tags, forceSingle=false } = props

    const count = tags.length

    if (count > 2 || (forceSingle && count > 1)) {
        return <div className="tag-list">
            <div className="tag-list-tag data-tag">{tags[0]}</div>
            <div className="tag-list-tag data-tag">{`+${count - 1} more`}</div>
        </div>
    }
    else if (count === 2) {
        return <div className="tag-list">
            <div className="tag-list-tag data-tag">{tags[0]}</div>
            <div className="tag-list-tag data-tag">{tags[1]}</div>
        </div>
    }
    else if (count === 1) {
        return <div className="tag-list">
            <div className="tag-list-tag data-tag">{tags[0]}</div>
        </div>
    }
    else {
        return null
    }
}

export default TagListRenderer
