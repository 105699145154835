import { Component } from "react";
import deepcopy from "rfdc";
import WrapperButton from "../../core/buttons/WrapperButton";
import { format_phone_number_with_extension } from "../../core/utils/utils";


class InlineContactDisplay extends Component {

    renderInvoiceReminderPreference = (contact) => {
        if (contact.receive_invoice_reminders_via_email && contact.receive_invoice_reminders_via_sms) {
            return "Invoice reminders via Email and SMS"
        } else if (contact.receive_invoice_reminders_via_email) {
            return "Invoice reminders via Email"
        }
        else if (contact.receive_invoice_reminders_via_sms) {
            return "Invoice reminders via SMS"
        }
        else {
            return "\u2014"
        }
    }

    renderEstimateReminderPreference = (contact) => {
        if (contact.receive_estimate_reminders_via_email && contact.receive_estimate_reminders_via_sms) {
            return "Estimate reminders via Email and SMS"
        } else if (contact.receive_estimate_reminders_via_email) {
            return "Estimate reminders via Email"
        }
        else if (contact.receive_estimate_reminders_via_sms) {
            return "Estimate reminders via SMS"
        }
        else {
            return "\u2014"
        }
    }

    render() {
        const { contact, requestAction} = this.props
        let errors = deepcopy()(contact.errors || {})

        const nameValue = contact.name || "\u2014"
        const phoneValue = errors.phone ? `${contact.phone}${contact.phone_extension ? " x" : ""}${contact.phone_extension}` : contact.phone ? format_phone_number_with_extension(contact.phone, contact.phone_extension) : "\u2014"
        const emailValue = contact.email || "\u2014"

        const contactString = `${nameValue !== "\u2014" ? nameValue : "(No Name)"}: ${phoneValue}, ${emailValue}`

        return (
            <WrapperButton key={`${contact.id}_${contact.name}_${contact.phone}_${contact.phone_extension}_${contact.email}`} type="object_link" handler={event => requestAction("CONTACT_EDIT")} title={contactString} ariaLabel="Update Contact">
                <div className="data-panel__list-select__option__label contact-label">
                    {
                        contact.primary && (
                            <div className="contact-label__row contact-label-primary">
                                <div className="contact-label__field" aria-label="Primary">
                                    <div className="contact-label__field-value"><span className="data-tag" style={{margin: "0", width: "100%"}}>Primary Contact</span></div>
                                </div>
                            </div>
                        )
                    }
                    {errors.non_field_error && <div className="data-panel__form__field__errors" aria-label="Non Field Error">{errors.non_field_error}</div>}
                    <div className="contact-label__row">
                        <div className="contact-label__field" aria-label="Name">
                            <div className={"contact-label__field-label" + (errors.name ? " invalid" : "")} aria-hidden="true">Name</div>
                            <div className="contact-label__field-value">{nameValue}</div>
                            {errors.name && <div className="data-panel__form__field__errors" aria-label="Field Errors">{errors.name}</div>}
                        </div>
                    </div>
                    <div className="contact-label__row">
                        <div className="contact-label__field" aria-label="Phone">
                            <div className={"contact-label__field-label" + (errors.phone ? " invalid" : "")} aria-hidden="true">Phone</div>
                            <div className="contact-label__field-value">{phoneValue}</div>
                            {errors.phone && <div className="data-panel__form__field__errors" aria-label="Field Errors">{errors.phone}</div>}
                        </div>
                    </div>
                    <div className="contact-label__row">
                        <div className="contact-label__field" aria-label="Email">
                            <div className={"contact-label__field-label" + (errors.email ? " invalid" : "")} aria-hidden="true">Email</div>
                            <div className="contact-label__field-value">{emailValue}</div>
                            {errors.email && <div className="data-panel__form__field__errors" aria-label="Field Errors">{errors.email}</div>}
                        </div>
                    </div>
                    {
                        (contact.attached_to === "external_client" || contact.attached_to === "service_location") && (
                            <div className="contact-label__row">
                                <div className="contact-label__field" aria-label="Reminders">
                                    <div className="contact-label__field-label" aria-hidden="true">Reminders</div>
                                    <div className="contact-label__field-value">{this.renderInvoiceReminderPreference(contact)}</div>
                                    <div className="contact-label__field-value">{this.renderEstimateReminderPreference(contact)}</div>
                                </div>
                            </div>
                        )
                    }
                </div>
                <div className="data-panel__list-select__option__icon"><i className="fa-sharp fa-angle-right" aria-hidden="true"></i></div>
            </WrapperButton>
        )
    }
}

export default InlineContactDisplay;
