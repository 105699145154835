import { APCAcontrast, sRGBtoY } from "apca-w3";
import Color from "color";
import { colorParsley } from "colorparsley";
import moment from "moment-timezone";
import pluralize from "pluralize";
import { formatPhoneNumber } from "react-phone-number-input";
import { PriceBookItemTypes } from "./enums";

function snakeCaseToTitleCase(value) {
    return value.split("_").map(word => word[0].toUpperCase() + word.slice(1).toLowerCase()).join(" ")
}

function valueIsDefined(value) {
    return value !== "" && value !== null && value !== undefined
}

function validateSendContact(contact, requiredFields) {
    let isValid = true
    let errors = {}

    requiredFields.forEach(fieldName => {
        if (!valueIsDefined(contact[fieldName])) {
            isValid = false
            errors[fieldName] = "This field is required."
        }
    })

    return { isValid, errors }
}

// We're keeping this name the same as its python equivalent to aid in search
function format_phone_number_with_extension(phone_number, extension) {
    let extension_string = ""

    if (phone_number && extension) {
        extension_string += ` x${extension}`
    }

    return formatPhoneNumber(phone_number) + extension_string
}

function formatLocalTime(datetime, preferredTimezone, short=false, addDay=false, addFullDay=false, addYear=false) {
    const localTime = moment(datetime).tz(preferredTimezone);

    let dateFormatString
    let timeFormatString

    if (short) {
        dateFormatString = "MMM D"
        timeFormatString = "h:mmA"
    }
    else {
        dateFormatString = "MMM Do"
        timeFormatString = "h:mm A"
    }

    if (addDay || addFullDay) {
        dateFormatString = `${addFullDay ? "dddd" : "ddd"}, ` + dateFormatString
    }

    if (addYear) {
        dateFormatString = dateFormatString + ", Y"
    }

    const localDateString = localTime.format(dateFormatString)
    const localTimeString = localTime.format(timeFormatString)

    return {localDateString, localTimeString, localDateTimeString: `${localDateString} - ${localTimeString}`}
}

function formatDateTime(datetime, preferredTimezone, options) {
    const { short=false, addDay=false, addFullDay=false, addYear=false } = options
    return formatLocalTime(datetime, preferredTimezone, short, addDay, addFullDay, addYear)
}

function formatServiceDates(serviceStartDate, serviceEndDate, preferredTimezone) {
    let formattedDates = "--"
    let formattedLabel = "Service Date(s)"

    if (valueIsDefined(serviceStartDate) && valueIsDefined(serviceEndDate)) {
        const formattedServiceStartDate = formatLocalTime(serviceStartDate, preferredTimezone, true, false, false, true).localDateString
        const formattedServiceEndDate = formatLocalTime(serviceEndDate, preferredTimezone, true, false, false, true).localDateString

        if (formattedServiceStartDate === formattedServiceEndDate) {
            formattedDates = formattedServiceStartDate
            formattedLabel = "Service Date"
        }
        else {
            formattedDates = `${formattedServiceStartDate} - ${formattedServiceEndDate}`
            formattedLabel = "Service Dates"
        }
    }

    return {formattedDates, formattedLabel}
}

function formatCurrency(value, currencyCode, languageCode) {
    return new Intl.NumberFormat(languageCode, { style: "currency", currency: currencyCode }).format(value)
}

function formatCurrencyForServiceCompany(value, serviceCompany) {
    if (valueIsDefined(value)) {
        return formatCurrency(value, serviceCompany.currency_code, serviceCompany.language_code)
    }
}

function formatAddress(obj, fieldPrefix, multiline=false) {
    const recipient = obj[`${fieldPrefix}_recipient`] || ""

    const fieldsLine1 = [
        obj[`${fieldPrefix}_street`] || "",
        obj[`${fieldPrefix}_unit`] || ""
    ]
    const addressLine1 = fieldsLine1.filter(field => field !== "").join(" ")

    const fieldsLine2 = [
        obj[`${fieldPrefix}_city`] || "",
        obj[`${fieldPrefix}_state`] || ""
    ]
    const postal = obj[`${fieldPrefix}_postal`] || ""
    const formattedPostal = postal ? ` ${postal}` : ""
    const addressLine2 = fieldsLine2.filter(field => field !== "").join(", ") + formattedPostal

    let addressLines
    if (recipient !== "") {
        addressLines = [recipient, addressLine1, addressLine2]
    }
    else {
        addressLines = [addressLine1, addressLine2]
    }

    const lineSeparator = multiline ? "\n" : ", "

    return addressLines.filter(line => line !== "").join(`${lineSeparator}`)
}

function getLineItemUnits(lineItem) {
    let quantityUnit = ""
    let priceUnit = ""
    let priceLabel = "Price"

    if (lineItem.line_item_type === 1 && lineItem.unit_type === 1) {
        if (parseInt(lineItem.quantity) != 1) {
            quantityUnit = "hrs"
        }
        else {
            quantityUnit = "hr"
        }

        priceUnit = "/hr"
        priceLabel = "Rate"
    }

    return {quantityUnit, priceUnit, priceLabel}
}

function currencyFormatter(currencyCode, languageCode) {
    return (value) => formatCurrency(value, currencyCode, languageCode)
}

function currencyFormatterForServiceCompany(serviceCompany) {
    return (value) => formatCurrencyForServiceCompany(value, serviceCompany)
}

function getCurrencySymbol(currencyCode, languageCode) {
    return new Intl.NumberFormat(languageCode, {style: "currency", currency: currencyCode, minimumFractionDigits: 0, maximumFractionDigits: 0}).format(0).replace(/\d/g, '').trim()
}

function getCurrencySymbolForServiceCompany(serviceCompany) {
    return getCurrencySymbol(serviceCompany.currency_code, serviceCompany.language_code)
}

function tintColor(color, amount) {
    return Color(color).mix(Color("white"), amount).hex()
}

function renderInvoiceEstimateDate(date, preferredTimezone, alertPastDue=false, prefix=null) {
    const { localDateString } = formatLocalTime(date, preferredTimezone, true, false, false, true)
    const isPastDue = alertPastDue && moment.tz(date, preferredTimezone).isBefore()

    return <span className={isPastDue ? "text-overdue" : ""}>{prefix}{localDateString}</span>
}

function isPaginatedResponse(response) {
    return response?.hasOwnProperty("results") && response?.hasOwnProperty("next") && response?.hasOwnProperty("previous")
}

async function sendDataToServer(container, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, dataManipulator, setErrors) {
    if (onSuccess === undefined) {
        onSuccess = (json) => {}
    }

    if (onError === undefined) {
        onError = () => {}
    }

    if (dataManipulator === undefined) {
        dataManipulator = (data, state) => data
    }

    if (setErrors === undefined) {
        setErrors = (fieldName, message, errorDict) => errorDict[fieldName] = message
    }

    container.setState((state, props) => {
        let updatedState = state
        updatedState[submittingName] = true
        updatedState.errors[errorDictName] = {}
        return updatedState
    })

    var headers = new Headers();
    headers.append("X-CSRFToken", Cookies.get("csrftoken"))
    headers.append("Accept", "application/json")
    headers.append("Content-Type", "application/json")

    let data = dataManipulator(container.state[dataName], container.state)

    const request = new Request(
        endpoint,
        {method: endpointMethod, headers: headers, body: JSON.stringify(data), credentials: "same-origin"}
    )
    const response = await fetch(request)
    let json

    if (response.ok) {
        if (response.status === 204) {
            json = null
        }
        else {
            json = await response.json()
        }
        onSuccess(json)
    }
    else {
        onError()

        try {
            json = await response.json()
        }
        catch (exc) {
            console.error(exc)
            console.error(response)

            container.setState((state, props) => {
                let updatedState = state

                updatedState[submittingName] = false
                updatedState.errors[errorDictName].unexpectedError = true

                return updatedState
            })

            return
        }

        container.setState((state, props) => {
            let updatedState = state
            updatedState[submittingName] = false

            if (response.status !== 400) {
                console.error(json);
                updatedState.errors[errorDictName].unexpectedError = true
            }

            if (Array.isArray(json)) {
                console.error(json);
                updatedState.errors[errorDictName].unexpectedError = true
            }
            else {
                Object.getOwnPropertyNames(json).forEach(key => {
                    let error
                    if (key === "unexpected_error") {
                        console.error(json);
                        updatedState.errors[errorDictName].unexpectedError = true
                    }
                    else if (json[key].length === 1 && typeof(json[key][0]) === "string") {
                        error = json[key][0]
                    }
                    else if (typeof(json[key]) === "object") {
                        error = json[key].map(nestedError => {
                            Object.getOwnPropertyNames(nestedError).forEach(key => nestedError[key] = nestedError[key][0])
                            return nestedError
                        })
                    }
                    setErrors(key, error, updatedState.errors[errorDictName])
                })

                if (Object.keys(updatedState.errors[errorDictName]).length === 0) {
                    console.error(json);
                    updatedState.errors[errorDictName].unexpectedError = true
                }
                else {
                    if (updatedState.errors[errorDictName].unexpectedError !== true) {
                        // Scroll to first error
                        const fieldName = Object.keys(updatedState.errors[errorDictName])[0]
                        const element = document.querySelector(`#div_id_${fieldName}`)

                        if (element) {
                            element.scrollIntoView()
                        }
                        else {
                            console.error(`Couldn't find field for error scrolling: #div_id_${fieldName}`)
                        }
                    }
                }
            }

            return updatedState
        })
    }
}

function getScrollbarWidth() {
    // Credit: https://davidwalsh.name/detect-scrollbar-width
    const scrollDiv = document.createElement("div")
    scrollDiv.setAttribute('style', 'width: 100px; height: 100px; overflow: scroll; position:absolute; top:-9999px;')
    document.body.appendChild(scrollDiv)
    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth
    document.body.removeChild(scrollDiv)
    return scrollbarWidth
}

const shouldUseDarkText = (color) => {
    const testColor = colorParsley(color)
    return Math.abs(APCAcontrast(sRGBtoY(colorParsley("black")), sRGBtoY(testColor))) >= Math.abs(APCAcontrast(sRGBtoY(colorParsley("white")), sRGBtoY(testColor)))
}

const convertToKebabCase = (value) => {
    return value?.toLowerCase().replaceAll(" ", "-")
}

export const getIsTaxable = (lineItem, serviceDefault, partDefault, otherDefault) => {
    const default_taxable_values = {
        [PriceBookItemTypes.service]: serviceDefault,
        [PriceBookItemTypes.part]: partDefault,
        [PriceBookItemTypes.other]: otherDefault,
    }
    return default_taxable_values[lineItem.line_item_type] ?? false
}

export const getDefaultTaxable = (priceBookItem, serviceDefault, partDefault, otherDefault) => {
    const default_taxable_values = {
        [PriceBookItemTypes.service]: serviceDefault,
        [PriceBookItemTypes.part]: partDefault,
        [PriceBookItemTypes.other]: otherDefault,
    }
    return default_taxable_values[priceBookItem.pricebook_item_type] ?? false
}

export const historyHasState = (history) => {
    const historyContainsIdxOnly = history.state && Object.keys(history.state).length === 1 && history.state.idx !== undefined
    const historyHasState = history.state && Object.keys(history.state).length

    return historyHasState && !historyContainsIdxOnly
}

export { format_phone_number_with_extension, isPaginatedResponse, sendDataToServer, snakeCaseToTitleCase, valueIsDefined, validateSendContact, formatLocalTime, formatDateTime, formatCurrency, formatCurrencyForServiceCompany, formatAddress, getCurrencySymbol, getCurrencySymbolForServiceCompany, currencyFormatter, currencyFormatterForServiceCompany, tintColor, renderInvoiceEstimateDate, formatServiceDates, getLineItemUnits, getScrollbarWidth, shouldUseDarkText, convertToKebabCase };
