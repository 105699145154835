// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PageHeader-base__qaQgH{display:flex;flex-direction:column;gap:var(--size-4);align-items:flex-start;align-self:stretch;padding:var(--size-0);border-radius:var(--border-radius-0)}.PageHeader-titleAndSubtitle__dddBR{display:flex;flex-direction:column;gap:var(--size-1);align-items:flex-start;align-self:stretch;padding:var(--size-0);border-radius:var(--border-radius-0)}.PageHeader-title__WUpzn{color:var(--gray-12)}.PageHeader-subtitle__XiLsz{font:var(--font-mobile-body-375);font-feature-settings:var(--feature-settings-mobile-body-375);letter-spacing:var(--letter-spacing-mobile-body-375);color:var(--gray-a11)}@media only screen and (min-width: 64rem){.PageHeader-subtitle__XiLsz{font:var(--font-global-body-375);font-feature-settings:var(--feature-settings-global-body-375);letter-spacing:var(--letter-spacing-global-body-375)}}.PageHeader-pageTitle__DYwzG .PageHeader-title__WUpzn{font:var(--font-mobile-heading-06);font-feature-settings:var(--feature-settings-mobile-heading-06);letter-spacing:var(--letter-spacing-mobile-heading-06)}@media only screen and (min-width: 64rem){.PageHeader-pageTitle__DYwzG .PageHeader-title__WUpzn{font:var(--font-global-heading-06);font-feature-settings:var(--feature-settings-global-heading-06);letter-spacing:var(--letter-spacing-global-heading-06)}}.PageHeader-sectionTitle___vPKg .PageHeader-title__WUpzn{font:var(--font-mobile-heading-05);font-feature-settings:var(--feature-settings-mobile-heading-05);letter-spacing:var(--letter-spacing-mobile-heading-05)}@media only screen and (min-width: 64rem){.PageHeader-sectionTitle___vPKg .PageHeader-title__WUpzn{font:var(--font-global-heading-05);font-feature-settings:var(--feature-settings-global-heading-05);letter-spacing:var(--letter-spacing-global-heading-05)}}.PageHeader-infoMessage__KAwwF{width:var(--size-full)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "PageHeader-base__qaQgH",
	"titleAndSubtitle": "PageHeader-titleAndSubtitle__dddBR",
	"title": "PageHeader-title__WUpzn",
	"subtitle": "PageHeader-subtitle__XiLsz",
	"pageTitle": "PageHeader-pageTitle__DYwzG",
	"sectionTitle": "PageHeader-sectionTitle___vPKg",
	"infoMessage": "PageHeader-infoMessage__KAwwF"
};
export default ___CSS_LOADER_EXPORT___;
