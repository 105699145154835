import { UserAvatar } from "@molecules"
import UserExtraCountAvatar from "../components/UserExtraCountAvatar"

function AssignedTechniciansRenderer(props) {
    const { technicians } = props

    const count = technicians.length

    if (count > 2) {
        return <div className="user-avatar-list">
            <UserAvatar user={technicians[0]} />
            <UserExtraCountAvatar extraCount={count - 1} />
        </div>
    }
    else if (count === 2) {
        return <div className="user-avatar-list">
            <UserAvatar user={technicians[0]} />
            <UserAvatar user={technicians[1]} />
        </div>
    }
    else if (count === 1) {
        return <div className="user-avatar-list">
            <UserAvatar user={technicians[0]} />
        </div>
    }
    else {
        return null
    }
}

export default AssignedTechniciansRenderer
