import EstimateCreateContainer from "@legacy/estimates/EstimateCreateContainer"

import useIsDesktop from "@hooks/useIsDesktop"
import useSlugExtractor from "@hooks/useSlugExtractor"
import useToastQueue from "@hooks/useToastQueue"

import { TopBar } from "@organisms"

import { AppFrame } from "@templates"

import { ESTIMATES_ROUTES } from "@routes/estimates"

export default function EstimateEdit() {
    const isDesktop = useIsDesktop()

    const estimateId = window.ESTIMATE_ID
    const isDraft = window.ESTIMATE_IS_DRAFT

    const [listRoute, estimateDetailsRoute] = useSlugExtractor(
        [ESTIMATES_ROUTES.LIST, ESTIMATES_ROUTES.DETAILS],
        estimateId,
    )
    const { addToastToQueue } = useToastQueue()

    return (
        <AppFrame.Root pageId="EstimateEdit">
            <TopBar.Root>
                <TopBar.Header>
                    <TopBar.TopBarHeaderStart>
                        <TopBar.PageTitle
                            iconName="estimates"
                            label="Estimates"
                            iconOnly={!isDesktop}
                            destination={listRoute}
                        />
                        <TopBar.VerticalDivider />
                        <TopBar.PageSubtitle
                            label={isDraft ? "New Estimate" : "Edit Estimate"}
                            backDestination={estimateDetailsRoute}
                        />
                    </TopBar.TopBarHeaderStart>
                </TopBar.Header>
            </TopBar.Root>
            <AppFrame.PageContent>
                <EstimateCreateContainer formMode="EDIT_ESTIMATE" addToastToQueue={addToastToQueue} />
            </AppFrame.PageContent>
        </AppFrame.Root>
    )
}
