
import React from 'react';
import { format_phone_number_with_extension } from '../utils/utils';

function PhoneNumberDisplay(props) {
    const { phone, phoneExtension="", hyperlink=false } = props
    let formattedPhoneNumber = format_phone_number_with_extension(phone, phoneExtension)

    if (hyperlink) {
        formattedPhoneNumber = <a href={`tel:${phone}`}>{formattedPhoneNumber}</a>
    }

    return formattedPhoneNumber
}

export default PhoneNumberDisplay
