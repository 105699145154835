import React, { Component } from 'react';


class BasicDisplayField extends Component {

    render() {
        const {fieldName, fieldLabel, fieldValue, indented=false, maxHeight, footer} = this.props
        let style

        if (maxHeight !== undefined) {
            style = {"maxHeight": maxHeight, "overflow": "auto", "display": "inline-block"}
        }

        return (
            <div className="data-panel__form__field" id={`div_id_${fieldName}`} aria-label={fieldLabel}>
                <span className="data-panel__form__field__label" aria-hidden="true">{fieldLabel}</span>
                <span className={"data-panel__form__field__value" + (indented ? " data-panel__form__field__value--indented" : "")} aria-label={fieldLabel}><span style={style}>{fieldValue}</span></span>
                {footer}
            </div>
        )
    }
}

export default BasicDisplayField;
