import React, { Component, Fragment } from 'react';


class ToggleSwitch extends Component {

    render() {
        const {fieldName, fieldValue, onToggleChange, checkedText, uncheckedText, disabled=false} = this.props

        return (
            <Fragment>
                <label className={"toggle-switch" + (fieldValue === true ? " is-checked" : "") + (disabled ? " is-disabled" : "")} data-checked-text={checkedText} data-unchecked-text={uncheckedText} htmlFor={`id_${fieldName}`} id={`label_${fieldName}`}>
                    <input
                        className="toggle-switch__checkbox"
                        id={`id_${fieldName}`}
                        name={fieldName}
                        checked={fieldValue}
                        onChange={onToggleChange}
                        type="checkbox"
                        disabled={disabled}
                        autoComplete="off"
                    />
                    <span className="toggle-switch__toggle"></span>
                </label>
            </Fragment>
        )
    }
}

export default ToggleSwitch;
