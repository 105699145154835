import { icon as fontAwesomeIcon } from "@fortawesome/fontawesome-svg-core/import.macro"
import clsx from "clsx"

import { CustomIcon, Icon, IconButton } from "@atoms"

import usePopover from "@molecules/Popover/Popover.store"

import { BottomSheet } from "@organisms"

import styles from "./PopoverHeader.module.scss"
import { PopoverHeaderProps } from "./PopoverHeader.types"

export default function PopoverHeader(props: PopoverHeaderProps) {
    const { title, showBackButton, onBackClick, icon, showSearchInput, showBorderBottom } = props

    const { isOpen } = usePopover()

    const isUsingCustomIcon = typeof icon === "string"

    return (
        <div
            className={clsx(styles.base, {
                [styles.withBackButton]: showBackButton,
                [styles.withBorderBottom]: showBorderBottom,
            })}
        >
            <div className={styles.heading}>
                {showBackButton && (
                    <IconButton
                        colorScheme="gray"
                        variant="ghost"
                        size="xs"
                        isTabbable={isOpen}
                        icon={fontAwesomeIcon({ name: "arrow-left", style: "light", family: "sharp" })}
                        onClick={onBackClick}
                        aria-label="Back to previous items"
                        noDebounce={true}
                    />
                )}
                {icon && isUsingCustomIcon ? <CustomIcon icon={icon} size={14} /> : <Icon icon={icon} size={14} />}
                <div className={styles.title}>{title}</div>
            </div>
            {showSearchInput && <BottomSheet.SearchInput />}
        </div>
    )
}
