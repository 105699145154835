import { convertToKebabCase } from "../utils/utils"

function StatusChipRenderer(props) {
    const { value, extraClasses="" } = props
    return <div className={`chip status-${convertToKebabCase(value)}${extraClasses ? ` ${extraClasses}` : ""}`}>
        <div className="dot"></div>
        <div className="text">{value}</div>
    </div>
}

export default StatusChipRenderer
