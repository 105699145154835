import useUser from "@hooks/useUser"

import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useQuery, useQueryClient } from '@tanstack/react-query'
import React from "react"
import WrapperLinkButton from "../../core/buttons/WrapperLinkButton"
import { DashboardCardRowStates } from "./DashboardCard"


async function fetchRecommendedActions(currentUser, pageSize, page, filterValue) {
    const endpoint = DjangoUrls["dashboard:api-recommended-actions-list"](currentUser.service_company.slug)

    const searchParams = new URLSearchParams()
    searchParams.append("page_size", pageSize)
    searchParams.append("page", page)
    searchParams.append("module", filterValue)

    const response = await fetch(endpoint + "?" + searchParams.toString())
    if (!response.ok) {
        throw new Error("There was an error while trying to load recommended actions.")
    }
    return await response.json()
}


function RecommendedActionsCardRow(props) {
    const { rowData } = props
    const iconMap = {
        "estimates":  icon({name: "file-lines", style: "light", family: "sharp"}),
        "jobs":  icon({name: "wrench-simple", style: "light", family: "sharp"}),
        "invoices":  icon({name: "circle-dollar", style: "light", family: "sharp"}),
        "purchase_orders":  icon({name: "basket-shopping", style: "light", family: "sharp"}),
        "pricebook":  icon({name: "tag", style: "light", family: "sharp"}),
    }

    return (
        <WrapperLinkButton destination={rowData.action_url}>
            <div className="card__data__row recommended-action" aria-label="Action Item">
                <div className="card__data__row__content-container">
                    <div className="card__data__row__icon" aria-hidden="true">
                        <div className="icon-circle-container alerting"><FontAwesomeIcon icon={iconMap[rowData.action_module]} /></div>
                    </div>
                    <div className="card__data__row__body">
                        <div className="recommended-action__title" aria-label="Action Item Title">{rowData.action_title}</div>
                        <div className="recommended-action__suggestion" aria-label="Action Item Suggestion">{rowData.action_suggestion}</div>
                    </div>
                </div>
                <div className="card__data__row__link-signifier" aria-hidden="true">
                    <i className="fa-sharp fa-solid fa-chevron-right" />
                </div>
            </div>
        </WrapperLinkButton>
    )
}


function RecommendedActionsCard(props) {
    const { pageSize, currentPage, filterSelection, setFilterOptions, setCurrentPageData } = props
    const { user:currentUser } = useUser()
    const filterValue = filterSelection?.value || ""
    const queryClient = useQueryClient()

    const { data, error, isLoading, isError } = useQuery({
        queryKey: ["recommended-actions", pageSize, currentPage, filterValue],
        queryFn: () => fetchRecommendedActions(currentUser, pageSize, currentPage, filterValue),
        staleTime: 60000,
    })

    React.useEffect(() => {
        setFilterOptions([
            {"label": "All", "value": ""},
            {"label": "Estimates", "value": "estimates"},
            {"label": "Jobs", "value": "jobs"},
            {"label": "Invoices", "value": "invoices"},
            {"label": "Purchase Orders", "value": "purchase_orders"},
            {"label": "Pricebook", "value": "pricebook"},
        ])
    }, [])

    // Send the data to the parent to render pagination
    React.useEffect(() => {
        setCurrentPageData(data)
    }, [data])

    // Prefetch the next page
    React.useEffect(() => {
        if (data?.next) {
            queryClient.prefetchQuery({
                queryKey: ["recommended-actions", pageSize, currentPage + 1, filterValue],
                queryFn: () => fetchRecommendedActions(currentUser, pageSize, currentPage + 1, filterValue),
            })
        }
    }, [data, pageSize, currentPage, filterValue, queryClient])

    return <DashboardCardRowStates
        isLoading={isLoading}
        isError={isError}
        errorMessage={error?.message}
        isEmpty={data?.results.length === 0}
        emptyIcon="fa-check"
        emptyMessage="All recommended actions are complete. Great work!"
    >
        {data?.results.map(rowData => <RecommendedActionsCardRow rowData={rowData} key={rowData.action_title} />)}
    </DashboardCardRowStates>
}


export default RecommendedActionsCard
