import React, { Component } from 'react';
import UniversalButton from '../buttons/UniversalButton';


class SearchCreateSelectField extends Component {

    render() {
        const {fieldName, fieldLabel, fieldValue, inputComponent, showButton, buttonLabel, buttonAction, disabled=false, optional=false, optionalText="optional", errors} = this.props

        return (
            <div className="data-panel__form__field" id={`div_id_${fieldName}`} aria-label={fieldLabel}>
                <label htmlFor={`id_${fieldName}`} className="data-panel__form__field__label in-form">{fieldLabel}{optional && <span className="text-optional"> ({optionalText})</span>}</label>
                <div className="data-panel__form__field--search-create-select" title={disabled && fieldValue ? fieldValue : null}>
                    {inputComponent}
                </div>
                {
                    showButton === true && (
                        <UniversalButton type="list_select_action" text={buttonLabel} handler={buttonAction} />
                    )
                }
                {errors[fieldName] && <div className="data-panel__form__field__errors" aria-label="Field Errors">{errors[fieldName]}</div>}
            </div>
        )
    }
}

export default SearchCreateSelectField;
