import { snakeCaseToTitleCase } from "../utils/utils"

function InventoryStatusRenderer(props) {
    const { value, trackInventory } = props

    const inventoryClassName = `inventory-details-text--${value}`
    const inventoryTagName = snakeCaseToTitleCase(value)

    if (trackInventory) {
        return <span className={inventoryClassName}>{inventoryTagName}</span>
    }
    else {
        return <>Untracked</>
    }
}

export default InventoryStatusRenderer
