import { RouteCRUD } from "./routes.types"

export const VENDOR_ROUTES: RouteCRUD = {
    CREATE: "/{service_company_slug}/vendors/create/",
    LIST: "/{service_company_slug}/vendors/",
    COUNT: "/{service_company_slug}/api/vendors/count/",
    LIGHTWEIGHT_LIST: "/{service_company_slug}/api/vendors/lightweight/",
    UPDATE: "/{service_company_slug}/vendors/{id}/update/",
    DETAILS: "/{service_company_slug}/vendors/{id}/",
}
