import pluralize from "pluralize";
import { Fragment } from "react";
import SearchOrCreateSelectInput from "../../core/inputs/SearchOrCreateSelectInput";
import { PriceBookItemTypes } from "../../core/utils/enums";
import { getPriceBookItemPartContext } from "../utils/utils";


const PRICEBOOK_ITEM_TYPE_NAME_MAP = {
    [PriceBookItemTypes.service]: "Service",
    [PriceBookItemTypes.part]: "Part or Material",
    [PriceBookItemTypes.other]: "Misc. Charge",
    [PriceBookItemTypes.discount]: "Discount",
}

function getPriceBookItemObjectLabel(priceBookItem) {
    return <Fragment>
        <span><strong>{priceBookItem.description}</strong><br></br></span>
        {getPriceBookItemPartContext(priceBookItem) && <span className="select-label-secondary-level"><em>{getPriceBookItemPartContext(priceBookItem)}</em><br></br></span>}
        {priceBookItem.labels.length !== 0 && <span className="select-label-secondary-level">{priceBookItem.labels.map((label) => label.name).join(", ")}</span>}
    </Fragment>
}

function priceBookItemToPriceBookItemOption(priceBookItem) {
    return {value: priceBookItem.id, label: getPriceBookItemObjectLabel(priceBookItem), object: priceBookItem}
}

function PriceBookItemSearchOrCreateSelect(props) {
    const {pricebookItemType, creatable} = props

    return <SearchOrCreateSelectInput
        objectName={PRICEBOOK_ITEM_TYPE_NAME_MAP[pricebookItemType]}
        notFoundLabel={<span>{`No ${pluralize(PRICEBOOK_ITEM_TYPE_NAME_MAP[pricebookItemType])} Found. You can add them via the PriceBook.`}</span>}
        getObjectLabel={getPriceBookItemObjectLabel}
        getCreateLabel={(value) => `Add "${value}" to PriceBook`}
        listEndpoint={DjangoUrls["pricebook:api-pricebookitem-lightweight-list"](window.MARKETPLACE_ENTITY_SLUG)}
        listEndpointKwargs={[
            ["pricebook_item_type", pricebookItemType],
            ["is_active", true],
            ["confirmed", true],
            ["ordering", "description"],
        ]}
        getDetailsEndpoint={(priceBookItem) => DjangoUrls["pricebook:api-pricebookitem-detail"](window.MARKETPLACE_ENTITY_SLUG, priceBookItem.id)}
        {...props}
    />
}

export { priceBookItemToPriceBookItemOption };
export default PriceBookItemSearchOrCreateSelect
