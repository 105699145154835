import Spinner from '@legacy/core/components/Spinner'
import debounce from 'debounce-promise'
import { Component } from 'react'
import deepcopy from 'rfdc'
import { sendDataToServer, valueIsDefined } from '../core/utils/utils'
import EquipmentForm from '../equipment/forms/EquipmentForm'
import JobEquipmentSummaryForm from './forms/JobEquipmentSummaryForm'

const FORM_MODES = {
    ADD_EQUIPMENT_SUMMARY: "ADD_EQUIPMENT_SUMMARY",
    ADD_EQUIPMENT_STANDALONE: "ADD_EQUIPMENT_STANDALONE",
    ADD_EQUIPMENT: "ADD_EQUIPMENT",
}

const FORM_MODE_SUBTITLES = {
    ADD_EQUIPMENT_SUMMARY_STANDALONE: "Edit Job Equipment",
    ADD_EQUIPMENT_SUMMARY: "Job Equipment Summary",
    ADD_EQUIPMENT_STANDALONE: "Log New Equipment",
    ADD_EQUIPMENT: "Log New Equipment",
}

const FORM_MODE_BACK_BUTTON_DISPLAY = {
    ADD_EQUIPMENT_SUMMARY: "flex",
    ADD_EQUIPMENT_STANDALONE: "none",
    ADD_EQUIPMENT: "none",
}

const PRIMARY_FORM_MODES = [FORM_MODES.ADD_EQUIPMENT_SUMMARY]
const SECONDARY_FORM_MODES = [FORM_MODES.ADD_EQUIPMENT, FORM_MODES.ADD_EQUIPMENT_STANDALONE]

const EQUIPMENT_FORM_MODES = [
    FORM_MODES.ADD_EQUIPMENT,
    FORM_MODES.ADD_EQUIPMENT_STANDALONE
]

const FORM_DATA_NAMES_BY_MODE = {
    ADD_EQUIPMENT_SUMMARY: "jobData",
    ADD_EQUIPMENT_STANDALONE: "equipmentData",
    ADD_EQUIPMENT: "equipmentData",
}

// look into this
const SUBMITTING_NAMES_BY_MODE = {
    ADD_EQUIPMENT_SUMMARY: "submittingJob",
    ADD_EQUIPMENT_STANDALONE: "submittingEquipment",
    ADD_EQUIPMENT: "submittingEquipment",
}

const ERROR_NAMES_BY_MODE = {
    ADD_EQUIPMENT_SUMMARY: "job",
    ADD_EQUIPMENT_STANDALONE: "equipment",
    ADD_EQUIPMENT: "equipment",
}


class EquipmentSummaryContainer extends Component {

    constructor(props) {
        super(props)

        const defaultMode = this.props.formMode || FORM_MODES.ADD_EQUIPMENT_SUMMARY
        this.addToastToQueue = this.props.addToastToQueue
        this.duplicateEquipmentCache = {}

        const urlParams = new URLSearchParams(window.location.search)
        const type = urlParams.get("type")
        this.standaloneMode = type === "standalone"

        this.state = {
            jobData: null,
            equipmentData: {},

            selectedEquipment: [],
            duplicateEquipmentData: [],

            errors: {
                job: {},
                equipment: {},
            },

            defaultMode: defaultMode,
            mode: defaultMode,

            equipmentCategoryOptions: window.EQUIPMENT_CATEGORIES || [],
            equipmentTypeOptions: window.EQUIPMENT_TYPES || [],
            ownershipTypeOptions: window.OWNERSHIP_TYPES || [],

            returnScroll: 0,
        }

        window.onpopstate = (event) => {
            if (event.state !== null && Object.keys(event.state).length) {
                this.setState(event.state)
            }
        }
    }

    componentDidMount = async () => {
        if (this.standaloneMode) {
            document.querySelector(".page-subtitle").innerHTML = FORM_MODE_SUBTITLES["ADD_EQUIPMENT_SUMMARY_STANDALONE"]
        }

        if (this.state.jobData === null) {
            const endpoint = DjangoUrls["jobs:api-jobs-detail"](window.MARKETPLACE_ENTITY_SLUG, window.JOB_ID)
            const response = await fetch(endpoint)
            const job = await response.json()

            this.setState((state, props) => {
                let updatedState = state

                updatedState.jobData = job
                updatedState.selectedEquipment = job.equipment

                return updatedState
            })
        }

        this.fetchDuplicateEquipment()
    }

    // Form helpers

    updateFormData = (formName, fieldName, fieldValue) => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState[formName][fieldName] = fieldValue
            return updatedState
        }, this.fetchDuplicateEquipment)
    }

    switchFormMode = (mode) => {
        const subtitle = mode === "ADD_EQUIPMENT_SUMMARY" && this.standaloneMode ? FORM_MODE_SUBTITLES["ADD_EQUIPMENT_SUMMARY_STANDALONE"] : FORM_MODE_SUBTITLES[mode]

        document.querySelector(".page-subtitle").innerHTML = subtitle
        document.querySelector(".back-button").style.display = FORM_MODE_BACK_BUTTON_DISPLAY[mode]

        if (SECONDARY_FORM_MODES.includes(mode)) {
            history.replaceState(this.state, "", "")
        }

        this.setState((state, props) => {
            let updatedState = state
            updatedState.mode = mode

            const urlParams = new URLSearchParams(window.location.search)
            urlParams.set("mode", mode.toLowerCase().replace(/_/g, "-"))

            history.pushState(updatedState, "", `?${urlParams.toString()}`);
            return updatedState
        })
    }

    switchToPrimaryForm = () => {
        this.setState((state, props) => {
            let updatedState = state

            // Clear the secondary form data
            updatedState[FORM_DATA_NAMES_BY_MODE[state.mode]] = {}
            updatedState[SUBMITTING_NAMES_BY_MODE[state.mode]] = false
            updatedState.errors[ERROR_NAMES_BY_MODE[state.mode]] = {}

            return updatedState
        }, this.fetchDuplicateEquipment)
        this.switchFormMode(this.state.defaultMode)
    }

    switchToSecondaryForm = (newFormMode, data, initialData) => {
        this.setState((state, props) => {
            let updatedState = state
            // Set the scroll state
            updatedState.returnScroll = document.querySelector(".main").scrollTop

            updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]] = {}

            if (data !== null) {
                updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]] = deepcopy()(data)
            }
            else {
                if (EQUIPMENT_FORM_MODES.includes(newFormMode)) {
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].service_location = state.jobData.service_location  // Set the service location id on the new equipment

                    // Auto-set if there's only one option; the associated field is disabled if there's only one as well
                    const valueOptions = state.equipmentCategoryOptions.filter(option => option.value !== "")
                    if (valueOptions.length == 1) {
                        updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].equipment_category = valueOptions[0].value
                    }
                }
            }

            if (initialData !== null) {
                Object.assign(updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]], initialData)
            }

            return updatedState
        })

        this.switchFormMode(newFormMode)
    }

    updateEquipmentSelection = (selectedEquipment, then=null) => {
        this.setState((state, props) => {
            let updatedState = state

            updatedState.selectedEquipment = selectedEquipment
            updatedState.jobData.equipment = selectedEquipment

            return updatedState
        }, then)
    }

    fetchDuplicateEquipment = debounce(
        async () => {
            this.setState((state, props) => {
                let updatedState = state
                updatedState.duplicateEquipmentData = []
                return updatedState
            })

            if (valueIsDefined(this.state.equipmentData.manufacturer) && valueIsDefined(this.state.equipmentData.model_number) && valueIsDefined(this.state.equipmentData.serial_number)) {
                const params = new URLSearchParams({
                    "manufacturer": this.state.equipmentData.manufacturer,
                    "model_number": this.state.equipmentData.model_number,
                    "serial_number": this.state.equipmentData.serial_number,
                })

                const endpoint = DjangoUrls["equipment:api-equipment-duplicates-list"](window.MARKETPLACE_ENTITY_SLUG, this.state.jobData.service_location.external_client.id) + `?${params.toString()}`
                let duplicateEquipment

                if (params.toString() in this.duplicateEquipmentCache) {
                    duplicateEquipment = this.duplicateEquipmentCache[params.toString()]
                }
                else {
                    const duplicateEquipmentResponse = await fetch(endpoint)
                    duplicateEquipment = await duplicateEquipmentResponse.json()
                    this.duplicateEquipmentCache[params.toString()] = duplicateEquipment
                }

                this.setState((state, props) => {
                    let updatedState = state
                    updatedState.duplicateEquipmentData = duplicateEquipment.filter(equipment => equipment.id !== this.state.equipmentData.id)
                    return updatedState
                })
            }
        }, 500
    )

    // Crud job

    updateJob = async () => {
        const endpoint = DjangoUrls["jobs:api-jobs-update-equipment"](window.MARKETPLACE_ENTITY_SLUG, window.JOB_ID)
        const successUrl = DjangoUrls["jobs:jobs-complete"](window.MARKETPLACE_ENTITY_SLUG, window.JOB_ID)
        const onSuccess = (job) => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Job equipment updated`,
                path: successUrl.split("?")[0],
                barePathOnly: true,
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Job equipment could not be updated",
            })
        }
        this.CUDJob(endpoint, "PUT", onSuccess, onError)
    }

    updateJobEquipment = async (successUrl) => {
        const endpoint = DjangoUrls["jobs:api-jobs-update-equipment"](window.MARKETPLACE_ENTITY_SLUG, window.JOB_ID)
        const onSuccess = (job) => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: "Job equipment updated",
                path: successUrl.split("?")[0],
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Job equipment could not be updated",
            })
        }
        this.CUDJob(endpoint, "PUT", onSuccess, onError)
    }

    CUDJob = async (endpoint, endpointMethod, onSuccess, onError) => {
        const dataName = "jobData"
        const submittingName = "submittingJob"
        const errorDictName = "job"

        const dataManipulator = (data, state) => {
            return {
                service_location: data.service_location.id,
                equipment_expected: true,
                equipment: data.equipment.map(equipment => equipment.id),
            }
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, dataManipulator, undefined)
    }

    // Crud equipment

    createEquipment = async () => {
        const endpoint = DjangoUrls["equipment:api-service-location-equipment-list"](window.MARKETPLACE_ENTITY_SLUG, this.state.jobData.service_location.id)
        const endpointMethod = "POST"

        const onSuccess = (equipment) => {
            this.updateEquipmentSelection([...this.state.selectedEquipment, equipment])
            this.switchToPrimaryForm()
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Equipment "${equipment.display_name}" created`,
            })
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Equipment could not be created",
            })
        }

        this.createUpdateEquipment(endpoint, endpointMethod, onSuccess, onError)
    }

    createEquipmentStandalone = async () => {
        const endpoint = DjangoUrls["equipment:api-service-location-equipment-list"](window.MARKETPLACE_ENTITY_SLUG, this.state.jobData.service_location.id)
        const successUrl = DjangoUrls["jobs:jobs-equipment-summary"](window.MARKETPLACE_ENTITY_SLUG, this.state.jobData.id) + `?type=standalone`
        const endpointMethod = "POST"

        const onSuccess = (equipment) => {
            this.updateEquipmentSelection(
                [...this.state.selectedEquipment, equipment],
                () => this.updateJobEquipment(successUrl)
            )
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Equipment "${equipment.display_name}" created`,
                path: successUrl.split("?")[0],
            })
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Equipment could not be created",
            })
        }

        this.createUpdateEquipment(endpoint, endpointMethod, onSuccess, onError)
    }

    createUpdateEquipment = async (endpoint, endpointMethod, onSuccess, onError) => {
        const dataName = "equipmentData"
        const submittingName = "submittingEquipment"
        const errorDictName = "equipment"

        const dataManipulator = (data, state) => {
            data.equipment_category = data.equipment_category || null
            data.equipment_type = data.equipment_type || null
            data.warranties = data.warranties || []

            if (data.ownership_type === "") {
                delete data.ownership_type
            }

            return data
        }

        const setErrors = (fieldName, message, errorDict) => {
            if (fieldName === "non_field_errors" && message === "The fields service_location, display_name must make a unique set.") {
                errorDict["display_name"] = "A piece of equipment with this name at this service location already exists."
            }
            else if (fieldName === "non_field_errors" && message === "There are other pieces of equipment that belong to this Client with the same manufacturer, model number, and serial number.") {
                errorDict["identifying_info"] = "Please ensure this equipment's manufacturer, model number, and serial number are unique for this Client."
            }
            else if (fieldName === "warranties") {
                errorDict["warranties"] = "Please correct the warranty errors below:"

                // Apply the nested errors
                this.setState((state, props) => {
                    let updatedState = state
                    message.map((warrantyError, index) => updatedState[FORM_DATA_NAMES_BY_MODE[state.mode]].warranties[index].errors = warrantyError)
                    return updatedState
                })
            }
            else {
                errorDict[fieldName] = message
            }
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, dataManipulator, setErrors)
    }

    // Handle Actions

    handleActionRequest = (action) => {
        switch (action) {
            case "SUBMIT_JOB_EQUIPMENT_SUMMARY":
                this.updateJob()
                break
            case "ADD_EQUIPMENT_STANDALONE":
                const successUrl = DjangoUrls["jobs:jobs-detail"](window.MARKETPLACE_ENTITY_SLUG, window.JOB_ID)
                this.updateJobEquipment(successUrl)
                break
            case "JOB_EQUIPMENT_SUMMARY_CANCEL_EDITS":
                location.assign(DjangoUrls["jobs:jobs-detail"](window.MARKETPLACE_ENTITY_SLUG, window.JOB_ID))
                break
            case "EQUIPMENT_CREATE":
                this.createEquipment()
                break
            case "EQUIPMENT_CREATE_STANDALONE":
                this.createEquipmentStandalone()
                break
            default:
                console.error(`No action handler exists for action "${action}".`)
        }
    }

    // Render

    render() {
        if (this.state.jobData === null) {
            return <Spinner centered={true} />
        }
        else {
            if (PRIMARY_FORM_MODES.includes(this.state.mode)) {
                return (
                    <JobEquipmentSummaryForm
                        mode={this.state.mode}
                        job={this.state.jobData}
                        submitting={this.state.submittingJob}
                        errors={this.state.errors.job}
                        onFormDataChange={(fieldName, fieldValue) => this.updateFormData("jobData", fieldName, fieldValue)}
                        requestAction={this.handleActionRequest}
                        switchToSecondaryForm={this.switchToSecondaryForm}
                        updateEquipmentSelection={this.updateEquipmentSelection}
                        selectedEquipment={this.state.selectedEquipment}
                        returnScroll={this.state.returnScroll}
                        addToastToQueue={this.addToastToQueue}
                    ></JobEquipmentSummaryForm>
                )
            }
            else if (EQUIPMENT_FORM_MODES.includes(this.state.mode)) {
                return <EquipmentForm
                    mode={this.state.mode}
                    submitting={this.state.submittingEquipment}
                    equipment={this.state.equipmentData}
                    errors={this.state.errors.equipment}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("equipmentData", fieldName, fieldValue)}
                    requestAction={this.handleActionRequest}
                    switchToPrimaryForm={this.switchToPrimaryForm}
                    equipmentCategoryOptions={this.state.equipmentCategoryOptions}
                    equipmentTypeOptions={this.state.equipmentTypeOptions}
                    ownershipTypeOptions={this.state.ownershipTypeOptions}
                    duplicateEquipment={this.state.duplicateEquipmentData}
                    returnScroll={0}
                ></EquipmentForm>
            }
            else {
                return (
                    <div className="data-panel-container data-panel-container--with-margin">
                        <div className="data-panel" aria-label="Unknown Form Mode">
                            <div className="data-panel__form">
                                <p className="data-panel__form__caption">An unhandled form mode was supplied.</p>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    }
}
export default EquipmentSummaryContainer;
