import React, { Component } from "react";


class ButtonGroupRow extends Component {

    render() {
        return (
            <div className="button-group__row" aria-label="Button Group Row">
                {this.props.children}
            </div>
        )
    }
}

export default ButtonGroupRow;
