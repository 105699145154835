import SettingsPaymentsConfigPanel from "@legacy/core/components/SettingsPaymentsConfigPanel"

import useToastQueue from "@hooks/useToastQueue"

import { Settings } from "@templates/index"

export default function SettingsPayments() {
    const { addToastToQueue } = useToastQueue()

    return (
        <Settings.Wrapper title="Payments">
            <SettingsPaymentsConfigPanel addToastToQueue={addToastToQueue} />
        </Settings.Wrapper>
    )
}
