import { Fragment } from "react";
import SearchOrCreateSelectInput from "../../core/inputs/SearchOrCreateSelectInput";
import { valueIsDefined } from "../../core/utils/utils";


const getJobSubStatusObjectLabel = (subStatus) => {
    return <Fragment>
        <span><strong>{subStatus.name}</strong></span>
    </Fragment>
}

export const subStatusToSubStatusOption = (subStatus) => {
    return {value: subStatus.id, label: getJobSubStatusObjectLabel(subStatus), object: subStatus}
}

function JobSubStatusSearchOrCreateSelect(props) {
    const { selectedStatus } = props

    return <SearchOrCreateSelectInput
        renderKey={`substatus_for_${selectedStatus}`}
        objectName="Sub-Status"
        refetchSelection={false}
        getObjectLabel={(subStatus) => getJobSubStatusObjectLabel(subStatus)}
        listEndpoint={DjangoUrls["jobs:api-jobs-substatuses-list"](window.MARKETPLACE_ENTITY_SLUG)}
        listEndpointKwargs={valueIsDefined(selectedStatus) ? [["associated_status", selectedStatus]] : []}
        {...props}
    />
}

export default JobSubStatusSearchOrCreateSelect
