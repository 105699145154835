import InvoiceDetailsContainer from "@legacy/invoices/InvoiceDetailsContainer"

import useToastQueue from "@hooks/useToastQueue"

import { AppFrame } from "@templates"

export default function InvoicePublicDetails() {
    const { addToastToQueue } = useToastQueue()
    return (
        <AppFrame.Root pageId="InvoicePublicDetails" navigationType="none">
            <AppFrame.PageContent>
                <InvoiceDetailsContainer addToastToQueue={addToastToQueue} />
            </AppFrame.PageContent>
        </AppFrame.Root>
    )
}
