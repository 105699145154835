import Spinner from "@legacy/core/components/Spinner";
import { Component } from "react";
import deepcopy from "rfdc";
import { currencyFormatter, historyHasState, sendDataToServer } from "../core/utils/utils";
import ClientDetailsCard from "./components/ClientDetailsCard";
import ClientDeactivateForm from "./forms/ClientDeactivateForm";
import ClientReactivateForm from "./forms/ClientReactivateForm";

const PAGE_MODES = {
    VIEW_CLIENT: "VIEW_CLIENT",
    DEACTIVATE_CLIENT: "DEACTIVATE_CLIENT",
    REACTIVATE_CLIENT: "REACTIVATE_CLIENT"
}

const PAGE_MODE_SUBTITLES = {
    VIEW_CLIENT: "Client Details",
    DEACTIVATE_CLIENT: "Deactivate",
    REACTIVATE_CLIENT: "Reactivate",
}

const PAGE_MODE_BACK_BUTTON_DISPLAY = {
    VIEW_CLIENT: "flex",
    DEACTIVATE_CLIENT: "none",
    REACTIVATE_CLIENT: "none",
}

const PRIMARY_PAGE_MODES = [PAGE_MODES.VIEW_CLIENT]
const SECONDARY_PAGE_MODES = [
    PAGE_MODES.DEACTIVATE_CLIENT,
    PAGE_MODES.REACTIVATE_CLIENT
]

const FORM_DATA_NAMES_BY_MODE = {
    VIEW_CLIENT: "clientData",
    DEACTIVATE_CLIENT: "deactivateData",
    REACTIVATE_CLIENT: "reactivateData",
}

const SUBMITTING_NAMES_BY_MODE = {
    VIEW_CLIENT: "submittingClient",
    DEACTIVATE_CLIENT: "submittingDeactivate",
    REACTIVATE_CLIENT: "submittingReactivate",
}

const ERROR_NAMES_BY_MODE = {
    VIEW_CLIENT: "client",
    DEACTIVATE_CLIENT: "deactivate",
    REACTIVATE_CLIENT: "reactivate",
}


class ClientDetailsContainer extends Component {

    // Initialize

    constructor(props) {
        super(props)

        const defaultMode = this.props.formMode || PAGE_MODES.VIEW_CLIENT
        this.addToastToQueue = this.props.addToastToQueue

        this.state = {
            clientData: null,

            deactivateData: {},
            reactivateData: {},

            errors: {
                deactivate: {},
                reactivate: {},
            },

            currencyCode: window.CURRENCY_CODE,
            languageCode: window.LANGUAGE_CODE,

            defaultMode: defaultMode,
            mode: defaultMode,

            returnScroll: 0,
        }

        window.onpopstate = (event) => {
            if (event.state !== null && Object.keys(event.state).length) {
                this.setState(event.state)
            }
        }
    }

    componentDidMount = async () => {
        if (this.state.clientData === null) {
            const clientEndpoint = DjangoUrls["clients:api-clients-detail"](window.MARKETPLACE_ENTITY_SLUG, window.CLIENT_ID)
            const clientResponse = await fetch(clientEndpoint)
            const client = await clientResponse.json()

            this.setState((state, props) => {
                let updatedState = state
                updatedState.clientData = client
                return updatedState
            })
        }

        if (historyHasState(history)) {
            if (document.querySelector(".page-subtitle")) {
                document.querySelector(".page-subtitle").innerHTML = PAGE_MODE_SUBTITLES[history.state.mode]
            }
            if (document.querySelector(".back-button")) {
                document.querySelector(".back-button").style.display = PAGE_MODE_BACK_BUTTON_DISPLAY[history.state.mode]
            }
            this.setState(history.state)
        }
    }

    // Form helpers

    updateFormData = (formName, fieldName, fieldValue) => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState[formName][fieldName] = fieldValue
            return updatedState
        })
    }

    switchFormMode = (mode) => {
        if (document.querySelector(".page-subtitle")) {
            document.querySelector(".page-subtitle").innerHTML = PAGE_MODE_SUBTITLES[mode]
        }
        if (document.querySelector(".back-button")) {
            document.querySelector(".back-button").style.display = PAGE_MODE_BACK_BUTTON_DISPLAY[mode]
        }

        if (SECONDARY_PAGE_MODES.includes(mode)) {
            history.replaceState(this.state, "", "")
        }

        this.setState((state, props) => {
            let updatedState = state
            updatedState.mode = mode
            history.pushState(updatedState, "", "?mode=" + mode.toLowerCase().replace(/_/g, "-"));
            return updatedState
        })
    }

    switchToPrimaryForm = () => {
        this.setState((state, props) => {
            let updatedState = state

            // Clear the secondary form data
            updatedState[FORM_DATA_NAMES_BY_MODE[state.mode]] = {}
            updatedState[SUBMITTING_NAMES_BY_MODE[state.mode]] = false
            updatedState.errors[ERROR_NAMES_BY_MODE[state.mode]] = {}

            return updatedState
        })
        this.switchFormMode(this.state.defaultMode)
    }

    switchToSecondaryForm = (newFormMode, data, initialData) => {
        this.setState((state, props) => {
            let updatedState = state
            // Set the scroll state
            updatedState.returnScroll = document.querySelector(".main").scrollTop

            updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]] = {}

            if (data !== null) {
                updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]] = deepcopy()(data)
            }

            if (initialData !== null) {
                Object.assign(updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]], initialData)
            }

            return updatedState
        })

        this.switchFormMode(newFormMode)
    }

    deactivateClient = async () => {
        const dataName = "deactivateData"
        const submittingName = "submittingDeactivate"
        const errorDictName = "deactivate"

        const endpoint = DjangoUrls["clients:api-clients-deactivate"](window.MARKETPLACE_ENTITY_SLUG, this.state.clientData.id)
        const successUrl = DjangoUrls["clients:clients-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.clientData.id)

        const onSuccess = () => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Client "${this.state.clientData.name}" deactivated`,
                path: successUrl,
                barePathOnly: true,
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Client could not be deactivated"
            })
        }

        await sendDataToServer(this, endpoint, "POST", dataName, submittingName, errorDictName, onSuccess, onError, undefined, undefined)
    }

    reactivateClient = async () => {
        const dataName = "reactivateData"
        const submittingName = "submittingReactivate"
        const errorDictName = "reactivate"

        const endpoint = DjangoUrls["clients:api-clients-reactivate"](window.MARKETPLACE_ENTITY_SLUG, this.state.clientData.id)
        const successUrl = DjangoUrls["clients:clients-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.clientData.id)

        const onSuccess = () => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Client "${this.state.clientData.name}" reactivated`,
                path: successUrl,
                barePathOnly: true,
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Client could not be reactivated"
            })
        }

        await sendDataToServer(this, endpoint, "POST", dataName, submittingName, errorDictName, onSuccess, onError, undefined, undefined)
    }

    // Handle Actions

    handleActionRequest = (action) => {
        switch (action) {
            case "CLIENT_EDIT":
                location.assign(DjangoUrls["clients:clients-update"](window.MARKETPLACE_ENTITY_SLUG, this.state.clientData.id))
                break
            case "CLIENT_EQUIPMENT_VIEW":
                location.assign(DjangoUrls["clients:clients-equipment"](window.MARKETPLACE_ENTITY_SLUG, this.state.clientData.id))
                break
            case "CLIENT_MERGE":
                location.assign(DjangoUrls["clients:clients-merge"](window.MARKETPLACE_ENTITY_SLUG, this.state.clientData.id))
                break
            case "CLIENT_LOCATION_MERGE":
                location.assign(DjangoUrls["clients:clients-service-locations-merge"](window.MARKETPLACE_ENTITY_SLUG, this.state.clientData.id))
                break
            case "CLIENT_REACTIVATE":
                this.switchToSecondaryForm("REACTIVATE_CLIENT", null, null)
                break
            case "CLIENT_REACTIVATE_SUBMIT":
                this.reactivateClient()
                break
            case "CLIENT_DEACTIVATE":
                this.switchToSecondaryForm("DEACTIVATE_CLIENT", null, null)
                break
            case "CLIENT_DEACTIVATE_SUBMIT":
                this.deactivateClient()
                break
            default:
                console.error(`No action handler exists for action "${action}".`)
        }
    }

    // Render

    render() {
        if (this.state.clientData === null) {
            return <Spinner centered={true} />
        }
        else {
            if (PRIMARY_PAGE_MODES.includes(this.state.mode)) {
                return <ClientDetailsCard
                    client={this.state.clientData}
                    requestAction={this.handleActionRequest}
                    switchToSecondaryForm={this.switchToSecondaryForm}
                    formatCurrencyValue={currencyFormatter(this.state.currencyCode, this.state.languageCode)}
                    returnScroll={this.state.returnScroll}
                ></ClientDetailsCard>
            }
            else if (this.state.mode === PAGE_MODES.REACTIVATE_CLIENT) {
                return <ClientReactivateForm
                    client={this.state.clientData}
                    reactivateData={this.state.reactivateData}
                    requestAction={this.handleActionRequest}
                    switchToPrimaryForm={this.switchToPrimaryForm}
                    submitting={this.state.submittingReactivate}
                    errors={this.state.errors.reactivate}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("reactivateData", fieldName, fieldValue)}
                    returnScroll={0}
                ></ClientReactivateForm>
            }
            else if (this.state.mode === PAGE_MODES.DEACTIVATE_CLIENT) {
                return <ClientDeactivateForm
                    client={this.state.clientData}
                    deactivateData={this.state.deactivateData}
                    requestAction={this.handleActionRequest}
                    switchToPrimaryForm={this.switchToPrimaryForm}
                    submitting={this.state.submittingDeactivate}
                    errors={this.state.errors.deactivate}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("deactivateData", fieldName, fieldValue)}
                    returnScroll={0}
                ></ClientDeactivateForm>
            }
            else {
                return (
                    <div className="data-panel-container data-panel-container--with-margin">
                        <div className="data-panel" aria-label="Unknown Form Mode">
                            <div className="data-panel__form">
                                <p className="data-panel__form__caption">An unhandled form mode was supplied.</p>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    }
}

export default ClientDetailsContainer;
