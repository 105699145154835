import React, { Component } from "react";
import deepcopy from "rfdc";
import { valueIsDefined } from "../../core/utils/utils";
import InlineVendorPartCostContainer from "../InlineVendorPartCostContainer";


class VendorPartCostListEditSelect extends Component {

    constructor(props) {
        super(props)

        this.state = {
            deletedVendorPartCostIDs: {},
        }
    }

    deleteVendorPartCost = (index, vendorPartCost) => {
        const { vendorPartCosts, updateVendorPartCosts } = this.props
        const vendorPartCostKey = vendorPartCost.vendor

        // Save the ID in case this description is going to be reused
        this.setState((state, props) => {
            let updatedState = state
            if (valueIsDefined(vendorPartCost.id)) {
                updatedState.deletedVendorPartCostIDs[vendorPartCostKey] = vendorPartCost.id
            }
            return updatedState
        })

        let newVendorPartCosts = deepcopy()(vendorPartCosts)
        newVendorPartCosts.splice(index, 1)
        updateVendorPartCosts(newVendorPartCosts)
    }

    updateVendorPartCost = (index, vendorPartCost) => {
        const { vendorPartCosts, updateVendorPartCosts } = this.props
        const vendorPartCostKey = vendorPartCost.vendor

        // Re-use an existing ID for a new vendorPartCost to avoid uniqueness constraint issues
        if (!valueIsDefined(vendorPartCost.id)) {
            if (vendorPartCostKey in this.state.deletedVendorPartCostIDs) {
                vendorPartCost.id = this.state.deletedVendorPartCostIDs[vendorPartCostKey]
            }
            else {
                vendorPartCost.id = null
            }
        }

        let newVendorPartCosts = deepcopy()(vendorPartCosts)

        if (vendorPartCost.preferred) {
            for (let newVendorPartCost of newVendorPartCosts) {
                newVendorPartCost.preferred = false
            }
        }

        newVendorPartCosts[index] = vendorPartCost

        updateVendorPartCosts(newVendorPartCosts)
    }

    setPrimary = (vendorPartCost) => {
        if (!valueIsDefined(vendorPartCost.preferred)) {
            const isNew = !valueIsDefined(vendorPartCost.id)
            vendorPartCost.preferred = isNew ? false : vendorPartCost.id === this.props.preferredVendorPartCostId
        }
        return vendorPartCost
    }

    renderVendorPartCost = (vendorPartCost, index) => {
        const { currencySymbol } = this.props
        return <InlineVendorPartCostContainer key={`vendorPartCost_${vendorPartCost.vendor}_${vendorPartCost.preferred || false}_${vendorPartCost.errors}`} allVendorPartCosts={this.props.vendorPartCosts} vendorPartCost={vendorPartCost} index={index} updateVendorPartCost={newVendorPartCost => this.updateVendorPartCost(index, newVendorPartCost)} deleteVendorPartCost={newVendorPartCost => this.deleteVendorPartCost(index, newVendorPartCost)} currencySymbol={currencySymbol} />
    }

    render = () => {
        const { fieldName, vendorPartCosts, preferredVendorPartCostId, updateVendorPartCosts, currencySymbol } = this.props

        return (
            <div id={`id_${fieldName}`} className="data-panel__list-select">
                {vendorPartCosts.map(this.setPrimary).map(this.renderVendorPartCost)}
            </div>
        )
    }
}

export default VendorPartCostListEditSelect;
