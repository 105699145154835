import Spinner from "@legacy/core/components/Spinner";
import { Component } from "react";
import deepcopy from "rfdc";
import { historyHasState, sendDataToServer } from "../core/utils/utils";
import ServiceLocationMergeConfirmDisplay from "./components/ServiceLocationMergeConfirmDisplay";
import ServiceLocationMergeForm from "./forms/ServiceLocationMergeForm";

const FORM_MODES = {
    MERGE_SERVICE_LOCATIONS: "MERGE_SERVICE_LOCATIONS",
    CONFIRM_MERGE: "CONFIRM_MERGE",
}

const FORM_MODE_SUBTITLES = {
    MERGE_SERVICE_LOCATIONS: "Merge Service Locations",
    CONFIRM_MERGE: "Confirm Merge",
}

const FORM_MODE_BACK_BUTTON_DISPLAY = {
    MERGE_SERVICE_LOCATIONS: "flex",
    CONFIRM_MERGE: "none",
}

const PRIMARY_FORM_MODES = [FORM_MODES.MERGE_SERVICE_LOCATIONS]
const SECONDARY_FORM_MODES = [
    FORM_MODES.CONFIRM_MERGE,
]

const FORM_DATA_NAMES_BY_MODE = {
    MERGE_SERVICE_LOCATIONS: "mergeData",
    CONFIRM_MERGE: undefined,
}

const SUBMITTING_NAMES_BY_MODE = {
    MERGE_SERVICE_LOCATIONS: "submittingMerge",
    CONFIRM_MERGE: "submittingMerge",
}

const ERROR_NAMES_BY_MODE = {
    MERGE_SERVICE_LOCATIONS: "merge",
    CONFIRM_MERGE: "merge",
}


class ServiceLocationMergeContainer extends Component {

    // Initialize

    constructor(props) {
        super(props)

        const defaultMode = this.props.formMode || FORM_MODES.MERGE_SERVICE_LOCATIONS
        this.addToastToQueue = this.props.addToastToQueue

        this.state = {
            clientData: null,
            mergeData: {},

            selectedSourceServiceLocation: null,
            selectedDestinationServiceLocation: null,

            errors: {
                merge: {},
            },

            defaultMode: defaultMode,
            mode: defaultMode,

            returnScroll: 0,
        }

        window.onpopstate = (event) => {
            if (event.state !== null && Object.keys(event.state).length) {
                this.setState(event.state)
            }
        }
    }

    componentDidMount = async () => {
        if (this.state.clientData === null) {
            const endpoint = DjangoUrls["clients:api-clients-detail"](window.MARKETPLACE_ENTITY_SLUG, window.CLIENT_ID)
            const response = await fetch(endpoint)

            let client

            if (response.ok) {
                client = await response.json()

                this.setState((state, props) => {
                    let updatedState = state
                    updatedState.clientData = client
                    return updatedState
                })
            }
        }

        if (historyHasState(history)) {
            document.querySelector(".page-subtitle").innerHTML = FORM_MODE_SUBTITLES[history.state.mode]
            document.querySelector(".back-button").style.display = FORM_MODE_BACK_BUTTON_DISPLAY[history.state.mode]
            this.setState(history.state)
        }
    }

    // Form helpers

    updateFormData = (formName, fieldName, fieldValue) => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState[formName][fieldName] = fieldValue
            return updatedState
        })
    }

    switchFormMode = (mode) => {
        document.querySelector(".page-subtitle").innerHTML = FORM_MODE_SUBTITLES[mode]

        let backButton = document.querySelector(".back-button")
        if (backButton) {
            backButton.style.display = FORM_MODE_BACK_BUTTON_DISPLAY[mode]
        }

        if (SECONDARY_FORM_MODES.includes(mode)) {
            history.replaceState(this.state, "", "")
        }

        this.setState((state, props) => {
            let updatedState = state
            updatedState.mode = mode
            history.pushState(updatedState, "", "?mode=" + mode.toLowerCase().replace(/_/g, "-"));
            return updatedState
        })
    }

    switchToPrimaryForm = () => {
        this.setState((state, props) => {
            let updatedState = state

            // Clear the secondary form data
            updatedState[FORM_DATA_NAMES_BY_MODE[state.mode]] = {}
            updatedState[SUBMITTING_NAMES_BY_MODE[state.mode]] = false
            updatedState.errors[ERROR_NAMES_BY_MODE[state.mode]] = {}

            return updatedState
        })
        this.switchFormMode(this.state.defaultMode)
    }

    switchToSecondaryForm = (newFormMode, data) => {
        this.setState((state, props) => {
            let updatedState = state
            // Set the scroll state
            updatedState.returnScroll = document.querySelector(".main").scrollTop

            updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]] = {}

            if (data !== null) {
                updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]] = deepcopy()(data)
            }

            return updatedState
        })

        this.switchFormMode(newFormMode)
    }

    updateServiceLocationSelection = (selectedServiceLocation, type) => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState.errors.merge = {}
            updatedState[`selected${type}ServiceLocation`] = selectedServiceLocation

            if (selectedServiceLocation !== null) {
                updatedState.mergeData[`${type.toLowerCase()}_service_location`] = selectedServiceLocation.id
            }

            return updatedState
        })
    }

    // Merge

    mergeServiceLocations = async () => {
        const endpoint = DjangoUrls["clients:api-clients-service-locations-merge"](window.MARKETPLACE_ENTITY_SLUG, window.CLIENT_ID)
        const endpointMethod = "POST"
        const successUrl = DjangoUrls["clients:clients-detail"](window.MARKETPLACE_ENTITY_SLUG, window.CLIENT_ID)

        const onSuccess = () => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: "Service Locations merged",
                path: successUrl,
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Service Locations could not be merged"
            })
        }

        const dataName = "mergeData"
        const submittingName = "submittingMerge"
        const errorDictName = "merge"

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, undefined, undefined)
    }

    // Handle Actions

    handleActionRequest = (action) => {
        switch (action) {
            case "MERGE":
                this.mergeServiceLocations()
                break
            case "CONFIRM_MERGE":
                if (this.state.selectedSourceServiceLocation !== null && this.state.selectedDestinationServiceLocation !== null) {
                    this.setState((state, props) => {
                        let updatedState = state
                        updatedState.errors.merge = {}
                        return updatedState
                    })
                    this.switchToSecondaryForm("CONFIRM_MERGE", null, null)
                }
                else {
                    this.setState((state, props) => {
                        let updatedState = state
                        updatedState.errors.merge = {}

                        if (this.state.selectedSourceServiceLocation === null) {
                            updatedState.errors.merge.source_service_location = ["Please select a source location."]
                        }

                        if (this.state.selectedDestinationServiceLocation === null) {
                            updatedState.errors.merge.destination_service_location = ["Please select a destination location."]
                        }

                        return updatedState
                    })
                }
                break
            case "CANCEL_MERGE":
                location.assign(DjangoUrls["clients:clients-detail"](window.MARKETPLACE_ENTITY_SLUG, window.CLIENT_ID))
                break
            default:
                console.error(`No action handler exists for action "${action}".`)
        }
    }

    // Render

    render() {
        if (this.state.clientData === null) {
            return <Spinner centered={true} />
        }
        else {
            if (PRIMARY_FORM_MODES.includes(this.state.mode)) {
                return <ServiceLocationMergeForm
                    mode={this.state.mode}
                    mergeData={this.state.mergeData}
                    errors={this.state.errors.merge}
                    client={this.state.clientData}
                    sourceServiceLocation={this.state.selectedSourceServiceLocation}
                    destinationServiceLocation={this.state.selectedDestinationServiceLocation}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("mergeData", fieldName, fieldValue)}
                    requestAction={this.handleActionRequest}
                    switchToSecondaryForm={this.switchToSecondaryForm}
                    updateSourceServiceLocationSelection={serviceLocation => {this.updateServiceLocationSelection(null, "Destination"); this.updateServiceLocationSelection(serviceLocation, "Source")}}
                    updateDestinationServiceLocationSelection={serviceLocation => this.updateServiceLocationSelection(serviceLocation, "Destination")}
                    returnScroll={this.state.returnScroll}
                ></ServiceLocationMergeForm>
            }
            else if (SECONDARY_FORM_MODES.includes(this.state.mode)) {
                return <ServiceLocationMergeConfirmDisplay
                    mode={this.state.mode}
                    submitting={this.state.submittingMerge}
                    errors={this.state.errors.merge}
                    client={this.state.clientData}
                    sourceServiceLocation={this.state.selectedSourceServiceLocation}
                    destinationServiceLocation={this.state.selectedDestinationServiceLocation}
                    requestAction={this.handleActionRequest}
                    switchToPrimaryForm={this.switchToPrimaryForm}
                    returnScroll={0}
                ></ServiceLocationMergeConfirmDisplay>
            }
            else {
                return (
                    <div className="data-panel-container data-panel-container--with-margin">
                        <div className="data-panel" aria-label="Unknown Form Mode">
                            <div className="data-panel__form">
                                <p className="data-panel__form__caption">An unhandled form mode was supplied.</p>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    }
}

export default ServiceLocationMergeContainer;
