import clsx from "clsx"

import styles from "./FakeCaret.module.scss"
import { FakeCaretProps } from "./FakeCaret.types"

export default function FakeCaret(props: FakeCaretProps) {
    const { isFilled = false } = props

    return (
        <div className={styles.base}>
            <div
                className={clsx(styles.caret, {
                    [styles.filled]: isFilled,
                })}
            />
        </div>
    )
}
