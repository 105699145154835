import { atom, useAtom } from "jotai"
import { useEffect } from "react"

const userAtom = atom<User>(null as User)

export default function useUser() {
    const [user, setUser] = useAtom(userAtom)

    useEffect(() => {
        // avoid reassigning user if it's already set
        if (!user) {
            // as a user fetch
            setUser({
                ...window.CURRENT_USER,
                isServiceTechnician: window.CURRENT_USER?.user_type === 2,
                isServiceDispatcher: window.CURRENT_USER?.user_type === 3,
            })
        }
    }, [])

    const updateUser = (fields: Partial<User>) => {
        if (user) {
            setUser({ ...user, ...fields })
        }
    }

    const isLoading = !user

    return {
        user,
        isLoading,
        updateUser,
    }
}
