import { Fragment } from "react";
import SearchOrCreateSelectInput from "../../core/inputs/SearchOrCreateSelectInput";
import { format_phone_number_with_extension } from "../../core/utils/utils";

function getContactObjectLabel(contact, restrictOptionsTo) {
    const attachmentMap = {
        "external_client": "Client",
        "service_location": "Location",
        "vendor": "Vendor",
    }

    const phone = format_phone_number_with_extension(contact.phone, contact.phone_extension)
    const email = contact.email
    let contactDetails

    if (restrictOptionsTo === "phone") {
        contactDetails = phone
    }
    else if (restrictOptionsTo === "email") {
        contactDetails = email
    }
    else {
        contactDetails = <Fragment>{phone}{(contact.phone && contact.email) ? <br></br> : ""}{email}</Fragment>
    }

    return (
        <Fragment>
            <span><strong>{contact.name || "(No Name)"}</strong><br></br></span>
            <span className="select-label-secondary-level"><em>{`${contact.primary ? "Primary " : ""}${attachmentMap[contact.attached_to] || ""} Contact`}</em><br></br></span>
            <span className="select-label-secondary-level">{contactDetails}</span>
        </Fragment>
    )
}

function contactToContactOption(contact, restrictOptionsTo) {
    return {value: contact.id, label: getContactObjectLabel(contact, restrictOptionsTo), object: contact}
}

function ContactSearchOrCreateSelect(props) {
    const { searchVendorContacts=false, vendor, client, serviceLocation, fieldName, restrictOptionsTo } = props
    let listEndpoint

    if (searchVendorContacts) {
        listEndpoint = DjangoUrls["pricebook:api-vendors-contacts-list"](window.MARKETPLACE_ENTITY_SLUG, vendor.id)
    }
    else {
        listEndpoint = DjangoUrls["clients:api-clients-service-locations-contacts-combined-list"](window.MARKETPLACE_ENTITY_SLUG, client.id, serviceLocation.id)
    }

    return <SearchOrCreateSelectInput
        renderKey={`contact_${fieldName}_select_${searchVendorContacts ? vendor.id : serviceLocation.id}`}
        objectName="Contact"
        refetchSelection={false}
        getObjectLabel={(contact) => getContactObjectLabel(contact, restrictOptionsTo)}
        listEndpoint={listEndpoint}
        listEndpointKwargs={restrictOptionsTo ? [["require_contact_detail", restrictOptionsTo]] : []}
        {...props}
    />
}

export { contactToContactOption };
export default ContactSearchOrCreateSelect
