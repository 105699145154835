import useIsDesktop from "@hooks/useIsDesktop"

import Modal from "@molecules/Modal/Modal"

import { BottomSheet } from "@organisms/index"

import { ResponsiveDialogFooterProps } from "./ResponsiveDialogFooter.types"

export default function ResponsiveDialogFooter(props: ResponsiveDialogFooterProps) {
    const { children } = props

    const isDesktop = useIsDesktop()

    return isDesktop ? (
        <Modal.Footer>
            <>{children}</>
        </Modal.Footer>
    ) : (
        <BottomSheet.Footer>
            <>{children}</>
        </BottomSheet.Footer>
    )
}
