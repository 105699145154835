import useIsDesktop from "@hooks/useIsDesktop"

import Modal from "@molecules/Modal/Modal"

import { ResponsiveDialogBodyProps } from "./ResponsiveDialogBody.types"

export default function ResponsiveDialogBody(props: ResponsiveDialogBodyProps) {
    const { children } = props

    const isDesktop = useIsDesktop()

    return isDesktop ? (
        <Modal.Body>
            <>{children}</>
        </Modal.Body>
    ) : (
        <>{children}</>
    )
}
