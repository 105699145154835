
function serviceLocationAddressASC(a, b) {
    const aAddress = a.cleaned_service_location_physical_address_formatted
    const bAddress = b.cleaned_service_location_physical_address_formatted

    if (aAddress > bAddress) {
        return 1
    }
    else if (aAddress < bAddress) {
        return -1
    }
    else {
        if (a.id > b.id) {
            return 1
        }
        else {
            return -1
        }
    }
}

export { serviceLocationAddressASC }
