import InvoiceCreateContainer from "@legacy/invoices/InvoiceCreateContainer"

import useIsDesktop from "@hooks/useIsDesktop"
import useSlugExtractor from "@hooks/useSlugExtractor"
import useToastQueue from "@hooks/useToastQueue"

import { TopBar } from "@organisms"

import { AppFrame } from "@templates"

import { INVOICES_ROUTES } from "@routes/invoices"

export default function InvoiceEdit() {
    const isDesktop = useIsDesktop()

    const invoiceId = window.INVOICE_ID
    const isDraft = window.INVOICE_IS_DRAFT

    const [listRoute, detailsRoute] = useSlugExtractor([INVOICES_ROUTES.LIST, INVOICES_ROUTES.DETAILS], invoiceId)
    const { addToastToQueue } = useToastQueue()

    return (
        <AppFrame.Root pageId="InvoiceEdit">
            <TopBar.Root>
                <TopBar.Header>
                    <TopBar.TopBarHeaderStart>
                        <TopBar.PageTitle
                            iconOnly={!isDesktop}
                            iconName="invoices"
                            label="Invoices"
                            destination={listRoute}
                        />
                        <TopBar.VerticalDivider />
                        <TopBar.PageSubtitle
                            label={isDraft ? "New Invoice" : "Edit Invoice"}
                            backDestination={detailsRoute}
                        />
                    </TopBar.TopBarHeaderStart>
                </TopBar.Header>
            </TopBar.Root>
            <AppFrame.PageContent>
                <InvoiceCreateContainer formMode="EDIT_INVOICE" addToastToQueue={addToastToQueue} />
            </AppFrame.PageContent>
        </AppFrame.Root>
    )
}
