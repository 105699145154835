import React, { Component } from "react";
import deepcopy from "rfdc";
import InlineInventoryLevelContainer from "../InlineInventoryLevelContainer";


class InventoryLevelListEditSelect extends Component {

    updateInventoryLevel = (index, inventoryLevel) => {
        const { inventoryLevels, updateInventoryLevels } = this.props

        let newInventoryLevels = deepcopy()(inventoryLevels)
        newInventoryLevels[index] = inventoryLevel

        updateInventoryLevels(newInventoryLevels)
    }

    renderInventoryLevel = (inventoryLevel, index) => {
        return <InlineInventoryLevelContainer key={`inventoryLevel_${inventoryLevel.location_name}_${inventoryLevel.errors}`} inventoryLevel={inventoryLevel} index={index} updateInventoryLevel={newInventoryLevel => this.updateInventoryLevel(index, newInventoryLevel)} />
    }

    render = () => {
        const { fieldName, inventoryLevels, updateInventoryLevels } = this.props

        return (
            <div id={`id_${fieldName}`} className="data-panel__list-select">
                {inventoryLevels.map(this.renderInventoryLevel)}
            </div>
        )
    }
}

export default InventoryLevelListEditSelect;
