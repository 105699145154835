import { DefaultValues, UseFormProps, UseFormReturn } from "react-hook-form"
import * as Yup from "yup"

type ChildrenProps<FormFields> = {
    isPending: boolean
    nonFieldError: string | undefined
    form: UseFormReturn<FormFields>
}

type FormFieldsBase = {
    [key: string]: string
}

type FormFieldsBaseWithNonFieldError = FormFieldsBase & { __all__?: string[] }

/**
 * Represents the base structure for mutation errors within forms.
 * This type can accommodate various error formats:
 *
 * 1. Object with keys corresponding to form fields and values as an array of error messages:
 *    {
 *      fieldName: ["Error message 1", "Error message 2"],
 *      anotherFieldName: ["Another error message"]
 *    }
 *
 * 2. A simple string for general error messages:
 *    "A general error occurred."
 *
 * 3. An Error object for system-level errors:
 *    new Error("A system error occurred.")
 *
 * Usage should be tailored to the context of the form and the nature of errors encountered.
 */
export type MutationErrorBase =
    | {
          [key in keyof FormFieldsBaseWithNonFieldError]: string[]
      }
    | string
    | Error

type FormConfig<FormFields> = {
    mode?: UseFormProps["mode"]
    reValidateMode?: UseFormProps["reValidateMode"]
    defaultValues?: DefaultValues<FormFields>
}

export type MutationSuccessFunctionParams<FormFields, MutationReturn> = {
    data: MutationReturn
    formValues: FormFields
}

type MutationConfig<FormFields, MutationPayload> = {
    endpoint: string
    method: "POST" | "PATCH" | "DELETE"
    genericErrorMessage: string
    genericSuccessMessage?: string
    payloadFormatter?: (formValues: FormFields) => MutationPayload
    disabled?: boolean
}

export interface GenericFormProps<
    FormFields,
    MutationReturn,
    MutationError extends MutationErrorBase,
    MutationPayload,
> {
    children: ({ isPending, nonFieldError, form }: ChildrenProps<FormFields>) => React.ReactNode

    formConfig: FormConfig<FormFields>
    formValidationSchema?: Yup.ObjectSchema<FormFields> | ReturnType<typeof Yup.lazy<Yup.ObjectSchema<FormFields>>>
    formValues?: FormFields
    onAfterFormValuesSet?: () => void
    triggerFormReset?: boolean

    mutationConfig: MutationConfig<FormFields, MutationPayload>
    onMutationError?: (error: GenericError<MutationError>) => void
    onMutationSuccess?: (params: MutationSuccessFunctionParams<FormFields, MutationReturn>) => void
    mutationTriggerPayload?: MutationPayload

    isAutoSave?: boolean
    autoSaveDebounceTimeMs?: number

    noFormTag?: boolean

    className?: string
}

export class GenericError<MutationError> extends Error {
    public error: MutationError

    constructor(message: string, mutationError: MutationError) {
        super(message)
        this.name = "GenericError"
        this.error = mutationError
    }
}
