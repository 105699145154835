import InvoiceCreate from "./InvoiceCreate/InvoiceCreate"
import InvoiceDetails from "./InvoiceDetails/InvoiceDetails"
import InvoiceEdit from "./InvoiceEdit/InvoiceEdit"
import InvoiceList from "./InvoiceList/InvoiceList"
import InvoicePublicDetails from "./InvoicePublicDetails/InvoicePublicDetails"

const List = InvoiceList
const Create = InvoiceCreate
const Edit = InvoiceEdit
const Details = InvoiceDetails
const PublicDetails = InvoicePublicDetails

export default { List, Create, Edit, Details, PublicDetails }
