import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import clsx from "clsx"
import { memo } from "react"

import useIsDesktop from "@hooks/useIsDesktop"
import useUser from "@hooks/useUser"

import { Icon, NavigationLink } from "@atoms"

import { ActivatableIcon, UserAvatar } from "@molecules"

import styles from "./NavigationItem.module.scss"
import { NavigationItemProps } from "./NavigationItem.types"

function NavigationItem(props: NavigationItemProps) {
    const {
        module,
        label,
        path,
        hasSubItems,
        isInStack = false,
        onClick,
        variant,
        isExpanded = false,
        isDisabled = false,
        isActive = undefined,
        onActive,
        className,
        showUserAvatar,
    } = props

    const isDesktop = useIsDesktop()

    const isKeyboardNavigationEnabled = !isDisabled && (!isInStack || isExpanded)

    const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        isDisabled ? event.preventDefault() : onClick?.(event)
    }

    const { user } = useUser()

    const renderIcon = (isActive: boolean) => {
        if (isInStack) {
            return null
        } else {
            return showUserAvatar ? (
                <UserAvatar user={user} shape="circle" size={isDesktop ? 20 : 24} />
            ) : (
                <ActivatableIcon className={styles.icon} isActive={isActive || isExpanded} iconName={module} />
            )
        }
    }

    return (
        <NavigationLink
            title={label}
            path={path}
            className={clsx(styles.base, className, styles[variant], {
                [styles.isInStack]: isInStack,
                [styles.disabled]: isDisabled,
                [styles.expanded]: isExpanded,
                [styles.subItemsHeader]: hasSubItems,
            })}
            activeClassName={styles.active}
            onClick={handleClick}
            isTabbable={isKeyboardNavigationEnabled}
            isActive={isActive}
            onActive={onActive}
        >
            {(isActive) => (
                <div className={styles.innerContainer}>
                    <div className={styles.iconAndText}>
                        <div className={styles.iconContainer}>{renderIcon(isActive)}</div>
                        <span className={styles.text}>{label}</span>
                    </div>

                    {hasSubItems && (
                        <div className={styles.chevronWrap}>
                            <Icon
                                size={isDesktop ? 10 : 14}
                                icon={icon({ name: "angle-left", style: "regular", family: "sharp" })}
                                className={clsx(styles.chevron, { [styles.expanded]: isExpanded })}
                                noContainer={true}
                            />
                        </div>
                    )}
                </div>
            )}
        </NavigationLink>
    )
}

export default memo(NavigationItem)
