import Accordion from '../../core/components/Accordion';
import InlineEquipmentContainer from '../InlineEquipmentContainer';


class EquipmentDetailsAccordion extends Accordion {

    fetchData = async () => {
        return this.props.data
    }

    getID = () => {
        return `equipment_${this.props.data.id}`
    }

    getTitle = () => {
        return this.state.data ? this.state.data.display_name : "Equipment Details"
    }

    getHelpText = () => {
        return "Click or tap to view or edit equipment details."
    }

    getExtraBodyClasses = () => {
        return "data-panel"
    }

    renderBody = (data) => {
        const { clientID, editable=false, showJobHistory=false, showNextButton=false, nextButtonUrl, addToastToQueue } = this.props

        return <InlineEquipmentContainer
            equipment={data}
            clientID={clientID}
            updateParentData={(data) => this.setState({data})}
            editable={editable}
            showJobHistory={showJobHistory}
            showNextButton={showNextButton}
            nextButtonUrl={nextButtonUrl}
            addToastToQueue={addToastToQueue}
        />
    }
}

export default EquipmentDetailsAccordion;
