import React, { Component } from 'react';


class DateField extends Component {

    render() {
        const {fieldName, fieldLabel, fieldValue, fieldOnChange, disabled=false, optional=false, optionalText="optional", errors} = this.props

        return (
            <div className="data-panel__form__field" id={`div_id_${fieldName}`} aria-label={fieldLabel}>
                <label htmlFor={`id_${fieldName}`} className="data-panel__form__field__label in-form">{fieldLabel}{optional && <span className="text-optional"> ({optionalText})</span>}</label>
                <div className="data-panel__form__field__input">
                    <input
                        defaultValue={fieldValue}
                        onChange={event => fieldOnChange(event.target.value)}
                        type="date"
                        id={`id_${fieldName}`}
                        name={fieldName}
                        placeholder="yyyy-mm-dd"
                        autoComplete="off"
                        className={disabled ? "is-disabled" : ""}
                        disabled={disabled}
                    />
                </div>
                {errors[fieldName] && <div className="data-panel__form__field__errors" aria-label="Field Errors">{errors[fieldName]}</div>}
            </div>
        )
    }
}

export default DateField;
