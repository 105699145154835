import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import clsx from "clsx"
import { useState } from "react"
import { useHotkeys } from "react-hotkeys-hook"

import useIsAnyDialogOpen from "@hooks/useIsAnyDialogOpen"

import { HotKey, Icon } from "@atoms"

import { HotKeyStack } from "@molecules/index"

import SearchModal from "@organisms/SideBar/MainSideBar/SideBarSearchButton/SearchModal/SearchModal"

import styles from "./SideBarSearchButton.module.scss"
import { SideBarSearchButtonProps } from "./SideBarSearchButton.props"

const SEARCH_KEYBOARD_HOTKEY = "Mod+K"

export default function SideBarSearchButton(props: SideBarSearchButtonProps) {
    const { isDisabled } = props

    const [isOpen, setIsOpen] = useState<boolean>(false)

    const openModal = () => (!isDisabled ? setIsOpen(true) : null)

    const { isAnyDialogOpen } = useIsAnyDialogOpen()

    useHotkeys(
        SEARCH_KEYBOARD_HOTKEY,
        (event) => {
            // Prevent the browser's search from opening
            event.preventDefault()
            setIsOpen(!isOpen)
        },
        {
            enableOnFormTags: ["input", "textarea", "select"],
            enabled: !isAnyDialogOpen && !isDisabled,
        },
        [isOpen, isAnyDialogOpen],
    )

    return (
        <>
            <button className={clsx(styles.base, { [styles.isDisabled]: isDisabled })} onClick={openModal}>
                <div className={styles.iconAndText}>
                    <Icon
                        className={styles.icon}
                        icon={icon({
                            name: "search",
                            style: "regular",
                            family: "sharp",
                        })}
                        size={14}
                    />
                    <span className={styles.label}>Search anything</span>
                </div>
                {!isDisabled && (
                    <HotKeyStack>
                        <HotKey symbolName="Mod" variant="solid" />
                        <HotKey symbolName="K" variant="solid" />
                    </HotKeyStack>
                )}
            </button>
            <SearchModal isOpen={isOpen} onOpenChange={setIsOpen} />
        </>
    )
}
