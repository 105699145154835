import clsx from "clsx"
import { memo } from "react"

import useIsScrolling from "@hooks/useIsScrolling"

import { ScrollableContainerProps } from "./ScrollableContainer.types"

function ScrollableContainer(props: ScrollableContainerProps) {
    const { children, scrollClassName, className } = props
    const { ref: navigationRef, isScrolling } = useIsScrolling<HTMLDivElement>()

    return (
        <div
            ref={navigationRef}
            className={clsx(className, {
                [scrollClassName]: isScrolling,
            })}
        >
            {children}
        </div>
    )
}

export default memo(ScrollableContainer)
