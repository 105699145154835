import { ColumnId, ColumnVisibility } from "@organisms/Table/Table.types"

import getColumnNameByColumnId from "./getColumnNameByColumnId"

interface GetSortingEnabledColumnsByIdsArgs {
    columnIds: ColumnId[]
    objectType: ObjectType
    columnVisibility: ColumnVisibility
}

export default function mapTableInvisibleColumns(args: GetSortingEnabledColumnsByIdsArgs) {
    const { columnIds, objectType, columnVisibility } = args

    if (columnIds?.length === 0 || !columnIds) {
        return []
    }

    return columnIds
        .map((columnId) => {
            const columnName = getColumnNameByColumnId(columnId, objectType)
            if (columnVisibility[columnId] === false) {
                return {
                    value: columnId,
                    label: columnName,
                }
            }
        })
        .filter(Boolean)
}
