import EventAccordion from "../../core/components/EventAccordion"
import { valueIsDefined } from "../../core/utils/utils"


class PriceBookItemUsageHistoryAccordion extends EventAccordion {

    fetchData = async () => {
        const { priceBookItemID } = this.props

        if (valueIsDefined(priceBookItemID)) {
            const endpoint = DjangoUrls["pricebook:api-pricebookitem-usage-history-list"](window.MARKETPLACE_ENTITY_SLUG, priceBookItemID)
            const response = await fetch(endpoint)
            return await response.json()
        }
        else {
            return null
        }
    }

    getTitle = () => {
        return "Usage History"
    }

    getEmphasis = () => {
        return "date"
    }

    getEmptyText = () => {
        return "No usage history found."
    }
}

export default PriceBookItemUsageHistoryAccordion;
