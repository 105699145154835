import { PickerOverlay } from 'filestack-react'
import { useState } from 'react'
import { createPortal } from 'react-dom'
import UniversalButton from '../buttons/UniversalButton'
import AttachmentHorizontalDisplay from '../components/AttachmentHorizontalDisplay'

const body = document.querySelector("body")

function AttachmentUploadField(props) {
    const { fieldName, fieldLabel, attachments, fileStackAPIKey, fileStackPolicy, fileStackSignature, onUploadDone, optional, optionalText, errors } = props
    const [pickerOpen, setPickerOpen] = useState(false)

    return (
        <div className="data-panel__form__field" id={`div_id_${fieldName}`} aria-label={fieldLabel}>
            <label htmlFor={`id_${fieldName}`} className="data-panel__form__field__label in-form">{fieldLabel}{optional && <span className="text-optional"> ({optionalText})</span>}</label>
            {errors[fieldName] && <div className="data-panel__form__field__errors" aria-label="Field Errors">{errors[fieldName]}</div>}
            <div><AttachmentHorizontalDisplay attachments={attachments}/></div>
            <div className="data-panel__form__field__input data-panel__form__field__input--with-list-select">
                {
                    pickerOpen && (
                        createPortal(
                            <PickerOverlay
                                apikey={fileStackAPIKey}
                                clientOptions={{
                                    security: {
                                        policy: fileStackPolicy,
                                        signature: fileStackSignature
                                    }
                                }}
                                pickerOptions={{
                                    fromSources: ["local_file_system"],
                                    maxFiles: 10,
                                    onClose: () => {
                                        setPickerOpen(false)
                                    }
                                }}
                                onUploadDone={(response) => {setPickerOpen(false); onUploadDone(response)}}
                            />
                        , body)
                    )
                }
                <UniversalButton type="list_select_action" text={<><i className="fa-sharp fa-light fa-plus" aria-hidden="true"></i>Add Attachment</>} handler={() => setPickerOpen(true)} />
            </div>
        </div>
    )
}

export default AttachmentUploadField
