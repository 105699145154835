// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AuthDivider-base__wDAkf{display:flex;gap:var(--size-5);align-items:center;justify-content:space-between;width:var(--size-full);margin:var(--size-8) 0;color:var(--gray-10);text-align:center;text-transform:uppercase;font:var(--font-mobile-caption-small-375);font-feature-settings:var(--feature-settings-mobile-caption-small-375);letter-spacing:var(--letter-spacing-mobile-caption-small-375)}@media only screen and (min-width: 64rem){.AuthDivider-base__wDAkf{font:var(--font-global-caption-small-400);font-feature-settings:var(--feature-settings-global-caption-small-400);letter-spacing:var(--letter-spacing-global-caption-small-400);margin:var(--size-6) 0}}.AuthDivider-line__lLGXX{width:var(--size-full);height:var(--size-0_25);background-color:var(--gray-a6)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "AuthDivider-base__wDAkf",
	"line": "AuthDivider-line__lLGXX"
};
export default ___CSS_LOADER_EXPORT___;
