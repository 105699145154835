import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import clsx from "clsx"
import { useMemo, useState } from "react"

import { DEFAULT_ROOT_FONT_SIZE } from "@constants/fonts"

import styles from "./Icon.module.scss"
import { IconProps, IconSize } from "./Icon.types"

export function getContainerSizeFromSize(size: IconSize | null, noContainer: boolean) {
    const containerMap: { [key in IconSize]: string } = {
        8: "var(--size-3)",
        10: "var(--size-3_5)",
        12: "var(--size-4)",
        14: "var(--size-5)",
        16: "var(--size-6)",
        18: "var(--size-6_5)",
        20: "var(--size-7_5)",
        24: "var(--size-8_5)",
        28: "var(--size-9)",
        32: "var(--size-10)",
    }

    if (noContainer) {
        return undefined
    } else {
        return containerMap[size]
    }
}

export default function Icon(props: IconProps) {
    const {
        isOverlapping,
        icon,
        iconOnMouseOver,
        size = null,
        noContainer = false,
        className,
        spin = false,
        rotation,
        containerClassName,
        iconOnActive,
        isActive,
    } = props

    const [isMouseOver, setIsMouseOver] = useState<boolean>(false)

    const fontSize = useMemo(() => (size ? `${size / DEFAULT_ROOT_FONT_SIZE}rem` : null), [size]) // Convert the pixel-based size to rem
    const containerSize = useMemo(() => getContainerSizeFromSize(size, noContainer), [size, noContainer])

    const currentIcon = useMemo(() => {
        if (isMouseOver && iconOnMouseOver) {
            return iconOnMouseOver
        } else if (isActive && iconOnActive) {
            return iconOnActive
        } else {
            return icon
        }
    }, [isMouseOver, iconOnMouseOver, icon, isActive, iconOnActive])

    return (
        <div
            className={clsx(styles.base, containerClassName, {
                [styles.overlapping]: isOverlapping,
            })}
            style={{
                // Repeating the fontSize here is necessary for the mask to work properly
                fontSize,
                ["--container-size" as string]: containerSize,
            }}
            onMouseOver={iconOnMouseOver ? () => setIsMouseOver(true) : undefined}
            onMouseOut={iconOnMouseOver ? () => setIsMouseOver(false) : undefined}
        >
            <FontAwesomeIcon
                style={{
                    fontSize,
                }}
                icon={currentIcon}
                className={className}
                spin={spin}
                rotation={rotation}
            />
        </div>
    )
}
