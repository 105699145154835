import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import { useEffect, useRef, useState } from "react"

import useIsAnyDialogOpen from "@hooks/useIsAnyDialogOpen"
import useIsDesktop from "@hooks/useIsDesktop"

import { Button } from "@atoms"

import TimelineViewMenuBottomSheet from "./TimelineViewMenuBottomSheet/TimelineViewMenuBottomSheet"
import TimelineViewMenuPopover from "./TimelineViewMenuPopover/TimelineViewMenuPopover"

export default function TimelineViewMenu() {
    const [isTimelineViewMenuOpen, setIsTimelineViewMenuOpen] = useState<boolean>(false)

    const isDesktop = useIsDesktop()

    const { setIsAnyDialogOpen } = useIsAnyDialogOpen()

    useEffect(() => {
        setIsAnyDialogOpen(isTimelineViewMenuOpen)
    }, [isTimelineViewMenuOpen])

    const openTimelineViewMenu = () => setIsTimelineViewMenuOpen(true)

    const onClose = () => setIsTimelineViewMenuOpen(false)

    const buttonRef = useRef<HTMLButtonElement>(null)

    return (
        <>
            <Button
                colorScheme="gray"
                size={isDesktop ? "sm" : "md"}
                variant="outline"
                isPressed={isTimelineViewMenuOpen}
                leftIcon={icon({
                    name: "sliders",
                    style: "regular",
                    family: "sharp",
                })}
                onClick={openTimelineViewMenu}
                ref={buttonRef}
                noDebounce={true}
            >
                View
            </Button>
            {isDesktop ? (
                <TimelineViewMenuPopover isOpen={isTimelineViewMenuOpen} onClose={onClose} anchorRef={buttonRef} />
            ) : (
                <TimelineViewMenuBottomSheet isOpen={isTimelineViewMenuOpen} onClose={onClose} />
            )}
        </>
    )
}
