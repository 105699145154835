import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import { useEffect, useRef, useState } from "react"

import useIsAnyDialogOpen from "@hooks/useIsAnyDialogOpen"
import useIsDesktop from "@hooks/useIsDesktop"

import { Button } from "@atoms"

import { Popover } from "@molecules"

import { BottomSheet } from "@organisms"

import TableViewMenuBottomSheet from "./TableViewMenuBottomSheet/TableViewMenuBottomSheet"
import TableViewMenuPopover from "./TableViewMenuPopover/TableViewMenuPopover"

export default function TableViewMenu() {
    const [isTableViewMenuOpen, setIsTableViewMenuOpen] = useState<boolean>(false)

    const isDesktop = useIsDesktop()

    const { setIsAnyDialogOpen } = useIsAnyDialogOpen()

    useEffect(() => {
        setIsAnyDialogOpen(isTableViewMenuOpen)
    }, [isTableViewMenuOpen])

    const openTableViewMenu = () => setIsTableViewMenuOpen(true)

    const onClose = () => setIsTableViewMenuOpen(false)

    const buttonRef = useRef<HTMLButtonElement>(null)

    return (
        <>
            <Button
                colorScheme="gray"
                size={isDesktop ? "sm" : "md"}
                variant="outline"
                isPressed={isTableViewMenuOpen}
                leftIcon={icon({
                    name: "sliders",
                    style: "regular",
                    family: "sharp",
                })}
                onClick={openTableViewMenu}
                ref={buttonRef}
                noDebounce={true}
            >
                View
            </Button>
            {isDesktop ? (
                <Popover.Root isOpen={isTableViewMenuOpen} onClose={onClose} anchorRef={buttonRef}>
                    <TableViewMenuPopover isOpen={isTableViewMenuOpen} />
                </Popover.Root>
            ) : (
                <BottomSheet.Root isOpen={isTableViewMenuOpen} onClose={onClose}>
                    <TableViewMenuBottomSheet />
                </BottomSheet.Root>
            )}
        </>
    )
}
