import React, { Component } from 'react';
import SignaturePad from 'react-signature-pad-wrapper';
import UniversalButton from '../buttons/UniversalButton';


class SignatureField extends Component {

    handleSignatureChange = () => {
        let signatureData

        if (this.signaturePad !== null && !this.signaturePad.isEmpty()) {
            signatureData = this.signaturePad.toDataURL("image/svg+xml")
        }
        else {
            signatureData = ""
        }
        this.props.fieldOnChange(signatureData)
    }

    setRef = (ref) => {
        this.signaturePad = ref

        if (this.signaturePad) {
            this.signaturePad.instance.addEventListener("endStroke", this.handleSignatureChange)
        }
    }

    render() {
        const {fieldName, fieldLabel, fieldOnChange, disabled=false, optional=false, optionalText="optional", errors} = this.props

        return (
            <div className="data-panel__form__field" id={`div_id_${fieldName}`} aria-label={fieldLabel}>
                <label htmlFor={`id_${fieldName}`} className="data-panel__form__field__label in-form">{fieldLabel}{optional && <span className="text-optional"> ({optionalText})</span>}</label>
                <div className="data-panel__form__field__input">
                    <div className="signature-pad-container" id={`id_${fieldName}`}>
                        <UniversalButton type="signature_pad_clear" text="CLEAR" handler={event => {this.signaturePad.clear(); this.handleSignatureChange()}} />
                        <SignaturePad
                            ref={ref => this.setRef(ref)}
                            options={{ minWidth: 2, maxWidth: 4, penColor: "#00ca8a" }}
                            redrawOnResize={true}
                            canvasProps={{ disabled: disabled, className: "signature-pad-canvas" }}
                        ></SignaturePad>
                    </div>
                </div>
                {errors[fieldName] && <div className="data-panel__form__field__errors" aria-label="Field Errors">{errors[fieldName]}</div>}
            </div>
        )
    }
}

export default SignatureField;
