import clsx from "clsx"
import { createContext } from "react"

import styles from "./TabStack.module.scss"
import { TabStackContextValues, TabStackProps } from "./TabStack.types"

export const TabStackContext = createContext<TabStackContextValues | null>(null)
TabStackContext.displayName = "TabStack"

export default function TabStack(props: TabStackProps) {
    const { children, attached, size } = props

    return (
        <div
            className={clsx(styles.base, styles[`${size}Size`], {
                [styles.attached]: attached,
            })}
            role="tablist"
        >
            <TabStackContext.Provider value={{ attached, size }}>{children}</TabStackContext.Provider>
        </div>
    )
}
