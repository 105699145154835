import useUser from '@hooks/useUser';
import determineDaysDue from '@utils/determineDaysDue';
import determineOverdueBucket from '@utils/determineOverdueBucket';
import ObjectListCard from '../../core/components/ObjectListCard';
import AlertingRenderer from '../../core/renderers/AlertingRenderer';
import DateTimeRenderer from '../../core/renderers/DateTimeRenderer';
import LastSeenRenderer from '../../core/renderers/LastSeenRenderer';
import StatusChipRenderer from '../../core/renderers/StatusChipRenderer';
import { formatCurrency } from "../../core/utils/utils";


function InvoiceListCard(props) {
    const { data, showState } = props
    const invoice = data
    const { user:currentUser } = useUser()

    const footerContext = {
        label: null,
        value: null
    }

    footerContext.label = "Date Due:"
    footerContext.value = (
        <AlertingRenderer alertCondition={invoice.state_label === "Overdue"}>
            <DateTimeRenderer type="date" value={invoice.date_due} options={{short: true, addYear: true}} />
        </AlertingRenderer>
    )

    return <ObjectListCard
        objectName="Invoice"
        objectID={invoice.custom_id || invoice.id}
        showState={showState}
        stateLabel={invoice.state_label}
        headerContext={<LastSeenRenderer value={invoice.last_viewed} />}
        statusChip={<StatusChipRenderer value={["Outstanding", "Overdue"].includes(invoice.status_label) ? determineDaysDue(invoice) : invoice.status_label} extraClasses={["Outstanding", "Overdue"].includes(invoice.status_label) ? `status-overdue-${determineOverdueBucket(invoice.date_due)?.toLowerCase().replaceAll("_", "-")}` : ""} />}
        bodyTitle={invoice.client_name}
        bodySubtextPrimary={invoice.service_name}
        bodySubtextSecondary={invoice.service_location_address}
        footerContextLabel={footerContext.label}
        footerContextValue={footerContext.value}
        footerPrimary={formatCurrency(invoice.amount_due, currentUser.service_company.currency_code, currentUser.service_company.language_code)}
    />
}

export default InvoiceListCard;
