// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DateInputPopover-content__NRkEo{z-index:var(--z-index-dropdown);background-color:var(--white);border:var(--size-0_25) solid var(--gray-4);border-color:var(--gray-7);border-width:var(--size-0_25);border-radius:var(--border-radius-1);outline:none;box-shadow:var(--box-shadow-sm-down)}.DateInputPopover-content__NRkEo[data-state=open][data-side=top]{animation-name:DateInputPopover-slideDownAndFade__N3rTt}.DateInputPopover-content__NRkEo[data-state=open][data-side=right]{animation-name:DateInputPopover-slideLeftAndFade__Znxnk}.DateInputPopover-content__NRkEo[data-state=open][data-side=bottom]{animation-name:DateInputPopover-slideUpAndFade__UMWvq}.DateInputPopover-content__NRkEo[data-state=open][data-side=left]{animation-name:DateInputPopover-slideRightAndFade__OEZqw}@keyframes DateInputPopover-slideUpAndFade__UMWvq{from{transform:translateY(var(--size-0_5));opacity:0}to{transform:translateY(0);opacity:1}}@keyframes DateInputPopover-slideRightAndFade__OEZqw{from{transform:translateX(calc(var(--size-0_5) * -1));opacity:0}to{transform:translateX(0);opacity:1}}@keyframes DateInputPopover-slideDownAndFade__N3rTt{from{transform:translateY(calc(var(--size-0_5) * -1));opacity:0}to{transform:translateY(0);opacity:1}}@keyframes DateInputPopover-slideLeftAndFade__Znxnk{from{transform:translateX(var(--size-0_5));opacity:0}to{transform:translateX(0);opacity:1}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "DateInputPopover-content__NRkEo",
	"slideDownAndFade": "DateInputPopover-slideDownAndFade__N3rTt",
	"slideLeftAndFade": "DateInputPopover-slideLeftAndFade__Znxnk",
	"slideUpAndFade": "DateInputPopover-slideUpAndFade__UMWvq",
	"slideRightAndFade": "DateInputPopover-slideRightAndFade__OEZqw"
};
export default ___CSS_LOADER_EXPORT___;
