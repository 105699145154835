import { Component } from "react";
import ButtonGroup from "../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../core/buttons/UniversalButton";
import BasicDisplayField from "../../core/fields/BasicDisplayField";
import SearchCreateSelectField from "../../core/fields/SearchCreateSelectField";
import ServiceLocationSearchOrCreateSelect, { serviceLocationToServiceLocationOption } from "../inputs/ServiceLocationSearchOrCreateSelect";
import { renderClientString, renderServiceLocationString } from "../utils/utils";


class ServiceLocationMergeForm extends Component {

    componentDidMount() {
        // Return to the previous scroll
        document.querySelector(".main").scrollTo(0, this.props.returnScroll || 0)
    }

    renderButtons = () => {
        const {
            mode,
            mergeData,
            client,
            sourceServiceLocation,
            destinationServiceLocation,
            onFormDataChange,
            requestAction,
            switchToSecondaryForm,
            updateSourceServiceLocationSelection,
            updateDestinationServiceLocationSelection,
            returnScroll
        } = this.props

        return (
            <ButtonGroup>
                <ButtonGroupRow>
                    <UniversalButton type="primary" text="Continue" handler={event => requestAction("CONFIRM_MERGE")} />
                    <UniversalButton type="secondary" text="Cancel" handler={event => requestAction("CANCEL_MERGE")} />
                </ButtonGroupRow>
            </ButtonGroup>
        )
    }

    render() {
        const {
            mode,
            mergeData,
            errors,
            client,
            sourceServiceLocation,
            destinationServiceLocation,
            onFormDataChange,
            requestAction,
            switchToSecondaryForm,
            updateSourceServiceLocationSelection,
            updateDestinationServiceLocationSelection,
            returnScroll
        } = this.props

        return (
            <div className="data-panel-container data-panel-container--with-margin">
                <div className="data-panel" aria-label="Service Location Merge">
                    <div className="data-panel__form" aria-label="Service Location Merge">
                        <p className="data-panel__form__caption">Select the service locations you wish to merge below.</p>
                        <p className="data-panel__form__caption">
                            <strong>This should only be used to merge duplicated service locations. All equipment, jobs, and invoices from the source service location will be retroactively updated with the destination service locations's details, and the source service location and events associated with it will be deleted.</strong><br /><br />
                            <strong>This action cannot be undone.</strong>
                        </p>
                        <BasicDisplayField
                            fieldName="client"
                            fieldLabel="Client"
                            fieldValue={renderClientString(client)}
                            indented={false}
                        ></BasicDisplayField>
                        <SearchCreateSelectField
                            fieldName="source_service_location"
                            fieldLabel="Source Service Location"
                            fieldValue={sourceServiceLocation !== null ? renderServiceLocationString(sourceServiceLocation) : null}
                            inputComponent={
                                <ServiceLocationSearchOrCreateSelect
                                    onSelectionChange={selectedOption => updateSourceServiceLocationSelection(selectedOption != null ? selectedOption.object : null)}
                                    defaultSelected={sourceServiceLocation !== null ? serviceLocationToServiceLocationOption(sourceServiceLocation) : null}
                                    client={client}
                                    showCreateButton={false}
                                    extraKey="source"
                                ></ServiceLocationSearchOrCreateSelect>
                            }
                            showButton={false}
                            errors={errors}
                        ></SearchCreateSelectField>
                        {
                            sourceServiceLocation !== null && (
                                <SearchCreateSelectField
                                    key={`${sourceServiceLocation.id}_destination`}
                                    fieldName="destination_service_location"
                                    fieldLabel="Destination Service Location"
                                    fieldValue={destinationServiceLocation !== null ? renderServiceLocationString(destinationServiceLocation) : null}
                                    inputComponent={
                                        <ServiceLocationSearchOrCreateSelect
                                            ignoreOptions={[sourceServiceLocation.id]}
                                            onSelectionChange={selectedOption => updateDestinationServiceLocationSelection(selectedOption != null ? selectedOption.object : null)}
                                            defaultSelected={destinationServiceLocation !== null ? serviceLocationToServiceLocationOption(destinationServiceLocation) : null}
                                            client={client}
                                            showCreateButton={false}
                                            extraKey="destination"
                                        ></ServiceLocationSearchOrCreateSelect>
                                    }
                                    showButton={false}
                                    errors={errors}
                                ></SearchCreateSelectField>
                            )
                        }
                    </div>
                    {this.renderButtons()}
                </div>
            </div>
        )
    }

}

export default ServiceLocationMergeForm;
