import SettingsAvailabilitySchedulesPanel from "@legacy/core/components/SettingsAvailabilitySchedulesPanel"

import useToastQueue from "@hooks/useToastQueue"

import { Settings } from "@templates/index"

export default function SettingsAvailabilitySchedules() {
    const { addToastToQueue } = useToastQueue()

    return (
        <Settings.Wrapper title="Availability schedules">
            <SettingsAvailabilitySchedulesPanel addToastToQueue={addToastToQueue} />
        </Settings.Wrapper>
    )
}
