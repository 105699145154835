import clsx from "clsx"
import { createContext, useEffect, useRef, useState } from "react"
import DraggableBottomSheet from "react-draggable-bottom-sheet"
import { useHotkeys } from "react-hotkeys-hook"

import styles from "./BottomSheet.module.scss"
import { BottomSheetContextValues, BottomSheetProps } from "./BottomSheet.types"
import BottomSheetContent from "./BottomSheetContent/BottomSheetContent"
import BottomSheetDivider from "./BottomSheetDivider/BottomSheetDivider"
import BottomSheetDragIndicator from "./BottomSheetDragIndicator/BottomSheetDragIndicator"
import BottomSheetFooter from "./BottomSheetFooter/BottomSheetFooter"
import BottomSheetItem from "./BottomSheetItem/BottomSheetItem"
import BottomSheetSearchInput from "./BottomSheetSearchInput/BottomSheetSearchInput"

export const BottomSheetContext = createContext(null as BottomSheetContextValues)
BottomSheetContext.displayName = "BottomSheet"

function BottomSheet(props: BottomSheetProps) {
    const { children, isOpen, onClose, isFullHeight = false, className } = props

    const [contentHeight, setContentHeight] = useState<number>(null)
    const [contentActive, setContentActive] = useState<string>(null)
    const [isContentOverflowing, setIsContentOverflowing] = useState<boolean>(false)

    const handleClose = () => {
        setContentActive(null)
        onClose()
    }

    useHotkeys("esc", handleClose, { enabled: isOpen })

    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const allFocusableElements = Array.from(ref.current.querySelectorAll('[tabindex="0"]'))
        const firstFocusableElement = allFocusableElements[0] as HTMLElement
        const lastFocusableElement = allFocusableElements[allFocusableElements.length - 1] as HTMLElement

        const onKeyDown = (event: KeyboardEvent) => {
            const isTabPressed = event.key === "Tab" || event.keyCode === 9
            const isTabAndShiftPressed = isTabPressed && event.shiftKey
            const isOnlyTabPressed = isTabPressed && !event.shiftKey
            const isOnTheFirstElement = document.activeElement === firstFocusableElement
            const isOnTheLastElement = document.activeElement === lastFocusableElement
            const isOutsideFocusableElements = !allFocusableElements.includes(document.activeElement)

            if (!isTabPressed) {
                return
            }

            if (isOutsideFocusableElements) {
                firstFocusableElement?.focus()
                event.preventDefault()
            }

            if (isTabAndShiftPressed && isOnTheFirstElement) {
                lastFocusableElement?.focus()
                event.preventDefault()
            }

            if (isOnlyTabPressed && isOnTheLastElement) {
                firstFocusableElement?.focus()
                event.preventDefault()
            }
        }

        if (isOpen) {
            document.addEventListener("keydown", onKeyDown)
        } else {
            document.removeEventListener("keydown", onKeyDown)
        }

        return () => {
            document.removeEventListener("keydown", onKeyDown)
        }
    }, [isOpen, contentActive])

    return (
        <BottomSheetContext.Provider
            value={{
                contentHeight,
                setContentHeight,
                isOpen,
                contentActive,
                setContentActive,
                isContentOverflowing,
                setIsContentOverflowing,
            }}
        >
            <DraggableBottomSheet
                isOpen={isOpen}
                close={handleClose}
                classNames={{
                    backdrop: styles.backdrop,
                    dragIndicator: {
                        wrap: styles.dragIndicatorWrap,
                    },
                    window: {
                        wrap: styles.window,
                        content: styles.content,
                    },
                }}
                styles={{
                    window: {
                        content: {
                            height: isFullHeight ? window.innerHeight : contentHeight,
                        },
                    },
                }}
            >
                <div
                    ref={ref}
                    className={clsx(className, {
                        [styles.fullHeight]: isFullHeight,
                    })}
                >
                    {children}
                </div>
            </DraggableBottomSheet>
        </BottomSheetContext.Provider>
    )
}

const Root = BottomSheet
const Item = BottomSheetItem
const Divider = BottomSheetDivider
const Content = BottomSheetContent
const SearchInput = BottomSheetSearchInput
const DragIndicator = BottomSheetDragIndicator
const Footer = BottomSheetFooter

export default { Root, Item, Divider, Content, SearchInput, DragIndicator, Footer }
