import useIsDesktop from "@hooks/useIsDesktop"

import { Avatar } from "@atoms/index"

import { CompanyAvatarProps } from "./CompanyAvatar.types"

export default function CompanyAvatar(props: CompanyAvatarProps) {
    const { serviceCompany } = props

    const isDesktop = useIsDesktop()

    return (
        <Avatar
            type="company"
            fallbackName={serviceCompany?.name}
            src={serviceCompany?.logo}
            size={isDesktop ? 28 : 36}
        />
    )
}
