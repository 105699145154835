import { Breadcrumb } from "@organisms/index"

import { Settings } from "@templates"

import { SETTINGS_ROUTES } from "@routes/settings"

import PasswordManagement from "./components/PasswordManagement/PasswordManagement"

export default function SettingsSecurityAndAccess() {
    return (
        <Settings.Wrapper title="Security & access">
            <Settings.PageContainer>
                <Settings.Breadcrumbs>
                    <Breadcrumb.Item label="My account" showSlash={true} />
                    <Breadcrumb.Item label="Security & access" path={SETTINGS_ROUTES.securityAndAccess} />
                </Settings.Breadcrumbs>
                <Settings.PageHeader
                    variant="page-heading"
                    title="Security & access"
                    subtitle="Keep your account secure."
                />

                <Settings.Divider />

                <PasswordManagement />
            </Settings.PageContainer>
        </Settings.Wrapper>
    )
}
