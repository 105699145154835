// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ListInfoGlyph-base__fzf2H{display:flex;align-items:center;justify-content:center;padding:var(--size-4);border-radius:var(--border-radius-4)}.ListInfoGlyph-black__lOTor{color:var(--gray-12);background:var(--gradient-gray-12)}.ListInfoGlyph-dangerous__czcAT{color:var(--tomato-9);background:var(--gradient-tomato-9)}.ListInfoGlyph-warning__gs9oq{color:var(--amber-9);background:var(--gradient-amber-9)}.ListInfoGlyph-success__y9elA{color:var(--jade-9);background:var(--gradient-jade-9)}.ListInfoGlyph-info__Komln{color:var(--blue-9);background:var(--gradient-blue-9)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "ListInfoGlyph-base__fzf2H",
	"black": "ListInfoGlyph-black__lOTor",
	"dangerous": "ListInfoGlyph-dangerous__czcAT",
	"warning": "ListInfoGlyph-warning__gs9oq",
	"success": "ListInfoGlyph-success__y9elA",
	"info": "ListInfoGlyph-info__Komln"
};
export default ___CSS_LOADER_EXPORT___;
