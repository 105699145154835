import { useMemo, useState } from "react"
import * as Yup from "yup"

import useIsDesktop from "@hooks/useIsDesktop"
import useUser from "@hooks/useUser"

import GenericForm from "@molecules/Form/GenericForm/GenericForm"
import { MutationSuccessFunctionParams } from "@molecules/Form/GenericForm/GenericForm.types"
import Input from "@molecules/Form/inputs/Input/Input"

import { USER_ENDPOINT } from "@endpoints/user"

import { INPUT_DEBOUNCE_SUBMIT_TIME_MS } from "@constants/input"

import { UserFirstName } from "./FirstNameForm.types"

const formSchema: Yup.ObjectSchema<UserFirstName> = Yup.object().shape({
    first_name: Yup.string().required("Please enter a first name"),
})

export default function FirstNameForm() {
    const [isInitialValueSet, setIsInitialValueSet] = useState<boolean>(false)

    const isDesktop = useIsDesktop()

    const { user, updateUser } = useUser()

    const onMutationSuccess = (args: MutationSuccessFunctionParams<UserFirstName, UserFirstName>) => {
        updateUser({ first_name: args.formValues.first_name })
    }

    const onAfterFormValuesSet = () => {
        setIsInitialValueSet(true)
    }

    const formValues = useMemo(() => {
        if (isInitialValueSet) {
            return undefined
        } else if (user?.first_name) {
            return {
                first_name: user?.first_name,
            }
        }
    }, [user?.first_name, isInitialValueSet])

    return (
        <GenericForm<UserFirstName, User, Error, UserFirstName>
            formConfig={{
                mode: "all",
                reValidateMode: "onChange",
                defaultValues: {
                    first_name: "",
                },
            }}
            formValidationSchema={formSchema}
            mutationConfig={{
                endpoint: USER_ENDPOINT,
                method: "PATCH",
                genericErrorMessage: "Could not update first name",
                genericSuccessMessage: "First name updated",
            }}
            isAutoSave={true}
            autoSaveDebounceTimeMs={INPUT_DEBOUNCE_SUBMIT_TIME_MS}
            formValues={formValues}
            onMutationSuccess={onMutationSuccess}
            onAfterFormValuesSet={onAfterFormValuesSet}
            noFormTag={true}
        >
            {() => (
                <Input<UserFirstName>
                    label="First name"
                    size={isDesktop ? "md" : "lg"}
                    placeholder="First name"
                    aria-label="First name"
                    name="first_name"
                />
            )}
        </GenericForm>
    )
}
