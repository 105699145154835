import clsx from "clsx"
import { memo, useMemo } from "react"

import useNavigationLinks, { NavigationLink } from "@hooks/useNavigationLinks"
import useUser from "@hooks/useUser"

import filterUserLinks from "@utils/filterUserLinks"
import generateRandomKey from "@utils/generateRandomKey"

import { ScrollableContainer } from "@atoms"

import { NavigationItem, WorkspaceButton } from "@molecules"

import { StackedNavigationItems, UserProfileButton } from "@organisms"

import HelpCenter from "./HelpCenter/HelpCenter"
import styles from "./MainSideBar.module.scss"
import SideBarCreateButton from "./SideBarCreateButton/SideBarCreateButton"
import SideBarSearchButton from "./SideBarSearchButton/SideBarSearchButton"
import WhatsNew from "./WhatsNew/WhatsNew"

function MainSideBar() {
    const { user } = useUser()

    const { home, calendar, estimates, jobs, invoices, purchase_orders, vendors, clients, pricebook, reports } =
        useNavigationLinks()

    const links: NavigationLink[] = [
        home,
        calendar,
        estimates,
        jobs,
        invoices,
        purchase_orders,
        vendors,
        clients,
        pricebook,
        reports,
    ]

    const filteredLinks = useMemo(() => filterUserLinks(links, user), [user])

    return (
        <nav className={styles.base}>
            <div className={styles.header}>
                <WorkspaceButton />

                <UserProfileButton />
            </div>
            <div className={styles.searchInput}>
                <SideBarSearchButton />
            </div>

            <ScrollableContainer
                className={clsx(styles.navigation, { [styles.withCreateButton]: user?.isServiceDispatcher })}
                scrollClassName={styles.scrolling}
            >
                <div className={styles.primaryNavigation}>
                    {filteredLinks.map((link) => {
                        if (link.subLinks?.length) {
                            return (
                                <StackedNavigationItems
                                    key={generateRandomKey()}
                                    header={link}
                                    items={link.subLinks}
                                />
                            )
                        } else {
                            return (
                                <NavigationItem
                                    key={generateRandomKey()}
                                    label={link.label}
                                    module={link.module}
                                    path={link.path}
                                    variant="primary"
                                />
                            )
                        }
                    })}
                </div>
                <div className={styles.secondaryNavigation}>
                    <HelpCenter />
                    <WhatsNew />
                </div>
            </ScrollableContainer>
            {user?.isServiceDispatcher && <SideBarCreateButton />}
        </nav>
    )
}

export default memo(MainSideBar)
