import React, { Component } from 'react';


class CharField extends Component {

    render() {
        const {fieldName, fieldLabel, fieldValue, fieldOnChange, maxLength, placeholder, disabled=false, optional=false, optionalText="optional", errors, idKey=""} = this.props

        return (
            <div className="data-panel__form__field" id={`div_id_${fieldName}${idKey}`} aria-label={fieldLabel}>
                <label htmlFor={`id_${fieldName}${idKey}`} className="data-panel__form__field__label in-form">{fieldLabel}{optional && <span className="text-optional"> ({optionalText})</span>}</label>
                <div className="data-panel__form__field__input" title={disabled && fieldValue ? fieldValue : null}>
                    <input
                        defaultValue={fieldValue}
                        onChange={event => fieldOnChange(event.target.value)}
                        type="text"
                        id={`id_${fieldName}${idKey}`}
                        name={fieldName}
                        maxLength={maxLength}
                        placeholder={placeholder}
                        autoComplete="off"
                        className={disabled ? "is-disabled" : ""}
                        disabled={disabled}
                    />
                </div>
                {errors[fieldName] && <div className="data-panel__form__field__errors" aria-label="Field Errors">{errors[fieldName]}</div>}
            </div>
        )
    }
}

export default CharField;
