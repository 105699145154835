import useIsDesktop from "@hooks/useIsDesktop"
import useRouter from "@hooks/useRouter"

import { Settings } from "@templates"

import SettingsNavigation from "@pages/Settings/SettingsNavigation/SettingsNavigation"

import { SETTINGS_ROUTES } from "@routes/settings"

export default function SettingsMainPage() {
    const isDesktop = useIsDesktop()

    const router = useRouter()

    if (isDesktop) {
        router.replace(SETTINGS_ROUTES.profile)
    } else {
        return (
            <Settings.Wrapper title="Settings">
                <SettingsNavigation />
            </Settings.Wrapper>
        )
    }
}
