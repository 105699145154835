import React, { Component } from "react";
import deepcopy from "rfdc";
import InlineInventoryLevelDisplay from "./components/InlineInventoryLevelDisplay";
import InlineInventoryLevelForm from "./forms/InlineInventoryLevelForm";
import { validateInventoryLevel } from "./utils/utils";

const PAGE_MODES = {
    VIEW_INVENTORY_LEVEL: "VIEW_INVENTORY_LEVEL",
    EDIT_INVENTORY_LEVEL: "EDIT_INVENTORY_LEVEL",
}

const FORM_DATA_NAMES_BY_MODE = {
    EDIT_INVENTORY_LEVEL: "inventoryLevelFormData",
}


class InlineInventoryLevelContainer extends Component {

    // Initialize

    constructor(props) {
        super(props)

        let defaultMode = this.props.formMode || PAGE_MODES.VIEW_INVENTORY_LEVEL

        const inventoryLevel = deepcopy()(this.props.inventoryLevel)

        this.state = {
            inventoryLevelData: inventoryLevel,
            inventoryLevelFormData: deepcopy()(inventoryLevel),
            mode: defaultMode,
        }
    }

    // Form helpers

    updateFormData = (formName, fieldName, fieldValue) => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState[formName][fieldName] = fieldValue
            return updatedState
        })
    }

    switchFormMode = (mode) => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState.mode = mode
            return updatedState
        })
    }

    switchToDisplay = () => {
        this.setState((state, props) => {
            let updatedState = state

            // Reset primary form data
            updatedState[FORM_DATA_NAMES_BY_MODE[state.mode]] = deepcopy()(this.props.inventoryLevel)
            return updatedState
        })
        this.switchFormMode(PAGE_MODES.VIEW_INVENTORY_LEVEL)
    }

    switchToPrimaryForm = () => {
        this.switchFormMode(PAGE_MODES.EDIT_INVENTORY_LEVEL)
    }

    // Handle Actions

    handleActionRequest = (action) => {
        switch (action) {
            case "INVENTORY_LEVEL_EDIT":
                this.switchToPrimaryForm()
                break
            case "INVENTORY_LEVEL_SAVE":
                const { isValid, errors } = validateInventoryLevel(this.state.inventoryLevelFormData, this.props.index)

                if (isValid) {
                    this.setState((state, props) => {
                        let updatedState = state

                        delete state.inventoryLevelFormData.errors
                        updatedState.inventoryLevelData = state.inventoryLevelFormData

                        this.props.updateInventoryLevel(updatedState.inventoryLevelData)

                        return updatedState
                    })
                    this.switchFormMode(PAGE_MODES.VIEW_INVENTORY_LEVEL)
                }
                else {
                    this.setState((state, props) => {
                        let updatedState = state
                        updatedState.inventoryLevelFormData.errors = errors
                        return updatedState
                    })
                }
                break
            case "INVENTORY_LEVEL_CANCEL_EDITS":
                this.switchToDisplay()
                break
            default:
                console.error(`No action handler exists for action "${action}".`)
        }
    }

    // Render

    render = () => {
        if (this.state.mode === PAGE_MODES.VIEW_INVENTORY_LEVEL) {
            return <InlineInventoryLevelDisplay
                inventoryLevel={this.state.inventoryLevelData}
                requestAction={this.handleActionRequest}
            />
        }
        else if (this.state.mode === PAGE_MODES.EDIT_INVENTORY_LEVEL) {
            return <InlineInventoryLevelForm
                inventoryLevel={this.state.inventoryLevelFormData}
                onFormDataChange={(fieldName, fieldValue) => this.updateFormData(FORM_DATA_NAMES_BY_MODE[this.state.mode], fieldName, fieldValue)}
                requestAction={this.handleActionRequest}
            />
        }
        else {
            return (
                <div className="data-panel-container data-panel-container--with-margin">
                    <div className="data-panel" aria-label="Unknown Form Mode">
                        <div className="data-panel__form">
                            <p className="data-panel__form__caption">An unhandled form mode was supplied.</p>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default InlineInventoryLevelContainer;
