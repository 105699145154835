import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import clsx from "clsx"

import useIsDesktop from "@hooks/useIsDesktop"

import { Button } from "@atoms"

import useAuth from "@pages/Auth/Auth.store"

import { ReactComponent as AppIcon } from "@assets/images/icons/app-icon.svg"

import styles from "./AuthHeader.module.scss"

export default function AuthHeader() {
    const isDesktop = useIsDesktop()

    const { setIsOnOTPSlide, isOnOTPSlide } = useAuth()

    const onBackButtonClick = () => {
        setIsOnOTPSlide(false)
    }

    return (
        <div className={styles.base}>
            <div className={styles.grid}>
                <Button
                    onClick={onBackButtonClick}
                    colorScheme="gray"
                    size={isDesktop ? "xs" : "sm"}
                    variant="ghost"
                    leftIcon={icon({
                        name: "angle-left",
                        family: "sharp",
                        style: "regular",
                    })}
                    className={clsx(styles.backButton, {
                        [styles.showBackButton]: isOnOTPSlide,
                    })}
                    isTabbable={isOnOTPSlide}
                >
                    Back
                </Button>

                <AppIcon className={styles.icon} />
            </div>
            <div className={styles.titleContainer}>
                <div
                    className={clsx(styles.title, {
                        [styles.titleActive]: isOnOTPSlide,
                    })}
                >
                    Enter code
                </div>
                <div
                    className={clsx(styles.title, {
                        [styles.titleActive]: !isOnOTPSlide,
                    })}
                >
                    Welcome to Roopairs
                </div>
            </div>
        </div>
    )
}
