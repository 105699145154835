export default function extractURLSearchParam(name: string, removeParam: boolean = true) {
    const urlParams = new URLSearchParams(window.location.search)
    const value = urlParams.get(name)

    // If a value was extracted, remove it from the url and return it
    if (value !== null) {
        if (removeParam) {
            const url = new URL(window.location.href)
            url.searchParams.delete(name)
            window.history.replaceState({}, "", url)
        }

        return decodeURIComponent(value)
    }
}
