import PurchaseOrderCreate from "./PurchaseOrderCreate/PurchaseOrderCreate"
import PurchaseOrderDetails from "./PurchaseOrderDetails/PurchaseOrderDetails"
import PurchaseOrderEdit from "./PurchaseOrderEdit/PurchaseOrderEdit"
import PurchaseOrderList from "./PurchaseOrderList/PurchaseOrderList"
import PurchaseOrderPublicDetails from "./PurchaseOrderPublicDetails/PurchaseOrderPublicDetails"

const List = PurchaseOrderList
const Create = PurchaseOrderCreate
const Edit = PurchaseOrderEdit
const Details = PurchaseOrderDetails
const PublicDetails = PurchaseOrderPublicDetails

export default { List, Create, Edit, Details, PublicDetails }
