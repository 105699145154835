import React, { Component } from 'react';


class DurationField extends Component {

    render() {
        const {fieldName, fieldLabel, fieldValue, fieldOnChange, unit, unitMultiplier, disabled=false, optional=false, optionalText="optional", errors} = this.props

        // Note: fieldValue is in seconds. Use unitMultiplier to convert seconds to unit

        return (
            <div className="data-panel__form__field" id={`div_id_${fieldName}`} aria-label={fieldLabel}>
                <label htmlFor={`id_${fieldName}`} className="data-panel__form__field__label in-form">{fieldLabel}{optional && <span className="text-optional"> ({optionalText})</span>}</label>
                <div className="data-panel__form__field__input">
                    <div className="data-panel__form__field__input-group" title={disabled && (fieldValue !== "" && fieldValue !== null && fieldValue !== undefined) ? `${fieldValue / unitMultiplier} ${unit}` : null}>
                        <input
                            defaultValue={(fieldValue !== "" && fieldValue !== null && fieldValue !== undefined) ? fieldValue / unitMultiplier : ""}
                            onChange={event => fieldOnChange(Math.trunc(event.target.value * unitMultiplier))}
                            type="number"
                            min="0"
                            step="1"
                            id={`id_${fieldName}`}
                            name={fieldName}
                            autoComplete="off"
                            className={disabled ? "is-disabled" : ""}
                            disabled={disabled}
                        />
                        <div className="input-group-append">
                            <div className="input-group-text">{unit}</div>
                        </div>
                    </div>
                </div>
                {errors[fieldName] && <div className="data-panel__form__field__errors" aria-label="Field Errors">{errors[fieldName]}</div>}
            </div>
        )
    }
}

export default DurationField;
