// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProfileBottomSheet-itemsList__insNu{display:flex;flex-direction:column;gap:var(--size-1);padding:var(--size-2)}.ProfileBottomSheet-itemsStack__saCUz{display:flex;flex-direction:column;gap:var(--size-0_5)}.ProfileBottomSheet-userMetadata__sbbSo{display:flex;flex-direction:column;gap:var(--size-3);align-items:center;justify-content:center;padding:var(--size-6) var(--size-4) var(--size-4) var(--size-4);text-align:center;text-decoration:none;cursor:pointer}.ProfileBottomSheet-userMetadata__sbbSo:hover{text-decoration:none}.ProfileBottomSheet-userMetadata__sbbSo:focus-visible{outline:var(--gray-12) solid var(--size-0_5);outline-offset:calc(var(--size-0_5)*-1)}.ProfileBottomSheet-name__n9lJq{display:block;color:var(--gray-12);cursor:pointer;font:var(--font-mobile-heading-02);font-feature-settings:var(--feature-settings-mobile-heading-02);letter-spacing:var(--letter-spacing-mobile-heading-02)}.ProfileBottomSheet-caption__HOwcH{display:block;color:var(--gray-11);cursor:pointer;font:var(--font-mobile-caption-400);font-feature-settings:var(--feature-settings-mobile-caption-400);letter-spacing:var(--letter-spacing-mobile-caption-400)}.ProfileBottomSheet-nameAndCaption__yPd7t{display:flex;flex-direction:column;gap:var(--size-0_5)}.ProfileBottomSheet-bottomSheetContent__ckvXV{padding:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"itemsList": "ProfileBottomSheet-itemsList__insNu",
	"itemsStack": "ProfileBottomSheet-itemsStack__saCUz",
	"userMetadata": "ProfileBottomSheet-userMetadata__sbbSo",
	"name": "ProfileBottomSheet-name__n9lJq",
	"caption": "ProfileBottomSheet-caption__HOwcH",
	"nameAndCaption": "ProfileBottomSheet-nameAndCaption__yPd7t",
	"bottomSheetContent": "ProfileBottomSheet-bottomSheetContent__ckvXV"
};
export default ___CSS_LOADER_EXPORT___;
