function filterEquipment(equipment, queryChunk) {
    let willFilter = false

    if (equipment) {
        willFilter = willFilter || equipment.id.toLowerCase().includes(queryChunk)
        willFilter = willFilter || equipment.display_name.toLowerCase().includes(queryChunk)
        willFilter = willFilter || equipment.manufacturer.toLowerCase().includes(queryChunk)
        willFilter = willFilter || equipment.model_number.toLowerCase().includes(queryChunk)
        willFilter = willFilter || equipment.serial_number.toLowerCase().includes(queryChunk)
        willFilter = willFilter || equipment.location.toLowerCase().includes(queryChunk)
        willFilter = willFilter || equipment.notes.toLowerCase().includes(queryChunk)
    }

    return willFilter
}

export { filterEquipment }
