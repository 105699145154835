import { Divider } from "@atoms/index"

import styles from "./PageDivider.module.scss"

export default function PageDivider() {
    return (
        <div className={styles.base}>
            <Divider />
        </div>
    )
}
