import { useContext } from "react"

import { OTPFormContext } from "./OTPForm"
import { OTPFormContextValues } from "./OTPForm.types"

export default function useOTPForm(): OTPFormContextValues {
    const context = useContext(OTPFormContext)
    if (!context) {
        throw new Error("useOTPForm must be used within a OTPForm component")
    }

    return context
}
