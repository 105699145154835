import JobDetailsContainer from "@legacy/jobs/JobDetailsContainer"

import { AppFrame } from "@templates"

export default function JobsPublicDetails() {
    return (
        <AppFrame.Root pageId="JobPublicDetails" navigationType="none">
            <AppFrame.PageContent>
                <JobDetailsContainer />
            </AppFrame.PageContent>
        </AppFrame.Root>
    )
}
