import Spinner from "@legacy/core/components/Spinner";
import { Component } from "react";
import CardFilterInput from "../core/components/CardFilterInput";
import EquipmentDetailsAccordion from "../equipment/components/EquipmentDetailsAccordion";
import { filterEquipment } from "../equipment/utils/filters";

class ClientEquipmentListContainer extends Component {

    constructor(props) {
        super(props)
        this.addToastToQueue = this.props.addToastToQueue
        this.state = {}
    }

    componentDidMount = async () => {
        const serviceLocationID = new URLSearchParams(document.location.search).get("service_location_id") || null
        let endpoint

        if (serviceLocationID !== null) {
            endpoint = DjangoUrls["equipment:api-service-location-equipment-list-by-location"](window.MARKETPLACE_ENTITY_SLUG, serviceLocationID)
        }
        else {
            endpoint = DjangoUrls["equipment:api-client-equipment-list-by-location"](window.MARKETPLACE_ENTITY_SLUG, window.CLIENT_ID)
        }

        const response = await fetch(endpoint)
        const json = await response.json()

        this.setState((state, props) => {
            let updatedState = state
            updatedState.groupedEquipment = json
            updatedState.filteredGroupedEquipment = json
            return updatedState
        });
    }

    filterGroupedEquipment = (filterKwargs) => {
        let filteredGroupedEquipment = {}

        for (let [location, equipmentList] of Object.entries(this.state.groupedEquipment)) {
            filteredGroupedEquipment[location] = equipmentList

            if (filterKwargs.searchQuery !== "") {
                for (let queryChunk of filterKwargs.searchQuery.split(" ")) {
                    queryChunk = queryChunk.toLowerCase()

                    filteredGroupedEquipment[location] = filteredGroupedEquipment[location].filter(equipment => filterEquipment(equipment, queryChunk))
                }
            }
        }

        this.setState((state, props) => {
            let updatedState = state
            updatedState.filteredGroupedEquipment = filteredGroupedEquipment
            return updatedState
        });
    }

    renderLocationGroups = (filteredGroupedEquipment) => {
        let locationGroups = []

        for (let [location, equipmentList] of Object.entries(this.state.filteredGroupedEquipment)) {
            if (equipmentList.length !== 0) {
                let equipmentCards = []

                equipmentList.map(equipment => {
                    equipmentCards.push(<EquipmentDetailsAccordion key={`equipment_${equipment.id}`} clientID={window.CLIENT_ID} data={equipment} editable={true} startInactive={true} showHelpText={false} showJobHistory={true} addToastToQueue={this.addToastToQueue}/>)
                })

                locationGroups.push(
                    <div className="equipment-location-group" key={location}>
                        <div className="equipment-location-group__location">{location}</div>
                        <div className="equipment-location-group__equipment">{equipmentCards.map(equipmentCard => equipmentCard)}</div>
                    </div>
                )
            }
        }

        if (locationGroups.length === 0) {
            locationGroups.push(
                <div className="equipment-location-group" key="empty">
                    <span className="equipment-emptytext">No equipment found.</span>
                </div>
            )
        }

        return locationGroups
    }

    render() {
        let content = null

        if (this.state.groupedEquipment === undefined) {
            content = (
                <div id="equipment_list_container">
                    <div className="equipment-list" aria-label="Equipment">
                        <Spinner centered={true} />
                    </div>
                </div>
            )
        }
        else {
            if (Object.entries(this.state.groupedEquipment).length === 0 && this.state.groupedEquipment.constructor === Object) {
                content = (
                    <div id="equipment_list_container">
                        <div className="equipment-list" aria-label="Equipment">
                            <span className="equipment-emptytext">Looks like you don't have any equipment logged for this client (yet).</span>
                        </div>
                    </div>
                )
            }
            else {
                content = (
                    <div id="equipment_list_container">
                        <div className="full-width-bar">
                            <CardFilterInput onKwargsChange={this.filterGroupedEquipment} showSearch={true} showDropdown={false}></CardFilterInput>
                        </div>
                        <div className="equipment-list" aria-label="Equipment">
                            {this.renderLocationGroups(this.state.filteredGroupedEquipment).map(locationGroup => locationGroup)}
                        </div>
                    </div>
                )
            }
        }

        return content
    }
}

export default ClientEquipmentListContainer;
