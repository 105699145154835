// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IconGroup-base__wTM3W{display:flex}.IconGroup-base__wTM3W>:first-child{margin-left:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "IconGroup-base__wTM3W"
};
export default ___CSS_LOADER_EXPORT___;
