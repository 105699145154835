import React, { Component } from 'react';
import TextareaAutosize from 'react-textarea-autosize';


class TextField extends Component {

    render() {
        const {fieldName, fieldLabel, fieldValue, fieldOnChange, rows, placeholder, disabled=false, optional=false, optionalText="optional", errors} = this.props

        return (
            <div className="data-panel__form__field" id={`div_id_${fieldName}`} aria-label={fieldLabel}>
                <label htmlFor={`id_${fieldName}`} className="data-panel__form__field__label in-form">{fieldLabel}{optional && <span className="text-optional"> ({optionalText})</span>}</label>
                <div className="data-panel__form__field__input" title={disabled && fieldValue ? fieldValue : null}>
                    <TextareaAutosize
                        defaultValue={fieldValue}
                        onChange={event => fieldOnChange(event.target.value)}
                        type="text"
                        id={`id_${fieldName}`}
                        name={fieldName}
                        minRows={rows}
                        placeholder={placeholder}
                        autoComplete="off"
                        className={disabled ? "is-disabled" : ""}
                        disabled={disabled}
                    ></TextareaAutosize>
                </div>
                {errors[fieldName] && <div className="data-panel__form__field__errors" aria-label="Field Errors">{errors[fieldName]}</div>}
            </div>
        )
    }
}

export default TextField;
