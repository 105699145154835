import { useMemo } from "react"

import useIsDesktop from "@hooks/useIsDesktop"
import useNavigationLinks, { NavigationLink } from "@hooks/useNavigationLinks"
import useUser from "@hooks/useUser"

import filterUserLinks from "@utils/filterUserLinks"
import generateRandomKey from "@utils/generateRandomKey"

import { NavigationItem, WorkspaceButton } from "@molecules"

import { BottomBar, StackedNavigationItems, UserProfileButton } from "@organisms"

import { AppFrame } from "@templates"

import styles from "./Menu.module.scss"

export default function Menu() {
    const { user } = useUser()
    const isDesktop = useIsDesktop()

    const { home, calendar, estimates, jobs, invoices, purchase_orders, vendors, clients, pricebook, reports } =
        useNavigationLinks()

    const links: NavigationLink[] = [
        home,
        calendar,
        estimates,
        jobs,
        invoices,
        purchase_orders,
        vendors,
        clients,
        pricebook,
        reports,
    ]

    const filteredLinks = useMemo(() => filterUserLinks(links, user), [user])

    return (
        <AppFrame.Root pageId="Menu">
            {!isDesktop && (
                <>
                    <div className={styles.header}>
                        <div className={styles.headerInner}>
                            <WorkspaceButton />
                            <UserProfileButton />
                        </div>
                    </div>
                    <div className={styles.navigation}>
                        {filteredLinks.map((link) => {
                            if (link.subLinks?.length) {
                                return (
                                    <StackedNavigationItems
                                        className={styles.navigationItem}
                                        key={generateRandomKey()}
                                        header={link}
                                        items={link.subLinks}
                                    />
                                )
                            } else {
                                return (
                                    <NavigationItem
                                        key={generateRandomKey()}
                                        label={link.label}
                                        module={link.module}
                                        path={link.path}
                                        variant="primary"
                                        className={styles.navigationItem}
                                    />
                                )
                            }
                        })}
                    </div>
                    <BottomBar />
                </>
            )}
        </AppFrame.Root>
    )
}
