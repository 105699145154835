
function Banner(props) {
    const { type, text, extraMargin=false } = props

    const typeIconMap = {
        "info": "fa-sharp fa-solid fa-circle-info",
        "success": "fa-sharp fa-solid fa-circle-check",
        "warning": "fa-sharp fa-solid fa-triangle-exclamation",
        "danger": "fa-sharp fa-solid fa-square-exclamation",
    }

    return (
        <div className={`banner banner--${type}${extraMargin ? " banner--with-extra-margin" : ""}`}>
            <div><i className={typeIconMap[type] || ""} aria-hidden="true"></i></div>
            <div>{text}</div>
        </div>
    )
}

export default Banner;
