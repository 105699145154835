import EventAccordion from "../../core/components/EventAccordion"
import { valueIsDefined } from "../../core/utils/utils"


class ClientFeedAccordion extends EventAccordion {

    fetchData = async () => {
        const { clientID } = this.props

        if (valueIsDefined(clientID)) {
            const endpoint = DjangoUrls["clients:api-clients-feed-list"](window.MARKETPLACE_ENTITY_SLUG, clientID)
            const response = await fetch(endpoint)
            return await response.json()
        }
        else {
            return null
        }
    }

    getTitle = () => {
        return "Client Timeline"
    }

    getEmphasis = () => {
        return "time"
    }
}

export default ClientFeedAccordion;
