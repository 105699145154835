export default function matchesDesktopScreen(desktopScreenSize = 1024): {
    isDesktop: boolean
    media: MediaQueryList
} {
    if (typeof window.matchMedia !== "function") {
        throw Error("matchMedia not supported by browser!")
    }

    const media = window.matchMedia(`(min-width: ${desktopScreenSize}px)`)

    return {
        media,
        isDesktop: media.matches,
    }
}
