import { Breadcrumb } from "@organisms/index"

import { Settings } from "@templates/index"

import EstimatesNotificationsForm from "@pages/Settings/SettingsNotifications/components/EstimatesNotificationsForm/EstimatesNotificationsForm"

import { SETTINGS_ROUTES } from "@routes/settings"

export default function SettingsNotificationsEstimates() {
    return (
        <Settings.Wrapper title="Estimates" backDestination={SETTINGS_ROUTES.notifications}>
            <Settings.PageContainer>
                <Settings.Breadcrumbs>
                    <Breadcrumb.Item label="My account" showSlash={true} />
                    <Breadcrumb.Item
                        label="Notifications"
                        showSlash={true}
                        isActive={false}
                        path={SETTINGS_ROUTES.notifications}
                    />
                    <Breadcrumb.Item label="Estimates" path={SETTINGS_ROUTES.notificationsEstimates} />
                </Settings.Breadcrumbs>

                <Settings.PageHeader
                    variant="page-heading"
                    title="Estimates"
                    subtitle="Select the topics for which you'd like to receive notifications."
                />

                <Settings.Divider />

                <Settings.Section>
                    <Settings.PageHeader
                        variant="section-heading"
                        title="General"
                        subtitle="Notifications regarding estimates in your workspace."
                    />
                    <EstimatesNotificationsForm />
                </Settings.Section>
            </Settings.PageContainer>
        </Settings.Wrapper>
    )
}
