import { forwardRef } from "react"
import PhoneInput, { Value } from "react-phone-number-input/input"

import Input from "@molecules/Form/inputs/Input/Input"
import { InputProps } from "@molecules/Form/inputs/Input/Input.types"

import { PhoneNumberInputProps } from "./PhoneNumberInput.types"

function PhoneNumberInput<T>(props: PhoneNumberInputProps & InputProps<T>, ref: React.Ref<HTMLInputElement>) {
    const { defaultCountryCode = "US", ...rest } = props

    return (
        <Input
            ref={ref}
            type="tel"
            renderInput={(props) => (
                <PhoneInput
                    defaultCountry={defaultCountryCode}
                    placeholder="(000) 000-0000"
                    value={props.value as Value}
                    onChange={props.onChange}
                    {...props}
                />
            )}
            {...rest}
        />
    )
}

export default forwardRef(PhoneNumberInput) as typeof PhoneNumberInput
