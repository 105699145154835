import clsx from "clsx"

import styles from "./Divider.module.scss"
import { DividerProps } from "./Divider.types"

export default function Divider(props: DividerProps) {
    const { className, contrast = "low", orientation = "horizontal" } = props

    return <div className={clsx(className, styles[`${contrast}Contrast`], styles[orientation])} />
}
