import * as Popover from "@radix-ui/react-popover"
import clsx from "clsx"
import { Command } from "cmdk"

import styles from "./SelectList.module.scss"
import { SelectListProps } from "./SelectList.types"

export default function SelectList(props: SelectListProps) {
    const { className, children } = props

    return (
        <Popover.Portal>
            <Popover.Content className={clsx(styles.base, className)} sideOffset={4}>
                <Command className={styles.container}>{children}</Command>
            </Popover.Content>
        </Popover.Portal>
    )
}
