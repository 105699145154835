import useRouter from "@hooks/useRouter"

import { ifSpaceOrEnter } from "@utils/keyboard"

import styles from "./CardListCard.module.scss"
import { CardListCardProps } from "./CardListCard.types"

export default function CardListItem(props: CardListCardProps) {
    const { height, transform, index, measureElement, activeTabConfig, currentData, CardComponent } = props
    const cardData = currentData[index]
    const router = useRouter()
    const detailsURL = activeTabConfig.getDetailsRoute(cardData)

    return (
        <div
            className={styles.cardContainer}
            style={{
                height: `${height}px`,
                transform: `translateY(${transform}px)`,
            }}
            onClick={() => router.push(detailsURL)}
            onKeyDown={(event) => ifSpaceOrEnter(event, () => router.push(detailsURL))}
            tabIndex={0}
            id={cardData?.id}
            data-index={index}
            ref={measureElement}
        >
            <CardComponent data={cardData} showState={activeTabConfig.title.includes("All")} />
        </div>
    )
}
