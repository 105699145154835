import React, { Component } from 'react';
import Select from 'react-select';

class AdvancedMultiSelectField extends Component {

    render() {
        const {fieldName, fieldLabel, fieldValue, fieldOnChange, choices, placeholder, noOptionsMessage, optional=false, optionalText="optional", errors, styles=null} = this.props

        return (
            <div className="data-panel__form__field" id={`div_id_${fieldName}`} aria-label={fieldLabel}>
                <label htmlFor={`id_${fieldName}`} className="data-panel__form__field__label in-form">{fieldLabel}{optional && <span className="text-optional"> ({optionalText})</span>}</label>
                <div className="data-panel__form__field--multiselect">
                    <Select
                        name={fieldName}
                        id={`id_${fieldName}`}
                        placeholder={placeholder}
                        isMulti={true}
                        isDisabled={choices.length <= 1}
                        options={choices}
                        styles={styles === null ? {} : styles}
                        defaultValue={fieldValue}
                        onChange={selectedOptions => fieldOnChange((selectedOptions || []).map(selectedOption => selectedOption.value))}
                        autoComplete="off"
                        className="react-select-container"
                        classNamePrefix="react-select"
                        noOptionsMessage={() => noOptionsMessage}
                    />
                </div>
                {errors[fieldName] && <div className="data-panel__form__field__errors" aria-label="Field Errors">{errors[fieldName]}</div>}
            </div>
        )
    }
}

export default AdvancedMultiSelectField;
