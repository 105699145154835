import { IconsLayeringProps } from "./IconsLayering.types"
import IconsLayeringCounter from "./IconsLayeringCounter/IconsLayeringCounter"

function IconsLayering(props: IconsLayeringProps) {
    const { children, iconsSize = "1x" } = props

    return (
        <div className={`fa-${iconsSize}`}>
            <div className="fa-layers fa-fw">{children}</div>
        </div>
    )
}

const Counter = IconsLayeringCounter
const Root = IconsLayering
export default { Counter, Root }
