import { useQuery } from "@tanstack/react-query"

import { NOTIFICATION_ENDPOINTS } from "@endpoints/notification"

import { NotificationMethodsList } from "./NotificationMethodsSection.types"

const notificationMethodsQueryKey = ["list-notification-methods", NOTIFICATION_ENDPOINTS.RETRIEVE_NOTIFICATION_METHODS]

const useNotificationMethodsQuery = () => {
    return useQuery({
        queryKey: notificationMethodsQueryKey,
        queryFn: async (): Promise<NotificationMethodsList> => {
            const response = await fetch(NOTIFICATION_ENDPOINTS.RETRIEVE_NOTIFICATION_METHODS)
            return (await response.json()) as NotificationMethodsList
        },
    })
}

export { useNotificationMethodsQuery }
