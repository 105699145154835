import { DateHelper } from "@bryntum/schedulerpro"
import { JobStatuses } from "@legacy/core/utils/enums"
import clsx from "clsx"
import { useMemo } from "react"

import formatDate from "@utils/formatDate"
import formatDuration from "@utils/formatDuration"

import { UnknownAvatar, UserAvatarStack } from "@molecules"

import styles from "./TimelineViewEventCard.module.scss"
import { TimelineViewEventCardProps } from "./TimelineViewEventCard.types"
import TimelineViewEventCardIcon from "./TimelineViewEventCardIcon/TimelineViewEventCardIcon"

export default function TimelineViewEventCard(props: TimelineViewEventCardProps) {
    const {
        startTime = null,
        endTime = null,
        status,
        statusColorOverride,
        customId,
        id,
        assignedTechnicians,
        serviceName,
        client,
        location,
        isResizeEnabled,
        duration = null,
        isDragging = false,
        isCompact = false,
        isVertical = false,
        isResizingEventStart = false,
        isResizingEventEnd = false,
        isOverlappingOnResize = false,
    } = props

    const isUnscheduled = status === JobStatuses.pending && !startTime

    const timeIntervalInfo = useMemo(() => {
        if (!startTime || !endTime) {
            return {
                intervalIsBiggerThan15Minutes: false,
                intervalIsBiggerThan30Minutes: false,
                intervalIsBiggerThan45Minutes: false,
            }
        } else {
            const differenceInMinutes = DateHelper.diff(startTime, endTime, "minute")

            const intervalIsBiggerThan45Minutes = differenceInMinutes > 45
            const intervalIsBiggerThan30Minutes = differenceInMinutes > 30
            const intervalIsBiggerThan15Minutes = differenceInMinutes > 15

            return {
                intervalIsBiggerThan15Minutes,
                intervalIsBiggerThan30Minutes,
                intervalIsBiggerThan45Minutes,
            }
        }
    }, [startTime, endTime])

    const renderAvatar = () => {
        if (isUnscheduled && !isDragging) {
            return <UnknownAvatar shape="circle" size={20} />
        } else if (
            timeIntervalInfo.intervalIsBiggerThan30Minutes &&
            !timeIntervalInfo.intervalIsBiggerThan45Minutes &&
            isCompact &&
            !isVertical
        ) {
            return <UserAvatarStack limit={1} users={assignedTechnicians} avatarSize={20} showTooltip={false} />
        } else if (timeIntervalInfo.intervalIsBiggerThan30Minutes || isVertical) {
            return <UserAvatarStack limit={3} users={assignedTechnicians} avatarSize={20} showTooltip={false} />
        }

        return null
    }

    const jobId = customId || id

    const { localTimeString: formattedStartTime } = formatDate({
        datetime: startTime,
        preferredTimezone: undefined,
    })

    const { localTimeString: formattedEndTime } = formatDate({
        datetime: endTime,
        preferredTimezone: undefined,
    })

    const isResizing = isResizingEventStart || isResizingEventEnd

    return (
        <div
            className={clsx(styles.base, {
                [styles.unscheduled]: isUnscheduled,
                [styles.smallestEventTimeRange]: !timeIntervalInfo.intervalIsBiggerThan15Minutes,
                [styles.compact]: isCompact && !isVertical,
                [styles.vertical]: isVertical,
                [styles.horizontal]: !isVertical,
                [styles.resizable]: isResizeEnabled,
                [styles.dragging]: isDragging,
                [styles.resizing]: isResizing,
                [styles.resizingEventStart]: isResizingEventStart,
                [styles.resizingEventEnd]: isResizingEventEnd,
                [styles.overlappingOnResize]: isOverlappingOnResize,
            })}
            data-bryntum-event-card={true}
            data-overlapping={isOverlappingOnResize}
            data-bryntum-event-card-resize-enabled={isResizeEnabled}
        >
            <div className={styles.container}>
                <div className={styles.tooltips}>
                    <div className={styles.eventTimeTooltip} data-bryntum-event-card-tooltip-to-left={true}>
                        {formattedStartTime}
                    </div>
                    <div className={styles.eventTimeTooltip} data-bryntum-event-card-tooltip-to-right={true}>
                        {formattedEndTime}
                    </div>
                </div>

                <div className={styles.resizeHandlerAreaEventStart} />
                <div className={styles.resizeHandlerAreaEventEnd} />

                <div className={styles.statusIconJobIdAndAvatars}>
                    <div className={styles.statusIconAndJobId}>
                        <TimelineViewEventCardIcon
                            startTime={startTime}
                            status={status}
                            statusColorOverride={statusColorOverride}
                        />

                        {timeIntervalInfo.intervalIsBiggerThan15Minutes || isUnscheduled || isVertical ? (
                            <div className={styles.jobId}>{jobId}</div>
                        ) : (
                            <>{isCompact && <span className={styles.ellipsis}>...</span>}</>
                        )}
                    </div>
                    {isCompact && !isVertical && timeIntervalInfo.intervalIsBiggerThan30Minutes && (
                        <div className={styles.serviceName}>{serviceName}</div>
                    )}

                    {renderAvatar()}
                </div>
                {(!isCompact || isVertical) && (
                    <div className={styles.serviceNameAndJobDetails}>
                        <div className={styles.serviceName}>{serviceName}</div>
                        {isVertical && !timeIntervalInfo.intervalIsBiggerThan15Minutes ? null : (
                            <div className={styles.jobDetails}>
                                {client} | {location}
                            </div>
                        )}
                        {isUnscheduled && (
                            <div className={styles.time}>
                                <div className={styles.duration}>{formatDuration(duration)}</div>
                            </div>
                        )}
                    </div>
                )}
            </div>
            <div className={styles.cardFooter} data-bryntum-event-card-footer={true}>
                <div className={styles.cardResizingDuration}>{formatDuration(duration)}</div>
            </div>
        </div>
    )
}
