// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InputHelpText-base__O9qNM{display:flex;gap:var(--size-1);align-items:center;color:var(--gray-a10)}.InputHelpText-textSm__myTfa,.InputHelpText-textMd__RY2hU{margin-bottom:var(--size-0);font:var(--font-global-caption-medium-375);font-feature-settings:var(--feature-settings-global-caption-medium-375);letter-spacing:var(--letter-spacing-global-caption-medium-375)}.InputHelpText-textLg__zDjas{margin-bottom:var(--size-0);font:var(--font-mobile-caption-medium-375);font-feature-settings:var(--feature-settings-mobile-caption-medium-375);letter-spacing:var(--letter-spacing-mobile-caption-medium-375)}.InputHelpText-normal__KhO2l{color:var(--gray-a10)}.InputHelpText-info__KgdbA{color:var(--blue-a11)}.InputHelpText-warning__L_O5Y{color:var(--amber-a11)}.InputHelpText-error__YCydi{color:var(--tomato-a11)}.InputHelpText-success__EhUZZ{color:var(--jade-a11)}.InputHelpText-normalIcon__eybna{color:var(--gray-a9)}.InputHelpText-infoIcon__cGs7u{color:var(--blue-a9)}.InputHelpText-warningIcon__D0f6m{color:var(--amber-a9)}.InputHelpText-errorIcon__AEUza{color:var(--tomato-a11)}.InputHelpText-successIcon__oRjJB{color:var(--jade-a9)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "InputHelpText-base__O9qNM",
	"textSm": "InputHelpText-textSm__myTfa",
	"textMd": "InputHelpText-textMd__RY2hU",
	"textLg": "InputHelpText-textLg__zDjas",
	"normal": "InputHelpText-normal__KhO2l",
	"info": "InputHelpText-info__KgdbA",
	"warning": "InputHelpText-warning__L_O5Y",
	"error": "InputHelpText-error__YCydi",
	"success": "InputHelpText-success__EhUZZ",
	"normalIcon": "InputHelpText-normalIcon__eybna",
	"infoIcon": "InputHelpText-infoIcon__cGs7u",
	"warningIcon": "InputHelpText-warningIcon__D0f6m",
	"errorIcon": "InputHelpText-errorIcon__AEUza",
	"successIcon": "InputHelpText-successIcon__oRjJB"
};
export default ___CSS_LOADER_EXPORT___;
