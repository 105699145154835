import EmailCheckbox from "./EmailCheckbox/EmailCheckbox"
import styles from "./NotificationHeader.module.scss"
import { NotificationHeaderProps } from "./NotificationHeader.types"
import SMSCheckbox from "./SMSCheckbox/SMSCheckbox"

function NotificationHeader(props: NotificationHeaderProps) {
    const { children } = props

    return (
        <div className={styles.base}>
            <div className={styles.text}>Notify me about...</div>
            <div className={styles.notificationMethods}>{children}</div>
        </div>
    )
}

export default {
    Root: NotificationHeader,
    SMSCheckbox,
    EmailCheckbox,
}
