import { useState } from "react"

import { Dropdown } from "@molecules"
import { Item } from "@molecules/Dropdown/Dropdown.types"

import { Breadcrumb } from "@organisms"

import styles from "./TopBarPageTitle.module.scss"
import { TopBarPageTitleProps } from "./TopBarPageTitle.types"

export default function TopBarPageTitle(props: TopBarPageTitleProps) {
    const { iconName, label, navigationItems, onNavigationItemSelect, destination, iconOnly } = props

    const [dropdownIsOpen, setDropdownIsOpen] = useState<boolean>(false)
    const [activeItem, setActiveItem] = useState<Item>(null)

    const hasSubItems = navigationItems && navigationItems.length > 0

    const openDropdown = () => setDropdownIsOpen(true)
    const closeDropdown = () => setDropdownIsOpen(false)

    const onSelect = (item: Item) => {
        setActiveItem(item)
        onNavigationItemSelect?.(item)
    }

    return (
        <div className={styles.base}>
            <Breadcrumb.Item
                iconName={iconName}
                onClick={hasSubItems ? openDropdown : null}
                label={activeItem?.label || label}
                hasSubItems={hasSubItems}
                isActive={true}
                isOpen={dropdownIsOpen}
                path={destination}
                iconOnly={iconOnly}
            />

            {hasSubItems && (
                <Dropdown
                    startingPoint="right"
                    side="bottom"
                    triggerClassName={styles.dropdownTrigger}
                    isOpen={dropdownIsOpen}
                    items={navigationItems}
                    onClose={closeDropdown}
                    onSelect={onSelect}
                />
            )}
        </div>
    )
}
