import ObjectListCard from '../../core/components/ObjectListCard';
import DurationRenderer from '../../core/renderers/DurationRenderer';
import InventoryStatusRenderer from "../../core/renderers/InventoryStatusRenderer";
import PriceBookItemPriceRenderer from '../../core/renderers/PriceBookItemPriceRenderer';
import StatusChipRenderer from '../../core/renderers/StatusChipRenderer';
import TagListRenderer from '../../core/renderers/TagListRenderer';


function PriceBookListCard(props) {
    const { data } = props
    const priceBookItem = data

    const footerContext = {
        label: null,
        value: null
    }

    let bodySubtextPrimary = priceBookItem.summary_template
    let bodySubtextSecondary = null

    if (priceBookItem.pricebook_item_type_label === "Service Charge") {
        footerContext.label = "Est. Duration:"
        footerContext.value = priceBookItem.default_job_duration ? <DurationRenderer value={priceBookItem.default_job_duration} /> : "N/A"
    }
    else if (priceBookItem.pricebook_item_type_label === "Part or Material") {
        footerContext.label = "Stock:"
        footerContext.value = <InventoryStatusRenderer value={priceBookItem.inventory_status} trackInventory={priceBookItem.track_inventory} />

        if (priceBookItem.manufacturer) {
            bodySubtextPrimary = priceBookItem.manufacturer
            bodySubtextSecondary = priceBookItem.part_number
        }
        else {
            bodySubtextPrimary = priceBookItem.part_number
        }
    }

    return <ObjectListCard
        objectName="Item"
        objectID={<TagListRenderer tags={priceBookItem.labels?.map((label) => label.name) ?? []} />}
        showState={false}
        stateLabel=""
        headerContext=""
        statusChip={<StatusChipRenderer value={priceBookItem.status_label} />}
        bodyTitle={priceBookItem.description}
        bodySubtextPrimary={bodySubtextPrimary}
        bodySubtextSecondary={bodySubtextSecondary}
        footerContextLabel={footerContext.label}
        footerContextValue={footerContext.value}
        footerPrimary={<PriceBookItemPriceRenderer instance={priceBookItem} />}
    />
}

export default PriceBookListCard;
