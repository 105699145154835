import { Component } from "react";
import ButtonGroup from "../../../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../../../core/buttons/UniversalButton";
import { renderRecordServiceLocationString } from "../utils/utils";


class QuickBooksServiceLocationRecordDisplay extends Component {

    render() {
        const { serviceLocationRecord, requestAction} = this.props

        return (
            // React can't handle nested buttons, so this is an exception case where we're not using a wrapper component
            <a className="button-wrapper button button--object-link data-panel__list-select__option" onClick={event => requestAction("RECORD_EDIT_SERVICE_LOCATION")} title={renderRecordServiceLocationString(serviceLocationRecord)} aria-label="Edit Service Location Record" tabIndex="0">
                <div className="data-panel__list-select__option__label record-label">
                    <div className="record-label__row">
                        <div className="record-label__field" aria-label="Location Name">
                            <div className="record-label__field-label" aria-hidden="true">Location Name</div>
                            <div className="record-label__field-value">{serviceLocationRecord.cleaned_service_location_name || "--"}</div>
                        </div>
                    </div>
                    <div className="record-label__row">
                        <div className="record-label__field" aria-label="Location Address">
                            <div className={"record-label__field-label" + (serviceLocationRecord.errors.cleaned_service_location_physical_address_street ? " invalid" : "")} aria-hidden="true">Location Address</div>
                            <div className="record-label__field-value">{serviceLocationRecord.cleaned_service_location_physical_address_formatted_multiline || "--"}</div>
                            {serviceLocationRecord.errors.cleaned_service_location_physical_address_street && <div className="data-panel__form__field__errors" aria-label="Field Errors">{serviceLocationRecord.errors.cleaned_service_location_physical_address_street}</div>}
                        </div>
                    </div>
                    <div className="record-label__row">
                        <div className="record-label__field" aria-label="Location Billing Address">
                            <div className="record-label__field-label" aria-hidden="true">Location Billing Address</div>
                            <div className="record-label__field-value">{serviceLocationRecord.cleaned_service_location_billing_address_formatted_multiline || "Same as client"}</div>
                        </div>
                    </div>
                    <div className="record-label__row">
                        <div className="record-label__field" aria-label="Gate Codes, Hazards, etc.">
                            <div className="record-label__field-label" aria-hidden="true">Gate Codes, Hazards, etc.</div>
                            <div className="record-label__field-value">{serviceLocationRecord.cleaned_service_location_notes || "--"}</div>
                        </div>
                    </div>
                    <div className="record-label__row">
                        <div className="record-label__field" aria-label="Location Contacts">
                            <div className="record-label__field-label" aria-hidden="true">Location Contacts</div>
                            <div className="record-label__field-value">{serviceLocationRecord.contacts.length !== 0 ? `${serviceLocationRecord.contacts.length} contact${serviceLocationRecord.contacts.length !== 1 ? "s" : ""}` : "(No contacts)"}</div>
                        </div>
                    </div>
                    <ButtonGroup>
                        <ButtonGroupRow>
                            <UniversalButton type="secondary" text="Ignore Record" handler={event => requestAction("RECORD_IGNORE_SERVICE_LOCATION")} />
                        </ButtonGroupRow>
                    </ButtonGroup>
                </div>
                <div className="data-panel__list-select__option__icon"><i className="fa-sharp fa-angle-right" aria-hidden="true"></i></div>
            </a>
        )
    }
}

export default QuickBooksServiceLocationRecordDisplay;
