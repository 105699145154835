import ClientCreateContainer from "@legacy/clients/ClientCreateContainer"

import useIsDesktop from "@hooks/useIsDesktop"
import useSlugExtractor from "@hooks/useSlugExtractor"
import useToastQueue from "@hooks/useToastQueue"

import { TopBar } from "@organisms"

import { AppFrame } from "@templates"

import { CLIENTS_ROUTES } from "@routes/clients"

export default function ClientEdit() {
    const isDesktop = useIsDesktop()

    const clientId = window.CLIENT_ID

    const [listRoute, detailsRoute] = useSlugExtractor([CLIENTS_ROUTES.LIST, CLIENTS_ROUTES.DETAILS], clientId)
    const { addToastToQueue } = useToastQueue()

    return (
        <AppFrame.Root pageId="ClientEdit">
            <TopBar.Root>
                <TopBar.Header>
                    <TopBar.TopBarHeaderStart>
                        <TopBar.PageTitle
                            iconOnly={!isDesktop}
                            iconName="clients"
                            label="Clients"
                            destination={listRoute}
                        />
                        <TopBar.VerticalDivider />
                        <TopBar.PageSubtitle label="Edit Client" backDestination={detailsRoute} />
                    </TopBar.TopBarHeaderStart>
                </TopBar.Header>
            </TopBar.Root>
            <AppFrame.PageContent>
                <ClientCreateContainer formMode="EDIT_CLIENT" addToastToQueue={addToastToQueue} />
            </AppFrame.PageContent>
        </AppFrame.Root>
    )
}
