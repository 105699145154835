import SearchOrCreateSelectInput from "../../core/inputs/SearchOrCreateSelectInput";


function getVendorObjectLabel(vendor) {
    return <span><strong>{vendor.name}</strong></span>
}

function vendorToVendorOption(vendor) {
    return {value: vendor.id, label: getVendorObjectLabel(vendor), object: vendor}
}

function VendorSearchOrCreateSelect(props) {
    const endpointKwargs = [
        ["is_active", true],
    ]

    return <SearchOrCreateSelectInput
        objectName="Vendor"
        getObjectLabel={getVendorObjectLabel}
        listEndpoint={DjangoUrls["pricebook:api-vendors-lightweight-list"](window.MARKETPLACE_ENTITY_SLUG)}
        listEndpointKwargs={endpointKwargs}
        getDetailsEndpoint={(vendor) => DjangoUrls["pricebook:api-vendors-detail"](window.MARKETPLACE_ENTITY_SLUG, vendor.id)}
        {...props}
    />
}

export { vendorToVendorOption };
export default VendorSearchOrCreateSelect
