import * as SliderPrimitive from "@radix-ui/react-slider"
import clsx from "clsx"
import { forwardRef } from "react"

import styles from "./Slider.module.scss"

function Slider(props: React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>, ref: React.Ref<HTMLDivElement>) {
    const { className, ...rest } = props

    return (
        <SliderPrimitive.Root ref={ref} className={clsx(styles.base, className)} {...rest}>
            <SliderPrimitive.Track className={styles.track}>
                <SliderPrimitive.Range className={styles.range} />
            </SliderPrimitive.Track>
            <SliderPrimitive.Thumb className={styles.thumb} />
        </SliderPrimitive.Root>
    )
}

export default forwardRef(Slider)
