import EventAccordion from "../../core/components/EventAccordion"
import { valueIsDefined } from "../../core/utils/utils"


class PurchaseOrderFeedAccordion extends EventAccordion {

    fetchData = async () => {
        const { purchaseOrderID } = this.props

        if (valueIsDefined(purchaseOrderID)) {
            const endpoint = DjangoUrls["purchase-orders:api-purchase-orders-feed-list"](window.MARKETPLACE_ENTITY_SLUG, purchaseOrderID)
            const response = await fetch(endpoint)
            return await response.json()
        }
        else {
            return null
        }
    }

    getTitle = () => {
        return "Purchase Order Timeline"
    }

    getEmphasis = () => {
        return "time"
    }
}

export default PurchaseOrderFeedAccordion;
