import { useContext } from "react"

import { TabStackContext } from "./TabStack"
import { TabStackContextValues } from "./TabStack.types"

export default function useTabStack(): TabStackContextValues {
    const context = useContext(TabStackContext)
    if (!context) {
        throw new Error("useTabStack must be used within a TabStack component")
    }

    return context
}
