import clsx from "clsx"
import dayjs from "dayjs"
import duration from "dayjs/plugin/duration"

import useUser from "@hooks/useUser"

import formatCurrency from "@utils/formatCurrency"
import formatDuration from "@utils/formatDuration"

import { Table } from "@organisms"

import styles from "./NumericalDataCell.module.scss"
import { NumericalDataCellProps } from "./NumericalDataCell.types"

dayjs.extend(duration)

export default function NumericalDataCell(props: NumericalDataCellProps) {
    const { value, type = "number", unitType, ignoreZero = false, isAlerting } = props

    const { user } = useUser()

    const isNoValue = (!value && value !== 0) || (ignoreZero && Number(value) === 0)

    if (isNoValue) {
        return <Table.Cells.NoValueCell isRightAligned={true} />
    }

    let renderedValue = String(value)

    if (type === "currency") {
        renderedValue = formatCurrency({
            value: Number(value),
            currencyCode: user.service_company.currency_code,
            languageCode: user.service_company.language_code,
        })
    } else if (type === "duration") {
        renderedValue = formatDuration(Number(value))
    } else if (type === "percentage") {
        renderedValue = `${value}%`
    } else if (type === "value-hour") {
        const formattedPrice = formatCurrency({
            value: Number(value),
            currencyCode: user.service_company.currency_code,
            languageCode: user.service_company.language_code,
        })

        const suffix = unitType === "Hourly" ? "/hr" : ""

        renderedValue = `${formattedPrice}${suffix}`
    }

    return (
        <div className={clsx(styles.base, styles[`${type}Type`], { [styles.alerting]: isAlerting })}>
            {renderedValue}
        </div>
    )
}
