import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"

import useIsDesktop from "@hooks/useIsDesktop"

import { IconButton } from "@atoms"

import styles from "./TopBarSearchButton.module.scss"
import { TopBarSearchButtonProps } from "./TopBarSearchButton.types"

export default function TopBarSearchButton(props: TopBarSearchButtonProps) {
    const { scope } = props

    const isDesktop = useIsDesktop()

    const onClick = () => {
        const openSearchModal = new CustomEvent("openSearchModal", {
            detail: {
                scope,
            },
        })
        window.dispatchEvent(openSearchModal)
    }

    if (!isDesktop) {
        return null
    }

    return (
        <IconButton
            colorScheme="gray"
            size="sm"
            variant="ghost"
            className={styles.base}
            icon={icon({
                name: "search",
                style: "light",
                family: "sharp",
            })}
            onClick={onClick}
            aria-label="Open search"
            noDebounce={true}
        />
    )
}
