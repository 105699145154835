import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"

import { Icon } from "@atoms"

import styles from "./CardListLoaderCard.module.scss"
import { CardListLoaderCardProps } from "./CardListLoaderCard.types"

export default function CardListLoaderCard(props: CardListLoaderCardProps) {
    const { height, transform } = props
    return (
        <div
            className={styles.base}
            style={{
                height: `${height}px`,
                transform: `translateY(${transform}px)`,
            }}
        >
            <div className={styles.contentContainer}>
                <div className={styles.icon} aria-hidden="true">
                    <Icon icon={icon({ name: "hourglass", style: "light", family: "sharp" })} />
                </div>
                <div className={styles.body}>
                    <div>Loading more cards...</div>
                </div>
            </div>
        </div>
    )
}
