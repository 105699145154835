import { BryntumGrid, BryntumSchedulerPro } from "@bryntum/schedulerpro-react"
import clsx from "clsx"
import { MutableRefObject, createContext, useEffect, useRef } from "react"

import useUser from "@hooks/useUser"

import "./BryntumBaseRoopairsTheme.scss"
import { JobEventDrag } from "./JobEventDrag"
import styles from "./JobTimelineView.module.scss"
import JobTimelineViewHorizontalScheduler from "./JobTimelineViewHorizontalScheduler"
import JobTimelineViewHorizontalUnscheduledJobs from "./JobTimelineViewHorizontalUnscheduledJobs"
import "./JobTimelineViewStyles/JobTimelineViewCells.scss"
import "./JobTimelineViewStyles/JobTimelineViewControlsDatePicker.scss"
import "./JobTimelineViewStyles/JobTimelineViewEvent.scss"
import "./JobTimelineViewStyles/JobTimelineViewGrid.scss"
import "./JobTimelineViewStyles/JobTimelineViewTechniciansBar.scss"
import "./JobTimelineViewStyles/JobTimelineViewTimeAxisHeader.scss"
import JobTimelineViewVerticalScheduler from "./JobTimelineViewVerticalScheduler"
import JobTimelineViewVerticalUnscheduledJobs from "./JobTimelineViewVerticalUnscheduledJobs"
import useJobTimelineViewStates from "./hooks/useJobTimelineViewStates"

interface JobTimelineViewContextProps {
    schedulerPro: MutableRefObject<BryntumSchedulerPro>
    unscheduledJobsGrid: MutableRefObject<BryntumGrid>
    unscheduledJobsGridDrag: MutableRefObject<JobEventDrag>
}

export const JobTimelineViewContext = createContext<JobTimelineViewContextProps>(null)

export default function JobTimelineView() {
    const { timelineViewOrientation } = useJobTimelineViewStates()
    const { user } = useUser()

    if (!user) {
        return null
    } else {
        if (timelineViewOrientation === "horizontal") {
            return <HorizontalTimeline />
        } else {
            return <VerticalTimeline />
        }
    }
}

const HorizontalTimeline = () => {
    const { jobCardStyle } = useJobTimelineViewStates()
    const { user } = useUser()

    const schedulerProRef = useRef<BryntumSchedulerPro>()
    const unscheduledJobsGridRef = useRef<BryntumGrid>(null)
    const unscheduledJobsGridDragRef = useRef<JobEventDrag>(null)

    // This keeps the unscheduled jobs panel in sync with the scheduler style
    useEffect(() => {
        if (unscheduledJobsGridDragRef.current) {
            unscheduledJobsGridDragRef.current.schedulerIsInCompactMode = jobCardStyle === "compact"
        }
    }, [jobCardStyle])

    return (
        <JobTimelineViewContext.Provider
            value={{
                schedulerPro: schedulerProRef,
                unscheduledJobsGrid: unscheduledJobsGridRef,
                unscheduledJobsGridDrag: unscheduledJobsGridDragRef,
            }}
        >
            <div
                className={clsx(styles.base, "job-timeline-view", {
                    [styles.timelineViewForTechnician]: user.isServiceTechnician,
                })}
            >
                <JobTimelineViewHorizontalScheduler />
                <JobTimelineViewHorizontalUnscheduledJobs />
            </div>
        </JobTimelineViewContext.Provider>
    )
}

const VerticalTimeline = () => {
    const { user } = useUser()

    const schedulerProRef = useRef<BryntumSchedulerPro>()
    const unscheduledJobsGridRef = useRef<BryntumGrid>(null)
    const unscheduledJobsGridDragRef = useRef<JobEventDrag>(null)

    return (
        <JobTimelineViewContext.Provider
            value={{
                schedulerPro: schedulerProRef,
                unscheduledJobsGrid: unscheduledJobsGridRef,
                unscheduledJobsGridDrag: unscheduledJobsGridDragRef,
            }}
        >
            <div
                className={clsx(styles.base, "job-timeline-view", {
                    [styles.timelineViewForTechnician]: user.isServiceTechnician,
                })}
            >
                <JobTimelineViewVerticalScheduler />
                <JobTimelineViewVerticalUnscheduledJobs />
            </div>
        </JobTimelineViewContext.Provider>
    )
}
