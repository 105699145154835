import { ObjectListData } from "@organisms/ObjectsView/ObjectsView.types"
import { SortingState } from "@organisms/Table/Table.types"

import getOrderingFromSortingState from "./getOrderingFromSortingState"

interface fetchObjectListDataArgs {
    objectName: string
    endpoint: string
    endpointKwargs: Kwargs
    searchKeywords: string
    sorting?: SortingState
    nextPage?: string
}

export default async function fetchObjectListData(args: fetchObjectListDataArgs): Promise<ObjectListData> {
    const { objectName, endpoint, endpointKwargs, searchKeywords, sorting, nextPage } = args
    let fetchUrl: string

    if (nextPage) {
        fetchUrl = nextPage
    } else {
        const searchParams = new URLSearchParams()

        endpointKwargs.forEach(([key, value]) => searchParams.append(key, value.toString()))

        searchParams.set("keywords", searchKeywords)
        searchParams.set("ordering", getOrderingFromSortingState(sorting))

        fetchUrl = endpoint + "?" + searchParams.toString()
    }

    const response = await fetch(fetchUrl)
    if (!response.ok) {
        throw new Error(`There was an error while trying to load ${objectName.toLowerCase()}.`)
    }

    return (await response.json()) as ObjectListData
}
