import Spinner from "@legacy/core/components/Spinner";
import { Component } from "react";
import { renderServiceLocationString } from "../../clients/utils/utils";
import ButtonGroup from "../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../core/buttons/UniversalButton";
import BasicDisplayField from "../../core/fields/BasicDisplayField";
import DurationField from "../../core/fields/DurationField";
import GeneratedDatetimeField from "../../core/fields/GeneratedDatetimeField";
import TextField from "../../core/fields/TextField";
import { JobOriginTypes } from "../../core/utils/enums";
import AssignedTechniciansField from "../fields/AssignedTechniciansField";
import { getDefaultAssignedTechnicians } from "../utils/utils";


class JobScheduleForm extends Component {

    componentDidMount() {
        // Return to the previous scroll
        document.querySelector(".main").scrollTo(0, this.props.returnScroll || 0)
    }

    renderButtons = () => {
        const {job, scheduleData, requestAction, switchToPrimaryForm, submitting, errors, onFormDataChange, workingTechnicianOptions, returnScroll} = this.props

        if (errors.unexpectedError) {
            return (
                <div className="data-panel__action-feedback">
                    <span className="text-invalid"><strong>An unexpected error occurred.</strong></span>
                </div>
            )
        }
        else {
            if (submitting) {
                return <Spinner centered={true} />
            }
            else {
                return (
                    <ButtonGroup>
                        <ButtonGroupRow>
                            <UniversalButton type="primary" text="Schedule" handler={event => requestAction("JOB_SCHEDULE_SUBMIT")} />
                            <UniversalButton type="secondary" text="Cancel" handler={event => switchToPrimaryForm()} />
                        </ButtonGroupRow>
                    </ButtonGroup>
                )
            }
        }
    }

    render() {
        const {job, scheduleData, requestAction, switchToPrimaryForm, submitting, errors, onFormDataChange, workingTechnicianOptions, returnScroll} = this.props

        return (
            <div className="data-panel-container data-panel-container--with-margin">
                <div className="data-panel" aria-label="Job Schedule">
                    <div className="data-panel__form" aria-label="Job Schedule Form">
                        <p className="data-panel__form__caption">Please provide some additional details to schedule this job.</p>
                        <BasicDisplayField
                            fieldName="id"
                            fieldLabel="Roopairs Job ID"
                            fieldValue={job.custom_id ? job.custom_id : job.id}
                        ></BasicDisplayField>
                        {
                            job.origin_type !== JobOriginTypes.roopairs && (
                                <BasicDisplayField
                                    fieldName="origin_id"
                                    fieldLabel={`${job.origin_type !== JobOriginTypes.other ? job.origin_type_label : job.origin_type_other_name} ID`}
                                    fieldValue={job.origin_id}
                                ></BasicDisplayField>
                            )
                        }
                        <BasicDisplayField
                            fieldName="service_name"
                            fieldLabel="Service Type"
                            fieldValue={job.service_name}
                        ></BasicDisplayField>
                        <BasicDisplayField
                            fieldName="is_job_walk"
                            fieldLabel="Job Type"
                            fieldValue={job.is_callback ? "Callback" : job.is_job_walk ? "Job Walk" : "Service"}
                        ></BasicDisplayField>

                        <BasicDisplayField
                            fieldName="service_location"
                            fieldLabel="Service Location"
                            fieldValue={renderServiceLocationString(job.service_location, true)}
                        ></BasicDisplayField>

                        <TextField
                            fieldName="details"
                            fieldLabel={job.is_callback ? "Reason For Callback" : "Reason For Call"}
                            fieldValue={scheduleData.details || job.details || ""}
                            fieldOnChange={details => onFormDataChange("details", details || "")}
                            rows={3}
                            placeholder="Customer states..."
                            errors={errors}
                        ></TextField>
                        <TextField
                            fieldName="dispatcher_notes"
                            fieldLabel="Technician Instructions"
                            fieldValue={scheduleData.dispatcher_notes || job.dispatcher_notes || ""}
                            fieldOnChange={dispatcher_notes => onFormDataChange("dispatcher_notes", dispatcher_notes || "")}
                            rows={3}
                            placeholder={`Notes specific to this ${job.is_job_walk ? "job walk" : "service"}. e.g. 'Ask client about replacing the unit.' or 'Make sure to collect payment on site.'`}
                            optional={true}
                            errors={errors}
                        ></TextField>
                        <GeneratedDatetimeField
                            fieldName="estimated_arrival_time"
                            fieldLabel="Estimated Arrival Time"
                            fieldValue={scheduleData.estimated_arrival_time}
                            fieldOnChange={estimated_arrival_time => onFormDataChange("estimated_arrival_time", estimated_arrival_time || null)}
                            errors={errors}
                            preferredTimezone={job.service_company.preferred_timezone}
                        ></GeneratedDatetimeField>
                        <DurationField
                            fieldName="estimated_duration"
                            fieldLabel="Estimated Job Duration"
                            fieldValue={scheduleData.estimated_duration || ""}
                            fieldOnChange={estimated_duration => onFormDataChange("estimated_duration", estimated_duration || null)}
                            unit="hours"
                            unitMultiplier={3600}
                            errors={errors}
                        ></DurationField>
                        <AssignedTechniciansField
                            fieldName="assigned_technicians"
                            fieldLabel="Assigned Technician(s)"
                            fieldValue={getDefaultAssignedTechnicians(scheduleData, workingTechnicianOptions)}
                            fieldOnChange={assigned_technicians => onFormDataChange("assigned_technicians", assigned_technicians || [])}
                            choices={workingTechnicianOptions}
                            placeholder="Select technicians..."
                            noOptionsMessage="No Technicians Found"
                            errors={errors}
                        ></AssignedTechniciansField>
                    </div>
                    {this.renderButtons()}
                </div>
            </div>
        )
    }
}

export default JobScheduleForm;
