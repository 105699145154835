import Banner from "@legacy/core/components/Banner";
import Spinner from "@legacy/core/components/Spinner";
import { Component, Fragment } from "react";
import ButtonGroup from "../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../core/buttons/UniversalButton";
import AttachmentHorizontalDisplay from "../../core/components/AttachmentHorizontalDisplay";
import EmailDisplay from "../../core/components/EmailDisplay";
import LineItemListDisplay from "../../core/components/LineItemListDisplay";
import PhoneNumberDisplay from "../../core/components/PhoneNumberDisplay";
import BasicDisplayField from "../../core/fields/BasicDisplayField";
import LinkedObjectsDisplayField from "../../core/fields/LinkedObjectsDisplayField";
import { convertToKebabCase, currencyFormatterForServiceCompany, formatCurrencyForServiceCompany, formatLocalTime } from "../../core/utils/utils";
import JobObjectLink from "../../jobs/components/JobObjectLink";
import PurchaseOrderFeedAccordion from "../components/PurchaseOrderFeedAccordion";
import { calculatePurchaseOrderAmounts, getPurchaseOrderShippingPreference, getPurchaseOrderStatusBarLabel, getPurchaseOrderTrackingDetails } from "../utils/utils";


class PurchaseOrderDetailsCard extends Component {

    componentDidMount() {
        // Return to the previous scroll
        document.querySelector(".main").scrollTo(0, this.props.returnScroll || 0)
    }

    renderButtons = () => {
        const { purchaseOrder, associatedJobs, requestAction, switchToSecondaryForm, submitting, errors, carrierAccountID } = this.props

        if (submitting) {
            return (
                <div className="data-panel__action-feedback">
                    <Spinner centered={true} />
                </div>
            )
        }
        else {
            if (window.USING_PUBLIC_URL === true) {
                if (purchaseOrder.status_label === "Sent") {
                    return (
                        <ButtonGroup>
                            <ButtonGroupRow>
                                <UniversalButton type="primary" text="Acknowledge Order" handler={event => requestAction("PURCHASE_ORDER_ACKNOWLEDGE")} />
                            </ButtonGroupRow>
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Download PDF" handler={event => requestAction("PURCHASE_ORDER_DOWNLOAD_PDF")} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
                else if (purchaseOrder.status_label === "Acknowledged") {
                    return (
                        <ButtonGroup>
                            <ButtonGroupRow>
                                <UniversalButton type="primary" text="Mark as Shipped" handler={event => requestAction("PURCHASE_ORDER_SHIP")} />
                            </ButtonGroupRow>
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Download PDF" handler={event => requestAction("PURCHASE_ORDER_DOWNLOAD_PDF")} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
                else if (purchaseOrder.status_label === "In Transit") {
                    return (
                        <ButtonGroup>
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Update Shipping Details" handler={event => requestAction("PURCHASE_ORDER_UPDATE_SHIPPING")} />
                            </ButtonGroupRow>
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Download PDF" handler={event => requestAction("PURCHASE_ORDER_DOWNLOAD_PDF")} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
                else {
                    return (
                        <ButtonGroup>
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Download PDF" handler={event => requestAction("PURCHASE_ORDER_DOWNLOAD_PDF")} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
            }
            else {
                if (purchaseOrder.status_label === "Draft") {
                    return (
                        <ButtonGroup>
                            <ButtonGroupRow>
                                <UniversalButton type="primary" text="Create and Send" handler={event => requestAction("PURCHASE_ORDER_CREATE")} />
                            </ButtonGroupRow>
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Download PDF" handler={event => requestAction("PURCHASE_ORDER_DOWNLOAD_PDF")} />
                                <UniversalButton type="secondary" text="Edit Draft" handler={event => requestAction("PURCHASE_ORDER_EDIT")} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
                else if (purchaseOrder.status_label === "Unsent") {
                    return (
                        <ButtonGroup>
                            <ButtonGroupRow>
                                <UniversalButton type="primary" text="Send Order" handler={event => requestAction("PURCHASE_ORDER_SEND")} />
                            </ButtonGroupRow>
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Revise Order" handler={event => requestAction("PURCHASE_ORDER_EDIT")} />
                            </ButtonGroupRow>
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Download PDF" handler={event => requestAction("PURCHASE_ORDER_DOWNLOAD_PDF")} />
                                <UniversalButton type="danger" text="Mark Cancelled" handler={event => requestAction("PURCHASE_ORDER_CANCEL")} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
                else if (purchaseOrder.status_label === "Sent") {
                    return (
                        <ButtonGroup>
                            <ButtonGroupRow>
                                <UniversalButton type="primary" text="Mark as Acknowledged" handler={event => requestAction("PURCHASE_ORDER_ACKNOWLEDGE")} />
                            </ButtonGroupRow>
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Revise Order" handler={event => requestAction("PURCHASE_ORDER_EDIT")} />
                            </ButtonGroupRow>
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Resend Order" handler={event => requestAction("PURCHASE_ORDER_SEND")} />
                            </ButtonGroupRow>
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Download PDF" handler={event => requestAction("PURCHASE_ORDER_DOWNLOAD_PDF")} />
                                <UniversalButton type="danger" text="Mark Cancelled" handler={event => requestAction("PURCHASE_ORDER_CANCEL")} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
                else if (purchaseOrder.status_label === "Acknowledged") {
                    return (
                        <ButtonGroup>
                            <ButtonGroupRow>
                                <UniversalButton type="primary" text="Mark as Shipped" handler={event => requestAction("PURCHASE_ORDER_SHIP")} />
                            </ButtonGroupRow>
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Revise Order" handler={event => requestAction("PURCHASE_ORDER_EDIT")} />
                            </ButtonGroupRow>
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Download PDF" handler={event => requestAction("PURCHASE_ORDER_DOWNLOAD_PDF")} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
                else if (purchaseOrder.status_label === "In Transit") {
                    return (
                        <ButtonGroup>
                            <ButtonGroupRow>
                                <UniversalButton type="primary" text="Mark as Delivered" handler={event => requestAction("PURCHASE_ORDER_MARK_AS_DELIVERED")} />
                            </ButtonGroupRow>
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Update Shipping Details" handler={event => requestAction("PURCHASE_ORDER_UPDATE_SHIPPING")} />
                            </ButtonGroupRow>
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Revise Order" handler={event => requestAction("PURCHASE_ORDER_EDIT")} />
                            </ButtonGroupRow>
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Download PDF" handler={event => requestAction("PURCHASE_ORDER_DOWNLOAD_PDF")} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
                else if (purchaseOrder.status_label === "Unstocked") {
                    return (
                        <ButtonGroup>
                            <ButtonGroupRow>
                                <UniversalButton type="primary" text="Mark as Stocked" handler={event => requestAction("PURCHASE_ORDER_MARK_AS_STOCKED")} />
                            </ButtonGroupRow>
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Revise Order" handler={event => requestAction("PURCHASE_ORDER_EDIT")} />
                            </ButtonGroupRow>
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Download PDF" handler={event => requestAction("PURCHASE_ORDER_DOWNLOAD_PDF")} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
                else if (purchaseOrder.status_label === "Unpaid") {
                    return (
                        <ButtonGroup>
                            <ButtonGroupRow>
                                <UniversalButton type="primary" text="Mark as Paid" handler={event => requestAction("PURCHASE_ORDER_MARK_AS_PAID")} />
                            </ButtonGroupRow>
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Revise Order" handler={event => requestAction("PURCHASE_ORDER_EDIT")} />
                            </ButtonGroupRow>
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Download PDF" handler={event => requestAction("PURCHASE_ORDER_DOWNLOAD_PDF")} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
                else if (purchaseOrder.status_label === "Paid" || purchaseOrder.status_label === "Cancelled") {
                    return (
                        <ButtonGroup>
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Download PDF" handler={event => requestAction("PURCHASE_ORDER_DOWNLOAD_PDF")} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
            }
        }
    }

    renderLastViewed = (lastViewed, preferredTimezone) => {
        const { localDateTimeString } = formatLocalTime(lastViewed, preferredTimezone, false, true, false, true)
        return localDateTimeString
    }

    render() {
        const { purchaseOrder, associatedJobs, requestAction, switchToSecondaryForm, submitting, errors, carrierAccountID } = this.props
        const { subtotal } = calculatePurchaseOrderAmounts(purchaseOrder)

        return (
            <Fragment>
                <div id="purchase_order_details_react_wrapper">
                    <div className="data-panel-container">
                        <div className={`data-panel-status-bar purchase-orders-${convertToKebabCase(purchaseOrder.status_label)}`} aria-label="Status"><span>{getPurchaseOrderStatusBarLabel(purchaseOrder)}{window.USING_PUBLIC_URL === true && " Purchase Order"}</span></div>
                        <div className="data-panel with-status-bar" aria-label="Purchase Order Details">
                            {
                                purchaseOrder.service_company.logo && (
                                    <div className="data-panel__logo" aria-hidden="true">
                                        <div className="data-panel__logo__frame">
                                            <img src={purchaseOrder.service_company.logo} alt="Logo" />
                                        </div>
                                    </div>
                                )
                            }
                            <div className="data-panel__form" aria-label="Purchase Order Details">
                                <div className="data-panel__form__field" aria-label="Purchase Order Vendor">
                                    <span className="data-panel__form__field__label" aria-hidden="true">Vendor</span>
                                    <div className="data-panel__form__field__display-list" aria-label="Vendor">
                                        <div className="data-panel__form__field__display-list__object purchase-order-contact-card" aria-label="Vendor">
                                            <p>
                                                <strong>{purchaseOrder.vendor.name}</strong><br />
                                                <span>{purchaseOrder.vendor.physical_address_formatted_multiline}</span><br />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="data-panel__form__field" aria-label="Purchase Order Bill To">
                                    <span className="data-panel__form__field__label" aria-hidden="true">Bill To</span>
                                    <div className="data-panel__form__field__display-list" aria-label="Bill To">
                                        <div className="data-panel__form__field__display-list__object invoice-contact-card" aria-label="Bill To">
                                            <p>
                                                <strong>{purchaseOrder.service_company.name}</strong><br />
                                                {purchaseOrder.vendor.account_id && <Fragment><span>Account ID: {purchaseOrder.vendor.account_id}</span><br /></Fragment>}
                                                <span>{purchaseOrder.service_company.physical_address_formatted_multiline}</span><br />
                                                {purchaseOrder.service_company.phone && <Fragment><span><PhoneNumberDisplay phone={purchaseOrder.service_company.phone} phoneExtension={purchaseOrder.service_company.phone_extension} hyperlink={true} /></span><br /></Fragment>}
                                                {purchaseOrder.service_company.email && <Fragment><span><EmailDisplay email={purchaseOrder.service_company.email} hyperlink={true} /></span><br /></Fragment>}
                                                {purchaseOrder.service_company.contractor_license_number && <Fragment><span>License #{purchaseOrder.service_company.contractor_license_number}</span><br /></Fragment>}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="data-panel__form__field" aria-label="Purchase Order Ship To">
                                    <span className="data-panel__form__field__label" aria-hidden="true">Ship To</span>
                                    <div className="data-panel__form__field__display-list" aria-label="Ship To">
                                        <div className="data-panel__form__field__display-list__object invoice-contact-card" aria-label="Ship To">
                                            <p>
                                                <strong>{purchaseOrder.service_company.name}</strong><br />
                                                <span>Re: Roopairs Order: {purchaseOrder.custom_id ? purchaseOrder.custom_id : purchaseOrder.id}</span><br />
                                                <span>{purchaseOrder.inventory_location.physical_address_formatted_multiline}</span><br />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <BasicDisplayField
                                    fieldName="inventory_location"
                                    fieldLabel="Inventory Location"
                                    fieldValue={purchaseOrder.inventory_location.name}
                                ></BasicDisplayField>
                                <BasicDisplayField
                                    fieldName="id"
                                    fieldLabel="Roopairs Purchase Order ID"
                                    fieldValue={purchaseOrder.custom_id ? purchaseOrder.custom_id : purchaseOrder.id}
                                ></BasicDisplayField>
                                {
                                    purchaseOrder.vendor_internal_id && (
                                        <BasicDisplayField
                                            fieldName="vendor_internal_id"
                                            fieldLabel="Vendor Purchase Order ID"
                                            fieldValue={purchaseOrder.vendor_internal_id}
                                        ></BasicDisplayField>
                                    )
                                }
                                <BasicDisplayField
                                    fieldName="date_issued"
                                    fieldLabel="Date Issued"
                                    fieldValue={formatLocalTime(purchaseOrder.date_issued, purchaseOrder.service_company.preferred_timezone, true, false, false, true).localDateString}
                                ></BasicDisplayField>
                                {
                                    purchaseOrder.estimated_arrival_time && ["Pending", "Open", "In Transit"].includes(purchaseOrder.state_label) && (
                                        <BasicDisplayField
                                            fieldName="estimated_arrival_time"
                                            fieldLabel="Estimated Arrival Time"
                                            fieldValue={formatLocalTime(purchaseOrder.estimated_arrival_time, purchaseOrder.service_company.preferred_timezone, false, true, false, true).localDateTimeString}
                                        ></BasicDisplayField>
                                    )
                                }
                                <BasicDisplayField
                                    fieldName="shipping"
                                    fieldLabel={["Pending", "Open"].includes(purchaseOrder.state_label) ? "Shipping Preference" : "Shipping"}
                                    fieldValue={getPurchaseOrderShippingPreference(purchaseOrder)}
                                ></BasicDisplayField>
                                {
                                    carrierAccountID && (
                                        <BasicDisplayField
                                            fieldName="carrier_account_id"
                                            fieldLabel="Carrier Account ID"
                                            fieldValue={carrierAccountID}
                                        ></BasicDisplayField>
                                    )
                                }
                                {
                                    !["Pending", "Open"].includes(purchaseOrder.state_label) && (
                                        <BasicDisplayField
                                            fieldName="tracking_number"
                                            fieldLabel="Tracking Number"
                                            fieldValue={getPurchaseOrderTrackingDetails(purchaseOrder)}
                                        ></BasicDisplayField>
                                    )
                                }
                                <BasicDisplayField
                                    fieldName="payment_terms"
                                    fieldLabel="Payment Terms"
                                    fieldValue={purchaseOrder.vendor.payment_terms !== 0 ? `Net ${purchaseOrder.vendor.payment_terms}` : "Same Day"}
                                ></BasicDisplayField>
                                {
                                    !window.USING_PUBLIC_URL && purchaseOrder.last_viewed !== null && (
                                        <BasicDisplayField
                                            fieldName="last_viewed"
                                            fieldLabel="Last Seen"
                                            fieldValue={this.renderLastViewed(purchaseOrder.last_viewed, purchaseOrder.service_company.preferred_timezone)}
                                        ></BasicDisplayField>
                                    )
                                }
                                {
                                    purchaseOrder.last_status_transition_time !== null && (
                                        <BasicDisplayField
                                            fieldName="last_status_transition_time"
                                            fieldLabel={`${["Sent", "Acknowledged"].includes(purchaseOrder.status_label) ? purchaseOrder.status_label : purchaseOrder.state_label} On`}
                                            fieldValue={this.renderLastViewed(purchaseOrder.last_status_transition_time, purchaseOrder.service_company.preferred_timezone)}
                                        ></BasicDisplayField>
                                    )
                                }
                                {
                                    purchaseOrder.last_status_transition_message !== "" && (
                                        <BasicDisplayField
                                            fieldName="last_status_transition_message"
                                            fieldLabel={`${["Sent", "Acknowledged"].includes(purchaseOrder.status_label) ? purchaseOrder.status_label : purchaseOrder.state_label} Message`}
                                            fieldValue={purchaseOrder.last_status_transition_message}
                                        ></BasicDisplayField>
                                    )
                                }
                                <BasicDisplayField
                                    fieldName="last_modified_at"
                                    fieldLabel="Last Modified"
                                    fieldValue={this.renderLastViewed(purchaseOrder.last_modified_at, purchaseOrder.service_company.preferred_timezone)}
                                ></BasicDisplayField>
                                <LineItemListDisplay
                                    fieldName="parts"
                                    fieldLabel="Parts and Materials"
                                    parent={purchaseOrder}
                                    lineItemList={purchaseOrder.parts}
                                    emptyMessage="No parts and materials"
                                    formatCurrencyValue={currencyFormatterForServiceCompany(purchaseOrder.service_company)}
                                    isPurchaseOrderLineItem={true}
                                ></LineItemListDisplay>
                                {
                                    purchaseOrder.comments !== "" && (
                                        <BasicDisplayField
                                            fieldName="comments"
                                            fieldLabel="Comments"
                                            fieldValue={purchaseOrder.comments}
                                        ></BasicDisplayField>
                                    )
                                }
                                {
                                    purchaseOrder.attachments.length !== 0 && purchaseOrder.service_company.feature_file_uploads_enabled &&  (
                                        <BasicDisplayField
                                            fieldName="attachments"
                                            fieldLabel="Attachments"
                                            fieldValue={<AttachmentHorizontalDisplay
                                                attachments={purchaseOrder.attachments}
                                                    />}
                                            >
                                        </BasicDisplayField>)
                                }
                                {
                                    purchaseOrder.service_company.purchase_order_fine_print !== "" && (
                                        <BasicDisplayField
                                            fieldName="purchase_order_fine_print"
                                            fieldLabel={purchaseOrder.service_company.purchase_order_fine_print_label}
                                            fieldValue={purchaseOrder.service_company.purchase_order_fine_print}
                                        ></BasicDisplayField>
                                    )
                                }
                                {
                                    !(window.USING_PUBLIC_URL === true) && associatedJobs.length !== 0 && (
                                        <LinkedObjectsDisplayField
                                            fieldName="associated_jobs_"
                                            fieldLabel="Associated Jobs"
                                            objectComponentList={associatedJobs.map((associatedJob, index) => <JobObjectLink key={`associated_jobs_${index}`} job={associatedJob} destination={DjangoUrls["jobs:jobs-detail"](window.MARKETPLACE_ENTITY_SLUG, associatedJob.id)}></JobObjectLink>)}
                                        ></LinkedObjectsDisplayField>
                                    )
                                }
                            </div>
                            <div className="data-panel__form data-panel__data-summary amount-summary" aria-label="Amount Summary">
                                <hr aria-hidden="true" />
                                <div className="data-panel__data-summary__row amount-summary__item amount-summary__item--subtotal" aria-label="Subtotal">
                                    <div className="data-panel__data-summary__data__label"><span>Expected Subtotal</span></div>
                                    <div className="data-panel__data-summary__data__value">{formatCurrencyForServiceCompany(subtotal, purchaseOrder.service_company)}</div>
                                </div>
                            </div>
                            {
                                Object.keys(errors).length !== 0 && (
                                    <Banner type="danger" text="This purchase order has errors and cannot be created. Please edit the draft to fix them." extraMargin={true} />
                                )
                            }
                            {this.renderButtons()}
                        </div>
                    </div>
                </div>
                {!(window.USING_PUBLIC_URL === true) &&
                    <div className="accordion-wrapper">
                        <PurchaseOrderFeedAccordion purchaseOrderID={purchaseOrder.id} />
                    </div>
                }
                {window.USING_PUBLIC_URL === true &&
                    <div className="powered-by-panel">
                        <span>Powered By</span>
                        <img className="logo--grey" src={window.LOGOTYPE_URL} width="148px" alt="Roopairs"></img>
                    </div>
                }
            </Fragment>
        )
    }

}

export default PurchaseOrderDetailsCard;
