import React from 'react';
import EventFeedItem from "../../core/components/EventFeedItem";
import Accordion from './Accordion';


class EventAccordion extends Accordion {

    getEmptyText = () => {
        return "No events found."
    }

    getEmphasis = () => {
        return null
    }

    renderBody = (data) => {
        if (data.length !== 0) {
            return data.map((event, index) => <EventFeedItem key={index} event={event} emphasis={this.getEmphasis()} />)
        }
        else {
            return <span className="text-centered">{this.getEmptyText()}</span>
        }
    }
}

export default EventAccordion;
