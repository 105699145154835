import nr from 'number-rounding';

function calculateEstimateAmounts(estimate) {
    let subtotal = 0
    let tax = 0
    let downPaymentAmountPaid = 0

    // Subtotal
    const serviceCharges = estimate.service_charges || []
    const parts = estimate.parts || []
    const otherCharges = estimate.other_charges || []
    const discounts = estimate.discounts || []

    const addToSubtotal = (lineItem) => {subtotal += nr.roundHalfEven(parseFloat(lineItem.quantity || 0) * parseFloat(lineItem.price || 0), 2)}
    const addToTax = (lineItem) => {lineItem.is_taxable ? tax += parseFloat(lineItem.quantity || 0) * parseFloat(lineItem.price || 0) * parseFloat(estimate.tax_percent / 100 || 0) : 0}

    serviceCharges.forEach(addToSubtotal)
    serviceCharges.forEach(addToTax)
    parts.forEach(addToSubtotal)
    parts.forEach(addToTax)
    otherCharges.forEach(addToSubtotal)
    otherCharges.forEach(addToTax)
    discounts.forEach(addToSubtotal)
    discounts.forEach(addToTax)

    if (serviceCharges.length === 0 && parts.length === 0 && otherCharges.length === 0 && discounts.length === 0 && estimate.line_items !== undefined && estimate.line_items.length !== 0) {
        estimate.line_items.forEach(addToSubtotal)
        estimate.line_items.forEach(addToTax)
    }

    // Round Tax after combining items
    tax = nr.roundHalfEven(tax, 2)

    // Total
    let total = subtotal + tax
    total = (total.toFixed(2) === "-0.00" ? 0 : total)

    // Down Payment Amount Paid
    const payments = estimate.payments || []
    payments.forEach((payment) => {downPaymentAmountPaid += parseFloat(payment.amount || 0)})

    // Down Payment Amount Due
    let downPaymentAmountDue = parseFloat(estimate.down_payment_amount || 0) - downPaymentAmountPaid
    downPaymentAmountDue = (downPaymentAmountDue.toFixed(2) === "-0.00" ? 0 : downPaymentAmountDue)

    return {subtotal, tax, total, downPaymentAmountPaid, downPaymentAmountDue}
}

function getEstimateStatusBarLabel(estimate) {
    let statusBarLabel = estimate.status_label

    if (estimate.status_label === "Draft") {
        statusBarLabel = "Draft Preview"
    }
    else if (estimate.status_label === "Unconverted") {
        statusBarLabel = "Approved and Unconverted"
    }

    // Remap status names for public view
    if (window.USING_PUBLIC_URL === true) {
        if (estimate.state_label === "Sent") {
            statusBarLabel = "Awaiting Approval"
        }
        else if (estimate.state_label === "Won") {
            statusBarLabel = "Approved"
        }
        else if (estimate.state_label === "Lost") {
            statusBarLabel = "Declined"
        }
    }

    return statusBarLabel
}


export { calculateEstimateAmounts, getEstimateStatusBarLabel };
