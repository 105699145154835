import styles from "./Breadcrumb.module.scss"
import { BreadcrumbProps } from "./Breadcrumb.types"
import BreadcrumbItem from "./BreadcrumbItem/BreadcrumbItem"

function Breadcrumb(props: BreadcrumbProps) {
    const { children } = props

    return <div className={styles.base}>{children}</div>
}

const Root = Breadcrumb
const Item = BreadcrumbItem

export default { Root, Item }
