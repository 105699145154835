import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import clsx from "clsx"
import { useMemo } from "react"

import useIsDesktop from "@hooks/useIsDesktop"

import { IconSize } from "@atoms/Icon/Icon.types"

import { Icon } from ".."
import styles from "./AlertBanner.module.scss"
import { AlertBannerProps } from "./AlertBanner.types"

export default function AlertBanner(props: AlertBannerProps) {
    const { type, size, title, subtitle, fullWidth = false, className } = props

    const typeIcon = useMemo(() => {
        const typeMap: { [key in AlertBannerProps["type"]]: IconProp } = {
            normal: icon({ name: "circle-question", style: "regular", family: "sharp" }),
            info: icon({ name: "circle-info", style: "regular", family: "sharp" }),
            warning: icon({ name: "warning", style: "regular", family: "sharp" }),
            error: icon({ name: "circle-exclamation", style: "regular", family: "sharp" }),
            success: icon({ name: "circle-check", style: "regular", family: "sharp" }),
        }
        return typeMap[type]
    }, [type])

    const isDesktop = useIsDesktop()
    const iconSize = useMemo(() => {
        const sizeMap: { [key in AlertBannerProps["size"]]: IconSize } = {
            sm: isDesktop ? 14 : 12,
            md: isDesktop ? 16 : 14,
        }
        return sizeMap[size]
    }, [size])

    return (
        <div
            role="alert"
            aria-live="polite"
            className={clsx(styles.base, styles[type], styles[`${size}Size`], className, {
                [styles.fullWidth]: fullWidth === true,
                [styles.onlyTitle]: !subtitle,
            })}
        >
            <Icon icon={typeIcon} size={iconSize} />
            <div>
                <p className={clsx(styles.title)}>{title}</p>
                {subtitle && <p className={clsx(styles.subtitle)}>{subtitle}</p>}
            </div>
        </div>
    )
}
