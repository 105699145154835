import { useOthers, useSelf } from "@configs/liveblocks.config"

import useIsDesktop from "@hooks/useIsDesktop"

import UserAvatarStack from "@molecules/UserAvatarStack/UserAvatarStack"

import styles from "./TopBarPagePresence.module.scss"

const TopBarPagePresence = () => {
    const self = useSelf()
    const others = useOthers()

    const isDesktop = useIsDesktop()

    const users: AvatarUser[] = []

    if (self) {
        users.push(self.info)
    }
    if (others) {
        others.forEach((other) => users.push(other.info))
    }

    return (
        isDesktop && <div className={styles.pagePresence}>{users && <UserAvatarStack users={users} limit={3} />}</div>
    )
}

export default TopBarPagePresence
