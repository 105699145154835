// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HotKey-base__u6Htj{display:inline-flex;align-items:center;justify-content:center;min-width:var(--size-4_5);min-height:var(--size-4_5);padding:0 var(--size-1);border:var(--size-0_25) solid rgba(0,0,0,0);border-radius:var(--border-radius-1)}.HotKey-solid__A3VZE{background:var(--surface-tertiary-a);box-shadow:var(--box-shadow-xs-down)}.HotKey-subtle__nMzGy{background:var(--gray-a3)}.HotKey-outline__ZpKU2{background:var(--white);border:var(--size-0_25) solid var(--gray-a5)}.HotKey-symbol__BeG0V{color:var(--gray-a11);text-align:center;font:var(--font-global-caption-medium-375);font-feature-settings:var(--feature-settings-global-caption-medium-375);letter-spacing:var(--letter-spacing-global-caption-medium-375)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "HotKey-base__u6Htj",
	"solid": "HotKey-solid__A3VZE",
	"subtle": "HotKey-subtle__nMzGy",
	"outline": "HotKey-outline__ZpKU2",
	"symbol": "HotKey-symbol__BeG0V"
};
export default ___CSS_LOADER_EXPORT___;
