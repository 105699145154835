import useAuth from "@pages/Auth/Auth.store"

import styles from "./AuthDivider.module.scss"

export default function AuthDivider() {
    const { isOnOTPSlide } = useAuth()

    return isOnOTPSlide ? null : (
        <div className={styles.base}>
            <div className={styles.line} />
            Or
            <div className={styles.line} />
        </div>
    )
}
