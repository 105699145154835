import { UserAvatarStack } from "@molecules"

import { Table } from "@organisms"

import { UsersCellProps } from "./UsersCell.types"

export default function UsersCell(props: UsersCellProps) {
    const { users } = props

    const noAvatars = users.length === 0

    if (noAvatars) {
        return <Table.Cells.NoValueCell />
    } else {
        return <UserAvatarStack users={users} limit={2} />
    }
}
