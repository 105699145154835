import { JobStatusBadge } from "@molecules"

import styles from "./StatusCell.module.scss"
import { StatusCellProps } from "./StatusCell.types"

export default function StatusCell(props: StatusCellProps) {
    const { status, dateDue, dotStatusOverride = null } = props

    return (
        <div className={styles.base}>
            <JobStatusBadge status={status} dateDue={dateDue} dotStatusOverride={dotStatusOverride} />
        </div>
    )
}
