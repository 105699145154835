import dayjs from "dayjs"
import { useEffect, useState } from "react"

import useIsDesktop from "@hooks/useIsDesktop"
import useUser from "@hooks/useUser"

import { Button } from "@atoms/index"

import SecurityCheckDialog from "@organisms/SecurityCheckDialog/SecurityCheckDialog"

import SetPasswordDialog from "@pages/Settings/SettingsSecurityAndAccess/components/SetPasswordDialog/SetPasswordDialog"

import { useHasPasswordQuery } from "./SetPasswordDialogsTrigger.store"

export default function SetPasswordDialogsTrigger() {
    const isDesktop = useIsDesktop()

    const { user, updateUser } = useUser()

    const [canUpdatePassword, setCanUpdatePassword] = useState<boolean>(false)
    const [madeSecurityCheck, setMadeSecurityCheck] = useState<boolean>(false)
    const [isSetPasswordDialogOpen, setIsSetPasswordDialogOpen] = useState<boolean>(false)

    const { data, isLoading } = useHasPasswordQuery()

    useEffect(() => {
        if (user) {
            const accountIsVerified = user.last_verified_at
            // todo: when user timezone settings feature get done, use it here
            const lastVerificationIsNewerThan5Minutes = dayjs().subtract(5, "minutes").isBefore(user.last_verified_at)

            setCanUpdatePassword(accountIsVerified && lastVerificationIsNewerThan5Minutes)
        }
    }, [user?.last_verified_at, isSetPasswordDialogOpen])

    useEffect(() => {
        if (data?.has_password) {
            updateUser({ hasPassword: true })
        }
    }, [data?.has_password])

    const onSecurityCheckFinish = () => {
        updateUser({ last_verified_at: dayjs().toDate() })
        setCanUpdatePassword(true)
        setMadeSecurityCheck(true)
    }

    const renderSetPasswordButton = () => {
        return (
            <Button
                colorScheme="gray"
                size={isDesktop ? "md" : "lg"}
                variant="solid"
                isFullWidth={true}
                isLoading={isLoading}
            >
                {!data?.has_password ? "Set password" : "Update password"}
            </Button>
        )
    }

    if (canUpdatePassword) {
        return (
            <SetPasswordDialog onOpenChange={setIsSetPasswordDialogOpen} isDefaultOpen={madeSecurityCheck}>
                {renderSetPasswordButton()}
            </SetPasswordDialog>
        )
    } else {
        return (
            <SecurityCheckDialog checkFor="email" onSuccess={onSecurityCheckFinish}>
                {renderSetPasswordButton()}
            </SecurityCheckDialog>
        )
    }
}
