import debounce from "debounce-promise";
import React from "react";
import CardFilterInput from "../../../core/components/CardFilterInput";
import TabbedContainer from "../../../core/TabbedContainer";
import { IndustryTypes } from "../../../core/utils/enums";
import { snakeCaseToTitleCase } from "../../../core/utils/utils";
import QuickBooksClientRecordTabSwitcher from "../quickbooks_common/components/QuickBooksClientRecordTabSwitcher";

const INDUSTRY_TYPES = [
    {"value": 0, "label": "All Types"},
    {"value": IndustryTypes.residential, "label": "Residential"},
    {"value": IndustryTypes.commercial, "label": "Commercial"},
    {"value": IndustryTypes.industrial, "label": "Industrial"},
    {"value": IndustryTypes.government, "label": "Government"},
]


class QuickBooksDesktopClientRecordListContainer extends TabbedContainer {

    constructor(props) {
        super(props)

        this.state = {
            tabbedObjects: {},
            existingTabbedObjectIDs: {},
            filteredTabbedObjects: {},
            nextRecordsEndpoints: {},
            nextRecordsWithOrphansEndpoints: {},
            loadingMoreObjects: false,
        }
    }

    filterObjects = (filterKwargs) => {
        const {tabs, tabFilterMap} = this.props

        const keywordQuery = filterKwargs.searchQuery || ""
        let industryType = filterKwargs.dropdownSelection || "0"
        industryType = industryType !== "0" ? industryType : ""

        for (let tab of this.filterTabs(tabs)) {
            let searchParams = new URLSearchParams(tabFilterMap.get(tab))
            searchParams.append("keywords", keywordQuery)
            searchParams.append("cleaned_client_industry_type", industryType)

            this.setState((state, props) => {
                let updatedState = state
                updatedState.tabbedObjects = {}
                updatedState.filteredTabbedObjects = {}
                updatedState.existingTabbedObjectIDs = {}
                updatedState.nextRecordsEndpoints = {}
                updatedState.nextRecordsWithOrphansEndpoints = {}
                return updatedState
            })
            this.fetchObjects(tab, "?" + searchParams.toString())
        }
    }

    getListEndpoint = (tab) => null

    _fetchResults = async (tab, tabFilterKwargs) => {
        const baseRecordEndpoint = DjangoUrls["quickbooks_desktop:api-quickbooks-desktop-records-list"](window.MARKETPLACE_ENTITY_SLUG)
        const baseRecordWithOrphansEndpoint = DjangoUrls["quickbooks_desktop:api-quickbooks-desktop-records-with-orphans-list"](window.MARKETPLACE_ENTITY_SLUG)

        const results = await Promise.all([
            this._getPagedResults(tab, tabFilterKwargs, baseRecordEndpoint, "nextRecordsEndpoints"),
            this._getPagedResults(tab, tabFilterKwargs, baseRecordWithOrphansEndpoint, "nextRecordsWithOrphansEndpoints"),
        ])

        const recordResults = results[0]
        const recordsWithOrphansResults = results[1]
        const combinedResults = recordResults.concat(recordsWithOrphansResults)

        return combinedResults
    }

    shouldFetchMoreObjects = (tab) => {
        return this.state.nextRecordsEndpoints[tab] !== null || this.state.nextRecordsWithOrphansEndpoints[tab] !== null
    }

    renderFilterInput = () => <CardFilterInput onKwargsChange={debounce(this.filterObjects, 500)} dropdownChoices={INDUSTRY_TYPES} showSearch={true} showDropdown={true}></CardFilterInput>
    renderTabSwitcher = () => <QuickBooksClientRecordTabSwitcher tabs={this.filterTabs(this.props.tabs)} tabTitleConverter={tab => snakeCaseToTitleCase(tab)} filteredTabbedObjects={this.state.filteredTabbedObjects} objectName="Record" shouldFetchMoreObjects={this.shouldFetchMoreObjects} loadingMoreObjects={this.state.loadingMoreObjects} fetchMoreObjects={this.fetchMoreObjects}></QuickBooksClientRecordTabSwitcher>
}

export default QuickBooksDesktopClientRecordListContainer;
