// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BottomSheetDragIndicator-base__F5dje{display:flex;align-items:center;justify-content:center;padding:var(--size-1_5) var(--size-2);background:var(--white)}.BottomSheetDragIndicator-indicator__Mv8jl{display:block;width:var(--size-10);height:var(--size-1);background-color:var(--gray-7);border-radius:var(--size-2)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "BottomSheetDragIndicator-base__F5dje",
	"indicator": "BottomSheetDragIndicator-indicator__Mv8jl"
};
export default ___CSS_LOADER_EXPORT___;
