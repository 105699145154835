import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import clsx from "clsx"

import { SrOnly } from "@particles/index"

import { IconButton } from "@atoms/index"

import { Modal } from "@molecules"

import styles from "./ModalHeader.module.scss"
import { ModalHeaderProps } from "./ModalHeader.types"

export default function ModalHeader(props: ModalHeaderProps) {
    const { children, className, showDivider = false, ...rest } = props

    return (
        <div
            className={clsx(styles.base, className, {
                [styles.divider]: showDivider,
            })}
            {...rest}
        >
            {children}
            <Modal.Close className={styles.iconButtonWrap} asChild={true}>
                <div>
                    <IconButton
                        colorScheme="gray"
                        icon={icon({
                            name: "close",
                            family: "sharp",
                            style: "regular",
                        })}
                        variant="ghost"
                        size="sm"
                        onClick={() => null}
                    />
                    <SrOnly>Close</SrOnly>
                </div>
            </Modal.Close>
        </div>
    )
}
