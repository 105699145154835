import clsx from "clsx"
import { forwardRef } from "react"
import { createPortal } from "react-dom"

import { ifSpaceOrEnter } from "@utils/keyboard"

import styles from "./Overlay.module.scss"
import { OverlayProps } from "./Overlay.types"

const body = document.querySelector("body")

function Overlay(props: OverlayProps, ref: React.Ref<HTMLDivElement>) {
    const { isActive, onClick, isTransparent } = props

    return createPortal(
        <div
            ref={ref}
            onClick={onClick}
            className={clsx(styles.base, { [styles.active]: isActive, [styles.transparent]: isTransparent })}
            aria-hidden={!isActive}
            role="button"
            tabIndex={isActive ? 0 : -1}
            onKeyDown={(event) => ifSpaceOrEnter(event, onClick)}
        />,
        body,
    )
}

export default forwardRef(Overlay)
