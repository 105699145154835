import Spinner from "@legacy/core/components/Spinner";
import { Component } from "react";
import { renderClientString } from "../../clients/utils/utils";
import ButtonGroup from "../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../core/buttons/UniversalButton";
import TextField from "../../core/fields/TextField";


class JobSkipSignatureForm extends Component {

    componentDidMount() {
        // Return to the previous scroll
        document.querySelector(".main").scrollTo(0, this.props.returnScroll || 0)
    }

    renderButtons = () => {
        const {job, confirmData, requestAction, switchToPrimaryForm, submitting, errors, onFormDataChange, returnScroll} = this.props

        if (errors.unexpectedError) {
            return (
                <div className="data-panel__action-feedback">
                    <span className="text-invalid"><strong>An unexpected error occurred.</strong></span>
                </div>
            )
        }
        else {
            if (submitting) {
                return <Spinner centered={true} />
            }
            else {
                return (
                    <ButtonGroup>
                        <ButtonGroupRow>
                            <UniversalButton type="primary" text="Skip and Complete" handler={event => requestAction("JOB_SKIP_SIGNATURE_SUBMIT")} />
                            <UniversalButton type="secondary" text="Cancel" handler={event => switchToPrimaryForm()} />
                        </ButtonGroupRow>
                    </ButtonGroup>
                )
            }
        }
    }

    render() {
        const {job, confirmData, requestAction, switchToPrimaryForm, submitting, errors, onFormDataChange, returnScroll} = this.props

        return (
            <div className="data-panel-container data-panel-container--with-margin">
                <div className="data-panel" aria-label="Job Skip Signature">
                    <div className="data-panel__form" aria-label="Job Skip Signature Form">
                        <p className="data-panel__form__caption">Please provide a reason for why a signature couldn't be collected:</p>

                        <TextField
                            fieldName="signature_skip_reason"
                            fieldLabel="Skip Reason"
                            fieldValue={confirmData.signature_skip_reason || ""}
                            fieldOnChange={signature_skip_reason => onFormDataChange("signature_skip_reason", signature_skip_reason || "")}
                            rows={3}
                            placeholder={`Let ${renderClientString(job.service_location.external_client)} know why you can't collect a signature.`}
                            errors={errors}
                        ></TextField>
                    </div>
                    {this.renderButtons()}
                </div>
            </div>
        )
    }
}

export default JobSkipSignatureForm;
