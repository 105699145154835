import { useContext } from "react"

import { JobTimelineViewContext } from "@organisms/ObjectsView/JobTimelineView/JobTimelineView"

export default function useJobTimelineViewBryntumInstances() {
    const context = useContext(JobTimelineViewContext)
    if (!context) {
        throw new Error("useJobTimelineViewBryntumInstances must be used within a JobTimelineView component")
    }

    return context
}
