import { Component, Fragment } from 'react';
import UniversalButton from '../buttons/UniversalButton';


class OpenInMapsButton extends Component {

    openAddressInMapsApp = (address) => {
        const encodedAddress = encodeURIComponent(address)

        // Apple needs some special treatment; android auto-opens a google maps url in maps; desktop goes to google maps
        let path = `https://www.google.com/maps/dir/?api=1&destination=${encodedAddress}`

        if (navigator.platform.includes("iPhone") || navigator.platform.includes("iPad") || navigator.platform.includes("iPod")) {
            path = `https://maps.apple.com/?daddr=${encodedAddress}`;
        }
        window.open(path, "_blank");
    }

    render() {
        return <UniversalButton type="data_panel_pill" text={<Fragment><i className="fa-sharp fa-regular fa-map-location-dot"></i>Open in Maps</Fragment>} handler={event => this.openAddressInMapsApp(this.props.address)} />
    }
}

export default OpenInMapsButton;
