import EventAccordion from "../../core/components/EventAccordion"
import { valueIsDefined } from "../../core/utils/utils"


class JobHistoryAccordion extends EventAccordion {

    fetchData = async () => {
        const { jobID } = this.props

        if (valueIsDefined(jobID)) {
            const endpoint = DjangoUrls["jobs:api-jobs-job-history-list"](window.MARKETPLACE_ENTITY_SLUG, jobID)
            const response = await fetch(endpoint)
            return await response.json()
        }
        else {
            return null
        }
    }

    getTitle = () => {
        return "Location History"
    }

    getEmphasis = () => {
        return "date"
    }

    getEmptyText = () => {
        return "No location history found."
    }
}

export default JobHistoryAccordion;
