import * as Popover from "@radix-ui/react-popover"

import { SelectProps } from "./Select.types"
import SelectInput from "./SelectInput/SelectInput"
import SelectList from "./SelectList/SelectList"
import SelectListHeader from "./SelectListHeader/SelectListHeader"
import SelectListItem from "./SelectListItem/SelectListItem"
import SelectListItemStack from "./SelectListItemStack/SelectListItemStack"

function Select(props: SelectProps) {
    const { children } = props

    return <Popover.Root>{children}</Popover.Root>
}

const Root = Select
const List = SelectList
const Input = SelectInput
const ListHeader = SelectListHeader
const ListItemStack = SelectListItemStack
const ListItem = SelectListItem

export default {
    Root,
    List,
    Input,
    ListHeader,
    ListItemStack,
    ListItem,
}
