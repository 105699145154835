// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BottomSheetFooter-base__og0UO{position:sticky;bottom:var(--size-0);display:flex;gap:var(--size-2);align-items:center;align-self:stretch;justify-content:space-between;padding:var(--size-4);background-color:var(--white)}.BottomSheetFooter-base__og0UO.BottomSheetFooter-withDivider__XEQR9::after{position:absolute;top:var(--size-0);right:var(--size-0);left:var(--size-0);width:var(--size-full);height:var(--size-0_25);background-color:var(--gray-4);content:\"\"}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "BottomSheetFooter-base__og0UO",
	"withDivider": "BottomSheetFooter-withDivider__XEQR9"
};
export default ___CSS_LOADER_EXPORT___;
