import { Component, Fragment } from "react";
import ButtonGroup from "../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../core/buttons/UniversalButton";
import UniversalLinkButton from "../../core/buttons/UniversalLinkButton";
import HorizontalLayout from "../../core/components/HorizontalLayout";
import BasicDisplayField from "../../core/fields/BasicDisplayField";
import { renderInvoiceEstimateDate } from "../../core/utils/utils";
import EquipmentJobHistory from "./EquipmentJobHistory";
import EquipmentWarrantyListDisplay from "./EquipmentWarrantyListDisplay";


class InlineEquipmentDisplay extends Component {

    renderButtons = () => {
        const {
            equipment,
            editable,
            showJobHistory,
            showNextButton,
            nextButtonUrl,
            requestAction
        } = this.props

        return (
            <ButtonGroup className="button-group--accordion">
                {
                    editable && (!window.USING_PUBLIC_URL) && (
                        <ButtonGroupRow>
                            <UniversalButton type="secondary" text="Edit" handler={event => requestAction("EQUIPMENT_EDIT")} />
                        </ButtonGroupRow>
                    )
                }
                {
                    showNextButton && (
                        <ButtonGroupRow>
                            <UniversalLinkButton type="primary" text="Next" destination={nextButtonUrl} />
                        </ButtonGroupRow>
                    )
                }
            </ButtonGroup>
        )
    }

    render() {
        const {
            equipment,
            editable,
            showJobHistory,
            showNextButton,
            nextButtonUrl,
            requestAction
        } = this.props

        return (
            <Fragment>
                <div className="data-panel__form" aria-label="Equipment Display">
                    <HorizontalLayout>
                        <BasicDisplayField
                            fieldName="equipment_category"
                            fieldLabel="Equipment Category"
                            fieldValue={equipment.equipment_category_label}
                        ></BasicDisplayField>
                        <BasicDisplayField
                            fieldName="equipment_type"
                            fieldLabel="Equipment Type"
                            fieldValue={equipment.equipment_type_label || "--"}
                        ></BasicDisplayField>
                    </HorizontalLayout>
                    <HorizontalLayout>
                        <BasicDisplayField
                            fieldName="manufacturer"
                            fieldLabel="Manufacturer"
                            fieldValue={equipment.manufacturer || "--"}
                        ></BasicDisplayField>
                        <BasicDisplayField
                            fieldName="model_number"
                            fieldLabel="Model Number"
                            fieldValue={equipment.model_number || "--"}
                        ></BasicDisplayField>
                    </HorizontalLayout>
                    <HorizontalLayout>
                        <BasicDisplayField
                            fieldName="serial_number"
                            fieldLabel="Serial Number"
                            fieldValue={equipment.serial_number || "--"}
                        ></BasicDisplayField>
                        <BasicDisplayField
                            fieldName="ownership_type"
                            fieldLabel="Ownership Type"
                            fieldValue={equipment.ownership_type_label}
                        ></BasicDisplayField>
                    </HorizontalLayout>
                    <HorizontalLayout>
                        <BasicDisplayField
                            fieldName="location"
                            fieldLabel="Location in Building"
                            fieldValue={equipment.location || "--"}
                        ></BasicDisplayField>
                        <BasicDisplayField
                            fieldName="installation_date"
                            fieldLabel="Installation Date"
                            fieldValue={equipment.installation_date ? renderInvoiceEstimateDate(equipment.installation_date, "UTC") : "--"}
                        ></BasicDisplayField>
                    </HorizontalLayout>
                    <BasicDisplayField
                        fieldName="notes"
                        fieldLabel="Notes"
                        fieldValue={equipment.notes || "--"}
                    ></BasicDisplayField>
                    <BasicDisplayField
                        fieldName="warranty_status"
                        fieldLabel="Warranty Status"
                        fieldValue={equipment.warranty_status}
                    ></BasicDisplayField>
                    <EquipmentWarrantyListDisplay
                        fieldName="warranties"
                        fieldLabel="Warranties"
                        warranties={equipment.warranties}
                    ></EquipmentWarrantyListDisplay>
                </div>
                {showJobHistory && <EquipmentJobHistory equipmentID={equipment.id} />}
                {this.renderButtons()}
            </Fragment>
        )
    }
}

export default InlineEquipmentDisplay;
