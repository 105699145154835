import { Tab, Toggle } from "@atoms/index"

import { MobileHeader, TabStack } from "@molecules/index"

import BottomSheet from "@organisms/BottomSheet/BottomSheet"
import useJobTimelineViewEventHandlers from "@organisms/ObjectsView/JobTimelineView/hooks/useJobTimelineViewEventHandlers"
import useJobTimelineViewStates from "@organisms/ObjectsView/JobTimelineView/hooks/useJobTimelineViewStates"

import styles from "./TimelineViewMenuBottomSheet.module.scss"
import { TimelineViewMenuBottomSheetProps } from "./TimelineViewMenuBottomSheet.types"

export default function TimelineViewMenuBottomSheet(props: TimelineViewMenuBottomSheetProps) {
    const { isOpen, onClose } = props

    const {
        jobCardStyle,
        setJobCardStyleToCompact,
        setJobCardStyleToRegular,
        shouldShowCancelledJobs,
        shouldShowFinalizedJobs,
        timelineViewOrientation,
    } = useJobTimelineViewStates()

    const { onCancelledJobsToggle, onFinalizedJobsToggle, onSetSchedulerToHorizontal, onSetSchedulerToVertical } =
        useJobTimelineViewEventHandlers()

    return (
        <BottomSheet.Root isOpen={isOpen} onClose={onClose}>
            <MobileHeader
                title="Timeline View Settings"
                titleIcon="fa-sharp-light-timeline-view"
                isTabbable={isOpen}
                showDragIndicator={true}
            />
            <div className={styles.content}>
                <BottomSheet.Item label="Show Finalized Jobs" onClick={onFinalizedJobsToggle}>
                    <Toggle size="md" checked={shouldShowFinalizedJobs} isControlled={false} />
                </BottomSheet.Item>
                <BottomSheet.Item label="Show Cancelled Jobs" onClick={onCancelledJobsToggle}>
                    <Toggle size="md" checked={shouldShowCancelledJobs} isControlled={false} />
                </BottomSheet.Item>
                <BottomSheet.Item label="Timeline Orientation">
                    <TabStack attached={true} size="sm">
                        <Tab
                            isPressed={timelineViewOrientation === "vertical"}
                            onClick={onSetSchedulerToVertical}
                            noDebounce={true}
                        >
                            Vertical
                        </Tab>
                        <Tab
                            isPressed={timelineViewOrientation === "horizontal"}
                            onClick={onSetSchedulerToHorizontal}
                            noDebounce={true}
                        >
                            Horizontal
                        </Tab>
                    </TabStack>
                </BottomSheet.Item>
                {timelineViewOrientation === "horizontal" && (
                    <BottomSheet.Item label="Job Card Style">
                        <TabStack attached={true} size="sm">
                            <Tab
                                isPressed={jobCardStyle === "compact"}
                                onClick={setJobCardStyleToCompact}
                                noDebounce={true}
                            >
                                Compact
                            </Tab>
                            <Tab
                                isPressed={jobCardStyle === "regular"}
                                onClick={setJobCardStyleToRegular}
                                noDebounce={true}
                            >
                                Regular
                            </Tab>
                        </TabStack>
                    </BottomSheet.Item>
                )}
            </div>
        </BottomSheet.Root>
    )
}
