import replaceSlugs from "@utils/replaceSlugs"

import useUser from "./useUser"

type RoutePath = string
type ID = string

export default function useSlugExtractor(routes: RoutePath[], id?: ID) {
    const { user } = useUser()

    const slugValues = { service_company_slug: user?.service_company?.slug }

    if (id) {
        slugValues["id"] = id
    }

    if (!user?.service_company?.slug) {
        return [null]
    } else {
        return routes.map((route) => replaceSlugs(route, slugValues))
    }
}
