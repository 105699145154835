import { useMutation } from "@tanstack/react-query"
import { useContext } from "react"

import { AvatarManagementContext } from "./AvatarManagement"
import { AvatarManagementContextValues, UserAvatar } from "./AvatarManagement.types"
import { deleteUserAvatar, updateUserAvatar } from "./AvatarManagement.utils"

export function useAvatarManagement(): AvatarManagementContextValues {
    const context = useContext(AvatarManagementContext)
    if (!context) {
        throw new Error("useAvatarManagement must be used within a AvatarManagement component")
    }

    return context
}

export function useUpdateAvatarMutation() {
    return useMutation<UserAvatar, Error, UserAvatar>({
        mutationFn: updateUserAvatar,
    })
}

export function useRemoveAvatarMutation() {
    return useMutation<void, Error>({
        mutationFn: deleteUserAvatar,
    })
}
