// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SetPasswordDialog-subtitle__YcNdi{display:flex;flex-direction:column;align-items:flex-start;align-self:stretch;padding:var(--size-4);color:var(--gray-a11);font:var(--font-mobile-body-375);font-feature-settings:var(--feature-settings-mobile-body-375);letter-spacing:var(--letter-spacing-mobile-body-375)}@media only screen and (min-width: 64rem){.SetPasswordDialog-subtitle__YcNdi{font:var(--font-global-body-375);font-feature-settings:var(--feature-settings-global-body-375);letter-spacing:var(--letter-spacing-global-body-375)}}.SetPasswordDialog-subtitleHighlight___mPh3{color:var(--gray-a12);font:var(--font-mobile-body-475);font-feature-settings:var(--feature-settings-mobile-body-475);letter-spacing:var(--letter-spacing-mobile-body-475)}@media only screen and (min-width: 64rem){.SetPasswordDialog-subtitleHighlight___mPh3{font:var(--font-global-body-475);font-feature-settings:var(--feature-settings-global-body-475);letter-spacing:var(--letter-spacing-global-body-475)}}.SetPasswordDialog-passwordInputs__sElGC{display:flex;flex-direction:column;gap:var(--size-6);align-items:flex-start;align-self:stretch;padding:var(--size-2) var(--size-4)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subtitle": "SetPasswordDialog-subtitle__YcNdi",
	"subtitleHighlight": "SetPasswordDialog-subtitleHighlight___mPh3",
	"passwordInputs": "SetPasswordDialog-passwordInputs__sElGC"
};
export default ___CSS_LOADER_EXPORT___;
