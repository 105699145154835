import Spinner from "@legacy/core/components/Spinner";
import { Component } from "react";
import ButtonGroup from "../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../core/buttons/UniversalButton";
import TextField from "../../core/fields/TextField";


class VendorReactivateForm extends Component {

    componentDidMount() {
        // Return to the previous scroll
        document.querySelector(".main").scrollTo(0, this.props.returnScroll || 0)
    }

    renderButtons = () => {
        const {vendor, reactivateData, requestAction, switchToPrimaryForm, submitting, errors, onFormDataChange, returnScroll} = this.props

        if (errors.unexpectedError) {
            return (
                <div className="data-panel__action-feedback">
                    <span className="text-invalid"><strong>An unexpected error occurred.</strong></span>
                </div>
            )
        }
        else {
            if (submitting) {
                return <Spinner centered={true} />
            }
            else {
                return (
                    <ButtonGroup>
                        <ButtonGroupRow>
                            <UniversalButton type="primary" text="Reactivate" handler={event => requestAction("VENDOR_REACTIVATE_SUBMIT")} />
                            <UniversalButton type="secondary" text="Cancel" handler={event => switchToPrimaryForm()} />
                        </ButtonGroupRow>
                    </ButtonGroup>
                )
            }
        }
    }

    render() {
        const {vendor, reactivateData, requestAction, switchToPrimaryForm, submitting, errors, onFormDataChange, returnScroll} = this.props

        return (
            <div className="data-panel-container data-panel-container--with-margin">
                <div className="data-panel" aria-label="Vendor Reactivate">
                    <div className="data-panel__form" aria-label="Vendor Reactivate Form">
                        <p className="data-panel__form__caption">Please confirm that you'd like to reactivate this vendor.</p>
                        <TextField
                            fieldName="reactivate_message"
                            fieldLabel="Reactivation Reason"
                            fieldValue={reactivateData.reactivate_message || ""}
                            fieldOnChange={reactivate_message => onFormDataChange("reactivate_message", reactivate_message || "")}
                            rows={3}
                            placeholder={`Why is ${vendor.name} being reactivated?`}
                            errors={errors}
                        ></TextField>
                    </div>
                    {this.renderButtons()}
                </div>
            </div>
        )
    }
}

export default VendorReactivateForm
