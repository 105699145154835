export const LIST_SEARCH_PARAM_TYPE = "type"
export const LIST_SEARCH_PARAM_PHASE = "phase"
export const LIST_SEARCH_PARAM_ACTIVE_TAB = "tab"
export const LIST_SEARCH_PARAM_KEYWORD_SEARCH = "keywords"
export const LIST_SEARCH_PARAM_ORDERING = "ordering"
export const TIMELINE_VIEW_PARAM_CURRENT_DATE = "date"

export const PROFILE_BOTTOM_SHEET_OPEN = "bottom-sheet"

export const AUTH_NEXT_PARAM = "next"
