import { Root, Thumb } from "@radix-ui/react-switch"
import clsx from "clsx"
import { forwardRef, useEffect, useId } from "react"
import { useFormContext } from "react-hook-form"

import { FormControl, FormField, FormItem } from "@molecules/Form/Form"
import { InputName } from "@molecules/Form/inputs/Input/Input.types"

import { Label } from ".."
import styles from "./Toggle.module.scss"
import { ToggleProps } from "./Toggle.types"

function Toggle<T>(props: ToggleProps<T>, ref: React.Ref<HTMLButtonElement>) {
    const {
        name = "",
        id,
        size,
        label = undefined,
        labelPosition = "right",
        thumbProps,
        isDisabled = false,
        isRequired = false,
        ...rest
    } = props

    const defaultId = useId()

    return (
        <span
            className={clsx(styles.wrapper, {
                [styles.wrapperMd]: size === "md",
                [styles.wrapperLg]: size === "lg",
            })}
        >
            {label && labelPosition === "left" && <Label {...label} htmlFor={id || defaultId} size={size} />}
            <Root
                className={clsx(styles.base, {
                    [styles.baseMd]: size === "md",
                    [styles.baseLg]: size === "lg",
                })}
                disabled={isDisabled}
                required={isRequired}
                id={id || defaultId}
                {...rest}
                name={String(name)}
                ref={ref}
            >
                <Thumb
                    className={clsx(styles.thumb, {
                        [styles.thumbMd]: size === "md",
                        [styles.thumbLg]: size === "lg",
                    })}
                    {...thumbProps}
                />
            </Root>
            {label && labelPosition === "right" && <Label {...label} htmlFor={id || defaultId} size={size} />}
        </span>
    )
}

const withController = <T extends InputName>(ToggleComponent: React.FC<Omit<ToggleProps<T>, "isControlled">>) => {
    const Controller = forwardRef<HTMLButtonElement, ToggleProps<T>>((props, ref) => {
        const { isControlled, ...rest } = props
        const form = useFormContext()

        if (isControlled) {
            return (
                <FormField
                    control={form.control}
                    name={props.name as string}
                    render={({ field }) => {
                        useEffect(() => {
                            if (props.value !== undefined) {
                                form.setValue(props.name as string, props.value)
                            }
                        }, [props.value])

                        return (
                            <FormItem>
                                <FormControl>
                                    <ToggleComponent
                                        {...rest}
                                        {...field}
                                        checked={field.value as boolean}
                                        onCheckedChange={field.onChange}
                                        onBlur={field.onBlur}
                                        ref={ref}
                                    />
                                </FormControl>
                            </FormItem>
                        )
                    }}
                />
            )
        } else {
            return <ToggleComponent {...rest} ref={ref} />
        }
    })

    Controller.displayName = "ToggleComponentController"

    return Controller as typeof Toggle
}

export default withController(forwardRef(Toggle))
