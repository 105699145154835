import { useEffect, useRef } from "react"
import { createPortal } from "react-dom"

import useUser from "@hooks/useUser"

import replaceSlugs from "@utils/replaceSlugs"

import { HOME_ROUTE } from "@routes/home"
import { JOBS_ROUTES } from "@routes/jobs"
import { MENU_ROUTE } from "@routes/menu"
import { SEARCH_ROUTE } from "@routes/search"

import styles from "./BottomBar.module.scss"
import BottomBarCreateButton from "./BottomBarCreateButton/BottomBarCreateButton"
import BottomBarItem from "./BottomBarItem/BottomBarItem"

const body = document.querySelector("body")

export default function BottomBar() {
    const { user } = useUser()
    const bottomBarRef = useRef<HTMLDivElement>(null)

    const slug = { service_company_slug: user?.service_company.slug }

    const routes = {
        home: replaceSlugs(HOME_ROUTE, slug),
        jobs: replaceSlugs(JOBS_ROUTES.LIST, slug),
        menu: replaceSlugs(MENU_ROUTE, slug),
        search: replaceSlugs(SEARCH_ROUTE, slug),
    }

    useEffect(() => {
        if (bottomBarRef.current) {
            const bottomBarHeight = bottomBarRef.current.offsetHeight

            const root = document.documentElement
            root.style.setProperty("--bottom-bar-height", `${bottomBarHeight}px`)
        }

        return () => {
            const root = document.documentElement
            root.style.setProperty("--bottom-bar-height", `0`)
        }
    }, [bottomBarRef])

    return createPortal(
        <div ref={bottomBarRef} className={styles.base} role="navigation">
            <div className={styles.container}>
                <BottomBarItem title="Home" path={routes.home} module="home" />

                <BottomBarItem title="Jobs" path={routes.jobs} module="jobs" />

                {user?.isServiceDispatcher && <BottomBarCreateButton />}

                <BottomBarItem title="Search" path={routes.search} module="search" />
                <BottomBarItem title="Menu" path={routes.menu} module="menu" />
            </div>
        </div>,
        body,
    )
}
