import React, { Component } from 'react';
import { valueIsDefined } from '../utils/utils';


class QuantityField extends Component {

    render() {
        const {fieldName, fieldLabel, fieldValue, fieldOnChange, disabled=false, optional=false, optionalText="optional", extraClasses=null, errors} = this.props

        return (
            <div className={`data-panel__form__field ${extraClasses !== null ? extraClasses.join(" ") : ""}`} id={`div_id_${fieldName}`} aria-label={fieldLabel}>
                <label htmlFor={`id_${fieldName}`} className="data-panel__form__field__label in-form">{fieldLabel}{optional && <span className="text-optional"> ({optionalText})</span>}</label>
                <div className="data-panel__form__field__input" title={disabled && fieldValue ? `${fieldValue}` : null}>
                    <input
                        defaultValue={valueIsDefined(fieldValue) ? parseFloat(fieldValue) : null}
                        onChange={event => fieldOnChange(event.target.value)}
                        type="number"
                        min="0"
                        max="9999"
                        step="0.5"
                        id={`id_${fieldName}`}
                        name={fieldName}
                        autoComplete="off"
                        className={disabled ? "is-disabled" : ""}
                        disabled={disabled}
                    />
                </div>
                {errors[fieldName] && <div className="data-panel__form__field__errors" aria-label="Field Errors">{errors[fieldName]}</div>}
            </div>
        )
    }
}

export default QuantityField;
