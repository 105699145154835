// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TopBarVerticalDivider-base__M2uDY{flex-shrink:0;height:var(--size-9);color:var(--gray-a3)}@media only screen and (min-width: 64rem){.TopBarVerticalDivider-base__M2uDY{height:var(--size-8)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "TopBarVerticalDivider-base__M2uDY"
};
export default ___CSS_LOADER_EXPORT___;
