import { Indicator, Item } from "@radix-ui/react-radio-group"
import clsx from "clsx"
import { useId } from "react"

import { Label } from ".."
import styles from "./RadioItem.module.scss"
import { RadioItemProps } from "./RadioItem.types"

export default function RadioItem(props: RadioItemProps) {
    const { id, size, label, labelPosition, value, isDisabled, ...rest } = props
    const defaultId = useId()

    return (
        <span className={styles.itemWrapper}>
            {labelPosition === "left" && label && (
                <Label size={size} htmlFor={id || defaultId} variant={isDisabled ? "disabled" : "default"}>
                    {label || value}
                </Label>
            )}
            <Item
                className={clsx(styles.item, {
                    [styles.itemSm]: size === "sm",
                    [styles.itemMd]: size === "md",
                })}
                value={value}
                disabled={isDisabled}
                {...rest}
                id={id || defaultId}
            >
                <Indicator
                    className={clsx(styles.indicator, {
                        [styles.indicatorSm]: size === "sm",
                        [styles.indicatorMd]: size === "md",
                    })}
                />
            </Item>
            {labelPosition === "right" && label && (
                <Label size={size} htmlFor={id || defaultId} variant={isDisabled ? "disabled" : "default"}>
                    {label || value}
                </Label>
            )}
        </span>
    )
}
