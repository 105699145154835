import debounce from "debounce-promise";
import { Component } from "react";
import deepcopy from "rfdc";
import { sendDataToServer, valueIsDefined } from "../core/utils/utils";
import InlineEquipmentDisplay from "./components/InlineEquipmentDisplay";
import InlineEquipmentForm from "./forms/InlineEquipmentForm";
import { validateEquipment } from "./utils/utils";

const PAGE_MODES = {
    VIEW_EQUIPMENT: "VIEW_EQUIPMENT",
    EDIT_EQUIPMENT: "EDIT_EQUIPMENT",
}

const FORM_DATA_NAMES_BY_MODE = {
    EDIT_EQUIPMENT: "equipmentFormData",
}


class InlineEquipmentContainer extends Component {

    // Initialize

    constructor(props) {
        super(props)

        let defaultMode = this.props.formMode || PAGE_MODES.VIEW_EQUIPMENT
        this.addToastToQueue = this.props.addToastToQueue

        const equipment = deepcopy()(this.props.equipment)
        this.duplicateEquipmentCache = {}

        this.state = {
            equipmentData: equipment,
            equipmentFormData: deepcopy()(equipment),

            equipmentCategoryOptions: window.EQUIPMENT_CATEGORIES || [],
            equipmentTypeOptions: window.EQUIPMENT_TYPES || [],
            ownershipTypeOptions: window.OWNERSHIP_TYPES || [],

            duplicateEquipmentData: [],

            errors: {
                equipment: {},
            },

            mode: defaultMode,
        }
    }

    // Form helpers

    updateFormData = (formName, fieldName, fieldValue) => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState[formName][fieldName] = fieldValue
            return updatedState
        }, this.fetchDuplicateEquipment)
    }

    switchFormMode = (mode) => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState.mode = mode

            if (mode === PAGE_MODES.EDIT_EQUIPMENT) {
                // Auto-set if there's only one option; the associated field is disabled if there's only one as well
                const valueOptions = state.equipmentCategoryOptions.filter(option => option.value !== "")
                if (valueOptions.length == 1) {
                    updatedState[FORM_DATA_NAMES_BY_MODE[mode]].equipment_category = valueOptions[0].value
                }
            }

            return updatedState
        }, this.fetchDuplicateEquipment)
    }

    switchToDisplay = () => {
        this.setState((state, props) => {
            let updatedState = state

            // Reset primary form data
            updatedState[FORM_DATA_NAMES_BY_MODE[state.mode]] = deepcopy()(this.props.equipment)
            return updatedState
        })
        this.switchFormMode(PAGE_MODES.VIEW_EQUIPMENT)
    }

    switchToPrimaryForm = () => {
        this.switchFormMode(PAGE_MODES.EDIT_EQUIPMENT)
    }

    fetchDuplicateEquipment = debounce(
        async () => {
            this.setState((state, props) => {
                let updatedState = state
                updatedState.duplicateEquipmentData = []
                return updatedState
            })

            if (valueIsDefined(this.state.equipmentFormData.manufacturer) && valueIsDefined(this.state.equipmentFormData.model_number) && valueIsDefined(this.state.equipmentFormData.serial_number)) {
                const params = new URLSearchParams({
                    "manufacturer": this.state.equipmentFormData.manufacturer,
                    "model_number": this.state.equipmentFormData.model_number,
                    "serial_number": this.state.equipmentFormData.serial_number,
                })

                const endpoint = DjangoUrls["equipment:api-equipment-duplicates-list"](window.MARKETPLACE_ENTITY_SLUG, this.props.clientID) + `?${params.toString()}`
                let duplicateEquipment

                if (params.toString() in this.duplicateEquipmentCache) {
                    duplicateEquipment = this.duplicateEquipmentCache[params.toString()]
                }
                else {
                    const duplicateEquipmentResponse = await fetch(endpoint)
                    duplicateEquipment = await duplicateEquipmentResponse.json()
                    this.duplicateEquipmentCache[params.toString()] = duplicateEquipment
                }

                this.setState((state, props) => {
                    let updatedState = state
                    updatedState.duplicateEquipmentData = duplicateEquipment.filter(equipment => equipment.id !== this.state.equipmentFormData.id)
                    return updatedState
                })
            }
        }, 500
    )

    // Crud equipment

    updateEquipment = async () => {
        const endpoint = DjangoUrls["equipment:api-service-location-equipment-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.equipmentData.service_location, this.state.equipmentData.id)
        const endpointMethod = "PUT"
        const dataName = "equipmentFormData"
        const submittingName = "submittingEquipment"
        const errorDictName = "equipment"

        const onSuccess = (equipment) => {
            this.setState((state, props) => {
                let updatedState = state

                delete state.equipmentFormData.errors
                updatedState.equipmentData = equipment

                updatedState[submittingName] = false

                return updatedState
            })
            this.switchFormMode(PAGE_MODES.VIEW_EQUIPMENT)
            this.props.updateParentData(equipment)
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Equipment "${equipment.display_name}" updated`,
            })
        }

        const dataManipulator = (data, state) => {
            data.equipment_category = data.equipment_category || null
            data.equipment_type = data.equipment_type || null

            if (data.ownership_type === "") {
                delete data.ownership_type
            }

            return data
        }

        const setErrors = (fieldName, message, errorDict) => {
            if (fieldName === "non_field_errors" && message === "The fields service_location, display_name must make a unique set.") {
                errorDict["display_name"] = "A piece of equipment with this name at this service location already exists."
            }
            else if (fieldName === "non_field_errors" && message === "There are other pieces of equipment that belong to this Client with the same manufacturer, model number, and serial number.") {
                errorDict["identifying_info"] = "Please ensure this equipment's manufacturer, model number, and serial number are unique for this Client."
            }
            else if (fieldName === "display_name" && message === "This field may not be blank.") {
                errorDict["display_name"] = "Please provide a display name."
            }
            else if (fieldName === "warranties") {
                errorDict["warranties"] = "Please correct the warranty errors below:"

                // Apply the nested errors
                this.setState((state, props) => {
                    let updatedState = state
                    message.map((warrantyError, index) => updatedState[FORM_DATA_NAMES_BY_MODE[state.mode]].warranties[index].errors = warrantyError)
                    return updatedState
                })
            }
            else {
                errorDict[fieldName] = message
            }
        }

        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Equipment could not be updated",
            })
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, dataManipulator, setErrors)
    }

    // Handle Actions

    handleActionRequest = (action) => {
        switch (action) {
            case "EQUIPMENT_EDIT":
                this.switchToPrimaryForm()
                break
            case "EQUIPMENT_SAVE":
                const { isValid, errors } = validateEquipment(this.state.equipmentFormData, this.props.index)

                if (isValid) {
                    this.updateEquipment()
                }
                else {
                    this.setState((state, props) => {
                        let updatedState = state
                        updatedState.errors.equipment = errors

                        // Scroll to first error
                        const fieldName = Object.keys(updatedState.errors.equipment)[0]
                        const elementSelector = `#equipment_${this.state.equipmentData.id} #div_id_${fieldName}`
                        const element = document.querySelector(elementSelector)

                        if (element) {
                            element.scrollIntoView()
                        }
                        else {
                            console.error(`Couldn't find field for error scrolling: ${elementSelector}`)
                        }

                        return updatedState
                    }, () => {
                        document.querySelector('[aria-label="Field Errors"]')?.scrollIntoView()}
                    )

                }
                break
            case "EQUIPMENT_CANCEL_EDITS":
                this.switchToDisplay()
                break
            default:
                console.error(`No action handler exists for action "${action}".`)
        }
    }

    // Render

    render = () => {
        const { editable, showJobHistory, showNextButton, nextButtonUrl } = this.props

        if (this.state.mode === PAGE_MODES.VIEW_EQUIPMENT) {
            return <InlineEquipmentDisplay
                equipment={this.state.equipmentData}
                editable={editable}
                showJobHistory={showJobHistory}
                showNextButton={showNextButton}
                nextButtonUrl={nextButtonUrl}
                requestAction={this.handleActionRequest}
            />
        }
        else if (this.state.mode === PAGE_MODES.EDIT_EQUIPMENT) {
            return <InlineEquipmentForm
                submitting={this.state.submittingEquipment}
                equipment={this.state.equipmentFormData}
                errors={this.state.errors.equipment}
                onFormDataChange={(fieldName, fieldValue) => this.updateFormData(FORM_DATA_NAMES_BY_MODE[this.state.mode], fieldName, fieldValue)}
                requestAction={this.handleActionRequest}
                equipmentCategoryOptions={this.state.equipmentCategoryOptions}
                equipmentTypeOptions={this.state.equipmentTypeOptions}
                ownershipTypeOptions={this.state.ownershipTypeOptions}
                duplicateEquipment={this.state.duplicateEquipmentData}
            />
        }
        else {
            return (
                <div className="data-panel__form">
                    <p className="data-panel__form__caption">An unhandled form mode was supplied.</p>
                </div>
            )
        }
    }
}

export default InlineEquipmentContainer;
