import { useEffect } from "react"

import useUser from "@hooks/useUser"

import { Toggle } from "@atoms/index"

import GenericForm from "@molecules/Form/GenericForm/GenericForm"
import toast from "@molecules/Toast/Toast"
import { Property } from "@molecules/index"

import EmailForm from "@pages/Settings/SettingsProfile/components/UserContactsForms/EmailForm/EmailForm"
import PhoneForm from "@pages/Settings/SettingsProfile/components/UserContactsForms/PhoneForm/PhoneForm"

import { NOTIFICATION_ENDPOINTS } from "@endpoints/notification"

import styles from "./NotificationMethodsSection.module.scss"
import { useNotificationMethodsQuery } from "./NotificationMethodsSection.store"
import { NotificationMethodsList } from "./NotificationMethodsSection.types"

export default function NotificationMethodsSection() {
    const { user } = useUser()

    const {
        data: defaultMethodsPreference,
        isLoading,
        isError: isErrorOnGettingDefaultData,
    } = useNotificationMethodsQuery()

    useEffect(() => {
        if (isErrorOnGettingDefaultData) {
            toast({
                type: "error",
                size: "md",
                title: "Could not retrieve notification method preference",
            })
        }
    }, [isErrorOnGettingDefaultData])

    const hasVerifiedPhone = user?.phone && user?.phone_verified
    const hasVerifiedEmail = user?.email_verified

    return (
        <div className={styles.base}>
            {!hasVerifiedEmail && (
                <Property.Root wrapOnMobile={true}>
                    <Property.Content>
                        <Property.Label>Email</Property.Label>
                        <Property.Subtitle>
                            Verify your email to receive notifications for any selected topics.
                        </Property.Subtitle>
                    </Property.Content>
                    <Property.Value>
                        <div className={styles.notificationMethod}>
                            <EmailForm showLabel={false} />
                        </div>
                    </Property.Value>
                </Property.Root>
            )}
            <GenericForm<NotificationMethodsList, NotificationMethodsList, Error, NotificationMethodsList>
                formConfig={{
                    defaultValues: null,
                }}
                mutationConfig={{
                    method: "PATCH",
                    endpoint: NOTIFICATION_ENDPOINTS.UPDATE_NOTIFICATION_METHODS,
                    genericErrorMessage: "Could not update notification method",
                }}
                isAutoSave={true}
                formValues={defaultMethodsPreference}
            >
                {() => (
                    <div className={styles.wrapper}>
                        {hasVerifiedEmail && (
                            <Property.Root>
                                <Property.Content>
                                    <Property.Label>Email</Property.Label>
                                    <Property.Subtitle>
                                        Receive notification via email for any selected topics.
                                    </Property.Subtitle>
                                </Property.Content>
                                <Property.Value>
                                    <Toggle<NotificationMethodsList>
                                        size="md"
                                        isDisabled={isLoading}
                                        name="contact_email"
                                        isControlled={true}
                                    />
                                </Property.Value>
                            </Property.Root>
                        )}
                        {hasVerifiedPhone && (
                            <Property.Root>
                                <Property.Content>
                                    <Property.Label>Text message</Property.Label>
                                    <Property.Subtitle>
                                        Receive notification via text for any selected topics.
                                    </Property.Subtitle>
                                </Property.Content>
                                <Property.Value>
                                    <Toggle<NotificationMethodsList>
                                        size="md"
                                        isDisabled={isLoading}
                                        name="contact_sms"
                                        isControlled={true}
                                    />
                                </Property.Value>
                            </Property.Root>
                        )}
                    </div>
                )}
            </GenericForm>
            {!hasVerifiedPhone && (
                <Property.Root wrapOnMobile={true}>
                    <Property.Content>
                        <Property.Label>Text message</Property.Label>
                        <Property.Subtitle>Set a phone number to turn on this notification method.</Property.Subtitle>
                    </Property.Content>
                    <Property.Value>
                        <div className={styles.notificationMethod}>
                            <PhoneForm showLabel={false} />
                        </div>
                    </Property.Value>
                </Property.Root>
            )}
        </div>
    )
}
