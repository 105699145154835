import { useMemo, useState } from "react"
import * as Yup from "yup"

import useIsDesktop from "@hooks/useIsDesktop"
import useUser from "@hooks/useUser"

import GenericForm from "@molecules/Form/GenericForm/GenericForm"
import { MutationSuccessFunctionParams } from "@molecules/Form/GenericForm/GenericForm.types"
import Input from "@molecules/Form/inputs/Input/Input"

import { USER_ENDPOINT } from "@endpoints/user"

import { INPUT_DEBOUNCE_SUBMIT_TIME_MS } from "@constants/input"

import { UserLastName } from "./LastNameForm.types"

const formSchema: Yup.ObjectSchema<UserLastName> = Yup.object().shape({
    last_name: Yup.string().required("Please enter a last name"),
})

export default function LastNameForm() {
    const [isInitialValueSet, setIsInitialValueSet] = useState<boolean>(false)

    const isDesktop = useIsDesktop()

    const { user, updateUser } = useUser()

    const onMutationSuccess = (args: MutationSuccessFunctionParams<UserLastName, UserLastName>) => {
        updateUser({ last_name: args.formValues.last_name })
    }

    const onAfterFormValuesSet = () => {
        setIsInitialValueSet(true)
    }

    const formValues = useMemo(() => {
        if (isInitialValueSet) {
            return undefined
        } else if (user?.last_name) {
            return {
                last_name: user?.last_name,
            }
        }
    }, [user?.last_name, isInitialValueSet])

    return (
        <GenericForm<UserLastName, User, Error, UserLastName>
            formConfig={{
                mode: "all",
                reValidateMode: "onChange",
                defaultValues: {
                    last_name: "",
                },
            }}
            formValidationSchema={formSchema}
            mutationConfig={{
                endpoint: USER_ENDPOINT,
                method: "PATCH",
                genericErrorMessage: "Could not update last name",
                genericSuccessMessage: "Last name updated",
            }}
            isAutoSave={true}
            autoSaveDebounceTimeMs={INPUT_DEBOUNCE_SUBMIT_TIME_MS}
            formValues={formValues}
            onAfterFormValuesSet={onAfterFormValuesSet}
            onMutationSuccess={onMutationSuccess}
            noFormTag={true}
        >
            {() => (
                <Input<UserLastName>
                    label="Last name"
                    size={isDesktop ? "md" : "lg"}
                    placeholder="Last name"
                    aria-label="Last name"
                    name="last_name"
                />
            )}
        </GenericForm>
    )
}
