import { Model } from "@bryntum/schedulerpro"

import useJobTimelineViewBryntumInstances from "./useJobTimelineViewBryntumInstances"

export default function useJobTimelineViewSchedulerRefresh() {
    const { schedulerPro } = useJobTimelineViewBryntumInstances()

    const startRefreshRowsInterval = () => {
        const ONE_MINUTE = 60000

        const interval = setInterval(() => {
            schedulerPro.current.instance.refreshRows()
        }, ONE_MINUTE)

        // Remember to clear interval when component is unmounted
        return interval
    }

    const forceEventToRerender = (eventStore: Model) => {
        eventStore.set({
            forceRerender: Math.random(),
        })
    }

    return {
        startRefreshRowsInterval,
        forceEventToRerender,
    }
}
