// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IDCell-base__qD3lv{display:flex;align-items:center;width:var(--size-full);padding:var(--size-3_5) var(--size-4);color:var(--gray-11);font:var(--font-global-body-400-tabular);font-feature-settings:var(--feature-settings-global-body-400-tabular);letter-spacing:var(--letter-spacing-global-body-400-tabular)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "IDCell-base__qD3lv"
};
export default ___CSS_LOADER_EXPORT___;
