import { useContext } from "react"

import { PopoverContext } from "./Popover"
import { PopoverContextValues } from "./Popover.types"

export default function usePopover(): PopoverContextValues {
    const context = useContext(PopoverContext)
    if (!context) {
        throw new Error("usePopover must be used within a Popover component")
    }

    return context
}
