import SettingsCompanyCamConfigPanel from "@legacy/core/components/SettingsCompanyCamConfigPanel"

import useToastQueue from "@hooks/useToastQueue"

import { Settings } from "@templates/index"

export default function SettingsCompanyCam() {
    const { addToastToQueue } = useToastQueue()

    return (
        <Settings.Wrapper title="CompanyCam">
            <SettingsCompanyCamConfigPanel addToastToQueue={addToastToQueue} />
        </Settings.Wrapper>
    )
}
