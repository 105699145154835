import { BryntumGrid } from "@bryntum/schedulerpro-react"
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import { useEffect } from "react"
import { createPortal } from "react-dom"

import useUser from "@hooks/useUser"

import { Badge } from "@atoms"

import { MobileHeader } from "@molecules/index"

import BottomSheet from "@organisms/BottomSheet/BottomSheet"

import styles from "./JobTimelineView.module.scss"
import { gridConfig } from "./JobTimelineViewConfig/JobTimelineViewConfig"
import UnscheduledJobsBottomSheetTrigger from "./UnscheduledJobsBottomSheetTrigger/UnscheduledJobsBottomSheetTrigger"
import UnscheduledJobsColumnMessage from "./UnscheduledJobsColumnMessage/UnscheduledJobsColumnMessage"
import useJobTimelineViewBryntumInstances from "./hooks/useJobTimelineViewBryntumInstances"
import useJobTimelineViewData from "./hooks/useJobTimelineViewData"
import useJobTimelineViewGridProps from "./hooks/useJobTimelineViewGridProps"
import useJobTimelineViewStates from "./hooks/useJobTimelineViewStates"
import useTimelineViewUnscheduledJobsUtils from "./hooks/useTimelineViewUnscheduledJobsUtils"

const body = document.querySelector("body")

export default function JobTimelineViewMobileUnscheduledJobs() {
    const {
        unscheduledJobsColumnStatus,
        unscheduledJobsColumnMessageStatus,
        totalOfUnscheduledJobs,
        showUnscheduledJobsColumnMessage,
        hideUnscheduledJobsColumnMessage,
        collapseUnscheduledJobsColumn,
        expandUnscheduledJobsColumn,
    } = useJobTimelineViewStates()

    const { manageUnscheduledJobsProcess, initializeUnscheduledJobsGrid } = useTimelineViewUnscheduledJobsUtils()

    const { mobileProps } = useJobTimelineViewGridProps()

    const { user } = useUser()

    const { unscheduledJobsGrid, schedulerPro } = useJobTimelineViewBryntumInstances()

    const { unscheduledJobsData } = useJobTimelineViewData()

    useEffect(() => {
        const hasUnscheduledJobsData = unscheduledJobsData?.pages?.length > 0
        if (hasUnscheduledJobsData) {
            manageUnscheduledJobsProcess()
        }
    }, [unscheduledJobsData?.pages])

    useEffect(() => {
        // Initializes the drag and drop functionality for the Grid -> Scheduler.
        const scheduler = schedulerPro.current?.instance

        if (scheduler) {
            initializeUnscheduledJobsGrid()
        }
    }, [schedulerPro.current?.instance])

    return (
        <>
            {user.isServiceDispatcher
                ? createPortal(
                      <UnscheduledJobsBottomSheetTrigger
                          count={totalOfUnscheduledJobs}
                          onClick={expandUnscheduledJobsColumn}
                      />,
                      body,
                  )
                : null}
            <BottomSheet.Root
                isOpen={unscheduledJobsColumnStatus === "expanded" && user.isServiceDispatcher}
                onClose={collapseUnscheduledJobsColumn}
                className="unscheduled-jobs-grid__bottom-sheet"
                isFullHeight={true}
            >
                {unscheduledJobsColumnMessageStatus === "initial" && (
                    <div className={styles.unscheduledJobsMessage}>
                        <UnscheduledJobsColumnMessage
                            showButton={true}
                            onButtonClick={hideUnscheduledJobsColumnMessage}
                        />
                    </div>
                )}

                <MobileHeader
                    title="Unscheduled Jobs"
                    showRightButton={
                        unscheduledJobsColumnMessageStatus === "hidden" ||
                        unscheduledJobsColumnMessageStatus === "shown"
                    }
                    rightButtonIcon={icon({
                        name: "question-circle",
                        style: "light",
                        family: "sharp",
                    })}
                    rightButtonAriaLabel="Unscheduled Jobs Help"
                    rightButtonIconWhenPressed={icon({
                        name: "question-circle",
                        style: "solid",
                        family: "sharp",
                    })}
                    onRightButtonClick={showUnscheduledJobsColumnMessage}
                    isRightButtonPressed={unscheduledJobsColumnMessageStatus === "shown"}
                    counter={
                        <Badge
                            colorScheme={totalOfUnscheduledJobs === 0 ? "gray" : "tomato"}
                            size="lg"
                            type="counter"
                            variant="subtle"
                        >
                            {totalOfUnscheduledJobs}
                        </Badge>
                    }
                />
                <BryntumGrid {...gridConfig} ref={unscheduledJobsGrid} {...mobileProps} />
            </BottomSheet.Root>

            <BottomSheet.Root
                isOpen={unscheduledJobsColumnStatus === "expanded" && unscheduledJobsColumnMessageStatus === "shown"}
                onClose={hideUnscheduledJobsColumnMessage}
            >
                <BottomSheet.DragIndicator />
                <UnscheduledJobsColumnMessage showBorderTop={false} />
            </BottomSheet.Root>
        </>
    )
}
