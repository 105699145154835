import clsx from "clsx"
import { useEffect, useRef } from "react"

import debounce from "@utils/debounce"

import { MobileHeader } from "@molecules/index"

import useBottomSheet from "@organisms/BottomSheet/BottomSheet.store"

import styles from "./BottomSheetContent.module.scss"
import { BottomSheetContentProps } from "./BottomSheetContent.types"

export default function BottomSheetContent(props: BottomSheetContentProps) {
    const { priority, children, isVisible, className, header, itemsClassName } = props

    const baseRef = useRef<HTMLDivElement>(null)
    const itemsRef = useRef<HTMLDivElement>(null)

    const { setContentHeight, setIsContentOverflowing } = useBottomSheet()

    useEffect(() => {
        const element = baseRef?.current

        const RESIZE_CALLBACK_DEBOUNCE_TIME = 100

        const onResize = debounce(() => {
            const viewportIsSmallerThanContent = window.innerHeight < element?.scrollHeight

            const newHeight = viewportIsSmallerThanContent ? window.innerHeight : element?.scrollHeight
            if (element) {
                element.style.maxHeight = newHeight + "px"

                const isOverflowing = element.scrollHeight > element.clientHeight

                if (isOverflowing) {
                    itemsRef.current.dataset.noDrag = "true"
                } else {
                    delete itemsRef.current?.dataset.noDrag
                }

                setIsContentOverflowing(isOverflowing)
            }
            setContentHeight(newHeight)
        }, RESIZE_CALLBACK_DEBOUNCE_TIME)

        const onScroll = () => {
            const isOverflowing = element.scrollHeight > element.clientHeight

            const baseRefIsOnScrollTop = element.scrollTop === 0

            if (isOverflowing && baseRefIsOnScrollTop) {
                delete itemsRef.current.dataset.noDrag
            } else if (isOverflowing) {
                itemsRef.current.dataset.noDrag = "true"
            }
        }

        const resizeObserver = new ResizeObserver(onResize)

        if (element && isVisible) {
            window.addEventListener("resize", onResize)
            element?.addEventListener("scroll", onScroll)
            resizeObserver.observe(element)
        } else if (element) {
            element?.removeEventListener("scroll", onScroll)
            window.removeEventListener("resize", onResize)
            resizeObserver.unobserve(element)
        }

        return () => {
            if (element) {
                element?.removeEventListener("scroll", onScroll)
                window.removeEventListener("resize", onResize)
                resizeObserver.unobserve(element)
            }
        }
    }, [baseRef.current, isVisible])

    return (
        <div
            ref={baseRef}
            className={clsx(styles.base, className, styles[priority], {
                [styles.visible]: isVisible,
            })}
        >
            {header && (
                <MobileHeader
                    title={header.title}
                    onBackClick={header.onBackClick}
                    showBackButton={priority == "secondary" && isVisible}
                    backButtonClassName={styles.backButton}
                    closeButtonClassName={styles.closeButton}
                    titleClassName={styles.title}
                    className={styles.header}
                    showDivider={header.showDivider}
                    isTabbable={isVisible}
                    showDragIndicator={true}
                    {...header}
                />
            )}
            <div className={clsx(styles.items, itemsClassName || styles.defaultItems)} ref={itemsRef}>
                {/* Dynamically renders the content of the link passing down a boolean that indicates if the link should be active. */}
                {children(isVisible)}
            </div>
        </div>
    )
}
