import useUser from '@hooks/useUser';
import ObjectListCard from '../../core/components/ObjectListCard';
import AlertingRenderer from "../../core/renderers/AlertingRenderer";
import DateTimeRenderer from "../../core/renderers/DateTimeRenderer";
import DownPaymentRenderer from '../../core/renderers/DownPaymentRenderer';
import LastSeenRenderer from "../../core/renderers/LastSeenRenderer";
import StatusChipRenderer from '../../core/renderers/StatusChipRenderer';
import { formatCurrency } from "../../core/utils/utils";

function EstimateListCard(props) {
    const { data, showState } = props
    const estimate = data
    const { user:currentUser } = useUser()

    const footerContext = {
        label: null,
        value: null
    }

    if (["Won", "Approved"].includes(estimate.state_label)) {
        footerContext.label = "Down Payment:"
        footerContext.value = <DownPaymentRenderer value={estimate.down_payment_status} alertCondition={true} />
    }
    else {
        footerContext.label = "Expires On:"
        footerContext.value = (
            <AlertingRenderer alertCondition={estimate.is_expired}>
                <DateTimeRenderer type="date" value={estimate.date_due} options={{short: true, addYear: true}} />
            </AlertingRenderer>
        )
    }

    return <ObjectListCard
        objectName="Estimate"
        objectID={estimate.custom_id || estimate.id}
        showState={showState}
        stateLabel={estimate.state_label}
        headerContext={<LastSeenRenderer value={estimate.last_viewed} />}
        statusChip={<StatusChipRenderer value={estimate.status_label} />}
        bodyTitle={estimate.client_name}
        bodySubtextPrimary={estimate.service_name}
        bodySubtextSecondary={estimate.service_location_address}
        footerContextLabel={footerContext.label}
        footerContextValue={footerContext.value}
        footerPrimary={formatCurrency(estimate.total, currentUser.service_company.currency_code, currentUser.service_company.language_code)}
    />
}

export default EstimateListCard;
