// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PageContainer-base__Vk03g{width:var(--size-full);max-width:var(--page-container-max-width);margin:0 auto;padding:var(--size-8) var(--size-5)}@media only screen and (min-width: 64rem){.PageContainer-base__Vk03g{padding:0 0 var(--size-16) 0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "PageContainer-base__Vk03g"
};
export default ___CSS_LOADER_EXPORT___;
