import clsx from "clsx"
import { useEffect, useRef } from "react"

import useAuth from "@pages/Auth/Auth.store"

import styles from "./AuthSlide.module.scss"
import { AuthSlideProps } from "./AuthSlide.types"

export default function AuthSlide(props: AuthSlideProps) {
    const { children, animateFrom, isActive } = props

    const { setContentHeight } = useAuth()

    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const element = ref?.current

        const onResize = () => {
            if (element && isActive) {
                setContentHeight(element?.scrollHeight)
            }
        }

        const resizeObserver = new ResizeObserver(onResize)

        if (element && isActive) {
            window.addEventListener("resize", onResize)
            resizeObserver.observe(element)
        } else if (element) {
            window.removeEventListener("resize", onResize)
            resizeObserver.unobserve(element)
        }

        return () => {
            if (element) {
                window.removeEventListener("resize", onResize)
                resizeObserver.unobserve(element)
            }
        }
    }, [isActive])

    return (
        <div
            ref={ref}
            className={clsx(styles.base, {
                [styles.animateFromLeft]: animateFrom === "left",
                [styles.animateFromRight]: animateFrom === "right",

                [styles.active]: isActive,
            })}
        >
            {isActive && children}
        </div>
    )
}
