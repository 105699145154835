import { useMutation } from "@tanstack/react-query"
import Cookies from "js-cookie"
import { createContext, useEffect, useState } from "react"

import useLocalStorage from "@hooks/useLocalStorage"
import useUser from "@hooks/useUser"

import { GenericError } from "@molecules/Form/GenericForm/GenericForm.types"
import toast from "@molecules/Toast/Toast"

import { ACCOUNT_VERIFICATION_TEMPORARY_CODE_ID_HOLD } from "@constants/storage"

import OTPAlertBanner from "./OTPAlertBanner/OTPAlertBanner"
import {
    CodeRequestError,
    CodeRequestPayload,
    CodeRequestResponse,
    CodeVerificationError,
    CodeVerificationPayload,
    CodeVerificationResponse,
    OTPFormContextValues,
    OTPFormProps,
} from "./OTPForm.types"
import OTPPasteButton from "./OTPPasteButton/OTPPasteButton"
import OTPSendCodeButton from "./OTPSendCodeButton/OTPSendCodeButton"
import OTPVerifyCodeButton from "./OTPVerifyCodeButton/OTPVerifyCodeButton"

export const OTPFormContext = createContext(null as OTPFormContextValues)
OTPFormContext.displayName = "OTPFormContext"

function OTPForm(props: OTPFormProps) {
    const {
        codeRequestEndpoint,
        codeVerificationEndpoint,
        children,
        triggerOTPReset,
        onCodeVerificationSuccessful,
        triggerSendCode,
        checkFor,
        email,
        emailId,
        phone,
        phoneId,
        hasRequestedCode,
    } = props

    const { user } = useUser()

    const [otpInputValue, setOtpInputValue] = useState<string>("")
    const [isCodeInvalid, setIsCodeInvalid] = useState<boolean>(false)
    const [isCodeResendBlocked, setIsCodeResendBlocked] = useState<boolean>(false)
    const [isShowingErrorMessage, setIsShowingErrorMessage] = useState<boolean>(false)

    useEffect(() => {
        if (triggerOTPReset) {
            setOtpInputValue("")
        }
    }, [triggerOTPReset])

    const {
        isPending: isRequestingCode,
        error: codeRequestError,
        isSuccess: isCodeRequestSuccessful,
        data: codeRequestData,
        isError: isErrorOnCodeSend,
        mutate: requestCode,
    } = useMutation<CodeRequestResponse, GenericError<CodeRequestError>, CodeRequestPayload>({
        mutationFn: async (mutationPayload: CodeRequestPayload): Promise<CodeRequestResponse> => {
            const response = await fetch(codeRequestEndpoint, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "X-CSRFToken": Cookies.get("csrftoken"),
                },
                body: JSON.stringify(mutationPayload),
            })

            if (!response.ok) {
                await response.json().then((error: CodeRequestError) => {
                    throw new GenericError("Could not request code.", error)
                })
            }

            return (await response?.json()) as Promise<CodeRequestResponse>
        },
    })

    const {
        isPending: isVerifyingCode,
        error: codeVerificationError,
        isSuccess: isCodeVerificationSuccessful,
        isError: isErrorOnCodeVerification,
        mutate: verifyCode,
    } = useMutation<CodeVerificationResponse, GenericError<CodeVerificationError>, CodeVerificationPayload>({
        mutationFn: async (mutationPayload: CodeVerificationPayload): Promise<CodeVerificationResponse> => {
            const response = await fetch(codeVerificationEndpoint, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "X-CSRFToken": Cookies.get("csrftoken"),
                },
                body: JSON.stringify(mutationPayload),
            })

            if (!response.ok) {
                await response.json().then((error: CodeVerificationError) => {
                    throw new GenericError("Could not verify code.", error)
                })
            }

            return (await response?.json()) as Promise<CodeVerificationResponse>
        },
    })

    const codeVerificationOverride = isCodeVerificationSuccessful
        ? null
        : codeRequestData?.email_id || codeRequestData?.phone_id || emailId || phoneId

    const [codeVerificationId, setCodeVerificationId] = useLocalStorage(
        ACCOUNT_VERIFICATION_TEMPORARY_CODE_ID_HOLD,
        null,
        codeVerificationOverride,
    )

    const noCodeVerificationId = codeVerificationId === null || !codeVerificationId

    useEffect(() => {
        if (isCodeVerificationSuccessful) {
            setCodeVerificationId(null)
            onCodeVerificationSuccessful()
        }
    }, [isCodeVerificationSuccessful])

    useEffect(() => {
        if (isCodeRequestSuccessful || hasRequestedCode) {
            setIsCodeResendBlocked(true)

            toast({
                size: "md",
                type: "success",
                title: "Code sent",
            })
        }
    }, [isCodeRequestSuccessful, hasRequestedCode])

    useEffect(() => {
        if (isErrorOnCodeSend && noCodeVerificationId) {
            toast({
                size: "md",
                type: "error",
                title: "Could not send code",
            })
        }
    }, [isErrorOnCodeSend])

    useEffect(() => {
        if (triggerSendCode) {
            handleSendCode()
        }
    }, [triggerSendCode])

    useEffect(() => {
        if (isErrorOnCodeVerification) {
            setIsCodeInvalid(true)
        }
    }, [isErrorOnCodeVerification])

    useEffect(() => {
        if (isCodeInvalid) {
            const ONE_AND_HALF_SECOND_MS = 1500
            setTimeout(() => {
                setOtpInputValue("")
                setIsCodeInvalid(false)
            }, ONE_AND_HALF_SECOND_MS)
        }
    }, [isCodeInvalid])

    useEffect(() => {
        if (
            codeVerificationError?.error?.code ||
            codeRequestError?.error?.email_address ||
            codeRequestError?.error?.phone_number
        ) {
            setIsShowingErrorMessage(true)
        }
    }, [codeVerificationError, codeRequestError])

    const handleSendCode = () => {
        if (checkFor === "email") {
            requestCode({
                email_address: email || user.email,
            })
        } else if (checkFor === "phone") {
            requestCode({
                phone_number: phone || user.phone,
            })
        }
    }

    const handleCodeVerification = () => {
        if (checkFor === "email") {
            verifyCode({
                code: String(otpInputValue),
                email_id: codeVerificationId,
            })
        } else if (checkFor === "phone") {
            verifyCode({
                code: String(otpInputValue),
                phone_id: codeVerificationId,
            })
        }
    }

    const onOTPInputChange = (newValue: string) => {
        setIsShowingErrorMessage(false)

        if (isCodeInvalid) {
            setIsCodeInvalid(false)
        }

        setOtpInputValue(newValue)
    }

    return (
        <OTPFormContext.Provider
            value={{
                errorMessage:
                    codeVerificationError?.error?.code ||
                    codeRequestError?.error?.email_address ||
                    codeRequestError?.error?.phone_number,
                handleSendCode,
                isVerifyingCode,
                isCodeResendBlocked,
                otpInputValue,
                onOTPInputChange,
                isCodeInvalid,
                handleCodeVerification,
                setIsCodeInvalid,
                setOtpInputValue,
                isRequestingCode,
                setIsCodeResendBlocked,
                isShowingErrorMessage,
            }}
        >
            {children}
        </OTPFormContext.Provider>
    )
}

export default {
    Root: OTPForm,
    AlertBanner: OTPAlertBanner,
    PasteButton: OTPPasteButton,
    SendCodeButton: OTPSendCodeButton,
    VerifyCodeButton: OTPVerifyCodeButton,
}
