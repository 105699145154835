import { useQuery } from '@tanstack/react-query';
import { format } from "date-fns";
import debounce from 'debounce-promise';
import moment from "moment-timezone";
import { useState } from "react";
import { DayPicker } from "react-day-picker";
import { valueIsDefined } from '../../core/utils/utils';


async function fetchAvailabilityData(currentUser, dateString) {
    const endpoint = DjangoUrls["calendar:api-calendar-data-availability"](currentUser.service_company.slug, dateString)

    const response = await fetch(endpoint)

    if (response.ok) {
        return await response.json()
    }
    else {
        return null
    }
}

function CalendarDay(props) {
    const { date, showAvailability, availabilityData } = props

    const currentUser = window.CURRENT_USER
    const preferredTimezone = currentUser.service_company.preferred_timezone

    const dateString = moment.tz(date, preferredTimezone).format("YYYY-MM-DD")
    const availability = valueIsDefined(availabilityData) ? availabilityData[dateString] : undefined

    let dotClass = ""

    if (availability === undefined) {
        dotClass = "DayPicker-Day-Date-Signifier-Dot--availability-unknown"
    }
    else {
        dotClass = `DayPicker-Day-Date-Signifier-Dot--availability-${availability.availability_characterization}`
    }

    return (
        <div className="DayPicker-Day-Date-Container" title={availability !== undefined ? `${availability.availability_hours} / ${availability.base_availability_hours} hours | ${Math.round(availability.availability_percentage * 100)}% available` : ""}>
            <div className="DayPicker-Day-Date">{date.getDate()}</div>
            {showAvailability && <div className="DayPicker-Day-Signifier"><span className={`DayPicker-Day-Signifier-Dot ${dotClass}`}></span></div>}
        </div>
    )
}


export default function CalendarDayPicker(props) {
    const { initialDate, onDateChange, showAvailability } = props

    const currentUser = window.CURRENT_USER
    const preferredTimezone = currentUser.service_company.preferred_timezone
    const ISOStartOfWeek = currentUser.service_company.start_of_week

    const [selectedDate, setSelectedDate] = useState(initialDate)
    const [availabilityDate, setAvailabilityDate] = useState(initialDate)

    const availabilityDateString = moment.tz(availabilityDate, preferredTimezone).format("YYYY-MM-DD")
    const availabilityDateMonthString = moment.tz(availabilityDate, preferredTimezone).format("YYYY-MM")

    const { data: availabilityData } = useQuery({
        queryKey: ["availability-data", availabilityDateMonthString],
        queryFn: () => fetchAvailabilityData(currentUser, availabilityDateString),
        staleTime: 360000,
        enabled: showAvailability,
    })

    return (
        <div className="data-panel day-picker" aria-label="Calendar Day Picker" >
            <DayPicker
                mode="single"
                showOutsideDays={true}
                selected={selectedDate}
                // month={monthDisplay}
                onSelect={(day) => {setSelectedDate(day); onDateChange(day)}}
                onMonthChange={debounce((day) => setAvailabilityDate(day), 500)}
                weekStartsOn={ISOStartOfWeek === 7 ? 0 : ISOStartOfWeek}
                formatters={{
                    formatWeekdayName: (date, options) => format(date, "EEE", { locale: options?.locale })
                }}
                components={{
                    DayContent: (day) => <CalendarDay {...day} showAvailability={showAvailability} availabilityData={availabilityData} />,
                }}
            />
        </div>
    )
}
