import { valueIsDefined } from "../../../../core/utils/utils"

function renderRecordClientString(record) {
    return record.cleaned_client_name ? record.cleaned_client_name : "(No Name)"
}

function renderRecordServiceLocationString(record, multiline=false) {
    if (multiline) {
        return `${record.cleaned_service_location_name}${valueIsDefined(record.cleaned_service_location_name) ? "\n" : ""}${record.cleaned_service_location_physical_address_formatted_multiline}`
    }
    else {
        const nameDisplay = valueIsDefined(record.cleaned_service_location_name) ? "[" + record.cleaned_service_location_name + "] " : ""
        return `${nameDisplay}${record.cleaned_service_location_physical_address_formatted}`
    }
}

export { renderRecordClientString, renderRecordServiceLocationString }
