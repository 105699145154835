import UniversalButton from "./UniversalButton";


class UniversalLinkButton extends UniversalButton {

    render() {
        const { type, text, destination, openNew=false, download, title, extraClasses } = this.props
        return <a className={`${this.getClassName(type)}${extraClasses ? ` ${extraClasses}` : ""}`} href={destination} download={download} target={openNew ? "_blank" : undefined} title={title}>{text}</a>
    }
}

export default UniversalLinkButton;
