import { RouteCRUD } from "./routes.types"

export const PURCHASE_ORDER_ROUTES: RouteCRUD = {
    CREATE: "/{service_company_slug}/purchase-orders/create/",
    LIST: "/{service_company_slug}/purchase-orders/",
    COUNT: "/{service_company_slug}/api/purchase-orders/count/",
    LIGHTWEIGHT_LIST: "/{service_company_slug}/api/purchase-orders/lightweight/",
    UPDATE: "/{service_company_slug}/purchase-orders/{id}/update/",
    DETAILS: "/{service_company_slug}/purchase-orders/{id}/",
}
