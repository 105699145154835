import Spinner from "@legacy/core/components/Spinner";
import { Component } from "react";
import ButtonGroup from "../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../core/buttons/UniversalButton";
import WrapperButton from "../../core/buttons/WrapperButton";
import { renderServiceLocationString } from "../utils/utils";


class ServiceLocationListSelect extends Component {

    constructor() {
        super()

        this.state = {
            objects: null,
            existingObjectIDs: undefined,
            nextObjectsEndpoint: undefined,
            loadingMoreObjects: false,
        }
    }

    getListEndpoint = () => {
        return DjangoUrls["clients:api-clients-service-locations-list"](window.MARKETPLACE_ENTITY_SLUG, this.props.client.id)
    }

    _getPagedResults = async () => {
        let objectsEndpoint = this.state.nextObjectsEndpoint

        if (objectsEndpoint === undefined) {
            objectsEndpoint = this.getListEndpoint()
        }

        let results = []
        let nextObjectsEndpoint = null

        if (objectsEndpoint !== null) {
            const response = await fetch(objectsEndpoint)
            const json = await response.json()
            results = json.results
            nextObjectsEndpoint = json.next
        }

        this.setState((state, props) => {
            let updatedState = state
            updatedState.nextObjectsEndpoint = nextObjectsEndpoint
            return updatedState
        })

        return results
    }

    fetchObjects = async () => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState.loadingMoreObjects = true
            return updatedState
        })

        const results = await this._getPagedResults()

        if (this.state.existingObjectIDs === undefined) {
            this.state.existingObjectIDs = new Set()
        }

        let uniqueResults = []

        results.forEach(result => {
            if (!this.state.existingObjectIDs.has(result.id)) {
                uniqueResults.push(result)
                this.state.existingObjectIDs.add(result.id)
            }
        })

        this.setState((state, props) => {
            let updatedState = state

            updatedState.objects = (state.objects || []).concat(uniqueResults)
            updatedState.loadingMoreObjects = false

            return updatedState
        })
    }

    shouldFetchMoreObjects = () => {
        return this.state.nextObjectsEndpoint !== null
    }

    fetchMoreObjects = () => {
        this.fetchObjects()
    }

    componentDidMount = () => {
        this.fetchObjects()
    }

    renderLoadMoreButton = (buttonText) => {
        if (this.state.loadingMoreObjects) {
            return <Spinner centered={true} />
        }
        else {
            if (this.shouldFetchMoreObjects()) {
                return (
                    <ButtonGroup>
                        <ButtonGroupRow>
                            <UniversalButton type="list_select_load_more" text={buttonText} handler={event => this.fetchMoreObjects()} />
                        </ButtonGroupRow>
                    </ButtonGroup>
                )
            }
        }
    }

    render = () => {
        const {fieldName, onSelect, client} = this.props

        if (this.state.objects === null) {
            return (
                <div id={`id_${fieldName}`} className="data-panel__list-select">
                    <Spinner centered={true} />
                </div>
            )
        }
        else {
            let serviceLocationOptions = []
            this.state.objects.forEach(serviceLocation => {serviceLocationOptions.push({"value": serviceLocation.id, "label": renderServiceLocationString(serviceLocation), "object": serviceLocation})})

            return (
                <div id={`id_${fieldName}`} className="data-panel__list-select">
                    {serviceLocationOptions.map(serviceLocationOption => (
                        <WrapperButton key={serviceLocationOption.value} type="object_link" handler={event => onSelect(serviceLocationOption.object)} title={serviceLocationOption.label} ariaLabel="Update Service Location">
                            <div className="data-panel__list-select__option__label"><span>{serviceLocationOption.label}</span></div><div className="data-panel__list-select__option__icon"><i className="fa-sharp fa-angle-right" aria-hidden="true"></i></div>
                        </WrapperButton>
                    ))}
                    {this.renderLoadMoreButton("Load More Locations")}
                </div>
            )
        }
    }
}

export default ServiceLocationListSelect;
