import React, { Fragment } from 'react'
import EventAccordion from '../../core/components/EventAccordion'
import { valueIsDefined } from '../../core/utils/utils'
import formatDuration from "@utils/formatDuration"

class JobFeedAccordion extends EventAccordion {

    fetchData = async () => {
        const { jobID } = this.props

        if (valueIsDefined(jobID)) {
            const endpoint = DjangoUrls["jobs:api-jobs-feed-list"](window.MARKETPLACE_ENTITY_SLUG, jobID)
            const response = await fetch(endpoint)
            return await response.json()
        }
        else {
            return null
        }
    }

    getTitle = () => {
        return "Job Timeline"
    }

    getEmphasis = () => {
        return "time"
    }

    renderFooter = (data) => {
        const { totalTimeLogged=0 } = this.props

        return totalTimeLogged !== 0 && (
            <Fragment>
                <hr />
                Total Time Logged: {formatDuration(totalTimeLogged)}
            </Fragment>
        )
    }
}

export default JobFeedAccordion;
