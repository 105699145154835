// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AvatarStatus-base__YWLrz{width:.2em;height:.2em;border-radius:var(--border-radius-full)}.AvatarStatus-size4__KMukb{width:var(--size-1);height:var(--size-1)}.AvatarStatus-size6__x1Vz3{width:var(--size-1_5);height:var(--size-1_5)}.AvatarStatus-working___eXb1{background-color:var(--jade-9)}.AvatarStatus-unavailable__gU5SZ{background-color:var(--tomato-9)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "AvatarStatus-base__YWLrz",
	"size4": "AvatarStatus-size4__KMukb",
	"size6": "AvatarStatus-size6__x1Vz3",
	"working": "AvatarStatus-working___eXb1",
	"unavailable": "AvatarStatus-unavailable__gU5SZ"
};
export default ___CSS_LOADER_EXPORT___;
