// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Divider-lowContrast__iMovB{background-color:var(--gray-a3)}.Divider-mediumContrast__letzb{background-color:var(--gray-a5)}.Divider-horizontal__BRiTA{width:var(--size-full);height:var(--size-0_25)}.Divider-vertical__NCXtf{width:var(--size-0_25);height:var(--size-full)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lowContrast": "Divider-lowContrast__iMovB",
	"mediumContrast": "Divider-mediumContrast__letzb",
	"horizontal": "Divider-horizontal__BRiTA",
	"vertical": "Divider-vertical__NCXtf"
};
export default ___CSS_LOADER_EXPORT___;
