// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Slider-base__cM0vB{position:relative;display:flex;align-items:center;align-items:center;justify-content:center;width:var(--size-full);height:var(--size-5);user-select:none;touch-action:none}.Slider-track__FgtDX{position:relative;width:var(--size-full);height:var(--size-1);overflow:hidden;background-color:var(--gray-a3);border-radius:var(--border-radius-6)}.Slider-track__FgtDX[data-disabled]{background-color:var(--gray-a3)}.Slider-range__CQiZk{position:absolute;height:var(--size-full);background-color:var(--gray-a12)}.Slider-range__CQiZk[data-disabled]{background-color:var(--gray-7)}.Slider-thumb__xPbeD{display:block;width:var(--size-4);height:var(--size-4);background:var(--surface-tertiary);border:var(--size-0_25) solid var(--gray-a6);border-radius:var(--border-radius-full);outline:0;box-shadow:var(--box-shadow-xs-down);transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);transition-duration:var(--duration-slower);transition-property:background-color,border-color,width,height}.Slider-thumb__xPbeD:focus-visible:not([data-disabled]),.Slider-thumb__xPbeD:active:not([data-disabled]){width:var(--size-5);height:var(--size-5);border:var(--size-0_5) solid var(--gray-12)}.Slider-thumb__xPbeD:hover{cursor:grab}.Slider-thumb__xPbeD:active{cursor:grabbing}.Slider-thumb__xPbeD[data-disabled]{background-color:var(--gray-7);border:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "Slider-base__cM0vB",
	"track": "Slider-track__FgtDX",
	"range": "Slider-range__CQiZk",
	"thumb": "Slider-thumb__xPbeD"
};
export default ___CSS_LOADER_EXPORT___;
