const API_BASE_URL = process.env.BASE_URL

export const AUTH_ENDPOINTS = {
    LOGOUT: API_BASE_URL + "/auth/logout/",
    OTP: {
        EMAIL_REQUEST: API_BASE_URL + "/auth/otp/email/request/",
        EMAIL_VERIFY: API_BASE_URL + "/auth/otp/email/verify/",
        EMAIL_AUTHENTICATE: API_BASE_URL + "/auth/otp/email/authenticate/",
        SMS_REQUEST: API_BASE_URL + "/auth/otp/sms/request/",
        SMS_VERIFY: API_BASE_URL + "/auth/otp/sms/verify/",
        SMS_AUTHENTICATE: API_BASE_URL + "/auth/otp/sms/authenticate/",
    },
    PASSWORD: {
        SMS_AUTHENTICATE: API_BASE_URL + "/auth/password/sms/authenticate/",
        EMAIL_AUTHENTICATE: API_BASE_URL + "/auth/password/email/authenticate/",
    },
}
