import Spinner from "@legacy/core/components/Spinner";
import { Component, Fragment } from "react";
import { sendDataToServer } from "../../core/utils/utils";
import ButtonGroup from "../buttons/ButtonGroup";
import ButtonGroupRow from "../buttons/ButtonGroupRow";
import UniversalLinkButton from "../buttons/UniversalLinkButton";


class SettingsCompanyCamConfigPanel extends Component {

    constructor(props) {
        super(props)
        this.addToastToQueue = this.props.addToastToQueue

        this.state = {
            companyCamConfig: null,

            errors: {
                companyCamConfig: {},
            },
            useCompanyCam: false,
        }
    }

    componentDidMount = async () => {
        const endpoint = DjangoUrls["settings-retrieve-companycam-config"]()
        const response = await fetch(endpoint)
        const json = await response.json()

        this.setState((state, props) => {
            let updatedState = state
            updatedState.companyCamConfig = json
            updatedState.useCompanyCam = json.use_companycam

            return updatedState
        })

        // If the account link was successful, show a success message
        const urlParams = new URLSearchParams(window.location.search)
        const success = urlParams.get('success')

        if (success === "true") {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: "CompanyCam account linked",
            })

            const url = new URL(window.location.href)
            url.searchParams.delete('success')
            window.history.replaceState({}, "", url)
        }
    }

    saveConfig = async () => {
        const endpoint = DjangoUrls["settings-update-companycam-config"]();
        const endpointMethod = "PUT"

        const dataName = "companyCamConfig"
        const submittingName = "submittingCompanyCamConfig"
        const errorDictName = "companyCamConfig"

        const onSuccess = (json) => {
            this.setState((state, props) => {
                let updatedState = state
                updatedState[submittingName] = false
                return updatedState
            });
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: "CompanyCam settings updated",
            })
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "CompanyCam settings could not be updated"
            })
        }

        const dataManipulator = (data, state) => {
            data.use_companycam = state.useCompanyCam

            return data
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, dataManipulator, undefined)
    }

    toggleUseCompanyCam = () => {
        this.setState(
            (state, props) => {
                let updatedState = state
                updatedState.useCompanyCam = !state.useCompanyCam
                return updatedState
            },
            this.saveConfig
        )
    }

    renderButtons = () => {
        if (this.state.submittingCompanyCamConfig) {
            return <Spinner centered={true} />
        }
        else {
            if (this.state.unexpectedError) {
                return (
                    <div className="data-panel__action-feedback">
                        <span className="text-invalid"><strong>An unexpected error occurred.</strong></span>
                    </div>
                )
            }
            else {
                if (this.state.useCompanyCam) {
                    if (this.state.companyCamConfig.connected === true) {
                        return (
                            <div className="data-panel__action-feedback">
                                <span>Your account is connected to CompanyCam.</span>
                            </div>
                        )
                    }
                    else
                    {
                        return (
                            <ButtonGroup>
                                <ButtonGroupRow>
                                    <UniversalLinkButton type="primary" text={<Fragment>Connect with CompanyCam&nbsp;&nbsp;<i className="fa-sharp fa-light fa-arrow-up-right-from-square" aria-hidden="true"></i></Fragment>} destination={this.state.companyCamConfig.COMPANYCAM_OAUTH_ENDPOINT} />
                                </ButtonGroupRow>
                            </ButtonGroup>
                        )
                    }
                }
            }
        }
    }

    render() {
        if (this.state.companyCamConfig === null) {
            return (
                <div id="settings_companycam">
                    <div className="data-panel-container">
                        <Spinner centered={true} />
                    </div>
                </div>
            )
        }
        else {
            return (
                <div id="settings_companycam">
                    <div className="data-panel-container">
                        <div className="data-panel" aria-label="Configure CompanyCam">
                            <div className="data-panel__heading" aria-label="Panel Information">
                                <span className="data-panel__heading__title" aria-label="Panel Header">Configure CompanyCam</span>
                                <hr aria-hidden="true" />
                            </div>

                            <div className="data-panel__form" aria-label="Configure CompanyCam">
                                <div className="data-panel__form__field" id="div_id_use_companycam" aria-label="Use CompanyCam">
                                    <label htmlFor="id_use_companycam" className="data-panel__form__field__label in-form">Use CompanyCam</label>
                                    <div className="data-panel__form__field__input data-panel__form__field__input--with-checkbox">
                                        <input type="checkbox" className="checkbox-input" name="use_companycam" id="id_use_companycam" checked={this.state.useCompanyCam} onChange={event => this.toggleUseCompanyCam()} />
                                        <label className="checkbox-label" htmlFor="id_use_companycam">Use CompanyCam</label>
                                    </div>
                                    {this.state.errors.companyCamConfig.use_companycam && <div className="data-panel__form__field__errors" aria-label="Field Errors">{this.state.errors.companyCamConfig.use_companycam}</div>}
                                </div>
                            </div>
                            {this.renderButtons()}
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default SettingsCompanyCamConfigPanel;
