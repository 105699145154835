import Spinner from "@legacy/core/components/Spinner";
import { Component } from "react";
import ButtonGroup from "../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../core/buttons/UniversalButton";
import AttachmentUploadField from "../../core/fields/AttachmentUploadField";
import BasicDisplayField from "../../core/fields/BasicDisplayField";
import { JobOriginTypes } from "../../core/utils/enums";


class JobAttachmentForm extends Component {

    componentDidMount() {
        // Return to the previous scroll
        document.querySelector(".main").scrollTo(0, this.props.returnScroll || 0)
    }

    renderButtons = () => {
        const { job, updateAttachmentsData, requestAction, switchToPrimaryForm, submitting, errors, onFormDataChange, returnScroll, fileStackAPIKey, fileStackPolicy, fileStackSignature, updateAttachments } = this.props

        if (errors.unexpectedError) {
            return (
                <div className="data-panel__action-feedback">
                    <span className="text-invalid"><strong>An unexpected error occurred.</strong></span>
                </div>
            )
        }
        else {
            if (submitting) {
                return <Spinner centered={true} />
            }
            else {
                return (
                    <ButtonGroup>
                        <ButtonGroupRow>
                            <UniversalButton type="primary" text="Save" handler={event => requestAction("JOB_UPLOAD_ATTACHMENTS")} />
                            <UniversalButton type="secondary" text="Cancel" handler={event => switchToPrimaryForm()} />
                        </ButtonGroupRow>
                    </ButtonGroup>
                )
            }
        }
    }

    render() {
        const { job, updateAttachmentsData, requestAction, switchToPrimaryForm, submitting, errors, onFormDataChange, returnScroll, fileStackAPIKey, fileStackPolicy, fileStackSignature, updateAttachments } = this.props

        return (
            <div className="data-panel-container data-panel-container--with-margin">
                <div className="data-panel" aria-label="Job Pause">
                    <div className="data-panel__form" aria-label="Job Pause Form">
                        <BasicDisplayField
                            fieldName="id"
                            fieldLabel="Roopairs Job ID"
                            fieldValue={job.custom_id ? job.custom_id : job.id}
                        ></BasicDisplayField>
                        {
                            job.origin_type !== JobOriginTypes.roopairs && (
                                <BasicDisplayField
                                    fieldName="origin_id"
                                    fieldLabel={`${job.origin_type !== JobOriginTypes.other ? job.origin_type_label : job.origin_type_other_name} ID`}
                                    fieldValue={job.origin_id}
                                ></BasicDisplayField>
                            )
                        }
                        <BasicDisplayField
                            fieldName="service_name"
                            fieldLabel="Service Type"
                            fieldValue={job.service_name}
                        ></BasicDisplayField>
                        <BasicDisplayField
                            fieldName="is_job_walk"
                            fieldLabel="Job Type"
                            fieldValue={job.is_callback ? "Callback" : job.is_job_walk ? "Job Walk" : "Service"}
                        ></BasicDisplayField>

                        {
                            <AttachmentUploadField
                                fieldName="attachments"
                                fieldLabel="Attachments"
                                attachments={job.attachments}
                                fileStackAPIKey={fileStackAPIKey}
                                fileStackPolicy={fileStackPolicy}
                                fileStackSignature={fileStackSignature}
                                onUploadDone={(response) => updateAttachments(
                                    response.filesUploaded.map(file => ({
                                        "upload_filename": file.filename,
                                        "upload_id": file.uploadId,
                                        "upload_handle": file.handle,
                                        "upload_url": file.url,
                                        "upload_mimetype": file.mimetype,
                                        "upload_size": file.size,
                                        "upload_source": file.source
                                    }))
                                )}
                                optional={true}
                                optionalText={"if any"}
                                errors={errors}
                            />
                        }
                    </div>
                    {this.renderButtons()}
                </div>
            </div>
        )
    }
}

export default JobAttachmentForm;
