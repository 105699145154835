import Spinner from "@legacy/core/components/Spinner";
import { Component, Fragment } from "react";
import deepcopy from "rfdc";
import ButtonGroup from "../../../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../../../core/buttons/UniversalButton";
import Banner from "../../../../core/components/Banner";
import AddressAutocompleteFieldGroup from "../../../../core/fields/AddressAutocompleteFieldGroup";
import BasicSelectField from "../../../../core/fields/BasicSelectField";
import CharField from "../../../../core/fields/CharField";
import ListSelectField from "../../../../core/fields/ListSelectField";
import SwitchField from "../../../../core/fields/SwitchField";
import TextField from "../../../../core/fields/TextField";
import { ClientTypes, IndustryTypes } from "../../../../core/utils/enums";
import QuickBooksContactListDisplay from "../components/QuickBooksContactListDisplay";
import RecordContactListEditSelect from "../components/RecordContactListEditSelect";
import RecordServiceLocationListEditSelect from "../components/RecordServiceLocationListEditSelect";


const INDUSTRY_TYPES = [
    {"value": "", "label": "Choose industry type..."},
    {"value": IndustryTypes.residential, "label": "Residential"},
    {"value": IndustryTypes.commercial, "label": "Commercial"},
    {"value": IndustryTypes.industrial, "label": "Industrial"},
    {"value": IndustryTypes.government, "label": "Government"},
]


class QuickBooksClientRecordForm extends Component {

    renderButtons = () => {
        const {submitting, record, errors, formErrors, onFormDataChange, requestAction} = this.props

        if (formErrors.unexpectedError) {
            return (
                <div className="data-panel__action-feedback">
                    <span className="text-invalid"><strong>An unexpected error occurred.</strong></span>
                </div>
            )
        }
        else {
            if (submitting) {
                return (
                    <div className="data-panel__action-feedback">
                        <Spinner centered={true} />
                    </div>
                )
            }
            else {
                return (
                    <ButtonGroup>
                        <ButtonGroupRow>
                            <UniversalButton type="primary" text="Save" handler={event => requestAction("RECORD_UPDATE_CLIENT")} />
                            <UniversalButton type="secondary" text="Cancel Edits" handler={event => requestAction("RECORD_CANCEL_CLIENT_EDITS")} />
                        </ButtonGroupRow>
                    </ButtonGroup>
                )
            }
        }
    }

    renderContactList = (record, contacts, errors) => {
        if (record.has_orphans) {
            return <QuickBooksContactListDisplay record={record} label="Client Contacts" />
        }
        else {
            return (
                <ListSelectField
                    fieldName="contacts"
                    fieldLabel="Client Contacts"
                    inputComponent={<RecordContactListEditSelect fieldName="contacts" contacts={contacts} updateContacts={contacts => this.props.onFormDataChange("contacts", contacts)} />}
                    showButton={false}
                    errors={errors}
                ></ListSelectField>
            )
        }
    }

    render() {
        const {submitting, record, formErrors, onFormDataChange, requestAction} = this.props
        let { errors } = this.props
        errors = deepcopy()(errors)

        if (errors.cleaned_client_name) {
            errors.cleaned_client_name += ` If it helps, QuickBooks identifies this record as \n"${record.name}".`
        }

        const contacts = record.contacts.filter(contact => contact.ignored !== true)

        return (
            <Fragment>
                <div className="data-panel__form" aria-label="Record Edit Form">
                    {
                        record.has_orphans && (
                            <Banner type="info" text={<span>This client record has already been imported (but the service locations below haven't).<br /><br />You can make changes to this client's details from the Clients module in Roopairs.</span>} />
                        )
                    }
                    <SwitchField
                        fieldName="client_type"
                        fieldLabel="Client Type"
                        fieldValue={record.cleaned_client_client_type !== undefined ? record.cleaned_client_client_type === ClientTypes.individual : window.DEFAULT_CLIENT_TYPE === ClientTypes.individual || false}
                        fieldOnChange={cleaned_client_client_type => onFormDataChange("cleaned_client_client_type", cleaned_client_client_type ? ClientTypes.individual : ClientTypes.business)}
                        uncheckedText="Business"
                        checkedText="Individual"
                        errors={errors}
                        disabled={record.has_orphans}
                    ></SwitchField>
                    <BasicSelectField
                        fieldName="cleaned_client_industry_type"
                        fieldLabel="Industry Type"
                        fieldValue={record.cleaned_client_industry_type || ""}
                        fieldOnChange={cleaned_client_industry_type => onFormDataChange("cleaned_client_industry_type", cleaned_client_industry_type || "")}
                        choices={INDUSTRY_TYPES}
                        errors={errors}
                        optional={true}
                        disabled={record.has_orphans}
                    ></BasicSelectField>
                    <CharField
                        fieldName="cleaned_client_name"
                        fieldLabel="Name"
                        fieldValue={record.cleaned_client_name || ""}
                        fieldOnChange={cleaned_client_name => onFormDataChange("cleaned_client_name", cleaned_client_name || "")}
                        maxLength="100"
                        errors={(errors.cleaned_client_name === undefined && formErrors.cleaned_client_name) ? formErrors : errors}
                        disabled={record.has_orphans}
                    ></CharField>
                    <AddressAutocompleteFieldGroup
                        key="record_cleaned_client_billing_address"
                        fieldGroupName="cleaned_client_billing_address"
                        fieldGroupLabel="Billing Address"
                        fieldPrefix="cleaned_client_billing_address"
                        fieldValues={{
                            "cleaned_client_billing_address_recipient": record.cleaned_client_billing_address_recipient || "",
                            "cleaned_client_billing_address_street": record.cleaned_client_billing_address_street || "",
                            "cleaned_client_billing_address_unit": record.cleaned_client_billing_address_unit || "",
                            "cleaned_client_billing_address_city": record.cleaned_client_billing_address_city || "",
                            "cleaned_client_billing_address_state": record.cleaned_client_billing_address_state || "",
                            "cleaned_client_billing_address_postal": record.cleaned_client_billing_address_postal || "",
                            "cleaned_client_billing_address_country": record.cleaned_client_billing_address_country || "",
                        }}
                        onFormDataChange={onFormDataChange}
                        includeRecipient={true}
                        optional={true}
                        errors={errors}
                        disabled={record.has_orphans}
                    ></AddressAutocompleteFieldGroup>
                    <TextField
                        fieldName="notes"
                        fieldLabel="Client Notes"
                        fieldValue={record.cleaned_client_notes || ""}
                        fieldOnChange={cleaned_client_notes => onFormDataChange("cleaned_client_notes", cleaned_client_notes || "")}
                        rows={3}
                        placeholder="Any additional details about this client. Details about a particular job should not be entered here."
                        optional={true}
                        errors={errors}
                        disabled={record.has_orphans}
                    ></TextField>
                    {contacts.length !== 0 && this.renderContactList(record, contacts, errors)}
                    {
                        record.children !== undefined && record.children.length !== 0 && (
                            <ListSelectField
                                fieldName="service_locations"
                                fieldLabel="Service Locations"
                                inputComponent={<RecordServiceLocationListEditSelect fieldName="children" errorsExist={record.child_has_errors} records={record.children || []}></RecordServiceLocationListEditSelect>}
                                showButton={false}
                                errors={errors}
                            ></ListSelectField>
                        )
                    }
                </div>
                {this.renderButtons()}
            </Fragment>
        )
    }
}

export default QuickBooksClientRecordForm;
