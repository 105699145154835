import { ColumnId } from "@organisms/Table/Table.types"

import { columnNameByColumnId, columnNameByIdVariations } from "@constants/mappings/table/columnNameByColumnId.mapping"

export default function getColumnNameByColumnId(id: ColumnId, objectType: ObjectType) {
    if (columnNameByIdVariations[objectType]?.[id]) {
        return columnNameByIdVariations[objectType][id]
    } else {
        return columnNameByColumnId[id]
    }
}
