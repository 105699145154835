import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import clsx from "clsx"

import convertToKebabCase from "@utils/convertToKebabCase"

import { Icon } from "@atoms"

import { Table } from "@organisms"

import styles from "./DownPaymentCell.module.scss"
import { DownPaymentCellProps, DownPaymentStatus } from "./DownPaymentCell.types"

export default function DownPaymentCell(props: DownPaymentCellProps) {
    const { value } = props

    if (!value) {
        return <Table.Cells.NoValueCell />
    }

    const iconMap: {
        [key in DownPaymentStatus]: IconProp
    } = {
        "Not Required": icon({
            name: "minus",
            style: "light",
            family: "sharp",
        }),
        Paid: icon({
            name: "check-circle",
            style: "light",
            family: "sharp",
        }),
        "Partially Paid": icon({
            name: "circle-half-stroke",
            style: "light",
            family: "sharp",
        }),
        Unpaid: icon({
            name: "xmark-circle",
            style: "light",
            family: "sharp",
        }),
    }

    return (
        <div className={clsx(styles.base, styles[convertToKebabCase(value)])}>
            <Icon icon={iconMap[value]} size={12} noContainer={true} />
            {value}
        </div>
    )
}
