import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"

import useUser from "@hooks/useUser"
import useUserNavigationUtils from "@hooks/useUserNavigationUtils"

import { NavigationLink } from "@atoms"

import { UserAvatar } from "@molecules"

import { BottomSheet } from "@organisms"

import styles from "./ProfileBottomSheet.module.scss"
import { ProfileBottomSheetProps } from "./ProfileBottomSheet.types"
import ProfileBottomSheetWhatsNew from "./ProfileBottomSheetWhatsNew/ProfileBottomSheetWhatsNew"

export default function ProfileBottomSheet(props: ProfileBottomSheetProps) {
    const { isOpen, onClose } = props

    const { user } = useUser()

    const { logout, openHelpSidebar, goToSettings, goToContactSupport, goToCannyRequestFeature } =
        useUserNavigationUtils()

    return (
        <BottomSheet.Root isOpen={isOpen} onClose={onClose}>
            <BottomSheet.Content
                isVisible={isOpen}
                priority="exclusive"
                header={{
                    title: "Account",
                    showDragIndicator: true,
                }}
                itemsClassName={styles.bottomSheetContent}
            >
                {() => (
                    <>
                        <NavigationLink
                            isTabbable={isOpen}
                            onClick={goToSettings}
                            className={styles.userMetadata}
                            title="Profile"
                        >
                            {() => (
                                <>
                                    <UserAvatar user={user} size={80} shape="circle" />
                                    <div className={styles.nameAndCaption}>
                                        <span className={styles.name}>{user?.full_name}</span>
                                        <span className={styles.caption}>View profile →</span>
                                    </div>
                                </>
                            )}
                        </NavigationLink>
                        <div className={styles.itemsList}>
                            <BottomSheet.Item
                                isTabbable={isOpen}
                                icon={icon({ name: "gear", style: "light", family: "sharp" })}
                                label={"Settings"}
                                onClick={goToSettings}
                            />
                            <BottomSheet.Divider />
                            <div className={styles.itemsStack}>
                                <BottomSheet.Item
                                    isTabbable={isOpen}
                                    icon={icon({ name: "question-circle", style: "light", family: "sharp" })}
                                    label={"Help Center"}
                                    onClick={openHelpSidebar}
                                    className={styles.bottomSheetItem}
                                />
                                <BottomSheet.Item
                                    isTabbable={isOpen}
                                    icon={icon({ name: "headset", style: "light", family: "sharp" })}
                                    label={"Contact Support"}
                                    onClick={goToContactSupport}
                                    className={styles.bottomSheetItem}
                                />
                                <BottomSheet.Item
                                    isTabbable={isOpen}
                                    icon={icon({ name: "circle-info", style: "light", family: "sharp" })}
                                    label={"Request Feature"}
                                    onClick={goToCannyRequestFeature}
                                    className={styles.bottomSheetItem}
                                />
                                <ProfileBottomSheetWhatsNew />
                            </div>
                            <BottomSheet.Divider />
                            <BottomSheet.Item
                                isTabbable={isOpen}
                                icon={icon({ name: "arrow-right-from-bracket", style: "light", family: "sharp" })}
                                label={"Log Out"}
                                isDangerous={true}
                                onClick={() => void logout()}
                            />
                        </div>
                    </>
                )}
            </BottomSheet.Content>
        </BottomSheet.Root>
    )
}
