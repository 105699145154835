import { AssignmentModel, DateHelper, ResourceModel, ResourceTimeRangeModel } from "@bryntum/schedulerpro"
import { useInfiniteQuery, useQuery, useQueryClient } from "@tanstack/react-query"
import Cookies from "js-cookie"

import useSlugExtractor from "@hooks/useSlugExtractor"
import useUser from "@hooks/useUser"

import fetchObjectListData from "@utils/fetchObjectListData"
import replaceSlugs from "@utils/replaceSlugs"

import toast from "@molecules/Toast/Toast"

import {
    EventModel,
    EventRecord,
    JobUpdatePayload,
    SchedulerEventModel,
    TechnicianAvailabilityData,
} from "@organisms/ObjectsView/JobTimelineView/JobTimelineView.types"
import { ObjectListData } from "@organisms/ObjectsView/ObjectsView.types"

import { AVAILABILITY_SCHEDULES_ENDPOINTS, CALENDAR_DATA_ENDPOINTS } from "@endpoints/calendar"
import { JOB_ENDPOINTS } from "@endpoints/jobs"

import useJobTimelineViewBryntumInstances from "./useJobTimelineViewBryntumInstances"
import useJobTimelineViewOverlappingUtils from "./useJobTimelineViewOverlappingUtils"
import useJobTimelineViewStates from "./useJobTimelineViewStates"
import useJobTimelineViewTimeRanges from "./useJobTimelineViewTimeRanges"
import useJobTimelineViewUIUtils from "./useJobTimelineViewUIUtils"

export default function useJobTimelineViewData() {
    const { limitOfItemsPerGridFetch, currentDate } = useJobTimelineViewStates()

    const [unscheduledJobsListEndpoint] = useSlugExtractor([CALENDAR_DATA_ENDPOINTS.LIST_UNSCHEDULED_JOBS])

    const unscheduledJobsQuery = `${unscheduledJobsListEndpoint}?limit=${limitOfItemsPerGridFetch}&offset=0"`

    const { user } = useUser()

    const serviceCompanySlug = user?.service_company?.slug

    const { schedulerPro } = useJobTimelineViewBryntumInstances()

    const { getTimeRanges, generateAllSchedulerTimeRanges } = useJobTimelineViewTimeRanges()

    const { handleOverlappingEvents } = useJobTimelineViewOverlappingUtils()

    const { shouldShowFinalizedJobs, shouldShowCancelledJobs } = useJobTimelineViewStates()

    const queryClient = useQueryClient()

    const {
        hideFinalizedJobsFromScheduler,
        showFinalizedJobsInScheduler,
        hideCancelledJobsFromScheduler,
        showCancelledJobsInScheduler,
    } = useJobTimelineViewUIUtils()

    const {
        data: unscheduledJobsData,
        isLoading: isFetchingUnscheduledJobs,
        isError: isUnscheduledJobsError,
        fetchNextPage: fetchNextUnscheduledJobsPage,
        hasNextPage: unscheduledJobsPaginationHasNextPage,
        refetch: refetchUnscheduledJobs,
    } = useInfiniteQuery<ObjectListData<CalendarEvent>>({
        queryKey: [unscheduledJobsQuery],
        queryFn: ({ pageParam }) =>
            fetchObjectListData({
                objectName: "tabContentName",
                endpoint: unscheduledJobsQuery,
                endpointKwargs: [],
                searchKeywords: "",
                sorting: null,
                nextPage: pageParam as string,
            }),
        initialPageParam: "",
        staleTime: 60000,
        getNextPageParam: (lastPage) => lastPage.next,
        enabled: !!limitOfItemsPerGridFetch,
    })

    const {
        data: calendarData,
        isLoading: isFetchingCalendarData,
        isError: isCalendarDataError,
        refetch: refetchCalendarData,
    } = useQuery<CalendarEvent[], Error>({
        queryKey: ["calendarData", currentDate],
        queryFn: () => fetchCalendarDataForDate(new Date(currentDate)),
        staleTime: 60000,
    })

    const {
        data: availabilitySchedulesData,
        isLoading: isFetchingAvailabilitySchedulesData,
        refetch: refetchAvailabilitySchedules,
        isError: isAvailabilitySchedulesError,
    } = useQuery<TechnicianAvailabilityData[], Error>({
        queryKey: ["availabilitySchedules", currentDate],
        queryFn: () => fetchAvailabilitySchedulesForDate(new Date(currentDate)),
        staleTime: 60000,
    })

    async function fetchCalendarDataForDate(date: Date) {
        const dateString = DateHelper.format(date, "YYYY-MM-DD")

        const calendarDataEndpoint = replaceSlugs(CALENDAR_DATA_ENDPOINTS.DATA, {
            service_company_slug: serviceCompanySlug,
            date: dateString,
        })

        const calendarDataResponse = await fetch(calendarDataEndpoint, { cache: "no-cache" })

        if (!calendarDataResponse.ok) {
            throw new Error(calendarDataResponse.statusText)
        }

        return (await calendarDataResponse.json()) as CalendarEvent[]
    }

    async function fetchAvailabilitySchedulesForDate(date: Date) {
        const dateString = DateHelper.format(date, "YYYY-MM-DD")

        const availabilitySchedulesEndpoint = replaceSlugs(AVAILABILITY_SCHEDULES_ENDPOINTS.LIST, {
            service_company_slug: serviceCompanySlug,
            date: dateString,
        })

        const availabilitySchedulesResponse = await fetch(availabilitySchedulesEndpoint)

        if (!availabilitySchedulesResponse.ok) {
            throw new Error(availabilitySchedulesResponse.statusText)
        }

        return (await availabilitySchedulesResponse.json()) as TechnicianAvailabilityData[]
    }

    const populateResources = () => {
        const resources: CalendarTechnician[] = []
        const resourceIDs = new Set()

        const hasAvailabilitySchedulesData = availabilitySchedulesData?.length > 0

        if (!hasAvailabilitySchedulesData) {
            return resources
        } else {
            availabilitySchedulesData?.forEach((schedule) => {
                resourceIDs.add(schedule.technician?.id)
                resources.push({
                    ...schedule.technician,
                    available: schedule.available,
                    status: schedule.status,
                    id: schedule.technician?.id,
                    first_name: schedule.technician.first_name,
                    last_name: schedule.technician.last_name,
                    full_name: schedule.technician.full_name,
                    short_name: schedule.technician.short_name,
                    avatar: schedule.technician.avatar,
                    gravatar: schedule.technician.gravatar,
                    calendar: schedule.available ? "common-calendar" : "full-day-off",
                })
            })

            calendarData.forEach((event) => {
                if (!resourceIDs.has(event.technician_id)) {
                    const technician = event.assigned_technicians.find((tech) => tech?.id === event.technician_id)
                    if (technician) {
                        resources.push({
                            ...technician,
                            available: false,
                            status: "Inactive",
                            calendar: "full-day-off",
                        })
                    }
                }
            })

            return resources
        }
    }

    const mapCalendarEventsAndAssignments = () => {
        const assignments: AssignmentModel[] = []
        const events: EventRecord[] = []

        calendarData.forEach((event, index) => {
            assignments.push({
                id: index,
                event: event.id,
                resource: event.technician_id || "unassigned",
            } as AssignmentModel)

            const eventIdIsRepeated = events.some((e) => e.id === event.id)

            if (!eventIdIsRepeated) {
                const DURATION_IN_HOURS = event.estimated_duration / 3600

                events.push({
                    ...event,
                    name: event.service_name,
                    startDate: event.start_time,
                    endDate: event.end_time,
                    duration: DURATION_IN_HOURS,
                })
            }
        })

        return { assignments, events }
    }

    const generateUnassignedResource = (): CalendarTechnician => {
        return {
            calendar: "common-calendar",
            id: "unassigned",
            name: "Unassigned",
        } as CalendarTechnician
    }

    const populateSchedulerWithData = (
        resourceTimeRanges: ResourceTimeRangeModel[],
        resources: ResourceModel[],
        events: EventModel<EventRecord>[],
        assignments: AssignmentModel[],
    ) => {
        schedulerPro.current.instance.resources = []
        schedulerPro.current.instance.resources = resources

        schedulerPro.current.instance.events = events
        schedulerPro.current.instance.assignments = assignments

        // TODO: Investigate why this works for fixing the issue ROO-2471
        schedulerPro.current.instance.resourceTimeRanges = resourceTimeRanges
        schedulerPro.current.instance.resourceTimeRanges = resourceTimeRanges
    }

    const configureAndPopulateScheduler = () => {
        shouldShowFinalizedJobs ? showFinalizedJobsInScheduler() : hideFinalizedJobsFromScheduler()
        shouldShowCancelledJobs ? showCancelledJobsInScheduler : hideCancelledJobsFromScheduler()

        const timeRange = getTimeRanges(currentDate)
        const resources = populateResources()
        const { assignments, events } = mapCalendarEventsAndAssignments()

        const resourceTimeRanges = generateAllSchedulerTimeRanges(timeRange, resources)

        const finalResourcesList = [
            ...(user.isServiceDispatcher ? [generateUnassignedResource()] : []),
            ...resources,
        ] as unknown as ResourceModel[]

        const unscheduledJobs = schedulerPro.current.instance.events.filter((event: EventModel<EventRecord>) => {
            return !event.originalData.startDate ? event : null
        })

        const finalEventsList = [
            ...(events as unknown as EventModel<EventRecord>[]),
            ...(unscheduledJobs as EventModel<EventRecord>[]),
        ]

        populateSchedulerWithData(resourceTimeRanges, finalResourcesList, finalEventsList, assignments)

        schedulerPro.current.instance.refreshRows()

        handleOverlappingEvents()
    }

    const syncJobUpdatesWithServer = async (jobId: Job["id"], updatedJob: JobUpdatePayload) => {
        const updateJobEndpoint = replaceSlugs(JOB_ENDPOINTS.UPDATE, {
            service_company_slug: serviceCompanySlug,
            id: jobId,
        })

        try {
            const response = await fetch(updateJobEndpoint, {
                method: "PATCH",
                headers: {
                    "X-CSRFToken": Cookies.get("csrftoken"),
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    skip_success_modal: true,
                    ...updatedJob,
                }),
            })

            if (response.ok) {
                toast({
                    type: "success",
                    size: "md",
                    title: `Job ${updatedJob?.custom_id || updatedJob?.id || jobId} updated`,
                })
            } else {
                toast({
                    type: "error",
                    size: "md",
                    title: "Job could not be updated",
                })
            }
        } catch (error) {
            toast({
                type: "error",
                size: "md",
                title: "Job could not be updated",
            })
        }
    }

    const updateEventRecord = (
        eventRecord: SchedulerEventModel,
        newData: {
            resizingStartTime?: Date
            resizingEndTime?: Date
            isResizingEventStart?: boolean
            isResizingEventEnd?: boolean
            isOverlappingOnResize?: boolean
        },
    ) => {
        if (newData.isOverlappingOnResize !== undefined) {
            eventRecord.isOverlappingOnResize = newData.isOverlappingOnResize
        }
        if (newData.resizingEndTime !== undefined) {
            eventRecord.resizingEndTime = newData.resizingEndTime
        }
        if (newData.resizingStartTime !== undefined) {
            eventRecord.resizingStartTime = newData.resizingStartTime
        }
        if (newData.isResizingEventStart !== undefined) {
            eventRecord.isResizingEventStart = newData.isResizingEventStart
        }
        if (newData.isResizingEventEnd !== undefined) {
            eventRecord.isResizingEventEnd = newData.isResizingEventEnd
        }
    }

    const updateEventStore = (jobId: Job["id"], newData: CalendarEvent) => {
        const eventStore = schedulerPro.current.instance.eventStore.getById(jobId)
        eventStore.set(newData)
    }

    const updateEventInCache = ({
        jobId,
        technicianToDrop,
        technicianToAdd,
        newData,
    }: {
        jobId: Job["id"]
        newData: CalendarEvent
        technicianToDrop?: CalendarTechnician
        technicianToAdd?: CalendarTechnician
    }) => {
        queryClient.setQueryData(["calendarData", currentDate], (oldData: CalendarEvent[]) => {
            const newCalendarData = oldData.map((event: CalendarEvent) => {
                const currentAssignedTechnician = event.technician_id || "unassigned"

                const shouldDropCurrentTechnician = currentAssignedTechnician === technicianToDrop?.id
                const shouldUnassign = technicianToAdd?.id === "unassigned"

                const isTargetedEvent = event.id === jobId
                const isMultiAssignedEvent = event.assigned_technicians.length > 1
                const isSameTechnicianOperation = (!technicianToAdd || !technicianToDrop) && isTargetedEvent

                const shouldReplaceTechnician = isTargetedEvent && shouldDropCurrentTechnician
                const shouldRemoveFromCache =
                    isTargetedEvent && shouldDropCurrentTechnician && shouldUnassign && isMultiAssignedEvent

                const isPartOfUpdatedEvent =
                    isTargetedEvent &&
                    !shouldDropCurrentTechnician &&
                    isMultiAssignedEvent &&
                    !isSameTechnicianOperation

                if (isPartOfUpdatedEvent || isSameTechnicianOperation) {
                    return {
                        ...event,
                        ...newData,
                    }
                } else if (!isTargetedEvent) {
                    return event
                } else if (shouldRemoveFromCache) {
                    return null
                } else if (shouldReplaceTechnician) {
                    return {
                        ...event,
                        ...newData,
                        technician_id: technicianToAdd.id,
                    }
                } else {
                    return event
                }
            })

            return newCalendarData.filter(Boolean)
        })
    }

    const resetTimeRanges = () => {
        schedulerPro.current.instance.resourceTimeRanges = []
    }

    const addEventToCache = (date: Date, newEvent: CalendarEvent) => {
        queryClient.setQueryData(["calendarData", date], (oldData: CalendarEvent[]) => {
            // Because in the first time this function is called there might not be an old data yet
            if (oldData) {
                return [...oldData, newEvent]
            }
        })
    }

    return {
        unscheduledJobsData,
        isFetchingUnscheduledJobs,
        fetchNextUnscheduledJobsPage,
        unscheduledJobsPaginationHasNextPage,
        refetchUnscheduledJobs,
        calendarData,
        isFetchingCalendarData,
        refetchCalendarData,
        availabilitySchedulesData,
        isFetchingAvailabilitySchedulesData,
        refetchAvailabilitySchedules,
        populateResources,
        mapCalendarEventsAndAssignments,
        generateUnassignedResource,
        populateSchedulerWithData,
        configureAndPopulateScheduler,
        syncJobUpdatesWithServer,
        updateEventRecord,
        updateEventStore,
        isUnscheduledJobsError,
        isCalendarDataError,
        isAvailabilitySchedulesError,
        updateEventInCache,
        addEventToCache,
        resetTimeRanges,
    }
}
