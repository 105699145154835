import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"

import useIsDesktop from "@hooks/useIsDesktop"

import { IconButton } from "@atoms/index"

export default function TimelineViewTechniciansBarHeader() {
    const isDesktop = useIsDesktop()

    return (
        <div className="job-timeline-view__technicians-bar__header">
            <span className="job-timeline-view__technicians-bar__header-text">Technicians</span>
            {!isDesktop && (
                <IconButton
                    className="job-timeline-view__technicians-bar__header-toggle-button jsJobTechniciansBarColumnHeaderToggle"
                    icon={icon({
                        name: "arrow-left-to-line",
                        family: "sharp",
                        style: "regular",
                    })}
                    variant="ghost"
                    size="sm"
                    onClick={() => null}
                    colorScheme="gray"
                />
            )}
        </div>
    )
}
