import { AlertBannerProps } from "@atoms/AlertBanner/AlertBanner.types"
import { AlertBanner } from "@atoms/index"

import useOTPForm from "@organisms/OTPForm/OTPForm.store"

export default function OTPAlertBanner(props: Partial<AlertBannerProps>) {
    const { errorMessage, isShowingErrorMessage } = useOTPForm()

    return (
        errorMessage &&
        isShowingErrorMessage && (
            <AlertBanner size="md" title={errorMessage} type="error" fullWidth={true} {...props} />
        )
    )
}
