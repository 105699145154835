import pluralize from "pluralize"

import convertToSnakeCase from "@utils/convertToSnakeCase"

import { ObjectsViewType } from "@organisms/ObjectsView/ObjectsView.types"

export interface GetObjectViewConfigStorageKeyArgs {
    objectType: ObjectType
    objectTypeVariation?: string
    viewType: ObjectsViewType
    phase: ObjectPhase
    tab: string
}

export default function getObjectViewConfigStorageKey(args: GetObjectViewConfigStorageKeyArgs) {
    const { objectType, viewType, phase, tab, objectTypeVariation } = args

    const objectTypePlural = pluralize(objectType)
    const snakeCaseViewType = viewType.toLowerCase()
    const snakeCaseTab = convertToSnakeCase(tab)

    return `${objectTypePlural}${
        objectTypeVariation ? "_" + convertToSnakeCase(objectTypeVariation) : ""
    }_${snakeCaseViewType}__${phase}_${snakeCaseTab}`
}
