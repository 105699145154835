import { RouteCRUD } from "./routes.types"

export const ESTIMATES_ROUTES: RouteCRUD = {
    CREATE: "/{service_company_slug}/estimates/create/",
    LIST: "/{service_company_slug}/estimates/",
    COUNT: "/{service_company_slug}/api/estimates/count/",
    LIGHTWEIGHT_LIST: "/{service_company_slug}/api/estimates/lightweight/",
    UPDATE: "/{service_company_slug}/estimates/{id}/update/",
    DETAILS: "/{service_company_slug}/estimates/{id}/",
}
