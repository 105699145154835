import nr from 'number-rounding';
import { Fragment } from 'react';
import Banner from "../../core/components/Banner";

function calculatePurchaseOrderAmounts(purchaseOrder) {
    let subtotal = 0

    // Subtotal
    const parts = purchaseOrder.parts || []

    const addToSubtotal = (lineItem) => {subtotal += nr.roundHalfEven(parseFloat(lineItem.quantity || 0) * parseFloat(lineItem.expected_cost || 0), 2)}

    parts.forEach(addToSubtotal)

    if (parts.length === 0 && purchaseOrder.line_items !== undefined && purchaseOrder.line_items.length !== 0) {
        purchaseOrder.line_items.forEach(addToSubtotal)
    }

    return { subtotal }
}

function getPurchaseOrderStatusBarLabel(purchaseOrder) {
    let statusBarLabel = purchaseOrder.status_label

    if (purchaseOrder.status_label === "Draft") {
        statusBarLabel = "Draft Preview"
    }

    if (window.USING_PUBLIC_URL === true) {
        if (purchaseOrder.status_label === "Sent") {
            statusBarLabel = "Awaiting Acknowledgement"
        }
        else if (purchaseOrder.status_label === "Acknowledged") {
            statusBarLabel = "Awaiting Shipment"
        }
        else if (["Delivered", "Stocked"].includes(purchaseOrder.state_label)) {
            statusBarLabel = "Delivered"
        }
    }

    return statusBarLabel
}

function getPurchaseOrderShippingPreference(purchaseOrder) {
    let preference

    if (["Pending", "Open"].includes(purchaseOrder.state_label)) {
        preference = purchaseOrder.preferred_shipping || "No Preference"
    }
    else {
        preference = purchaseOrder.shipping || <Fragment><span>--<br /></span><Banner type="info" text="No shipping details provided. Please reach out to the vendor for more information." /></Fragment>
    }

    return preference
}

function getPurchaseOrderTrackingDetails(purchaseOrder) {
    return purchaseOrder.tracking_number || <Fragment><span>--<br /></span><Banner type="info" text="No tracking number provided. Please reach out to the vendor for more information." /></Fragment>
}


export { calculatePurchaseOrderAmounts, getPurchaseOrderStatusBarLabel, getPurchaseOrderShippingPreference, getPurchaseOrderTrackingDetails };
