import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import { createContext, useEffect, useRef, useState } from "react"

import useIsDesktop from "@hooks/useIsDesktop"
import useUser from "@hooks/useUser"

import getImageType from "@utils/getImageType"

import { Button } from "@atoms/index"

import HiddenImageInput from "@molecules/Form/inputs/HiddenImageInput/HiddenImageInput"
import { HiddenImageInputRef, ImageDimensions } from "@molecules/Form/inputs/HiddenImageInput/HiddenImageInput.types"
import toast from "@molecules/Toast/Toast"

import AvatarManagementDialogTrigger from "@pages/Settings/SettingsProfile/components/AvatarManagementDialogTrigger/AvatarManagementDialogTrigger"

import { IMAGE_MAX_SIZE_IN_kB } from "@constants/avatarImageLimits"

import styles from "./AvatarManagement.module.scss"
import { useRemoveAvatarMutation, useUpdateAvatarMutation } from "./AvatarManagement.store"
import { AvatarFormatType, AvatarManagementContextValues } from "./AvatarManagement.types"
import { validateImageProperties } from "./AvatarManagement.utils"

export const AvatarManagementContext = createContext(null as AvatarManagementContextValues)
AvatarManagementContext.displayName = "AvatarManagement"

export default function AvatarManagement() {
    const { user, updateUser } = useUser()

    const [displayedAvatar, setDisplayedAvatar] = useState<string>("")
    const [imageToCrop, setImageToCrop] = useState<string>(displayedAvatar)
    const [imageFormatType, setImageFormatType] = useState<AvatarFormatType>(null)
    const [userHasUploadedAvatar, setUserHasUploadedAvatar] = useState<boolean>(false)
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

    const isDesktop = useIsDesktop()
    const fileInputRef = useRef<HiddenImageInputRef>(null)

    const {
        mutate: updateAvatarMutation,
        isError: isErrorOnUpload,
        isPending: isUploadingImage,
        isSuccess: isImageUploaded,
    } = useUpdateAvatarMutation()

    const {
        mutate: deleteUserAvatarMutation,
        isError: isErrorOnDelete,
        isPending: isRemovingImage,
        isSuccess: isImageRemoved,
    } = useRemoveAvatarMutation()

    useEffect(() => {
        if (isImageRemoved) {
            updateUser({ avatar: undefined })
            setDisplayedAvatar(user?.gravatar)
            setImageToCrop(null)
            setUserHasUploadedAvatar(false)

            toast({
                type: "success",
                size: "md",
                title: "Image removed",
            })
        }
    }, [isImageRemoved])

    useEffect(() => {
        if (isErrorOnDelete) {
            toast({
                type: "error",
                size: "md",
                title: "Could not remove image",
            })
        }
    }, [isErrorOnDelete])

    useEffect(() => {
        if (isImageUploaded) {
            updateUser({ avatar: displayedAvatar })
            setUserHasUploadedAvatar(true)

            toast({
                type: "success",
                size: "md",
                title: "Image uploaded",
            })
        }
    }, [isImageUploaded])

    useEffect(() => {
        if (isErrorOnUpload) {
            setDisplayedAvatar(null)

            toast({
                type: "error",
                size: "md",
                title: "Could not upload image",
            })
        }
    }, [isErrorOnUpload])

    useEffect(() => {
        const fetchAvatar = async () => {
            if (user) {
                const avatarUrl = user.avatar || user.gravatar
                setUserHasUploadedAvatar(!!user.avatar)

                if (avatarUrl) {
                    try {
                        const response = await fetch(avatarUrl)
                        if (response.ok) {
                            setDisplayedAvatar(response.url)
                            const imageType = await getImageType(response.url)
                            setImageFormatType(imageType as AvatarFormatType)
                        }
                    } catch (error) {
                        console.log("Error getting image type:", error)
                    }
                }
            }
        }
        void fetchAvatar()
    }, [user?.id])

    useEffect(() => {
        return () => {
            if (displayedAvatar) {
                URL.revokeObjectURL(displayedAvatar)
            }

            if (imageToCrop) {
                URL.revokeObjectURL(imageToCrop)
            }
        }
    }, [])

    const handleFileChange = (image: File, imageDimensions: ImageDimensions) => {
        const fileFormat = image.type as AvatarFormatType

        const validationResult = validateImageProperties(image, imageDimensions)

        if (validationResult.valid) {
            setImageFormatType(fileFormat)

            updateAvatarMutation({
                avatar: image,
            })

            const objectUrl = URL.createObjectURL(image)

            setImageToCrop(objectUrl)
            setDisplayedAvatar(objectUrl)

            setIsDialogOpen(true)
        } else {
            toast({
                type: "error",
                size: "md",
                title: validationResult.message,
            })
        }

        fileInputRef.current?.resetInput()
    }

    const handleDeleteAvatar = () => {
        deleteUserAvatarMutation()
    }

    const selectImageFromOS = () => {
        fileInputRef.current?.selectImageFromOS()
    }

    return (
        <AvatarManagementContext.Provider
            value={{
                imageToCrop,
                displayedAvatar,
                imageFormatType,
                isUploadingImage,
                userHasUploadedAvatar,
                setImageToCrop,
                selectImageFromOS,
                setDisplayedAvatar,
                setImageFormatType,
                updateAvatarMutation,
                deleteUserAvatarMutation,
                isDialogOpen,
                setIsDialogOpen,
            }}
        >
            <div className={styles.base}>
                <HiddenImageInput onFileChange={handleFileChange} ref={fileInputRef} />
                <AvatarManagementDialogTrigger />
                <div className={styles.buttons}>
                    <Button
                        leftIcon={icon({ name: "upload", family: "sharp", style: "solid" })}
                        colorScheme="gray"
                        variant="solid"
                        size={isDesktop ? "md" : "lg"}
                        onClick={selectImageFromOS}
                        isLoading={isUploadingImage}
                        loadingText="Uploading"
                    >
                        Upload
                    </Button>
                    {userHasUploadedAvatar && (
                        <Button
                            size={isDesktop ? "md" : "lg"}
                            variant="subtle"
                            colorScheme="tomato"
                            onClick={handleDeleteAvatar}
                            isLoading={isRemovingImage}
                            loadingText="Removing"
                        >
                            Remove
                        </Button>
                    )}
                </div>
                <div className={styles.text}>
                    Files must be <span className={styles.textHighlight}>under {IMAGE_MAX_SIZE_IN_kB}kB</span> and in{" "}
                    <span className={styles.textHighlight}>JPG, PNG, </span> or{" "}
                    <span className={styles.textHighlight}>WEBP</span> format.
                </div>
            </div>
        </AvatarManagementContext.Provider>
    )
}
