import Spinner from "@legacy/core/components/Spinner";
import { Component } from "react";
import deepcopy from "rfdc";
import { currencyFormatter, historyHasState, sendDataToServer } from "../core/utils/utils";
import VendorDetailsCard from "./components/VendorDetailsCard";
import VendorDeactivateForm from "./forms/VendorDeactivateForm";
import VendorReactivateForm from "./forms/VendorReactivateForm";

const PAGE_MODES = {
    VIEW_VENDOR: "VIEW_VENDOR",
    DEACTIVATE_VENDOR: "DEACTIVATE_VENDOR",
    REACTIVATE_VENDOR: "REACTIVATE_VENDOR"
}

const PAGE_MODE_SUBTITLES = {
    VIEW_VENDOR: "Vendor Details",
    DEACTIVATE_VENDOR: "Deactivate",
    REACTIVATE_VENDOR: "Reactivate"
}

const PAGE_MODE_BACK_BUTTON_DISPLAY = {
    VIEW_VENDOR: "flex",
    DEACTIVATE_VENDOR: "none",
    REACTIVATE_VENDOR: "none"
}

const PRIMARY_PAGE_MODES = [PAGE_MODES.VIEW_VENDOR]
const SECONDARY_PAGE_MODES = [
    PAGE_MODES.DEACTIVATE_VENDOR,
    PAGE_MODES.REACTIVATE_VENDOR
]

const FORM_DATA_NAMES_BY_MODE = {
    VIEW_VENDOR: "vendorData",
    DEACTIVATE_VENDOR: "deactivateData",
    REACTIVATE_VENDOR: "reactivateData"
}

const SUBMITTING_NAMES_BY_MODE = {
    VIEW_VENDOR: "submittingVendor",
    DEACTIVATE_VENDOR: "submittingDeactivate",
    REACTIVATE_VENDOR: "submittingReactivate"
}

const ERROR_NAMES_BY_MODE = {
    VIEW_VENDOR: "vendor",
    DEACTIVATE_VENDOR: "deactivate",
    REACTIVATE_VENDOR: "reactivate"
}


class VendorDetailsContainer extends Component {

    // Initialize

    constructor(props) {
        super(props)

        const defaultMode = this.props.formMode || PAGE_MODES.VIEW_VENDOR
        this.addToastToQueue = this.props.addToastToQueue

        this.state = {
            vendorData: null,

            deactivateData: {},
            reactivateData: {},

            errors: {
                deactivate: {},
                reactivate: {},
            },

            currencyCode: window.CURRENCY_CODE,
            languageCode: window.LANGUAGE_CODE,

            defaultMode: defaultMode,
            mode: defaultMode,

            returnScroll: 0
        }

        window.onpopstate = (event) => {
            if (event.state !== null && Object.keys(event.state).length) {
                this.setState(event.state)
            }
        }
    }

    componentDidMount = async () => {
        if (this.state.vendorData === null) {
            const vendorEndpoint = DjangoUrls["pricebook:api-vendors-detail"](window.MARKETPLACE_ENTITY_SLUG, window.VENDOR_ID)
            const vendorResponse = await fetch(vendorEndpoint)
            const vendor = await vendorResponse.json()

            this.setState((state, props) => {
                let updatedState = state
                updatedState.vendorData = vendor
                return updatedState
            })
        }

        if (historyHasState(history)) {
            if (document.querySelector(".page-subtitle")) {
                document.querySelector(".page-subtitle").innerHTML = PAGE_MODE_SUBTITLES[history.state.mode]
            }
            if (document.querySelector(".back-button")) {
                document.querySelector(".back-button").style.display = PAGE_MODE_BACK_BUTTON_DISPLAY[history.state.mode]
            }
            this.setState(history.state)
        }
    }

    // Form helpers

    updateFormData = (formName, fieldName, fieldValue) => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState[formName][fieldName] = fieldValue
            return updatedState
        })
    }

    switchFormMode = (mode) => {
        if (document.querySelector(".page-subtitle")) {
            document.querySelector(".page-subtitle").innerHTML = PAGE_MODE_SUBTITLES[mode]
        }
        if (document.querySelector(".back-button")) {
            document.querySelector(".back-button").style.display = PAGE_MODE_BACK_BUTTON_DISPLAY[mode]
        }

        if (SECONDARY_PAGE_MODES.includes(mode)) {
            history.replaceState(this.state, "", "")
        }

        this.setState((state, props) => {
            let updatedState = state
            updatedState.mode = mode
            history.pushState(updatedState, "", "?mode=" + mode.toLowerCase().replace(/_/g, "-"))
            return updatedState
        })
    }

    switchToPrimaryForm = () => {
        this.setState((state, props) => {
            let updatedState = state

            // Clear the secondary form data
            updatedState[FORM_DATA_NAMES_BY_MODE[state.mode]] = {}
            updatedState[SUBMITTING_NAMES_BY_MODE[state.mode]] = false
            updatedState.errors[ERROR_NAMES_BY_MODE[state.mode]] = {}

            return updatedState
        })
        this.switchFormMode(this.state.defaultMode)
    }

    switchToSecondaryForm = (newFormMode, data, initialData) => {
        this.setState((state, props) => {
            let updatedState = state
            // Set the scroll state
            updatedState.returnScroll = document.querySelector(".main").scrollTop

            updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]] = {}

            if (data !== null) {
                updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]] = deepcopy()(data)
            }

            if (initialData !== null) {
                Object.assign(updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]], initialData)
            }

            return updatedState
        })

        this.switchFormMode(newFormMode)
    }

    deactivateVendor = async () => {
        const dataName = "deactivateData"
        const submittingName = "submittingDeactivate"
        const errorDictName = "deactivate"

        const endpoint = DjangoUrls["pricebook:api-vendors-deactivate"](window.MARKETPLACE_ENTITY_SLUG, this.state.vendorData.id)
        const successUrl = DjangoUrls["pricebook:vendor-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.vendorData.id)

        const onSuccess = () => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Vendor "${this.state.vendorData.name}" deactivated`,
                path: successUrl,
                barePathOnly: true,
                delayRender: true
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Vendor could not be deactivated"
            })
        }

        await sendDataToServer(this, endpoint, "POST", dataName, submittingName, errorDictName, onSuccess, onError, undefined, undefined)
    }

    reactivateVendor = async () => {
        const dataName = "reactivateData"
        const submittingName = "submittingReactivate"
        const errorDictName = "reactivate"

        const endpoint = DjangoUrls["pricebook:api-vendors-reactivate"](window.MARKETPLACE_ENTITY_SLUG, this.state.vendorData.id)
        const successUrl = DjangoUrls["pricebook:vendor-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.vendorData.id)

        const onSuccess = () => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Vendor "${this.state.vendorData.name}" reactivated`,
                path: successUrl,
                barePathOnly: true,
                delayRender: true
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Vendor could not be reactivated"
            })
        }

        await sendDataToServer(this, endpoint, "POST", dataName, submittingName, errorDictName, onSuccess, onError, undefined, undefined)
    }

    // Handle Actions

    handleActionRequest = (action) => {
        switch (action) {
            case "VENDOR_EDIT":
                location.assign(DjangoUrls["pricebook:vendor-update"](window.MARKETPLACE_ENTITY_SLUG, this.state.vendorData.id))
                break
            case "VENDOR_REACTIVATE":
                this.switchToSecondaryForm("REACTIVATE_VENDOR", null, null)
                break
            case "VENDOR_REACTIVATE_SUBMIT":
                this.reactivateVendor()
                break
            case "VENDOR_DEACTIVATE":
                this.switchToSecondaryForm("DEACTIVATE_VENDOR", null, null)
                break
            case "VENDOR_DEACTIVATE_SUBMIT":
                this.deactivateVendor()
                break
            default:
                console.error(`No action handler exists for action "${action}".`)
        }
    }

    // Render

    render() {
        if (this.state.vendorData === null) {
            return <Spinner centered={true} />
        }
        else {
            if (PRIMARY_PAGE_MODES.includes(this.state.mode)) {
                return <VendorDetailsCard
                    vendor={this.state.vendorData}
                    requestAction={this.handleActionRequest}
                    switchToSecondaryForm={this.switchToSecondaryForm}
                    formatCurrencyValue={currencyFormatter(this.state.currencyCode, this.state.languageCode)}
                    returnScroll={this.state.returnScroll}
                ></VendorDetailsCard>
            }
            else if (this.state.mode === PAGE_MODES.REACTIVATE_VENDOR) {
                return <VendorReactivateForm
                    vendor={this.state.vendorData}
                    reactivateData={this.state.reactivateData}
                    requestAction={this.handleActionRequest}
                    switchToPrimaryForm={this.switchToPrimaryForm}
                    submitting={this.state.submittingReactivate}
                    errors={this.state.errors.reactivate}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("reactivateData", fieldName, fieldValue)}
                    returnScroll={0}
                ></VendorReactivateForm>
            }
            else if (this.state.mode === PAGE_MODES.DEACTIVATE_VENDOR) {
                return <VendorDeactivateForm
                    vendor={this.state.vendorData}
                    deactivateData={this.state.deactivateData}
                    requestAction={this.handleActionRequest}
                    switchToPrimaryForm={this.switchToPrimaryForm}
                    submitting={this.state.submittingDeactivate}
                    errors={this.state.errors.deactivate}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("deactivateData", fieldName, fieldValue)}
                    returnScroll={0}
                ></VendorDeactivateForm>
            }
            else {
                return (
                    <div className="data-panel-container data-panel-container--with-margin">
                        <div className="data-panel" aria-label="Unknown Form Mode">
                            <div className="data-panel__form">
                                <p className="data-panel__form__caption">An unhandled form mode was supplied.</p>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    }
}

export default VendorDetailsContainer;
