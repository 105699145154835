// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SettingsSideBar-backButtonAndTitle__waeuN{display:flex;gap:var(--size-2);align-items:center;padding:var(--size-4) var(--size-3) var(--size-0) var(--size-3)}.SettingsSideBar-settingsTitle__FQtGq{margin-bottom:0;font:var(--font-global-heading-04);font-feature-settings:var(--feature-settings-global-heading-04);letter-spacing:var(--letter-spacing-global-heading-04)}.SettingsSideBar-settingsNavigation__cTkv4{margin-bottom:auto}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backButtonAndTitle": "SettingsSideBar-backButtonAndTitle__waeuN",
	"settingsTitle": "SettingsSideBar-settingsTitle__FQtGq",
	"settingsNavigation": "SettingsSideBar-settingsNavigation__cTkv4"
};
export default ___CSS_LOADER_EXPORT___;
