import React, { Component } from 'react';
import AdvancedMultiSelectField from '../../core/fields/AdvancedMultiSelectField';
import { tintColor } from '../../core/utils/utils';


class AssignedTechniciansField extends Component {
    technicianColorOverrides = {
        option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
            ...styles,
            backgroundColor: isDisabled ? null : isSelected ? data.color : isFocused ? `${tintColor(data.object.color, 0.8)} !important` : null,
            color: isDisabled ? "#ccc" : `${data.object.color} !important`,
            borderLeft: `var(--border-indentation-size) solid ${data.object.color}`,
        }),
        multiValue: (styles, { data }) => ({
            ...styles,
            backgroundColor: `${tintColor(data.object.color, 0.8)} !important`,
        }),
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: `${data.object.color} !important`,
        }),
    }

    render() {
        return <AdvancedMultiSelectField {...this.props} styles={this.technicianColorOverrides}></AdvancedMultiSelectField>
    }
}

export default AssignedTechniciansField;
