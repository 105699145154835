export function ifSpaceOrEnter<T extends HTMLElement>(
    event: React.KeyboardEvent<T>,
    callback: VoidFunction | ((e: React.KeyboardEvent<T> | React.MouseEvent<T>) => void),
) {
    const isSpaceOrEnter = ["Enter", "NumpadEnter", "Space"].includes(event.code)

    if (isSpaceOrEnter) {
        event.preventDefault()
        callback?.(event)
    }
}
