import { Component, Fragment } from "react";
import ButtonGroup from "../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../core/buttons/UniversalButton";
import HorizontalLayout from "../../core/components/HorizontalLayout";
import BooleanField from "../../core/fields/BooleanField";
import CharField from "../../core/fields/CharField";
import PriceField from "../../core/fields/PriceField";
import SearchCreateSelectField from "../../core/fields/SearchCreateSelectField";
import VendorSearchOrCreateSelect from "../inputs/VendorSearchOrCreateSelect";


class InlineVendorPartCostForm extends Component {

    constructor(props) {
        super(props)

        this.state = {
            vendorSearchInput: "",

            selectedVendor: null,
        }
    }

    renderButtons = () => {
        const { vendorPartCost, isNewAddition, onFormDataChange, requestAction, currencySymbol } = this.props

        return (
            <ButtonGroup>
                <ButtonGroupRow>
                    <UniversalButton type="primary" text="Save" handler={event => requestAction("VENDOR_PART_COST_SAVE")} />
                </ButtonGroupRow>
                {
                    isNewAddition
                    ?
                    <ButtonGroupRow>
                        <UniversalButton type="secondary" text="Cancel" handler={event => requestAction("VENDOR_PART_COST_DELETE")} />
                    </ButtonGroupRow>
                    :
                    <Fragment>
                        <ButtonGroupRow>
                            <UniversalButton type="secondary" text="Cancel Edits" handler={event => requestAction("VENDOR_PART_COST_CANCEL_EDITS")} />
                        </ButtonGroupRow>
                        <ButtonGroupRow>
                            <UniversalButton type="danger" text="Delete Vendor Cost" handler={event => requestAction("VENDOR_PART_COST_DELETE")} />
                        </ButtonGroupRow>
                    </Fragment>
                }
            </ButtonGroup>
        )
    }

    handleSelectionChange = (selectedOption) => {
        const { vendorPartCost, onFormDataChange } = this.props

        if (selectedOption !== null) {
            if (selectedOption.object.id !== vendorPartCost.vendor) {
                const vendor = selectedOption.object

                onFormDataChange("vendor", vendor.id)
                onFormDataChange("vendor_name", vendor.name)
            }
        }
        else {
            onFormDataChange("vendor", "")
            onFormDataChange("vendor_name", "")
        }
    }

    render() {
        const { vendorPartCost, isNewAddition, onFormDataChange, requestAction, currencySymbol } = this.props
        const errors = vendorPartCost.errors || {}

        return (
            <div className="data-panel__list-select__form">
                <div className="data-panel__form" aria-label="Vendor Part Cost Edit Form">
                    {errors.non_field_error && <div className="data-panel__form__field__errors" aria-label="Non Field Error">{errors.non_field_error}</div>}
                    <SearchCreateSelectField
                        fieldName="vendor"
                        fieldLabel="Vendor"
                        fieldValue={vendorPartCost.vendor_name || null}
                        inputComponent={
                            <VendorSearchOrCreateSelect
                                onSelectionChange={selectedOption => this.handleSelectionChange(selectedOption)}
                                onInputChange={(input, action) => {action.action === "input-change" && this.setState({vendorSearchInput: input})}}
                                defaultSelected={vendorPartCost ? {value: vendorPartCost.vendor, label: vendorPartCost.vendor_name} : null}
                                showCreateButton={false}
                            ></VendorSearchOrCreateSelect>
                        }
                        showButton={false}
                        errors={errors}
                    ></SearchCreateSelectField>
                    <HorizontalLayout>
                        <PriceField
                            fieldName="vendor_cost"
                            fieldLabel="Vendor Cost"
                            fieldValue={vendorPartCost.vendor_cost || ""}
                            fieldOnChange={vendor_cost => onFormDataChange("vendor_cost", vendor_cost || 0)}
                            currencySymbol={currencySymbol}
                            errors={errors}
                        ></PriceField>
                        <CharField
                            fieldName="vendor_part_number"
                            fieldLabel="Vendor Part Number"
                            fieldValue={vendorPartCost.vendor_part_number || ""}
                            fieldOnChange={vendor_part_number => onFormDataChange("vendor_part_number", vendor_part_number || "")}
                            maxLength={100}
                            errors={errors}
                        ></CharField>
                    </HorizontalLayout>
                    <BooleanField
                        fieldName="preferred"
                        fieldLabel="Preferred"
                        fieldValue={vendorPartCost.preferred || false}
                        fieldOnChange={preferred => onFormDataChange("preferred", preferred)}
                        inputLabel="Preferred Vendor"
                        errors={errors}
                    ></BooleanField>
                </div>
                {this.renderButtons()}
            </div>
        )
    }
}

export default InlineVendorPartCostForm;
