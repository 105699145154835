import { atom, useAtom } from "jotai"
import { atomWithStorage } from "jotai/utils"
import { useEffect, useMemo } from "react"

import getObjectViewConfigStorageKey, { GetObjectViewConfigStorageKeyArgs } from "@utils/getObjectViewConfigStorageKey"
import mapTableInvisibleColumns from "@utils/mapTableInvisibleColumns"
import mapTableVisibleColumns from "@utils/mapTableVisibleColumns"

import { ObjectsViewType } from "@organisms/ObjectsView/ObjectsView.types"
import { ColumnId, ColumnSort } from "@organisms/Table/Table.types"

import defaultViewConfigs from "@constants/defaultViewConfigs"

const objectsViewAtom = atomWithStorage<typeof defaultViewConfigs>("objectsView", defaultViewConfigs)
const currentObjectsViewStorageKeyAtom = atom<string>("")
const currentObjectsViewTypeAtom = atom<ObjectsViewType>("table")
const currentObjectTypeAtom = atom<ObjectType>("estimate")
const reachedPinLimitAtom = atom<boolean>(false)
const sortingChangedAtom = atom<boolean>(false)

type UseCurrentObjectsViewOptions = GetObjectViewConfigStorageKeyArgs

const MINIMUM_OF_COLUMNS_VISIBLE = 1

export default function useCurrentObjectsView(options?: UseCurrentObjectsViewOptions) {
    const [allObjectsView, setAllObjectsView] = useAtom(objectsViewAtom)
    const [currentObjectsViewKey, setCurrentObjectsViewKey] = useAtom(currentObjectsViewStorageKeyAtom)
    const [currentObjectsViewType, setCurrentObjectsViewType] = useAtom(currentObjectsViewTypeAtom)
    const [currentObjectType, setCurrentObjectType] = useAtom(currentObjectTypeAtom)
    const [reachedPinLimit, setReachedPinLimit] = useAtom(reachedPinLimitAtom)
    const [sortingChanged, setSortingChanged] = useAtom(sortingChangedAtom)

    const viewStorageKey = options ? getObjectViewConfigStorageKey(options) : undefined

    const currentObjectsView = useMemo(() => {
        const storedConfig =
            allObjectsView[options?.viewType || currentObjectsViewType]?.[viewStorageKey || currentObjectsViewKey]

        if (storedConfig) {
            return storedConfig
        } else {
            const defaultConfig =
                defaultViewConfigs[options?.viewType || currentObjectsViewType][
                    viewStorageKey || currentObjectsViewKey
                ]
            return defaultConfig
        }
    }, [
        allObjectsView,
        currentObjectsViewKey,
        currentObjectsViewType,
        options?.viewType,
        viewStorageKey,
        defaultViewConfigs,
    ])

    useEffect(() => {
        const currentColumnPinning = currentObjectsView?.columnPinning?.left || []
        setReachedPinLimit(currentColumnPinning.length >= 3)
    }, [currentObjectsView?.columnPinning?.left])

    useEffect(() => {
        if (options?.objectType) {
            setCurrentObjectType(options.objectType)
        }
    }, [options?.objectType])

    useEffect(() => {
        if (options?.viewType) {
            setCurrentObjectsViewType(options.viewType)
        }
    }, [options?.viewType])

    useEffect(() => {
        if (viewStorageKey) {
            setCurrentObjectsViewKey(viewStorageKey)
        }
    }, [viewStorageKey])

    const updateCurrentObjectsView = (newView: ObjectsViewConfig) => {
        setAllObjectsView((prevViews) => ({
            ...prevViews,
            [currentObjectsViewType]: {
                ...prevViews[currentObjectsViewType],
                [currentObjectsViewKey]: newView,
            },
        }))
    }

    const updateCurrentObjectsViewSorting = (
        newSorting: ColumnSort[] | ((oldSorting: ColumnSort[]) => ColumnSort[]),
    ) => {
        if (typeof newSorting === "function") {
            newSorting = newSorting(currentObjectsView.sorting)
        }

        setAllObjectsView((prevViews) => {
            return {
                ...prevViews,
                [currentObjectsViewType]: {
                    ...prevViews[currentObjectsViewType],
                    [currentObjectsViewKey]: {
                        ...currentObjectsView,
                        sorting: newSorting,
                    },
                },
            }
        })

        setSortingChanged(true)
    }

    const updateColumnVisibility = (columnId: ColumnId, visibility: boolean) => {
        const newColumnVisibility = {
            [columnId]: visibility,
        }

        const columnVisibility = {
            ...currentObjectsView.columnVisibility,
            ...newColumnVisibility,
        }

        const columnIsPinned = currentObjectsView.columnPinning?.left?.includes(columnId)

        if (columnIsPinned) {
            unpinColumn(columnId)
        }

        setAllObjectsView((prevViews) => {
            return {
                ...prevViews,
                [currentObjectsViewType]: {
                    ...prevViews[currentObjectsViewType],
                    [currentObjectsViewKey]: {
                        ...currentObjectsView,
                        columnVisibility,
                    },
                },
            }
        })
    }

    const unpinColumn = (columnId: ColumnId) => {
        const currentColumnPinning = currentObjectsView.columnPinning.left

        const index = currentColumnPinning.indexOf(columnId)
        currentColumnPinning.splice(index, 1)

        const columnPinning = {
            ...currentObjectsView.columnPinning,
            left: currentColumnPinning,
        }

        setAllObjectsView((prevViews) => {
            return {
                ...prevViews,
                [currentObjectsViewType]: {
                    ...prevViews[currentObjectsViewType],
                    [currentObjectsViewKey]: {
                        ...currentObjectsView,
                        columnPinning,
                    },
                },
            }
        })
    }

    const pinColumn = (columnId: ColumnId) => {
        const currentColumnPinning = currentObjectsView.columnPinning.left

        currentColumnPinning.push(columnId)

        const columnPinning = {
            ...currentObjectsView.columnPinning,
            left: currentColumnPinning,
        }

        setAllObjectsView((prevViews) => {
            return {
                ...prevViews,
                [currentObjectsViewType]: {
                    ...prevViews[currentObjectsViewType],
                    [currentObjectsViewKey]: {
                        ...currentObjectsView,
                        columnPinning,
                    },
                },
            }
        })
    }

    const updateColumnPinningOrder = (newColumnPinningOrder: ColumnId[]) => {
        setAllObjectsView((prevViews) => {
            return {
                ...prevViews,
                [currentObjectsViewType]: {
                    ...prevViews[currentObjectsViewType],
                    [currentObjectsViewKey]: {
                        ...currentObjectsView,
                        columnPinning: {
                            left: newColumnPinningOrder,
                        },
                    },
                },
            }
        })
    }

    const updateColumnOrder = (newColumnOrder: ColumnId[]) => {
        setAllObjectsView((prevViews) => {
            return {
                ...prevViews,
                [currentObjectsViewType]: {
                    ...prevViews[currentObjectsViewType],
                    [currentObjectsViewKey]: {
                        ...currentObjectsView,
                        columnOrder: newColumnOrder,
                    },
                },
            }
        })
    }

    const visibleColumns = useMemo(() => {
        return mapTableVisibleColumns({
            columnIds: currentObjectsView?.columnOrder,
            columnVisibility: currentObjectsView?.columnVisibility,
            columnPinning: currentObjectsView?.columnPinning,
        })
    }, [currentObjectsView?.columnOrder, currentObjectsView?.columnVisibility, currentObjectsView?.columnPinning])

    const invisibleColumns = useMemo(() => {
        return mapTableInvisibleColumns({
            columnIds: currentObjectsView?.columnOrder,
            columnVisibility: currentObjectsView?.columnVisibility,
            objectType: currentObjectType,
        })
    }, [currentObjectsView?.columnOrder, currentObjectsView?.columnVisibility, currentObjectType])

    const reachedMinimumOfColumnsVisible = useMemo(() => {
        return visibleColumns.allColumns.length <= MINIMUM_OF_COLUMNS_VISIBLE
    }, [visibleColumns])

    return {
        isLoading: !currentObjectsView,
        currentObjectsView,
        updateCurrentObjectsView,
        updateCurrentObjectsViewSorting,
        updateColumnVisibility,
        updateColumnOrder,
        pinColumn,
        unpinColumn,
        updateColumnPinningOrder,
        currentObjectsViewKey,
        currentObjectsViewType,
        currentObjectType,
        reachedPinLimit,
        reachedMinimumOfColumnsVisible,
        visibleColumns,
        invisibleColumns,
        sortingChanged,
        setSortingChanged,
    }
}
