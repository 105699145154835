import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import { useRef } from "react"

import useUser from "@hooks/useUser"

import { BottomSheet } from "@organisms"
import styles from "@organisms/UserProfileButton/ProfileBottomSheet/ProfileBottomSheet.module.scss"

export default function ProfileBottomSheetWhatsNew() {
    const ref = useRef<HTMLDivElement>(null)

    const { user } = useUser()

    const openChangelog = () => {
        ref.current?.click()
    }

    return (
        <div
            ref={ref}
            onClick={openChangelog}
            id="changelog_trigger"
            data-canny-changelog={true}
            className={styles.bottomSheetItem}
        >
            <BottomSheet.Item
                onClick={openChangelog}
                icon={icon({ name: "bullhorn", style: "light", family: "sharp" })}
                label={"What's New"}
            />

            <div id="changelog_anchor" aria-hidden="true" />
            <>
                {window.Canny("initChangelog", {
                    appID: window?.cannyAppID,
                    ssoToken: user?.canny_sso_token,
                    position: "right",
                    align: "bottom",
                    theme: "auto",
                })}
            </>
        </div>
    )
}
