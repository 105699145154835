import React, { Component, Fragment } from "react";
import ButtonGroup from "../../../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../../../core/buttons/UniversalButton";
import { serviceLocationAddressASC } from "../utils/sorters";
import QuickBooksServiceLocationDisplay from "./QuickBooksServiceLocationDisplay";


class QuickBooksServiceLocationListDisplay extends Component {

    renderServiceLocationsWithoutErrors(record, serviceLocations) {
        const { showAllServiceLocations, toggleShowAllServiceLocations } = this.props
        const serviceLocationsCount = serviceLocations.length

        if (showAllServiceLocations || serviceLocationsCount <= 10) {
            return serviceLocations.sort(serviceLocationAddressASC).map((serviceLocationRecord, index) => <QuickBooksServiceLocationDisplay key={`service_location_${index}`} record={record} serviceLocationRecord={serviceLocationRecord} />)
        }
        else {
            return (
                <ButtonGroup>
                    <ButtonGroupRow>
                        <UniversalButton type="secondary" text={`Show ${serviceLocationsCount} Service Locations`} handler={event => toggleShowAllServiceLocations()} />
                    </ButtonGroupRow>
                </ButtonGroup>
            )
        }
    }

    renderServiceLocationsWithErrors(record, serviceLocations) {
        return serviceLocations.sort(serviceLocationAddressASC).map((serviceLocationRecord, index) => <QuickBooksServiceLocationDisplay key={`service_location_${index}`} record={record} serviceLocationRecord={serviceLocationRecord} />)
    }

    renderServiceLocations = (record) => {
        if (record.child_has_errors === false) {
            return this.renderServiceLocationsWithoutErrors(record, record.children)
        }
        else {
            let childrenWithErrors = []
            let childrenWithoutErrors = []

            // Partition records by whether or not they have errors
            let existingIDs = new Set()
            record.children.forEach(serviceLocationRecord => {
                if (!existingIDs.has(serviceLocationRecord.id)) {
                    if (Object.keys(serviceLocationRecord.errors).length !== 0) {
                        childrenWithErrors.push(serviceLocationRecord)
                    }
                    else {
                        childrenWithoutErrors.push(serviceLocationRecord)
                    }

                    existingIDs.add(serviceLocationRecord.id)
                }
            })

            return (
                <Fragment>
                    {this.renderServiceLocationsWithErrors(record, childrenWithErrors)}
                    {this.renderServiceLocationsWithoutErrors(record, childrenWithoutErrors)}
                </Fragment>
            )
        }
    }

    render = () => {
        const { record } = this.props

        return (
            <div className="data-panel__form__field" aria-label="Service Locations">
                <span className="data-panel__form__field__label" aria-hidden="true">Service Locations</span>
                <div className="data-panel__form__field__display-list" aria-label="Service Locations">
                    {
                        record.children === undefined || record.children.length === 0
                        ?
                        "(No service locations)"
                        :
                        this.renderServiceLocations(record)
                    }
                </div>
            </div>
        )
    }
}

export default QuickBooksServiceLocationListDisplay;
