import { Fragment } from "react";
import SearchOrCreateSelectInput from "../../core/inputs/SearchOrCreateSelectInput";


function getEquipmentObjectLabel(equipment) {
    return <Fragment>
        <span><strong>{equipment.location ? `[${equipment.location}] ` : ""}{equipment.display_name}</strong></span>
        <span className="select-label-secondary-level"><br></br>
        {(equipment.manufacturer || equipment.model_number) ? <>{`${equipment.manufacturer} ${equipment.model_number}`}<br></br></> : null}
        {`Warranty: ${equipment.warranty_status}`}</span>
    </Fragment>
}

function equipmentToEquipmentOption(equipment) {
    return {value: equipment.id, label: getEquipmentObjectLabel(equipment), object: equipment}
}

function EquipmentSearchOrCreateSelect(props) {
    const {serviceLocation, extraKey} = props

    return <SearchOrCreateSelectInput
        renderKey={`equipment_select_${serviceLocation.id}`}
        objectName="Equipment"
        getObjectLabel={getEquipmentObjectLabel}
        listEndpoint={DjangoUrls["equipment:api-service-location-equipment-lightweight-list"](window.MARKETPLACE_ENTITY_SLUG, serviceLocation.id)}
        listEndpointKwargs={[]}
        getDetailsEndpoint={(equipment) => DjangoUrls["equipment:api-service-location-equipment-detail"](window.MARKETPLACE_ENTITY_SLUG, serviceLocation.id, equipment.id)}
        {...props}
    />
}

export { equipmentToEquipmentOption };
export default EquipmentSearchOrCreateSelect
