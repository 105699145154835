// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TimelineViewEventCardIcon-base__C1uvf{display:flex;flex-shrink:0;align-items:center;justify-content:center;width:var(--size-6);height:var(--size-6)}.TimelineViewEventCardIcon-scheduled__xNZkv{color:var(--blue-9)}.TimelineViewEventCardIcon-active__gLXuB{color:var(--jade-9)}.TimelineViewEventCardIcon-paused__GdJMY{color:var(--amber-9)}.TimelineViewEventCardIcon-pastDue__fXPZe{color:var(--tomato-9)}.TimelineViewEventCardIcon-onHold__l_WwK{color:var(--orange-11)}.TimelineViewEventCardIcon-onHoldInternal__yka9p{color:var(--amber-11)}.TimelineViewEventCardIcon-onHoldExternal__1xcW2{color:var(--gray-12)}.TimelineViewEventCardIcon-completed__bA8CJ{color:var(--orange-9)}.TimelineViewEventCardIcon-finalized__Wdm9F{color:var(--gray-8)}.TimelineViewEventCardIcon-cancelled__bDFma{color:var(--gray-8)}.TimelineViewEventCardIcon-pending__FVtRg{color:var(--violet-9)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "TimelineViewEventCardIcon-base__C1uvf",
	"scheduled": "TimelineViewEventCardIcon-scheduled__xNZkv",
	"active": "TimelineViewEventCardIcon-active__gLXuB",
	"paused": "TimelineViewEventCardIcon-paused__GdJMY",
	"pastDue": "TimelineViewEventCardIcon-pastDue__fXPZe",
	"onHold": "TimelineViewEventCardIcon-onHold__l_WwK",
	"onHoldInternal": "TimelineViewEventCardIcon-onHoldInternal__yka9p",
	"onHoldExternal": "TimelineViewEventCardIcon-onHoldExternal__1xcW2",
	"completed": "TimelineViewEventCardIcon-completed__bA8CJ",
	"finalized": "TimelineViewEventCardIcon-finalized__Wdm9F",
	"cancelled": "TimelineViewEventCardIcon-cancelled__bDFma",
	"pending": "TimelineViewEventCardIcon-pending__FVtRg"
};
export default ___CSS_LOADER_EXPORT___;
