import { memo } from "react"
import { useTimer } from "react-timer-hook"

import { CountDownProps } from "./CountDown.types"

function CountDown(props: CountDownProps) {
    const { seconds, onExpire } = props

    const expiryTimestamp = new Date()
    expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + seconds)

    const { seconds: currentSeconds, minutes } = useTimer({ expiryTimestamp, onExpire })

    return (
        <>
            {minutes}:{currentSeconds}
        </>
    )
}

export default memo(CountDown)
