// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UserContactsForms-base__ap_GG{display:flex;flex-direction:column;gap:var(--size-3);align-items:flex-start;align-self:stretch}.UserContactsForms-forms__SQqGC{display:flex;flex-direction:column;gap:var(--size-6);align-items:flex-start;align-self:stretch}@media only screen and (min-width: 64rem){.UserContactsForms-forms__SQqGC{flex-direction:row;gap:var(--size-10)}}.UserContactsForms-disclaimer__AxsUN{color:var(--gray-a11);font:var(--font-mobile-caption-medium-375);font-feature-settings:var(--feature-settings-mobile-caption-medium-375);letter-spacing:var(--letter-spacing-mobile-caption-medium-375)}@media only screen and (min-width: 64rem){.UserContactsForms-disclaimer__AxsUN{font:var(--font-global-caption-medium-375);font-feature-settings:var(--feature-settings-global-caption-medium-375);letter-spacing:var(--letter-spacing-global-caption-medium-375)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "UserContactsForms-base__ap_GG",
	"forms": "UserContactsForms-forms__SQqGC",
	"disclaimer": "UserContactsForms-disclaimer__AxsUN"
};
export default ___CSS_LOADER_EXPORT___;
