import { Component, Fragment } from "react";
import { PaymentMethods } from "../utils/enums";
import { formatCurrencyForServiceCompany, formatLocalTime } from "../utils/utils";


class PaymentListDisplay extends Component {

    render = () => {
        const {fieldId, fieldName, object, payments} = this.props

        return (
            <div id={fieldId} className="data-panel__form__field__display-list" aria-label="Payment List">
                {payments.map(
                    (item, index) => {
                        return (
                            <div key={index} className={"data-panel__form__field__display-list__object payment-display" + (item.is_online_payment ? " online-payment-card" : "") + (item.from_down_payment ? " down-payment-card" : "") + (item.is_uncollectible ? " uncollectible-payment-card" : "")} aria-label="Payment">
                                {item.is_uncollectible && <div className="uncollectible-designator"><span>Uncollectible / Bad Debt</span></div>}
                                {item.is_online_payment && <div className="stripe-designator"><i className="fa-brands fa-stripe inline-stripe" aria-label="Stripe"></i><span>Online Card Payment{item.from_down_payment !== null ? " from Estimate" : ""}</span></div>}
                                {!item.is_online_payment && item.from_down_payment !== null && <div className="down-payment-designator"><span>From Estimate</span></div>}
                                <div className="payment-display__row">
                                    <div className="payment-display__field" aria-label="Payment Method">
                                        <div className="payment-display__field-label" aria-hidden="true">Payment Method</div>
                                        <div className="payment-display__field-value" aria-label="Payment Method">{item.payment_method === PaymentMethods.other && item.payment_method_other_name ? item.payment_method_other_name : item.payment_method_label}</div>
                                    </div>
                                    <div className="payment-display__field" aria-label="Amount">
                                        <div className="payment-display__field-label" aria-hidden="true">Amount</div>
                                        <div className="payment-display__field-value" aria-label="Amount">{item.amount ? formatCurrencyForServiceCompany(item.amount * -1, object.service_company) : "--"}</div>
                                    </div>
                                </div>
                                <div className="payment-display__row">
                                    <div className="payment-display__field" aria-label="Payment Date">
                                        <div className="payment-display__field-label" aria-hidden="true">Payment Date</div>
                                        <div className="payment-display__field-value" aria-label="Payment Date">{formatLocalTime(item.payment_date, "UTC", true, false, false, true).localDateString}</div>
                                    </div>
                                </div>
                                {
                                    item.is_online_payment
                                    ?
                                    <div className="payment-display__row">
                                        <div className="payment-display__field">
                                            <div className="payment-display__field-label">Reference ID</div>
                                            <div className="payment-display__field-value">{item.stripe_charge}</div>
                                        </div>
                                    </div>
                                    :
                                    <Fragment>
                                        {
                                            item.reference_id !== "" && (
                                                <div className="payment-display__row">
                                                    <div className="payment-display__field">
                                                        <div className="payment-display__field-label">Reference ID</div>
                                                        <div className="payment-display__field-value">{item.reference_id}</div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </Fragment>
                                }
                                {
                                    item.payment_memo !== "" && (
                                        <div className="payment-display__row">
                                            <div className="payment-display__field">
                                                <div className="payment-display__field-label">Memo</div>
                                                <div className="payment-display__field-value">{item.payment_memo}</div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        )
                    }
                )}
            </div>
        )
    }
}

export default PaymentListDisplay;
