import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import { Toaster as SonnerToaster, toast as sonnerToast } from "sonner"

import { Button, Icon, IconButton } from "@atoms"

import styles from "./Toast.module.scss"
import { ToastProps } from "./Toast.types"

export default function toast(props: ToastProps) {
    const { type, size, title, subtitle, cta, secondaryCta } = props

    return sonnerToast.custom((t) => {
        const iconMap: { [key in ToastProps["type"]]: IconProp } = {
            normal: icon({ name: "question-circle", style: "solid", family: "sharp" }),
            info: icon({ name: "info-circle", style: "solid", family: "sharp" }),
            success: icon({ name: "check-circle", style: "solid", family: "sharp" }),
            warning: icon({ name: "warning", style: "solid", family: "sharp" }),
            error: icon({ name: "circle-exclamation", style: "solid", family: "sharp" }),
            loading: icon({ name: "spinner-third", style: "solid", family: "sharp" }),
        }
        const toastIcon = iconMap[type] || icon({ name: "circle", style: "solid", family: "sharp" })

        return (
            <>
                <div className={styles.headerWrapper}>
                    <div className={styles.iconAndTitleWrapper}>
                        <Icon size={16} icon={toastIcon} className={styles[`${type}Icon`]} spin={type === "loading"} />
                        <div className={styles.title} data-title="">
                            {title}
                        </div>
                    </div>
                    <div className={styles.buttonContainer}>
                        {size === "md" && cta && <Button {...cta} colorScheme="gray" size="sm" variant="subtle" />}
                        <IconButton
                            colorScheme="gray"
                            size="sm"
                            variant="ghost"
                            className={styles.closeButton}
                            icon={icon({ name: "close", style: "regular", family: "sharp" })}
                            onClick={() => sonnerToast.dismiss(t)}
                            noDebounce={true}
                        />
                    </div>
                </div>
                {size === "lg" && (
                    <div className={styles.bodyContainer}>
                        {subtitle && (
                            <div data-description="" className={styles.subtitle}>
                                {subtitle}
                            </div>
                        )}
                        {
                            <div className={styles.ctaContainer}>
                                {cta && <Button {...cta} colorScheme="gray" size="md" variant="subtle" />}
                                {secondaryCta && (
                                    <Button {...secondaryCta} colorScheme="gray" size="md" variant="ghost" />
                                )}
                            </div>
                        }
                    </div>
                )}
            </>
        )
    })
}

export function Toaster() {
    return (
        <SonnerToaster
            closeButton={true}
            className={styles.toaster}
            offset="1rem"
            position="bottom-right"
            toastOptions={{
                classNames: {
                    toast: styles.toastBase,
                },
            }}
        />
    )
}
