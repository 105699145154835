import { ColumnId } from "@organisms/Table/Table.types"

import getTableSortingIcon from "./getTableSortingIcon"

export default function getTableSortingIconsPairByColumnId(columnId: ColumnId) {
    const asc = getTableSortingIcon({
        columnId,
        ascending: true,
    })

    const desc = getTableSortingIcon({
        columnId,
        ascending: false,
    })

    return {
        asc,
        desc,
    }
}
