// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PopoverOrderColumns-base__ps_5r{display:flex;flex-direction:column;max-height:inherit}.PopoverOrderColumns-addColumnButton__WC4Ca{position:relative;padding:var(--size-1_5) var(--size-2_5)}.PopoverOrderColumns-addColumnButton__WC4Ca::before{position:absolute;top:var(--size-0);right:var(--size-0);left:var(--size-0);display:block;width:var(--size-full);height:var(--size-0_25);background-color:var(--gray-3);content:\"\"}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "PopoverOrderColumns-base__ps_5r",
	"addColumnButton": "PopoverOrderColumns-addColumnButton__WC4Ca"
};
export default ___CSS_LOADER_EXPORT___;
