// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PopoverSortingColumns-base__WZIVz{display:flex;gap:var(--size-2);align-items:center;padding:var(--size-0) var(--size-3_5) var(--size-2) var(--size-3_5)}.PopoverSortingColumns-droplist__r8RDW{width:var(--size-44)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "PopoverSortingColumns-base__WZIVz",
	"droplist": "PopoverSortingColumns-droplist__r8RDW"
};
export default ___CSS_LOADER_EXPORT___;
