import clsx from "clsx"

import { AlertBanner } from "@atoms/index"

import styles from "./PageHeader.module.scss"
import { PageHeaderProps } from "./PageHeader.types"

export default function PageHeader(props: PageHeaderProps) {
    const { title, subtitle, infoMessage, variant } = props

    return (
        <div
            className={clsx(styles.base, {
                [styles.pageTitle]: variant === "page-heading",
                [styles.sectionTitle]: variant === "section-heading",
            })}
        >
            <div className={styles.titleAndSubtitle}>
                <div className={styles.title}>{title}</div>
                <div className={styles.subtitle}>{subtitle}</div>
            </div>
            {infoMessage && (
                <div className={styles.infoMessage}>
                    <AlertBanner type="info" size="md" title={infoMessage} />
                </div>
            )}
        </div>
    )
}
