import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"

import { Popover } from "@molecules"
import usePopover from "@molecules/Popover/Popover.store"

import PopoverAddColumns from "./PopoverAddColumns/PopoverAddColumns"
import PopoverOrderColumns from "./PopoverOrderColumns/PopoverOrderColumns"
import PopoverSortingColumns from "./PopoverSortingColumns/PopoverSortingColumns"
import { TableViewMenuPopoverProps } from "./TableViewMenuPopover.types"
import TableViewMenuPopoverDivider from "./TableViewMenuPopoverDivider/TableViewMenuPopoverDivider"

export default function TableViewMenuPopover(props: TableViewMenuPopoverProps) {
    const { isOpen } = props

    const { contentActive } = usePopover()

    if (!isOpen) {
        return null
    } else {
        return (
            <>
                <Popover.Content isVisible={!contentActive} priority="primary">
                    {(isVisible) => {
                        return (
                            <>
                                <Popover.Header
                                    title="Table View Settings"
                                    icon={icon({
                                        name: "table-cells-large",
                                        style: "solid",
                                        family: "sharp",
                                    })}
                                    showBorderBottom={true}
                                />
                                <Popover.SubHeader title="Sorting" />
                                <PopoverSortingColumns isVisible={isVisible} />
                                <TableViewMenuPopoverDivider fullWidth={true} />

                                <PopoverOrderColumns isVisible={isVisible} />
                            </>
                        )
                    }}
                </Popover.Content>
                <Popover.Content isVisible={contentActive === "add-column"} priority="secondary">
                    {(isVisible) => {
                        return <PopoverAddColumns isVisible={isVisible} />
                    }}
                </Popover.Content>
            </>
        )
    }
}
