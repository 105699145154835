import React, { Component } from "react";
import ButtonGroup from "../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../core/buttons/UniversalButton";
import HorizontalLayout from "../../core/components/HorizontalLayout";
import CharField from "../../core/fields/CharField";
import DateField from "../../core/fields/DateField";
import TextField from "../../core/fields/TextField";


class InlineEquipmentWarrantyForm extends Component {

    renderButtons = () => {
        const { warranty, isNewAddition, onFormDataChange, requestAction } = this.props

        return (
            <ButtonGroup>
                <ButtonGroupRow>
                    <UniversalButton type="primary" text="Save" handler={event => requestAction("EQUIPMENT_WARRANTY_SAVE")} />
                </ButtonGroupRow>
                {
                    isNewAddition
                    ?
                    <ButtonGroupRow>
                        <UniversalButton type="secondary" text="Cancel" handler={event => requestAction("EQUIPMENT_WARRANTY_DELETE")} />
                    </ButtonGroupRow>
                    :
                    <>
                        <ButtonGroupRow>
                            <UniversalButton type="secondary" text="Cancel Edits" handler={event => requestAction("EQUIPMENT_WARRANTY_CANCEL_EDITS")} />
                        </ButtonGroupRow>
                        <ButtonGroupRow>
                            <UniversalButton type="danger" text="Delete Warranty" handler={event => requestAction("EQUIPMENT_WARRANTY_DELETE")} />
                        </ButtonGroupRow>
                    </>
                }

            </ButtonGroup>
        )
    }

    render() {
        const { warranty, onFormDataChange, requestAction } = this.props
        const errors = warranty.errors || {}

        return (
            <div className="data-panel__list-select__form">
                <div className="data-panel__form" aria-label="Equipment Warranty Edit Form" style={{width: "100%"}}>
                    {errors.non_field_error && <div className="data-panel__form__field__errors" aria-label="Non Field Error">{errors.non_field_error}</div>}
                    <CharField
                        fieldName="name"
                        fieldLabel="Name"
                        fieldValue={warranty.name || ""}
                        fieldOnChange={name => onFormDataChange("name", name || "")}
                        maxLength="60"
                        errors={errors}
                    ></CharField>
                    <HorizontalLayout>
                        <DateField
                            fieldName="start_date"
                            fieldLabel="Start Date"
                            fieldValue={warranty.start_date}
                            fieldOnChange={start_date => onFormDataChange("start_date", start_date || null)}
                            errors={errors}
                        ></DateField>
                        <DateField
                            fieldName="end_date"
                            fieldLabel="End Date"
                            fieldValue={warranty.end_date}
                            fieldOnChange={end_date => {
                                onFormDataChange("end_date", end_date || null)
                            }}
                            optional={true}
                            errors={errors}
                        ></DateField>
                    </HorizontalLayout>
                    <TextField
                        fieldName="details"
                        fieldLabel="Details"
                        fieldValue={warranty.details || ""}
                        fieldOnChange={details => onFormDataChange("details", details || "")}
                        placeholder="Any additional details about this warranty."
                        rows={3}
                        optional={true}
                        errors={errors}
                    ></TextField>
                </div>
                {this.renderButtons()}
            </div>
        )
    }
}

export default InlineEquipmentWarrantyForm;
