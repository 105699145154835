interface FormatCurrencyArgs {
    value: number
    currencyCode: string
    languageCode: string
}

export default function formatCurrency(args: FormatCurrencyArgs) {
    const { value, currencyCode, languageCode } = args

    return new Intl.NumberFormat(languageCode, { style: "currency", currency: currencyCode }).format(value)
}
