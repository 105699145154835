import EstimateCreate from "./EstimateCreate/EstimateCreate"
import EstimateDetails from "./EstimateDetails/EstimateDetails"
import EstimateEdit from "./EstimateEdit/EstimateEdit"
import EstimateList from "./EstimateList/EstimateList"
import EstimatePublicDetails from "./EstimatePublicDetails/EstimatePublicDetails"

const List = EstimateList
const Create = EstimateCreate
const Edit = EstimateEdit
const Details = EstimateDetails
const PublicDetails = EstimatePublicDetails

export default { List, Create, Edit, Details, PublicDetails }
