// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IconButton-xsSize__C1xUf{height:var(--size-7);padding:var(--size-1_5);border-radius:var(--border-radius-1_5)}.IconButton-smSize__nYO8F{height:var(--size-8);padding:var(--size-1_5);border-radius:var(--border-radius-1_5)}.IconButton-mdSize__NnYyI{height:var(--size-9);padding:var(--size-2);border-radius:var(--border-radius-1_5)}.IconButton-lgSize__Nbeya{height:var(--size-10);padding:var(--size-2);border-radius:var(--border-radius-2)}.IconButton-xlSize__PXMTl{height:var(--size-11);padding:var(--size-2_5);border-radius:var(--border-radius-2)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsSize": "IconButton-xsSize__C1xUf",
	"smSize": "IconButton-smSize__nYO8F",
	"mdSize": "IconButton-mdSize__NnYyI",
	"lgSize": "IconButton-lgSize__Nbeya",
	"xlSize": "IconButton-xlSize__PXMTl"
};
export default ___CSS_LOADER_EXPORT___;
