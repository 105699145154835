import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import { Command } from "cmdk"

import useCurrentObjectsView from "@hooks/useCurrentObjectsView"

import generateRandomKey from "@utils/generateRandomKey"

import { MobileHeader } from "@molecules/index"

import { BottomSheet } from "@organisms"
import useBottomSheet from "@organisms/BottomSheet/BottomSheet.store"
import { TableViewMenuColumn } from "@organisms/Table/TableViewMenu/TableViewMenu.types"

import styles from "./BottomSheetAddColumns.module.scss"
import { BottomSheetAddColumnsProps } from "./BottomSheetAddColumns.types"

export default function BottomSheetAddColumns(props: BottomSheetAddColumnsProps) {
    const { isVisible } = props

    const { updateColumnVisibility, invisibleColumns } = useCurrentObjectsView()
    const { setContentActive } = useBottomSheet()

    const handleColumnVisibilityChange = (column: TableViewMenuColumn) => {
        updateColumnVisibility(column.value, true)
    }

    const noColumns = invisibleColumns.length === 0

    const onHeaderBackClick = () => setContentActive("columns")

    if (noColumns) {
        return (
            <>
                <MobileHeader
                    title="Add Column"
                    showBackButton={true}
                    onBackClick={onHeaderBackClick}
                    isTabbable={isVisible}
                    showDragIndicator={true}
                />
                <div className={styles.noColumns}>
                    <div className={styles.noColumnsText}>No columns to add</div>
                </div>
            </>
        )
    }

    return (
        <Command>
            <MobileHeader
                title="Add Column"
                onBackClick={onHeaderBackClick}
                showDivider={false}
                showBackButton={true}
                isTabbable={isVisible}
                showDragIndicator={true}
            />
            <div className={styles.searchInput}>
                <BottomSheet.SearchInput />
            </div>
            <Command.List>
                <Command.Empty>
                    <div className={styles.noColumns}>
                        <div className={styles.noColumnsText}>No columns found</div>
                    </div>
                </Command.Empty>
                <Command.Group className={styles.items}>
                    {invisibleColumns?.map((column) => (
                        <Command.Item key={generateRandomKey()} value={column.label}>
                            <BottomSheet.Item
                                icon={icon({
                                    name: "plus",
                                    style: "light",
                                    family: "sharp",
                                })}
                                isTabbable={isVisible}
                                label={column.label}
                                onClick={() => handleColumnVisibilityChange(column)}
                            />
                        </Command.Item>
                    ))}
                </Command.Group>
            </Command.List>
        </Command>
    )
}
