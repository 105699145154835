import clsx from "clsx"
import { forwardRef, useEffect, useId, useImperativeHandle, useMemo, useRef, useState } from "react"
import { useFormContext } from "react-hook-form"
import { Value } from "react-phone-number-input"

import Label from "@atoms/Label/Label"
import { Button, IconButton } from "@atoms/index"

import { FormControl, FormField, FormItem, useFormField } from "@molecules/Form/Form"

import styles from "./Input.module.scss"
import { InputName, InputProps } from "./Input.types"
import InputHelpText from "./InputHelpText/InputHelpText"
import InputPrefixSuffix from "./InputPrefixSuffix/InputPrefixSuffix"

function Input<T>(props: InputProps<T>, ref: React.Ref<HTMLInputElement>) {
    const [isFocused, setIsFocused] = useState<boolean>(false)

    const { name, id, invalid, error } = useFormField()

    const {
        size,
        label = undefined,
        inputHelpText = undefined,
        prefixIcon = undefined,
        prefixString = undefined,
        suffixIcon = undefined,
        suffixString = undefined,
        isDisabled = false,
        isReadOnly = false,
        isRequired = false,
        className,
        containerClassName,
        type = "text",
        renderInput = undefined,
        renderLabelRightItem = undefined,
        iconButton = undefined,
        button = undefined,
        isValuePlaceHolder = false,
        value,
        autoFocus = false,
        isTabbable = true,
        ...rest
    } = props

    const inputRef = useRef<HTMLInputElement>(null)

    useImperativeHandle(ref, () => inputRef.current, [])

    useEffect(() => {
        if (autoFocus && inputRef.current) {
            inputRef.current.focus()
        }
    }, [autoFocus])

    const defaultId = useId()

    const labelIsString = typeof label === "string"
    const labelVariant = useMemo(
        () => (isDisabled ? "disabled" : invalid ? "error" : isFocused ? "focused" : "default"),
        [isDisabled, invalid, isFocused],
    )

    const helpTextIsString = typeof inputHelpText === "string"

    const inputProps = {
        ref: inputRef,
        className: clsx(
            styles.input,
            {
                [styles.inputSm]: size === "sm",
                [styles.inputMd]: size === "md",
                [styles.inputLg]: size === "lg",

                [styles.inputValuePlaceHolder]: isValuePlaceHolder,
            },
            className,
        ),
        disabled: isDisabled,
        readOnly: isReadOnly,
        required: isRequired,
        name: name,
        id: id || defaultId,
        autoComplete: "off",
        spellCheck: false,
        autoCorrect: "off",
        type: type,
        onFocus: () => setIsFocused(true),
        value,
        autoFocus: false,
        tabIndex: isTabbable ? 0 : -1,
        ...rest,
    }

    const labelAndHelpSize = useMemo<InputProps["size"]>(() => {
        const sizeMap: {
            [key in InputProps["size"]]: InputProps["size"]
        } = {
            sm: "sm",
            md: "sm",
            lg: "md",
        }
        return sizeMap[size]
    }, [size])

    const renderInputMessage = () => {
        if (isDisabled) {
            return null
        } else if (error?.message && invalid) {
            return (
                <InputHelpText type="error" size={labelAndHelpSize}>
                    {error.message as string}
                </InputHelpText>
            )
        } else if (helpTextIsString) {
            return (
                <InputHelpText type="normal" size={labelAndHelpSize}>
                    {inputHelpText}
                </InputHelpText>
            )
        } else if (inputHelpText) {
            return <InputHelpText {...inputHelpText} size={labelAndHelpSize} />
        } else {
            return null
        }
    }

    return (
        <div className={clsx(styles.inputWithLabelAndHelp, containerClassName)}>
            {label !== undefined ? (
                labelIsString ? (
                    <div className={styles.labelWrap}>
                        <Label variant={labelVariant} size={labelAndHelpSize} htmlFor={id || defaultId}>
                            {label}
                        </Label>
                        {renderLabelRightItem?.()}
                    </div>
                ) : (
                    <div className={styles.labelWrap}>
                        <Label variant={labelVariant} size={labelAndHelpSize} htmlFor={id || defaultId} {...label} />
                        {renderLabelRightItem?.()}
                    </div>
                )
            ) : undefined}

            <div className={styles.inputAndButton}>
                <div
                    className={clsx(
                        styles.inputWrapper,
                        {
                            [styles.inputWrapperSm]: size === "sm",
                            [styles.inputWrapperMd]: size === "md",
                            [styles.inputWrapperLg]: size === "lg",
                        },
                        {
                            [styles.inputWrapperDisabled]: isDisabled,
                            [styles.inputWrapperError]: invalid,
                        },
                    )}
                >
                    {prefixIcon ? (
                        <InputPrefixSuffix
                            type="prefix"
                            icon={prefixIcon}
                            size={size}
                            htmlFor={id || defaultId}
                            isDisabled={isDisabled}
                        />
                    ) : prefixString ? (
                        <InputPrefixSuffix
                            type="prefix"
                            value={prefixString}
                            size={size}
                            htmlFor={id || defaultId}
                            isDisabled={isDisabled}
                        />
                    ) : undefined}
                    {type === "tel" && renderInput ? (
                        renderInput({
                            ...inputProps,
                            value: inputProps.value as Value,
                            // prevents react-phone-number-input to fire unwanted errors
                            onChange: inputProps.onChange as undefined,
                            name: name,
                        })
                    ) : (
                        <input {...inputProps} name={name} />
                    )}
                    {iconButton && (
                        <IconButton
                            size={size}
                            colorScheme="gray"
                            variant="ghost"
                            isTabbable={isTabbable}
                            {...iconButton}
                        />
                    )}

                    {suffixIcon ? (
                        <InputPrefixSuffix
                            type="suffix"
                            icon={suffixIcon}
                            size={labelAndHelpSize}
                            htmlFor={id || defaultId}
                            isDisabled={isDisabled}
                        />
                    ) : suffixString ? (
                        <InputPrefixSuffix
                            type="suffix"
                            value={suffixString}
                            size={labelAndHelpSize}
                            htmlFor={id || defaultId}
                            isDisabled={isDisabled}
                        />
                    ) : undefined}
                </div>
                {button && <Button size={size} {...button} />}
            </div>
            {renderInputMessage()}
        </div>
    )
}

const withController = <T extends InputName>(InputComponent: React.FC<InputProps<T>>) => {
    const Controller = forwardRef<HTMLInputElement, InputProps<T>>((props, ref) => {
        const form = useFormContext()

        return (
            <FormField
                control={form.control}
                name={props.name as string}
                render={({ field }) => {
                    // In case the value is passed via prop we want this value in sync with react hook form.
                    // A practical example for this is the DateInput component.
                    useEffect(() => {
                        if (props.value !== undefined) {
                            form.setValue(props.name as string, props.value)
                        }
                    }, [props.value])

                    return (
                        <FormItem>
                            <FormControl>
                                <InputComponent
                                    {...props}
                                    {...field}
                                    onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                                        const nextElement = event.relatedTarget as HTMLElement | null
                                        if (nextElement && nextElement.dataset.preventInputValidation) {
                                            // Skip validation if the clicked element contains preventInputValidation
                                            return
                                        } else {
                                            field.onBlur()
                                        }
                                    }}
                                    value={props.value ?? (field.value as string)}
                                    ref={ref}
                                />
                            </FormControl>
                        </FormItem>
                    )
                }}
            />
        )
    })

    Controller.displayName = "InputComponentController"

    return Controller as typeof Input
}

export default withController(forwardRef(Input))
