import { BryntumSchedulerPro } from "@bryntum/schedulerpro-react"

import { schedulerConfig } from "./JobTimelineViewConfig/JobTimelineViewConfig"
import JobTimelineViewSchedulerWrapper from "./JobTimelineViewSchedulerWrapper"
import useJobTimelineViewBryntumInstances from "./hooks/useJobTimelineViewBryntumInstances"
import useJobTimelineViewSchedulerProps from "./hooks/useJobTimelineViewSchedulerProps"

export default function JobTimelineViewHorizontalScheduler() {
    const { schedulerPro } = useJobTimelineViewBryntumInstances()

    const { horizontalProps } = useJobTimelineViewSchedulerProps()

    return (
        <JobTimelineViewSchedulerWrapper>
            <BryntumSchedulerPro ref={schedulerPro} {...schedulerConfig} {...horizontalProps} />
        </JobTimelineViewSchedulerWrapper>
    )
}
