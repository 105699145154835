// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TableViewMenuColumnsOrder-base__vNqNb{padding:var(--size-2) var(--size-3);overflow-y:auto}@media only screen and (min-width: 64rem){.TableViewMenuColumnsOrder-base__vNqNb{padding:0 var(--size-1_5) var(--size-1_5) var(--size-1_5)}}.TableViewMenuColumnsOrder-divider__q2DRC{padding:var(--size-2_5) var(--size-3)}@media only screen and (min-width: 64rem){.TableViewMenuColumnsOrder-divider__q2DRC{padding:var(--size-1) var(--size-3)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "TableViewMenuColumnsOrder-base__vNqNb",
	"divider": "TableViewMenuColumnsOrder-divider__q2DRC"
};
export default ___CSS_LOADER_EXPORT___;
