import React, { Component } from 'react';
import ToggleSwitch from "../inputs/ToggleSwitch";


class SwitchField extends Component {

    render() {
        const {fieldName, fieldLabel, fieldValue, fieldOnChange, checkedText, uncheckedText, disabled=false, optional=false, optionalText="optional", errors} = this.props

        return (
            <div className="data-panel__form__field" id={`div_id_${fieldName}`} aria-label={fieldLabel}>
                <label className="data-panel__form__field__label in-form">{fieldLabel}{optional && <span className="text-optional"> ({optionalText})</span>}</label>
                <div className="data-panel__form__field__input" title={disabled ? fieldValue ? checkedText : uncheckedText : null}>
                    <ToggleSwitch
                        disabled={disabled}
                        onToggleChange={event => fieldOnChange(!fieldValue)}
                        fieldName={fieldName}
                        fieldValue={fieldValue}
                        checkedText={checkedText}
                        uncheckedText={uncheckedText}
                    ></ToggleSwitch>
                </div>
                {errors[fieldName] && <div className="data-panel__form__field__errors" aria-label="Field Errors">{errors[fieldName]}</div>}
            </div>
        )
    }
}

export default SwitchField;
