// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SortingIcons-base__RwsN1{display:flex;gap:var(--size-1);align-items:center;padding:var(--size-0_25) var(--size-0)}.SortingIcons-icon__Luu43{display:flex;flex-direction:column}.SortingIcons-sorting__pnJpZ{color:var(--gray-11)}.SortingIcons-active__uwozb{color:var(--gray-12)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "SortingIcons-base__RwsN1",
	"icon": "SortingIcons-icon__Luu43",
	"sorting": "SortingIcons-sorting__pnJpZ",
	"active": "SortingIcons-active__uwozb"
};
export default ___CSS_LOADER_EXPORT___;
