import { Tab, Toggle } from "@atoms"

import { Popover, Property, TabStack } from "@molecules"

import useJobTimelineViewEventHandlers from "@organisms/ObjectsView/JobTimelineView/hooks/useJobTimelineViewEventHandlers"
import useTimelineViewStates from "@organisms/ObjectsView/JobTimelineView/hooks/useJobTimelineViewStates"
import PropertyStack from "@organisms/PropertyStack/PropertyStack"

import styles from "./TimelineViewMenuPopover.module.scss"
import { TimelineViewMenuPopoverProps } from "./TimelineViewMenuPopover.types"

export default function TimelineViewMenuPopover(props: TimelineViewMenuPopoverProps) {
    const { isOpen, onClose, anchorRef } = props

    const {
        jobCardStyle,
        setJobCardStyleToCompact,
        setJobCardStyleToRegular,
        shouldShowCancelledJobs,
        shouldShowFinalizedJobs,
        timelineViewOrientation,
    } = useTimelineViewStates()

    const { onCancelledJobsToggle, onFinalizedJobsToggle, onSetSchedulerToHorizontal, onSetSchedulerToVertical } =
        useJobTimelineViewEventHandlers()

    if (!isOpen) {
        return null
    } else {
        return (
            <Popover.Root isOpen={isOpen} onClose={onClose} anchorRef={anchorRef}>
                <Popover.Content isVisible={isOpen} priority="primary">
                    {() => {
                        return (
                            <>
                                <Popover.Header
                                    title="Timeline View Settings"
                                    icon="fa-sharp-light-timeline-view"
                                    showBorderBottom={true}
                                />

                                <PropertyStack className={styles.content}>
                                    <Property.Root className={styles.item}>
                                        <Property.Content>
                                            <Property.Label className={styles.itemLabel} htmlFor="show-finalized-jobs">
                                                Show Finalized Jobs
                                            </Property.Label>
                                        </Property.Content>
                                        <Property.Value>
                                            <Toggle
                                                id="show-finalized-jobs"
                                                checked={shouldShowFinalizedJobs}
                                                onClick={onFinalizedJobsToggle}
                                                size="md"
                                                isControlled={false}
                                            />
                                        </Property.Value>
                                    </Property.Root>
                                    <Property.Root className={styles.item}>
                                        <Property.Content>
                                            <Property.Label className={styles.itemLabel} htmlFor="show-cancelled-jobs">
                                                Show Cancelled Jobs
                                            </Property.Label>
                                        </Property.Content>
                                        <Property.Value>
                                            <Toggle
                                                size="md"
                                                id="show-cancelled-jobs"
                                                checked={shouldShowCancelledJobs}
                                                onClick={onCancelledJobsToggle}
                                                isControlled={false}
                                            />
                                        </Property.Value>
                                    </Property.Root>

                                    <Property.Root className={styles.item}>
                                        <Property.Content>
                                            <Property.Label className={styles.itemLabel}>
                                                Timeline Orientation
                                            </Property.Label>
                                        </Property.Content>
                                        <Property.Value>
                                            <TabStack attached={true} size="sm">
                                                <Tab
                                                    isPressed={timelineViewOrientation === "vertical"}
                                                    onClick={onSetSchedulerToVertical}
                                                    noDebounce={true}
                                                >
                                                    Vertical
                                                </Tab>
                                                <Tab
                                                    isPressed={timelineViewOrientation === "horizontal"}
                                                    onClick={onSetSchedulerToHorizontal}
                                                    noDebounce={true}
                                                >
                                                    Horizontal
                                                </Tab>
                                            </TabStack>
                                        </Property.Value>
                                    </Property.Root>

                                    {timelineViewOrientation === "horizontal" && (
                                        <Property.Root className={styles.item}>
                                            <Property.Content>
                                                <Property.Label
                                                    className={styles.itemLabel}
                                                    htmlFor="show-finalized-jobs"
                                                >
                                                    Job Card Style
                                                </Property.Label>
                                            </Property.Content>
                                            <Property.Value>
                                                <TabStack attached={true} size="sm">
                                                    <Tab
                                                        isPressed={jobCardStyle === "compact"}
                                                        onClick={setJobCardStyleToCompact}
                                                        noDebounce={true}
                                                    >
                                                        Compact
                                                    </Tab>
                                                    <Tab
                                                        isPressed={jobCardStyle === "regular"}
                                                        onClick={setJobCardStyleToRegular}
                                                        noDebounce={true}
                                                    >
                                                        Regular
                                                    </Tab>
                                                </TabStack>
                                            </Property.Value>
                                        </Property.Root>
                                    )}
                                </PropertyStack>
                            </>
                        )
                    }}
                </Popover.Content>
            </Popover.Root>
        )
    }
}
