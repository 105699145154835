import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import * as DropdownMenu from "@radix-ui/react-dropdown-menu"
import clsx from "clsx"
import { Fragment, useEffect, useMemo, useRef } from "react"

import generateRandomKey from "@utils/generateRandomKey"

import { Icon } from "@atoms"

import styles from "./Dropdown.module.scss"
import DropdownProps, { Item } from "./Dropdown.types"

export default function Dropdown(props: DropdownProps) {
    const {
        items,
        isOpen,
        onClose,
        onSelect = () => null,
        side = "bottom",
        startingPoint = "right",
        triggerClassName,
        menuContentClassName,
    } = props

    const triggerRef = useRef<HTMLDivElement>(null)

    const onOpenChange = (isOpen: boolean) => {
        if (!isOpen) {
            onClose()
        }
    }

    const onItemClick = (item: Item) => {
        onSelect(item)
        onClose()

        item.onClick?.()
    }

    const hasDividers = useMemo(() => items.some((item) => item.dividerBottom || item.dividerTop), [items])

    useEffect(() => {
        if (isOpen) {
            triggerRef.current?.focus()
        }
    }, [isOpen])

    return (
        <DropdownMenu.Root open={isOpen} onOpenChange={onOpenChange}>
            <DropdownMenu.Trigger asChild={true} className={triggerClassName}>
                <span
                    className={clsx(styles.trigger, {
                        [styles.toRight]: startingPoint === "right",
                        [styles.toLeft]: startingPoint === "left",
                    })}
                    ref={triggerRef}
                />
            </DropdownMenu.Trigger>
            <DropdownMenu.Portal>
                <DropdownMenu.Content
                    onEscapeKeyDown={onClose}
                    onPointerDownOutside={onClose}
                    className={clsx(styles.menuContent, menuContentClassName, {
                        [styles.menuContentWithDividers]: hasDividers,
                    })}
                    sideOffset={8}
                    collisionPadding={8}
                    avoidCollisions={true}
                    side={side}
                    align={startingPoint === "right" ? "start" : "end"}
                    loop={true}
                >
                    {items.map((item) => {
                        return (
                            <Fragment key={generateRandomKey()}>
                                {item.dividerTop && <DropdownMenu.Separator className={styles.divider} />}
                                <DropdownMenu.Item
                                    onClick={() => onItemClick(item)}
                                    className={clsx(styles.menuItem, {
                                        [styles.menuItemDisabled]: item.isDisabled,
                                        [styles.menuItemActive]: item.isActive,
                                    })}
                                >
                                    <div className={styles.menuItemInnerContainer}>
                                        {item.icon && (
                                            <div className={styles.iconWrap}>
                                                <Icon icon={item.icon} size={16} />
                                            </div>
                                        )}
                                        <DropdownMenu.Label className={styles.itemLabel}>
                                            {item.label}
                                        </DropdownMenu.Label>
                                        {item.isActive && (
                                            <div className={styles.iconWrap}>
                                                <Icon
                                                    icon={icon({ name: "check", style: "regular", family: "sharp" })}
                                                    size={12}
                                                    noContainer={true}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </DropdownMenu.Item>
                                {item.dividerBottom && <DropdownMenu.Separator className={styles.divider} />}
                            </Fragment>
                        )
                    })}
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
        </DropdownMenu.Root>
    )
}
