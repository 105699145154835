import EstimateDetailsContainer from "@legacy/estimates/EstimateDetailsContainer"

import useToastQueue from "@hooks/useToastQueue"

import { AppFrame } from "@templates"

export default function EstimatePublicDetails() {
    const { addToastToQueue } = useToastQueue()
    return (
        <AppFrame.Root pageId="EstimatePublicDetails" navigationType="none">
            <AppFrame.PageContent>
                <EstimateDetailsContainer addToastToQueue={addToastToQueue} />
            </AppFrame.PageContent>
        </AppFrame.Root>
    )
}
