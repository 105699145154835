import React, { Component } from 'react';


class BooleanField extends Component {

    render() {
        const {fieldName, fieldLabel, fieldValue, fieldOnChange, inputLabel, disabled=false, optional=false, optionalText="optional", errors, idKey=""} = this.props

        return (
            <div className="data-panel__form__field" id={`div_id_${fieldName}${idKey}`} aria-label={fieldLabel}>
                <label htmlFor={`id_${fieldName}${idKey}`} className="data-panel__form__field__label in-form" >{fieldLabel}{optional && <span className="text-optional"> ({optionalText})</span>}</label>
                <div className="data-panel__form__field__input data-panel__form__field__input--with-checkbox">
                    <input
                        checked={fieldValue}
                        onChange={event => fieldOnChange(!fieldValue)}
                        type="checkbox"
                        id={`id_${fieldName}${idKey}`}
                        name={fieldName}
                        autoComplete="off"
                        className={`checkbox-input${disabled ? " is-disabled" : ""}`}
                        disabled={disabled}
                    />
                    <label className="checkbox-label" htmlFor={`id_${fieldName}${idKey}`}>{inputLabel}</label>
                </div>
                {errors[fieldName] && <div className="data-panel__form__field__errors" aria-label="Field Errors">{errors[fieldName]}</div>}
            </div>
        )
    }
}

export default BooleanField;
