import { Component, Fragment } from "react";
import { renderClientString } from "../../../../clients/utils/utils";
import ButtonGroup from "../../../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../../../core/buttons/UniversalButton";
import Banner from "../../../../core/components/Banner";
import BasicDisplayField from "../../../../core/fields/BasicDisplayField";
import { ClientTypes } from "../../../../core/utils/enums";
import QuickBooksContactListDisplay from "./QuickBooksContactListDisplay";
import QuickBooksServiceLocationListDisplay from "./QuickBooksServiceLocationListDisplay";


class QuickBooksClientRecordDisplay extends Component {

    constructor(props) {
        super(props)

        this.state = {
            showAllServiceLocations: false
        }
    }

    toggleShowAllServiceLocations = () => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState.showAllServiceLocations = !state.showAllServiceLocations
            return updatedState
        })
    }

    renderButtons = () => {
        const { record, requestAction } = this.props

        if (Object.keys(record.errors).length !== 0 || record.child_has_errors === true ) {
            return (
                <ButtonGroup>
                    <ButtonGroupRow>
                        <UniversalButton type="primary" text="Fix Errors" handler={event => requestAction("RECORD_EDIT_CLIENT")} />
                    </ButtonGroupRow>
                    {
                        !record.has_orphans && (
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Ignore Record" handler={event => requestAction("RECORD_IGNORE")} />
                            </ButtonGroupRow>
                        )
                    }
                </ButtonGroup>
            )
        }
        else if (record.status_label === "Processed" || record.children.some(child => child.status_label === "Processed")) {
            return (
                <ButtonGroup>
                    <ButtonGroupRow>
                        <UniversalButton type="primary" text="Confirm Record" handler={event => requestAction("RECORD_CONFIRM")} />
                    </ButtonGroupRow>
                    {
                        !record.has_orphans && (
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Ignore Record" handler={event => requestAction("RECORD_IGNORE")} />
                            </ButtonGroupRow>
                        )
                    }
                    <ButtonGroupRow>
                        <UniversalButton type="secondary" text="Edit Record" handler={event => requestAction("RECORD_EDIT_CLIENT")} />
                    </ButtonGroupRow>
                </ButtonGroup>
            )
        }
        else if (record.status_label === "Confirmed" || record.children.some(child => child.status_label === "Confirmed")) {
            return (
                <ButtonGroup>
                    <ButtonGroupRow>
                        <UniversalButton type="secondary" text="Un-confirm Record" handler={event => requestAction("RECORD_UNCONFIRM")} />
                    </ButtonGroupRow>
                    <ButtonGroupRow>
                        <UniversalButton type="secondary" text="Edit Record" handler={event => requestAction("RECORD_EDIT_CLIENT")} />
                    </ButtonGroupRow>
                </ButtonGroup>
            )
        }
    }

    getClientFromRecord = (record) => {
        if (record.action_target === null) {
            return null
        }

        return record.action_target
    }

    renderMatchDetails = (record, client) => {
        let matchString = ""
        let detailUrl

        if (record.recommended_action_label === "Merge") {
            matchString += "Merge on "
        }

        detailUrl = DjangoUrls["clients:clients-detail"](window.MARKETPLACE_ENTITY_SLUG, client.id)

        return <Fragment>{`${matchString}${record.match_type_label} with Client `}<a className="text-link text-link--inline" href={detailUrl} target="_blank">{renderClientString(client)}&nbsp;&nbsp;<i className="fa-sharp fa-light fa-arrow-up-right-from-square" aria-hidden="true"></i></a></Fragment>
    }

    renderMatchFieldText = (record, client, fieldName) => {
        if (client === null || Object.keys(record.errors).length !== 0 || record.child_has_errors === true) {
            return
        }

        let recordValue = record[`cleaned_client_${fieldName}`]
        let clientValue = client[fieldName]

        if (record.recommended_action_label === "Match" || record.recommended_action_label === "Merge") {
            if (recordValue !== "" && clientValue === "") {
                return <span className="data-panel__form__field__success"><i className="fa-sharp fa-light fa-square-plus" aria-hidden="true"></i>&nbsp;&nbsp;This will be added to the existing Roopairs client.</span>
            }
            else if (recordValue !== clientValue) {
                return <span className="data-panel__form__field__warning"><i className="fa-sharp fa-light fa-triangle-exclamation" aria-hidden="true"></i>&nbsp;&nbsp;This does not match the existing Roopairs client and will be ignored.</span>
            }
        }
    }

    render() {
        const { record, requestAction } = this.props
        const client = this.getClientFromRecord(record)

        return (
            <Fragment key={record.id}>
                <div className="data-panel__form" aria-label="Record Display">
                    {
                        record.has_orphans && (
                            <Banner type="info" text="This client record has already been imported (but the service locations below haven't)." />
                        )
                    }
                    {
                        (record.recommended_action_label === "Match" || record.recommended_action_label === "Merge") && (
                            <BasicDisplayField
                                fieldName="match_details"
                                fieldLabel="Match Details"
                                fieldValue={this.renderMatchDetails(record, client)}
                            ></BasicDisplayField>
                        )
                    }
                    <BasicDisplayField
                        fieldName="id"
                        fieldLabel="ID"
                        fieldValue={record.id}
                    ></BasicDisplayField>
                    <BasicDisplayField
                        fieldName="client_type"
                        fieldLabel="Client Type"
                        fieldValue={record.cleaned_client_client_type_label}
                        footer={this.renderMatchFieldText(record, client, "client_type")}
                    ></BasicDisplayField>
                    <BasicDisplayField
                        fieldName="industry_type"
                        fieldLabel="Industry Type"
                        fieldValue={record.cleaned_client_industry_type_label ? record.cleaned_client_industry_type_label : "--"}
                        footer={this.renderMatchFieldText(record, client, "industry_type")}
                    ></BasicDisplayField>
                    <BasicDisplayField
                        fieldName="name"
                        fieldLabel={record.cleaned_client_client_type === ClientTypes.business ? "Business Name" : "Individual Name"}
                        fieldValue={record.cleaned_client_name}
                        footer={this.renderMatchFieldText(record, client, "name")}
                    ></BasicDisplayField>
                    <BasicDisplayField
                        fieldName="billing_address"
                        fieldLabel="Billing Address"
                        fieldValue={record.cleaned_client_billing_address_formatted_multiline ? record.cleaned_client_billing_address_formatted_multiline : "--"}
                        footer={this.renderMatchFieldText(record, client, "billing_address_formatted")}
                    ></BasicDisplayField>
                    <BasicDisplayField
                        fieldName="client_notes"
                        fieldLabel="Client Notes"
                        fieldValue={record.cleaned_client_notes ? record.cleaned_client_notes : "--"}
                        footer={this.renderMatchFieldText(record, client, "notes")}
                    ></BasicDisplayField>
                    <QuickBooksContactListDisplay record={record} label="Client Contacts" />
                    <QuickBooksServiceLocationListDisplay record={record} showAllServiceLocations={this.state.showAllServiceLocations} toggleShowAllServiceLocations={this.toggleShowAllServiceLocations} />
                </div>
                {this.renderButtons()}
            </Fragment>
        )
    }
}

export default QuickBooksClientRecordDisplay;
