// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AppFrame-base__qRUVz{display:flex;flex-direction:column;flex-grow:1;min-width:var(--size-0)}.AppFrame-loading__dMZl4{display:flex;flex-direction:column;flex-grow:1;align-items:center;justify-content:center}.AppFrame-page__WIFDk{position:relative;display:flex;flex-direction:column;width:var(--size-full);height:var(--size-h-screen-dynamic);margin:var(--size-0)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "AppFrame-base__qRUVz",
	"loading": "AppFrame-loading__dMZl4",
	"page": "AppFrame-page__WIFDk"
};
export default ___CSS_LOADER_EXPORT___;
