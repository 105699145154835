import { Component, Fragment } from 'react';
import HorizontalLayout from "../components/HorizontalLayout";
import { valueIsDefined } from "../utils/utils";
import AddressAutocompleteField from './AddressAutocompleteField';
import CharField from './CharField';

class AddressAutocompleteFieldGroup extends Component {

    constructor(props) {
        super(props)

        this.state = {
            fieldsExpanded: false || Object.values(props.fieldValues).some(value => valueIsDefined(value)),
            key: 0
        }
    }

    render() {
        const {fieldGroupName, fieldGroupLabel, fieldPrefix, fieldValues, onFormDataChange, includeRecipient=false, disabled=false, optional=false, optionalText="optional", errors} = this.props

        return (
            <div className="data-panel__form__field__group" id={`div_id_${fieldGroupName}`} aria-label={fieldGroupLabel}>
                <label htmlFor={`id_${fieldGroupName}`} className="data-panel__form__field__label in-form">{fieldGroupLabel}{optional && <span className="text-optional"> ({optionalText})</span>}</label>
                <div className="data-panel__form__field__group__inputs">
                    {errors[fieldGroupName] && <div className="data-panel__form__field__errors" aria-label="Field Errors">{errors[fieldGroupName]}</div>}
                    {
                        includeRecipient && (
                            <CharField
                                fieldName={`${fieldPrefix}_recipient`}
                                fieldLabel="Recipient, Attention To, etc."
                                fieldValue={fieldValues[`${fieldPrefix}_recipient`]}
                                fieldOnChange={recipient => onFormDataChange(`${fieldPrefix}_recipient`, recipient)}
                                maxLength="100"
                                optional={true}
                                optionalText="if different"
                                disabled={disabled}
                                errors={errors}
                            ></CharField>
                        )
                    }
                    <AddressAutocompleteField
                        fieldName={`${fieldPrefix}_street`}
                        fieldLabel="Street Address"
                        fieldValue={fieldValues[`${fieldPrefix}_street`]}
                        fieldOnChange={street => onFormDataChange(`${fieldPrefix}_street`, street)}
                        onAutocompleteSelect={addressComponents => {
                            this.setState({fieldsExpanded: false})
                            onFormDataChange(`${fieldPrefix}_street`, addressComponents.street)
                            onFormDataChange(`${fieldPrefix}_city`, addressComponents.city)
                            onFormDataChange(`${fieldPrefix}_state`, addressComponents.state)
                            onFormDataChange(`${fieldPrefix}_postal`, addressComponents.postal)
                            // onFormDataChange(`${fieldPrefix}_country`, addressComponents.country)

                            // Bump up the key so that the dependent fields know to re-render
                            this.setState(
                                (state) => {
                                    const updatedState = state
                                    updatedState.fieldsExpanded = true
                                    updatedState.key = state.key += 1
                                    return updatedState
                                })
                        }}
                        onExpand={() => this.setState({fieldsExpanded: true})}
                        showExpandButton={!this.state.fieldsExpanded}
                        disabled={disabled}
                        errors={errors}
                    ></AddressAutocompleteField>
                    {
                        this.state.fieldsExpanded && (
                            <Fragment>
                                <CharField
                                    key={`unit_${this.state.key}`}
                                    fieldName={`${fieldPrefix}_unit`}
                                    fieldLabel="Apartment, Suite, etc."
                                    fieldValue={fieldValues[`${fieldPrefix}_unit`]}
                                    fieldOnChange={unit => onFormDataChange(`${fieldPrefix}_unit`, unit)}
                                    maxLength="100"
                                    disabled={disabled}
                                    errors={errors}
                                ></CharField>
                                <CharField
                                    key={`city_${this.state.key}`}
                                    fieldName={`${fieldPrefix}_city`}
                                    fieldLabel="City"
                                    fieldValue={fieldValues[`${fieldPrefix}_city`]}
                                    fieldOnChange={city => onFormDataChange(`${fieldPrefix}_city`, city)}
                                    maxLength="100"
                                    disabled={disabled}
                                    errors={errors}
                                ></CharField>
                                <HorizontalLayout>
                                    <CharField
                                        key={`state_${this.state.key}`}
                                        fieldName={`${fieldPrefix}_state`}
                                        fieldLabel="State/Province"
                                        fieldValue={fieldValues[`${fieldPrefix}_state`]}
                                        fieldOnChange={state => onFormDataChange(`${fieldPrefix}_state`, state)}
                                        maxLength="100"
                                        disabled={disabled}
                                        errors={errors}
                                    ></CharField>
                                    <CharField
                                        key={`postal_${this.state.key}`}
                                        fieldName={`${fieldPrefix}_postal`}
                                        fieldLabel="Postal Code"
                                        fieldValue={fieldValues[`${fieldPrefix}_postal`]}
                                        fieldOnChange={postal => onFormDataChange(`${fieldPrefix}_postal`, postal)}
                                        maxLength="20"
                                        disabled={disabled}
                                        errors={errors}
                                    ></CharField>
                                </HorizontalLayout>
                                {/* <CharField
                                    key={`country_${this.state.key}`}
                                    fieldName={`${fieldPrefix}_country`}
                                    fieldLabel="Country"
                                    fieldValue={fieldValues[`${fieldPrefix}_country`]}
                                    fieldOnChange={country => onFormDataChange(`${fieldPrefix}_country`, country)}
                                    maxLength="100"
                                    disabled={disabled}
                                    errors={errors}
                                ></CharField> */}
                            </Fragment>
                        )
                    }
                </div>
            </div>
        )
    }
}

export default AddressAutocompleteFieldGroup;
