import useIsDesktop from "@hooks/useIsDesktop"

import { ButtonProps } from "@atoms/Button/Button.types"
import { Button } from "@atoms/index"

import useOTPForm from "@organisms/OTPForm/OTPForm.store"

export default function OTPVerifyCodeButton(props: Partial<ButtonProps>) {
    const isDesktop = useIsDesktop()

    const { handleCodeVerification, isVerifyingCode } = useOTPForm()

    return (
        <Button
            size={isDesktop ? "md" : "xl"}
            colorScheme="gray"
            variant="solid"
            onClick={handleCodeVerification}
            isFullWidth={true}
            isLoading={isVerifyingCode}
            loadingText="Sign in"
            {...props}
        />
    )
}
