import EventAccordion from '../../core/components/EventAccordion'
import { valueIsDefined } from '../../core/utils/utils'


class InvoiceFeedAccordion extends EventAccordion {

    fetchData = async () => {
        const { invoiceID } = this.props

        if (valueIsDefined(invoiceID)) {
            const endpoint = DjangoUrls["invoices:api-invoices-feed-list"](window.MARKETPLACE_ENTITY_SLUG, invoiceID)
            const response = await fetch(endpoint)
            return await response.json()
        }
        else {
            return null
        }
    }

    getTitle = () => {
        return "Invoice Timeline"
    }

    getEmphasis = () => {
        return "time"
    }
}

export default InvoiceFeedAccordion;
