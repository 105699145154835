import clsx from "clsx"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

import styles from "./SkeletonCell.module.scss"
import { SkeletonCellProps } from "./SkeletonCell.types"

export default function SkeletonCell(props: SkeletonCellProps) {
    const { variant, cellWidth, isRightAligned, isAnimated = true } = props

    const renderSkeleton = () => {
        if (variant === "text") {
            return <Skeleton borderRadius={2} height={12} enableAnimation={isAnimated} />
        }

        if (variant === "header") {
            return <Skeleton borderRadius={2} height={10} enableAnimation={isAnimated} />
        }

        if (variant === "badge") {
            return <Skeleton borderRadius={6} height={24} enableAnimation={isAnimated} />
        }

        if (variant === "icon-text") {
            return (
                <>
                    <Skeleton borderRadius={12} height={12} width={12} enableAnimation={isAnimated} />
                    <Skeleton borderRadius={2} height={12} enableAnimation={isAnimated} />
                </>
            )
        }

        if (variant === "avatar") {
            return <Skeleton borderRadius={14} height={28} width={28} enableAnimation={isAnimated} />
        }
    }

    return (
        <td
            className={clsx(styles.base, styles[variant], {
                [styles.rightAligned]: isRightAligned,
            })}
            style={{
                minWidth: cellWidth,
                flexBasis: cellWidth,
            }}
        >
            {renderSkeleton()}
        </td>
    )
}
