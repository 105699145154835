import { Fragment } from "react";
import SearchOrCreateSelectInput from "../../core/inputs/SearchOrCreateSelectInput";
import { InventoryLocationTypes } from "../../core/utils/enums";


function getInventoryLocationObjectLabel(inventoryLocation) {
    return <Fragment>
        <span><strong>{inventoryLocation.name}</strong><br></br></span>
        {inventoryLocation.license_plate && <span className="select-label-secondary-level"><em>{inventoryLocation.license_plate}</em></span>}
    </Fragment>
}

function inventoryLocationToInventoryLocationOption(inventoryLocation) {
    return {value: inventoryLocation.id, label: getInventoryLocationObjectLabel(inventoryLocation), object: inventoryLocation}
}

function InventoryLocationSearchOrCreateSelect(props) {
    return <SearchOrCreateSelectInput
        objectName="Location"
        getObjectLabel={getInventoryLocationObjectLabel}
        listEndpoint={DjangoUrls["inventory:api-inventory-locations-list"](window.MARKETPLACE_ENTITY_SLUG)}
        listEndpointKwargs={[
            ["location_type", InventoryLocationTypes.warehouse],
            ["location_type", InventoryLocationTypes.drop_point],
        ]}
        getDetailsEndpoint={(inventoryLocation) => DjangoUrls["inventory:api-inventory-locations-detail"](window.MARKETPLACE_ENTITY_SLUG, inventoryLocation.id)}
        {...props}
    />
}

export { inventoryLocationToInventoryLocationOption };
export default InventoryLocationSearchOrCreateSelect
