import Spinner from "@legacy/core/components/Spinner";
import { Component, Fragment } from "react";
import { sendDataToServer } from "../../core/utils/utils";
import ButtonGroup from "../buttons/ButtonGroup";
import ButtonGroupRow from "../buttons/ButtonGroupRow";
import UniversalLinkButton from "../buttons/UniversalLinkButton";
import Banner from "./Banner";


class SettingsPaymentsConfigPanel extends Component {

    constructor(props) {
        super(props)
        this.addToastToQueue = this.props.addToastToQueue

        this.state = {
            paymentsConfig: null,

            errors: {
                paymentsConfig: {},
            },
            acceptOnlinePayments: false,
        }
    }

    componentDidMount = async () => {
        const endpoint = DjangoUrls["settings-retrieve-payments-config"]()
        const response = await fetch(endpoint)
        const json = await response.json()

        this.setState((state, props) => {
            let updatedState = state
            updatedState.paymentsConfig = json
            updatedState.acceptOnlinePayments = json.accept_online_payments

            return updatedState
        })

        // If the account link was successful, show a success message
        const urlParams = new URLSearchParams(window.location.search)
        const success = urlParams.get('success')

        if (success === "true") {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: "Stripe payments account updated",
            })

            const url = new URL(window.location.href)
            url.searchParams.delete('success')
            window.history.replaceState({}, "", url)
        }
    }

    saveConfig = async () => {
        const endpoint = DjangoUrls["settings-update-payments-config"]();
        const endpointMethod = "PUT"

        const dataName = "paymentsConfig"
        const submittingName = "submittingPaymentsConfig"
        const errorDictName = "paymentsConfig"

        const onSuccess = (json) => {
            this.setState((state, props) => {
                let updatedState = state
                updatedState[submittingName] = false
                return updatedState
            });
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: "Payments Settings updated",
            })
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Payments Settings could not be updated"
            })
        }

        const dataManipulator = (data, state) => {
            data.accept_online_payments = state.acceptOnlinePayments

            return data
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, dataManipulator, undefined)
    }

    toggleAcceptOnlinePayments = () => {
        this.setState(
            (state, props) => {
                let updatedState = state
                updatedState.acceptOnlinePayments = !state.acceptOnlinePayments
                return updatedState
            },
            this.saveConfig
        )
    }

    renderButtons = () => {
        if (this.state.submittingPaymentsConfig) {
            return <Spinner centered={true} />
        }
        else {
            if (this.state.unexpectedError) {
                return (
                    <div className="data-panel__action-feedback">
                        <span className="text-invalid"><strong>An unexpected error occurred.</strong></span>
                    </div>
                )
            }
            else {
                if (this.state.acceptOnlinePayments) {
                    return (
                        <ButtonGroup>
                            <ButtonGroupRow>
                                <UniversalLinkButton type="primary" text={<Fragment>{this.state.paymentsConfig.stripe_onboarding_details_submitted ? "Update Payments Account" : "Set Up Payments"}&nbsp;&nbsp;<i className="fa-sharp fa-light fa-arrow-up-right-from-square" aria-hidden="true"></i></Fragment>} destination={this.state.paymentsConfig.STRIPE_CONNECT_ONBOARDING_URL} />
                            </ButtonGroupRow>
                            {
                                this.state.paymentsConfig.stripe_onboarding_details_submitted && (
                                    <ButtonGroupRow>
                                        <UniversalLinkButton type="secondary" text={<Fragment>View Payments Dashboard&nbsp;&nbsp;<i className="fa-sharp fa-light fa-arrow-up-right-from-square" aria-hidden="true"></i></Fragment>} destination={this.state.paymentsConfig.STRIPE_CONNECT_DASHBOARD_URL} openNew={true} />
                                    </ButtonGroupRow>
                                )
                            }
                            {
                                this.state.paymentsConfig.STRIPE_CONNECT_EXPRESS_DASHBOARD_URL && (
                                    <ButtonGroupRow>
                                        <UniversalLinkButton type="secondary" text={<Fragment>View Old Payments Dashboard&nbsp;&nbsp;<i className="fa-sharp fa-light fa-arrow-up-right-from-square" aria-hidden="true"></i></Fragment>} destination={this.state.paymentsConfig.STRIPE_CONNECT_EXPRESS_DASHBOARD_URL} openNew={true} />
                                    </ButtonGroupRow>
                                )
                            }
                        </ButtonGroup>
                    )
                }
            }
        }
    }

    renderStripeStatus = () => {
        if (this.state.paymentsConfig.stripe_onboarding_details_submitted) {
            if (!this.state.paymentsConfig.stripe_onboarding_charges_enabled) {
                if (this.state.paymentsConfig.STRIPE_VERIFICATION_REQUIREMENTS.disabled_reason === "requirements.pending_verification") {
                    return <Banner type="info" text="Stripe is currently verifying the information you provided. Online payment collection is not yet enabled; please check back in a bit." />
                }
                else {
                    return <Banner type="warning" text='Stripe needs more information before you can collect payments online. Click "Update Payments Account" below to provide more details.' />
                }
            }
        }
        else {
            return <span className="text-lightened">Click "Set Up Payments" below to get started.</span>
        }
    }

    render() {
        if (this.state.paymentsConfig === null) {
            return (
                <div id="settings_payments">
                    <div className="data-panel-container">
                        <Spinner centered={true} />
                    </div>
                </div>
            )
        }
        else {
            return (
                <div id="settings_payments">
                    <div className="data-panel-container">
                        <div className="data-panel" aria-label="Configure Online Payments">
                            <div className="data-panel__heading" aria-label="Panel Information">
                                <span className="data-panel__heading__title" aria-label="Panel Header">Configure Online Payments</span>
                                <hr aria-hidden="true" />
                            </div>

                            <div className="data-panel__form" aria-label="Configure Payments">
                                <div className="data-panel__form__field" id="div_id_accept_online_payments" aria-label="Accept Online Payments">
                                    <label htmlFor="id_accept_online_payments" className="data-panel__form__field__label in-form">Accept Online Payments</label>
                                    <div className="data-panel__form__field__input data-panel__form__field__input--with-checkbox">
                                        <input type="checkbox" className="checkbox-input" name="accept_online_payments" id="id_accept_online_payments" checked={this.state.acceptOnlinePayments} onChange={event => this.toggleAcceptOnlinePayments()} />
                                        <label className="checkbox-label" htmlFor="id_accept_online_payments">Accept Online Card Payments</label>
                                    </div>
                                    {
                                        this.state.acceptOnlinePayments && this.state.submittingPaymentsConfig !== true && (
                                            <span className="text-lightened"><br />We use <strong>Stripe</strong> to make sure you get paid on time and to keep your personal and bank details secure.<br /><br />Card payments have a <strong>2.9% + 30¢</strong> processing fee.<br /><br /></span>
                                        )
                                    }
                                    {this.state.acceptOnlinePayments && this.renderStripeStatus()}
                                    {this.state.errors.paymentsConfig.accept_online_payments && <div className="data-panel__form__field__errors" aria-label="Field Errors">{this.state.errors.paymentsConfig.accept_online_payments}</div>}
                                </div>
                            </div>
                            {this.renderButtons()}
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default SettingsPaymentsConfigPanel;
