// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InputPrefixSuffix-base__PlFcu{position:relative;display:inline-flex;flex-shrink:0;align-items:center;justify-content:center;background:var(--gray-a2)}.InputPrefixSuffix-base__PlFcu::after{position:absolute;top:0;bottom:0;width:var(--size-0_25);background:var(--gray-a4);content:\"\"}.InputPrefixSuffix-prefix__Gc3ES::after{right:0}.InputPrefixSuffix-suffix__jlAeo::after{left:0}.InputPrefixSuffix-baseSm__lOXhH{min-width:var(--size-8);padding:var(--size-1_5)}.InputPrefixSuffix-baseMd__zV4HP{min-width:var(--size-9);padding:var(--size-2)}.InputPrefixSuffix-baseLg__wC5MG{min-width:var(--size-10);padding:var(--size-2)}.InputPrefixSuffix-icon__zF4O_{color:var(--gray-a9)}.InputPrefixSuffix-text__GEUkm{margin-bottom:var(--size-0)}.InputPrefixSuffix-textSm__OcGPC{font:var(--font-global-body-475);font-feature-settings:var(--feature-settings-global-body-475);letter-spacing:var(--letter-spacing-global-body-475)}.InputPrefixSuffix-textMd__IlHuU{font:var(--font-global-body-475);font-feature-settings:var(--feature-settings-global-body-475);letter-spacing:var(--letter-spacing-global-body-475)}.InputPrefixSuffix-disabled__jw0ic .InputPrefixSuffix-icon__zF4O_{color:var(--gray-a7)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "InputPrefixSuffix-base__PlFcu",
	"prefix": "InputPrefixSuffix-prefix__Gc3ES",
	"suffix": "InputPrefixSuffix-suffix__jlAeo",
	"baseSm": "InputPrefixSuffix-baseSm__lOXhH",
	"baseMd": "InputPrefixSuffix-baseMd__zV4HP",
	"baseLg": "InputPrefixSuffix-baseLg__wC5MG",
	"icon": "InputPrefixSuffix-icon__zF4O_",
	"text": "InputPrefixSuffix-text__GEUkm",
	"textSm": "InputPrefixSuffix-textSm__OcGPC",
	"textMd": "InputPrefixSuffix-textMd__IlHuU",
	"disabled": "InputPrefixSuffix-disabled__jw0ic"
};
export default ___CSS_LOADER_EXPORT___;
