import React, { Component } from "react";
import deepcopy from "rfdc";
import { valueIsDefined } from "../../core/utils/utils";
import InlineContactContainer from "../InlineContactContainer";
import { getContactKey } from "../utils/utils";


class ContactListEditSelect extends Component {

    constructor(props) {
        super(props)

        this.state = {
            deletedContactIDs: {},
        }
    }

    deleteContact = (index, contact) => {
        const { contacts, updateContacts } = this.props
        const contactKey = getContactKey(contact)

        // Save the ID in case this description is going to be reused
        this.setState((state, props) => {
            let updatedState = state
            if (valueIsDefined(contact.id)) {
                updatedState.deletedContactIDs[contactKey] = contact.id
            }
            return updatedState
        })

        let newContacts = deepcopy()(contacts)
        newContacts.splice(index, 1)
        updateContacts(newContacts)
    }

    updateContact = (index, contact) => {
        const { contacts, updateContacts } = this.props
        const contactKey = getContactKey(contact)

        // Re-use an existing ID for a new contact to avoid uniqueness constraint issues
        if (!valueIsDefined(contact.id)) {
            if (contactKey in this.state.deletedContactIDs) {
                contact.id = this.state.deletedContactIDs[contactKey]
            }
            else {
                contact.id = null
            }
        }

        let newContacts = deepcopy()(contacts)

        if (contact.primary) {
            for (let newContact of newContacts) {
                newContact.primary = false
            }
        }

        newContacts[index] = contact

        updateContacts(newContacts)
    }

    setPrimary = (contact) => {
        if (!valueIsDefined(contact.primary)) {
            const isNew = !valueIsDefined(contact.id)
            contact.primary = isNew ? false : contact.id === this.props.primaryContactId
        }
        return contact
    }

    renderContact = (contact, index) => {
        return <InlineContactContainer key={`contact_${getContactKey(contact)}_${contact.primary || false}_${contact.errors}`} allContacts={this.props.contacts} contact={contact} index={index} updateContact={newContact => this.updateContact(index, newContact)} deleteContact={newContact => this.deleteContact(index, newContact)} />
    }

    render = () => {
        const { fieldName, contacts, primaryContactId, updateContacts } = this.props

        return (
            <div id={`id_${fieldName}`} className="data-panel__list-select">
                {contacts.map(this.setPrimary).map(this.renderContact)}
            </div>
        )
    }
}

export default ContactListEditSelect;
