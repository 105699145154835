import Spinner from "@legacy/core/components/Spinner";
import { Component } from "react";
import deepcopy from "rfdc";
import { currencyFormatter, historyHasState, sendDataToServer } from "../core/utils/utils";
import ClientMergeConfirmDisplay from "./components/ClientMergeConfirmDisplay";
import ClientMergeForm from "./forms/ClientMergeForm";

const FORM_MODES = {
    MERGE_CLIENT: "MERGE_CLIENT",
    CONFIRM_MERGE: "CONFIRM_MERGE",
}

const FORM_MODE_SUBTITLES = {
    MERGE_CLIENT: "Merge Client",
    CONFIRM_MERGE: "Confirm Merge",
}

const FORM_MODE_BACK_BUTTON_DISPLAY = {
    MERGE_CLIENT: "flex",
    CONFIRM_MERGE: "none",
}

const PRIMARY_FORM_MODES = [FORM_MODES.MERGE_CLIENT]
const SECONDARY_FORM_MODES = [
    FORM_MODES.CONFIRM_MERGE,
]

const FORM_DATA_NAMES_BY_MODE = {
    MERGE_CLIENT: "mergeData",
    CONFIRM_MERGE: undefined,
}

const SUBMITTING_NAMES_BY_MODE = {
    MERGE_CLIENT: "submittingMerge",
    CONFIRM_MERGE: "submittingMerge",
}

const ERROR_NAMES_BY_MODE = {
    MERGE_CLIENT: "merge",
    CONFIRM_MERGE: "merge",
}


class ClientMergeContainer extends Component {

    // Initialize

    constructor(props) {
        super(props)

        const defaultMode = this.props.formMode || FORM_MODES.MERGE_CLIENT
        this.addToastToQueue = this.props.addToastToQueue

        this.state = {
            clientData: null,
            mergeData: {},

            selectedClient: null,

            errors: {
                merge: {},
            },

            currencyCode: window.CURRENCY_CODE,
            languageCode: window.LANGUAGE_CODE,

            defaultMode: defaultMode,
            mode: defaultMode,

            returnScroll: 0,
        }

        window.onpopstate = (event) => {
            if (event.state !== null && Object.keys(event.state).length) {
                this.setState(event.state)
            }
        }
    }

    componentDidMount = async () => {
        if (this.state.clientData === null) {
            const endpoint = DjangoUrls["clients:api-clients-detail"](window.MARKETPLACE_ENTITY_SLUG, window.CLIENT_ID)
            const response = await fetch(endpoint)

            let client

            if (response.ok) {
                client = await response.json()

                this.setState((state, props) => {
                    let updatedState = state
                    updatedState.clientData = client
                    updatedState.mergeData.source_client = client.id
                    return updatedState
                })
            }
        }

        if (historyHasState(history)) {
            document.querySelector(".page-subtitle").innerHTML = FORM_MODE_SUBTITLES[history.state.mode]
            document.querySelector(".back-button").style.display = FORM_MODE_BACK_BUTTON_DISPLAY[history.state.mode]
            this.setState(history.state)
        }
    }

    // Form helpers

    updateFormData = (formName, fieldName, fieldValue) => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState[formName][fieldName] = fieldValue
            return updatedState
        })
    }

    switchFormMode = (mode) => {
        document.querySelector(".page-subtitle").innerHTML = FORM_MODE_SUBTITLES[mode]
        document.querySelector(".back-button").style.display = FORM_MODE_BACK_BUTTON_DISPLAY[mode]

        if (SECONDARY_FORM_MODES.includes(mode)) {
            history.replaceState(this.state, "", "")
        }

        this.setState((state, props) => {
            let updatedState = state
            updatedState.mode = mode
            history.pushState(updatedState, "", "?mode=" + mode.toLowerCase().replace(/_/g, "-"));
            return updatedState
        })
    }

    switchToPrimaryForm = () => {
        this.setState((state, props) => {
            let updatedState = state

            // Clear the secondary form data
            updatedState[FORM_DATA_NAMES_BY_MODE[state.mode]] = {}
            updatedState[SUBMITTING_NAMES_BY_MODE[state.mode]] = false
            updatedState.errors[ERROR_NAMES_BY_MODE[state.mode]] = {}

            return updatedState
        })
        this.switchFormMode(this.state.defaultMode)
    }

    switchToSecondaryForm = (newFormMode, data, initialData) => {
        this.setState((state, props) => {
            let updatedState = state
            // Set the scroll state
            updatedState.returnScroll = document.querySelector(".main").scrollTop

            updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]] = {}

            if (data !== null) {
                updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]] = deepcopy()(data)
            }

            if (initialData !== null) {
                Object.assign(updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]], initialData)
            }

            return updatedState
        })

        this.switchFormMode(newFormMode)
    }

    updateClientSelection = (selectedClient) => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState.selectedClient = selectedClient

            if (selectedClient !== null) {
                updatedState.mergeData.destination_client = selectedClient.id
            }

            return updatedState
        })
    }

    // Merge

    mergeClients = async () => {
        const endpoint = DjangoUrls["clients:api-clients-merge"](window.MARKETPLACE_ENTITY_SLUG)
        const endpointMethod = "POST"
        const successUrl = DjangoUrls["clients:clients-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.selectedClient.id)
        const onSuccess = () => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Client "${this.state.clientData.name}" merged`,
                path: successUrl,
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }

        const dataName = "mergeData"
        const submittingName = "submittingMerge"
        const errorDictName = "merge"

        const setErrors = (fieldName, message, errorDict) => {
            if (fieldName === "non_field_errors" && message === "The fields service_company, name must make a unique set.") {
                errorDict["name"] = "A client with this name already exists."
            }
            else {
                errorDict[fieldName] = message
            }
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: `Client "${this.state.clientData.name}" could not be merged`,
            })
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, undefined, setErrors)
    }

    // Handle Actions

    handleActionRequest = (action) => {
        switch (action) {
            case "MERGE":
                this.mergeClients()
                break
            case "CONFIRM_MERGE":
                if (this.state.selectedClient !== null) {
                    this.setState((state, props) => {
                        let updatedState = state
                        updatedState.errors.merge = {}
                        return updatedState
                    })
                    this.switchToSecondaryForm("CONFIRM_MERGE", null, null)
                }
                else {
                    this.setState((state, props) => {
                        let updatedState = state
                        updatedState.errors.merge = {"destination_client": ["Please select a destination client."]}
                        return updatedState
                    })
                }
                break
            case "CANCEL_MERGE":
                location.assign(DjangoUrls["clients:clients-detail"](window.MARKETPLACE_ENTITY_SLUG, window.CLIENT_ID))
                break
            default:
                console.error(`No action handler exists for action "${action}".`)
        }
    }

    // Render

    render() {
        if (this.state.clientData === null) {
            return <Spinner centered={true} />
        }
        else {
            if (PRIMARY_FORM_MODES.includes(this.state.mode)) {
                return <ClientMergeForm
                    mode={this.state.mode}
                    mergeData={this.state.mergeData}
                    errors={this.state.errors.merge}
                    sourceClient={this.state.clientData}
                    destinationClient={this.state.selectedClient}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("mergeData", fieldName, fieldValue)}
                    requestAction={this.handleActionRequest}
                    switchToSecondaryForm={this.switchToSecondaryForm}
                    updateClientSelection={this.updateClientSelection}
                    returnScroll={this.state.returnScroll}
                ></ClientMergeForm>
            }
            else if (SECONDARY_FORM_MODES.includes(this.state.mode)) {
                return <ClientMergeConfirmDisplay
                    mode={this.state.mode}
                    submitting={this.state.submittingMerge}
                    errors={this.state.errors.merge}
                    sourceClient={this.state.clientData}
                    destinationClient={this.state.selectedClient}
                    formatCurrencyValue={currencyFormatter(this.state.currencyCode, this.state.languageCode)}
                    requestAction={this.handleActionRequest}
                    switchToPrimaryForm={this.switchToPrimaryForm}
                    returnScroll={0}
                ></ClientMergeConfirmDisplay>
            }
            else {
                return (
                    <div className="data-panel-container data-panel-container--with-margin">
                        <div className="data-panel" aria-label="Unknown Form Mode">
                            <div className="data-panel__form">
                                <p className="data-panel__form__caption">An unhandled form mode was supplied.</p>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    }
}

export default ClientMergeContainer;
