import EventAccordion from "../../core/components/EventAccordion"
import { valueIsDefined } from "../../core/utils/utils"


class EstimateFeedAccordion extends EventAccordion {

    fetchData = async () => {
        const { estimateID } = this.props

        if (valueIsDefined(estimateID)) {
            const endpoint = DjangoUrls["estimates:api-estimates-feed-list"](window.MARKETPLACE_ENTITY_SLUG, estimateID)
            const response = await fetch(endpoint)
            return await response.json()
        }
        else {
            return null
        }
    }

    getTitle = () => {
        return "Estimate Timeline"
    }

    getEmphasis = () => {
        return "time"
    }
}

export default EstimateFeedAccordion;
