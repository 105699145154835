import generateRandomKey from "@utils/generateRandomKey"

import { NavigationItem as NavigationItemType } from "@molecules/NavigationItem/NavigationItem.types"
import { NavigationItem, NavigationSection } from "@molecules/index"

import { SETTINGS_ROUTES } from "@routes/settings"

function SettingsNavigation() {
    const myAccountLinks: NavigationItemType[] = [
        {
            label: "Profile",
            module: "profile",
            path: SETTINGS_ROUTES.profile,
        },
        {
            label: "Preferences",
            module: "preferences",
            path: SETTINGS_ROUTES.preferences,
        },
        {
            label: "Security & access",
            module: "security_and_access",
            path: SETTINGS_ROUTES.securityAndAccess,
        },
        {
            label: "Notifications",
            module: "notifications",
            path: SETTINGS_ROUTES.notifications,
        },
    ]

    const workspaceLinks: NavigationItemType[] = [
        {
            label: "Payments",
            module: "payments",
            path: SETTINGS_ROUTES.payments,
        },
        {
            label: "CompanyCam",
            module: "company_cam",
            path: SETTINGS_ROUTES.companyCam,
        },
        {
            label: "Accounting",
            module: "accounting",
            path: SETTINGS_ROUTES.accounting,
        },
        {
            label: "Availability schedules",
            module: "availability_schedules",
            path: SETTINGS_ROUTES.availabilitySchedules,
        },
    ]

    return (
        <>
            <NavigationSection title="My account">
                {myAccountLinks.map((item) => (
                    <NavigationItem
                        key={generateRandomKey()}
                        label={item.label}
                        module={item.module}
                        path={item.path}
                        variant="primary"
                        isActive={item.isActive}
                        onActive={() => null}
                        showUserAvatar={item.module === "profile"}
                    />
                ))}
            </NavigationSection>
            <NavigationSection title="Workspace">
                {workspaceLinks.map((item) => (
                    <NavigationItem
                        key={generateRandomKey()}
                        label={item.label}
                        module={item.module}
                        path={item.path}
                        variant="primary"
                        isActive={item.isActive}
                        onActive={() => null}
                        showUserAvatar={item.module === "profile"}
                    />
                ))}
            </NavigationSection>
        </>
    )
}

export default SettingsNavigation
