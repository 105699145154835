function UserExtraCountAvatar(props) {
    const { extraCount } = props

    return (
        <div className="icon-circle-container user-avatar-container user-avatar-container--extra">
            <span>{`+${extraCount}`}</span>
        </div>
    )
}

export default UserExtraCountAvatar
