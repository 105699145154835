import { Component, Fragment } from 'react';
import UniversalButton from "../buttons/UniversalButton";
import LocationSearchInput from "../inputs/LocationSearchInput";

class AddressAutocompleteField extends Component {

    constructor(props) {
        super(props)

        this.state = {
            inputUsed: false
        }
    }

    render() {
        const {fieldName, fieldLabel, fieldValue, fieldOnChange, onAutocompleteSelect, onExpand, showExpandButton=true, disabled=false, optional=false, optionalText="optional", errors} = this.props

        return (
            <div className="data-panel__form__field" id={`div_id_${fieldName}`} aria-label={fieldLabel}>
                <label htmlFor={`id_${fieldName}`} className="data-panel__form__field__label in-form">{fieldLabel}{optional && <span className="text-optional"> ({optionalText})</span>}</label>
                <div className="data-panel__form__field__input data-panel__form__field__input--with-dropdown" title={disabled && fieldValue ? fieldValue : null}>
                    <LocationSearchInput fieldName={fieldName} defaultValue={fieldValue} disabled={disabled} onChange={event => {fieldOnChange(event.target.value); this.setState({inputUsed: true})}} onAutocompleteSelect={onAutocompleteSelect}></LocationSearchInput>
                    {showExpandButton && this.state.inputUsed && <UniversalButton type="list_select_action" text={<Fragment><i className="fa-sharp fa-regular fa-align-left" aria-hidden="true"></i>Enter Address Manually</Fragment>} handler={() => onExpand()} />}
                </div>
                {errors[fieldName] && <div className="data-panel__form__field__errors" aria-label="Field Errors">{errors[fieldName]}</div>}
            </div>
        )
    }
}

export default AddressAutocompleteField;
