// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModalHeader-base__G2pKG{position:relative;display:flex;flex:1 0 0;gap:var(--size-2);align-items:center;align-self:stretch;justify-content:space-between;padding:var(--size-2) var(--size-2) var(--size-2) var(--size-3_5);color:var(--gray-12);user-select:none;font:var(--font-global-heading-04);font-feature-settings:var(--feature-settings-global-heading-04);letter-spacing:var(--letter-spacing-global-heading-04)}.ModalHeader-iconButtonWrap__kFwMw{all:unset}.ModalHeader-divider__GEAn1::after{position:absolute;right:0;bottom:0;left:0;flex:1 0 0;width:var(--size-full);height:var(--size-0_25);background:var(--gray-a3);content:\"\"}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "ModalHeader-base__G2pKG",
	"iconButtonWrap": "ModalHeader-iconButtonWrap__kFwMw",
	"divider": "ModalHeader-divider__GEAn1"
};
export default ___CSS_LOADER_EXPORT___;
