import { Table } from "@organisms"

import { PaymentTermsCellProps } from "./PaymentTermsCell.types"

export default function PaymentTermsCell(props: PaymentTermsCellProps) {
    const { terms } = props

    const noTerms = !Number.isSafeInteger(terms)

    if (noTerms) {
        return <Table.Cells.NoValueCell />
    }

    const content = Number(terms) === 0 ? "Same Day" : `Net ${terms}`

    return <Table.Cells.TruncatedTextCell content={content} />
}
