import Spinner from "@legacy/core/components/Spinner"
import clsx from "clsx"

import styles from "@organisms/CardList/CardListEdgeStates/CardListEdgeStates.module.scss"

import { CardListLoadingStateProps } from "./CardListLoadingState.types"

export default function CardListLoadingState(props: CardListLoadingStateProps) {
    const { tabContentName, tabs, activeTab } = props

    const activeTabConfig = tabs?.find((tab) => tab.title === activeTab)

    if (!activeTabConfig) {
        return null
    }

    return (
        <div
            className={clsx(styles.base, styles.noScroll)}
            aria-label={`${tabContentName} List Loading`}
            role="tabpanel"
        >
            <div className={styles.content}>
                <Spinner />
            </div>
        </div>
    )
}
