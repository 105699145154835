import InvoiceDetailsContainer from "@legacy/invoices/InvoiceDetailsContainer"

import useIsDesktop from "@hooks/useIsDesktop"
import useSlugExtractor from "@hooks/useSlugExtractor"
import useToastQueue from "@hooks/useToastQueue"

import { TopBar } from "@organisms"

import { AppFrame } from "@templates"

import { INVOICES_ROUTES } from "@routes/invoices"

export default function InvoiceDetails() {
    const isDesktop = useIsDesktop()

    const [listRoute] = useSlugExtractor([INVOICES_ROUTES.LIST])
    const { addToastToQueue } = useToastQueue()

    return (
        <AppFrame.Root pageId="InvoiceDetails">
            <TopBar.Root>
                <TopBar.Header>
                    <TopBar.TopBarHeaderStart>
                        <TopBar.PageTitle
                            iconOnly={!isDesktop}
                            iconName="invoices"
                            label="Invoices"
                            destination={listRoute}
                        />
                        <TopBar.VerticalDivider />
                        <TopBar.PageSubtitle label="Invoice Details" backDestination={listRoute} />
                    </TopBar.TopBarHeaderStart>
                </TopBar.Header>
            </TopBar.Root>
            <AppFrame.PageContent>
                <InvoiceDetailsContainer addToastToQueue={addToastToQueue} />
            </AppFrame.PageContent>
        </AppFrame.Root>
    )
}
