import * as DialogPrimitive from "@radix-ui/react-dialog"
import clsx from "clsx"
import { forwardRef } from "react"

import { Overlay } from "@particles/index"

import useModal from "@molecules/Modal/Modal.store"

import styles from "./ModalContent.module.scss"
import { ModalContentProps } from "./ModalContent.types"

function ModalContent(props: ModalContentProps, ref: React.Ref<HTMLDivElement>) {
    const { children, className, ...rest } = props

    const { isOpen, closeModal } = useModal()

    return (
        <DialogPrimitive.DialogPortal>
            <Overlay isActive={isOpen} onClick={closeModal} />
            <DialogPrimitive.Content ref={ref} className={styles.base} {...rest}>
                <div className={clsx(styles.dialog, className)}>{children}</div>
            </DialogPrimitive.Content>
        </DialogPrimitive.DialogPortal>
    )
}

export default forwardRef(ModalContent)
