import formatDuration from "@utils/formatDuration";
import { Component, Fragment } from "react";
import { renderServiceLocationString } from "../../clients/utils/utils";
import ButtonGroup from "../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../core/buttons/UniversalButton";
import AttachmentHorizontalDisplay from "../../core/components/AttachmentHorizontalDisplay";
import ClientInformationDisplay from "../../core/components/ClientInformationDisplay";
import ContactListDisplay from "../../core/components/ContactListDisplay";
import BasicDisplayField from "../../core/fields/BasicDisplayField";
import LinkedObjectsDisplayField from "../../core/fields/LinkedObjectsDisplayField";
import ShowMoreTextRenderer from "../../core/renderers/ShowMoreTextRenderer";
import { JobOriginTypes } from "../../core/utils/enums";
import { format_phone_number_with_extension, renderInvoiceEstimateDate, valueIsDefined } from "../../core/utils/utils";
import EquipmentDetailsAccordion from "../../equipment/components/EquipmentDetailsAccordion";
import EstimateObjectLink from "../../estimates/components/EstimateObjectLink";
import InvoiceObjectLink from "../../invoices/components/InvoiceObjectLink";
import JobObjectLink from "../../jobs/components/JobObjectLink";
import PurchaseOrderObjectLink from "../../purchase_orders/components/PurchaseOrderObjectLink";
import { getJobStatusBarLabel, getJobStatusColorClass, renderJobTime } from "../utils/utils";
import JobFeedAccordion from "./JobFeedAccordion";
import JobHistoryAccordion from "./JobHistoryAccordion";
import JobSummaryAccordion from "./JobSummaryAccordion";


class JobDetailsCard extends Component {

    componentDidMount() {
        // Return to the previous scroll
        document.querySelector(".main").scrollTo(0, this.props.returnScroll || 0)
    }

    renderResumeDraftButton = (childObjects, buttonType, endpointName, buttonText) => {
        return childObjects.filter(object => object.is_draft).map(object => (
            <ButtonGroupRow>
                <UniversalButton type={buttonType} text={buttonText} handler={event => location.assign(DjangoUrls[endpointName](window.MARKETPLACE_ENTITY_SLUG, object.id))} />
            </ButtonGroupRow>
        )).shift()
    }

    renderButtons = () => {
        const { job, requestAction, switchToSecondaryForm, childEstimates, childInvoices, childCallbacks, purchaseOrders } = this.props

        if (window.USING_PUBLIC_URL === true) {
            return (
                <ButtonGroup>
                    <ButtonGroupRow>
                        <UniversalButton type="secondary" text="Download PDF" handler={event => requestAction("JOB_DOWNLOAD_PDF")} />
                    </ButtonGroupRow>
                </ButtonGroup>
            )
        }
        else if (window.USER_TYPE === "SERVICE_DISPATCHER" || window.USER_IS_ASSIGNED_TECHNICIAN === true) {
            if (job.state_label === "Pending") {
                if (window.USER_TYPE === "SERVICE_DISPATCHER") {
                    if (job.status_label === "Unscheduled") {
                        return (
                            <ButtonGroup>
                                <ButtonGroupRow>
                                    <UniversalButton type="primary" text="Schedule Job" handler={event => requestAction("JOB_SCHEDULE")} />
                                </ButtonGroupRow>
                                {
                                    window.FILE_UPLOADS_ENABLED && (
                                        <ButtonGroupRow>
                                            <UniversalButton type="secondary" text="Update Attachments" handler={event => requestAction("UPLOAD_ATTACHMENTS")} />
                                        </ButtonGroupRow>
                                    )
                                }
                                <ButtonGroupRow>
                                    <UniversalButton type="secondary" text="Download PDF" handler={event => requestAction("JOB_DOWNLOAD_PDF")} />
                                    <UniversalButton type="danger" text="Cancel Job" handler={event => requestAction("JOB_CANCEL")} />
                                </ButtonGroupRow>
                            </ButtonGroup>
                        )
                    }
                    else {
                        return (
                            <ButtonGroup>
                                <ButtonGroupRow>
                                    <UniversalButton type="secondary" text="Download PDF" handler={event => requestAction("JOB_DOWNLOAD_PDF")} />
                                    <UniversalButton type="secondary" text="Edit Draft" handler={event => requestAction("JOB_EDIT")} />
                                </ButtonGroupRow>
                            </ButtonGroup>
                        )
                    }
                }
            }
            else if (job.state_label === "Scheduled") {
                return (
                    <ButtonGroup>
                        <ButtonGroupRow>
                            <UniversalButton type="primary" text={job.initial_started_time !== null ? "Resume Job" : "Start Job"} handler={event => requestAction("JOB_START")} />
                        </ButtonGroupRow>
                        {
                            <Fragment>
                                {
                                    window.USER_TYPE === "SERVICE_DISPATCHER" && (
                                        <ButtonGroupRow>
                                            <UniversalButton type="secondary" text="Edit Job" handler={event => requestAction("JOB_EDIT")} />
                                            <UniversalButton type="danger" text="Cancel Job" handler={event => requestAction("JOB_CANCEL")} />
                                        </ButtonGroupRow>
                                    )
                                }
                                {
                                    job.initial_started_time !== null && <>
                                        <ButtonGroupRow>
                                            <UniversalButton type="secondary" text="Edit Equipment" handler={event => requestAction("EQUIPMENT_EDIT")} />
                                        </ButtonGroupRow>
                                        <ButtonGroupRow>
                                            <UniversalButton type="secondary" text="Edit Job Summary" handler={event => requestAction("JOB_EDIT_SUMMARY")} />
                                        </ButtonGroupRow>
                                    </>
                                }
                                {
                                    window.FILE_UPLOADS_ENABLED && (
                                        <ButtonGroupRow>
                                            <UniversalButton type="secondary" text="Update Attachments" handler={event => requestAction("UPLOAD_ATTACHMENTS")} />
                                        </ButtonGroupRow>
                                    )
                                }
                                {
                                    window.USER_TYPE === "SERVICE_DISPATCHER" && job.initial_started_time === null && (
                                        <ButtonGroupRow>
                                            <UniversalButton type="secondary" text="Unschedule Job" handler={event => requestAction("JOB_UNSCHEDULE")} />
                                        </ButtonGroupRow>
                                    )
                                }
                                {
                                    window.USER_TYPE === "SERVICE_DISPATCHER" && (
                                        <ButtonGroupRow>
                                            <UniversalButton type="secondary" text="Download PDF" handler={event => requestAction("JOB_DOWNLOAD_PDF")} />
                                        </ButtonGroupRow>
                                    )
                                }
                            </Fragment>
                        }
                    </ButtonGroup>
                )
            }
            else if (job.status_label === "Active") {
                return (
                    <ButtonGroup>
                        <ButtonGroupRow>
                            <UniversalButton type="primary" text="Complete Job" handler={event => requestAction("JOB_COMPLETE")} />
                        </ButtonGroupRow>
                        <ButtonGroupRow>
                            {window.USER_TYPE === "SERVICE_DISPATCHER" && <UniversalButton type="secondary" text="Reschedule Job" handler={event => requestAction("JOB_RESCHEDULE")} />}
                            <UniversalButton type="secondary" text="Pause Job" handler={event => requestAction("JOB_PAUSE")} />
                        </ButtonGroupRow>
                        <ButtonGroupRow>
                            <UniversalButton type="secondary" text="Edit Equipment" handler={event => requestAction("EQUIPMENT_EDIT")} />
                        </ButtonGroupRow>
                        {
                            window.USER_TYPE === "SERVICE_DISPATCHER" && (
                                <ButtonGroupRow>
                                    <UniversalButton type="secondary" text="Edit Job Details" handler={event => requestAction("JOB_EDIT_DETAILS")} />
                                </ButtonGroupRow>
                            )
                        }
                        <ButtonGroupRow>
                            <UniversalButton type="secondary" text="Edit Job Summary" handler={event => requestAction("JOB_EDIT_SUMMARY")} />
                        </ButtonGroupRow>
                        {
                            window.FILE_UPLOADS_ENABLED && (
                                <ButtonGroupRow>
                                    <UniversalButton type="secondary" text="Update Attachments" handler={event => requestAction("UPLOAD_ATTACHMENTS")} />
                                </ButtonGroupRow>
                            )
                        }
                        {
                            window.USER_TYPE === "SERVICE_DISPATCHER" && (
                                <ButtonGroupRow>
                                    <UniversalButton type="secondary" text="Download PDF" handler={event => requestAction("JOB_DOWNLOAD_PDF")} />
                                </ButtonGroupRow>
                            )
                        }
                    </ButtonGroup>
                )
            }
            else if (job.status_label === "Quick-Paused" || job.status_label === "On Hold") {
                return (
                    <ButtonGroup>
                        <ButtonGroupRow>
                            <UniversalButton type="primary" text="Resume Job" handler={event => requestAction("JOB_RESUME")} />
                        </ButtonGroupRow>
                        {
                            window.USER_TYPE === "SERVICE_DISPATCHER" && (
                                <ButtonGroupRow>
                                    <UniversalButton type="secondary" text="Reschedule Job" handler={event => requestAction("JOB_RESCHEDULE")} />
                                </ButtonGroupRow>
                            )
                        }
                        <ButtonGroupRow>
                            <UniversalButton type="secondary" text="Change Pause Status" handler={event => requestAction("JOB_PAUSE")} />
                        </ButtonGroupRow>
                        <ButtonGroupRow>
                            <UniversalButton type="secondary" text="Edit Equipment" handler={event => requestAction("EQUIPMENT_EDIT")} />
                        </ButtonGroupRow>
                        {
                            window.USER_TYPE === "SERVICE_DISPATCHER" && (
                                <ButtonGroupRow>
                                    <UniversalButton type="secondary" text="Edit Job Details" handler={event => requestAction("JOB_EDIT_DETAILS")} />
                                </ButtonGroupRow>
                            )
                        }
                        <ButtonGroupRow>
                            <UniversalButton type="secondary" text="Edit Job Summary" handler={event => requestAction("JOB_EDIT_SUMMARY")} />
                        </ButtonGroupRow>
                        {
                            window.FILE_UPLOADS_ENABLED && (
                                <ButtonGroupRow>
                                    <UniversalButton type="secondary" text="Update Attachments" handler={event => requestAction("UPLOAD_ATTACHMENTS")} />
                                </ButtonGroupRow>
                            )
                        }
                        {
                            window.USER_TYPE === "SERVICE_DISPATCHER" && (
                                <ButtonGroupRow>
                                    <UniversalButton type="secondary" text="Download PDF" handler={event => requestAction("JOB_DOWNLOAD_PDF")} />
                                </ButtonGroupRow>
                            )
                        }
                    </ButtonGroup>
                )
            }
            else if (job.state_label === "Completed") {
                if (job.is_job_walk) {
                    return (
                        <ButtonGroup>
                            {
                                window.USER_TYPE === "SERVICE_DISPATCHER" && childEstimates.length === 0 && (
                                    <Fragment>
                                        <ButtonGroupRow>
                                            <UniversalButton type="primary" text="Create Estimate" handler={event => requestAction("JOB_CREATE_ESTIMATE")} />
                                        </ButtonGroupRow>
                                        <ButtonGroupRow>
                                            <UniversalButton type="secondary" text="Mark as Estimated" handler={event => requestAction("JOB_MARK_AS_ESTIMATED")} />
                                        </ButtonGroupRow>
                                    </Fragment>
                                )
                            }
                            {window.USER_TYPE === "SERVICE_DISPATCHER" && this.renderResumeDraftButton(childEstimates, "primary", "estimates:estimates-update", "Resume Draft Estimate")}
                            {
                                <ButtonGroupRow>
                                    <UniversalButton type="secondary" text="Edit Equipment" handler={event => requestAction("EQUIPMENT_EDIT")} />
                                </ButtonGroupRow>
                            }
                            {
                                window.USER_TYPE === "SERVICE_DISPATCHER" && (
                                    <ButtonGroupRow>
                                        <UniversalButton type="secondary" text="Edit Job Details" handler={event => requestAction("JOB_EDIT_DETAILS")} />
                                    </ButtonGroupRow>
                                )
                            }
                            {
                                window.JOB_SEND_ENABLED && (
                                    <ButtonGroupRow>
                                        <UniversalButton type="secondary" text="Resend Job Summary" handler={event => requestAction("JOB_SEND")} />
                                    </ButtonGroupRow>
                                )
                            }
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Edit Job Summary" handler={event => requestAction("JOB_EDIT_SUMMARY")} />
                            </ButtonGroupRow>
                            {
                                window.FILE_UPLOADS_ENABLED && (
                                    <ButtonGroupRow>
                                        <UniversalButton type="secondary" text="Update Attachments" handler={event => requestAction("UPLOAD_ATTACHMENTS")} />
                                    </ButtonGroupRow>
                                )
                            }
                            {
                                window.USER_TYPE === "SERVICE_DISPATCHER" && childEstimates.length === 0 && (
                                    <ButtonGroupRow>
                                        <UniversalButton type="secondary" text="Resume Job" handler={event => requestAction("JOB_RESUME_FROM_COMPLETED")} />
                                    </ButtonGroupRow>
                                )
                            }
                            {
                                window.USER_TYPE === "SERVICE_DISPATCHER" && (
                                    <ButtonGroupRow>
                                        <UniversalButton type="secondary" text="Download PDF" handler={event => requestAction("JOB_DOWNLOAD_PDF")} />
                                    </ButtonGroupRow>
                                )
                            }
                        </ButtonGroup>
                    )
                }
                else {
                    return (
                        <ButtonGroup>
                            {
                                window.USER_TYPE === "SERVICE_DISPATCHER" && childInvoices.length === 0 && (
                                    <Fragment>
                                        <ButtonGroupRow>
                                            <UniversalButton type="primary" text="Create Invoice" handler={event => requestAction("JOB_CREATE_INVOICE")} />
                                        </ButtonGroupRow>
                                        <ButtonGroupRow>
                                            <UniversalButton type="secondary" text="Mark as Invoiced" handler={event => requestAction("JOB_MARK_AS_INVOICED")} />
                                        </ButtonGroupRow>
                                    </Fragment>
                                )
                            }
                            {window.USER_TYPE === "SERVICE_DISPATCHER" && this.renderResumeDraftButton(childInvoices, "primary", "invoices:invoices-update", "Resume Draft Invoice")}
                            {
                                <ButtonGroupRow>
                                    <UniversalButton type="secondary" text="Edit Equipment" handler={event => requestAction("EQUIPMENT_EDIT")} />
                                </ButtonGroupRow>
                            }
                            {
                                window.USER_TYPE === "SERVICE_DISPATCHER" && (
                                    <ButtonGroupRow>
                                        <UniversalButton type="secondary" text="Edit Job Details" handler={event => requestAction("JOB_EDIT_DETAILS")} />
                                    </ButtonGroupRow>
                                )
                            }
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Edit Job Summary" handler={event => requestAction("JOB_EDIT_SUMMARY")} />
                            </ButtonGroupRow>
                            {
                                window.JOB_SEND_ENABLED && (
                                    <ButtonGroupRow>
                                        <UniversalButton type="secondary" text="Resend Job Summary" handler={event => requestAction("JOB_SEND")} />
                                    </ButtonGroupRow>
                                )
                            }
                            {
                                window.FILE_UPLOADS_ENABLED && (
                                    <ButtonGroupRow>
                                        <UniversalButton type="secondary" text="Update Attachments" handler={event => requestAction("UPLOAD_ATTACHMENTS")} />
                                    </ButtonGroupRow>
                                )
                            }
                            {
                                window.USER_TYPE === "SERVICE_DISPATCHER" && childInvoices.length === 0 && (
                                    <ButtonGroupRow>
                                        <UniversalButton type="secondary" text="Resume Job" handler={event => requestAction("JOB_RESUME_FROM_COMPLETED")} />
                                    </ButtonGroupRow>
                                )
                            }
                            {
                                window.USER_TYPE === "SERVICE_DISPATCHER" && (
                                    <ButtonGroupRow>
                                        <UniversalButton type="secondary" text="Download PDF" handler={event => requestAction("JOB_DOWNLOAD_PDF")} />
                                    </ButtonGroupRow>
                                )
                            }
                        </ButtonGroup>
                    )
                }
            }
            else if (job.state_label === "Finalized") {
                return (
                    <ButtonGroup>
                        {
                            !job.is_job_walk && window.USER_TYPE === "SERVICE_DISPATCHER" && childCallbacks.length === 0 && (
                                <ButtonGroupRow>
                                    <UniversalButton type="secondary" text="Create Callback" handler={event => requestAction("JOB_CREATE_CALLBACK")} />
                                </ButtonGroupRow>
                            )
                        }
                        {
                            window.JOB_SEND_ENABLED && (
                                <ButtonGroupRow>
                                    <UniversalButton type="secondary" text="Resend Job Summary" handler={event => requestAction("JOB_SEND")} />
                                </ButtonGroupRow>
                            )
                        }
                        {
                            window.USER_TYPE === "SERVICE_DISPATCHER" && (
                                <ButtonGroupRow>
                                    <UniversalButton type="secondary" text="Download PDF" handler={event => requestAction("JOB_DOWNLOAD_PDF")} />
                                </ButtonGroupRow>
                            )
                        }
                    </ButtonGroup>
                )
            }
            else if (job.state_label === "Cancelled") {
                return (
                    <ButtonGroup>
                        <ButtonGroupRow>
                            <UniversalButton type="secondary" text="Download PDF" handler={event => requestAction("JOB_DOWNLOAD_PDF")} />
                        </ButtonGroupRow>
                    </ButtonGroup>
                )
            }
        }
    }

    render() {
        const { job, requestAction, switchToSecondaryForm, childInvoices, childEstimates, childCallbacks, purchaseOrders, addToastToQueue } = this.props

        const showServiceSummary = (
            job.service_charges.length > 0 &&
            job.initial_started_time !== null &&
            !["Completed", "Finalized", "Cancelled"].includes(job.state_label)
        )

        return (
            <Fragment>
                <div className="data-panel-container data-panel-container--with-margin">
                    <div className={`data-panel-status-bar ${getJobStatusColorClass(job)}`} aria-label="Status"><span>{getJobStatusBarLabel(job)}{(window.USING_PUBLIC_URL === true && " Job")}</span></div>
                    <div className="data-panel with-status-bar" aria-label="Job Details">
                        <ClientInformationDisplay
                            client={job.service_location.external_client}
                            serviceLocation={job.service_location}
                            showOpenInMapsButton={job.service_location.physical_address_geocode_formatted && !window.USING_PUBLIC_URL}
                            separatorClass={getJobStatusColorClass(job)}
                        ></ClientInformationDisplay>
                        <div className="data-panel__form" aria-label="Job Details">
                            {
                                job.estimate !== null && !window.CURRENT_USER.hide_financial_context && (
                                    <LinkedObjectsDisplayField
                                        fieldName="estimate"
                                        fieldLabel="Created From Estimate"
                                        objectComponentList={[<EstimateObjectLink key="parent_estimate_0" estimate={job.estimate} destination={(window.USING_PUBLIC_URL === true || window.USER_TYPE !== "SERVICE_DISPATCHER") ? window.PUBLIC_ESTIMATE_URL : DjangoUrls["estimates:estimates-detail"](window.MARKETPLACE_ENTITY_SLUG, job.estimate.id)}></EstimateObjectLink>]}
                                    ></LinkedObjectsDisplayField>
                                )
                            }
                            {
                                job.is_callback && !window.CURRENT_USER.hide_financial_context && (
                                    <LinkedObjectsDisplayField
                                        fieldName="callback_to"
                                        fieldLabel="Callback To"
                                        objectComponentList={[<JobObjectLink key="callback_to_0" job={job.callback_to} destination={window.USING_PUBLIC_URL === true ? window.PUBLIC_CALLBACK_TO_URL : DjangoUrls["jobs:jobs-detail"](window.MARKETPLACE_ENTITY_SLUG, job.callback_to.id)}></JobObjectLink>]}
                                    ></LinkedObjectsDisplayField>
                                )
                            }
                            <BasicDisplayField
                                fieldName="id"
                                fieldLabel="Roopairs Job ID"
                                fieldValue={job.custom_id ? job.custom_id : job.id}
                            ></BasicDisplayField>
                            {
                                job.origin_type !== JobOriginTypes.roopairs && (
                                    <BasicDisplayField
                                        fieldName="origin_id"
                                        fieldLabel={`${job.origin_type !== JobOriginTypes.other ? job.origin_type_label : job.origin_type_other_name} ID`}
                                        fieldValue={job.origin_id}
                                    ></BasicDisplayField>
                                )
                            }
                            <BasicDisplayField
                                fieldName="date_received"
                                fieldLabel="Date Received"
                                fieldValue={renderInvoiceEstimateDate(job.date_received, job.service_company.preferred_timezone)}
                            ></BasicDisplayField>
                            <BasicDisplayField
                                fieldName="service_name"
                                fieldLabel="Service Type"
                                fieldValue={job.service_name}
                            ></BasicDisplayField>
                            <BasicDisplayField
                                fieldName="is_job_walk"
                                fieldLabel="Job Type"
                                fieldValue={job.is_callback ? "Callback" : job.is_job_walk ? "Job Walk" : "Service"}
                            ></BasicDisplayField>
                            {
                                window.USING_PUBLIC_URL === true && (
                                    <BasicDisplayField
                                        fieldName="service_company"
                                        fieldLabel="Service Company"
                                        fieldValue={job.service_company.name}
                                    ></BasicDisplayField>
                                )
                            }
                            <BasicDisplayField
                                fieldName="service_location"
                                fieldLabel="Service Location"
                                fieldValue={renderServiceLocationString(job.service_location, true)}
                            ></BasicDisplayField>
                            {
                                window.MARKETPLACE_ENTITY_SLUG === job.service_company.slug && valueIsDefined(job.service_location.notes) && !window.CURRENT_USER.hide_service_location_notes_on_job && (
                                    <BasicDisplayField
                                        fieldName="service_location_notes"
                                        fieldLabel={<Fragment>Service Location Notes <span className="text-optional">(Only Visible to You)</span></Fragment>}
                                        fieldValue={job.service_location.notes}
                                    ></BasicDisplayField>
                                )
                            }
                            {
                                window.MARKETPLACE_ENTITY_SLUG === job.service_company.slug && job.service_location.external_client && valueIsDefined(job.service_location.external_client.notes) && !window.CURRENT_USER.hide_client_notes_on_job && (
                                    <BasicDisplayField
                                        fieldName="client_notes"
                                        fieldLabel={<Fragment>Client Notes <span className="text-optional">(Only Visible to You)</span></Fragment>}
                                        fieldValue={job.service_location.external_client.notes}
                                    ></BasicDisplayField>
                                )
                            }
                            {
                                job.estimated_arrival_time && (
                                    <BasicDisplayField
                                        fieldName="estimated_arrival_time"
                                        fieldLabel="Scheduled For"
                                        fieldValue={renderJobTime(job.estimated_arrival_time, job.service_company.preferred_timezone, job.state_label === "Scheduled")}
                                    ></BasicDisplayField>
                                )
                            }
                            {
                                job.initial_started_time && (
                                    <BasicDisplayField
                                        fieldName="initial_started_time"
                                        fieldLabel="Started On"
                                        fieldValue={renderJobTime(job.initial_started_time, job.service_company.preferred_timezone)}
                                    ></BasicDisplayField>
                                )
                            }
                            {
                                showServiceSummary && (
                                    <div className="data-panel__form__field" aria-label="Service Summary To Date">
                                        <span className="data-panel__form__field__label" aria-hidden="true">Service Summary To Date</span>
                                        <div className="data-panel__form__field__display-list" aria-label="Service Summary">
                                            {
                                                job.service_charges.map((item) => (
                                                    <div key={item.id} className="data-panel__form__field__display-list__object line-item-display">
                                                        <div className="line-item-display__row">
                                                            <div className="line-item-display__field">
                                                                <div className="line-item-display__field-label">Short Description</div>
                                                                <div className="line-item-display__field-value">
                                                                    {item.description}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="line-item-display__row">
                                                            <div className="line-item-display__field">
                                                                <div className="line-item-display__field-label">Service Summary</div>
                                                                <div className="line-item-display__field-value preserve-whitespace">
                                                                    <ShowMoreTextRenderer text={item.summary} startClamped={window.USING_PUBLIC_URL === false}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>
                                )
                            }
                            {
                                job.last_status_transition_time !== null && (
                                    <BasicDisplayField
                                        fieldName="last_status_transition_time"
                                        fieldLabel={`${["Active", "Quick-Paused"].includes(job.status_label) ? job.status_label : job.state_label} On`}
                                        fieldValue={renderJobTime(job.last_status_transition_time, job.service_company.preferred_timezone)}
                                    ></BasicDisplayField>
                                )
                            }
                            {
                                job.substatus && (
                                    <BasicDisplayField
                                        fieldName="substatus_label"
                                        fieldLabel={`${["Active", "Quick-Paused"].includes(job.status_label) ? job.status_label : job.state_label} SubStatus`}
                                        fieldValue={job.substatus_label}
                                    ></BasicDisplayField>
                                )
                            }
                            {
                                job.last_status_transition_message !== "" && (
                                    <BasicDisplayField
                                        fieldName="last_status_transition_message"
                                        fieldLabel={`${["Active", "Quick-Paused"].includes(job.status_label) ? job.status_label : job.state_label} Message`}
                                        fieldValue={job.substatus_reason_label ? <span><i>{job.substatus_reason_label}</i><br />{job.last_status_transition_message}</span> : job.last_status_transition_message}
                                    ></BasicDisplayField>
                                )
                            }
                            <BasicDisplayField
                                fieldName="last_modified_at"
                                fieldLabel="Last Modified"
                                fieldValue={renderJobTime(job.last_modified_at, job.service_company.preferred_timezone)}
                            ></BasicDisplayField>
                            {
                                job.estimated_duration && !(window.USING_PUBLIC_URL === true) && (
                                    <BasicDisplayField
                                        fieldName="estimated_duration"
                                        fieldLabel="Estimated Job Duration"
                                        fieldValue={formatDuration(job.estimated_duration)}
                                    ></BasicDisplayField>
                                )
                            }
                            {
                                job.assigned_technicians.length !== 0 && (
                                    <BasicDisplayField
                                        fieldName="assigned_technicians"
                                        fieldLabel="Assigned Technician(s)"
                                        fieldValue={job.assigned_technicians.map((technician, index) => <span key={index}>{technician.full_name}{job.assigned_technicians.length !== index + 1 && <br />}</span>)}
                                    ></BasicDisplayField>
                                )
                            }
                            <BasicDisplayField
                                fieldName="details"
                                fieldLabel={job.is_callback ? "Reason For Callback" : "Reason For Call"}
                                fieldValue={job.details || "--"}
                            ></BasicDisplayField>
                            {
                                window.MARKETPLACE_ENTITY_SLUG === job.service_company.slug && job.dispatcher_notes !== "" && (
                                    <BasicDisplayField
                                        fieldName="dispatcher_notes"
                                        fieldLabel={<Fragment>Technician Instructions <span className="text-optional">(Only Visible to You)</span></Fragment>}
                                        fieldValue={job.dispatcher_notes}
                                    ></BasicDisplayField>
                                )
                            }
                            {
                                window.MARKETPLACE_ENTITY_SLUG === job.service_company.slug && job.tags.length !== 0 && (
                                    <BasicDisplayField
                                        fieldName="tags"
                                        fieldLabel={<Fragment>Tags <span className="text-optional">(Only Visible to You)</span></Fragment>}
                                        fieldValue={job.tags.map((tag, index) => <span key={`tag_${index}`} className="data-tag">{tag}</span>)}
                                    ></BasicDisplayField>
                                )
                            }
                            {
                                window.MARKETPLACE_ENTITY_SLUG === job.service_company.slug && job.companycam_project_url !== "" && (
                                    <BasicDisplayField
                                        fieldName="companycam_project_url"
                                        fieldLabel={<Fragment>CompanyCam <span className="text-optional">(Only Visible to You)</span></Fragment>}
                                        fieldValue={<a className="text-link text-link--inline" href={job.companycam_project_url} target="_blank">Open CompanyCam Project&nbsp;&nbsp;<i className="fa-sharp fa-light fa-arrow-up-right-from-square" aria-hidden="true"></i></a>}
                                    ></BasicDisplayField>
                                )
                            }
                            {
                                job.reporter && (
                                    <ContactListDisplay
                                        fieldName="contacts_reporter"
                                        fieldLabel="Job Reporter"
                                        contacts={[job.reporter]}
                                    ></ContactListDisplay>
                                )
                            }
                            {
                                job.point_of_contact && (
                                    <ContactListDisplay
                                        fieldName="contacts_job_point_of_Contact"
                                        fieldLabel="Job Contact"
                                        contacts={[job.point_of_contact]}
                                    ></ContactListDisplay>
                                )
                            }
                            {
                                job.service_location.primary_contact && (
                                    <ContactListDisplay
                                        fieldName="contacts_service_location"
                                        fieldLabel="Location Contact"
                                        contacts={job.service_location.contacts.filter(contact => contact.id === job.service_location.primary_contact)}
                                    ></ContactListDisplay>
                                )
                            }
                            {
                                job.service_location.external_client && job.service_location.external_client.primary_contact && (
                                    <ContactListDisplay
                                        fieldName="contacts_client"
                                        fieldLabel="Client Contact"
                                        contacts={job.service_location.external_client.contacts.filter(contact => contact.id === job.service_location.external_client.primary_contact)}
                                    ></ContactListDisplay>
                                )
                            }
                            {
                                window.USING_PUBLIC_URL === true && (
                                    <ContactListDisplay
                                        fieldName="contacts_service_company"
                                        fieldLabel="Service Company Contact"
                                        contacts={[
                                            {
                                                "name": job.service_company.name,
                                                "phone": job.service_company.phone ? format_phone_number_with_extension(job.service_company.phone, job.service_company.phone_extension) : "--",
                                                "email": job.service_company.email || "--",
                                            }
                                        ]}
                                    ></ContactListDisplay>
                                )
                            }
                            {
                                !(window.USING_PUBLIC_URL === true) && childEstimates.length !== 0 && !window.CURRENT_USER.hide_financial_context && (
                                    <LinkedObjectsDisplayField
                                        fieldName="estimates"
                                        fieldLabel="Estimates"
                                        objectComponentList={childEstimates.map((estimate, index) => <EstimateObjectLink key={`child_estimate_${index}`} estimate={estimate} destination={DjangoUrls["estimates:estimates-detail"](window.MARKETPLACE_ENTITY_SLUG, estimate.id)}></EstimateObjectLink>)}
                                    ></LinkedObjectsDisplayField>
                                )
                            }
                            {
                                !(window.USING_PUBLIC_URL === true) && childCallbacks.length !== 0 && !window.CURRENT_USER.hide_financial_context && (
                                    <LinkedObjectsDisplayField
                                        fieldName="callbacks"
                                        fieldLabel="Callbacks"
                                        objectComponentList={childCallbacks.map((callback, index) => <JobObjectLink key={`callbacks_${index}`} job={callback} destination={DjangoUrls["jobs:jobs-detail"](window.MARKETPLACE_ENTITY_SLUG, callback.id)}></JobObjectLink>)}
                                    ></LinkedObjectsDisplayField>
                                )
                            }
                            {
                                !(window.USING_PUBLIC_URL === true) && childInvoices.length !== 0 && !window.CURRENT_USER.hide_financial_context && (
                                    <LinkedObjectsDisplayField
                                        fieldName="invoices"
                                        fieldLabel="Invoices"
                                        objectComponentList={childInvoices.map((invoice, index) => <InvoiceObjectLink key={`child_invoice_${index}`} invoice={invoice} destination={DjangoUrls["invoices:invoices-detail"](window.MARKETPLACE_ENTITY_SLUG, invoice.id)}></InvoiceObjectLink>)}
                                    ></LinkedObjectsDisplayField>
                                )
                            }
                            {
                                !(window.USING_PUBLIC_URL === true) && purchaseOrders.length !== 0 && !window.CURRENT_USER.hide_financial_context && (
                                    <LinkedObjectsDisplayField
                                        fieldName="purchase_orders"
                                        fieldLabel="Purchase Orders"
                                        objectComponentList={purchaseOrders.map((purchaseOrder, index) => <PurchaseOrderObjectLink key={`purchase_order_${index}`} purchaseOrder={purchaseOrder} destination={DjangoUrls["purchase-orders:purchase-orders-detail"](window.MARKETPLACE_ENTITY_SLUG, purchaseOrder.id)}></PurchaseOrderObjectLink>)}
                                    ></LinkedObjectsDisplayField>
                                )
                            }
                            {
                                job.attachments.length !== 0 && job.service_company.feature_file_uploads_enabled && (
                                    <BasicDisplayField
                                        fieldName="attachments"
                                        fieldLabel="Attachments"
                                        fieldValue={<AttachmentHorizontalDisplay attachments={job.attachments} />}
                                    />
                                )
                            }
                        </div>
                        {this.renderButtons()}
                    </div>
                </div>
                {
                    job.equipment.sort((obj, other) => obj.display_name.localeCompare(other.display_name)).map(equipment => {
                        return (
                            <div key={`equipment_${equipment.id}`} className="accordion-wrapper">
                                <EquipmentDetailsAccordion data={equipment} clientID={job.service_location.external_client.id} editable={true} showJobHistory={window.USING_PUBLIC_URL !== true} addToastToQueue={addToastToQueue} />
                            </div>
                        )
                    })
                }
                {
                    job.initial_started_time && (
                        <div className="accordion-wrapper">
                            <JobSummaryAccordion job={job} publicMode={window.USING_PUBLIC_URL === true} />
                        </div>
                    )
                }
                {
                    !(window.USING_PUBLIC_URL === true) && (
                        <div className="accordion-wrapper">
                            <JobFeedAccordion jobID={job.id} totalTimeLogged={job.total_time_logged} />
                        </div>
                    )
                }
                {
                    !(window.USING_PUBLIC_URL === true) && (
                        <div className="accordion-wrapper">
                            <JobHistoryAccordion jobID={job.id} />
                        </div>
                    )
                }
                {
                    window.USING_PUBLIC_URL === true && (
                        <div className="powered-by-panel">
                            <span>Powered By</span>
                            <img className="logo--grey" src={window.LOGOTYPE_URL} width="148px" alt="Roopairs"></img>
                        </div>
                    )
                }
            </Fragment>
        )
    }

}

export default JobDetailsCard;
