import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import clsx from "clsx"
import { forwardRef, useMemo } from "react"

import debounce from "@utils/debounce"

import { Icon } from "@atoms"
import { IconSize } from "@atoms/Icon/Icon.types"

import styles from "./Button.module.scss"
import { ButtonProps } from "./Button.types"

function Button(props: ButtonProps, ref: React.Ref<HTMLButtonElement>) {
    const {
        children,
        colorScheme,
        size,
        variant,
        type = "button",
        className,

        isDisabled = false,
        isLoading = false,
        _isFocused = false,
        _isHovered = false,
        isPressed = false,
        isTabbable = true,
        isFullWidth = false,

        loadingText,

        leftIcon,
        rightIcon,

        onClick,
        noDebounce = false,

        ...rest
    } = props

    const iconSize = useMemo(() => {
        const sizeMap: { [key in ButtonProps["size"]]: IconSize } = {
            xs: 12,
            sm: 14,
            md: 14,
            lg: 16,
            xl: 16,
        }
        return sizeMap[size]
    }, [size])

    return (
        <button
            className={clsx(
                styles.base,
                className,
                {
                    [styles.isLoading]: isLoading,
                    [styles.isFocused]: _isFocused,
                    [styles.isHovered]: _isHovered,
                    [styles.isPressed]: isPressed,
                    [styles.iconOnly]: !children && !isLoading && (leftIcon || rightIcon) && !(leftIcon && rightIcon),
                    [styles.fullWidth]: isFullWidth,
                },
                styles[`${colorScheme}ColorScheme`],
                styles[`${variant}Variant`],
                styles[`${size}Size`],
            )}
            type={type}
            onClick={
                noDebounce
                    ? onClick
                    : debounce((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onClick?.(event))
            }
            disabled={isDisabled || isLoading}
            aria-disabled={isDisabled}
            aria-busy={isLoading}
            aria-pressed={isPressed}
            tabIndex={isTabbable && !isDisabled ? 0 : -1}
            ref={ref}
            {...rest}
        >
            {leftIcon && (
                <span
                    className={clsx(styles.iconContainerBase, styles[`${size}IconContainer`], {
                        [styles.iconHidden]: isLoading,
                    })}
                >
                    <Icon icon={leftIcon} size={iconSize} />
                </span>
            )}
            {children && (
                <span
                    className={clsx({
                        [styles.childrenHidden]: isLoading && !loadingText,
                        [styles.loadingText]: isLoading && loadingText,
                        [styles.content]: !isLoading,
                    })}
                >
                    {isLoading && loadingText ? loadingText : children}
                </span>
            )}
            {isLoading && (
                <span className={clsx(styles.spinnerWrapper, { [styles.spinnerWrapperCenter]: !loadingText })}>
                    <Icon
                        icon={
                            variant === "solid"
                                ? icon({
                                      name: "spinner-third",
                                      style: "solid",
                                      family: "sharp",
                                  })
                                : icon({
                                      name: "spinner-third",
                                      style: "regular",
                                      family: "sharp",
                                  })
                        }
                        spin={true}
                        size={iconSize}
                    />
                </span>
            )}
            {rightIcon && (
                <span
                    className={clsx(styles.iconContainerBase, styles[`${size}IconContainer`], {
                        [styles.iconHidden]: isLoading,
                    })}
                >
                    <Icon icon={rightIcon} size={iconSize} />
                </span>
            )}
        </button>
    )
}

export default forwardRef(Button)
