// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TableViewTabs-base__Finig{position:relative;display:flex;align-items:center;height:var(--size-full);overflow-x:auto}@media only screen and (min-width: 64rem){.TableViewTabs-base__Finig{margin:var(--size-0) calc(var(--size-0_5)*-1)}}.TableViewTabs-base__Finig::before{position:absolute;top:var(--size-0);bottom:var(--size-0);left:var(--size-0);width:var(--size-12);height:var(--size-full);margin-top:auto;margin-bottom:auto;background:linear-gradient(270deg, var(--white) 0%, var(--pure-white-a1) 100%);transform:rotate(-180deg);opacity:0;transition:ease-in;transition-duration:var(--duration-slow);transition-property:opacity;content:\"\";pointer-events:none}@media only screen and (min-width: 64rem){.TableViewTabs-base__Finig::before{height:var(--size-8)}}@media only screen and (min-width: 64rem){.TableViewTabs-base__Finig::after{position:absolute;top:var(--size-0);right:var(--size-0);bottom:var(--size-0);width:var(--size-12);height:var(--size-full);margin-top:auto;margin-bottom:auto;background:linear-gradient(90deg, var(--white) 0%, var(--pure-white-a1) 100%);transform:rotate(-180deg);opacity:0;transition:ease-in;transition-duration:var(--duration-slow);transition-property:opacity;content:\"\";pointer-events:none}}@media only screen and (min-width: 64rem)and (min-width: 64rem){.TableViewTabs-base__Finig::after{height:var(--size-8)}}.TableViewTabs-base__Finig.TableViewTabs-leftContentClipped__IbjL_::before{opacity:1}.TableViewTabs-base__Finig.TableViewTabs-rightContentClipped__Cws3b::after{opacity:1}.TableViewTabs-scroll__U_pBp{display:flex;gap:var(--size-0_5);align-items:center;height:var(--size-full);padding-right:var(--size-3);overflow-x:auto;overscroll-behavior:contain}@media only screen and (min-width: 64rem){.TableViewTabs-scroll__U_pBp{padding:var(--size-0)}}.TableViewTabs-scroll__U_pBp::-webkit-scrollbar{display:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "TableViewTabs-base__Finig",
	"leftContentClipped": "TableViewTabs-leftContentClipped__IbjL_",
	"rightContentClipped": "TableViewTabs-rightContentClipped__Cws3b",
	"scroll": "TableViewTabs-scroll__U_pBp"
};
export default ___CSS_LOADER_EXPORT___;
