import { ObjectsViewType } from "@organisms/ObjectsView/ObjectsView.types"

export default function getObjectsViewLabel(viewType: ObjectsViewType): string | null {
    if (viewType === "table") {
        return "Table"
    } else if (viewType === "card") {
        return "Cards"
    } else if (viewType === "timeline") {
        return "Timeline"
    } else {
        return null
    }
}
