import { useQuery } from '@tanstack/react-query';
import { Component, Fragment } from 'react';
import { PriceBookItemTypes } from '../utils/enums';
import { formatServiceDates, getLineItemUnits, valueIsDefined } from "../utils/utils";


export async function fetchPriceBookData(currentUser, description) {
    if (valueIsDefined(description)) {
        // Attempt to find a matching item in the PriceBook
        let params = new URLSearchParams()
        params.append("confirmed", true)
        params.append("description", description)

        const priceBookEndpoint = DjangoUrls["pricebook:api-pricebookitem-lightweight-list"](currentUser.service_company.slug) + `?${params}`
        const priceBookResponse = await fetch(priceBookEndpoint)

        if (!priceBookResponse.ok) {
            return null
        }

        const priceBookResponseJSON = await priceBookResponse.json()

        if (priceBookResponseJSON.results.length !== 0) {
            return priceBookResponseJSON.results[0]
        }
        else {
            return null
        }
    }
    else {
        return null
    }
}

function PriceBookPartDataDisplay(props) {
    const { lineItem } = props
    const currentUser = window.CURRENT_USER

    const { data: pricebookItem, error, isLoading, isError } = useQuery({
        queryKey: ["pricebook-retrieve-by-description", currentUser.id, lineItem.description],
        queryFn: () => fetchPriceBookData(currentUser, lineItem.description),
        staleTime: 60000,
    })


    if (isError) {
        console.error(error)
    }
    else if (pricebookItem) {
        return (
            <div className="line-item-display__row pricebook_part_data_display">
                <div className="line-item-display__field" aria-label="Manufacturer">
                    <div className="line-item-display__field-label" aria-hidden="true">Manufacturer</div>
                    <div className="line-item-display__field-value" aria-label="Manufacturer">{pricebookItem.manufacturer || "--"}</div>
                </div>
                <div className="line-item-display__field" aria-label="Part Number">
                    <div className="line-item-display__field-label" aria-hidden="true">Part Number</div>
                    <div className="line-item-display__field-value" aria-label="Part Number">{pricebookItem.part_number || "--"}</div>
                </div>
            </div>
        )
    }

}


class LineItemListDisplay extends Component {

    render() {
        const {
            fieldName,
            fieldLabel,
            parent,
            lineItemList,
            emptyMessage,
            formatCurrencyValue,
            useTaxes=false,
            isEstimateLineItem=false,
            isJobLineItem=false,
            isInvoiceLineItem=false,
            isPurchaseOrderLineItem=false,
            isShownToClients=false,
            showPricebookPartDisplay=false,
        } = this.props

        return (
            <div className="data-panel__form__field" id={`div_id_${fieldName}`} aria-label={fieldLabel}>
                <span className="data-panel__form__field__label" aria-hidden="true">{fieldLabel}</span>
                <div className="data-panel__form__field__display-list" aria-label={`${fieldLabel} List`}>
                    {
                        lineItemList.length === 0
                        ?
                        `(${emptyMessage})`
                        :
                        lineItemList.map((item, index) => {
                            const { formattedDates, formattedLabel } = formatServiceDates(item.service_start_date, item.service_end_date, "UTC")
                            const { quantityUnit, priceUnit, priceLabel } = getLineItemUnits(item)

                            return (
                                <div key={`${fieldName}_${index}`} className="data-panel__form__field__display-list__object line-item-display" aria-label="Line Item">
                                    <div className="line-item-display__row">
                                        <div className="line-item-display__field" aria-label="Short Description">
                                            <div className="line-item-display__field-label" aria-hidden="true">Short Description</div>
                                            <div className="line-item-display__field-value" aria-label="Short Description">{item.description}</div>
                                        </div>
                                    </div>
                                    {
                                        !isEstimateLineItem && item.line_item_type === PriceBookItemTypes.service && (isJobLineItem ? !parent.is_job_walk : true) && (
                                            <div className="line-item-display__row">
                                                <div className="line-item-display__field" aria-label={formattedLabel}>
                                                    <div className="line-item-display__field-label" aria-hidden="true">{formattedLabel}</div>
                                                    <div className="line-item-display__field-value" aria-label={formattedLabel}>{formattedDates}</div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        window.MARKETPLACE_ENTITY_SLUG === parent.service_company.slug && item.inventory_location && (
                                            <div className="line-item-display__row">
                                                <div className="line-item-display__field" aria-label="Inventory Location">
                                                    <div className="line-item-display__field-label" aria-hidden="true">Inventory Location</div>
                                                    <div className="line-item-display__field-value" aria-label="Inventory Location">{item.inventory_location_label}</div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    { window.MARKETPLACE_ENTITY_SLUG === parent.service_company.slug && item.line_item_type === PriceBookItemTypes.part && showPricebookPartDisplay && <PriceBookPartDataDisplay lineItem={item} /> }
                                    <div className="line-item-display__row">
                                        {
                                            window.MARKETPLACE_ENTITY_SLUG === parent.service_company.slug && !window.CURRENT_USER.hide_financial_context && item.line_item_type === PriceBookItemTypes.part && !isShownToClients && (
                                                <div className="line-item-display__field" aria-label="Cost">
                                                    <div className="line-item-display__field-label" aria-hidden="true">Unit Cost</div>
                                                    <div className="line-item-display__field-value" aria-label="Cost">{valueIsDefined(item.cost) ? `${formatCurrencyValue(item.cost)}` : "--"}</div>
                                                </div>
                                            )
                                        }
                                        <div className="line-item-display__field" aria-label="Quantity">
                                            <div className="line-item-display__field-label" aria-hidden="true">Qty.</div>
                                            <div className="line-item-display__field-value" aria-label="Quantity">{valueIsDefined(item.quantity) ? `${parseFloat(item.quantity)}${quantityUnit}` : "--"}</div>
                                        </div>
                                        {
                                            isPurchaseOrderLineItem
                                            ?
                                            <Fragment>
                                                <div className="line-item-display__field" aria-label="Expected Vendor Cost">
                                                    <div className="line-item-display__field-label" aria-hidden="true">Expected Vendor Cost</div>
                                                    <div className="line-item-display__field-value" aria-label="Expected Vendor Cost">{valueIsDefined(item.expected_cost) ? formatCurrencyValue(item.expected_cost) : "--"}</div>
                                                </div>
                                                <div className="line-item-display__field" aria-label="Amount">
                                                    <div className="line-item-display__field-label" aria-hidden="true">Amount</div>
                                                    <div className="line-item-display__field-value" aria-label="Amount">{valueIsDefined(item.quantity) && valueIsDefined(item.expected_cost) ? formatCurrencyValue(parseFloat(item.quantity) * parseFloat(item.expected_cost)) : "--"}</div>
                                                </div>
                                            </Fragment>
                                            :
                                                !window.CURRENT_USER.hide_financial_context && (
                                                    <Fragment>
                                                        <div className="line-item-display__field" aria-label="Price">
                                                            <div className="line-item-display__field-label" aria-hidden="true">{priceLabel}</div>
                                                            <div className="line-item-display__field-value" aria-label="Price">{valueIsDefined(item.price) ? `${formatCurrencyValue(item.price)}${priceUnit}` : "--"}</div>
                                                        </div>
                                                        <div className="line-item-display__field" aria-label="Amount">
                                                            <div className="line-item-display__field-label" aria-hidden="true">Amount</div>
                                                            <div className="line-item-display__field-value" aria-label="Amount">{valueIsDefined(item.quantity) && valueIsDefined(item.price) ? formatCurrencyValue(parseFloat(item.quantity) * parseFloat(item.price)) : "--"}</div>
                                                        </div>
                                                    </Fragment>
                                                )
                                        }
                                    </div>
                                    {
                                        isPurchaseOrderLineItem && (
                                            <div className="line-item-label__row">
                                                <div className="line-item-label__field">
                                                    <div className="line-item-label__field-label">Vendor Part Number</div>
                                                    <div className="line-item-label__field-value">{item.part_number}</div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        useTaxes && item.line_item_type !== PriceBookItemTypes.discount && (
                                            <div className="line-item-label__row">
                                                <div className="line-item-label__field">
                                                    <div className="line-item-label__field-label">Tax</div>
                                                    <div className="line-item-label__field-value">{<span className="data-tag" style={{margin: "0", width: "auto"}}>{item.is_taxable ? "Taxable" : "Non-Taxable"}</span>}</div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        item.summary && (
                                            <div className="line-item-display__row">
                                                <div className="line-item-display__field">
                                                    <div className="line-item-display__field-label">{item.line_item_type === PriceBookItemTypes.service ? "Service" : "Detailed"} Summary</div>
                                                    <div className="line-item-display__field-value preserve-whitespace">{item.summary}</div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        isPurchaseOrderLineItem && valueIsDefined(item.jobs) && item.jobs.length !== 0 && (
                                            <div className="line-item-label__row">
                                                <div className="line-item-label__field">
                                                    <div className="line-item-label__field-label">Associated Jobs</div>
                                                    <div className="line-item-label__field-value">{item.jobs_label}</div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}

export default LineItemListDisplay;
