// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Popover-base__AjZFg{position:absolute;z-index:var(--z-index-popover);width:var(--size-96);overflow:hidden;background-color:var(--white);border-radius:var(--border-radius-1_5);outline:var(--size-0_25) solid var(--gray-6);outline-offset:calc(var(--size-0_25)*-1);box-shadow:var(--box-shadow-3xl-down);opacity:0;transition:max-height var(--duration-slow);pointer-events:none}.Popover-base__AjZFg *{pointer-events:none}.Popover-open__vNpZ7{opacity:1;pointer-events:all}.Popover-open__vNpZ7 *{pointer-events:all}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "Popover-base__AjZFg",
	"open": "Popover-open__vNpZ7"
};
export default ___CSS_LOADER_EXPORT___;
