import { InvoiceStatuses } from "@legacy/core/utils/enums"

import useObjectDetailsRoutes from "@hooks/useObjectDetailsRoute"
import useSlugExtractor from "@hooks/useSlugExtractor"

import { Table } from "@organisms"
import { Cells, Columns } from "@organisms/Table/Table.types"
import { GetObjectDetailsRouteFunction, Tab } from "@organisms/Table/TableViewControls/TableViewTab/TableViewTab.types"

import { INVOICES_ROUTES } from "@routes/invoices"

export default function useInvoiceTabs(): [Tab<Invoice>[], Tab<Invoice>[]] {
    const [tabCountEndpoint, tabListEndpoint] = useSlugExtractor([
        INVOICES_ROUTES.COUNT,
        INVOICES_ROUTES.LIGHTWEIGHT_LIST,
    ])

    const getTabObjectDetailsRoute = useObjectDetailsRoutes("Invoice") as GetObjectDetailsRouteFunction

    const headerByColumn = {
        id: "ID",
        client_name: "Client",
        state_label: "State",
        status_label: "Status",
        amount_due: "Amt. Due",
        total: "Total",
        service_name: "Service",
        service_location_address: "Location",
        date_due: "Due Date",
        last_status_transition_time: "Status Changed",
        last_modified_at: "Last Modified",
        last_viewed: "Seen",
        last_sent_at: "Sent",
        last_paid_at: "Paid",
        last_voided_at: "Voided",
        last_forgiven_at: "Forgiven",
    }

    const sortingEnabledByColumn = {
        id: true,
        client_name: true,
        state_label: false,
        status_label: true,
        amount_due: true,
        total: true,
        service_name: true,
        service_location_address: true,
        date_due: true,
        last_status_transition_time: false,
        last_modified_at: true,
        last_viewed: true,
        last_sent_at: true,
        last_paid_at: true,
        last_voided_at: true,
        last_forgiven_at: true,
    }

    const widthByColumn = {
        id: 96,
        client_name: 224,
        state_label: 128,
        status_label: 176,
        amount_due: 128,
        total: 144,
        service_name: 224,
        service_location_address: 288,
        date_due: 144,
        last_status_transition_time: 160,
        last_modified_at: 144,
        last_viewed: 96,
        last_sent_at: 144,
        last_paid_at: 144,
        last_voided_at: 144,
        last_forgiven_at: 144,
    }

    const pinningByColumn = {
        id: true,
        client_name: true,
        state_label: false,
        status_label: false,
        amount_due: false,
        total: false,
        service_name: false,
        service_location_address: false,
        date_due: false,
        last_status_transition_time: false,
        last_modified_at: false,
        last_viewed: false,
        last_sent_at: false,
        last_paid_at: false,
        last_voided_at: false,
        last_forgiven_at: false,
    }

    const cellByColumn: Cells<Invoice> = {
        id: ({ row }) => <Table.Cells.IDCell id={row.original.custom_id || row.original.id} />,
        client_name: ({ getValue }) => <Table.Cells.TruncatedTextCell content={getValue()} />,
        state_label: ({ getValue }) => <Table.Cells.BadgesCell badges={[getValue()]} />,
        status_label: ({ getValue, row }) => (
            <Table.Cells.StatusCell status={getValue()} dateDue={row.original.date_due} />
        ),
        amount_due: ({ getValue }) => <Table.Cells.NumericalDataCell value={getValue()} type="currency" />,
        total: ({ getValue }) => <Table.Cells.NumericalDataCell value={getValue()} type="currency" />,
        service_name: ({ getValue }) => <Table.Cells.TruncatedTextCell content={getValue()} />,
        service_location_address: ({ getValue }) => (
            <Table.Cells.TruncatedTextCell content={getValue()} variant="secondary" />
        ),
        date_due: ({ getValue, row }) => (
            <Table.Cells.DateTimeCell value={getValue()} isDangerous={row.original.state_label === "Overdue"} />
        ),
        last_status_transition_time: ({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />,
        last_modified_at: ({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />,
        last_viewed: ({ getValue }) => <Table.Cells.SeenCell value={getValue()} />,
        last_sent_at: ({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />,
        last_paid_at: ({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />,
        last_voided_at: ({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />,
        last_forgiven_at: ({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />,
    }

    const columnByName: Columns<Invoice> = {
        id: {
            header: headerByColumn["id"],
            accessorKey: "id",
            id: "id",
            size: widthByColumn["id"],
            cell: cellByColumn["id"],
            enableSorting: sortingEnabledByColumn["id"],
            enablePinning: pinningByColumn["id"],
        },
        client_name: {
            header: headerByColumn["client_name"],
            accessorKey: "client_name",
            id: "client_name",
            size: widthByColumn["client_name"],
            cell: cellByColumn["client_name"],
            enableSorting: sortingEnabledByColumn["client_name"],
            enablePinning: pinningByColumn["client_name"],
        },
        state_label: {
            header: headerByColumn["state_label"],
            accessorKey: "state_label",
            id: "state_label",
            size: widthByColumn["state_label"],
            cell: cellByColumn["state_label"],
            enableSorting: sortingEnabledByColumn["state_label"],
            enablePinning: pinningByColumn["state_label"],
        },
        status_label: {
            header: headerByColumn["status_label"],
            accessorKey: "status_label",
            id: "status_label",
            size: widthByColumn["status_label"],
            cell: cellByColumn["status_label"],
            enableSorting: sortingEnabledByColumn["status_label"],
            enablePinning: pinningByColumn["status_label"],
        },
        amount_due: {
            header: headerByColumn["amount_due"],
            accessorKey: "amount_due",
            id: "amount_due",
            size: widthByColumn["amount_due"],
            cell: cellByColumn["amount_due"],
            enableSorting: sortingEnabledByColumn["amount_due"],
            enablePinning: pinningByColumn["amount_due"],
        },
        total: {
            header: headerByColumn["total"],
            accessorKey: "total",
            id: "total",
            size: widthByColumn["total"],
            cell: cellByColumn["total"],
            enableSorting: sortingEnabledByColumn["total"],
            enablePinning: pinningByColumn["total"],
        },
        service_name: {
            header: headerByColumn["service_name"],
            accessorKey: "service_name",
            id: "service_name",
            size: widthByColumn["service_name"],
            cell: cellByColumn["service_name"],
            enableSorting: sortingEnabledByColumn["service_name"],
            enablePinning: pinningByColumn["service_name"],
        },
        service_location_address: {
            header: headerByColumn["service_location_address"],
            accessorKey: "service_location_address",
            id: "service_location_address",
            size: widthByColumn["service_location_address"],
            cell: cellByColumn["service_location_address"],
            enableSorting: sortingEnabledByColumn["service_location_address"],
            enablePinning: pinningByColumn["service_location_address"],
        },
        date_due: {
            header: headerByColumn["date_due"],
            accessorKey: "date_due",
            id: "date_due",
            size: widthByColumn["date_due"],
            cell: cellByColumn["date_due"],
            enableSorting: sortingEnabledByColumn["date_due"],
            enablePinning: pinningByColumn["date_due"],
        },
        last_status_transition_time: {
            header: headerByColumn["last_status_transition_time"],
            accessorKey: "last_status_transition_time",
            id: "last_status_transition_time",
            size: widthByColumn["last_status_transition_time"],
            cell: cellByColumn["last_status_transition_time"],
            enableSorting: sortingEnabledByColumn["last_status_transition_time"],
            enablePinning: pinningByColumn["last_status_transition_time"],
        },
        last_modified_at: {
            header: headerByColumn["last_modified_at"],
            accessorKey: "last_modified_at",
            id: "last_modified_at",
            size: widthByColumn["last_modified_at"],
            cell: cellByColumn["last_modified_at"],
            enableSorting: sortingEnabledByColumn["last_modified_at"],
            enablePinning: pinningByColumn["last_modified_at"],
        },
        last_viewed: {
            header: headerByColumn["last_viewed"],
            accessorKey: "last_viewed",
            id: "last_viewed",
            size: widthByColumn["last_viewed"],
            cell: cellByColumn["last_viewed"],
            enableSorting: sortingEnabledByColumn["last_viewed"],
            enablePinning: pinningByColumn["last_viewed"],
        },
        last_sent_at: {
            header: headerByColumn["last_sent_at"],
            accessorKey: "last_sent_at",
            id: "last_sent_at",
            size: widthByColumn["last_sent_at"],
            cell: cellByColumn["last_sent_at"],
            enableSorting: sortingEnabledByColumn["last_sent_at"],
            enablePinning: pinningByColumn["last_sent_at"],
        },
        last_paid_at: {
            header: headerByColumn["last_paid_at"],
            accessorKey: "last_paid_at",
            id: "last_paid_at",
            size: widthByColumn["last_paid_at"],
            cell: cellByColumn["last_paid_at"],
            enableSorting: sortingEnabledByColumn["last_paid_at"],
            enablePinning: pinningByColumn["last_paid_at"],
        },
        last_voided_at: {
            header: headerByColumn["last_voided_at"],
            accessorKey: "last_voided_at",
            id: "last_voided_at",
            size: widthByColumn["last_voided_at"],
            cell: cellByColumn["last_voided_at"],
            enableSorting: sortingEnabledByColumn["last_voided_at"],
            enablePinning: pinningByColumn["last_voided_at"],
        },
        last_forgiven_at: {
            header: headerByColumn["last_forgiven_at"],
            accessorKey: "last_forgiven_at",
            id: "last_forgiven_at",
            size: widthByColumn["last_forgiven_at"],
            cell: cellByColumn["last_forgiven_at"],
            enableSorting: sortingEnabledByColumn["last_forgiven_at"],
            enablePinning: pinningByColumn["last_forgiven_at"],
        },
    }

    const openInvoicesTabs: Tab<Invoice>[] = [
        {
            title: "All Open",
            tabObjectName: "Invoice",
            showTab: true,
            objectsPhase: "open",
            countEndpoint: tabCountEndpoint,
            listEndpoint: tabListEndpoint,
            getDetailsRoute: getTabObjectDetailsRoute,
            endpointKwargs: [
                ["status", InvoiceStatuses.pending],
                ["status", InvoiceStatuses.sent],
                ["ordering", "date_due"],
            ],
            columnDefinition: [
                columnByName["id"],
                columnByName["client_name"],
                columnByName["state_label"],
                columnByName["status_label"],
                columnByName["amount_due"],
                columnByName["service_name"],
                columnByName["service_location_address"],
                columnByName["date_due"],
                columnByName["last_status_transition_time"],
                columnByName["last_modified_at"],
            ],
        },
        {
            title: "Pending",
            tabObjectName: "Invoice",
            showTab: true,
            objectsPhase: "open",
            countEndpoint: tabCountEndpoint,
            listEndpoint: tabListEndpoint,
            getDetailsRoute: getTabObjectDetailsRoute,
            endpointKwargs: [
                ["status", InvoiceStatuses.pending],
                ["ordering", "date_due"],
            ],
            columnDefinition: [
                columnByName["id"],
                columnByName["client_name"],
                columnByName["status_label"],
                columnByName["amount_due"],
                columnByName["service_name"],
                columnByName["service_location_address"],
                columnByName["date_due"],
                columnByName["last_modified_at"],
            ],
        },
        {
            title: "Outstanding",
            tabObjectName: "Invoice",
            showTab: true,
            objectsPhase: "open",
            countEndpoint: tabCountEndpoint,
            listEndpoint: tabListEndpoint,
            getDetailsRoute: getTabObjectDetailsRoute,
            endpointKwargs: [
                ["status", InvoiceStatuses.sent],
                ["is_overdue", "false"],
                ["ordering", "date_due"],
            ],
            columnDefinition: [
                columnByName["id"],
                columnByName["client_name"],
                columnByName["status_label"],
                columnByName["amount_due"],
                columnByName["service_name"],
                columnByName["service_location_address"],
                columnByName["date_due"],
                columnByName["last_sent_at"],
                columnByName["last_modified_at"],
                columnByName["last_viewed"],
            ],
        },
        {
            title: "Overdue",
            tabObjectName: "Invoice",
            showTab: true,
            objectsPhase: "open",
            countEndpoint: tabCountEndpoint,
            listEndpoint: tabListEndpoint,
            getDetailsRoute: getTabObjectDetailsRoute,
            endpointKwargs: [
                ["status", InvoiceStatuses.sent],
                ["is_overdue", "true"],
                ["ordering", "date_due"],
            ],
            columnDefinition: [
                columnByName["id"],
                columnByName["client_name"],
                columnByName["status_label"],
                columnByName["amount_due"],
                columnByName["service_name"],
                columnByName["service_location_address"],
                columnByName["date_due"],
                columnByName["last_sent_at"],
                columnByName["last_modified_at"],
                columnByName["last_viewed"],
            ],
        },
    ]

    const closedInvoicesTabs: Tab<Invoice>[] = [
        {
            title: "All Closed",
            tabObjectName: "Invoice",
            showTab: true,
            objectsPhase: "closed",
            countEndpoint: tabCountEndpoint,
            listEndpoint: tabListEndpoint,
            getDetailsRoute: getTabObjectDetailsRoute,
            endpointKwargs: [
                ["status", InvoiceStatuses.paid],
                ["status", InvoiceStatuses.voided],
                ["status", InvoiceStatuses.forgiven],
                ["ordering", "-date_due"],
            ],
            columnDefinition: [
                columnByName["id"],
                columnByName["client_name"],
                columnByName["state_label"],
                columnByName["total"],
                columnByName["service_name"],
                columnByName["service_location_address"],
                columnByName["date_due"],
                columnByName["last_status_transition_time"],
                columnByName["last_modified_at"],
                columnByName["last_viewed"],
            ],
        },
        {
            title: "Paid",
            tabObjectName: "Invoice",
            showTab: true,
            objectsPhase: "closed",
            countEndpoint: tabCountEndpoint,
            listEndpoint: tabListEndpoint,
            getDetailsRoute: getTabObjectDetailsRoute,
            endpointKwargs: [
                ["status", InvoiceStatuses.paid],
                ["ordering", "-date_due"],
            ],
            columnDefinition: [
                columnByName["id"],
                columnByName["client_name"],
                columnByName["total"],
                columnByName["service_name"],
                columnByName["service_location_address"],
                columnByName["date_due"],
                columnByName["last_paid_at"],
                columnByName["last_modified_at"],
                columnByName["last_viewed"],
            ],
        },
        {
            title: "Voided",
            tabObjectName: "Invoice",
            showTab: true,
            objectsPhase: "closed",
            countEndpoint: tabCountEndpoint,
            listEndpoint: tabListEndpoint,
            getDetailsRoute: getTabObjectDetailsRoute,
            endpointKwargs: [
                ["status", InvoiceStatuses.voided],
                ["ordering", "-date_due"],
            ],
            columnDefinition: [
                columnByName["id"],
                columnByName["client_name"],
                columnByName["total"],
                columnByName["service_name"],
                columnByName["service_location_address"],
                columnByName["date_due"],
                columnByName["last_voided_at"],
                columnByName["last_modified_at"],
                columnByName["last_viewed"],
            ],
        },
        {
            title: "Forgiven",
            tabObjectName: "Invoice",
            showTab: true,
            objectsPhase: "closed",
            countEndpoint: tabCountEndpoint,
            listEndpoint: tabListEndpoint,
            getDetailsRoute: getTabObjectDetailsRoute,
            endpointKwargs: [
                ["status", InvoiceStatuses.forgiven],
                ["ordering", "-date_due"],
            ],
            columnDefinition: [
                columnByName["id"],
                columnByName["client_name"],
                columnByName["total"],
                columnByName["service_name"],
                columnByName["service_location_address"],
                columnByName["date_due"],
                columnByName["last_forgiven_at"],
                columnByName["last_modified_at"],
                columnByName["last_viewed"],
            ],
        },
    ]

    if (!tabListEndpoint) {
        return [null, null]
    } else {
        return [openInvoicesTabs, closedInvoicesTabs]
    }
}
