// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AuthDisclaimer-base__OGu6I{align-self:center;width:var(--size-56);margin-top:auto;padding-top:var(--size-8);color:var(--gray-a11);text-align:center;font:var(--font-mobile-caption-small-375);font-feature-settings:var(--feature-settings-mobile-caption-small-375);letter-spacing:var(--letter-spacing-mobile-caption-small-375)}@media only screen and (min-width: 64rem){.AuthDisclaimer-base__OGu6I{font:var(--font-global-caption-small-400);font-feature-settings:var(--feature-settings-global-caption-small-400);letter-spacing:var(--letter-spacing-global-caption-small-400);margin-top:var(--size-14);padding-top:var(--size-0)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "AuthDisclaimer-base__OGu6I"
};
export default ___CSS_LOADER_EXPORT___;
