import useUser from "@hooks/useUser"

import formatDate from "@utils/formatDate"
import isValidDate from "@utils/isValidDate"

import { Property } from "@molecules/index"

import SetPasswordDialogsTrigger from "@pages/Settings/SettingsSecurityAndAccess/components/SetPasswordDialogsTrigger/SetPasswordDialogsTrigger"

export default function PasswordManagement() {
    const { user } = useUser()

    const lastUpdatedFormattedDate = isValidDate(user?.password_last_modified_at)
        ? formatDate({
              short: true,
              datetime: user.password_last_modified_at,
              preferredTimezone: user.service_company.preferred_timezone,
              addYear: true,
          }).localDateString
        : null

    const subTitleCopy = user?.hasPassword
        ? lastUpdatedFormattedDate !== null
            ? `Last changed on ${lastUpdatedFormattedDate}`
            : "Last changed: Never"
        : "Secure your account with a password."

    return (
        <Property.Root wrapOnMobile={true}>
            <Property.Content>
                <Property.Label>Password</Property.Label>
                <Property.Subtitle>{subTitleCopy}</Property.Subtitle>
            </Property.Content>
            <Property.Value>
                <SetPasswordDialogsTrigger />
            </Property.Value>
        </Property.Root>
    )
}
