import {
    DateHelper,
    DomHelper,
    DragHelper,
    DragHelperConfig,
    Grid,
    SchedulerPro,
    ScrollManager,
    TimeZoneHelper,
} from "@bryntum/schedulerpro"
import { JobStatuses } from "@legacy/core/utils/enums"
import { InfiniteData, QueryObserverResult } from "@tanstack/react-query"
import ReactDOMServer from "react-dom/server"

import formatDate from "@utils/formatDate"
import formatTimelineViewCardLocationName from "@utils/formatTimelineViewCardLocationName"

import { ObjectListData } from "@organisms/ObjectsView/ObjectsView.types"

import { EventModel, JobUpdatePayload, SchedulerResourceModel } from "./JobTimelineView.types"
import TimelineViewEventCard from "./TimelineViewEventCard/TimelineViewEventCard"

interface Context {
    newX?: number
    newY?: number
    draggedJobEvent?: EventModel<CalendarEvent>
    resource?: SchedulerResourceModel<CalendarTechnician>
    element: HTMLElement
    target: HTMLElement
    grabbed: HTMLElement
    relatedElements: HTMLElement[]
    valid: boolean
}

type DragConfig = Partial<DragHelperConfig> & {
    grid: Grid
    schedule: SchedulerPro
    constrain: boolean
    outerElement: HTMLElement
    serviceCompanySlug: string
    preferredTimezone: string
    collapseUnscheduledJobsColumn: () => void
    expandUnscheduledJobsColumn: () => void
    refetchUnscheduledJobs: () => Promise<
        QueryObserverResult<InfiniteData<ObjectListData<CalendarEvent>, unknown>, Error>
    >
    handleOverlappingEvents: () => void
    removeAllOverlappingRegionFeedback: () => void
    syncJobUpdatesWithServer: (jobId: Job["id"], updated: JobUpdatePayload) => Promise<void>
    addEventToCache: (date: Date, event: CalendarEvent) => void
    schedulerIsInCompactMode: boolean
}

// Handles dragging unscheduled jobEvent from the grid onto the schedule
export class JobEventDrag extends DragHelper {
    static defaultConfig = {
        callOnFunctions: true,
        autoSizeClonedTarget: false,
        unifiedProxy: true,

        // Prevent removing proxy on drop, we adopt it for usage in the Schedule
        removeProxyAfterDrop: false,

        // Don't drag the actual row element, clone it
        cloneTarget: true,
        // Only allow drops on the schedule area
        dropTargetSelector: ".b-timeline-subgrid",
        // Only allow drag of row elements inside on the unplanned grid
        targetSelector: ".b-grid-row:not(.b-group-row)",
        dragWithin: null,
        outerElement: null,
    }

    public scrollManager!: ScrollManager
    public grid: Grid
    public outerElement: HTMLElement = null
    public schedule!: SchedulerPro
    public constrain: boolean
    public serviceCompanySlug: string
    public preferredTimezone: string
    public collapseUnscheduledJobsColumn: () => void
    public expandUnscheduledJobsColumn: () => void
    public refetchUnscheduledJobs: () => Promise<
        QueryObserverResult<InfiniteData<ObjectListData<CalendarEvent>, unknown>, Error>
    >
    public handleOverlappingEvents: () => void
    public syncJobUpdatesWithServer: (jobId: Job["id"], updated: JobUpdatePayload) => Promise<void>
    public addEventToCache: (date: Date, event: CalendarEvent) => void

    public removeAllOverlappingRegionFeedback: () => void

    public dragWithin: HTMLElement = null
    public schedulerIsInCompactMode: boolean = false
    private context: Context

    constructor(config: DragConfig) {
        super(config)

        this.grid = config.grid
        this.schedule = config.schedule
        this.constrain = true
        this.outerElement = config.outerElement
        this.scrollManager = config.schedule?.scrollManager as ScrollManager

        this.serviceCompanySlug = config.serviceCompanySlug
        this.preferredTimezone = config.preferredTimezone
        this.collapseUnscheduledJobsColumn = config.collapseUnscheduledJobsColumn
        this.expandUnscheduledJobsColumn = config.expandUnscheduledJobsColumn
        this.refetchUnscheduledJobs = config.refetchUnscheduledJobs
        this.handleOverlappingEvents = config.handleOverlappingEvents
        this.removeAllOverlappingRegionFeedback = config.removeAllOverlappingRegionFeedback
        this.syncJobUpdatesWithServer = config.syncJobUpdatesWithServer
        this.addEventToCache = config.addEventToCache

        this.schedulerIsInCompactMode = config.schedulerIsInCompactMode
    }

    override createProxy = (grabbedElement: HTMLElement): HTMLDivElement => {
        const { context, schedule, grid } = this
        const draggedJobEvent = grid?.getRecordFromElement(grabbedElement) as unknown as EventModel<CalendarEvent>

        const durationInPixels = schedule?.timeAxisViewModel.getDistanceForDuration(draggedJobEvent.durationMS)

        const existingProxy: HTMLDivElement = document.querySelector(".jsDragEventProxy")
        const proxy = existingProxy ? existingProxy : document.createElement("div")

        proxy.classList.add("jsDragEventProxy")

        proxy.style.cssText = ""

        if (schedule.mode === "horizontal") {
            proxy.style.height = `${Number(schedule.rowHeight) - 2 * Number(schedule.resourceMargin)}px`
            proxy.style.width = `${durationInPixels}px`
        } else {
            proxy.style.height = `${durationInPixels}px`
            proxy.style.width = `${schedule.resourceColumnWidth}px`
        }

        proxy.classList.add("b-sch-event-wrap", "b-sch-style-border", "b-unassigned-class", "b-sch-horizontal")

        proxy.innerHTML = ReactDOMServer.renderToString(
            <TimelineViewEventCard
                location={formatTimelineViewCardLocationName(draggedJobEvent.originalData)}
                client={draggedJobEvent.originalData.client}
                duration={draggedJobEvent.originalData.estimated_duration}
                customId={draggedJobEvent.originalData.custom_id}
                serviceName={draggedJobEvent.originalData.service_name}
                isResizeEnabled={false}
                status={draggedJobEvent.originalData.status}
                statusColorOverride={draggedJobEvent.originalData.status_color_override}
                id={draggedJobEvent.originalData.id}
                assignedTechnicians={[]}
                isDragging={true}
                isCompact={this.schedulerIsInCompactMode}
            />,
        )
        context.draggedJobEvent = draggedJobEvent

        return proxy
    }

    override onDragStart = (): void => {
        const { schedule } = this

        schedule.enableScrollingCloseToEdges(schedule.timeAxisSubGrid)
        // Prevent tooltips from showing while dragging
        schedule.features.eventTooltip.disabled = true

        this.collapseUnscheduledJobsColumn()
    }

    override onDrag = ({ context, event }: { context: Context; event: MouseEvent }): void => {
        const { schedule } = this

        const resource =
            context.target &&
            (schedule.resolveResourceRecord(context.target, [
                event.offsetX,
                event.offsetY,
            ]) as unknown as SchedulerResourceModel<CalendarTechnician>)

        const dropDate = schedule.getDateFromCoordinate(
            schedule.mode === "horizontal" ? context.newX : context.newY,
            "round",
            false,
        )

        context.valid = dropDate && schedule.allowOverlap && !!resource

        const duration = context.draggedJobEvent._data.estimated_duration / 3600

        const tooltipToLeft: HTMLDivElement = document.querySelector(
            ".jsDragEventProxy [data-bryntum-event-card-tooltip-to-left]",
        )
        const tooltipToRight: HTMLDivElement = document.querySelector(
            ".jsDragEventProxy [data-bryntum-event-card-tooltip-to-right]",
        )

        if (context.valid) {
            tooltipToLeft.style.display = "block"
            tooltipToRight.style.display = "block"
            const endDate = DateHelper.add(dropDate, duration, "hour")

            const { localTimeString: startTime } = formatDate({
                datetime: dropDate,
                preferredTimezone: undefined,
            })

            const { localTimeString: endTime } = formatDate({
                datetime: endDate,
                preferredTimezone: undefined,
            })

            tooltipToLeft.textContent = startTime
            tooltipToRight.textContent = endTime

            context.resource = resource
        } else {
            tooltipToLeft.style.display = "none"
            tooltipToRight.style.display = "none"
        }
    }

    override onDrop = async ({ context }: { context: Context }) => {
        const { schedule } = this

        if (context.valid) {
            this.removeAllOverlappingRegionFeedback()

            const { dropDate, endDate, isPastDue } = this.calculateDates(context)
            this.updateDraggedJobEvent(context.draggedJobEvent, dropDate, endDate, isPastDue, context.resource)
            await schedule.scheduleEvent({
                eventRecord: context.draggedJobEvent,
                startDate: dropDate,
                resourceRecord: context.resource,
                element: context.element,
            })

            this.handleOverlappingEvents()

            this.expandUnscheduledJobsColumn()
            await this.updateJobOnServer(context.draggedJobEvent, dropDate, context.resource)
            void this.refetchUnscheduledJobs()
        } else {
            this.expandUnscheduledJobsColumn()
        }

        schedule.disableScrollingCloseToEdges(schedule.timeAxisSubGrid)
        schedule.features.eventTooltip.disabled = false
    }

    private calculateDates(context: Context): { dropDate: Date; endDate: Date; isPastDue: boolean } {
        const { draggedJobEvent, element } = context
        const { schedule } = this

        const coordinate =
            schedule.mode === "horizontal" ? DomHelper.getTranslateX(element) : DomHelper.getTranslateY(element)
        const dropDate = schedule.getDateFromCoordinate(coordinate, "round", false)

        const durationInHours = draggedJobEvent.originalData.estimated_duration / 3600
        const endDate = DateHelper.add(dropDate, durationInHours, "hour")
        const now = TimeZoneHelper.toTimeZone(new Date(), this.preferredTimezone)
        const isPastDue = DateHelper.isBefore(dropDate, now)
        return { dropDate, endDate, isPastDue }
    }

    private updateDraggedJobEvent(
        draggedJobEvent: EventModel<CalendarEvent>,
        dropDate: Date,
        endDate: Date,
        isPastDue: boolean,
        resource: SchedulerResourceModel<CalendarTechnician>,
    ) {
        const newLabel = isPastDue ? "Past Due" : "Upcoming"
        draggedJobEvent.originalData.start_time = TimeZoneHelper.fromTimeZone(dropDate, this.preferredTimezone)
        draggedJobEvent._data.start_time = TimeZoneHelper.fromTimeZone(dropDate, this.preferredTimezone)
        draggedJobEvent.originalData.end_time = TimeZoneHelper.fromTimeZone(endDate, this.preferredTimezone)
        draggedJobEvent._data.end_time = TimeZoneHelper.fromTimeZone(endDate, this.preferredTimezone)
        draggedJobEvent.originalData.status = JobStatuses.scheduled
        draggedJobEvent._data.status = JobStatuses.scheduled
        draggedJobEvent.originalData.status_label = newLabel
        draggedJobEvent._data.status_label = newLabel
        const assignedTechnicians =
            resource.originalData.id === "unassigned" ? [] : [resource.originalData as CalendarTechnician & User]
        draggedJobEvent.originalData.assigned_technicians = assignedTechnicians
        draggedJobEvent._data.assigned_technicians = assignedTechnicians
        draggedJobEvent.originalData.technician_id =
            resource.originalData.id === "unassigned" ? null : Number(resource.originalData.id)
        draggedJobEvent._data.technician_id =
            resource.originalData.id === "unassigned" ? null : Number(resource.originalData.id)

        const dropDateMidnight = new Date(dropDate)
        dropDateMidnight.setHours(0, 0, 0, 0)

        this.addEventToCache(dropDateMidnight, {
            ...draggedJobEvent.originalData,
            startDate: draggedJobEvent.originalData.start_time,
            endDate: draggedJobEvent.originalData.end_time,
        } as CalendarEvent)
    }

    private async updateJobOnServer(
        draggedJobEvent: EventModel<CalendarEvent>,
        dropDate: Date,
        resource: SchedulerResourceModel<CalendarTechnician>,
    ) {
        return this.syncJobUpdatesWithServer(draggedJobEvent.originalData.id, {
            estimated_arrival_time: TimeZoneHelper.fromTimeZone(dropDate, this.preferredTimezone),
            estimated_duration: draggedJobEvent.originalData.estimated_duration,
            assigned_technicians: resource.originalData.id === "unassigned" ? [] : [resource.originalData.id],
            status: JobStatuses.scheduled,
            custom_id: draggedJobEvent.originalData.custom_id,
        } as JobUpdatePayload)
    }
}
