import React, { Component } from "react";
import deepcopy from "rfdc";
import { format_phone_number_with_extension } from "../../../../core/utils/utils";


class QuickBooksContactDisplay extends Component {

    render = () => {
        const { contact } = this.props
        let errors = deepcopy()(contact.errors || {})

        const nameValue = errors.name ? contact.name : (contact.cleaned_name ? contact.cleaned_name : "--")
        const phoneValue = errors.phone ? (contact.cleaned_phone ? `${contact.cleaned_phone}${contact.cleaned_phone_extension ? " x" : ""}${contact.cleaned_phone_extension}` : contact.phone) : (contact.cleaned_phone ? format_phone_number_with_extension(contact.cleaned_phone, contact.cleaned_phone_extension) : "--")
        const emailValue = errors.email ? (contact.cleaned_email ? contact.cleaned_email : contact.email) : (contact.cleaned_email ? contact.cleaned_email : "--")

        return (
            <div className="data-panel__form__field__display-list__object contact-display" aria-label="Contact">
                {
                    contact.primary && (
                        <div className="contact-display__row contact-display-primary">
                            <div className="contact-display__field" aria-label="Primary">
                                <div className="contact-display__field-value"><span className="data-tag" style={{margin: "0", width: "100%"}}>Primary Contact</span></div>
                            </div>
                        </div>
                    )
                }
                {errors.non_field_error && <div className="data-panel__form__field__errors" aria-label="Non Field Error">{errors.non_field_error}</div>}
                <div className="contact-display__row">
                    {
                        (contact.name || contact.cleaned_name) && (
                            <div className="contact-display__field" aria-label="Name">
                                <div className={"contact-display__field-label" + (errors.name ? " invalid" : "")} aria-hidden="true">Name</div>
                                <div className="contact-display__field-value">{nameValue}</div>
                                {errors.name && <div className="data-panel__form__field__errors" aria-label="Field Errors">{errors.name}</div>}
                            </div>
                        )
                    }
                    {
                        (contact.phone || contact.cleaned_phone) && (
                            <div className="contact-display__field" aria-label="Phone">
                                <div className={"contact-display__field-label" + (errors.phone ? " invalid" : "")} aria-hidden="true">Phone</div>
                                <div className="contact-display__field-value">{phoneValue}</div>
                                {errors.phone && <div className="data-panel__form__field__errors" aria-label="Field Errors">{errors.phone}</div>}
                            </div>
                        )
                    }
                    {
                        (contact.email || contact.cleaned_email) && (
                            <div className="contact-display__field" aria-label="Email">
                                <div className={"contact-display__field-label" + (errors.email ? " invalid" : "")} aria-hidden="true">Email</div>
                                <div className="contact-display__field-value">{emailValue}</div>
                                {errors.email && <div className="data-panel__form__field__errors" aria-label="Field Errors">{errors.email}</div>}
                            </div>
                        )
                    }
                </div>
            </div>
        )
    }
}

export default QuickBooksContactDisplay;
