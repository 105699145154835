import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import clsx from "clsx"

import { Button, Icon } from "@atoms"

import { IconGroup } from "@molecules"

import styles from "./UnscheduledJobsColumnMessage.module.scss"
import { UnscheduledJobsColumnMessageProps } from "./UnscheduledJobsColumnMessage.types"

export default function UnscheduledJobsColumnMessage(props: UnscheduledJobsColumnMessageProps) {
    const { onButtonClick = () => null, showBorderTop = true, showButton = false } = props

    return (
        <div
            className={clsx(styles.base, {
                [styles.borderTop]: showBorderTop,
            })}
        >
            <div className={styles.iconsAndCopy}>
                <IconGroup>
                    <Icon
                        icon={icon({
                            name: "circle-dashed",
                            style: "solid",
                            family: "sharp",
                        })}
                        isOverlapping={true}
                        size={24}
                        className={styles.unscheduledIcon}
                        noContainer={true}
                    />
                    <Icon
                        icon={icon({
                            name: "circle-calendar",
                            style: "solid",
                            family: "sharp",
                        })}
                        isOverlapping={true}
                        size={24}
                        className={styles.scheduledIcon}
                        noContainer={true}
                    />
                    <Icon
                        icon={icon({
                            name: "circle-stop",
                            style: "solid",
                            family: "sharp",
                        })}
                        isOverlapping={true}
                        size={24}
                        className={styles.onHoldIcon1}
                        noContainer={true}
                    />
                    <Icon
                        icon={icon({
                            name: "circle-stop",
                            style: "solid",
                            family: "sharp",
                        })}
                        isOverlapping={true}
                        size={24}
                        className={styles.onHoldIcon2}
                        noContainer={true}
                    />
                </IconGroup>
                <div className={styles.copy}>
                    <div className={styles.title}>Draft, Past Due & On Hold Jobs</div>
                    <div className={styles.description}>
                        We currently only support scheduling Unscheduled jobs from the side panel. To manage Draft,
                        Past Due, and On Hold jobs, switch to Table view.
                    </div>
                </div>
            </div>

            {showButton && (
                <Button colorScheme="gray" size="lg" variant="solid" onClick={onButtonClick} noDebounce={true}>
                    Got it!
                </Button>
            )}
        </div>
    )
}
