import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import clsx from "clsx"
import { useEffect, useState } from "react"
import { useHotkeys } from "react-hotkeys-hook"

import useIsAnyDialogOpen from "@hooks/useIsAnyDialogOpen"
import useRouter from "@hooks/useRouter"
import useUser from "@hooks/useUser"

import { ifSpaceOrEnter } from "@utils/keyboard"
import replaceSlugs from "@utils/replaceSlugs"

import { HotKey, Icon } from "@atoms"

import { OverflowMenu } from "@molecules"
import { Item } from "@molecules/OverflowMenu/OverflowMenu.types"

import { CLIENTS_ROUTES } from "@routes/clients"
import { ESTIMATES_ROUTES } from "@routes/estimates"
import { INVOICES_ROUTES } from "@routes/invoices"
import { JOBS_ROUTES } from "@routes/jobs"
import { PRICEBOOK_ITEMS_ROUTES } from "@routes/pricebook-items"
import { PURCHASE_ORDER_ROUTES } from "@routes/purchase-orders"
import { VENDOR_ROUTES } from "@routes/vendor"

import { APIDeckTaxStrategies, AccountingIntegrations } from "@constants/choices"

import styles from "./SideBarCreateButton.module.scss"
import { SideBarCreateButtonProps } from "./SideBarCreateButton.types"

const OPEN_OVERFLOW_MENU_HOTKEY = "c"

export default function SideBarCreateButton(props: SideBarCreateButtonProps) {
    const { isDisabled = false } = props

    const { user } = useUser()
    const router = useRouter()

    const isUsingTaxRates =
        user?.service_company.use_taxes &&
        !(
            user?.service_company.accounting_integration === AccountingIntegrations.apideck &&
            user?.service_company.apideck_tax_strategy !== APIDeckTaxStrategies.normal
        )

    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [userIsInteracting, setUserIsInteracting] = useState<boolean>(false)

    const openOverflowMenu = () => (!isDisabled ? setIsOpen(true) : null)
    const closeOverflowMenu = () => setIsOpen(false)

    const onObjectSelect = (item: Item) => {
        const route = replaceSlugs(item.value, { service_company_slug: user?.service_company.slug })
        router.push(route)
    }

    const onHoverActiveOrFocusVisible = () => {
        setUserIsInteracting(true)
    }

    const onMouseLeave = () => {
        setUserIsInteracting(false)
    }

    useHotkeys(
        OPEN_OVERFLOW_MENU_HOTKEY,
        () => openOverflowMenu(),
        {
            enabled: !isDisabled,
        },
        [isDisabled],
    )

    const { setIsAnyDialogOpen } = useIsAnyDialogOpen()

    useEffect(() => {
        setIsAnyDialogOpen(isOpen)
    }, [isOpen])

    return (
        <div className={styles.base}>
            <div
                className={clsx(styles.trigger, {
                    [styles.active]: isOpen,
                    [styles.isDisabled]: isDisabled,
                })}
                tabIndex={0}
                role="button"
                onClick={openOverflowMenu}
                onKeyDown={(e) => ifSpaceOrEnter(e, openOverflowMenu)}
                onMouseOver={onHoverActiveOrFocusVisible}
                onFocus={onHoverActiveOrFocusVisible}
                onMouseOut={onMouseLeave}
            >
                <div className={styles.iconAndText}>
                    <div className={styles.iconWrapper}>
                        <Icon
                            className={styles.icon}
                            icon={icon({ name: "circle-plus", style: "solid", family: "sharp" })}
                            size={16}
                        />
                    </div>
                    <div className={styles.label}>
                        <span>Create</span>
                    </div>
                </div>
                {!isDisabled && <HotKey symbolName="C" variant={isOpen || userIsInteracting ? "solid" : "subtle"} />}
            </div>
            <div className={styles.overflowMenu}>
                <OverflowMenu
                    triggerClassName={styles.overflowMenu}
                    onClose={closeOverflowMenu}
                    isOpen={isOpen}
                    onSelect={onObjectSelect}
                    startingPoint="right"
                    items={[
                        {
                            icon: icon({ name: "file-lines", style: "light", family: "sharp" }),
                            value: ESTIMATES_ROUTES.CREATE,
                            label: "Estimate",
                        },
                        {
                            icon: icon({ name: "wrench-simple", style: "light", family: "sharp" }),
                            value: JOBS_ROUTES.CREATE,
                            label: "Job",
                        },
                        {
                            icon: icon({ name: "circle-dollar", style: "light", family: "sharp" }),
                            value: INVOICES_ROUTES.CREATE,
                            label: "Invoice",
                        },
                        {
                            icon: icon({ name: "basket-shopping", style: "light", family: "sharp" }),
                            value: PURCHASE_ORDER_ROUTES.CREATE,
                            label: "Purchase Order",
                        },
                        {
                            icon: icon({ name: "store", style: "light", family: "sharp" }),
                            value: VENDOR_ROUTES.CREATE,
                            label: "Vendor",
                        },
                        {
                            icon: icon({ name: "user-circle", style: "light", family: "sharp" }),
                            value: CLIENTS_ROUTES.CREATE,
                            label: "Client",
                        },

                        {
                            value: "pricebook_item",
                            label: "Pricebook Item",
                            icon: icon({ name: "tag", style: "light", family: "sharp" }),
                            subItems: [
                                {
                                    value: PRICEBOOK_ITEMS_ROUTES.SERVICE.CREATE,
                                    label: "Service",
                                },
                                {
                                    value: PRICEBOOK_ITEMS_ROUTES.PART_OR_MATERIAL.CREATE,
                                    label: "Part or Material",
                                },
                                {
                                    value: PRICEBOOK_ITEMS_ROUTES.MISCELLANEOUS.CREATE,
                                    label: "Miscellaneous",
                                },
                                {
                                    value: PRICEBOOK_ITEMS_ROUTES.DISCOUNT.CREATE,
                                    label: "Discount",
                                },
                                ...(isUsingTaxRates
                                    ? [
                                          {
                                              value: PRICEBOOK_ITEMS_ROUTES.TAX_RATES.CREATE,
                                              label: "Tax Rate",
                                          },
                                      ]
                                    : []),
                            ],
                        },
                    ]}
                />
            </div>
        </div>
    )
}
