import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"

import useIsDesktop from "@hooks/useIsDesktop"
import useRouter from "@hooks/useRouter"
import useUser from "@hooks/useUser"

import { Button, ListInfoGlyph } from "@atoms"

import styles from "@organisms/Table/TableEdgeStates/TableEdgeStates.module.scss"
import TableLoadingState from "@organisms/Table/TableEdgeStates/TableLoadingState/TableLoadingState"

import { TableEmptyStateProps } from "./TableEmptyState.types"

const DESKTOP_LOADING_ROWS_COUNT = 4
const MOBILE_LOADING_ROWS_COUNT = 2

export default function TableEmptyState(props: TableEmptyStateProps) {
    const { tabContentName, activeTabConfig, objectCreateRoute } = props
    const isDesktop = useIsDesktop()
    const { push } = useRouter()
    const { user } = useUser()

    const title = `No ${tabContentName.toLowerCase().replace("all", "")}`

    const explanation = `There are no ${tabContentName
        .toLowerCase()
        .replace("all", "")
        .replace("&", "or")} to display.`

    return (
        <div className={styles.base}>
            <TableLoadingState
                {...props}
                isAnimated={false}
                isBackground={true}
                numberOfRows={isDesktop ? DESKTOP_LOADING_ROWS_COUNT : MOBILE_LOADING_ROWS_COUNT}
            />
            <div className={styles.content}>
                <div className={styles.copyWrap}>
                    <ListInfoGlyph
                        variant="black"
                        icon={icon({ name: "file-lines", style: "solid", family: "sharp" })}
                    />
                    <div className={styles.copy}>
                        <h2 className={styles.title}>{title}</h2>
                        <p className={styles.explanation}>{explanation}</p>
                    </div>
                </div>
                {user?.isServiceDispatcher && (
                    <Button
                        colorScheme="gray"
                        size="md"
                        variant="solid"
                        leftIcon={icon({ name: "plus", style: "solid", family: "sharp" })}
                        onClick={() => push(objectCreateRoute)}
                        noDebounce={true}
                    >
                        Create {activeTabConfig?.tabObjectName}
                    </Button>
                )}
            </div>
        </div>
    )
}
