import VendorCreate from "./VendorCreate/VendorCreate"
import VendorDetails from "./VendorDetails/VendorDetails"
import VendorEdit from "./VendorEdit/VendorEdit"
import VendorList from "./VendorList/VendorList"

const List = VendorList
const Create = VendorCreate
const Edit = VendorEdit
const Details = VendorDetails

export default { List, Create, Edit, Details }
