import { Component } from "react";
import deepcopy from "rfdc";
import WrapperButton from "../../core/buttons/WrapperButton";
import { formatCurrency, valueIsDefined } from "../../core/utils/utils";


class InlineVendorPartCostDisplay extends Component {

    render() {
        const { vendorPartCost, requestAction, currencySymbol } = this.props
        let errors = deepcopy()(vendorPartCost.errors || {})

        return (
            <WrapperButton key={`${vendorPartCost.id}_${vendorPartCost.vendor_name}`} type="object_link" handler={event => requestAction("VENDOR_PART_COST_EDIT")} title={vendorPartCost.vendor_name} ariaLabel="Update Vendor Part Cost">
                <div className="data-panel__list-select__option__label vendor-part-cost-label">
                    {
                        vendorPartCost.preferred && (
                            <div className="vendor-part-cost-label__row vendor-part-cost-label-preferred">
                                <div className="vendor-part-cost-label__field" aria-label="Preferred Vendor">
                                    <div className="vendor-part-cost-label__field-value"><span className="data-tag" style={{margin: "0", width: "100%"}}>Preferred Vendor</span></div>
                                </div>
                            </div>
                        )
                    }
                    {errors.non_field_error && <div className="data-panel__form__field__errors" aria-label="Non Field Error">{errors.non_field_error}</div>}
                    <div className="vendor-part-cost-label__row">
                        <div className="vendor-part-cost-label__field" aria-label="Vendor">
                            <div className="vendor-part-cost-label__field-label" aria-hidden="true">Vendor</div>
                            <div className="vendor-part-cost-label__field-value">{vendorPartCost.vendor_name}</div>
                        </div>
                    </div>
                    <div className="vendor-part-cost-label__row">
                        <div className="vendor-part-cost-label__field" aria-label="Vendor Cost">
                            <div className={"vendor-part-cost-label__field-label" + (errors.vendor_cost ? " invalid" : "")} aria-hidden="true">Vendor Cost</div>
                            <div className="vendor-part-cost-label__field-value">{valueIsDefined(vendorPartCost.vendor_cost) ? formatCurrency(vendorPartCost.vendor_cost, window.CURRENCY_CODE, window.LANGUAGE_CODE) : "--"}</div>
                            {errors.vendor_cost && <div className="data-panel__form__field__errors" aria-label="Field Errors">{errors.vendor_cost}</div>}
                        </div>
                        <div className="vendor-part-cost-label__field" aria-label="Vendor Part Number">
                            <div className={"vendor-part-cost-label__field-label" + (errors.vendor_part_number ? " invalid" : "")} aria-hidden="true">Vendor Part Number</div>
                            <div className="vendor-part-cost-label__field-value">{vendorPartCost.vendor_part_number || "--"}</div>
                            {errors.vendor_part_number && <div className="data-panel__form__field__errors" aria-label="Field Errors">{errors.vendor_part_number}</div>}
                        </div>
                    </div>
                </div>
                <div className="data-panel__list-select__option__icon"><i className="fa-sharp fa-angle-right" aria-hidden="true"></i></div>
            </WrapperButton>
        )
    }
}

export default InlineVendorPartCostDisplay;
