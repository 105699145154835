// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "[data-radix-popper-content-wrapper]{z-index:var(--z-index-dropdown) !important}.SelectList-base__kW3s0{width:var(--size-full);padding:var(--size-0);overflow:hidden;background:var(--surface-tertiary);border-radius:var(--border-radius-1_5);box-shadow:var(--box-shadow-xl-down)}.SelectList-container__QyGEQ{display:flex;flex-direction:column;gap:var(--size-1)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "SelectList-base__kW3s0",
	"container": "SelectList-container__QyGEQ"
};
export default ___CSS_LOADER_EXPORT___;
