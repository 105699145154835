import clsx from "clsx"

import { NavigationLink } from "@atoms"

import { ActivatableIcon } from "@molecules"

import styles from "./BottomBarItem.module.scss"
import { BottomBarItemProps } from "./BottomBarItem.types"

export default function BottomBarItem(props: BottomBarItemProps) {
    const { module, title, path, isActive, isDisabled } = props

    return (
        <NavigationLink
            title={title}
            path={path}
            className={clsx(styles.base, {
                [styles.disabled]: isDisabled,
            })}
            activeClassName={styles.active}
            isActive={isActive}
            isDisabled={isDisabled}
        >
            {(isActive) => (
                <div className={styles.innerContainer}>
                    <ActivatableIcon size={16} isActive={isActive} iconName={module} />
                    <span className={styles.label}>{title}</span>
                </div>
            )}
        </NavigationLink>
    )
}
