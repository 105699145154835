import PricebookCreate from "./PricebookCreate/PricebookCreate"
import PricebookEdit from "./PricebookEdit/PricebookEdit"
import PricebookList from "./PricebookList/PricebookList"
import PricebookReview from "./PricebookReview/PricebookReview"

const List = PricebookList
const Create = PricebookCreate
const Edit = PricebookEdit
const Review = PricebookReview

export default { List, Create, Edit, Review }
