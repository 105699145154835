import React, { Component } from "react";
import { formatLocalTime } from "../utils/utils";


class EventFeedItem extends Component {

    renderTimestamp = (timestamp, preferredTimezone) => {
        let emphasis = this.props.emphasis

        if (!["date", "time"].includes(emphasis)) {
            emphasis = null
        }

        const { localDateString, localTimeString } = formatLocalTime(timestamp, preferredTimezone, true, true, false, true)

        if (emphasis === "date") {
            return <span className="accordion__event__timestamp" aria-label="Timestamp"><em>{localDateString}</em> - {localTimeString}</span>
        }
        else if (emphasis == "time") {
            return <span className="accordion__event__timestamp" aria-label="Timestamp">{localDateString} - <em>{localTimeString}</em></span>
        }
        else {
            <span className="accordion__event__timestamp" aria-label="Timestamp">{localDateString} - {localTimeString}</span>
        }
    }

    render() {
        const { event } = this.props

        return (
            <li className="accordion__event" aria-label="Event">
                {this.renderTimestamp(event.timestamp, event.preferred_timezone)}
                <span className="accordion__event__event-string" aria-label="Event String" dangerouslySetInnerHTML={{__html: event.event_string}}></span>
                {event.message !== "" && <span className="accordion__event__message" aria-label="Event Message">&ldquo;{event.message}&rdquo;</span>}
            </li>
        )
    }
}

export default EventFeedItem;
