import React, { Component } from 'react';
import deepcopy from "rfdc";
import { sendDataToServer } from "../../../core/utils/utils";
import QuickBooksClientRecordDisplay from "./components/QuickBooksClientRecordDisplay";
import QuickBooksClientRecordForm from "./forms/QuickBooksClientRecordForm";
import { renderRecordClientString } from "./utils/utils";

const PAGE_MODES = {
    VIEW_RECORD: "VIEW_RECORD",
    EDIT_RECORD: "EDIT_RECORD",
}

const FORM_DATA_NAMES_BY_MODE = {
    EDIT_RECORD: "recordFormData",
}

class QuickBooksClientRecordCard extends Component {

    // Initialize

    constructor(props) {
        super(props)

        const defaultMode = this.props.formMode || PAGE_MODES.VIEW_RECORD

        let record = this.props.record

        // Add the created children to the children list
        record.children = record.children.filter(child => child.is_created_service_location === false)
        record.children = record.children.concat(record.created_children)

        // Only show children that match the current page
        if (["ERRORS", "NEW", "MATCHES"].includes(this.props.tab)) {
            record.children = record.children.filter(child => child.status_label === "Processed")
        }
        else if (["CONFIRMED"].includes(this.props.tab)) {
            record.children = record.children.filter(child => child.status_label === "Confirmed")
        }

        this.state = {
            removed: false,
            recordData: record,
            recordFormData: deepcopy()(this.props.record),

            errors: {
                record: {}
            },

            defaultMode: defaultMode,
            mode: defaultMode,
        }
    }

    // Form helpers

    updateFormData = (formName, fieldName, fieldValue) => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState[formName][fieldName] = fieldValue
            return updatedState
        })
    }

    switchFormMode = (mode) => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState.mode = mode
            return updatedState
        })
    }

    switchToDisplay = () => {
        this.setState((state, props) => {
            let updatedState = state

            // Reset primary form data
            updatedState[FORM_DATA_NAMES_BY_MODE[state.mode]] = deepcopy()(this.props.record)
            return updatedState
        })
        this.switchFormMode(PAGE_MODES.VIEW_RECORD)
    }

    switchToPrimaryForm = () => {
        this.switchFormMode(PAGE_MODES.EDIT_RECORD)
    }

    // Crud Record

    softDeleteRecord = (json) => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState.removed = true
            return updatedState
        })
    }

    updateRecord = async (endpoint, endpointMethod, onSuccess) => {
        const dataName = "recordFormData"
        const submittingName = "submittingRecord"
        const errorDictName = "record"

        const dataManipulator = (data, state) => {
            data = {}
            data.cleaned_client_client_type = state[dataName].cleaned_client_client_type
            data.cleaned_client_industry_type = state[dataName].cleaned_client_industry_type || null
            data.cleaned_client_name = state[dataName].cleaned_client_name
            data.cleaned_client_billing_address_recipient = state[dataName].cleaned_client_billing_address_recipient
            data.cleaned_client_billing_address_street = state[dataName].cleaned_client_billing_address_street
            data.cleaned_client_billing_address_unit = state[dataName].cleaned_client_billing_address_unit
            data.cleaned_client_billing_address_city = state[dataName].cleaned_client_billing_address_city
            data.cleaned_client_billing_address_state = state[dataName].cleaned_client_billing_address_state
            data.cleaned_client_billing_address_postal = state[dataName].cleaned_client_billing_address_postal
            data.cleaned_client_billing_address_country = state[dataName].cleaned_client_billing_address_country
            data.cleaned_client_notes = state[dataName].cleaned_client_notes
            data.contacts = deepcopy()(state[dataName].contacts)

            return data
        }

        const setErrors = (fieldName, message, errorDict) => {
            if (fieldName === "non_field_errors" && message === "The fields service_company_id, cleaned_client_name must make a unique set.") {
                errorDict["cleaned_client_name"] = "A import record with this name already exists."
            }
            else {
                errorDict[fieldName] = message
            }
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, undefined, dataManipulator, setErrors)
    }

    // Handle Actions

    handleActionRequest = (action) => {
        let endpoint

        switch (action) {
            case "RECORD_EDIT_CLIENT":
                this.switchToPrimaryForm()
                break
            case "RECORD_UPDATE_CLIENT":
                endpoint = DjangoUrls["quickbooks_desktop:api-quickbooks-desktop-records-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.recordData.id)
                this.updateRecord(endpoint, "PATCH", this.softDeleteRecord)
                break
            case "RECORD_CANCEL_CLIENT_EDITS":
                this.switchToDisplay()
                break
            case "RECORD_IGNORE":
                endpoint = DjangoUrls["quickbooks_desktop:api-quickbooks-desktop-records-ignore"](window.MARKETPLACE_ENTITY_SLUG, this.state.recordData.id)
                this.updateRecord(endpoint, "POST", this.softDeleteRecord)
                break
            case "RECORD_CONFIRM":
                endpoint = DjangoUrls["quickbooks_desktop:api-quickbooks-desktop-records-confirm"](window.MARKETPLACE_ENTITY_SLUG, this.state.recordData.id)
                this.updateRecord(endpoint, "POST", this.softDeleteRecord)
                break
            case "RECORD_UNCONFIRM":
                endpoint = DjangoUrls["quickbooks_desktop:api-quickbooks-desktop-records-unconfirm"](window.MARKETPLACE_ENTITY_SLUG, this.state.recordData.id)
                this.updateRecord(endpoint, "POST", this.softDeleteRecord)
                break
            default:
                console.error(`No action handler exists for action "${action}".`)
        }
    }

    // Render

    renderBody = () => {
        if (this.state.mode === PAGE_MODES.VIEW_RECORD) {
            return <QuickBooksClientRecordDisplay
                record={this.state.recordData}
                requestAction={this.handleActionRequest}
            />
        }
        else if (this.state.mode === PAGE_MODES.EDIT_RECORD) {
            return <QuickBooksClientRecordForm
                submitting={this.state.submittingRecord}
                record={this.state.recordFormData}
                errors={this.state.recordData.errors}
                formErrors={this.state.errors.record}
                onFormDataChange={(fieldName, fieldValue) => this.updateFormData(FORM_DATA_NAMES_BY_MODE[this.state.mode], fieldName, fieldValue)}
                requestAction={this.handleActionRequest}
            />
        }
        else {
            return (
                <div className="data-panel-container data-panel-container--with-margin">
                    <div className="data-panel" aria-label="Unknown Form Mode">
                        <div className="data-panel__form">
                            <p className="data-panel__form__caption">An unhandled form mode was supplied.</p>
                        </div>
                    </div>
                </div>
            )
        }
    }

    render() {
        return !this.state.removed && (
            <div className="data-panel-container data-panel-container--with-margin" style={{width: "100%"}}>
                <div className="data-panel data-panel--quickbooks" aria-label="QuickBooks Client Record">
                    <div className="data-panel__heading" aria-label="Client Information">
                        <span className="data-panel__heading__title" aria-label="Name">{renderRecordClientString(this.state.recordData)}</span>
                        <hr className={Object.keys(this.state.recordData.errors).length !== 0 || this.state.recordData.child_has_errors === true ? "invalid" : ""} aria-hidden="true" />
                    </div>
                    {this.renderBody()}
                </div>
            </div>
        )
    }
}

export default QuickBooksClientRecordCard;
