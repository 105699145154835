import React, { Component } from 'react';
import Select from 'react-select';


class CardFilterInput extends Component {
    constructor(props) {
        super(props)

        this.state = {
            searchQuery: "",
            dropdownSelection: "0",
            dropdownSelectionOption: {"value": "0", "label": "All Types"}
        }
    }

    _handleKwargsChange = (key, value) => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState[key] = value
            this.props.onKwargsChange(updatedState)
            return updatedState
        });
    }

    handleSearchQueryChange = (event) => {
        this._handleKwargsChange("searchQuery", event.target.value)
    }

    handleDropdownSelectionChange = (option) => {
        this._handleKwargsChange("dropdownSelection", option.value)
        this._handleKwargsChange("dropdownSelectionOption", option)
    }

    renderSearchMechanism = () => {
        if (this.props.showSearch) {
            return (
                <div className={"card-filter-mechanism card-filter__search" + (this.props.showDropdown ? "" : " card-filter-mechanism--full")} aria-label="Search Mechanism">
                    <input title="Search..." type="text" placeholder="Search..." value={this.state.searchQuery} onChange={this.handleSearchQueryChange} />
                </div>
            )
        }
    }

    renderDropdownMechanism = () => {
        if (this.props.showDropdown) {
            return (
                <div className={"card-filter-mechanism card-filter__dropdown-select" + (this.props.showSearch ? "" : " card-filter-mechanism--full")} aria-label="Dropdown Filter Mechanism">
                    <Select
                        isSearchable={false}
                        value={this.state.dropdownSelectionOption}
                        onChange={this.handleDropdownSelectionChange}
                        options={this.props.dropdownChoices}
                        className="react-select-container"
                        classNamePrefix="react-select"
                    />
                </div>
            )
        }
    }

    render() {
        return (
            <div className="card-filter" aria-label="Card Filter Mechanisms">
                {this.renderSearchMechanism()}
                {this.renderDropdownMechanism()}
            </div>
        )
    }
}

export default CardFilterInput;
