const API_BASE_URL = process.env.BASE_URL

export const CALENDAR_DATA_ENDPOINTS = {
    DATA: API_BASE_URL + "/{service_company_slug}/api/calendar/{date}/data/",
    AVAILABILITY: API_BASE_URL + "/{service_company_slug}/api/calendar/{date}/data/availability/",
    LIST_UNSCHEDULED_JOBS: API_BASE_URL + "/{service_company_slug}/api/calendar/unscheduled/",
}

export const AVAILABILITY_SCHEDULES_ENDPOINTS = {
    LIST: API_BASE_URL + "/{service_company_slug}/api/calendar/{date}/availability-schedules/",
}
