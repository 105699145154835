import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import { ReactNode } from "react"

import { CustomIcon, Icon } from "@atoms"

import { ObjectsViewType } from "@organisms/ObjectsView/ObjectsView.types"

export default function getObjectsViewIcon(viewType: ObjectsViewType): ReactNode | null {
    if (viewType === "table") {
        return (
            <Icon
                icon={icon({
                    name: "table-cells-large",
                    style: "light",
                    family: "sharp",
                })}
                size={14}
            />
        )
    } else if (viewType === "card") {
        return (
            <Icon
                icon={icon({
                    name: "diagram-cells",
                    style: "light",
                    family: "sharp",
                })}
                size={14}
            />
        )
    } else if (viewType === "timeline") {
        return <CustomIcon icon="fa-sharp-light-timeline-view" size={14} />
    } else {
        return null
    }
}
