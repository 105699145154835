import PurchaseOrderDetailsContainer from "@legacy/purchase_orders/PurchaseOrderDetailsContainer"

import useToastQueue from "@hooks/useToastQueue"

import { AppFrame } from "@templates"

export default function PurchaseOrderPublicDetails() {
    const { addToastToQueue } = useToastQueue()
    return (
        <AppFrame.Root pageId="PurchaseOrderPublicDetails" navigationType="none">
            <AppFrame.PageContent>
                <PurchaseOrderDetailsContainer addToastToQueue={addToastToQueue} />
            </AppFrame.PageContent>
        </AppFrame.Root>
    )
}
