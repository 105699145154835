import { Component, Fragment } from "react";
import ButtonGroup from "../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../core/buttons/UniversalButton";
import BooleanField from "../../core/fields/BooleanField";
import CharField from "../../core/fields/CharField";
import EmailField from "../../core/fields/EmailField";
import PhoneNumberField from "../../core/fields/PhoneNumberField";


class InlineContactForm extends Component {

    renderButtons = () => {
        const { contact, isNewAddition, onFormDataChange, requestAction } = this.props

        return (
            <ButtonGroup>
                <ButtonGroupRow>
                    <UniversalButton type="primary" text="Save" handler={event => requestAction("CONTACT_SAVE")} />
                </ButtonGroupRow>
                {
                    isNewAddition
                    ?
                    <ButtonGroupRow>
                        <UniversalButton type="secondary" text="Cancel" handler={event => requestAction("CONTACT_DELETE")} />
                    </ButtonGroupRow>
                    :
                    <Fragment>
                        <ButtonGroupRow>
                            <UniversalButton type="secondary" text="Cancel Edits" handler={event => requestAction("CONTACT_CANCEL_EDITS")} />
                        </ButtonGroupRow>
                        <ButtonGroupRow>
                            <UniversalButton type="danger" text="Delete Contact" handler={event => requestAction("CONTACT_DELETE")} />
                        </ButtonGroupRow>
                    </Fragment>
                }

            </ButtonGroup>
        )
    }

    render() {
        const { contact, isNewAddition, onFormDataChange, requestAction } = this.props
        const errors = contact.errors || {}

        return (
            <div className="data-panel__list-select__form">
                <div className="data-panel__form" aria-label="Contact Edit Form">
                    {errors.non_field_error && <div className="data-panel__form__field__errors" aria-label="Non Field Error">{errors.non_field_error}</div>}
                    <CharField
                        fieldName="name"
                        fieldLabel="Name"
                        fieldValue={contact.name || ""}
                        fieldOnChange={name => onFormDataChange("name", name || "")}
                        maxLength="100"
                        optional={true}
                        errors={errors}
                    ></CharField>
                    <PhoneNumberField
                        fieldName="phone"
                        fieldLabel="Phone"
                        fieldValue={contact.phone || ""}
                        fieldOnChange={phone => onFormDataChange("phone", phone || "")}
                        extensionFieldName="phone_extension"
                        extensionFieldLabel="Ext."
                        extensionFieldValue={contact.phone_extension || ""}
                        extensionOnChange={phone_extension => onFormDataChange("phone_extension", phone_extension || "")}
                        defaultCountryCode={window.PHONE_NUMBER_COUNTRY}
                        optional={true}
                        errors={errors}
                        showExtensionField={true}
                    ></PhoneNumberField>
                    <EmailField
                        fieldName="email"
                        fieldLabel="Email"
                        fieldValue={contact.email || ""}
                        fieldOnChange={email => onFormDataChange("email", email || "")}
                        optional={true}
                        errors={errors}
                    ></EmailField>
                    <BooleanField
                        fieldName="primary"
                        fieldLabel="Primary"
                        fieldValue={contact.primary || false}
                        fieldOnChange={primary => onFormDataChange("primary", primary)}
                        inputLabel="Primary Contact"
                        errors={errors}
                    ></BooleanField>
                    {window.USER_TYPE === "SERVICE_DISPATCHER" && (contact.attached_to === "external_client" || contact.attached_to === "service_location") && (
                        <>
                            <BooleanField
                                fieldName="receive_invoice_reminders_via_email"
                                fieldLabel="Reminders"
                                fieldValue={contact.receive_invoice_reminders_via_email || false}
                                fieldOnChange={(receive_invoice_reminders_via_email) =>
                                    onFormDataChange(
                                        "receive_invoice_reminders_via_email",
                                        receive_invoice_reminders_via_email,
                                    )
                                }
                                inputLabel="Receive Invoice Reminders via Email"
                                errors={errors}
                            ></BooleanField>
                            <BooleanField
                                fieldName="receive_invoice_reminders_via_sms"
                                fieldLabel={null}
                                fieldValue={contact.receive_invoice_reminders_via_sms || false}
                                fieldOnChange={(receive_invoice_reminders_via_sms) =>
                                    onFormDataChange(
                                        "receive_invoice_reminders_via_sms",
                                        receive_invoice_reminders_via_sms,
                                    )
                                }
                                inputLabel="Receive Invoice Reminders via SMS"
                                errors={errors}
                            ></BooleanField>
                            <BooleanField
                                fieldName="receive_estimate_reminders_via_email"
                                fieldLabel={null}
                                fieldValue={contact.receive_estimate_reminders_via_email || false}
                                fieldOnChange={(receive_estimate_reminders_via_email) =>
                                    onFormDataChange(
                                        "receive_estimate_reminders_via_email",
                                        receive_estimate_reminders_via_email,
                                    )
                                }
                                inputLabel="Receive Estimate Reminders via Email"
                                errors={errors}
                            ></BooleanField>
                            <BooleanField
                                fieldName="receive_estimate_reminders_via_sms"
                                fieldLabel={null}
                                fieldValue={contact.receive_estimate_reminders_via_sms || false}
                                fieldOnChange={(receive_estimate_reminders_via_sms) =>
                                    onFormDataChange(
                                        "receive_estimate_reminders_via_sms",
                                        receive_estimate_reminders_via_sms,
                                    )
                                }
                                inputLabel="Receive Estimate Reminders via SMS"
                                errors={errors}
                            ></BooleanField>
                        </>
                    )}
                </div>
                {this.renderButtons()}
            </div>
        )
    }
}

export default InlineContactForm;
