import React, { Component } from "react";


class CalendarTimeSlotWrapper extends Component {

    render = () => {
        let classNames = new Set(["calendar-timeslotwrapper"])

        if (this.props.resource) {
            // If this timeslot isn't in working hours, it's unavailable
            if (this.props.date < this.props.startOfDate || this.props.date >= this.props.endOfDate) {
                classNames.add("calendar-timeslotwrapper--unavailable")
            }

            // If the resource is unavailable, this timeslot is unavailable
            if (!this.props.resource.available) {
                classNames.add("calendar-timeslotwrapper--unavailable")
            }
        }
        else {
            // This is the time gutter. If the date is in the past, add a flag
            if (this.props.date < new Date()) {
                classNames.add("calendar-timeslotwrapper--isPast")
            }
        }

        return (
            <div className={[...classNames].join(" ")}>
                {this.props.children}
            </div>
        )
    }
}

export default CalendarTimeSlotWrapper;
