export const DECODED_PRICEBOOK_ITEM_URL_PARAM_TYPE_VALUES_MAP: { [key: string]: string } = {
    Services: "Services",
    Parts: "Parts+%26+Materials",
    "Parts+%26+Materials": "Parts+%26+Materials",
    "Parts & Materials": "Parts+%26+Materials",
    Miscellaneous: "Miscellaneous",
    Discounts: "Discounts",
    "Tax+Rates": "Tax+Rates",
    "Tax Rates": "Tax+Rates",
}
