// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NotificationTopicCard-base__jKsPZ{position:relative;display:flex;gap:var(--size-2);align-items:center;width:var(--size-full);padding:var(--size-4) var(--size-3);overflow:hidden}.NotificationTopicCard-iconAndText__r0TNO{display:flex;flex:1 0 0;gap:var(--size-1_5);align-items:flex-start;padding:var(--size-0);border-radius:var(--border-radius-0)}.NotificationTopicCard-text__uoGe_{display:flex;flex:1 0 0;flex-direction:column;gap:var(--size-0_5);align-items:flex-start;padding:var(--size-0);border-radius:var(--border-radius-0)}.NotificationTopicCard-title__L9y4a{align-self:stretch;color:var(--gray-12);font:var(--font-global-heading-03);font-feature-settings:var(--feature-settings-global-heading-03);letter-spacing:var(--letter-spacing-global-heading-03)}.NotificationTopicCard-caption__x9LXE{align-self:stretch;color:var(--gray-a11);font:var(--font-global-caption-md-375);font-feature-settings:var(--feature-settings-global-caption-md-375);letter-spacing:var(--letter-spacing-global-caption-md-375)}.NotificationTopicCard-chevron__em6uu{color:var(--gray-a9)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "NotificationTopicCard-base__jKsPZ",
	"iconAndText": "NotificationTopicCard-iconAndText__r0TNO",
	"text": "NotificationTopicCard-text__uoGe_",
	"title": "NotificationTopicCard-title__L9y4a",
	"caption": "NotificationTopicCard-caption__x9LXE",
	"chevron": "NotificationTopicCard-chevron__em6uu"
};
export default ___CSS_LOADER_EXPORT___;
