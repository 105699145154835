import { Command } from "cmdk"

import { AppSearch } from "@organisms"

import { AppFrame } from "@templates"

export default function Search() {
    return (
        <AppFrame.Root pageId="Search">
            <Command label="Command Menu" shouldFilter={false}>
                <AppSearch />
            </Command>
        </AppFrame.Root>
    )
}
