// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModalFooter-base__ryq8m{display:flex;gap:var(--size-2);align-items:center;align-self:stretch;justify-content:space-between;padding:var(--size-4)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "ModalFooter-base__ryq8m"
};
export default ___CSS_LOADER_EXPORT___;
