import React, { Component } from "react";


class ButtonGroup extends Component {

    render() {
        return (
            <div className={`button-group ${this.props.className || ""}`} aria-label="Button Group">
                {this.props.children}
            </div>
        )
    }
}

export default ButtonGroup;
