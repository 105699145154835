import JobCreateContainer from "@legacy/jobs/JobCreateContainer"

import useIsDesktop from "@hooks/useIsDesktop"
import useSlugExtractor from "@hooks/useSlugExtractor"
import useToastQueue from "@hooks/useToastQueue"

import { TopBar } from "@organisms"

import { AppFrame } from "@templates"

import { JOBS_ROUTES } from "@routes/jobs"

export default function JobEdit() {
    const isDesktop = useIsDesktop()

    const jobId = window.JOB_ID
    const isDraft = window.JOB_IS_DRAFT
    const { addToastToQueue } = useToastQueue()

    const [listRoute, detailsRoute] = useSlugExtractor([JOBS_ROUTES.LIST, JOBS_ROUTES.DETAILS], jobId)

    return (
        <AppFrame.Root pageId="JobEdit">
            <TopBar.Root>
                <TopBar.Header>
                    <TopBar.TopBarHeaderStart>
                        <TopBar.PageTitle iconOnly={!isDesktop} iconName="jobs" label="Jobs" destination={listRoute} />
                        <TopBar.VerticalDivider />
                        <TopBar.PageSubtitle label={isDraft ? "New Job" : "Edit Job"} backDestination={detailsRoute} />
                    </TopBar.TopBarHeaderStart>
                </TopBar.Header>
            </TopBar.Root>
            <AppFrame.PageContent>
                <JobCreateContainer formMode="EDIT_JOB" addToastToQueue={addToastToQueue} />
            </AppFrame.PageContent>
        </AppFrame.Root>
    )
}
