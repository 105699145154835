// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BottomSheetSortingColumns-sortingContainer__pddvT{padding:var(--size-0)}.BottomSheetSortingColumns-sortingColumns__zt1vU{display:flex;flex-direction:column;gap:var(--size-0_5);padding:var(--size-2_5)}.BottomSheetSortingColumns-sortingOrderSelector__FKhS3{padding:var(--size-2_5)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sortingContainer": "BottomSheetSortingColumns-sortingContainer__pddvT",
	"sortingColumns": "BottomSheetSortingColumns-sortingColumns__zt1vU",
	"sortingOrderSelector": "BottomSheetSortingColumns-sortingOrderSelector__FKhS3"
};
export default ___CSS_LOADER_EXPORT___;
