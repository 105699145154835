import useUser from "@hooks/useUser"

import { JobEventDrag } from "@organisms/ObjectsView/JobTimelineView/JobEventDrag"
import { EventModel, EventRecord } from "@organisms/ObjectsView/JobTimelineView/JobTimelineView.types"
import { GRID_ROW_HEIGHT } from "@organisms/ObjectsView/JobTimelineView/JobTimelineViewConfig/JobTimelineViewConfig"

import useJobTimelineViewBryntumInstances from "./useJobTimelineViewBryntumInstances"
import useJobTimelineViewData from "./useJobTimelineViewData"
import useJobTimelineViewOverlappingUtils from "./useJobTimelineViewOverlappingUtils"
import useJobTimelineViewStates from "./useJobTimelineViewStates"

export default function useTimelineViewUnscheduledJobsUtils() {
    const { user } = useUser()
    const serviceCompanySlug = user?.service_company?.slug
    const preferredTimezone = user?.service_company?.preferred_timezone

    const {
        isFetchingUnscheduledJobs,
        unscheduledJobsPaginationHasNextPage,
        fetchNextUnscheduledJobsPage,
        unscheduledJobsData,
        refetchUnscheduledJobs,
        syncJobUpdatesWithServer,
        addEventToCache,
    } = useJobTimelineViewData()

    const { schedulerPro, unscheduledJobsGridDrag, unscheduledJobsGrid } = useJobTimelineViewBryntumInstances()

    const {
        setLimitOfItemsPerGridFetch,
        timelineViewOrientation,
        setTotalOfUnscheduledJobs,
        jobCardStyle,
        collapseUnscheduledJobsColumn,
        expandUnscheduledJobsColumn,
    } = useJobTimelineViewStates()

    const { handleOverlappingEvents, removeAllOverlappingRegionFeedback } = useJobTimelineViewOverlappingUtils()

    const hasFetchedUnscheduledJobs = () => {
        return unscheduledJobsData?.pages?.[0].results.length > 0
    }

    const getAllUnscheduledJobs = (): CalendarEvent[] => {
        return unscheduledJobsData?.pages?.flatMap((page) => page.results) || []
    }

    const countTotalUnscheduledJobs = () => {
        return unscheduledJobsData?.pages?.[0].count || 0
    }

    const manageUnscheduledJobsProcess = () => {
        const unscheduledJobs = getAllUnscheduledJobs()

        const countOfUnscheduledJobs = countTotalUnscheduledJobs()

        if (hasFetchedUnscheduledJobs()) {
            fillGridWithUnscheduledJobs(unscheduledJobs)
        }

        if (Number.isSafeInteger(countOfUnscheduledJobs)) {
            setTotalOfUnscheduledJobs(countOfUnscheduledJobs)
        }
    }

    const fillGridWithUnscheduledJobs = (unscheduledJobs: CalendarEvent[]) => {
        const events: EventRecord[] = unscheduledJobs.map((job) => {
            const DURATION_IN_HOURS = job.estimated_duration / 3600

            return {
                name: job.service_name,
                startDate: null,
                endDate: null,
                duration: DURATION_IN_HOURS,
                ...job,
            } as EventRecord
        })

        const existingEvents = schedulerPro.current.instance.events as EventModel<CalendarEvent>[]

        schedulerPro.current.instance.events = [...existingEvents, ...events]
    }

    const onUnscheduledJobsGridPaint = ({
        source: { currentElement },
    }: {
        source: {
            currentElement: HTMLElement
        }
    }) => {
        setLimitOfItemsPerGridFetch(Math.ceil(currentElement.offsetHeight / GRID_ROW_HEIGHT))
    }

    const onMobileUnscheduledJobsGridPaint = () => {
        setLimitOfItemsPerGridFetch(Math.ceil(window.innerHeight / GRID_ROW_HEIGHT))
    }

    const onUnscheduledJobsGridScroll = ({
        source: { currentElement },
    }: {
        source: {
            currentElement: HTMLElement
        }
    }) => {
        const scrollerWrapper = currentElement.querySelector(".b-widget-scroller.b-vertical-overflow")
        const scrollerElement = scrollerWrapper.querySelector(".b-grid-vertical-scroller")

        const boundingClientRect = scrollerWrapper.getBoundingClientRect()
        const isAtTheEndScrollOfElement =
            scrollerWrapper.scrollTop + boundingClientRect.height >= scrollerElement.scrollHeight

        if (isAtTheEndScrollOfElement && !isFetchingUnscheduledJobs && unscheduledJobsPaginationHasNextPage) {
            void fetchNextUnscheduledJobsPage()
        }
    }

    const onMobileUnscheduledJobsGridScroll = ({
        source: { currentElement },
    }: {
        source: {
            currentElement: HTMLElement
        }
    }) => {
        const scrollerWrapper = currentElement.querySelector(".b-widget-scroller.b-vertical-overflow")
        const scrollerElement = scrollerWrapper.querySelector(".b-grid-vertical-scroller")

        const boundingClientRect = scrollerWrapper.getBoundingClientRect()
        const isAtTheEndScrollOfElement =
            scrollerWrapper.scrollTop + boundingClientRect.height >= scrollerElement.scrollHeight

        scrollerElement.setAttribute("data-no-drag", "true")

        if (isAtTheEndScrollOfElement && !isFetchingUnscheduledJobs && unscheduledJobsPaginationHasNextPage) {
            void fetchNextUnscheduledJobsPage()
        }
    }

    const initializeUnscheduledJobsGrid = () => {
        // for avoiding the creation of multiple instances of the drag and drop functionality, we destroy the previous one
        unscheduledJobsGridDrag.current?.destroy()
        const scheduler = schedulerPro.current?.instance

        const grid = unscheduledJobsGrid.current?.instance
        const { project } = scheduler

        // Create a chained store that will only contain events that are not scheduled yet.
        const chainedStore = (grid.store = project.eventStore.chain((eventRecord: EventRecord) => {
            return !eventRecord.startDate
        }, undefined))

        // When assignments change, update our chained store to reflect the changes.
        project.assignmentStore.on({
            change: () => {
                chainedStore.fillFromMaster()
            },
            thisObj: grid,
        })

        unscheduledJobsGridDrag.current = new JobEventDrag({
            grid,
            schedule: scheduler,
            constrain: false,
            outerElement: grid.element,
            serviceCompanySlug: serviceCompanySlug,
            preferredTimezone: preferredTimezone,
            schedulerIsInCompactMode: jobCardStyle === "compact" && timelineViewOrientation === "horizontal",
            collapseUnscheduledJobsColumn,
            expandUnscheduledJobsColumn,
            refetchUnscheduledJobs,
            handleOverlappingEvents,
            removeAllOverlappingRegionFeedback,
            syncJobUpdatesWithServer,
            addEventToCache,
        })
    }

    return {
        hasFetchedUnscheduledJobs,
        getAllUnscheduledJobs,
        countTotalUnscheduledJobs,
        manageUnscheduledJobsProcess,
        initializeUnscheduledJobsGrid,
        fillGridWithUnscheduledJobs,
        onUnscheduledJobsGridPaint,
        onUnscheduledJobsGridScroll,
        onMobileUnscheduledJobsGridPaint,
        onMobileUnscheduledJobsGridScroll,
    }
}
