import clsx from "clsx"

import styles from "./NoValueCell.module.scss"
import { NoValueCellProps } from "./NoValueCell.types"

export default function NoValueCell(props: NoValueCellProps) {
    const { isRightAligned } = props

    return (
        <div
            className={clsx(styles.base, {
                [styles.rightAligned]: isRightAligned,
            })}
        >
            —
        </div>
    )
}
