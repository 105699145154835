import React, { Component } from "react";



class CalendarResourceHeader extends Component {

    render = () => {
        const resource = this.props.resource
        const name = this.props.calendarExpanded ? resource.full_name : resource.short_name
        const status = resource.status !== "" ? resource.status : resource.available ? "Working" : "Not Working"

        return (
            <div className={`calendar-resource-header${resource.available ? "" : " calendar-resource-header--unavailable"}`} title={`${name} - ${status}`}>
                <span><strong>{name}</strong></span>
                <span><em>{status}</em></span>
            </div>
        )
    }
}

export default CalendarResourceHeader;
