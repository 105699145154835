import { useInfiniteQuery } from "@tanstack/react-query"
import { useMemo, useState } from "react"

import useLocalStorage from "@hooks/useLocalStorage"
import useUser from "@hooks/useUser"

import extractURLSearchParam from "@utils/extractURLSearchParam"
import fetchObjectListData from "@utils/fetchObjectListData"
import getOrderingFromSortingState from "@utils/getOrderingFromSortingState"
import getSortingStateFromOrdering from "@utils/getSortingStateFromOrdering"

import { TopBar } from "@organisms"
import { ObjectListData } from "@organisms/ObjectsView/ObjectsView.types"
import { SortingState } from "@organisms/Table/Table.types"
import TableViewControls from "@organisms/Table/TableViewControls/TableViewControls"

import { LIST_SEARCH_PARAM_ORDERING } from "@constants/searchParams"

import { CardListProps } from "./CardList.types"
import CardListContent from "./CardListContent/CardListContent"

function CardList(props: CardListProps) {
    const {
        listTitle,
        objectType,
        objectTypeVariation,
        objectCreateRoute,
        phase,
        tabs,
        activeTabConfig,
        activeTab,
        setActiveTab,
        orderingKey,
        searchKeywords,
        setSearchKeywords,
        onShowOpen,
        onShowClosed,
        CardComponent,
    } = props

    const [isRefetching, setIsRefetching] = useState(false)
    const { isLoading: isUserLoading } = useUser()

    const tabContentName = `${activeTabConfig?.title} ${listTitle}`
    const listEndpoint = activeTabConfig?.listEndpoint
    const endpointKwargs = activeTabConfig?.endpointKwargs

    // Get sorting
    const sortingOverride = useMemo(() => extractURLSearchParam(LIST_SEARCH_PARAM_ORDERING), [])
    const defaultOrdering = useMemo(
        () => endpointKwargs?.find((kwarg) => kwarg[0] === "ordering")?.[1] || "",
        [activeTabConfig],
    )
    const [ordering, setOrdering] = useLocalStorage(orderingKey, "", sortingOverride)

    const sorting = getSortingStateFromOrdering(ordering || (defaultOrdering as string))
    const setSorting = (newSorting: SortingState) => {
        setOrdering(getOrderingFromSortingState(newSorting))
    }

    const { data, error, isLoading, isError, fetchNextPage, hasNextPage, isFetchingNextPage, refetch } =
        useInfiniteQuery<ObjectListData>({
            queryKey: [listEndpoint, endpointKwargs, tabContentName, searchKeywords, sorting],
            queryFn: ({ pageParam }) =>
                fetchObjectListData({
                    objectName: tabContentName,
                    endpoint: listEndpoint,
                    endpointKwargs,
                    searchKeywords,
                    sorting,
                    nextPage: pageParam as string,
                }),
            initialPageParam: "",
            staleTime: 60000,
            getNextPageParam: (lastPage) => lastPage.next,
            enabled: !!(activeTabConfig && activeTabConfig.showTab),
        })

    const currentData = useMemo(() => data?.pages.map((page) => page.results).flat(1) || [], [data])

    const handleRefetch = async () => {
        setIsRefetching(true)
        await refetch()
        setIsRefetching(false)
    }

    return (
        <>
            <TopBar.Portal>
                {/* TODO: Replace with CardListViewControls */}
                <TableViewControls
                    phase={phase}
                    tabs={tabs}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    searchKeywords={searchKeywords}
                    setSearchKeywords={setSearchKeywords}
                    objectType={objectType}
                    onShowOpen={onShowOpen}
                    onShowClosed={onShowClosed}
                    showTableViewMenu={false}
                />
            </TopBar.Portal>
            <CardListContent
                activeTabConfig={activeTabConfig}
                currentData={currentData}
                setSorting={setSorting}
                fetchNextPage={fetchNextPage}
                hasNextPage={hasNextPage}
                isFetchingNextPage={isFetchingNextPage}
                objectType={objectType}
                objectTypeVariation={objectTypeVariation}
                activeTab={activeTab}
                tabContentName={tabContentName}
                tabs={tabs}
                error={error}
                refetchObjects={handleRefetch}
                objectCreateRoute={objectCreateRoute}
                CardComponent={CardComponent}
                isEmpty={currentData?.length === 0}
                isLoading={isLoading || isUserLoading || isRefetching}
                isSearching={!!searchKeywords}
                isError={isError}
            />
        </>
    )
}

const Root = CardList

export default {
    Root,
}
