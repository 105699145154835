import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import { Root } from "@radix-ui/react-label"
import clsx from "clsx"
import { useMemo } from "react"

import { Icon, Tooltip } from "@atoms"
import { IconSize } from "@atoms/Icon/Icon.types"

import styles from "./Label.module.scss"
import { LabelProps } from "./Label.types"

export default function Label(props: LabelProps) {
    const { children, size, variant = "default", leftIcon, tooltip, htmlFor, ...rest } = props

    const iconSize = useMemo(() => {
        const sizeMap: { [key in LabelProps["size"]]: IconSize } = {
            sm: 12,
            md: 14,
            lg: 16,
        }
        return sizeMap[size]
    }, [size])

    return (
        <div className={styles.wrapper}>
            {leftIcon && (
                <span className={styles[variant]}>
                    <Icon icon={leftIcon} size={iconSize} />
                </span>
            )}
            <Root
                className={clsx(
                    styles.base,
                    {
                        [styles.clickable]: htmlFor !== undefined && variant !== "disabled",
                    },
                    styles[variant],
                    {
                        [styles.baseSm]: size === "sm",
                        [styles.baseMd]: size === "md",
                        [styles.baseLg]: size === "lg",
                    },
                )}
                htmlFor={htmlFor}
                {...rest}
            >
                {children}
            </Root>
            {tooltip && variant !== "disabled" && (
                <Tooltip
                    {...tooltip}
                    triggerClassName={clsx(tooltip.triggerClassName, styles.base, styles.tooltipTrigger)}
                >
                    <span>
                        <Icon
                            icon={icon({ name: "info-circle", style: "regular", family: "sharp" })}
                            size={iconSize}
                        />
                    </span>
                </Tooltip>
            )}
        </div>
    )
}
