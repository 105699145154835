import Spinner from "@legacy/core/components/Spinner";
import { Component } from "react";
import deepcopy from "rfdc";
import ButtonGroup from "../../../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../../../core/buttons/UniversalButton";
import AddressAutocompleteFieldGroup from "../../../../core/fields/AddressAutocompleteFieldGroup";
import CharField from "../../../../core/fields/CharField";
import ListSelectField from "../../../../core/fields/ListSelectField";
import TextField from "../../../../core/fields/TextField";
import { valueIsDefined } from "../../../../core/utils/utils";
import RecordContactListEditSelect from "../components/RecordContactListEditSelect";


const CLEANED_SERVICE_LOCATION_BILLING_ADDRESS_FIELD_NAMES = [
    "cleaned_service_location_billing_address_recipient",
    "cleaned_service_location_billing_address_street",
    "cleaned_service_location_billing_address_unit",
    "cleaned_service_location_billing_address_city",
    "cleaned_service_location_billing_address_state",
    "cleaned_service_location_billing_address_postal",
    "cleaned_service_location_billing_address_country",
]


class QuickBooksServiceLocationRecordForm extends Component {

    constructor(props) {
        super(props)

        this.state = {
            useBillingAddress: CLEANED_SERVICE_LOCATION_BILLING_ADDRESS_FIELD_NAMES.some(fieldName => valueIsDefined(this.props.serviceLocationRecord[fieldName])),
        }
    }

    renderButtons = () => {
        const {submitting, serviceLocationRecord, errors, formErrors, onFormDataChange, requestAction} = this.props

        if (formErrors.unexpectedError) {
            return (
                <div className="data-panel__action-feedback">
                    <span className="text-invalid"><strong>An unexpected error occurred.</strong></span>
                </div>
            )
        }
        else {
            if (submitting) {
                return (
                    <div className="data-panel__action-feedback">
                        <Spinner centered={true} />
                    </div>
                )
            }
            else {
                return (
                    <ButtonGroup>
                        <ButtonGroupRow>
                            <UniversalButton type="primary" text="Save" handler={event => requestAction("RECORD_UPDATE_SERVICE_LOCATION")} />
                        </ButtonGroupRow>
                        <ButtonGroupRow>
                            <UniversalButton type="secondary" text="Cancel Edits" handler={event => requestAction("RECORD_CANCEL_SERVICE_LOCATION_EDITS")} />
                        </ButtonGroupRow>
                    </ButtonGroup>
                )
            }
        }
    }


    toggleUseBillingAddress = () => {
        this.props.onFormDataChange("cleaned_service_location_billing_address_recipient", "")
        this.props.onFormDataChange("cleaned_service_location_billing_address_street", "")
        this.props.onFormDataChange("cleaned_service_location_billing_address_unit", "")
        this.props.onFormDataChange("cleaned_service_location_billing_address_city", "")
        this.props.onFormDataChange("cleaned_service_location_billing_address_state", "")
        this.props.onFormDataChange("cleaned_service_location_billing_address_postal", "")
        this.props.onFormDataChange("cleaned_service_location_billing_address_country", "")
        this.props.onFormDataChange("cleaned_service_location_billing_address_formatted", "")
        this.props.onFormDataChange("cleaned_service_location_billing_address_formatted_multiline", "")
        this.setState({useBillingAddress: !this.state.useBillingAddress})
    }

    render() {
        const {submitting, serviceLocationRecord, formErrors, onFormDataChange, requestAction} = this.props
        let { errors } = this.props
        errors = deepcopy()(errors)

        if (errors.cleaned_service_location_physical_address_street) {
            errors.cleaned_service_location_physical_address_street += ` If it helps, QuickBooks identifies this record as \n"${serviceLocationRecord.name}".`
        }

        const contacts = serviceLocationRecord.contacts.filter(contact => contact.ignored !== true)

        return (
            <div className="data-panel__list-select__form">
                <div className="data-panel__form" aria-label="Record Edit Form">
                    <CharField
                        fieldName="cleaned_service_location_name"
                        fieldLabel="Location Name"
                        fieldValue={serviceLocationRecord.cleaned_service_location_name || ""}
                        fieldOnChange={cleaned_service_location_name => onFormDataChange("cleaned_service_location_name", cleaned_service_location_name || "")}
                        maxLength="100"
                        placeholder="Nickname, Franchise ID, etc."
                        optional={true}
                        errors={errors}
                    ></CharField>
                    <AddressAutocompleteFieldGroup
                        key="record_cleaned_service_location_physical_address"
                        fieldGroupName="cleaned_service_location_physical_address"
                        fieldGroupLabel="Location Address"
                        fieldPrefix="cleaned_service_location_physical_address"
                        fieldValues={{
                            "cleaned_service_location_physical_address_street": serviceLocationRecord.cleaned_service_location_physical_address_street || "",
                            "cleaned_service_location_physical_address_unit": serviceLocationRecord.cleaned_service_location_physical_address_unit || "",
                            "cleaned_service_location_physical_address_city": serviceLocationRecord.cleaned_service_location_physical_address_city || "",
                            "cleaned_service_location_physical_address_state": serviceLocationRecord.cleaned_service_location_physical_address_state || "",
                            "cleaned_service_location_physical_address_postal": serviceLocationRecord.cleaned_service_location_physical_address_postal || "",
                            "cleaned_service_location_physical_address_country": serviceLocationRecord.cleaned_service_location_physical_address_country || "",
                        }}
                        onFormDataChange={onFormDataChange}
                        errors={errors}
                    ></AddressAutocompleteFieldGroup>
                    <div className="data-panel__form__field" id="div_id_billing_address" aria-label="Location Billing Address">
                        <label htmlFor="id_client" className="data-panel__form__field__label in-form">Location Billing Address</label>
                        <div className="data-panel__form__field__input data-panel__form__field__input--with-checkbox">
                            <input type="checkbox" className="checkbox-input" name="use_billing_address" id="id_use_billing_address" checked={!this.state.useBillingAddress} onChange={event => this.toggleUseBillingAddress()} />
                            <label className="checkbox-label" htmlFor="id_use_billing_address">Same as client</label>
                        </div>
                        {
                            this.state.useBillingAddress && (
                                <AddressAutocompleteFieldGroup
                                    key="record_cleaned_service_location_billing_address"
                                    fieldGroupName="cleaned_service_location_billing_address"
                                    fieldGroupLabel=""
                                    fieldPrefix="cleaned_service_location_billing_address"
                                    fieldValues={{
                                        "cleaned_service_location_billing_address_recipient": serviceLocationRecord.cleaned_service_location_billing_address_recipient || "",
                                        "cleaned_service_location_billing_address_street": serviceLocationRecord.cleaned_service_location_billing_address_street || "",
                                        "cleaned_service_location_billing_address_unit": serviceLocationRecord.cleaned_service_location_billing_address_unit || "",
                                        "cleaned_service_location_billing_address_city": serviceLocationRecord.cleaned_service_location_billing_address_city || "",
                                        "cleaned_service_location_billing_address_state": serviceLocationRecord.cleaned_service_location_billing_address_state || "",
                                        "cleaned_service_location_billing_address_postal": serviceLocationRecord.cleaned_service_location_billing_address_postal || "",
                                        "cleaned_service_location_billing_address_country": serviceLocationRecord.cleaned_service_location_billing_address_country || "",
                                    }}
                                    onFormDataChange={onFormDataChange}
                                    includeRecipient={true}
                                    errors={errors}
                                ></AddressAutocompleteFieldGroup>
                            )
                        }
                    </div>
                    <TextField
                        fieldName="cleaned_service_location_notes"
                        fieldLabel="Gate Codes, Hazards, etc."
                        fieldValue={serviceLocationRecord.cleaned_service_location_notes || ""}
                        fieldOnChange={cleaned_service_location_notes => onFormDataChange("cleaned_service_location_notes", cleaned_service_location_notes || "")}
                        rows={3}
                        placeholder="Any additional details about this location. Details about a particular job should not be entered here."
                        optional={true}
                        errors={errors}
                    ></TextField>
                    {
                        contacts.length !== 0 && (
                            <ListSelectField
                                fieldName="contacts"
                                fieldLabel="Location Contacts"
                                inputComponent={<RecordContactListEditSelect fieldName="contacts" contacts={contacts} updateContacts={contacts => onFormDataChange("contacts", contacts)} />}
                                showButton={false}
                                errors={errors}
                            ></ListSelectField>
                        )
                    }
                </div>
                {this.renderButtons()}
            </div>
        )
    }
}

export default QuickBooksServiceLocationRecordForm;
