import React, { Fragment } from 'react';
import TabSwitcher from '../../../../core/components/TabSwitcher';
import QuickBooksClientRecordCard from '../QuickBooksClientRecordCard';


class QuickBooksClientRecordTabSwitcher extends TabSwitcher {

    renderCard = (record, index, array) => <QuickBooksClientRecordCard key={this.state.activeTab + "-" + index} record={record} tab={this.state.activeTab} />

    renderCardsForTab = (tab, records) => {
        if (records.length !== 0) {
            return (
                <Fragment>
                    {records.map(this.renderCard)}
                    {this.renderLoadMoreButton("Load More Records")}
                </Fragment>
            )
        }
        else {
            return <span className="card-category-emptytext">No records in this category.</span>
        }
    }

}

export default QuickBooksClientRecordTabSwitcher;
