
function TruncatedTextRenderer(props) {
    const  value  = props.getValue ? props.getValue() : props.value

    return <span className="text-truncated">
        {String(value)}
    </span>
}

export default TruncatedTextRenderer
