import * as PopoverPrimitive from "@radix-ui/react-popover"
import clsx from "clsx"
import { forwardRef } from "react"

import styles from "./DateInputPopover.module.scss"

const DateInputPopover = PopoverPrimitive.Root

const DateInputPopoverTrigger = PopoverPrimitive.Trigger

const DateInputPopoverContent = forwardRef<
    React.ElementRef<typeof PopoverPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>
>(
    (
        {
            className,
            align = "center",
            sideOffset = 4,
            ...props
        }: PopoverPrimitive.PopoverContentProps & React.RefAttributes<HTMLDivElement>,
        ref,
    ) => (
        <PopoverPrimitive.Portal>
            <PopoverPrimitive.Content
                ref={ref}
                align={align}
                sideOffset={sideOffset}
                className={clsx(styles.content, className)}
                {...props}
            />
        </PopoverPrimitive.Portal>
    ),
)

DateInputPopoverContent.displayName = PopoverPrimitive.Content.displayName

export { DateInputPopover, DateInputPopoverContent, DateInputPopoverTrigger }
