import React, { Component, Fragment } from 'react';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';

class LocationSearchInput extends Component {

    constructor(props) {
        super(props)

        this.state = {
            address: props.defaultValue || "",
            geocodedAddress: {},
            reducedAddressComponents: {},
            cleanedAddressComponents: {},
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (state.address !== props.defaultValue) {
          return {address: props.defaultValue}
        }
        return null
      }

    handleChange = address => {
        const event = {
            target: {
                name: this.props.fieldName,
                value: address
            }
        }
        this.setState({ address })
        this.props.onChange(event)
    }

    handleSelect = async address => {
        try {
            const geocodeResponse = await geocodeByAddress(address)

            if (geocodeResponse.length >= 1) {
                const geocodedAddress = geocodeResponse[0]

                // Source https://stackoverflow.com/a/56803604/1834570
                const reducedAddressComponents = geocodedAddress.address_components.reduce(
                    (seed, { short_name, types }) => (types.forEach(t => seed[t] = short_name), seed),
                    {}
                )

                const cleanedAddressComponents = {
                    "street": `${reducedAddressComponents.street_number || ""}${reducedAddressComponents.street_number ? " " : ""}${reducedAddressComponents.route || ""}`,
                    "city": reducedAddressComponents.locality,
                    "state": reducedAddressComponents.administrative_area_level_1,
                    "postal":  reducedAddressComponents.postal_code,
                    "country": reducedAddressComponents.country,
                }

                this.setState({ geocodedAddress, reducedAddressComponents, cleanedAddressComponents, address: cleanedAddressComponents.street })
                this.props.onAutocompleteSelect(cleanedAddressComponents)
            }
        }
        catch (error) {
            console.log("Autocomplete geocode encountered an error: ", error)
        }
    }

    handleError  = (status, clearSuggestions) => {
        console.log("Autocomplete encountered an error: ", status)
        clearSuggestions()
    }

    render() {
        const {fieldName, defaultValue, disabled=false} = this.props

        return (
            <PlacesAutocomplete
                value={this.state.address}
                onChange={this.handleChange}
                onSelect={this.handleSelect}
                onError={this.handleError}
                searchOptions={{types: ["address"]}}
                highlightFirstSuggestion={true}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <Fragment>
                        <input {...getInputProps({id: `id_${fieldName}`, name: fieldName, className: disabled ? "is-disabled" : "", disabled: disabled})} />
                        {
                            (loading || suggestions.length > 0) &&
                            <div className="dropdown-container">
                                {loading && <div className="loading-indicator">Loading Results...</div>}
                                {suggestions.map(suggestion => {
                                    const className = suggestion.active ? "suggestion-item active" : "suggestion-item"

                                    return (
                                        <div {...getSuggestionItemProps(suggestion, {className})} key={suggestion.description}>
                                            <span>{suggestion.description}</span>
                                        </div>
                                    )
                                })}
                                {<div className="service-attribution-logo"><img src={window.GOOGLE_ATTRIBUTION_IMAGE_PATH}></img></div>}
                            </div>
                        }
                    </Fragment>
                )}
            </PlacesAutocomplete>
        )
    }
}

export default LocationSearchInput;
