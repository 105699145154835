import useUser from "@hooks/useUser";
import { useQuery } from '@tanstack/react-query';
import React, { Fragment } from "react";
import WrapperLinkButton from "../../core/buttons/WrapperLinkButton";
import { DashboardCardRowStates } from "./DashboardCard";


async function fetchWorkStatusCounts(endpoint, moduleName) {
    const searchParams = new URLSearchParams()
    searchParams.append("module", moduleName)

    const response = await fetch(endpoint + "?" + searchParams.toString())
    if (!response.ok) {
        throw new Error("There was an error while trying to load work status counts.")
    }
    return await response.json()
}


function WorkStatusCardRow(props) {
    const { rowData } = props

    return (
        <WrapperLinkButton destination={rowData.url}>
            <div className="card__data__row work-status" aria-label="Work Status">
                <div className="card__data__row__content-container">
                    <div className="card__data__row__body">
                        <div className="work-status__primary" arial-label="Work Status Primary Data">
                            <div className="work-status__count" aria-label="Work Status Count">{rowData.count}</div>
                            <div className="work-status__title" aria-label="Work Status Title">{rowData.title}</div>
                        </div>
                        {rowData.supplemental_data && <div className="work-status__supplemental" aria-label="Work Status Supplemental Data">{rowData.supplemental_data}</div>}
                    </div>
                </div>
                <div className="card__data__row__link-signifier" aria-hidden="true">
                    <i className="fa-sharp fa-solid fa-chevron-right" />
                </div>
            </div>
        </WrapperLinkButton>
    )
}


function WorkStatusCard(props) {
    const { user } = useUser()

    const { title, setButtonConfig } = props
    const moduleName = title.toLowerCase().replace(" ", "_")

    const buttonConfigMap = {
        "estimates": {
            text: <Fragment><i className="fa-sharp fa-light fa-plus" aria-hidden="true" />New Estimate</Fragment>,
            title: "Create New Estimate",
            url: DjangoUrls["estimates:estimates-create"](user.service_company.slug),
            predicate: user.user_type_label === "Service Dispatcher",
        },
        "jobs": {
            text: <Fragment><i className="fa-sharp fa-light fa-plus" aria-hidden="true" />New Job</Fragment>,
            title: "Create New Job",
            url: DjangoUrls["jobs:jobs-create"](user.service_company.slug),
            predicate: user.user_type_label === "Service Dispatcher",
        },
        "invoices":  {
            text: <Fragment><i className="fa-sharp fa-light fa-plus" aria-hidden="true" />New Invoice</Fragment>,
            title: "Create New Invoice",
            url: DjangoUrls["invoices:invoices-create"](user.service_company.slug),
            predicate: user.user_type_label === "Service Dispatcher",
        },
        "purchase_orders":  {
            text: <Fragment><i className="fa-sharp fa-light fa-plus" aria-hidden="true" />New Order</Fragment>,
            title: "Create New Purchase Order",
            url: DjangoUrls["purchase-orders:purchase-orders-create"](user.service_company.slug),
            predicate: user.user_type_label === "Service Dispatcher",
        },
    }

    const endpoint = DjangoUrls["dashboard:api-work-status-list"](user.service_company.slug)
    const { data, error, isLoading, isError } = useQuery({
        queryKey: ["work-status", moduleName],
        queryFn: () => fetchWorkStatusCounts(endpoint, moduleName),
        staleTime: 60000,
    })

    React.useEffect(() => {
        setButtonConfig(buttonConfigMap[moduleName])
    }, [])

    return <DashboardCardRowStates
        isLoading={isLoading}
        isError={isError}
        errorMessage={error?.message}
        isEmpty={data?.length === 0}
        emptyIcon="fa-empty-set"
        emptyMessage="There is no status to display."
    >
        {data?.map(rowData => <WorkStatusCardRow rowData={rowData} key={`${moduleName} ${rowData.title}`} />)}
    </DashboardCardRowStates>
}


export default WorkStatusCard
