import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"

import { Icon } from "@atoms"

import styles from "./PopoverButton.module.scss"
import { PopoverButtonProps } from "./PopoverButton.types"

export default function PopoverButton(props: PopoverButtonProps) {
    const { children, onClick, showChevron, isTabbable } = props
    return (
        <button className={styles.base} onClick={onClick} tabIndex={isTabbable ? 0 : -1}>
            <div className={styles.menuItemInnerContainer}>
                <div className={styles.iconAndText}>
                    <Icon
                        icon={icon({
                            name: "plus",
                            style: "light",
                            family: "sharp",
                        })}
                        size={14}
                    />
                    <div className={styles.text}>{children}</div>
                </div>
                {showChevron && (
                    <div className={styles.chevron}>
                        <Icon
                            icon={icon({
                                name: "angle-right",
                                style: "light",
                                family: "sharp",
                            })}
                            size={14}
                        />
                    </div>
                )}
            </div>
        </button>
    )
}
