import { sortEquipmentWarranty } from '../utils/sorters';
import EquipmentWarrantyDisplay from './EquipmentWarrantyDisplay';


export default function EquipmentWarrantyListDisplay(props) {
    const { fieldName, fieldLabel, warranties } = props

    return warranties.length !== 0 && (
        <div className="data-panel__form__field" id={`div_id_${fieldName}`} aria-label={fieldLabel}>
            <span className="data-panel__form__field__label" aria-hidden="true">{fieldLabel}</span>
            <div className="data-panel__form__field__display-list" aria-label={`${fieldLabel} List`}>
                {warranties.sort(sortEquipmentWarranty).map((warranty, index) => <EquipmentWarrantyDisplay key={`${fieldName}_${index}`} warranty={warranty} />)}
            </div>
        </div>
    )
}
