import { ProjectModelConfig } from "@bryntum/schedulerpro"
import { BryntumGridProps, BryntumSchedulerProProps } from "@bryntum/schedulerpro-react"

import { JobEventModel } from "@organisms/ObjectsView/JobTimelineView/JobEventModel"

import {
    renderHeaderCurrentDay,
    renderHeaderMinutes,
    renderVerticalTechnicianBarTechCell,
} from "./JobTimelineViewConfig.utils"

const minimumZoom = 128
const maximumZoom = 240

const GRID_ROW_HEIGHT = 108

export const REGULAR_TIMELINE_ROW_HEIGHT = 90
export const COMPACT_TIMELINE_ROW_HEIGHT = 52
export const BUTTON_DEBOUNCE_DELAY = 250

const zoomSteps = 12

const basePreset = {
    base: "dayAndWeek",
    tickWidth: 60,

    displayDateFormat: "ll HH:mm",
    timeResolution: {
        increment: 15,
        unit: "minute",
    },
    headers: [
        {
            unit: "day",
            align: "start",
            dateFormat: "MMM DD YYYY",
            renderer: renderHeaderCurrentDay,
        },
        {
            unit: "minute",
            align: "start",
            increment: 15, // 15 minutes
            dateFormat: "h a",
            renderer: renderHeaderMinutes,
        },
    ],
}

const zoomLevels = []

for (let i = minimumZoom; i <= maximumZoom; i = i + zoomSteps) {
    zoomLevels.push({
        id: `myPreset${i}`, // Unique id value provided to recognize your view preset. Not required, but having it you can simply set new view preset by id: scheduler.viewPreset = 'myPreset'
        ...basePreset,
        tickWidth: i,
    })
}

export const projectConfig: Partial<ProjectModelConfig> = {
    eventStore: {
        removeUnassignedEvent: true,
        modelClass: JobEventModel,
    },
}

const schedulerConfig: BryntumSchedulerProProps = {
    extraData: {
        startHour: 0,
        endHour: 23,
    },
    forceFit: false,
    enableEventAnimations: true,
    columnLines: false,
    columnLinesFeature: false,
    // enable zooming if receive the zoomLevels array
    presets: [],
    cls: "job-timeline-view jsJobTimelineView",
    // Remove comment for infinite scrolling. source: https://bryntum.com/products/scheduler/examples/infinite-scroll/

    // listeners: {
    //     horizontalScroll() {
    //         // Keep scrollTo date synced with the visible date
    //         console.log("this.widgetMap>>>", this.widgetMap, this.viewportCenterDate)

    //         if (this.widgetMap) {
    //             this.widgetMap.scrollTo.value = DateHelper.floor(this.viewportCenterDate, "1 day")
    //         }
    //     },
    //     buffer: 100,
    // },
    // infiniteScroll: true,
    eventDragCreateFeature: false,
    project: projectConfig,
    emptyText: "",
    // for vertical timeline view
    resourceColumns: {
        columnWidth: 200,
        height: 80,
        headerRenderer: renderVerticalTechnicianBarTechCell,
    },
    rowHeight: 90,
    barMargin: 4,
    createEventOnDblClick: false,
    zoomOnTimeAxisDoubleClick: false,
    eventStyle: "plain",
    taskEditFeature: false,
    showTooltip: false,
    cellTooltipFeature: false,
    eventTooltipFeature: false,
    scheduleTooltipFeature: false,
    eventSegmentsFeature: false,
    dependenciesFeature: false,
    timeAxisHeaderMenuFeature: false,
    monitorResize: false,
    cellMenuFeature: false,
    eventMenuFeature: false,
    headerMenuFeature: false,
    resourceMenuFeature: false,
    scheduleMenuFeature: false,
    scheduleContextFeature: false,
    eventResizeFeature: {
        tip: () => false,
    },
    regionResizeFeature: false,
    resizeToFitIncludesHeader: false,
    columnResizeFeature: false,
    features: {
        timeRanges: {
            currentDateFormat: "h:mm A",
            showCurrentTimeLine: true,
        },
        eventDrag: {
            showTooltip: false,
        },
    },
    onPresetChange: () => {
        // will be fired on zooming
    },
    resourceTimeRangesFeature: true,
    snap: true,
    viewPreset: basePreset,
}

// Custom grid that holds unscheduled jobs
const gridConfig: BryntumGridProps = {
    selectionMode: {
        cell: false,
    },
    stripeFeature: true,

    rowHeight: GRID_ROW_HEIGHT,
    disableGridRowModelWarning: true,
}

export { GRID_ROW_HEIGHT, gridConfig, schedulerConfig }
