import { NavigationLink } from "@atoms/index"

import useAuth from "@pages/Auth/Auth.store"

import { PRIVACY_POLICY_URL, TERMS_OF_SERVICE_URL } from "@constants/urls"

import styles from "./AuthDisclaimer.module.scss"

export default function AuthDisclaimer() {
    const { isOnOTPSlide } = useAuth()

    return isOnOTPSlide ? null : (
        <div className={styles.base}>
            By using Roopairs, you agree to our{" "}
            <NavigationLink openIn="newTab" path={TERMS_OF_SERVICE_URL} isUnderLine={true}>
                {() => "Terms of Service"}
            </NavigationLink>{" "}
            and{" "}
            <NavigationLink openIn="newTab" path={PRIVACY_POLICY_URL} isUnderLine={true}>
                {() => "Privacy Policy"}
            </NavigationLink>
            .
        </div>
    )
}
