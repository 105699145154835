// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Menu-header__KVwJQ{position:sticky;top:var(--size-0);right:var(--size-0);left:var(--size-0);background-color:var(--white);border-bottom:var(--size-0_25) solid var(--gray-a3)}.Menu-headerInner__BXz01{position:relative;display:flex;gap:var(--size-2);align-items:center;justify-content:space-between;padding:var(--size-1) var(--size-2)}.Menu-navigation__bdwnR{display:flex;flex-direction:column;gap:var(--size-0_5);width:var(--size-full);padding:var(--size-2);overflow-y:auto}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "Menu-header__KVwJQ",
	"headerInner": "Menu-headerInner__BXz01",
	"navigation": "Menu-navigation__bdwnR"
};
export default ___CSS_LOADER_EXPORT___;
