import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"

import useUserNavigationUtils from "@hooks/useUserNavigationUtils"

import { OverflowMenu } from "@molecules"

import styles from "./ProfileOverflowMenu.module.scss"
import { ProfileOverflowMenuProps } from "./ProfileOverflowMenu.types"

export default function ProfileOverflowMenu(props: ProfileOverflowMenuProps) {
    const { isOpen, onClose } = props

    const {
        logout,
        openHelpSidebar,
        openWhatsNewChangeLog,
        goToSettings,
        goToContactSupport,
        goToCannyRequestFeature,
    } = useUserNavigationUtils()

    return (
        <OverflowMenu
            onClose={onClose}
            isOpen={isOpen}
            startingPoint="right"
            triggerClassName={styles.overflowMenuTrigger}
            items={[
                {
                    icon: icon({ name: "gear", style: "light", family: "sharp" }),
                    label: "Settings",
                    value: "settings",
                    dividerBottom: true,
                    onClick: goToSettings,
                },
                {
                    icon: icon({ name: "question-circle", style: "light", family: "sharp" }),
                    label: "Help Center",
                    value: "help",
                    onClick: openHelpSidebar,
                },
                {
                    icon: icon({ name: "headset", style: "light", family: "sharp" }),
                    label: "Contact Support",
                    value: "support",
                    onClick: goToContactSupport,
                },
                {
                    icon: icon({ name: "circle-info", style: "light", family: "sharp" }),
                    label: "Request Feature",
                    value: "request-feature",
                    onClick: goToCannyRequestFeature,
                },
                {
                    icon: icon({ name: "bullhorn", style: "light", family: "sharp" }),
                    label: "What's New",
                    value: "whatsnew",
                    onClick: openWhatsNewChangeLog,
                },
                {
                    icon: icon({ name: "arrow-right-from-bracket", style: "light", family: "sharp" }),
                    label: "Log Out",
                    value: "logout",
                    dividerTop: true,
                    isDangerous: true,
                    onClick: () => void logout(),
                },
            ]}
        />
    )
}
