// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NoValueCell-base__uEUU1{display:flex;align-items:center;justify-content:flex-start;width:var(--size-full);padding:var(--size-3_5) var(--size-4);color:var(--gray-9);font:var(--font-global-body-400);font-feature-settings:var(--feature-settings-global-body-400);letter-spacing:var(--letter-spacing-global-body-400)}.NoValueCell-rightAligned__LNMNr{justify-content:flex-end}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "NoValueCell-base__uEUU1",
	"rightAligned": "NoValueCell-rightAligned__LNMNr"
};
export default ___CSS_LOADER_EXPORT___;
