import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"

import useIsDesktop from "@hooks/useIsDesktop"
import useRouter from "@hooks/useRouter"

import { Button } from "@atoms"

import { TopBarCTAButtonProps } from "./TopBarCTAButton.types"

export default function TopBarCTAButton(props: TopBarCTAButtonProps) {
    const { children, destination } = props

    const router = useRouter()

    const isDesktop = useIsDesktop()

    const onClick = () => {
        router.push(destination)
    }

    return (
        <Button
            colorScheme="gray"
            size={isDesktop ? "sm" : "md"}
            variant="solid"
            leftIcon={icon({
                name: "plus",
                style: "solid",
                family: "sharp",
            })}
            onClick={onClick}
            noDebounce={true}
        >
            {children}
        </Button>
    )
}
