import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"

import useLocalStorage from "@hooks/useLocalStorage"
import useRouter from "@hooks/useRouter"
import useSlugExtractor from "@hooks/useSlugExtractor"

import { IconButton, ScrollableContainer } from "@atoms/index"

import mainSideBarStyles from "@organisms/SideBar/MainSideBar/MainSideBar.module.scss"

import SettingsNavigation from "@pages/Settings/SettingsNavigation/SettingsNavigation"

import { HOME_ROUTE } from "@routes/home"

import { SETTINGS_PREVIOUS_PAGE } from "@constants/storage"

import styles from "./SettingsSideBar.module.scss"

function SettingsSideBar() {
    const [previousPage] = useLocalStorage<string>(SETTINGS_PREVIOUS_PAGE, null)

    const router = useRouter()

    const [homeRoute] = useSlugExtractor([HOME_ROUTE])

    const onBackButtonClick = () => {
        router.push(previousPage || homeRoute)
    }

    return (
        <nav className={mainSideBarStyles.base}>
            <div className={styles.backButtonAndTitle}>
                <IconButton
                    colorScheme="gray"
                    size="sm"
                    variant="ghost"
                    isTabbable={true}
                    icon={icon({ name: "angle-left", style: "regular", family: "sharp" })}
                    onClick={onBackButtonClick}
                    aria-label="Back to previous page"
                    noDebounce={true}
                />

                <span className={styles.settingsTitle}>Settings</span>
            </div>
            <ScrollableContainer className={mainSideBarStyles.navigation} scrollClassName={styles.scrolling}>
                <div className={styles.settingsNavigation}>
                    <SettingsNavigation />
                </div>
            </ScrollableContainer>
        </nav>
    )
}

export default SettingsSideBar
