import ProgressBar from "@ramonak/react-progress-bar";
import React from "react";

function Meter(props) {
    const [currentPercentage, setCurrentPercentage] = React.useState(0)
    const { percentage } = props

    let bucket = "base"

    if (percentage < 25) {
        bucket = "25"
    }
    else if (percentage < 50) {
        bucket = "50"
    }
    else if (percentage < 75) {
        bucket = "75"
    }
    else {
        bucket = "100"
    }

    React.useEffect(() => {
        setTimeout(() => setCurrentPercentage(percentage || 0), 300)
    }, [percentage])

    return <ProgressBar
        completed={currentPercentage}
        isLabelVisible={false}
        height={"0.625em"}
        borderRadius={`var(--border-radius-meter)`}
        bgColor={`var(--color-meter-${bucket})`}
        baseBgColor="var(--color-meter-base)"
        transitionDuration="0.75s"
    />
}

export default Meter
