import formatDuration from "@utils/formatDuration";
import { Fragment } from 'react';
import Accordion from '../../core/components/Accordion';
import Banner from '../../core/components/Banner';
import LineItemListDisplayGroup from '../../core/components/LineItemListDisplayGroup';
import BasicDisplayField from '../../core/fields/BasicDisplayField';
import { JobOriginTypes, JobStatuses } from '../../core/utils/enums';
import { currencyFormatterForServiceCompany, formatCurrencyForServiceCompany } from '../../core/utils/utils';
import { calculateEstimateAmounts } from '../../estimates/utils/utils';
import { calculateInvoiceAmounts } from '../../invoices/utils/utils';

class JobSummaryAccordion extends Accordion {

    fetchData = async () => {
        return this.props.job
    }

    getTitle = () => {
        return "Job Summary"
    }

    getExtraBodyClasses = () => {
        return "data-panel"
    }

    renderBody = (data) => {
        const job = data
        const { subtotal } = job.is_job_walk ? calculateEstimateAmounts(job) : calculateInvoiceAmounts(job)
        const formPrefix = job.is_job_walk ? "Estimated " : ""

        if (window.CURRENT_USER?.hide_financial_context === undefined) {
            window.CURRENT_USER.hide_financial_context = true
        }

        return (
            <div className="data-panel__form" aria-label="Job Summary Information">
                {
                    (!this.props.publicMode || (window.CURRENT_USER?.service_company?.slug === job.service_company.slug)) && <Fragment>
                        <BasicDisplayField
                            fieldName="id"
                            fieldLabel="Roopairs Job ID"
                            fieldValue={job.custom_id ? job.custom_id : job.id}
                        ></BasicDisplayField>
                        {
                            job.origin_type !== JobOriginTypes.roopairs && (
                                <BasicDisplayField
                                    fieldName="origin_id"
                                    fieldLabel={`${job.origin_type !== JobOriginTypes.other ? job.origin_type_label : job.origin_type_other_name} ID`}
                                    fieldValue={job.origin_id}
                                ></BasicDisplayField>
                            )
                        }
                        <BasicDisplayField
                            fieldName="service_name"
                            fieldLabel="Service Type"
                            fieldValue={job.service_name}
                        ></BasicDisplayField>
                        <BasicDisplayField
                            fieldName="is_job_walk"
                            fieldLabel="Job Type"
                            fieldValue={job.is_callback ? "Callback" : job.is_job_walk ? "Job Walk" : "Service"}
                        ></BasicDisplayField>
                        <BasicDisplayField
                            fieldName="total_time_logged"
                            fieldLabel="Total Time Logged"
                            fieldValue={formatDuration(job.total_time_logged)}
                        ></BasicDisplayField>
                        {
                            job.estimate_custom_id && (
                                <BasicDisplayField
                                    fieldName="estimate_custom_id"
                                    fieldLabel="Suggested Estimate ID"
                                    fieldValue={job.estimate_custom_id}
                                ></BasicDisplayField>
                            )
                        }
                        {
                            job.invoice_custom_id && (
                                <BasicDisplayField
                                    fieldName="invoice_custom_id"
                                    fieldLabel="Suggested Invoice ID"
                                    fieldValue={job.invoice_custom_id}
                                ></BasicDisplayField>
                            )
                        }
                        <LineItemListDisplayGroup
                            object={job}
                            labelPrefix={formPrefix}
                            formatCurrencyValue={currencyFormatterForServiceCompany(job.service_company)}
                            useTaxes={false}
                            isJobLineItem={true}
                            showPricebookPartDisplay={true}
                        ></LineItemListDisplayGroup>
                        {
                            !window.CURRENT_USER.hide_financial_context && (
                                <BasicDisplayField
                                    fieldName="subtotal"
                                    fieldLabel={`${formPrefix}Subtotal`}
                                    fieldValue={formatCurrencyForServiceCompany(subtotal, job.service_company)}
                                ></BasicDisplayField>
                            )
                        }
                        {
                            window.MARKETPLACE_ENTITY_SLUG === job.service_company.slug &&  job.technician_notes !== "" && (
                                <BasicDisplayField
                                    fieldName="technician_notes"
                                    fieldLabel={<Fragment>Notes from Technician <span className="text-optional">(Only Visible to You)</span></Fragment>}
                                    fieldValue={job.technician_notes}
                                ></BasicDisplayField>
                            )
                        }
                    </Fragment>
                }
                {
                    this.props.publicMode && (window.CURRENT_USER?.service_company?.slug !== job.service_company.slug) && <>
                        <Banner type="info" text="Please note that the following technician summary is not a finalized
                        invoice for the work performed. The finalized invoice will be sent after our billing
                        team reviews the technician's summary and makes any necessary modifications." />
                        <LineItemListDisplayGroup
                            object={job}
                            labelPrefix={formPrefix}
                            formatCurrencyValue={currencyFormatterForServiceCompany(job.service_company)}
                            useTaxes={false}
                            isJobLineItem={true}
                            asServiceSummary={!job.service_company.show_full_technician_summary}
                            showPricebookPartDisplay={true}
                        ></LineItemListDisplayGroup>
                    </>
                }
                {
                    [JobStatuses.completed, JobStatuses.finalized].includes(job.status) && !job.service_company.bypass_job_signature && (
                        <div className="data-panel__form__field" aria-label="Signator">
                            <span className="data-panel__form__field__label" aria-hidden="true">Signature</span>
                            {
                                job.signature_skip_reason === ""
                                ?
                                <Fragment>
                                    <span className="data-panel__form__field__value" aria-label="Signed Off By">Signed off by {job.signed_by}</span>
                                    <img src={job.signature} height="200px" alt="Signature" />
                                </Fragment>
                                :
                                <span className="data-panel__form__field__value" aria-label="Skip Signature Reason">Signature skipped: &ldquo;{job.signature_skip_reason}&rdquo;</span>
                            }
                        </div>
                    )
                }
            </div>
        )
    }
}

export default JobSummaryAccordion;
