// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SearchModal-base___ozVk{position:fixed;left:var(--size-0);z-index:var(--z-index-modal);display:flex;align-items:flex-start;justify-content:center;padding:calc(13vh + .03px) var(--size-4) var(--size-4);pointer-events:none;inset:var(--size-0)}.SearchModal-base___ozVk [cmdk-dialog]{width:var(--size-full);max-width:calc(var(--size-72) + var(--size-72));max-height:100vh;outline:none}.SearchModal-dialog__f1oTn{display:flex;flex-direction:column;width:var(--size-full);height:var(--size-full);max-height:calc(var(--size-52) + var(--size-52));overflow:hidden;background-color:var(--white);border:var(--size-0_25) solid var(--gray-4);border-radius:var(--size-2);box-shadow:var(--box-shadow-lg-down);pointer-events:auto}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "SearchModal-base___ozVk",
	"dialog": "SearchModal-dialog__f1oTn"
};
export default ___CSS_LOADER_EXPORT___;
