import clsx from "clsx"

import FakeCaret from "@atoms/FakeCaret/FakeCaret"

import styles from "./OTPInputSlot.module.scss"
import { OTPInputSlotProps } from "./OTPInputSlot.types"

export default function OTPInputSlot(props: OTPInputSlotProps) {
    const { isInvalid = false, char, hasFakeCaret, isActive, isDisabled = false, size } = props

    const renderContent = () => {
        if (char && isActive) {
            return (
                <>
                    {char}
                    <FakeCaret isFilled={true} />
                </>
            )
        } else if (char) {
            return char
        } else if (hasFakeCaret) {
            return <FakeCaret />
        } else {
            return <div className={styles.placeholder}>-</div>
        }
    }

    return (
        <div
            className={clsx(styles.base, {
                [styles.filled]: char,
                [styles.active]: isActive,
                [styles.invalid]: isInvalid,

                [styles.sizeSm]: size === "sm",
                [styles.sizeMd]: size === "md",

                [styles.disabled]: isDisabled,
            })}
        >
            {renderContent()}
        </div>
    )
}
