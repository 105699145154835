import Spinner from "@legacy/core/components/Spinner";
import { Component } from "react";
import deepcopy from "rfdc";
import ContactForm from "../clients/forms/ContactForm";
import { ShippingCarriers, ShippingMethods } from "../core/utils/enums";
import { historyHasState, sendDataToServer, validateSendContact, valueIsDefined } from "../core/utils/utils";
import PurchaseOrderDetailsCard from "./components/PurchaseOrderDetailsCard";
import PurchaseOrderAcknowledgeForm from "./forms/PurchaseOrderAcknowledgeForm";
import PurchaseOrderCancelForm from "./forms/PurchaseOrderCancelForm";
import PurchaseOrderSendForm from "./forms/PurchaseOrderSendForm";
import PurchaseOrderShipForm from "./forms/PurchaseOrderShipForm";
import PurchaseOrderUpdateShippingForm from "./forms/PurchaseOrderUpdateShippingForm";


const PAGE_MODES = {
    VIEW_PURCHASE_ORDER: "VIEW_PURCHASE_ORDER",
    SEND_PURCHASE_ORDER: "SEND_PURCHASE_ORDER",
    ACKNOWLEDGE_PURCHASE_ORDER: "ACKNOWLEDGE_PURCHASE_ORDER",
    SHIP_PURCHASE_ORDER: "SHIP_PURCHASE_ORDER",
    UPDATE_SHIPPING_PURCHASE_ORDER: "UPDATE_SHIPPING_PURCHASE_ORDER",
    CANCEL_PURCHASE_ORDER: "CANCEL_PURCHASE_ORDER",
    ADD_CONTACT: "ADD_CONTACT",
}

const PAGE_MODE_SUBTITLES = {
    VIEW_PURCHASE_ORDER: "Purchase Order Details",
    SEND_PURCHASE_ORDER: "Send Purchase Order",
    ACKNOWLEDGE_PURCHASE_ORDER: "Acknowledge Purchase Order",
    SHIP_PURCHASE_ORDER: "Mark Purchase Order as Shipped",
    UPDATE_SHIPPING_PURCHASE_ORDER: "Update Shipping Details",
    CANCEL_PURCHASE_ORDER: "Mark Purchase Order as Cancelled",
    ADD_CONTACT: "Add Contact",
}

const PAGE_MODE_BACK_BUTTON_DISPLAY = {
    VIEW_PURCHASE_ORDER: "flex",
    SEND_PURCHASE_ORDER: "none",
    ACKNOWLEDGE_PURCHASE_ORDER: "none",
    SHIP_PURCHASE_ORDER: "none",
    UPDATE_SHIPPING_PURCHASE_ORDER: "none",
    CANCEL_PURCHASE_ORDER: "none",
    ADD_PAYMENT: "none",
    EDIT_PAYMENT: "none",
    PAY_ONLINE: "none",
    ADD_CONTACT: "none",
}

const PRIMARY_PAGE_MODES = [PAGE_MODES.VIEW_PURCHASE_ORDER]
const SECONDARY_PAGE_MODES = [
    PAGE_MODES.SEND_PURCHASE_ORDER,
    PAGE_MODES.ACKNOWLEDGE_PURCHASE_ORDER,
    PAGE_MODES.SHIP_PURCHASE_ORDER,
    PAGE_MODES.UPDATE_SHIPPING_PURCHASE_ORDER,
    PAGE_MODES.CANCEL_PURCHASE_ORDER,
    PAGE_MODES.ADD_CONTACT,
]

const SHIPPING_PAGE_MODES = [
    PAGE_MODES.SHIP_PURCHASE_ORDER,
    PAGE_MODES.UPDATE_SHIPPING_PURCHASE_ORDER,
]

const CONTACT_PAGE_MODES = [
    PAGE_MODES.ADD_CONTACT,
]

const FORM_DATA_NAMES_BY_MODE = {
    VIEW_PURCHASE_ORDER: "purchaseOrderData",
    SEND_PURCHASE_ORDER: "sendData",
    ACKNOWLEDGE_PURCHASE_ORDER: "acknowledgeData",
    SHIP_PURCHASE_ORDER: "shippingData",
    UPDATE_SHIPPING_PURCHASE_ORDER: "shippingData",
    CANCEL_PURCHASE_ORDER: "cancelData",
    ADD_CONTACT: "contactData",
}

const SUBMITTING_NAMES_BY_MODE = {
    VIEW_PURCHASE_ORDER: "submittingPurchaseOrder",
    SEND_PURCHASE_ORDER: "submittingSend",
    ACKNOWLEDGE_PURCHASE_ORDER: "submittingAcknowledge",
    SHIP_PURCHASE_ORDER: "submittingShipping",
    UPDATE_SHIPPING_PURCHASE_ORDER: "submittingShipping",
    CANCEL_PURCHASE_ORDER: "submittingCancel",
    ADD_CONTACT: "submittingContact",
}

const ERROR_NAMES_BY_MODE = {
    VIEW_PURCHASE_ORDER: "purchaseOrder",
    SEND_PURCHASE_ORDER: "send",
    ACKNOWLEDGE_PURCHASE_ORDER: "acknowledge",
    SHIP_PURCHASE_ORDER: "shipping",
    UPDATE_SHIPPING_PURCHASE_ORDER: "shipping",
    CANCEL_PURCHASE_ORDER: "cancel",
    ADD_CONTACT: "contact",
}


class PurchaseOrderDetailsContainer extends Component {

    // Initialize

    constructor(props) {
        super(props)

        const defaultMode = this.props.formMode || PAGE_MODES.VIEW_PURCHASE_ORDER
        this.addToastToQueue = this.props.addToastToQueue

        this.shippingCarrierChoices = window.SHIPPING_CARRIER_CHOICES
        this.shippingMethodChoices = window.SHIPPING_METHOD_CHOICES
        this.defaultVendorMessage = window.CURRENT_USER?.service_company?.purchase_order_default_vendor_message

        this.state = {
            purchaseOrderData: null,
            associatedJobsData: null,

            sendData: {},
            acknowledgeData: {},
            shippingData: {},
            cancelData: {},
            contactData: {},

            markAsDeliveredData: {},
            markAsStockedData: {},
            markAsPaidData: {},

            selectedSendPhoneContacts: [],
            selectedSendEmailContacts: [],
            selectedOnlinePaymentRecipient: null,

            errors: {
                purchaseOrder: {},
                send: {},
                acknowledge: {},
                shipping: {},
                cancel: {},
                contact: {},
            },

            defaultMode: defaultMode,
            mode: defaultMode,

            sendViaSMS: false,
            sendViaEmail: false,
            sendManually: false,

            returnScroll: 0,
        }

        window.onpopstate = (event) => {
            if (event.state !== null && Object.keys(event.state).length) {
                this.setState(event.state)
            }
        }
    }

    componentDidMount = async () => {
        if (this.state.purchaseOrderData === null) {
            let purchaseOrderEndpoint

            if (window.USING_PUBLIC_URL === true) {
                purchaseOrderEndpoint = window.PUBLIC_REST_URL
            }
            else {
                purchaseOrderEndpoint = DjangoUrls["purchase-orders:api-purchase-orders-detail"](window.MARKETPLACE_ENTITY_SLUG, window.PURCHASE_ORDER_ID)
            }

            const purchaseOrderResponse = await fetch(purchaseOrderEndpoint)
            const purchaseOrder = await purchaseOrderResponse.json()

            this.setState((state, props) => {
                let updatedState = state
                updatedState.purchaseOrderData = purchaseOrder

                updatedState.purchaseOrderData.parts = deepcopy()(purchaseOrder.line_items)

                return updatedState
            })

            if (window.USER_TYPE === "SERVICE_DISPATCHER" && purchaseOrder !== null && this.state.associatedJobsData === null && !(window.USING_PUBLIC_URL === true)) {
                let associatedJobs = []

                let associatedJobsEndpoint
                let associatedJobsResponse
                let associatedJobsResponseJSON = {
                    next: `${DjangoUrls["jobs:api-jobs-list"](window.MARKETPLACE_ENTITY_SLUG)}?purchase_order=${purchaseOrder.id}`,
                    previous: null,
                    results: [],
                }

                while (associatedJobsResponseJSON.next !== null) {
                    associatedJobsEndpoint = associatedJobsResponseJSON.next
                    associatedJobsResponse = await fetch(associatedJobsEndpoint)
                    associatedJobsResponseJSON = await associatedJobsResponse.json()
                    associatedJobs.push(...associatedJobsResponseJSON.results)
                }

                this.setState((state, props) => {
                    let updatedState = state
                    updatedState.associatedJobsData = associatedJobs
                    return updatedState
                })
            }
        }

        if (historyHasState(history)) {
            if (document.querySelector(".page-subtitle")) {
                document.querySelector(".page-subtitle").innerHTML = PAGE_MODE_SUBTITLES[history.state.mode]
            }
            if (document.querySelector(".back-button")) {
                document.querySelector(".back-button").style.display = PAGE_MODE_BACK_BUTTON_DISPLAY[history.state.mode]
            }
            this.setState(history.state)
        }
        else {
            // If the client asks for the send purchase order page directly, oblidge
            const desiredMode = new URLSearchParams(document.location.search).get("mode") || ""
            if (desiredMode === "send-purchase-order") {
                this.handleActionRequest("PURCHASE_ORDER_SEND")
            }
            else if (desiredMode === "pay-online") {
                this.handleActionRequest("PURCHASE_ORDER_PAY_ONLINE")
            }
        }
    }

    // Form helpers

    updateFormData = (formName, fieldName, fieldValue) => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState[formName][fieldName] = fieldValue
            return updatedState
        })
    }

    switchFormMode = (mode) => {
        if (document.querySelector(".page-subtitle")) {
            document.querySelector(".page-subtitle").innerHTML = PAGE_MODE_SUBTITLES[mode]
        }
        if (document.querySelector(".back-button")) {
            document.querySelector(".back-button").style.display = PAGE_MODE_BACK_BUTTON_DISPLAY[mode]
        }

        if (SECONDARY_PAGE_MODES.includes(mode)) {
            history.replaceState(this.state, "", "")
        }

        this.setState((state, props) => {
            let updatedState = state
            updatedState.mode = mode
            history.pushState(updatedState, "", "?mode=" + mode.toLowerCase().replace(/_/g, "-"));
            return updatedState
        })
    }

    switchToPrimaryForm = () => {
        this.setState((state, props) => {
            let updatedState = state

            // Clear the secondary form data
            updatedState[FORM_DATA_NAMES_BY_MODE[state.mode]] = {}
            updatedState[SUBMITTING_NAMES_BY_MODE[state.mode]] = false
            updatedState.errors[ERROR_NAMES_BY_MODE[state.mode]] = {}

            return updatedState
        })
        this.switchFormMode(this.state.defaultMode)
    }

    switchToSecondaryForm = (newFormMode, data, initialData) => {
        this.setState((state, props) => {
            let updatedState = state
            // Set the scroll state
            updatedState.returnScroll = document.querySelector(".main").scrollTop

            updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]] = {}

            if (data !== null) {
                updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]] = deepcopy()(data)
            }
            else {
                if (newFormMode === PAGE_MODES.SHIP_PURCHASE_ORDER) {
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].vendor_internal_id = updatedState.purchaseOrderData.vendor_internal_id
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].estimated_arrival_time = updatedState.purchaseOrderData.estimated_arrival_time
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].shipping_carrier = valueIsDefined(updatedState.purchaseOrderData.shipping_carrier) ? updatedState.purchaseOrderData.shipping_carrier : updatedState.purchaseOrderData.preferred_shipping_carrier
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].shipping_carrier_other_name = updatedState.purchaseOrderData.shipping_carrier_other_name || updatedState.purchaseOrderData.preferred_shipping_carrier_other_name
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].shipping_method = valueIsDefined(updatedState.purchaseOrderData.shipping_method) ? updatedState.purchaseOrderData.shipping_method : updatedState.purchaseOrderData.preferred_shipping_method
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].shipping_method_other_name = updatedState.purchaseOrderData.shipping_method_other_name || updatedState.purchaseOrderData.preferred_shipping_method_other_name
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].tracking_number = updatedState.purchaseOrderData.tracking_number

                }
                else if (newFormMode === PAGE_MODES.UPDATE_SHIPPING_PURCHASE_ORDER) {
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].vendor_internal_id = updatedState.purchaseOrderData.vendor_internal_id
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].estimated_arrival_time = updatedState.purchaseOrderData.estimated_arrival_time
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].shipping_carrier = updatedState.purchaseOrderData.shipping_carrier
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].shipping_carrier_other_name = updatedState.purchaseOrderData.shipping_carrier_other_name
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].shipping_method = updatedState.purchaseOrderData.shipping_method
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].shipping_method_other_name = updatedState.purchaseOrderData.shipping_method_other_name
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].tracking_number = updatedState.purchaseOrderData.tracking_number
                }
                else if (newFormMode === PAGE_MODES.ADD_CONTACT) {
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].is_ephemeral = false
                }
                else if (newFormMode === PAGE_MODES.SEND_PURCHASE_ORDER && this.defaultVendorMessage) {
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].vendor_message = this.defaultVendorMessage
                }
            }

            if (initialData !== null) {
                Object.assign(updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]], initialData)
            }

            return updatedState
        })

        this.switchFormMode(newFormMode)
    }

    updateSendPhoneSelection = (selectedContacts) => {
        this.setState((state, props) => {
            let updatedState = state

            updatedState.selectedSendPhoneContacts = selectedContacts
            updatedState.sendData.phone_contacts = selectedContacts

            return updatedState
        })
    }

    updateSendEmailSelection = (selectedContacts) => {
        this.setState((state, props) => {
            let updatedState = state

            updatedState.selectedSendEmailContacts = selectedContacts
            updatedState.sendData.email_contacts = selectedContacts

            return updatedState
        })
    }

    updateOnlinePaymentRecipientSelection = (selectedContact) => {
        this.setState((state, props) => {
            let updatedState = state

            updatedState.selectedOnlinePaymentRecipient = selectedContact

            return updatedState
        })
    }

    // Create PurchaseOrder

    createPurchaseOrder = async () => {
        const dataName = "purchaseOrderData"
        const submittingName = "submittingPurchaseOrder"
        const errorDictName = "purchaseOrder"
        const endpoint = DjangoUrls["purchase-orders:api-purchase-orders-list"](window.MARKETPLACE_ENTITY_SLUG)
        const endpointMethod = "POST"

        const dataManipulator = (data, state) => {
            let preparedData = deepcopy()(data)
            preparedData.is_draft = false

            // Convert service company to slug
            preparedData.service_company = data.service_company.slug

            // Convert vendor to ID
            preparedData.vendor = data.vendor.id

            // Convert inventory location to ID
            preparedData.inventory_location = data.inventory_location.id

            return preparedData
        }

        const onSuccess = (purchaseOrder) => {
            const successUrl = DjangoUrls["purchase-orders:purchase-orders-detail"](window.MARKETPLACE_ENTITY_SLUG, purchaseOrder.id) + "?mode=send-purchase-order"
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Purchase Order "${purchaseOrder.custom_id || purchaseOrder.id}" created`,
                path: successUrl.split("?")[0],
                barePathOnly: true,
                delayRender: true,
            })

            history.replaceState({}, "", "")
            location.assign(successUrl)
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Purchase Order could not be created",
            })
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, dataManipulator, undefined)
    }

    // Send data

    submitSendData = async () => {
        const endpoint = DjangoUrls["purchase-orders:api-purchase-orders-send"](window.MARKETPLACE_ENTITY_SLUG, this.state.purchaseOrderData.id)
        const endpointMethod = "POST"
        const successUrl = DjangoUrls["purchase-orders:purchase-orders-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.purchaseOrderData.id)

        const dataName = "sendData"
        const submittingName = "submittingSend"
        const errorDictName = "send"

        const onSuccess = () => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Purchase Order "${this.state.purchaseOrderData.custom_id || this.state.purchaseOrderData.id}" sent`,
                path: successUrl.split("?")[0],
                barePathOnly: true,
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }

        const dataManipulator = (data, state) => {
            data.send_via_sms = state.sendViaSMS
            data.send_via_email = state.sendViaEmail
            data.send_manually = state.sendManually
            data.phone_contacts = data.phone_contacts || []
            data.email_contacts = data.email_contacts || []

            return data
        }

        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Purchase Order could not be sent",
            })
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, dataManipulator, undefined)
    }

    // Acknowledge data

    submitAcknowledgeData = async () => {
        const endpoint = DjangoUrls["purchase-orders:api-purchase-orders-acknowledge"](window.MARKETPLACE_ENTITY_SLUG, this.state.purchaseOrderData.id)
        const endpointMethod = "POST"
        const successUrl = DjangoUrls["purchase-orders:purchase-orders-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.purchaseOrderData.id)

        this.sendAcknowledgeData(endpoint, endpointMethod, successUrl)
    }

    submitAcknowledgeDataPublic = async () => {
        const endpoint = window.PUBLIC_ACKNOWLEDGE_URL
        const successUrl = window.PUBLIC_URL
        const endpointMethod = "POST"

        this.sendAcknowledgeData(endpoint, endpointMethod, successUrl)
    }

    sendAcknowledgeData = async (endpoint, endpointMethod, successUrl) => {
        const dataName = "acknowledgeData"
        const submittingName = "submittingAcknowledge"
        const errorDictName = "acknowledge"

        const onSuccess = () => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Purchase Order "${this.state.purchaseOrderData.custom_id || this.state.purchaseOrderData.id}" acknowledged`,
                path: successUrl.split("?")[0],
                barePathOnly: true,
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Purchase Order could not be acknowledged",
            })
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, undefined, undefined)
    }

    // Shipping data

    submitShippingData = async () => {
        const endpoint = DjangoUrls["purchase-orders:api-purchase-orders-mark-as-shipped"](window.MARKETPLACE_ENTITY_SLUG, this.state.purchaseOrderData.id)
        const endpointMethod = "POST"
        const successUrl = DjangoUrls["purchase-orders:purchase-orders-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.purchaseOrderData.id)

        this.sendShippingData(endpoint, endpointMethod, successUrl)
    }

    submitShippingDataPublic = async () => {
        const endpoint = window.PUBLIC_SHIP_URL
        const successUrl = window.PUBLIC_URL
        const endpointMethod = "POST"

        this.sendShippingData(endpoint, endpointMethod, successUrl)
    }

    sendShippingData = async (endpoint, endpointMethod, successUrl) => {
        const dataName = "shippingData"
        const submittingName = "submittingShipping"
        const errorDictName = "shipping"

        const onSuccess = () => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Purchase Order "${this.state.purchaseOrderData.custom_id || this.state.purchaseOrderData.id}" marked as shipped`,
                path: successUrl.split("?")[0],
                barePathOnly: true,
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Purchase Order could not be marked as shipped",
            })
        }

        const dataManipulator = (data, state) => {
            data.shipping_carrier = data.shipping_carrier || null
            data.shipping_method = data.shipping_method || null

            if (parseInt(data.shipping_carrier) !== ShippingCarriers.other) {
                data.shipping_carrier_other_name = ""
            }

            if (parseInt(data.shipping_method) !== ShippingMethods.other) {
                data.shipping_method_other_name = ""
            }

            return data
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, dataManipulator, undefined)
    }

    // Cancel data

    submitCancelData = async () => {
        const endpoint = DjangoUrls["purchase-orders:api-purchase-orders-cancel"](window.MARKETPLACE_ENTITY_SLUG, this.state.purchaseOrderData.id)
        const endpointMethod = "POST"
        const successUrl = DjangoUrls["purchase-orders:purchase-orders-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.purchaseOrderData.id)

        const dataName = "cancelData"
        const submittingName = "submittingCancel"
        const errorDictName = "cancel"

        const onSuccess = () => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Purchase Order "${this.state.purchaseOrderData.custom_id || this.state.purchaseOrderData.id}" cancelled`,
                path: successUrl.split("?")[0],
                barePathOnly: true,
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Purchase Order could not be cancelled",
            })
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, undefined, undefined)
    }

    // Crud Contact

    createContact = async () => {
        const populationRef = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]].populationRef

        const contactData = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]]

        const { isValid, errors } = validateSendContact(
            contactData,
            [populationRef === "updateSendPhoneSelection" ? "phone" : "email"]
        )

        if (isValid) {
            const endpoint = DjangoUrls["pricebook:api-vendors-contacts-list"](window.MARKETPLACE_ENTITY_SLUG, this.state.purchaseOrderData.vendor.id)
            const endpointMethod = "POST"

            const onSuccess = (contact) => {
                let destinationMode = null
                let destinationData = null

                // Set the new contact in state and then grab that state to use in a new form
                if (populationRef === "updateSendPhoneSelection") {
                    destinationMode = PAGE_MODES.SEND_PURCHASE_ORDER
                    destinationData = deepcopy()(this.state.sendData)

                    if (valueIsDefined(contact.phone)) {
                        const newContacts = [...this.state.selectedSendPhoneContacts, contact]
                        destinationData.phone_contacts = newContacts
                        this[populationRef](newContacts)
                    }
                }
                else if (populationRef === "updateSendEmailSelection") {
                    destinationMode = PAGE_MODES.SEND_PURCHASE_ORDER
                    destinationData = deepcopy()(this.state.sendData)

                    if (valueIsDefined(contact.email)) {
                        const newContacts = [...this.state.selectedSendEmailContacts, contact]
                        destinationData.email_contacts = newContacts
                        this[populationRef](newContacts)
                    }
                }

                this.switchToPrimaryForm()
                this.switchToSecondaryForm(destinationMode, destinationData, null)
                this.addToastToQueue({
                    type: "success",
                    size: contact.is_ephemeral ? "lg" : "md",
                    title: `Contact ${contact.is_ephemeral ? "added" : "created"}`,
                    subtitle: `This contact will not be saved to the contact list`
                })
            }
            const onError = () => {
                this.addToastToQueue({
                    type: "error",
                    size: "md",
                    title: `Contact could not be ${contactData.is_ephemeral ? "added" : "created"}`,
                })
            }

            this.createUpdateContact(endpoint, endpointMethod, onSuccess, onError)
        }
        else {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: `Contact could not be ${contactData.is_ephemeral ? "added" : "created"}`,
            })
            this.setState((state, props) => {
                let updatedState = state
                updatedState.errors[ERROR_NAMES_BY_MODE[state.mode]] = errors
                return updatedState
            })
        }
    }

    createUpdateContact = async (endpoint, endpointMethod, onSuccess, onError) => {
        const dataName = "contactData"
        const submittingName = "submittingContact"
        const errorDictName = "contact"

        const setErrors = (fieldName, message, errorDict) => {
            if (fieldName === "non_field_errors" && message.endsWith("name, phone, phone_extension, email must make a unique set.")) {
                errorDict["non_field_error"] = "A contact with these details already exists."
            }
            else {
                errorDict[fieldName] = message
            }
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, undefined, setErrors)
    }

    // Mark Purchase Order as Sent

    markPurchaseOrderAsSent = async () => {
        const endpoint = DjangoUrls["purchase-orders:api-purchase-orders-mark-as-sent"](window.MARKETPLACE_ENTITY_SLUG, this.state.purchaseOrderData.id)
        const endpointMethod = "POST"
        const successUrl = DjangoUrls["purchase-orders:purchase-orders-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.purchaseOrderData.id)

        const dataName = "sendData"
        const submittingName = "submittingSend"
        const errorDictName = "send"

        const onSuccess = (json) => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Purchase Order "${this.state.purchaseOrderData.custom_id || this.state.purchaseOrderData.id}" marked as sent`,
                path: successUrl.split("?")[0],
                barePathOnly: true,
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }

        const dataManipulator = (data, state) => {
            data.phone_contacts = data.phone_contacts || []
            data.email_contacts = data.email_contacts || []
            return data
        }

        document.querySelectorAll("#message_modal_mark_as_sent .modal__close .button").forEach(button => button.style.display = "none")
        document.querySelector("#message_modal_mark_as_sent .modal__close .spinner-centered").style.display = "block"

        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Purchase Order could not be marked as sent",
            })
            const markAsInvoicedModal = document.querySelector("#message_modal_mark_as_sent .modal__close")
            if (markAsInvoicedModal) {
                markAsInvoicedModal.innerHTML = '<span class="text-invalid"><strong>An unexpected error occurred.</strong></span>'
            }
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, dataManipulator, undefined)
    }

    // Mark Purchase Order as Delivered

    markPurchaseOrderAsDelivered = async () => {
        const endpoint = DjangoUrls["purchase-orders:api-purchase-orders-mark-as-delivered"](window.MARKETPLACE_ENTITY_SLUG, this.state.purchaseOrderData.id)
        const endpointMethod = "POST"
        const successUrl = DjangoUrls["purchase-orders:purchase-orders-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.purchaseOrderData.id)

        const dataName = "markAsDeliveredData"
        const submittingName = "submittingMarkAsDelivered"
        const errorDictName = "markAsDelivered"

        const onSuccess = (json) => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Purchase Order "${this.state.purchaseOrderData.custom_id || this.state.purchaseOrderData.id}" marked as delivered`,
                path: successUrl.split("?")[0],
                barePathOnly: true,
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }

        document.querySelectorAll("#message_modal_mark_as_delivered .modal__close .button").forEach(button => button.style.display = "none")
        document.querySelector("#message_modal_mark_as_delivered .modal__close .spinner-centered").style.display = "block"

        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Purchase Order could not be marked as delivered",
            })
            const markAsInvoicedModal = document.querySelector("#message_modal_mark_as_delivered .modal__close")
            if (markAsInvoicedModal) {
                markAsInvoicedModal.innerHTML = '<span class="text-invalid"><strong>An unexpected error occurred.</strong></span>'
            }
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, undefined, undefined)
    }

    // Mark Purchase Order as Stocked

    markPurchaseOrderAsStocked = async () => {
        const endpoint = DjangoUrls["purchase-orders:api-purchase-orders-mark-as-stocked"](window.MARKETPLACE_ENTITY_SLUG, this.state.purchaseOrderData.id)
        const endpointMethod = "POST"
        const successUrl = DjangoUrls["purchase-orders:purchase-orders-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.purchaseOrderData.id)

        const dataName = "markAsStockedData"
        const submittingName = "submittingMarkAsStocked"
        const errorDictName = "markAsStocked"

        const onSuccess = (json) => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Purchase Order "${this.state.purchaseOrderData.custom_id || this.state.purchaseOrderData.id}" marked as stocked`,
                path: successUrl.split("?")[0],
                barePathOnly: true,
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }

        document.querySelectorAll("#message_modal_mark_as_stocked .modal__close .button").forEach(button => button.style.display = "none")
        document.querySelector("#message_modal_mark_as_stocked .modal__close .spinner-centered").style.display = "block"

        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Purchase Order could not be marked as stocked",
            })
            const markAsInvoicedModal = document.querySelector("#message_modal_mark_as_stocked .modal__close")
            if (markAsInvoicedModal) {
                markAsInvoicedModal.innerHTML = '<span class="text-invalid"><strong>An unexpected error occurred.</strong></span>'
            }
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, undefined, undefined)
    }

    // Mark Purchase Order as Paid

    markPurchaseOrderAsPaid = async () => {
        const endpoint = DjangoUrls["purchase-orders:api-purchase-orders-mark-as-paid"](window.MARKETPLACE_ENTITY_SLUG, this.state.purchaseOrderData.id)
        const endpointMethod = "POST"
        const successUrl = DjangoUrls["purchase-orders:purchase-orders-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.purchaseOrderData.id)

        const dataName = "markAsPaidData"
        const submittingName = "submittingMarkAsPaid"
        const errorDictName = "markAsPaid"

        const onSuccess = () => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Purchase Order "${this.state.purchaseOrderData.custom_id || this.state.purchaseOrderData.id}" marked as paid`,
                path: successUrl.split("?")[0],
                barePathOnly: true,
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }

        document.querySelectorAll("#message_modal_mark_as_paid .modal__close .button").forEach(button => button.style.display = "none")
        document.querySelector("#message_modal_mark_as_paid .modal__close .spinner-centered").style.display = "block"

        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Purchase Order could not be marked as paid",
            })
            const markAsInvoicedModal = document.querySelector("#message_modal_mark_as_paid .modal__close")
            if (markAsInvoicedModal) {
                markAsInvoicedModal.innerHTML = '<span class="text-invalid"><strong>An unexpected error occurred.</strong></span>'
            }
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, undefined, undefined)
    }

    // Handle Actions

    handleActionRequest = (action) => {
        switch (action) {
            case "PURCHASE_ORDER_CREATE":
                this.createPurchaseOrder()
                break
            case "PURCHASE_ORDER_EDIT":
                location.assign(DjangoUrls["purchase-orders:purchase-orders-update"](window.MARKETPLACE_ENTITY_SLUG, this.state.purchaseOrderData.id))
                break
            case "PURCHASE_ORDER_SEND":
                this.switchToSecondaryForm(PAGE_MODES.SEND_PURCHASE_ORDER, null, null)
                break
            case "TOGGLE_SEND_VIA_SMS":
                this.setState((state, props) => {
                    let updatedState = state
                    updatedState.sendViaSMS = !state.sendViaSMS
                    return updatedState
                })
                break
            case "TOGGLE_SEND_VIA_EMAIL":
                this.setState((state, props) => {
                    let updatedState = state
                    updatedState.sendViaEmail = !state.sendViaEmail
                    return updatedState
                })
                break
            case "TOGGLE_SEND_MANUALLY":
                this.setState((state, props) => {
                    let updatedState = state
                    updatedState.sendManually = !state.sendManually
                    return updatedState
                })
                break
            case "PURCHASE_ORDER_SEND_SUBMIT":
                if (!(this.state.sendViaSMS || this.state.sendViaEmail || this.state.sendManually)) {
                    // Check that at least one method is selected
                    this.setState((state, props) => {
                        let updatedState = state
                        updatedState.errors.send.deliveryMethod = "Please select at least one delivery method."
                        return updatedState
                    })
                }
                else {
                    if (this.state.purchaseOrderData.status_label === "Sent" || this.state.sendViaSMS || this.state.sendViaEmail) {
                        this.submitSendData()
                    }
                    else {
                        // Mark as sent if it's not yet sent or if they choose not to send it via SMS or Email
                        window.markPurchaseOrderAsSent = this.markPurchaseOrderAsSent
                        document.querySelector("#message_modal_mark_as_sent").style.display=""
                        window.MicroModal.show("message_modal_mark_as_sent")
                    }
                }
                break
            case "PURCHASE_ORDER_ACKNOWLEDGE":
                this.switchToSecondaryForm(PAGE_MODES.ACKNOWLEDGE_PURCHASE_ORDER, null, null)
                break
            case "PURCHASE_ORDER_ACKNOWLEDGE_SUBMIT":
                if (window.USING_PUBLIC_URL) {
                    this.submitAcknowledgeDataPublic()
                }
                else {
                    this.submitAcknowledgeData()
                }
                break
            case "PURCHASE_ORDER_SHIP":
                this.switchToSecondaryForm(PAGE_MODES.SHIP_PURCHASE_ORDER, null, null)
                break
            case "PURCHASE_ORDER_SHIP_SUBMIT":
                if (window.USING_PUBLIC_URL) {
                    this.submitShippingDataPublic()
                }
                else {
                    this.submitShippingData()
                }
                break
            case "PURCHASE_ORDER_UPDATE_SHIPPING":
                this.switchToSecondaryForm(PAGE_MODES.UPDATE_SHIPPING_PURCHASE_ORDER, null, null)
                break
            case "PURCHASE_ORDER_UPDATE_SHIPPING_SUBMIT":
                if (window.USING_PUBLIC_URL) {
                    this.submitShippingDataPublic()
                }
                else {
                    this.submitShippingData()
                }
                break
            case "PURCHASE_ORDER_MARK_AS_DELIVERED":
                window.markPurchaseOrderAsDelivered = this.markPurchaseOrderAsDelivered
                document.querySelector("#message_modal_mark_as_delivered").style.display=""
                window.MicroModal.show("message_modal_mark_as_delivered")
                break
            case "PURCHASE_ORDER_MARK_AS_STOCKED":
                window.markPurchaseOrderAsStocked = this.markPurchaseOrderAsStocked
                document.querySelector("#message_modal_mark_as_stocked").style.display=""
                window.MicroModal.show("message_modal_mark_as_stocked")
                break
            case "PURCHASE_ORDER_MARK_AS_PAID":
                window.markPurchaseOrderAsPaid = this.markPurchaseOrderAsPaid
                document.querySelector("#message_modal_mark_as_paid").style.display=""
                window.MicroModal.show("message_modal_mark_as_paid")
                break
            case "PURCHASE_ORDER_CANCEL":
                this.switchToSecondaryForm(PAGE_MODES.CANCEL_PURCHASE_ORDER, null, null)
                break
            case "PURCHASE_ORDER_CANCEL_SUBMIT":
                this.submitCancelData()
                break
            case "PURCHASE_ORDER_DOWNLOAD_PDF":
                location.assign(window.PUBLIC_PDF_URL)
                break
            case "CONTACT_CREATE":
                this.createContact()
                break
            case "CONTACT_CANCEL_CREATE":
                const populationRef = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]].populationRef

                let destinationMode = null
                let destinationData = null

                if (populationRef === "updateSendPhoneSelection") {
                    destinationMode = PAGE_MODES.SEND_PURCHASE_ORDER
                    destinationData = deepcopy()(this.state.sendData)
                }
                else if (populationRef === "updateSendEmailSelection") {
                    destinationMode = PAGE_MODES.SEND_PURCHASE_ORDER
                    destinationData = deepcopy()(this.state.sendData)
                }
                else if (populationRef === "updateOnlinePaymentRecipientSelection") {
                    destinationMode = PAGE_MODES.ADD_PAYMENT
                    destinationData = deepcopy()(this.state.paymentData)
                }

                this.switchToPrimaryForm()
                this.switchToSecondaryForm(destinationMode, destinationData, null)
                break
            default:
                console.error(`No action handler exists for action "${action}".`)
        }
    }

    // Render

    render() {
        if (this.state.purchaseOrderData === null) {
            return <Spinner centered={true} />
        }
        else {
            let carrierAccountID = null

            if (["Pending", "Open"].includes(this.state.purchaseOrderData.state_label)) {
                const selectedPreferredCarrierChoice = this.shippingCarrierChoices.find(choice => choice.value === parseInt(this.state.purchaseOrderData.preferred_shipping_carrier))
                carrierAccountID = selectedPreferredCarrierChoice && valueIsDefined(selectedPreferredCarrierChoice.account_id) ? selectedPreferredCarrierChoice.account_id : null
            }
            else {
                const selectedCarrierChoice = this.shippingCarrierChoices.find(choice => choice.value === parseInt(this.state.purchaseOrderData.shipping_carrier))
                carrierAccountID = selectedCarrierChoice && valueIsDefined(selectedCarrierChoice.account_id) ? selectedCarrierChoice.account_id : null
            }

            if (PRIMARY_PAGE_MODES.includes(this.state.mode)) {
                return <PurchaseOrderDetailsCard
                    purchaseOrder={this.state.purchaseOrderData}
                    associatedJobs={this.state.associatedJobsData || []}
                    requestAction={this.handleActionRequest}
                    switchToSecondaryForm={this.switchToSecondaryForm}
                    returnScroll={this.state.returnScroll}
                    submitting={this.state.submittingPurchaseOrder}
                    errors={this.state.errors.purchaseOrder}
                    carrierAccountID={carrierAccountID}
                ></PurchaseOrderDetailsCard>
            }
            else if (this.state.mode === PAGE_MODES.SEND_PURCHASE_ORDER) {
                return <PurchaseOrderSendForm
                    purchaseOrder={this.state.purchaseOrderData}
                    sendData={this.state.sendData}
                    requestAction={this.handleActionRequest}
                    switchToPrimaryForm={this.switchToPrimaryForm}
                    switchToSecondaryForm={this.switchToSecondaryForm}
                    submitting={this.state.submittingSend}
                    errors={this.state.errors.send}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("sendData", fieldName, fieldValue)}
                    updateSendPhoneSelection={this.updateSendPhoneSelection}
                    updateSendEmailSelection={this.updateSendEmailSelection}
                    selectedSendPhoneContacts={this.state.selectedSendPhoneContacts}
                    selectedSendEmailContacts={this.state.selectedSendEmailContacts}
                    sendViaSMS={this.state.sendViaSMS}
                    sendViaEmail={this.state.sendViaEmail}
                    sendManually={this.state.sendManually}
                    returnScroll={0}
                ></PurchaseOrderSendForm>
            }
            else if (this.state.mode === PAGE_MODES.ACKNOWLEDGE_PURCHASE_ORDER) {
                return <PurchaseOrderAcknowledgeForm
                    purchaseOrder={this.state.purchaseOrderData}
                    acknowledgeData={this.state.acknowledgeData}
                    requestAction={this.handleActionRequest}
                    switchToPrimaryForm={this.switchToPrimaryForm}
                    submitting={this.state.submittingAcknowledge}
                    errors={this.state.errors.acknowledge}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("acknowledgeData", fieldName, fieldValue)}
                    carrierAccountID={carrierAccountID}
                    returnScroll={0}
                ></PurchaseOrderAcknowledgeForm>
            }
            else if (this.state.mode === PAGE_MODES.SHIP_PURCHASE_ORDER) {
                return <PurchaseOrderShipForm
                    purchaseOrder={this.state.purchaseOrderData}
                    shippingData={this.state.shippingData}
                    requestAction={this.handleActionRequest}
                    switchToPrimaryForm={this.switchToPrimaryForm}
                    submitting={this.state.submittingShipping}
                    errors={this.state.errors.shipping}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("shippingData", fieldName, fieldValue)}
                    shippingCarrierChoices={this.shippingCarrierChoices}
                    shippingMethodChoices={this.shippingMethodChoices}
                    returnScroll={0}
                ></PurchaseOrderShipForm>
            }
            else if (this.state.mode === PAGE_MODES.UPDATE_SHIPPING_PURCHASE_ORDER) {
                return <PurchaseOrderUpdateShippingForm
                    purchaseOrder={this.state.purchaseOrderData}
                    shippingData={this.state.shippingData}
                    requestAction={this.handleActionRequest}
                    switchToPrimaryForm={this.switchToPrimaryForm}
                    submitting={this.state.submittingShipping}
                    errors={this.state.errors.shipping}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("shippingData", fieldName, fieldValue)}
                    shippingCarrierChoices={this.shippingCarrierChoices}
                    shippingMethodChoices={this.shippingMethodChoices}
                    returnScroll={0}
                ></PurchaseOrderUpdateShippingForm>
            }
            else if (this.state.mode === PAGE_MODES.CANCEL_PURCHASE_ORDER) {
                return <PurchaseOrderCancelForm
                    purchaseOrder={this.state.purchaseOrderData}
                    cancelData={this.state.cancelData}
                    requestAction={this.handleActionRequest}
                    switchToPrimaryForm={this.switchToPrimaryForm}
                    submitting={this.state.submittingCancel}
                    errors={this.state.errors.cancel}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("cancelData", fieldName, fieldValue)}
                    returnScroll={0}
                ></PurchaseOrderCancelForm>
            }
            else if (CONTACT_PAGE_MODES.includes(this.state.mode)) {
                return <ContactForm
                    mode={this.state.mode}
                    submitting={this.state.submittingContact}
                    contact={this.state.contactData}
                    errors={this.state.errors.contact}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("contactData", fieldName, fieldValue)}
                    requestAction={this.handleActionRequest}
                    switchToPrimaryForm={this.switchToPrimaryForm}
                    attachToVendor={true}
                    returnScroll={0}
                ></ContactForm>
            }
            else {
                return (
                    <div className="data-panel-container data-panel-container--with-margin">
                        <div className="data-panel" aria-label="Unknown Form Mode">
                            <div className="data-panel__form">
                                <p className="data-panel__form__caption">An unhandled form mode was supplied.</p>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    }
}

export default PurchaseOrderDetailsContainer;
