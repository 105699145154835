import { Breadcrumb } from "@organisms/index"

import styles from "./PageBreadcrumbs.module.scss"
import { PageBreadcrumbsProps } from "./PageBreadcrumbs.types"

export default function PageBreadcrumbs(props: PageBreadcrumbsProps) {
    const { children } = props

    return (
        <div className={styles.base}>
            <Breadcrumb.Root>{children}</Breadcrumb.Root>
        </div>
    )
}
