import { Component } from "react";
import deepcopy from "rfdc";
import ButtonGroup from "../../../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../../../core/buttons/UniversalButton";
import { format_phone_number_with_extension } from "../../../../core/utils/utils";


class QuickBooksContactRecordDisplay extends Component {

    render() {
        const { contact, requestAction} = this.props
        let errors = deepcopy()(contact.errors || {})

        const nameValue = errors.name ? contact.name : (contact.cleaned_name ? contact.cleaned_name : "--")
        const phoneValue = errors.phone ? (contact.cleaned_phone ? `${contact.cleaned_phone}${contact.cleaned_phone_extension ? " x" : ""}${contact.cleaned_phone_extension}` : contact.phone) : (contact.cleaned_phone ? format_phone_number_with_extension(contact.cleaned_phone, contact.cleaned_phone_extension) : "--")
        const emailValue = errors.email ? (contact.cleaned_email ? contact.cleaned_email : contact.email) : (contact.cleaned_email ? contact.cleaned_email : "--")

        const contactString = `${nameValue !== "--" ? nameValue : "(No Name)"}: ${phoneValue}, ${emailValue}`

        return (
            // React can't handle nested buttons, so this is an exception case where we're not using a wrapper component
            <a className="button-wrapper button button--object-link data-panel__list-select__option" onClick={event => requestAction("RECORD_EDIT_CONTACT")} title={contactString} aria-label="Edit Contact Record" tabIndex="0">
                <div className="data-panel__list-select__option__label record-label">
                    {
                        contact.primary && (
                            <div className="record-label__row record-label-primary">
                                <div className="record-label__field" aria-label="Primary">
                                    <div className="record-label__field-value"><span className="data-tag" style={{margin: "0", width: "100%"}}>Primary Contact</span></div>
                                </div>
                            </div>
                        )
                    }
                    {errors.non_field_error && <div className="data-panel__form__field__errors" aria-label="Non Field Error">{errors.non_field_error}<br></br></div>}
                    <div className="record-label__row">
                        <div className="record-label__field" aria-label="Name">
                            <div className={"record-label__field-label" + (errors.name ? " invalid" : "")} aria-hidden="true">Name</div>
                            <div className="record-label__field-value">{nameValue}</div>
                            {errors.name && <div className="data-panel__form__field__errors" aria-label="Field Errors">{errors.name}</div>}
                        </div>
                    </div>
                    <div className="record-label__row">
                        <div className="record-label__field" aria-label="Phone">
                            <div className={"record-label__field-label" + (errors.phone ? " invalid" : "")} aria-hidden="true">Phone</div>
                            <div className="record-label__field-value">{phoneValue}</div>
                            {errors.phone && <div className="data-panel__form__field__errors" aria-label="Field Errors">{errors.phone}</div>}
                        </div>
                    </div>
                    <div className="record-label__row">
                        <div className="record-label__field" aria-label="Email">
                            <div className={"record-label__field-label" + (errors.email ? " invalid" : "")} aria-hidden="true">Email</div>
                            <div className="record-label__field-value">{emailValue}</div>
                            {errors.email && <div className="data-panel__form__field__errors" aria-label="Field Errors">{errors.email}</div>}
                        </div>
                    </div>
                    <ButtonGroup>
                        <ButtonGroupRow>
                            <UniversalButton type="secondary" text="Ignore Contact" handler={event => requestAction("RECORD_IGNORE_CONTACT")} />
                        </ButtonGroupRow>
                    </ButtonGroup>
                </div>
                <div className="data-panel__list-select__option__icon"><i className="fa-sharp fa-angle-right" aria-hidden="true"></i></div>
            </a>
        )
    }
}

export default QuickBooksContactRecordDisplay;
