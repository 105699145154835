import useIsDesktop from "@hooks/useIsDesktop"

import { Divider } from "@atoms"

import styles from "./TopBarHorizontalDivider.module.scss"

export default function TopBarHorizontalDivider() {
    const isDesktop = useIsDesktop()

    if (isDesktop) {
        return (
            <div className={styles.base}>
                <Divider contrast="low" />
            </div>
        )
    } else {
        return null
    }
}
