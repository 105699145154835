import React from 'react';
import ObjectListCard from '../../core/components/ObjectListCard';
import AlertingRenderer from '../../core/renderers/AlertingRenderer';
import CurrencyRenderer from '../../core/renderers/CurrencyRenderer';
import { valueIsDefined } from "../../core/utils/utils";


function ClientListCard(props) {
    const { data } = props
    const client = data

    const footerContext = {
        label: null,
        value: null
    }

    if (parseInt(client.outstanding_balance)) {
        footerContext.label = "Balance Due:"
        footerContext.value = (
            <AlertingRenderer alertCondition={valueIsDefined(client.credit_limit) && parseFloat(client.outstanding_balance) > parseFloat(client.credit_limit)}>
                <CurrencyRenderer value={client.outstanding_balance} />
            </AlertingRenderer>
        )
    }

    return <ObjectListCard
        objectName="Client"
        objectID={null}
        showState={false}
        stateLabel=""
        headerContext={null}
        statusChip={null}
        bodyTitle={client.name}
        bodySubtextPrimary={client.service_locations_display}
        bodySubtextSecondary={null}
        footerContextLabel={footerContext.label}
        footerContextValue={footerContext.value}
        footerPrimary={null}
    />
}

export default ClientListCard;
