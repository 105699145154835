import * as DialogPrimitive from "@radix-ui/react-dialog"
import clsx from "clsx"
import { forwardRef } from "react"

import { ModalBodyProps } from "./ModalBody.types"

function ModalBody(props: ModalBodyProps, ref: React.Ref<HTMLDivElement>) {
    const { children, className } = props

    return (
        <DialogPrimitive.Description ref={ref} className={clsx(className)} {...props} asChild={true}>
            {children}
        </DialogPrimitive.Description>
    )
}

export default forwardRef(ModalBody)
