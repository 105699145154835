import { renderServiceLocationString } from "@legacy/clients/utils/utils";
import Spinner from "@legacy/core/components/Spinner";
import { Component } from "react";
import deepcopy from "rfdc";
import { ClientTypes, PriceBookItemTypes } from "../core/utils/enums";
import { getCurrencySymbol, historyHasState, sendDataToServer } from "../core/utils/utils";
import PriceBookItemForm from "../pricebook/forms/PriceBookItemForm";
import { getPricebookTaxById } from "../pricebook/utils/utils";
import ClientForm from "./forms/ClientForm";
import ServiceLocationForm from "./forms/ServiceLocationForm";

const FORM_MODES = {
    ADD_CLIENT: "ADD_CLIENT",
    EDIT_CLIENT: "EDIT_CLIENT",
    ADD_SERVICE_LOCATION: "ADD_SERVICE_LOCATION",
    EDIT_SERVICE_LOCATION: "EDIT_SERVICE_LOCATION",
    ADD_PRICEBOOKITEM_TAX: "ADD_PRICEBOOKITEM_TAX",
    EDIT_PRICEBOOKITEM_TAX: "EDIT_PRICEBOOKITEM_TAX",
}

const FORM_MODE_SUBTITLES = {
    ADD_CLIENT: "New Client",
    EDIT_CLIENT: "Edit Client",
    ADD_SERVICE_LOCATION: "Add Service Location",
    EDIT_SERVICE_LOCATION: "Edit Service Location",
    ADD_PRICEBOOKITEM_TAX: "Add PriceBook Tax",
    EDIT_PRICEBOOKITEM_TAX: "Edit PriceBook Tax",
}

const FORM_MODE_BACK_BUTTON_DISPLAY = {
    ADD_CLIENT: "flex",
    EDIT_CLIENT: "flex",
    ADD_SERVICE_LOCATION: "none",
    EDIT_SERVICE_LOCATION: "none",
    ADD_PRICEBOOKITEM_TAX: "none",
    EDIT_PRICEBOOKITEM_TAX: "none",
}

const PRIMARY_FORM_MODES = [FORM_MODES.ADD_CLIENT, FORM_MODES.EDIT_CLIENT]
const SECONDARY_FORM_MODES = [
    FORM_MODES.ADD_SERVICE_LOCATION,
    FORM_MODES.EDIT_SERVICE_LOCATION,
    FORM_MODES.ADD_PRICEBOOKITEM_TAX,
    FORM_MODES.EDIT_PRICEBOOKITEM_TAX,
]
const SERVICE_LOCATION_FORM_MODES = [
    FORM_MODES.ADD_SERVICE_LOCATION,
    FORM_MODES.EDIT_SERVICE_LOCATION,
]

const PRICEBOOK_ITEM_FORM_MODES = [
    FORM_MODES.ADD_PRICEBOOKITEM_TAX,
    FORM_MODES.EDIT_PRICEBOOKITEM_TAX,
]

const FORM_DATA_NAMES_BY_MODE = {
    ADD_CLIENT: "clientData",
    EDIT_CLIENT: "clientData",
    ADD_SERVICE_LOCATION: "serviceLocationData",
    EDIT_SERVICE_LOCATION: "serviceLocationData",
    ADD_PRICEBOOKITEM_TAX: "priceBookItemData",
    EDIT_PRICEBOOKITEM_TAX: "priceBookItemData",
}

const SUBMITTING_NAMES_BY_MODE = {
    ADD_CLIENT: "submittingClient",
    EDIT_CLIENT: "submittingClient",
    ADD_SERVICE_LOCATION: "submittingServiceLocation",
    EDIT_SERVICE_LOCATION: "submittingServiceLocation",
    ADD_PRICEBOOKITEM_TAX: "submittingPriceBookItem",
    EDIT_PRICEBOOKITEM_TAX: "submittingPriceBookItem",
}

const ERROR_NAMES_BY_MODE = {
    ADD_CLIENT: "client",
    EDIT_CLIENT: "client",
    ADD_SERVICE_LOCATION: "serviceLocation",
    EDIT_SERVICE_LOCATION: "serviceLocation",
    ADD_PRICEBOOKITEM_TAX: "priceBookItem",
    EDIT_PRICEBOOKITEM_TAX: "priceBookItem",
}


class ClientCreateContainer extends Component {

    // Initialize

    constructor(props) {
        super(props)

        const defaultMode = this.props.formMode || FORM_MODES.ADD_CLIENT
        this.addToastToQueue = this.props.addToastToQueue

        this.priceBookTaxes = window.PRICEBOOK_TAXES || []

        this.state = {
            clientData: null,
            serviceLocationData: {},
            priceBookItemData: {},

            errors: {
                client: {},
                serviceLocation: {},
                priceBookItem: {},
            },

            defaultMode: defaultMode,
            mode: defaultMode,

            phoneNumberCountry: window.PHONE_NUMBER_COUNTRY,
            defaultClientType: window.DEFAULT_CLIENT_TYPE,

            currencyCode: window.CURRENCY_CODE,
            languageCode: window.LANGUAGE_CODE,
            useTaxes: window.USE_TAXES,

            pricebook_default_taxable_service: window.PRICEBOOK_DEFAULT_TAXABLE_SERVICE,
            pricebook_default_taxable_part: window.PRICEBOOK_DEFAULT_TAXABLE_PART,
            pricebook_default_taxable_other: window.PRICEBOOK_DEFAULT_TAXABLE_OTHER,

            showQuickBooksRevenueAccountSelect: window.ACCOUNTING_INTEGRATION === 2 && window.QUICKBOOKS_LINE_ITEM_SCHEME === 2,
            showQuickBooksTaxAgencyVendorSelect: window.ACCOUNTING_INTEGRATION === 2,
            showTaxCreateButton: window.ACCOUNTING_INTEGRATION === 0,

            selectedPriceBookTax: null,

            returnScroll: 0,
        }

        window.onpopstate = (event) => {
            if (event.state !== null && Object.keys(event.state).length) {
                this.setState(event.state)
            }
        }
    }

    componentDidMount = async () => {
        if (this.state.clientData === null) {
            if (this.state.defaultMode === FORM_MODES.EDIT_CLIENT && window.CLIENT_ID) {
                const endpoint = DjangoUrls["clients:api-clients-detail"](window.MARKETPLACE_ENTITY_SLUG, window.CLIENT_ID)
                const response = await fetch(endpoint)

                let client

                if (response.ok) {
                    client = await response.json()

                    this.setState((state, props) => {
                        let updatedState = state
                        updatedState.clientData = client
                        updatedState.selectedPriceBookTax = getPricebookTaxById(client.default_pricebook_tax, this.priceBookTaxes)

                        return updatedState
                    })
                }
            }
            else if (this.state.defaultMode === FORM_MODES.ADD_CLIENT) {
                this.setState((state, props) => {
                    let updatedState = state
                    updatedState.clientData = {
                        client_type: window.DEFAULT_CLIENT_TYPE || ClientTypes.business,
                    }
                    return updatedState
                })
            }
        }

        if (historyHasState(history)) {
            document.querySelector(".page-subtitle").innerHTML = FORM_MODE_SUBTITLES[history.state.mode]
            document.querySelector(".back-button").style.display = FORM_MODE_BACK_BUTTON_DISPLAY[history.state.mode]
            this.setState(history.state)
        }
    }

    // Form helpers

    updateFormData = (formName, fieldName, fieldValue) => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState[formName][fieldName] = fieldValue
            return updatedState
        })
    }

    switchFormMode = (mode) => {
        document.querySelector(".page-subtitle").innerHTML = FORM_MODE_SUBTITLES[mode]
        document.querySelector(".back-button").style.display = FORM_MODE_BACK_BUTTON_DISPLAY[mode]

        if (SECONDARY_FORM_MODES.includes(mode)) {
            history.replaceState(this.state, "", "")
        }

        this.setState((state, props) => {
            let updatedState = state
            updatedState.mode = mode
            history.pushState(updatedState, "", "?mode=" + mode.toLowerCase().replace(/_/g, "-"));
            return updatedState
        })
    }

    switchToPrimaryForm = () => {
        this.setState((state, props) => {
            let updatedState = state

            // Clear the secondary form data
            updatedState[FORM_DATA_NAMES_BY_MODE[state.mode]] = {}
            updatedState[SUBMITTING_NAMES_BY_MODE[state.mode]] = false
            updatedState.errors[ERROR_NAMES_BY_MODE[state.mode]] = {}

            return updatedState
        })
        this.switchFormMode(this.state.defaultMode)
    }

    switchToSecondaryForm = (newFormMode, data, initialData) => {
        this.setState((state, props) => {
            let updatedState = state
            // Set the scroll state
            updatedState.returnScroll = document.querySelector(".main").scrollTop

            updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]] = {}

            if (data !== null) {
                updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]] = deepcopy()(data)

                if (newFormMode === FORM_MODES.EDIT_PRICEBOOKITEM_TAX) {
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].returnMode = state.mode
                }
            }
            else if (newFormMode === FORM_MODES.ADD_PRICEBOOKITEM_TAX) {
                // Set the type to `tax` on the new PriceBook Item.
                updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].pricebook_item_type = PriceBookItemTypes.tax
                updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].is_active = true
                updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].returnMode = state.mode
            }
            else {
                updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].external_client = state.clientData.id  // Set the client id on the new service location
            }

            if (initialData !== null) {
                Object.assign(updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]], initialData)
            }

            return updatedState
        })

        this.switchFormMode(newFormMode)
    }

    updatePriceBookTaxSelection = (selectedPriceBookTax, then=null) => {
        this.setState((state, props) => {
            let updatedState = state

            // If no return mode is set (i.e. we're not on the pricebook item form, use the current mode)
            const parentMode = state[FORM_DATA_NAMES_BY_MODE[state.mode]].returnMode || state.mode

            if (PRIMARY_FORM_MODES.includes(parentMode)) {
                updatedState.selectedPriceBookTax = selectedPriceBookTax
            } else {
                updatedState[FORM_DATA_NAMES_BY_MODE[parentMode]].selectedPriceBookTax = selectedPriceBookTax
            }

            updatedState[FORM_DATA_NAMES_BY_MODE[parentMode]].default_pricebook_tax = selectedPriceBookTax?.id || null

            return updatedState
        }, then)
    }


    // Crud Client

    createClient = async () => {
        const endpoint = DjangoUrls["clients:api-clients-list"](window.MARKETPLACE_ENTITY_SLUG)

        const onSuccess = (client) => {
            const successUrl = DjangoUrls["clients:clients-detail"](window.MARKETPLACE_ENTITY_SLUG, client.id)

            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Client "${client.name}" created`,
                path: successUrl,
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Client could not be created",
            })
        }

        this.createUpdateClient(endpoint, "POST", onSuccess, onError)
    }

    updateClient = async () => {
        const endpoint = DjangoUrls["clients:api-clients-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.clientData.id)
        const successUrl = DjangoUrls["clients:clients-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.clientData.id)
        const onSuccess = (client) => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Client "${client.name}" updated`,
                path: successUrl,
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Client could not be updated",
            })
        }

        this.createUpdateClient(endpoint, "PUT", onSuccess, onError)
    }

    createUpdateClient = async (endpoint, endpointMethod, onSuccess, onError) => {
        const dataName = "clientData"
        const submittingName = "submittingClient"
        const errorDictName = "client"

        const dataManipulator = (data, state) => {
            data.industry_type = data.industry_type || null  // Convert blank Industry Type value to null
            data.default_invoice_net = data.default_invoice_net !== "" ? data.default_invoice_net : null  // Convert blank net value to null
            data.contacts = data.contacts || []
            return data
        }

        const setErrors = (fieldName, message, errorDict) => {
            if (fieldName === "non_field_errors" && message === "The fields service_company, name must make a unique set.") {
                errorDict["name"] = "A client with this name already exists."
            }
            else if (fieldName === "contacts") {
                errorDict["contacts"] = "Please correct the contact errors below:"

                // Apply the nested errors
                this.setState((state, props) => {
                    let updatedState = state
                    message.map((contactError, index) => updatedState[FORM_DATA_NAMES_BY_MODE[state.mode]].contacts[index].errors = contactError)
                    return updatedState
                })
            }
            else {
                errorDict[fieldName] = message
            }
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, dataManipulator, setErrors)
    }

    // Crud Service Location

    createServiceLocation = async () => {
        const endpoint = DjangoUrls["clients:api-clients-service-locations-list"](window.MARKETPLACE_ENTITY_SLUG, this.state.clientData.id)
        const onSuccess = (serviceLocation) => {
            this.switchToPrimaryForm()
            this.addToastToQueue({
                type: "success",
                size: "lg",
                title: "Service Location created",
                subtitle: renderServiceLocationString(serviceLocation)
            })
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Service Location could not be created"
            })
        }

        this.createUpdateServiceLocation(endpoint, "POST", onSuccess, onError)
    }

    updateServiceLocation = async () => {
        const endpoint = DjangoUrls["clients:api-clients-service-locations-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.clientData.id, this.state.serviceLocationData.id)
        const onSuccess = (serviceLocation) => {
            this.switchToPrimaryForm()
            this.addToastToQueue({
                type: "success",
                size: "lg",
                title: "Service Location updated",
                subtitle: renderServiceLocationString(serviceLocation)
            })
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Service Location could not be updated"
            })
        }

        this.createUpdateServiceLocation(endpoint, "PUT", onSuccess, onError)
    }

    createUpdateServiceLocation = async (endpoint, endpointMethod, onSuccess, onError) => {
        const dataName = "serviceLocationData"
        const submittingName = "submittingServiceLocation"
        const errorDictName = "serviceLocation"

        const dataManipulator = (data, state) => {
            data.default_invoice_net = data.default_invoice_net !== "" ? data.default_invoice_net : null  // Convert blank net value to null
            data.contacts = data.contacts || []
            return data
        }

        const setErrors = (fieldName, message, errorDict) => {
            if (fieldName === "non_field_errors" && message === "The fields external_client, name, physical_address_formatted must make a unique set.") {
                errorDict["physical_address_street"] = "A service location with this name and address already exists."
            }
            else if (fieldName === "contacts") {
                errorDict["contacts"] = "Please correct the contact errors below:"

                // Apply the nested errors
                this.setState((state, props) => {
                    let updatedState = state
                    message.map((contactError, index) => updatedState[FORM_DATA_NAMES_BY_MODE[state.mode]].contacts[index].errors = contactError)
                    return updatedState
                })
            }
            else {
                errorDict[fieldName] = message
            }
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, dataManipulator, setErrors)
    }

    // Crud PriceBook Items

    createPriceBookTax = async (isDraft) => {
        const endpoint = DjangoUrls["pricebook:api-pricebookitem-list"](window.MARKETPLACE_ENTITY_SLUG)

        const returnMode = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]].returnMode

        const onSuccess = (priceBookItem) => {
            const switchToForm = () => {
                this.switchToPrimaryForm()

                if (SERVICE_LOCATION_FORM_MODES.includes(returnMode)) {
                    this.switchToSecondaryForm(returnMode, deepcopy()(this.state.serviceLocationData), null)
                }
            }
            this.updatePriceBookTaxSelection(priceBookItem, switchToForm)
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Tax Rate "${priceBookItem.description}" created`,
            })
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Tax Rate could not be created"
            })
        }

        this.CUDPriceBookItem(endpoint, "POST", onSuccess, onError)
    }

    updatePriceBookTax = async () => {
        const priceBookItemID = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]].id
        const endpoint = DjangoUrls["pricebook:api-pricebookitem-detail"](window.MARKETPLACE_ENTITY_SLUG, priceBookItemID)

        const returnMode = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]].returnMode

        const onSuccess = (priceBookItem) => {
            const switchToForm = () => {
                this.switchToPrimaryForm()

                if (SERVICE_LOCATION_FORM_MODES.includes(returnMode)) {
                    this.switchToSecondaryForm(returnMode, deepcopy()(this.state.serviceLocationData), null)
                }
            }
            this.updatePriceBookTaxSelection(priceBookItem, switchToForm)
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Tax Rate "${priceBookItem.description}" updated`,
            })
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Tax Rate could not be updated"
            })
        }

        this.CUDPriceBookItem(endpoint, "PUT", onSuccess, onError)
    }

    CUDPriceBookItem = async (endpoint, endpointMethod, onSuccess, onError) => {
        const dataName = "priceBookItemData"
        const submittingName = "submittingPriceBookItem"
        const errorDictName = "priceBookItem"

        const dataManipulator = (data, state) => {
            let finalData = deepcopy()(data)
            finalData.confirmed = true

            // If this isn't a service charge, remove service-charge-specific data
            if (state.priceBookItemData.pricebook_item_type !== PriceBookItemTypes.service) {
                delete finalData.default_unit_type
                delete finalData.expected_job_duration
            }

            return finalData
        }

        const setErrors = (fieldName, message, errorDict) => {
            if (fieldName === "non_field_errors" && message === "The fields description, service_company must make a unique set.") {
                errorDict["description"] = "A PriceBook item with this name already exists."
            }
            else {
                errorDict[fieldName] = message
            }
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, dataManipulator, setErrors)
    }

    // Handle Actions

    handleActionRequest = (action) => {
        switch (action) {
            case "CLIENT_CREATE":
                this.createClient()
                break
            case "CLIENT_UPDATE":
                this.updateClient()
                break
            case "CLIENT_CANCEL_CREATE":
                location.assign(DjangoUrls["clients:clients-list"](window.MARKETPLACE_ENTITY_SLUG))
                break
            case "CLIENT_CANCEL_EDITS":
                location.assign(DjangoUrls["clients:clients-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.clientData.id))
                break
            case "SERVICE_LOCATION_CREATE":
                this.createServiceLocation()
                break
            case "SERVICE_LOCATION_UPDATE":
                this.updateServiceLocation()
                break
            case "PRICEBOOK_TAX_CREATE":
                this.createPriceBookTax()
                break
            case "PRICEBOOK_TAX_CANCEL_CREATE":
                const returnMode = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]].returnMode

                this.switchToPrimaryForm()

                if (SERVICE_LOCATION_FORM_MODES.includes(returnMode)) {
                    this.switchToSecondaryForm(returnMode, deepcopy()(this.state.serviceLocationData), null)
                }
                break
            case "PRICEBOOK_TAX_UPDATE":
                this.updatePriceBookTax()
                break
            default:
                console.error(`No action handler exists for action "${action}".`)
        }
    }

    // Render

    render() {
        if (this.state.clientData === null) {
            return <Spinner centered={true} />
        }
        else {
            if (PRIMARY_FORM_MODES.includes(this.state.mode)) {
                return <ClientForm
                    mode={this.state.mode}
                    submitting={this.state.submittingClient}
                    client={this.state.clientData}
                    errors={this.state.errors.client}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("clientData", fieldName, fieldValue)}
                    requestAction={this.handleActionRequest}
                    switchToSecondaryForm={this.switchToSecondaryForm}
                    showServiceLocationSelect={true}
                    defaultCountryCode={this.state.phoneNumberCountry}
                    currencySymbol={getCurrencySymbol(this.state.currencyCode, this.state.languageCode)}
                    defaultClientType={this.state.defaultClientType}
                    useTaxes={this.state.useTaxes}
                    priceBookTaxes={this.priceBookTaxes}
                    showTaxCreateButton={this.state.showTaxCreateButton}
                    selectedPriceBookTax={this.state.selectedPriceBookTax}
                    updatePriceBookTaxSelection={this.updatePriceBookTaxSelection}
                    returnScroll={this.state.returnScroll}
                />
            }
            else if (SERVICE_LOCATION_FORM_MODES.includes(this.state.mode)) {
                return <ServiceLocationForm
                    mode={this.state.mode}
                    submitting={this.state.submittingServiceLocation}
                    client={this.state.clientData}
                    serviceLocation={this.state.serviceLocationData}
                    errors={this.state.errors.serviceLocation}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("serviceLocationData", fieldName, fieldValue)}
                    requestAction={this.handleActionRequest}
                    switchToPrimaryForm={this.switchToPrimaryForm}
                    switchToSecondaryForm={this.switchToSecondaryForm}
                    defaultCountryCode={this.state.phoneNumberCountry}
                    useTaxes={this.state.useTaxes}
                    showTaxCreateButton={this.state.showTaxCreateButton}
                    selectedPriceBookTax={(
                        this.state.serviceLocationData.selectedPriceBookTax ||
                        (this.state.serviceLocationData.default_pricebook_tax && getPricebookTaxById(this.state.serviceLocationData.default_pricebook_tax, this.priceBookTaxes))
                    )}
                    updatePriceBookTaxSelection={this.updatePriceBookTaxSelection}
                    returnScroll={0}
                />
            }
            else if (PRICEBOOK_ITEM_FORM_MODES.includes(this.state.mode)) {
                return <PriceBookItemForm
                    mode={this.state.mode}
                    submitting={this.state.submittingPriceBookItem}
                    priceBookItem={this.state.priceBookItemData}
                    errors={this.state.errors.priceBookItem}
                    onFormDataChange={(fieldName, fieldValue) => {this.updateFormData("priceBookItemData", fieldName, fieldValue)}}
                    requestAction={this.handleActionRequest}
                    switchToPrimaryForm={this.switchToPrimaryForm}
                    currencySymbol={getCurrencySymbol(this.state.currencyCode, this.state.languageCode)}
                    showQuickBooksRevenueAccountSelect={this.state.showQuickBooksRevenueAccountSelect}
                    showQuickBooksTaxAgencyVendorSelect={this.state.showQuickBooksTaxAgencyVendorSelect}
                    useTaxes={this.state.useTaxes}
                    pricebookDefaultTaxableService={this.state.pricebook_default_taxable_service}
                    pricebookDefaultTaxablePart={this.state.pricebook_default_taxable_part}
                    pricebookDefaultTaxableOther={this.state.pricebook_default_taxable_other}
                    returnScroll={0}
                />
            }
            else {
                return (
                    <div className="data-panel-container data-panel-container--with-margin">
                        <div className="data-panel" aria-label="Unknown Form Mode">
                            <div className="data-panel__form">
                                <p className="data-panel__form__caption">An unhandled form mode was supplied.</p>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    }
}

export default ClientCreateContainer;
