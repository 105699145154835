import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"

import { MobileHeader } from "@molecules/index"

import BottomSheet from "@organisms/BottomSheet/BottomSheet"
import useBottomSheet from "@organisms/BottomSheet/BottomSheet.store"

import BottomSheetAddColumns from "./BottomSheetAddColumns/BottomSheetAddColumns"
import BottomSheetOrderColumns from "./BottomSheetOrderColumns/BottomSheetOrderColumns"
import BottomSheetSortingColumns from "./BottomSheetSortingColumns/BottomSheetSortingColumns"
import styles from "./TableViewMenuBottomSheet.module.scss"

export default function TableViewMenuBottomSheet() {
    const { contentActive, setContentActive } = useBottomSheet()

    return (
        <>
            <BottomSheet.Content priority="primary" isVisible={!contentActive} itemsClassName={styles.primaryContent}>
                {(isTabbable) => (
                    <>
                        <MobileHeader
                            title="Table View Settings"
                            titleIcon={icon({
                                name: "table-cells-large",
                                style: "solid",
                                family: "sharp",
                            })}
                            isTabbable={isTabbable}
                            showDragIndicator={true}
                        />
                        <div className={styles.primaryContentItems}>
                            <BottomSheet.Item
                                icon={icon({ name: "arrow-up-arrow-down", style: "light", family: "sharp" })}
                                isTabbable={isTabbable}
                                label="Sorting"
                                onClick={() => setContentActive("sorting")}
                                hasSubItems={true}
                            />
                            <BottomSheet.Item
                                icon={icon({ name: "columns", style: "light", family: "sharp" })}
                                isTabbable={isTabbable}
                                label="Columns"
                                onClick={() => setContentActive("columns")}
                                hasSubItems={true}
                            />
                        </div>
                    </>
                )}
            </BottomSheet.Content>
            <BottomSheet.Content
                isVisible={contentActive === "columns"}
                priority="secondary"
                itemsClassName={styles.bottomSheetContent}
            >
                {(isVisible) => <BottomSheetOrderColumns isVisible={isVisible} />}
            </BottomSheet.Content>
            <BottomSheet.Content
                isVisible={contentActive === "add-columns"}
                priority="secondary"
                itemsClassName={styles.bottomSheetContent}
            >
                {(isVisible) => <BottomSheetAddColumns isVisible={isVisible} />}
            </BottomSheet.Content>
            <BottomSheet.Content
                isVisible={contentActive === "sorting"}
                priority="secondary"
                itemsClassName={styles.sortingContainer}
                header={{
                    title: "Sorting",
                    showBackButton: true,
                    onBackClick: () => setContentActive(null),
                    showDragIndicator: true,
                }}
            >
                {(isVisible) => (
                    <>
                        <BottomSheetSortingColumns isVisible={isVisible} />
                    </>
                )}
            </BottomSheet.Content>
        </>
    )
}
