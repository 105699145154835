import { useMemo } from "react"

import capitalize from "@utils/capitalize"

import { Table } from "@organisms"

import { InventoryStatusCellProps } from "./InventoryStatusCell.types"

export default function InventoryStatusCell(props: InventoryStatusCellProps) {
    const { value, trackInventory } = props

    if (!trackInventory) {
        return <Table.Cells.TruncatedTextCell content={"Untracked"} />
    }

    const variant = useMemo(() => {
        if (value === "full") {
            return "success"
        } else if (value === "limited") {
            return "caution"
        } else if (value === "low") {
            return "warning"
        } else if (value === "depleted") {
            return "dangerous"
        } else {
            return "secondary"
        }
    }, [value])

    return <Table.Cells.TruncatedTextCell variant={variant} content={capitalize(value)} />
}
