// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BottomBarItem-base__h_FIk{position:relative;display:block;width:var(--size-full);padding:var(--size-1);color:var(--gray-a10);text-decoration:none;border-radius:var(--border-radius-2);outline:none;cursor:pointer;transition:var(--duration-fast);transition-property:background-color,color}.BottomBarItem-base__h_FIk:focus-visible:not(.BottomBarItem-disabled__UN7aP){color:var(--gray-12);background-color:var(--gray-a3);outline:var(--size-0_5) solid var(--gray-12);outline-offset:calc(var(--size-0_5)*-1)}.BottomBarItem-base__h_FIk:hover:not(.BottomBarItem-disabled__UN7aP){color:var(--gray-12);background-color:var(--gray-a3)}.BottomBarItem-base__h_FIk:active:not(.BottomBarItem-disabled__UN7aP){color:var(--gray-12);background-color:var(--gray-a4)}.BottomBarItem-base__h_FIk.BottomBarItem-active__csi4t:not(.BottomBarItem-disabled__UN7aP){color:var(--gray-12)}.BottomBarItem-base__h_FIk.BottomBarItem-disabled__UN7aP{color:var(--gray-a7);cursor:not-allowed}.BottomBarItem-innerContainer__aCUGK{display:flex;flex-direction:column;flex-shrink:0;align-items:center;justify-content:center}.BottomBarItem-label__cVZUl{font:var(--font-mobile-caption-small-475);font-feature-settings:var(--feature-settings-mobile-caption-small-475);letter-spacing:var(--letter-spacing-mobile-caption-small-475);line-height:var(--size-4);text-align:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "BottomBarItem-base__h_FIk",
	"disabled": "BottomBarItem-disabled__UN7aP",
	"active": "BottomBarItem-active__csi4t",
	"innerContainer": "BottomBarItem-innerContainer__aCUGK",
	"label": "BottomBarItem-label__cVZUl"
};
export default ___CSS_LOADER_EXPORT___;
