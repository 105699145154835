import React, { Component } from 'react';


class EmailField extends Component {

    render() {
        const {fieldName, fieldLabel, fieldValue, fieldOnChange, disabled=false, optional=false, optionalText="optional", errors} = this.props

        return (
            <div className="data-panel__form__field" id={`div_id_${fieldName}`} aria-label={fieldLabel}>
                <label htmlFor={`id_${fieldName}`} className="data-panel__form__field__label in-form">{fieldLabel}{optional && <span className="text-optional"> ({optionalText})</span>}</label>
                <div className="data-panel__form__field__input" title={disabled && fieldValue ? many === true ? fieldValue.join(", ") : fieldValue : null}>
                    {
                        <input
                            defaultValue={fieldValue}
                            onChange={event => fieldOnChange(event.target.value)}
                            type="email"
                            maxLength="254"
                            id={`id_${fieldName}`}
                            name="email"
                            autoComplete="off"
                            className={disabled ? "is-disabled" : ""}
                            disabled={disabled}
                        />
                    }
                </div>
                {errors[fieldName] && <div className="data-panel__form__field__errors" aria-label="Field Errors">{errors[fieldName]}</div>}
            </div>
        )
    }
}

export default EmailField;
