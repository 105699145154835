import { useEffect, useState } from "react"

import matchesDesktopScreen from "@utils/matchesDesktopScreen"

export default function useIsDesktop(desktopScreenSize = 1024) {
    const mediaListener = matchesDesktopScreen(desktopScreenSize).media
    const [isDesktop, setIsDesktop] = useState<boolean>(mediaListener.matches)

    const checkIsDesktop = (event: MediaQueryListEvent | MediaQueryList) => {
        setIsDesktop(event.matches)
    }

    useEffect(() => {
        try {
            mediaListener.addEventListener("change", checkIsDesktop)
        } catch {
            mediaListener.addListener(checkIsDesktop)
        }

        return () => {
            try {
                mediaListener.removeEventListener("change", checkIsDesktop)
            } catch {
                mediaListener.removeListener(checkIsDesktop)
            }
        }
    }, [])

    return isDesktop
}
