import { ColumnDef } from "@tanstack/react-table"
import clsx from "clsx"

import generateRandomKey from "@utils/generateRandomKey"

import { Table } from "@organisms"
import { ObjectsData } from "@organisms/ObjectsView/ObjectsView.types"
import styles from "@organisms/Table/TableContent/TableContent.module.scss"
import { cellOrientationByHeader, loadingTypeByHeader } from "@organisms/Table/TableEdgeStates/TableEdgeStates.utils"

import { TableLoadingStateProps } from "./TableLoadingState.types"

const DEFAULT_NUMBER_OF_LOADING_ROWS = 14

export default function TableLoadingState(props: TableLoadingStateProps) {
    const {
        tabContentName,
        tabs,
        activeTab,
        isAnimated = true,
        isBackground,
        numberOfRows = DEFAULT_NUMBER_OF_LOADING_ROWS,
    } = props

    const activeTabConfig = tabs?.find((tab) => tab.title === activeTab)

    if (!activeTabConfig) {
        return null
    }

    const columns = activeTabConfig.columnDefinition as ColumnDef<ObjectsData, unknown>[]

    const rows = Array.from(Array(numberOfRows).keys())

    return (
        <div
            className={clsx(styles.base, styles.noScroll, {
                [styles.tableAsBackground]: isBackground,
            })}
            aria-label={`${tabContentName} List Loading`}
            role="tabpanel"
        >
            <table>
                <thead className={styles.tableHeader}>
                    <tr className={styles.headerRow}>
                        {columns.map((column) => (
                            <Table.Cells.SkeletonCell
                                isAnimated={isAnimated}
                                key={generateRandomKey()}
                                variant="header"
                                cellWidth={column.size}
                                isRightAligned={cellOrientationByHeader[column.id as keyof ObjectsData] === "right"}
                            />
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {rows.map(() => (
                        <tr key={generateRandomKey()} className={clsx(styles.bodyRow, styles.loadingRow)}>
                            {columns.map((column) => (
                                <Table.Cells.SkeletonCell
                                    isAnimated={isAnimated}
                                    key={generateRandomKey()}
                                    variant={loadingTypeByHeader[column.id as keyof ObjectsData]}
                                    cellWidth={column.size}
                                    isRightAligned={
                                        cellOrientationByHeader[column.id as keyof ObjectsData] === "right"
                                    }
                                />
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}
