import clsx from "clsx"
import { useMemo } from "react"
import zxcvbn from "zxcvbn"

import styles from "./PasswordStrengthIndicator.module.scss"
import { PasswordStrengthIndicatorProps } from "./PasswordStrengthIndicator.types"

export default function PasswordStrengthIndicator(props: PasswordStrengthIndicatorProps) {
    const { password, size } = props

    const result = useMemo(() => password && zxcvbn(password), [password])

    const strengthLabels = ["Weak", "Weak", "Average", "Strong", "Excellent"]
    const label = strengthLabels[result?.score] || "Strength"

    const strengthClasses = [styles.weak, styles.weak, styles.average, styles.strong, styles.excellent]
    const strengthClass = strengthClasses[result?.score]

    return (
        <div
            className={clsx(styles.base, {
                [styles.sizeSm]: size === "sm",
                [styles.sizeMd]: size === "md",
                [strengthClass]: !!strengthClass,
            })}
            aria-valuenow={result?.score}
            aria-valuemax={4}
            aria-valuemin={0}
            role="progressbar"
            aria-label={label}
        >
            <div className={styles.strength}>{label}</div>
            <div className={styles.indicator}>
                <div className={styles.ellipse} />
                <div className={styles.ellipse} />
                <div className={styles.ellipse} />
                <div className={styles.ellipse} />
                <div className={styles.ellipse} />
                <div className={styles.ellipse} />
                <div className={styles.ellipse} />
                <div className={styles.ellipse} />
            </div>
        </div>
    )
}
