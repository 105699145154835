import deepcopy from "rfdc";
import WrapperButton from "../../core/buttons/WrapperButton";
import { convertToKebabCase, formatDateTime, valueIsDefined } from "../../core/utils/utils";
import { getWarrantyStatus } from "../utils/utils";


export default function InlineEquipmentWarrantyDisplay(props) {
    const { warranty, requestAction } = props
    const errors = deepcopy()(warranty.errors || {})

    const formattedStartDate = valueIsDefined(warranty.start_date) ? formatDateTime(warranty.start_date, "UTC", {short: true, addYear: true}).localDateString : "--"
    const formattedEndDate = valueIsDefined(warranty.end_date) ? formatDateTime(warranty.end_date, "UTC", {short: true, addYear: true}).localDateString : "--"

    return (
        <WrapperButton key={`${warranty.id}_${warranty.name}`} type="object_link" handler={event => requestAction("EQUIPMENT_WARRANTY_EDIT")} title={warranty.name} ariaLabel="Update Warranty">
            <div className="data-panel__list-select__option__label equipment-warranty-label">
                <div className="equipment-warranty-label__row equipment-warranty-status-display">
                    <div className="equipment-warranty-label__field" aria-label={getWarrantyStatus(warranty.start_date, warranty.end_date)}>
                        <div className="equipment-warranty-label__field-value"><span className={`data-tag equipment-warranty--${convertToKebabCase(getWarrantyStatus(warranty.start_date, warranty.end_date))}`} style={{margin: "0", width: "100%"}}>{getWarrantyStatus(warranty.start_date, warranty.end_date)}</span></div>
                    </div>
                </div>

                {errors.non_field_error && <div className="data-panel__form__field__errors" aria-label="Non Field Error">{errors.non_field_error}</div>}
                <div className="equipment-warranty-label__row">
                    <div className="equipment-warranty-label__field" aria-label="Name">
                        <div className="equipment-warranty-label__field-label" aria-hidden="true">Name</div>
                        <div className="equipment-warranty-label__field-value">{warranty.name}</div>
                    </div>
                </div>
                <div className="equipment-warranty-label__row">
                    <div className="equipment-warranty-label__field" aria-label="Start Date">
                        <div className={"equipment-warranty-label__field-label" + (errors.start_date ? " invalid" : "")} aria-hidden="true">Start Date</div>
                        <div className="equipment-warranty-label__field-value">{formattedStartDate}</div>
                        {errors.start_date && <div className="data-panel__form__field__errors" aria-label="Field Errors">{errors.start_date}</div>}
                    </div>
                    <div className="equipment-warranty-label__field" aria-label="End Date">
                        <div className={"equipment-warranty-label__field-label" + (errors.end_date ? " invalid" : "")} aria-hidden="true">End Date</div>
                        <div className="equipment-warranty-label__field-value">{formattedEndDate}</div>
                        {errors.end_date && <div className="data-panel__form__field__errors" aria-label="Field Errors">{errors.end_date}</div>}
                    </div>
                </div>
                <div className="equipment-warranty-label__row">
                    <div className="equipment-warranty-label__field" aria-label="Details">
                        <div className={"equipment-warranty-label__field-label" + (errors.details ? " invalid" : "")} aria-hidden="true">Details</div>
                        <div className="equipment-warranty-label__field-value">{warranty.details || "--"}</div>
                        {errors.details && <div className="data-panel__form__field__errors" aria-label="Field Errors">{errors.details}</div>}
                    </div>
                </div>
            </div>
            <div className="data-panel__list-select__option__icon"><i className="fa-sharp fa-angle-right" aria-hidden="true"></i></div>
        </WrapperButton>
    )
}
