import { RouteCRUD } from "./routes.types"

export const INVOICES_ROUTES: RouteCRUD = {
    CREATE: "/{service_company_slug}/invoices/create/",
    LIST: "/{service_company_slug}/invoices/",
    COUNT: "/{service_company_slug}/api/invoices/count/",
    LIGHTWEIGHT_LIST: "/{service_company_slug}/api/invoices/lightweight/",
    UPDATE: "/{service_company_slug}/invoices/{id}/update/",
    DETAILS: "/{service_company_slug}/invoices/{id}/",
}
