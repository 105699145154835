export const DEFAULT_DEBOUNCE_TIME = 250

export default function debounce(func: (...args: unknown[]) => unknown, wait = DEFAULT_DEBOUNCE_TIME) {
    let timeout: ReturnType<typeof setTimeout>

    return function executedFunction(...args: unknown[]) {
        const later = () => {
            clearTimeout(timeout)
            func(...args)
        }

        clearTimeout(timeout)
        timeout = setTimeout(later, wait)
    }
}
