import { useQuery } from "@tanstack/react-query"

import { HasPasswordResponse } from "./SetPasswordDialogsTrigger.types"
import { getHasPassword } from "./SetPasswordDialogsTrigger.utils"

const useHasPasswordQuery = () =>
    useQuery<HasPasswordResponse, Error>({
        queryKey: ["hasPassword"],
        queryFn: getHasPassword,
    })

export { useHasPasswordQuery }
