import Spinner from "@legacy/core/components/Spinner";
import { Component } from "react";
import ButtonGroup from "../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../core/buttons/UniversalButton";
import BasicDisplayField from "../../core/fields/BasicDisplayField";
import TextField from "../../core/fields/TextField";
import { formatCurrencyForServiceCompany } from "../../core/utils/utils";
import { calculatePurchaseOrderAmounts } from "../utils/utils";


class PurchaseOrderCancelForm extends Component {

    componentDidMount() {
        // Return to the previous scroll
        document.querySelector(".main").scrollTo(0, this.props.returnScroll || 0)
    }

    renderButtons = () => {
        const { purchaseOrder, cancelData, requestAction, switchToPrimaryForm, submitting, errors, onFormDataChange, returnScroll } = this.props

        if (errors.unexpectedError) {
            return (
                <div className="data-panel__action-feedback">
                    <span className="text-invalid"><strong>An unexpected error occurred.</strong></span>
                </div>
            )
        }
        else {
            if (submitting) {
                return <Spinner centered={true} />
            }
            else {
                return (
                    <ButtonGroup>
                        <ButtonGroupRow>
                            <UniversalButton type="danger" text="Mark as Cancelled" handler={event => requestAction("PURCHASE_ORDER_CANCEL_SUBMIT")} />
                            <UniversalButton type="secondary" text="Nevermind" handler={event => switchToPrimaryForm()} />
                        </ButtonGroupRow>
                    </ButtonGroup>
                )
            }
        }
    }

    render() {
        const { purchaseOrder, cancelData, requestAction, switchToPrimaryForm, submitting, errors, onFormDataChange, returnScroll } = this.props
        const { subtotal } = calculatePurchaseOrderAmounts(purchaseOrder)

        return (
            <div className="data-panel-container data-panel-container--with-margin">
                <div className="data-panel" aria-label="Purchase Order Cancel">
                    <div className="data-panel__form" aria-label="Purchase Order Cancel Form">
                        <p className="data-panel__form__caption">Please confirm that this purchase order has been cancelled.</p>
                        <p className="data-panel__form__caption text-medium">
                            Note that Roopairs doesn't reach out to your vendor to cancel the order. Please work with your vendor to ensure this order is in fact cancelled.
                        </p>
                        <BasicDisplayField
                            fieldName="id"
                            fieldLabel="Roopairs Purchase Order ID"
                            fieldValue={purchaseOrder.custom_id ? purchaseOrder.custom_id : purchaseOrder.id}
                        ></BasicDisplayField>
                        <BasicDisplayField
                            fieldName="vendor"
                            fieldLabel="Vendor"
                            fieldValue={purchaseOrder.vendor.name}
                        ></BasicDisplayField>
                        <BasicDisplayField
                            fieldName="item_count"
                            fieldLabel="Item Count"
                            fieldValue={`${purchaseOrder.line_items.length} items`}
                        ></BasicDisplayField>
                        <BasicDisplayField
                            fieldName="subtotal"
                            fieldLabel="Expected Subtotal"
                            fieldValue={formatCurrencyForServiceCompany(subtotal, purchaseOrder.service_company)}
                        ></BasicDisplayField>
                        <TextField
                            fieldName="cancel_message"
                            fieldLabel="Cancel Reason"
                            fieldValue={cancelData.cancel_message || ""}
                            fieldOnChange={cancel_message => onFormDataChange("cancel_message", cancel_message || "")}
                            rows={3}
                            placeholder="Why did you cancel this purchase order?"
                            errors={errors}
                        ></TextField>
                    </div>
                    {this.renderButtons()}
                </div>
            </div>
        )
    }
}

export default PurchaseOrderCancelForm;
