import Banner from "../../core/components/Banner"


export default function DuplicateEquipmentDisplay(props) {
    const { duplicateEquipment } = props

    return (
        <Banner
            type={window.SERVICE_COMPANY_PREVENT_DUPLICATE_EQUIPMENT_CREATION ? "info" : "danger"}
            text={
                <div>
                    <p>The identifying information entered for this equipment (Manufacturer, Model Number, Serial Number) already exists on another piece of equipment for this client:</p>
                    <ul>
                        {duplicateEquipment.map(equipment => <li key={equipment.id}><strong>{equipment.display_name}</strong><br/>{equipment.service_location_string}</li>)}
                    </ul>
                </div>
            }
            extraMargin={true}
        />
    )
}
