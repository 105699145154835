import React, { Component } from "react";


class PureComponentWrapper extends Component {

    shouldComponentUpdate = (nextProps) => {
        return this.props.updateTrigger(this.props, nextProps)
    }

    render = () => {
        const WrappedComponent = this.props.wrappedComponent
        return <WrappedComponent {...this.props} />
    }
}

export default PureComponentWrapper;
