import clsx from "clsx"

import { getSymbolByName } from "@utils/getSymbolByName"

import styles from "./HotKey.module.scss"
import { HotKeyProps } from "./HotKey.types"

export default function HotKey(props: HotKeyProps) {
    const { symbolName, variant, className } = props

    const symbol = getSymbolByName(symbolName)

    return (
        <div className={clsx(styles.base, className, styles[variant])}>
            <span className={styles.symbol}>{symbol}</span>
        </div>
    )
}
