import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import clsx from "clsx"
import { useMemo } from "react"

import useCurrentObjectsView from "@hooks/useCurrentObjectsView"
import useIsDesktop from "@hooks/useIsDesktop"

import getColumnNameByColumnId from "@utils/getColumnNameByColumnId"
import navigatorVibrate from "@utils/navigatorVibrate"

import { CustomIcon, Icon, IconButton, Tooltip } from "@atoms"

import styles from "./TableViewMenuColumnOrderingItem.module.scss"
import { TableViewMenuColumnOrderingItemProps } from "./TableViewMenuColumnOrderingItem.types"

export default function TableViewMenuColumnOrderingItem(props: TableViewMenuColumnOrderingItemProps) {
    const { columnId, isTabbable, onPinColumn, onUnpinColumn, isPinned } = props

    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: columnId })

    const isDesktop = useIsDesktop()

    const { currentObjectType, updateColumnVisibility, reachedPinLimit, reachedMinimumOfColumnsVisible } =
        useCurrentObjectsView()

    const handleColumnVisibilityChange = () => {
        updateColumnVisibility(columnId, false)
    }

    const handleColumnPinningChange = () => {
        if (isPinned) {
            onUnpinColumn(columnId)
        } else {
            onPinColumn(columnId)
        }
    }

    const vibrateUserPhoneIfReachingPinLimit = () => {
        if (reachedPinLimit && !isPinned) {
            navigatorVibrate()
        }
    }

    const label = useMemo(() => {
        return getColumnNameByColumnId(columnId, currentObjectType)
    }, [columnId, currentObjectType])

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        touchAction: "none",
    }

    const properIcon = useMemo(() => {
        if (isDragging) {
            return icon({
                name: "arrows-up-down",
                style: "light",
                family: "sharp",
            })
        } else {
            return icon({
                name: "grip-lines",
                style: "light",
                family: "sharp",
            })
        }
    }, [isDragging])

    return (
        <>
            <div
                className={clsx(styles.base, {
                    [styles.reachedPinLimit]: reachedPinLimit,
                    [styles.dragging]: isDragging,
                })}
                style={style}
                ref={setNodeRef}
                {...attributes}
                {...listeners}
                tabIndex={-1}
            >
                <div className={styles.container}>
                    <div className={styles.iconAndLabel}>
                        <div className={styles.icon}>
                            <Icon icon={properIcon} size={16} />
                        </div>
                        <div className={styles.label}>{label}</div>
                    </div>
                    <Tooltip
                        type="message"
                        icon={
                            <CustomIcon
                                icon="fa-sharp-light-thumbtack-slash"
                                size={12}
                                className={styles.tooltipIcon}
                            />
                        }
                        title="Pinned Columns Limit "
                        message="Maximum number of pinned columns is 3. Unpin one of the columns to pin a new one. "
                        side="left"
                        isEnabled={reachedPinLimit && !isPinned}
                    >
                        <div className={styles.buttons} data-no-dnd="true">
                            <div onTouchStart={vibrateUserPhoneIfReachingPinLimit}>
                                <IconButton
                                    colorScheme="gray"
                                    size={isDesktop ? "sm" : "md"}
                                    variant="ghost"
                                    className={isPinned ? styles.unpinButton : styles.pinButton}
                                    isTabbable={isTabbable}
                                    isDisabled={reachedPinLimit && !isPinned}
                                    icon={icon({ name: "thumbtack", style: "light", family: "sharp" })}
                                    customIcon="fa-sharp-light-thumbtack-slash"
                                    useCustomIcon={isPinned}
                                    onClick={handleColumnPinningChange}
                                    noDebounce={true}
                                />
                            </div>
                        </div>
                    </Tooltip>
                    <IconButton
                        colorScheme="gray"
                        size={isDesktop ? "sm" : "md"}
                        variant="ghost"
                        isTabbable={isTabbable}
                        isDisabled={reachedMinimumOfColumnsVisible}
                        icon={icon({ name: "close", style: "light", family: "sharp" })}
                        onClick={handleColumnVisibilityChange}
                        noDebounce={true}
                    />
                </div>
            </div>
        </>
    )
}
