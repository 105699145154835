import useUser from "@hooks/useUser"
import { formatCurrency, valueIsDefined } from "../utils/utils"

function CurrencyRenderer(props) {
    const value  = props.value || props.getValue()
    const { user:currentUser } = useUser()

    if (valueIsDefined(value)) {
        return (
            <>
                {formatCurrency(value, currentUser.service_company.currency_code, currentUser.service_company.language_code)}
            </>
        )
    }
    else {
        return null
    }

}

export default CurrencyRenderer
