import React, { Component } from 'react';
import { renderClientString, renderServiceLocationString } from '../../clients/utils/utils';
import OpenInMapsButton from './OpenInMapsButton';


class ClientInformationDisplay extends Component {

    render() {
        const { client, serviceLocation=null, showOpenInMapsButton=false, separatorClass="" } = this.props

        return (
            <div className="data-panel__heading" aria-label="Client Information">
                <span className="data-panel__heading__title" aria-label="Name">{client && renderClientString(client)}</span>
                {serviceLocation && <span className="data-panel__heading__subtitle" aria-label="Address">{renderServiceLocationString(serviceLocation)}</span>}
                <hr className={separatorClass} aria-hidden="true" />
                {showOpenInMapsButton && <OpenInMapsButton address={serviceLocation.physical_address_geocode_formatted}></OpenInMapsButton>}
            </div>
        )
    }
}

export default ClientInformationDisplay;
