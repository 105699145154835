import clsx from "clsx"

import useBottomSheet from "@organisms/BottomSheet/BottomSheet.store"

import styles from "./BottomSheetFooter.module.scss"
import { BottomSheetFooterProps } from "./BottomSheetFooter.types"

export default function BottomSheetFooter(props: BottomSheetFooterProps) {
    const { children, showDivider = false } = props

    const { isContentOverflowing } = useBottomSheet()

    return (
        <div
            className={clsx(styles.base, {
                [styles.withDivider]: showDivider || isContentOverflowing,
            })}
        >
            {children}
        </div>
    )
}
