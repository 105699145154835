import clsx from "clsx"

import styles from "./AvatarStatus.module.scss"
import { AvatarStatusProps } from "./AvatarStatus.types"

export default function AvatarStatus(props: AvatarStatusProps) {
    const { status, size } = props

    return (
        <div
            className={clsx(styles.base, styles[`size${size}`], {
                [styles.working]: status === "working",
                [styles.unavailable]: status === "unavailable",
            })}
        />
    )
}
