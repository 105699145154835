import { APIDeckTaxStrategies } from "@constants/choices";
import Spinner from "@legacy/core/components/Spinner";
import { Component, Fragment } from "react";
import ClientSearchOrCreateSelect, { clientToClientOption } from "../../clients/inputs/ClientSearchOrCreateSelect";
import ServiceLocationSearchOrCreateSelect, { serviceLocationToServiceLocationOption } from "../../clients/inputs/ServiceLocationSearchOrCreateSelect";
import { renderClientString, renderServiceLocationString } from "../../clients/utils/utils";
import ButtonGroup from "../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../core/buttons/UniversalButton";
import Banner from "../../core/components/Banner";
import LineItemListSelectFieldGroup from "../../core/components/LineItemListSelectFieldGroup";
import AddressAutocompleteFieldGroup from "../../core/fields/AddressAutocompleteFieldGroup";
import AttachmentUploadField from "../../core/fields/AttachmentUploadField";
import BasicDisplayField from "../../core/fields/BasicDisplayField";
import BooleanField from "../../core/fields/BooleanField";
import CharField from "../../core/fields/CharField";
import DateField from "../../core/fields/DateField";
import LinkedObjectsDisplayField from "../../core/fields/LinkedObjectsDisplayField";
import SearchCreateSelectField from "../../core/fields/SearchCreateSelectField";
import TextField from "../../core/fields/TextField";
import { valueIsDefined } from "../../core/utils/utils";
import JobObjectLink from "../../jobs/components/JobObjectLink";
import PriceBookServiceSearchOrCreateSelect, { priceBookServiceToPriceBookServiceOption } from "../../pricebook/inputs/PriceBookServiceSearchOrCreateSelect";
import PriceBookTaxSearchOrCreateSelect, { getPriceBookTaxObjectLabel, priceBookTaxToPriceBookTaxOption } from "../../pricebook/inputs/PriceBookTaxSearchOrCreateSelect";
import EstimateFeedAccordion from "../components/EstimateFeedAccordion";
import { calculateEstimateAmounts } from "../utils/utils";

class EstimateForm extends Component {

    constructor(props) {
        super(props)

        this.state = {
            clientSearchInput: "",
            serviceLocationSearchInput: "",
            serviceSearchInput: "",
            taxSearchInput: "",
        }
    }

    componentDidMount() {
        // Return to the previous scroll
        document.querySelector(".main").scrollTo(0, this.props.returnScroll || 0)
    }

    estimateIsSeen = (estimate) => {
        return valueIsDefined(estimate.id) && valueIsDefined(estimate.is_draft) && estimate.is_draft === false && estimate.last_viewed !== null
    }

    renderButtons = () => {
        const {
            mode,
            submitting,
            estimate,
            jobWalk,
            errors,
            onFormDataChange,
            requestAction,
            switchToSecondaryForm,
            updateClientSelection,
            updateServiceLocationSelection,
            updatePriceBookServiceSelection,
            updatePriceBookTaxSelection,
            selectedClient,
            selectedServiceLocation,
            selectedPriceBookService,
            selectedPriceBookTax,
            formatCurrencyValue,
            currencySymbol,
            showCustomEstimateIDField,
            useBillingAddress,
            foldDataComplete,
            showQuickBooksItemSelect=false,
            showTaxCreateButton=false,
            useTaxes=false,
            hideTaxRates=false,
            fileStackAPIKey,
            fileStackPolicy,
            fileStackSignature,
            updateAttachments,
            returnScroll
        } = this.props

        if (errors.unexpectedError) {
            return (
                <div className="data-panel__action-feedback">
                    <span className="text-invalid"><strong>An unexpected error occurred.</strong></span>
                </div>
            )
        }
        else {
            if (submitting) {
                return (
                    <div className="data-panel__action-feedback">
                        <Spinner centered={true} />
                    </div>
                )
            }
            else {
                if (mode === "ADD_ESTIMATE") {
                    return (
                        <ButtonGroup>
                            <ButtonGroupRow>
                                <UniversalButton type="primary" text="Create and Send" handler={event => requestAction("ESTIMATE_CREATE")} />
                            </ButtonGroupRow>
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Preview Estimate" handler={event => requestAction("ESTIMATE_PREVIEW")} />
                                <UniversalButton type="secondary" text="Save Draft" handler={event => requestAction("ESTIMATE_CREATE_DRAFT")} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
                else if (mode === "EDIT_ESTIMATE") {
                    if (estimate.is_draft) {
                        return (
                            <ButtonGroup>
                                <ButtonGroupRow>
                                    <UniversalButton type="primary" text="Create and Send" handler={event => requestAction("ESTIMATE_CREATE")} />
                                </ButtonGroupRow>
                                <ButtonGroupRow>
                                    <UniversalButton type="secondary" text="Preview Estimate" handler={event => requestAction("ESTIMATE_PREVIEW")} />
                                </ButtonGroupRow>
                                <ButtonGroupRow>
                                    <UniversalButton type="secondary" text="Save Draft" handler={event => requestAction("ESTIMATE_CREATE_DRAFT")} />
                                    <UniversalButton type="danger" text="Delete Draft" handler={event => requestAction("ESTIMATE_DELETE_DRAFT")} />
                                </ButtonGroupRow>
                            </ButtonGroup>
                        )
                    }
                    else {
                        return (
                            <ButtonGroup>
                                {
                                    this.estimateIsSeen(estimate) && (
                                        <ButtonGroupRow>
                                            <UniversalButton type="primary" text="Save and Resend" handler={event => requestAction("ESTIMATE_UPDATE_AND_RESEND")} />
                                        </ButtonGroupRow>
                                    )
                                }
                                <ButtonGroupRow>
                                    <UniversalButton type={this.estimateIsSeen(estimate) ? "secondary" : "primary"} text="Save" handler={event => requestAction("ESTIMATE_UPDATE")} />
                                    <UniversalButton type="secondary" text="Cancel Edits" handler={event => requestAction("ESTIMATE_CANCEL_EDITS")} />
                                </ButtonGroupRow>
                            </ButtonGroup>
                        )
                    }
                }
            }
        }
    }

    render() {
        const {
            mode,
            submitting,
            estimate,
            jobWalk,
            errors,
            onFormDataChange,
            requestAction,
            switchToSecondaryForm,
            updateClientSelection,
            updateServiceLocationSelection,
            updatePriceBookServiceSelection,
            updatePriceBookTaxSelection,
            selectedClient,
            selectedServiceLocation,
            selectedPriceBookService,
            selectedPriceBookTax,
            formatCurrencyValue,
            currencySymbol,
            showCustomEstimateIDField,
            useBillingAddress,
            foldDataComplete,
            showQuickBooksItemSelect=false,
            showTaxCreateButton=false,
            useTaxes=false,
            hideTaxRates=false,
            fileStackAPIKey,
            fileStackPolicy,
            fileStackSignature,
            updateAttachments,
            returnScroll
        } = this.props
        const {subtotal, tax, total, downPaymentAmountPaid, downPaymentAmountDue} = calculateEstimateAmounts(estimate)

        const allowClientDetailsEdit = Object.keys(jobWalk).length === 0 && (estimate.job_walk === null || estimate.job_walk === undefined)

        return (
            <Fragment>
                <div id="estimate_form_react_wrapper">
                    <div className="data-panel-container data-panel-container--with-margin">
                        <div className="data-panel" aria-label="Estimate Create/Update">
                            <div className="data-panel__form" aria-label="Estimate Create/Update Form">
                                <p className="data-panel__form__caption">Please provide the following information to create your estimate:</p>
                                <fieldset>
                                    <legend>Client Details</legend>
                                    <SearchCreateSelectField
                                        fieldName="client"
                                        fieldLabel="Client"
                                        fieldValue={selectedClient !== null ? renderClientString(selectedClient) : null}
                                        inputComponent={
                                            <ClientSearchOrCreateSelect
                                                disabled={!allowClientDetailsEdit}
                                                onSelectionChange={selectedOption => updateClientSelection(selectedOption != null ? selectedOption.object : null)}
                                                onInputChange={(input, action) => {action.action === "input-change" && this.setState({clientSearchInput: input})}}
                                                defaultSelected={selectedClient !== null ? clientToClientOption(selectedClient) : null}
                                                showCreateButton={true}
                                                onCreateClick={event => switchToSecondaryForm("ADD_CLIENT", null, {name: this.state.clientSearchInput})}
                                                listEndpointKwargs={[
                                                    ["is_active", true]
                                                ]}
                                            ></ClientSearchOrCreateSelect>
                                        }
                                        showButton={allowClientDetailsEdit && selectedClient !== null}
                                        buttonLabel={<Fragment><i className="fa-sharp fa-light fa-pen-to-square" aria-hidden="true"></i>Edit Client</Fragment>}
                                        buttonAction={event => switchToSecondaryForm("EDIT_CLIENT", selectedClient, null)}
                                        disabled={!allowClientDetailsEdit}
                                        errors={errors}
                                    ></SearchCreateSelectField>
                                    {
                                        selectedClient !== null && selectedClient.notes !== "" && (
                                            <BasicDisplayField
                                                fieldName="client_notes"
                                                fieldLabel="Client Notes"
                                                fieldValue={selectedClient.notes}
                                                indented={true}
                                            ></BasicDisplayField>
                                        )
                                    }
                                    {
                                        selectedClient !== null && (
                                            <SearchCreateSelectField
                                                fieldName="service_location"
                                                fieldLabel="Service Location"
                                                fieldValue={selectedServiceLocation !== null ? renderServiceLocationString(selectedServiceLocation) : null}
                                                inputComponent={
                                                    <ServiceLocationSearchOrCreateSelect
                                                        disabled={!allowClientDetailsEdit}
                                                        onSelectionChange={selectedOption => updateServiceLocationSelection(selectedOption != null ? selectedOption.object : null)}
                                                        onInputChange={(input, action) => {action.action === "input-change" && this.setState({serviceLocationSearchInput: input})}}
                                                        defaultSelected={selectedServiceLocation !== null ? serviceLocationToServiceLocationOption(selectedServiceLocation) : null}
                                                        client={selectedClient}
                                                        showCreateButton={true}
                                                        onCreateClick={event => switchToSecondaryForm("ADD_SERVICE_LOCATION", null, {physical_address_street: this.state.serviceLocationSearchInput})}
                                                    ></ServiceLocationSearchOrCreateSelect>
                                                }
                                                showButton={allowClientDetailsEdit && selectedServiceLocation !== null}
                                                buttonLabel={<Fragment><i className="fa-sharp fa-light fa-pen-to-square" aria-hidden="true"></i>Edit Service Location</Fragment>}
                                                buttonAction={event => switchToSecondaryForm("EDIT_SERVICE_LOCATION", selectedServiceLocation, null)}
                                                disabled={!allowClientDetailsEdit}
                                                errors={errors}
                                            ></SearchCreateSelectField>
                                        )
                                    }
                                    {
                                        selectedClient !== null && selectedServiceLocation !== null && selectedServiceLocation.notes !== "" && (
                                            <BasicDisplayField
                                                fieldName="service_location_notes"
                                                fieldLabel="Service Location Notes"
                                                fieldValue={selectedServiceLocation.notes}
                                                indented={true}
                                            ></BasicDisplayField>
                                        )
                                    }
                                </fieldset>
                                {
                                    selectedClient !== null && selectedServiceLocation !== null && (
                                        <fieldset>
                                            <legend>Basic Details</legend>
                                            {
                                                Object.keys(jobWalk).length !== 0 && !(estimate.job_walk === null || estimate.job_walk === undefined) && (
                                                    <LinkedObjectsDisplayField
                                                        fieldName="job_walk"
                                                        fieldLabel="Created From Job Walk"
                                                        objectComponentList={[<JobObjectLink key="parent_job_walk_0" job={jobWalk} destination={DjangoUrls["jobs:jobs-detail"](window.MARKETPLACE_ENTITY_SLUG, jobWalk.id)}></JobObjectLink>]}
                                                    ></LinkedObjectsDisplayField>
                                                )
                                            }
                                            {
                                                Object.keys(jobWalk).length !== 0 && !(estimate.job_walk === null || estimate.job_walk === undefined) && jobWalk.technician_notes && (
                                                    <BasicDisplayField
                                                        fieldName="technician_notes"
                                                        fieldLabel="Notes From Technician"
                                                        fieldValue={jobWalk.technician_notes}
                                                        indented={true}
                                                    ></BasicDisplayField>
                                                )
                                            }
                                            {
                                                showCustomEstimateIDField
                                                ?
                                                <CharField
                                                    fieldName="custom_id"
                                                    fieldLabel="Estimate ID"
                                                    fieldValue={estimate.custom_id || ""}
                                                    fieldOnChange={custom_id => onFormDataChange("custom_id", custom_id || "")}
                                                    maxLength="15"
                                                    errors={errors}
                                                ></CharField>
                                                :
                                                <div id="div_id_custom_id">{errors.custom_id && <Banner type="warning" text={errors.custom_id} extraMargin={true} />}</div>
                                            }
                                            <SearchCreateSelectField
                                                fieldName="service_name"
                                                fieldLabel="Service Type"
                                                fieldValue={selectedPriceBookService ? selectedPriceBookService.description : (estimate.service_name || null)}
                                                inputComponent={
                                                    <PriceBookServiceSearchOrCreateSelect
                                                        disabled={!allowClientDetailsEdit}
                                                        onSelectionChange={selectedOption => updatePriceBookServiceSelection(selectedOption != null ? selectedOption.object : null)}
                                                        onInputChange={(input, action) => {action.action === "input-change" && this.setState({serviceSearchInput: input})}}
                                                        defaultSelected={selectedPriceBookService ? priceBookServiceToPriceBookServiceOption(selectedPriceBookService) : (estimate.service_name ? {value: null, label: estimate.service_name} : null)}
                                                        showCreateButton={true}
                                                        onCreateClick={event => switchToSecondaryForm("ADD_PRICEBOOKITEM_SERVICE", null, {description: this.state.serviceSearchInput})}
                                                    ></PriceBookServiceSearchOrCreateSelect>
                                                }
                                                showButton={allowClientDetailsEdit && selectedPriceBookService !== null}
                                                buttonLabel={<Fragment><i className="fa-sharp fa-light fa-pen-to-square" aria-hidden="true"></i>Edit Service Type</Fragment>}
                                                buttonAction={event => switchToSecondaryForm("EDIT_PRICEBOOKITEM_SERVICE", selectedPriceBookService, null)}
                                                disabled={!allowClientDetailsEdit}
                                                errors={errors}
                                            ></SearchCreateSelectField>
                                            <TextField
                                                fieldName="details"
                                                fieldLabel="Reason For Call"
                                                fieldValue={estimate.details || ""}
                                                fieldOnChange={details => onFormDataChange("details", details || "")}
                                                rows={3}
                                                placeholder="Customer states..."
                                                errors={errors}
                                            ></TextField>
                                            <div className="data-panel__form__field" id="div_id_billing_address" aria-label="Billing Address">
                                                <label htmlFor="id_client" className="data-panel__form__field__label in-form">Billing Address</label>
                                                <div className="data-panel__form__field__input data-panel__form__field__input--with-checkbox">
                                                    <input type="checkbox" className="checkbox-input" name="use_billing_address" id="id_use_billing_address" checked={!useBillingAddress} onChange={event => requestAction("TOGGLE_USE_BILLING_ADDRESS")} />
                                                    <label className="checkbox-label" htmlFor="id_use_billing_address">Same as service location</label>
                                                </div>
                                                {
                                                    useBillingAddress && (
                                                        <AddressAutocompleteFieldGroup
                                                            key={`estimate_billing_address_${selectedClient.id}_${selectedServiceLocation.id}`}
                                                            fieldGroupName="billing_address"
                                                            fieldGroupLabel=""
                                                            fieldPrefix="billing_address"
                                                            fieldValues={{
                                                                "billing_address_recipient": estimate.billing_address_recipient || "",
                                                                "billing_address_street": estimate.billing_address_street || "",
                                                                "billing_address_unit": estimate.billing_address_unit || "",
                                                                "billing_address_city": estimate.billing_address_city || "",
                                                                "billing_address_state": estimate.billing_address_state || "",
                                                                "billing_address_postal": estimate.billing_address_postal || "",
                                                                "billing_address_country": estimate.billing_address_country || "",
                                                            }}
                                                            onFormDataChange={onFormDataChange}
                                                            includeRecipient={true}
                                                            errors={errors}
                                                        ></AddressAutocompleteFieldGroup>
                                                    )
                                                }
                                            </div>
                                            <DateField
                                                fieldName="date_issued"
                                                fieldLabel="Issue Date"
                                                fieldValue={estimate.date_issued || ""}
                                                fieldOnChange={date_issued => onFormDataChange("date_issued", date_issued || "")}
                                                errors={errors}
                                            ></DateField>
                                            <DateField
                                                fieldName="date_due"
                                                fieldLabel="Expiration Date"
                                                fieldValue={estimate.date_due || ""}
                                                fieldOnChange={date_due => onFormDataChange("date_due", date_due || "")}
                                                errors={errors}
                                            ></DateField>
                                            {
                                                window.ACCEPT_ONLINE_PAYMENTS && (
                                                    <BooleanField
                                                        fieldName="accept_online_payments"
                                                        fieldLabel="Payment Methods"
                                                        fieldValue={estimate.accept_online_payments}
                                                        fieldOnChange={accept_online_payments => onFormDataChange("accept_online_payments", accept_online_payments)}
                                                        inputLabel="Accept Online Card Payments"
                                                        errors={errors}
                                                    ></BooleanField>
                                                )
                                            }
                                        </fieldset>
                                    )
                                }
                                {
                                    selectedClient !== null && selectedServiceLocation !== null && foldDataComplete === true && (
                                        <fieldset>
                                            <legend>Charge Details</legend>
                                            <br />
                                            <LineItemListSelectFieldGroup
                                                object={estimate}
                                                labelPrefix={"Estimated "}
                                                formatCurrencyValue={formatCurrencyValue}
                                                showQuickBooksItemSelect={showQuickBooksItemSelect}
                                                useTaxes={useTaxes}
                                                switchToSecondaryForm={switchToSecondaryForm}
                                                errors={errors}
                                                nonFieldErrorMessage={errors.line_items || "There's an issue with one or more of the items below. Try coming back to this page and trying again in a bit."}
                                                isEstimateLineItem={true}
                                            ></LineItemListSelectFieldGroup>
                                            {
                                                useTaxes && !hideTaxRates ? (
                                                    <SearchCreateSelectField
                                                        key={estimate.tax_name}
                                                        fieldName="tax_name"
                                                        fieldLabel="Tax Rate"
                                                        fieldValue={selectedPriceBookTax ? selectedPriceBookTax.description : (estimate.tax_name || null)}
                                                        inputComponent={
                                                            <PriceBookTaxSearchOrCreateSelect
                                                                onSelectionChange={selectedOption => updatePriceBookTaxSelection(selectedOption != null ? selectedOption.object : null)}
                                                                onInputChange={(input, action) => {action.action === "input-change" && this.setState({taxSearchInput: input})}}
                                                                defaultSelected={
                                                                    selectedPriceBookTax
                                                                    ?
                                                                    priceBookTaxToPriceBookTaxOption(selectedPriceBookTax)
                                                                    :
                                                                    (estimate.tax_name ? { value: null, label: getPriceBookTaxObjectLabel({ description: estimate.tax_name, default_price: estimate.tax_percent})} : null)
                                                                }
                                                                showCreateButton={showTaxCreateButton}
                                                                limitToQuickBooksSyncedItems={!showTaxCreateButton}
                                                                onCreateClick={event => switchToSecondaryForm("ADD_PRICEBOOKITEM_TAX", null, {description: this.state.taxSearchInput})}
                                                            />
                                                        }
                                                        showButton={selectedPriceBookTax !== null}
                                                        buttonLabel={<Fragment><i className="fa-sharp fa-light fa-pen-to-square" aria-hidden="true"></i>Edit Tax Rate</Fragment>}
                                                        buttonAction={event => switchToSecondaryForm("EDIT_PRICEBOOKITEM_TAX", selectedPriceBookTax, null)}
                                                        errors={errors}
                                                    />
                                                )
                                                :
                                                (
                                                    window.CURRENT_USER.service_company.apideck_tax_strategy === APIDeckTaxStrategies.automatic
                                                    ?
                                                    <>
                                                        <BasicDisplayField
                                                            fieldName="tax_name"
                                                            fieldLabel="Tax Rate"
                                                            fieldValue={estimate.tax_name ? getPriceBookTaxObjectLabel({ description: estimate.tax_name, default_price: estimate.tax_percent}) : ""}
                                                        />
                                                        <Banner type="info" text="Automatic Tax Rate calculation isn't supported on estimates." extraMargin={true} />
                                                    </>
                                                    :
                                                    <></>
                                                )
                                            }
                                            <TextField
                                                fieldName="comments"
                                                fieldLabel="Comments"
                                                fieldValue={estimate.comments || ""}
                                                fieldOnChange={comments => onFormDataChange("comments", comments || "")}
                                                rows={3}
                                                placeholder="Any comments to add to the estimate."
                                                optional={true}
                                                errors={errors}
                                            ></TextField>
                                            {
                                                window.FILE_UPLOADS_ENABLED && (
                                                    <AttachmentUploadField
                                                        fieldName="attachments"
                                                        fieldLabel="Attachments"
                                                        attachments={estimate.attachments}
                                                        fileStackAPIKey={fileStackAPIKey}
                                                        fileStackPolicy={fileStackPolicy}
                                                        fileStackSignature={fileStackSignature}
                                                        onUploadDone={(response) => updateAttachments(
                                                            response.filesUploaded.map(file => ({
                                                                "upload_filename": file.filename,
                                                                "upload_id": file.uploadId,
                                                                "upload_handle": file.handle,
                                                                "upload_url": file.url,
                                                                "upload_mimetype": file.mimetype,
                                                                "upload_size": file.size,
                                                                "upload_source": file.source
                                                            }))
                                                        )}
                                                        optional={true}
                                                        optionalText={"if any"}
                                                        errors={errors}
                                                    />
                                                )
                                            }
                                        </fieldset>
                                    )
                                }
                            </div>
                            {
                                selectedClient !== null && selectedServiceLocation !== null && foldDataComplete === true && (
                                    <div className="data-panel__form data-panel__data-summary amount-summary" aria-label="Amount Summary">
                                        <hr aria-hidden="true" />
                                        <div className="data-panel__data-summary__row amount-summary__item amount-summary__item--subtotal" aria-label="Estimated Subtotal">
                                            <div className="data-panel__data-summary__data__label"><span>Estimated Subtotal</span></div>
                                            <div className="data-panel__data-summary__data__value">{formatCurrencyValue(subtotal)}</div>
                                        </div>
                                        {
                                            useTaxes && (
                                                <div className="data-panel__data-summary__row amount-summary__item amount-summary__item--tax" aria-label="Estimated Tax">
                                                    {
                                                        hideTaxRates
                                                        ?
                                                        <div className="data-panel__data-summary__data__label"><span title={estimate.tax_breakdown?.map(component => `[${component.rate}%] ${component.name}`).join("\n")}>Estimated Tax ({estimate.tax_percent ? `${estimate.tax_percent}%` : "none"})</span></div>
                                                        :
                                                        <div className="data-panel__data-summary__data__label"><span>Estimated Tax ({selectedPriceBookTax ? `${selectedPriceBookTax.default_price}%` : "none"})</span></div>
                                                    }
                                                    <div className="data-panel__data-summary__data__value">{formatCurrencyValue(tax)}</div>
                                                </div>
                                            )
                                        }
                                        <div className="data-panel__data-summary__row amount-summary__item amount-summary__item--down-payment">
                                            <div className="data-panel__data-summary__data__label">
                                                <div className="data-panel__form__field data-panel__form__field--inline" id="div_id_down_payment_amount" aria-label="Required Down Payment">
                                                    <label htmlFor="id_tax_down_payment" className="data-panel__form__field__label in-form">Required Down Payment <span className="text-optional">(optional)</span></label>
                                                    <div className="data-panel__form__field__input data-panel__form__field__input--tiny">
                                                        <div className="data-panel__form__field__input-group">
                                                            <div className="input-group-prepend">
                                                                <div className="input-group-text">{currencySymbol}</div>
                                                            </div>
                                                            <input type="number" min="0" step="0.5" name="down_payment_amount" defaultValue={estimate.down_payment_amount || 0} onChange={event => onFormDataChange(event.target.name, event.target.value)} id="id_down_payment_amount" autoComplete="off"></input>
                                                        </div>
                                                    </div>
                                                    {errors.down_payment_amount && <div className="data-panel__form__field__errors" aria-label="Field Errors">{errors.down_payment_amount}</div>}
                                                </div>
                                            </div>
                                        </div>
                                        <hr aria-hidden="true" />
                                        <div className="data-panel__data-summary__row amount-summary__item amount-summary__item--total" aria-label="Estimated Total">
                                            <div className="data-panel__data-summary__data__label"><span>Estimated Total</span></div>
                                            <div className="data-panel__data-summary__data__value">{formatCurrencyValue(total)}</div>
                                        </div>
                                        {
                                            this.estimateIsSeen(estimate) && (
                                                <Banner type="warning" text="The latest version of this estimate has been seen. We recommend resending it to your client with a message summarizing the revisions you've made." />
                                            )
                                        }
                                    </div>
                                )
                            }
                            {
                                selectedClient !== null && selectedServiceLocation !== null && foldDataComplete === true && this.renderButtons()
                            }
                        </div>
                    </div>
                </div>
                {
                    valueIsDefined(estimate.id) && (
                        <div className="accordion-wrapper">
                            <EstimateFeedAccordion estimateID={estimate.id} />
                        </div>
                    )
                }
            </Fragment>
        )
    }

}

export default EstimateForm;
