import { useEffect, useRef, useState } from "react"
import Cropper, { Area } from "react-easy-crop"

import useIsDesktop from "@hooks/useIsDesktop"

import { getCroppedImg } from "@utils/imageCrop"

import { AlertBanner, Button } from "@atoms/index"

import HiddenImageInput from "@molecules/Form/inputs/HiddenImageInput/HiddenImageInput"
import { HiddenImageInputRef, ImageDimensions } from "@molecules/Form/inputs/HiddenImageInput/HiddenImageInput.types"
import toast from "@molecules/Toast/Toast"
import { ResponsiveDialog, Slider } from "@molecules/index"

import { useAvatarManagement } from "@pages/Settings/SettingsProfile/components/AvatarManagement/AvatarManagement.store"
import { AvatarFormatType } from "@pages/Settings/SettingsProfile/components/AvatarManagement/AvatarManagement.types"
import { validateImageProperties } from "@pages/Settings/SettingsProfile/components/AvatarManagement/AvatarManagement.utils"

import styles from "./AvatarCropDialog.module.scss"
import { AvatarCropDialogProps } from "./AvatarCropDialog.types"

export default function AvatarCropDialog(props: AvatarCropDialogProps) {
    const { children } = props

    const fileInputRef = useRef<HiddenImageInputRef>(null)

    const {
        imageToCrop,
        imageFormatType,
        isUploadingImage,
        setImageToCrop,
        setDisplayedAvatar,
        setImageFormatType,
        updateAvatarMutation,
        isDialogOpen,
        setIsDialogOpen,
    } = useAvatarManagement()

    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState<number>(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>(null)

    // this state is a workaround that fixes this issue: https://linear.app/roopairs/issue/ROO-2592/image-adjust-crop-circle-padding-issues
    const [isCropperShown, setIsCropperShown] = useState<boolean>(false)

    const isDesktop = useIsDesktop()

    useEffect(() => {
        const CROPPER_SHOWN_TIME_MS = 100

        if (isDialogOpen) {
            setTimeout(() => {
                setIsCropperShown(true)
            }, CROPPER_SHOWN_TIME_MS)
        } else {
            setIsCropperShown(false)
        }
    }, [isDialogOpen])

    useEffect(() => {
        if (!isDialogOpen) {
            setCrop({ x: 0, y: 0 })
            setZoom(1)
        }
    }, [isDialogOpen])

    const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }

    const handleReplaceImage = () => {
        fileInputRef.current?.selectImageFromOS()
    }

    const onImageReplaced = (image: File, imageDimensions: ImageDimensions) => {
        const fileFormat = image.type as AvatarFormatType

        const validationResult = validateImageProperties(image, imageDimensions)

        if (validationResult.valid) {
            setImageFormatType(fileFormat)

            setImageToCrop(URL.createObjectURL(image))
        } else {
            toast({
                type: "error",
                size: "md",
                title: validationResult.message,
            })
        }
    }

    const saveCroppedImage = async () => {
        try {
            const croppedImage = await getCroppedImg({
                imageSrc: imageToCrop,
                pixelCrop: croppedAreaPixels,
                imageFormatType,
            })

            void fetch(croppedImage)
                .then((res) => res.blob())
                .then((blob) => {
                    const imageFile = new File([blob], `filename.${blob.type.split("/")[1]}`, {
                        type: blob.type,
                    })

                    setDisplayedAvatar(croppedImage)

                    updateAvatarMutation({
                        avatar: imageFile,
                    })

                    setIsDialogOpen(false)
                })
        } catch (e) {
            toast({
                type: "error",
                size: "md",
                title: "Could not upload image",
            })
        }
    }

    return (
        <ResponsiveDialog.Root
            headerTitle="Adjust image"
            trigger={children}
            modalClassName={styles.modal}
            isOpen={isDialogOpen}
            onOpenChange={setIsDialogOpen}
        >
            <ResponsiveDialog.Body>
                <HiddenImageInput onFileChange={onImageReplaced} ref={fileInputRef} />
                <AlertBanner
                    fullWidth={true}
                    size="sm"
                    title="The avatar is visible to workspace members and in public contexts."
                    type="info"
                    className={styles.alertBanner}
                />
                <div className={styles.cropperArea} data-no-drag={true}>
                    {isCropperShown && (
                        <Cropper
                            image={imageToCrop}
                            crop={crop}
                            zoom={zoom}
                            aspect={1}
                            onCropChange={setCrop}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                            cropShape="round"
                            minZoom={1}
                            maxZoom={3}
                            objectFit="contain"
                            showGrid={false}
                            restrictPosition={true}
                            zoomWithScroll={false}
                        />
                    )}
                </div>

                {isDesktop && (
                    <div className={styles.zoomWrap}>
                        <span className={styles.zoomTitle}>Zoom</span>
                        <Slider
                            value={[zoom]}
                            min={1}
                            max={3}
                            step={0.1}
                            aria-labelledby="Zoom"
                            onValueChange={(value) => setZoom(value[0])}
                        />
                    </div>
                )}
            </ResponsiveDialog.Body>
            <ResponsiveDialog.Footer>
                <Button colorScheme="gray" variant="subtle" size="md" onClick={handleReplaceImage}>
                    Change image
                </Button>

                <Button
                    colorScheme="gray"
                    variant="solid"
                    size="md"
                    onClick={saveCroppedImage as () => void}
                    isLoading={isUploadingImage}
                    loadingText="Saving"
                >
                    Save
                </Button>
            </ResponsiveDialog.Footer>
        </ResponsiveDialog.Root>
    )
}
