import clsx from "clsx"
import { useEffect, useRef, useState } from "react"

import useIsDesktop from "@hooks/useIsDesktop"
import useIsScrolling from "@hooks/useIsScrolling"

import TabStack from "@molecules/TabStack/TabStack"

import styles from "./TableViewTabs.module.scss"
import { TableViewTabsProps } from "./TableViewTabs.types"

export default function TableViewTabs(props: TableViewTabsProps) {
    const { children } = props

    const isDesktop = useIsDesktop()

    const baseRef = useRef<HTMLDivElement>(null)

    const {
        ref: scrollRef,
        isScrolling,
        isRightContentClipped,
        isLeftContentClipped,
    } = useIsScrolling<HTMLDivElement>({
        inHorizontal: true,
    })

    const [contentOverflows, setContentOverflows] = useState(false)

    useEffect(() => {
        const onResize = () => {
            setContentOverflows(scrollRef.current?.scrollWidth > baseRef.current?.clientWidth)
        }

        const resizeObserver = new ResizeObserver(onResize)

        if (scrollRef.current) {
            resizeObserver.observe(scrollRef.current)
        }

        return () => {
            if (scrollRef.current) {
                resizeObserver.unobserve(scrollRef.current)
            }
        }
    }, [scrollRef.current])

    const onWheel = (event: React.WheelEvent<HTMLDivElement>) => {
        if (event.shiftKey) {
            return
        }

        event.preventDefault()
        scrollRef.current.scrollLeft += event.deltaY
    }

    return (
        <div
            ref={baseRef}
            className={clsx(styles.base, {
                [styles.leftContentClipped]: isLeftContentClipped,
                [styles.rightContentClipped]:
                    (isRightContentClipped && contentOverflows) || (contentOverflows && !isScrolling),
            })}
        >
            <div className={styles.scroll} ref={scrollRef} onWheel={onWheel}>
                <TabStack attached={false} size={isDesktop ? "sm" : "md"}>
                    {children}
                </TabStack>
            </div>
        </div>
    )
}
