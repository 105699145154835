// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Auth-form__qs1vL{display:flex;flex-direction:column;gap:var(--size-6);align-items:stretch;padding-top:var(--size-8)}@media only screen and (min-width: 64rem){.Auth-form__qs1vL{gap:var(--size-4);padding-top:var(--size-6)}}.Auth-link__ODjNZ{align-self:flex-end;justify-self:flex-end;color:var(--gray-a11);text-align:right;cursor:pointer;font:var(--font-global-buttons-and-links-medium-500);font-feature-settings:var(--feature-settings-global-buttons-and-links-medium-500);letter-spacing:var(--letter-spacing-global-buttons-and-links-medium-500)}@media only screen and (min-width: 64rem){.Auth-link__ODjNZ{font:var(--font-global-buttons-and-links-small-500);font-feature-settings:var(--feature-settings-global-buttons-and-links-small-500);letter-spacing:var(--letter-spacing-global-buttons-and-links-small-500)}}.Auth-centeredLink__bC02_{align-self:center;justify-self:center;text-align:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "Auth-form__qs1vL",
	"link": "Auth-link__ODjNZ",
	"centeredLink": "Auth-centeredLink__bC02_"
};
export default ___CSS_LOADER_EXPORT___;
