import useIsDesktop from "@hooks/useIsDesktop"

import { ButtonProps } from "@atoms/Button/Button.types"
import { Button, CountDown } from "@atoms/index"

import useOTPForm from "@organisms/OTPForm/OTPForm.store"

export default function OTPSendCodeButton(props: Partial<ButtonProps>) {
    const isDesktop = useIsDesktop()

    const { handleSendCode, isVerifyingCode, isRequestingCode, isCodeResendBlocked, setIsCodeResendBlocked } =
        useOTPForm()

    const onCountDownEnds = () => setIsCodeResendBlocked(false)

    return (
        <Button
            colorScheme="gray"
            variant="solid"
            size={isDesktop ? "md" : "lg"}
            onClick={handleSendCode}
            isLoading={isRequestingCode}
            isDisabled={isCodeResendBlocked || isVerifyingCode}
            loadingText="Sending code"
            isFullWidth={true}
            {...props}
        >
            Resend code{" "}
            {isCodeResendBlocked && (
                <>
                    in <CountDown onExpire={onCountDownEnds} seconds={30} />
                </>
            )}
        </Button>
    )
}
