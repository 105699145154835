// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NotificationHeader-base__UF7rt{display:flex;gap:var(--size-2);align-items:center;justify-content:space-between;margin-right:calc(var(--size-3)*-1);margin-left:calc(var(--size-3)*-1);padding:var(--size-2_5) var(--size-3);background:var(--gray-a3);border-radius:var(--border-radius-2)}.NotificationHeader-text__Vds9p{color:var(--gray-a11);font:var(--font-global-body-375);font-feature-settings:var(--feature-settings-global-body-375);letter-spacing:var(--letter-spacing-global-body-375)}.NotificationHeader-notificationMethods__MKJHZ{display:flex;gap:var(--size-4);align-items:center;justify-content:flex-end}@media only screen and (min-width: 64rem){.NotificationHeader-notificationMethods__MKJHZ{gap:var(--size-3_5)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "NotificationHeader-base__UF7rt",
	"text": "NotificationHeader-text__Vds9p",
	"notificationMethods": "NotificationHeader-notificationMethods__MKJHZ"
};
export default ___CSS_LOADER_EXPORT___;
