// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BottomSheetDivider-base__ubo1l{width:var(--size-full);padding:var(--size-0) var(--size-2_5)}.BottomSheetDivider-fullWidth__OLcyx{padding-right:var(--size-0);padding-left:var(--size-0)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "BottomSheetDivider-base__ubo1l",
	"fullWidth": "BottomSheetDivider-fullWidth__OLcyx"
};
export default ___CSS_LOADER_EXPORT___;
