import useUser from "@hooks/useUser"

import replaceSlugs from "@utils/replaceSlugs"

import { IconName } from "@molecules/ActivatableIcon/ActivatableIcon.types"

import { CALENDAR_ROUTE } from "@routes/calendar"
import { CLIENTS_ROUTES } from "@routes/clients"
import { ESTIMATES_ROUTES } from "@routes/estimates"
import { HOME_ROUTE } from "@routes/home"
import { INVOICES_ROUTES } from "@routes/invoices"
import { JOBS_ROUTES } from "@routes/jobs"
import { PRICEBOOK_ITEMS_ROUTES } from "@routes/pricebook-items"
import { PURCHASE_ORDER_ROUTES } from "@routes/purchase-orders"
import { REPORTS_ROUTE } from "@routes/reports"
import { VENDOR_ROUTES } from "@routes/vendor"

import { APIDeckTaxStrategies, AccountingIntegrations } from "@constants/choices"

export type NavigationLink = {
    module?: IconName
    label: string
    path: string
    subLinks?: NavigationLink[]
    predicate?: (user: User) => boolean
}

export default function useNavigationLinks() {
    const { user } = useUser()

    const slug = { service_company_slug: user?.service_company.slug }
    const isUsingTaxRates =
        user?.service_company.use_taxes &&
        !(
            user?.service_company.accounting_integration === AccountingIntegrations.apideck &&
            user?.service_company.apideck_tax_strategy !== APIDeckTaxStrategies.normal
        )

    const routes = {
        home: replaceSlugs(HOME_ROUTE, slug),
        calendar: replaceSlugs(CALENDAR_ROUTE, slug),
        clients: replaceSlugs(CLIENTS_ROUTES.LIST, slug),
        estimates: replaceSlugs(ESTIMATES_ROUTES.LIST, slug),
        purchaseOrders: replaceSlugs(PURCHASE_ORDER_ROUTES.LIST, slug),
        vendors: replaceSlugs(VENDOR_ROUTES.LIST, slug),
        invoices: replaceSlugs(INVOICES_ROUTES.LIST, slug),
        jobs: replaceSlugs(JOBS_ROUTES.LIST, slug),
        pricebook: {
            services: replaceSlugs(PRICEBOOK_ITEMS_ROUTES.SERVICE.LIST, slug),
            partsOrMaterials: replaceSlugs(PRICEBOOK_ITEMS_ROUTES.PART_OR_MATERIAL.LIST, slug),
            miscellaneous: replaceSlugs(PRICEBOOK_ITEMS_ROUTES.MISCELLANEOUS.LIST, slug),
            discounts: replaceSlugs(PRICEBOOK_ITEMS_ROUTES.DISCOUNT.LIST, slug),
            tax_rates: isUsingTaxRates ? replaceSlugs(PRICEBOOK_ITEMS_ROUTES.TAX_RATES.LIST, slug) : undefined,
        },
        reports: replaceSlugs(REPORTS_ROUTE, slug),
    }

    const home: NavigationLink = {
        module: "home",
        path: routes.home,
        label: "Home",
    }

    const calendar: NavigationLink = {
        module: "calendar",
        path: routes.calendar,
        label: "Calendar",
        predicate: (user: User) => user?.isServiceDispatcher,
    }

    const clients: NavigationLink = {
        module: "clients",
        path: routes.clients,
        label: "Clients",
        predicate: (user: User) => user?.isServiceDispatcher,
    }

    const estimates: NavigationLink = {
        module: "estimates",
        path: routes.estimates,
        label: "Estimates",
        predicate: (user: User) => user?.isServiceDispatcher,
    }

    const jobs: NavigationLink = {
        module: "jobs",
        path: routes.jobs,
        label: "Jobs",
    }

    const invoices: NavigationLink = {
        module: "invoices",
        path: routes.invoices,
        label: "Invoices",
        predicate: (user: User) => user?.isServiceDispatcher,
    }

    const purchase_orders: NavigationLink = {
        module: "purchase_orders",
        path: routes.purchaseOrders,
        label: "Purchase Orders",
        predicate: (user: User) => user?.isServiceDispatcher,
    }

    const vendors: NavigationLink = {
        module: "vendors",
        path: routes.vendors,
        label: "Vendors",
        predicate: (user: User) => user?.isServiceDispatcher,
    }

    const pricebook: NavigationLink = {
        module: "pricebook",
        path: null,
        label: "Pricebook",
        predicate: (user: User) => user?.isServiceDispatcher,
        subLinks: [
            {
                label: "Services",
                path: routes.pricebook.services,
            },
            {
                label: "Parts & Materials",
                path: routes.pricebook.partsOrMaterials,
            },
            {
                label: "Miscellaneous",
                path: routes.pricebook.miscellaneous,
            },
            {
                label: "Discounts",
                path: routes.pricebook.discounts,
            },
            ...(isUsingTaxRates
                ? [
                      {
                          label: "Tax Rates",
                          path: routes.pricebook.tax_rates,
                      },
                  ]
                : []),
        ],
    }

    const reports: NavigationLink = {
        module: "reports",
        path: routes.reports,
        label: "Reports",
        predicate: (user: User) => user?.isServiceDispatcher && user?.service_company.vizzly_dashboard_id !== "",
    }

    return {
        home,
        calendar,
        clients,
        estimates,
        jobs,
        invoices,
        purchase_orders,
        vendors,
        pricebook,
        reports,
    }
}
