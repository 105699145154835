import { JobStatuses } from "@legacy/core/utils/enums"

import useIsDesktop from "@hooks/useIsDesktop"

import { EventRecord } from "@organisms/ObjectsView/JobTimelineView/JobTimelineView.types"

import useJobTimelineViewBryntumInstances from "./useJobTimelineViewBryntumInstances"

export default function useJobTimelineViewUIUtils() {
    const { schedulerPro } = useJobTimelineViewBryntumInstances()
    const isDesktop = useIsDesktop()

    const hideCancelledJobsFromScheduler = () => {
        const scheduler = schedulerPro.current.instance

        void scheduler.eventStore.filter({
            id: "cancelledJobsFilter",
            filterBy: (event: EventRecord) => {
                return event.status !== JobStatuses.cancelled
            },
        })
    }

    const showCancelledJobsInScheduler = () => {
        const scheduler = schedulerPro.current.instance

        void scheduler.eventStore.removeFilter("cancelledJobsFilter")
    }

    const hideFinalizedJobsFromScheduler = () => {
        const scheduler = schedulerPro.current?.instance

        void scheduler.eventStore.filter({
            id: "finalizedJobsFilter",
            filterBy: (event: EventRecord) => {
                return event.status !== JobStatuses.finalized
            },
        })
    }

    const showFinalizedJobsInScheduler = () => {
        const scheduler = schedulerPro.current.instance

        void scheduler.eventStore.removeFilter("finalizedJobsFilter")
    }

    const hideEventFeedback = () => {
        const eventAddFeedback: HTMLElement = document.querySelector(".jsJobTimelineViewCreateJobIndicator")

        if (eventAddFeedback) {
            eventAddFeedback.style.display = "none"
        }
    }

    const showEventFeedback = () => {
        const isResizing = schedulerPro.current.instance.features.eventResize.isResizing
        const isDragging = schedulerPro.current.instance.features.eventDrag.isDragging

        if (isResizing || isDragging || !isDesktop) {
            return hideEventFeedback()
        }

        const eventAddFeedback: HTMLElement = document.querySelector(".jsJobTimelineViewCreateJobIndicator")

        if (eventAddFeedback) {
            eventAddFeedback.style.display = "flex"
        }
    }

    const hideEventMoveFeedback = () => {
        const eventAddFeedback: HTMLElement = document.querySelector(".jsBryntumTimelineViewMoveFeedback")

        if (eventAddFeedback) {
            eventAddFeedback.classList.remove("job-timeline-view__job-moving-indicator--visible")
        }
    }

    const showEventMoveFeedback = ({
        isOverlapping,
        startDate,
        endDate,
        resourceId,
    }: {
        isOverlapping: boolean
        startDate: Date
        endDate: Date
        resourceId: string | number
    }) => {
        const resourceTimeRangeStore = schedulerPro.current.instance.resourceTimeRangeStore as {
            getById(id: string | number): {
                id: string | number
                resourceId: string | number
                startDate: Date
                endDate: Date
                name: string
                cls: string
                setStartEndDate(startDate: Date, endDate: Date): void
            }
            add: (timeRange: {
                id: string | number
                resourceId: string | number
                startDate: Date
                endDate: Date
                name: string
                cls: string
            }) => void
        }

        const targetedTimeRange = resourceTimeRangeStore.getById("job-move-feedback")

        targetedTimeRange.setStartEndDate(startDate, endDate)
        targetedTimeRange.resourceId = resourceId

        const eventAddFeedback: HTMLElement = document.querySelector(".jsBryntumTimelineViewMoveFeedback")

        eventAddFeedback?.classList?.add("job-timeline-view__job-moving-indicator--visible")

        if (eventAddFeedback && isOverlapping) {
            eventAddFeedback?.classList?.add("job-timeline-view__job-moving-indicator--overlapping")
        } else {
            eventAddFeedback?.classList?.remove("job-timeline-view__job-moving-indicator--overlapping")
        }
    }

    return {
        hideCancelledJobsFromScheduler,
        showCancelledJobsInScheduler,
        hideFinalizedJobsFromScheduler,
        showFinalizedJobsInScheduler,
        hideEventFeedback,
        showEventFeedback,
        hideEventMoveFeedback,
        showEventMoveFeedback,
    }
}
