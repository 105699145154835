import { useEffect, useMemo, useRef } from "react"

import useUser from "@hooks/useUser"

import toast from "@molecules/Toast/Toast"

import TopBar from "@organisms/TopBar/TopBar"

import { JobTimelineViewSchedulerWrapperProps } from "./JobTimelineView.types"
import JobTimelineViewControls from "./JobTimelineViewControls/JobTimelineViewControls"
import useJobTimelineViewBryntumInstances from "./hooks/useJobTimelineViewBryntumInstances"
import useJobTimelineViewData from "./hooks/useJobTimelineViewData"
import useJobTimelineViewNavigation from "./hooks/useJobTimelineViewNavigation"
import useJobTimelineViewSchedulerConfig from "./hooks/useJobTimelineViewSchedulerConfig"
import useJobTimelineViewSchedulerRefresh from "./hooks/useJobTimelineViewSchedulerRefresh"
import useJobTimelineViewStates from "./hooks/useJobTimelineViewStates"

export default function JobTimelineViewSchedulerWrapper(props: JobTimelineViewSchedulerWrapperProps) {
    const { children } = props

    const { isSchedulerConfigured, setIsSchedulerConfigured } = useJobTimelineViewStates()

    const { configureAndPopulateScheduler } = useJobTimelineViewData()

    const { configureSchedulerTimezone, configureSchedulerDates } = useJobTimelineViewSchedulerConfig()

    const {
        calendarData,
        isFetchingCalendarData,
        availabilitySchedulesData,
        isFetchingAvailabilitySchedulesData,
        isAvailabilitySchedulesError,
        isCalendarDataError,
    } = useJobTimelineViewData()

    const { startRefreshRowsInterval } = useJobTimelineViewSchedulerRefresh()

    const { user } = useUser()

    const { schedulerPro } = useJobTimelineViewBryntumInstances()

    const { currentDate } = useJobTimelineViewStates()

    const { handleScrollPosition } = useJobTimelineViewNavigation()

    const previousDate = useRef(currentDate)

    useEffect(() => {
        const interval = startRefreshRowsInterval()

        return () => {
            clearInterval(interval)
        }
    }, [])

    useEffect(() => {
        void configureSchedulerTimezone().then(() => {
            configureSchedulerDates()
        })
    }, [])

    const hasDateChanged = useMemo(() => {
        if (previousDate.current === undefined) {
            return false
        } else {
            return currentDate.toISOString() !== previousDate.current.toISOString()
        }
    }, [currentDate, previousDate.current])

    useEffect(() => {
        const dataIsLoaded =
            calendarData !== undefined &&
            availabilitySchedulesData !== undefined &&
            !isFetchingCalendarData &&
            !isFetchingAvailabilitySchedulesData

        if (user && schedulerPro.current && dataIsLoaded && !isSchedulerConfigured) {
            configureAndPopulateScheduler()
            setIsSchedulerConfigured(true)
        }
    }, [
        user,
        calendarData,
        availabilitySchedulesData,
        isFetchingCalendarData,
        isFetchingAvailabilitySchedulesData,
        schedulerPro.current?.instance,
    ])

    useEffect(() => {
        const dataIsLoaded =
            calendarData !== undefined &&
            availabilitySchedulesData !== undefined &&
            !isFetchingCalendarData &&
            !isFetchingAvailabilitySchedulesData

        if (user && schedulerPro.current && dataIsLoaded && hasDateChanged) {
            configureAndPopulateScheduler()

            previousDate.current = currentDate

            handleScrollPosition()
        }
    }, [
        currentDate,
        availabilitySchedulesData,
        isFetchingCalendarData,
        isFetchingAvailabilitySchedulesData,
        schedulerPro.current?.instance,
    ])

    useEffect(() => {
        if (isCalendarDataError) {
            toast({
                type: "error",
                size: "md",
                title: "Could not load jobs",
            })
        }
    }, [isCalendarDataError])

    useEffect(() => {
        if (isAvailabilitySchedulesError) {
            toast({
                type: "error",
                size: "md",
                title: "Could not load technicians",
            })
        }
    }, [isAvailabilitySchedulesError])

    useEffect(() => {
        if (isSchedulerConfigured) {
            handleScrollPosition()
        }
    }, [isSchedulerConfigured])

    return (
        <>
            <TopBar.Portal>
                <JobTimelineViewControls />
            </TopBar.Portal>

            {children}
        </>
    )
}
