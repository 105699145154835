import styles from "./NotificationConfigPage.module.scss"
import { NotificationConfigPageProps } from "./NotificationConfigPage.types"

function Wrapper(props: NotificationConfigPageProps) {
    const { children } = props

    return <div className={styles.wrapper}>{children}</div>
}

function Checkboxes(props: NotificationConfigPageProps) {
    const { children } = props

    return <div className={styles.checkboxes}>{children}</div>
}

export default { Checkboxes, Wrapper }
