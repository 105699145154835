// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TopBarHeaderStart-base__d7NLz{display:flex;gap:var(--size-1);align-items:center;min-width:var(--size-0);max-width:var(--size-full)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "TopBarHeaderStart-base__d7NLz"
};
export default ___CSS_LOADER_EXPORT___;
