import ReactModal from 'react-modal';


export default function Modal(props) {
    const { isOpen=false, content, buttons } = props

    ReactModal.setAppElement('body')

    return (
        <ReactModal
            isOpen={isOpen}
            contentLabel=""
            defaultStyles={{content: null, overlay: null}}
        >
            <div className="ReactModal__Content">
                {content}
            </div>
            <div className="modal__close">
                {buttons}
            </div>
        </ReactModal>
    )
}
