// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UserAvatarStack-base__XKiAy{display:flex;align-items:center;justify-content:flex-start}.UserAvatarStack-stack__hrUiL{display:grid;flex-direction:column;grid-template-columns:auto 1fr;gap:var(--size-2);align-items:center;width:var(--size-full)}.UserAvatarStack-inline__cgH8K{flex-direction:row}.UserAvatarStack-avatarName__bel0z{overflow:hidden;color:var(--gray-12);white-space:nowrap;text-overflow:ellipsis;font:var(--font-global-caption-medium-375);font-feature-settings:var(--feature-settings-global-caption-medium-375);letter-spacing:var(--letter-spacing-global-caption-medium-375)}.UserAvatarStack-avatarName__bel0z.UserAvatarStack-unknown__GTDPy{color:var(--gray-9)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "UserAvatarStack-base__XKiAy",
	"stack": "UserAvatarStack-stack__hrUiL",
	"inline": "UserAvatarStack-inline__cgH8K",
	"avatarName": "UserAvatarStack-avatarName__bel0z",
	"unknown": "UserAvatarStack-unknown__GTDPy"
};
export default ___CSS_LOADER_EXPORT___;
