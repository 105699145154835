// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Overlay-base__kFJQ7{position:fixed;top:var(--size-0);right:var(--size-0);bottom:var(--size-0);left:var(--size-0);z-index:var(--z-index-backdrop);background-color:var(--pure-black-a9);opacity:var(--size-0);transition:opacity var(--duration-normal) ease-in-out;pointer-events:none}.Overlay-base__kFJQ7.Overlay-transparent__pekyN{background-color:var(--black-a0)}.Overlay-active__TqIZf{opacity:1;pointer-events:auto}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "Overlay-base__kFJQ7",
	"transparent": "Overlay-transparent__pekyN",
	"active": "Overlay-active__TqIZf"
};
export default ___CSS_LOADER_EXPORT___;
