// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TopBarViewSelector-base__VnrX0{display:flex;gap:var(--size-2);align-items:center}@media only screen and (min-width: 64rem){.TopBarViewSelector-droplist__Nnkim{min-width:var(--size-36)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "TopBarViewSelector-base__VnrX0",
	"droplist": "TopBarViewSelector-droplist__Nnkim"
};
export default ___CSS_LOADER_EXPORT___;
