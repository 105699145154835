import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"

import useIsDesktop from "@hooks/useIsDesktop"

import { Button, ListInfoGlyph } from "@atoms"

import styles from "@organisms/Table/TableEdgeStates/TableEdgeStates.module.scss"
import TableLoadingState from "@organisms/Table/TableEdgeStates/TableLoadingState/TableLoadingState"

import { TableErrorStateProps } from "./TableErrorState.types"

const DESKTOP_LOADING_ROWS_COUNT = 4
const MOBILE_LOADING_ROWS_COUNT = 2

export default function TableErrorState(props: TableErrorStateProps) {
    const { error, refetchObjects } = props

    const isDesktop = useIsDesktop()

    return (
        <div className={styles.base}>
            <TableLoadingState
                {...props}
                isAnimated={false}
                isBackground={true}
                numberOfRows={isDesktop ? DESKTOP_LOADING_ROWS_COUNT : MOBILE_LOADING_ROWS_COUNT}
            />
            <div className={styles.content}>
                <div className={styles.copyWrap}>
                    <ListInfoGlyph variant="dangerous" icon={icon({ name: "bug", style: "solid", family: "sharp" })} />
                    <div className={styles.copy}>
                        <h2 className={styles.title}>Hmm, something went wrong...</h2>
                        <p className={styles.explanation}>{error?.message}</p>
                    </div>
                </div>

                <Button colorScheme="gray" size="md" variant="outline" onClick={() => void refetchObjects()}>
                    Try Again
                </Button>
            </div>
        </div>
    )
}
