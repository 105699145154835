import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"

import useUser from "@hooks/useUser"

import formatDate from "@utils/formatDate"

import { Icon } from "@atoms"

import { Table } from "@organisms"

import styles from "./SeenCell.module.scss"
import { SeenCellProps } from "./SeenCell.types"

export default function SeenCell(props: SeenCellProps) {
    const { value } = props

    const { user } = useUser()

    if (!value) {
        return <Table.Cells.NoValueCell />
    }

    const { localDateTimeString } = formatDate({
        datetime: value,
        preferredTimezone: user?.service_company.preferred_timezone,
        addDay: true,
        addYear: true,
    })

    return (
        <div className={styles.base}>
            <Icon
                icon={icon({
                    name: "eye",
                    style: "light",
                    family: "sharp",
                })}
                size={12}
                noContainer={true}
            />
            <span title={`Last seen ${localDateTimeString}`}>Seen</span>
        </div>
    )
}
