import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import clsx from "clsx"
import { Command } from "cmdk"
import { useState } from "react"

import { Icon } from "@atoms"

import styles from "./SelectListHeader.module.scss"

export default function SelectListHeader() {
    const [value, setValue] = useState<string>("")

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value)
    }

    return (
        <div
            className={clsx(styles.base, {
                [styles.typed]: value.length > 0,
                [styles.typing]: value.length === 0,
            })}
        >
            <Icon
                icon={icon({
                    name: "search",
                    style: "regular",
                    family: "sharp",
                })}
                size={14}
            />

            <Command.Input
                onChangeCapture={onInputChange}
                placeholder="Search..."
                className={styles.input}
                autoFocus={true}
            />
        </div>
    )
}
